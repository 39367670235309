import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
  height: 100%;

  .titulo {
    h2 {
      font-size: 18px !important;
    }

    span {
      font-size: 14px !important;
    }

    .p-skeleton {
      margin-top: 8px;
    }
  }

  .imposto {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #112c50;
    font-size: 18px;

    & > span:last-child {
      font-size: 20px;
    }

    .p-skeleton {
      margin-top: 8px;
    }
  }

  .botoes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    button {
      width: 190px;
    }
  }
`;
