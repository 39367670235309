import styled from 'styled-components';

import { devices, pxToRem } from '../../../../utils/functions/styles';

const CARD_COLORS_MAP = new Map([
  ['irpro_inter_mensal', '#2B2B2D'],
  ['irpro_inter_anual', '#2B2B2D'],
  ['investpro_inter_mensal', '#2B2B2D'],
  ['gratis', '#96B7C7'],
  ['degustacao', '#53C3C2'],
  ['avulso_ir', '#335893'],
  ['anual_ir', '#4D4FA1'],
  ['bi_anual_ir', '#4D4FA1'],
  ['anual_ir_avancado', '#1B3667'],
  ['bi_anual_ir_avancado', '#1B3667'],
]);

const getCardColor = planCode => {
  if (!planCode) return CARD_COLORS_MAP.get('gratis');

  return CARD_COLORS_MAP.get(planCode);
};

export const Container = styled.div.attrs(props => ({
  cardColor: getCardColor(props.planCode),
}))`
  width: ${pxToRem(468)};
  height: ${pxToRem(298)};

  @media ${devices.mobileL} {
    width: 100%;
    height: ${pxToRem(230)};
    font-size: 12px;
  }

  background: linear-gradient(
    109.29deg,
    #101010 12.85%,
    ${props => props.cardColor} 102.61%
  );
  box-sizing: border-box;
  backdrop-filter: blur(24px);
  border-radius: 15px;

  padding: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: #fff;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
