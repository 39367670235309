import { Button, Container, CustomTag as Tag } from './styles';

export function CustomTag({
  status,
  mostrarBotaoEmissao,
  acaoDoBotao,
  emissaoHabilitada,
}) {
  return (
    <Container>
      <Tag type={status.type}>{status.label}</Tag>

      {mostrarBotaoEmissao && (
        <Button disabled={emissaoHabilitada} onClick={() => acaoDoBotao?.()}>
          <i
            className="pi pi-print"
            style={{
              fontSize: '1.1rem',
            }}
          ></i>
        </Button>
      )}
    </Container>
  );
}
