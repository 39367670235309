import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import { Footer, Form } from './styles';

export function Notificacoes() {
  return (
    <>
      <Card>
        <h1>Informe a mensagem que será enviada.</h1>

        <p>
          Mensagens personalizadas com o nome, por exemplo, têm resultado
          melhor.
        </p>

        <Form>
          <div className="field">
            <label htmlFor="title">Título</label>
            <InputText
              id="title"
              placeholder="@nome, finalizada a apuração de imposto de Set/21"
            />
          </div>
          <div className="field">
            <label htmlFor="message">Mensagem</label>
            <InputTextarea
              id="message"
              rows={10}
              placeholder="@nome, suas alienações em setembro/21 ficaram acima do teto de R$ 20.000,00. Após apurar todas as ordens no mês foi identificado imposto a pagar."
            />
          </div>
          <div className="field">
            <label htmlFor="url">URL</label>
            <InputText
              id="url"
              placeholder="https://www.bussoladoinvestidor.com.br"
            />
          </div>
          <div className="field upload-button-container">
            <label htmlFor="searchUser">Base de usuários</label>
            <FileUpload
              id="searchUser"
              className="upload-button"
              mode="basic"
              auto
              chooseLabel="Procurar"
            />
          </div>
        </Form>

        <Footer>
          <Button label="Enviar mensagem" />
        </Footer>
      </Card>
    </>
  );
}
