import { AppDS } from '@dev_bdi/bdi-front-dspack';
import 'moment/min/moment-with-locales.min';
import { useContext, useEffect, useState } from 'react';
import 'smarttchart/scripts/datafeed';
import 'smarttchart/scripts/utils';
import 'tradingview_charting_library/charting_library/charting_library.max';

import LETRAS from './assets/images/letras';
import PaymentPlan from './components/PaymentPlan';
import Rotas from './components/Rotas';
import Topmenu from './components/Topmenu';
import Usuario from './components/Usuario';
import {
  PLANO_GRATIS_KEY,
  SUPORTE_KEY,
  getMenuRoutes,
} from './components/menuRoutes';
import ConfigContext from './context/config';
import { useSubscriptions } from './context/subscriptions/SubscriptionsContext';
import { useToast } from './context/toast/useToast';
import { useConfiguracaoUsuario } from './hooks/useConfiguracaoUsuario';
import history from './service/history';
import './styles.css';

const App = () => {
  const { state } = useContext(ConfigContext);
  const [menu, setMenu] = useState([]);
  const [topmenu, setTopmenu] = useState();
  const [rotas, setRotas] = useState([]);
  const [dadosUsuario, setDadosUsuario] = useState({});
  const { configUsuario, loadingConfigUsuario } = useConfiguracaoUsuario();
  const { getCurrentSubscription } = useSubscriptions();

  const { showToast } = useToast();

  async function exibeMenu(routes) {
    state.menu ? setMenu(routes) : setMenu();
  }

  async function exibeTopmenu() {
    state.menu ? setTopmenu(<Topmenu />) : setTopmenu();
  }

  useEffect(() => {
    (async () => {
      try {
        const subscription = getCurrentSubscription();

        if (PaymentPlan === 'adm') {
          exibeMenu(getMenuRoutes(SUPORTE_KEY));
        } else if (subscription === 'NOT_FOUND') {
          exibeMenu(getMenuRoutes(PLANO_GRATIS_KEY));
        } else {
          exibeMenu(getMenuRoutes(subscription.plano?.billingPlanCode));
        }
      } finally {
        setRotas(Rotas);
        exibeTopmenu();
      }
    })();
  }, [state.menu]);

  useEffect(() => {
    async function createListUser() {
      let idUsuario = Usuario.id;

      fetch(
        `${process.env.REACT_APP_API_USUARIOS}/DadosPessoais/v1/GetUsuarioDadosPessoaisByIdUsuario/${idUsuario}`,
      )
        .then(result => result.json())
        .then(data => {
          setDadosUsuario(data);
        })
        .catch(error => {
          console.error(error);
        });
    }
    createListUser();
  }, [showToast]);

  useEffect(() => {
    if (dadosUsuario.urlFotoPerfil) {
      Usuario.foto =
        `${process.env.REACT_APP_URL_CALCULADORA2}` +
        dadosUsuario.urlFotoPerfil;
    }

    if (!Usuario.foto && dadosUsuario.nomeCompleto) {
      const primeiraLetra = dadosUsuario.nomeCompleto.charAt(0);

      Usuario.foto = LETRAS[primeiraLetra];
    }
  }, [dadosUsuario]);

  useEffect(() => {
    if (!configUsuario || !!configUsuario.usuarioAntigo)
      history.push('/cadastroinicial');
    else history.push('/');
  }, [configUsuario]);

  if (!Usuario) {
    window.location.href = process.env.REACT_APP_LOGIN;
  }

  return loadingConfigUsuario ? null : (
    <AppDS
      usuario={Usuario}
      menubdi={menu}
      rotas={rotas}
      login={process.env.REACT_APP_LOGIN}
      topmenu={topmenu}
    />
  );
};

export default App;
