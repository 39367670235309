import { ButtonBDI } from '@dev_bdi/bdi-front-dspack';
import axios from 'axios';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { useContext, useEffect, useRef, useState } from 'react';

import ConfigContext from '../../context/config';
import {
  EMPTY_MESSAGE,
  NO_ATTACHED_FILES,
  SEND_EMAIL_ERROR,
  SEND_EMAIL_SUCCESS,
} from '../../context/toast/constants';
import { useToast } from '../../context/toast/useToast';
import { apiOperacoes } from '../../service/ApiOperacoes/api';
import apiUsuarios from '../../service/apiUsuarios';
import history from '../../service/history';
import { formatStringValueToNumber } from '../../utils/functions/currency';
import Usuario from '../Usuario';
import {
  Box2,
  Box100cTable,
  Box100d,
  Box101a,
  Box101b,
  DialogFooter,
  PosicoesIniciaisContainer,
} from './styles';

export const Relatorio = () => {
  const { state, setUsuario } = useContext(ConfigContext);
  const [posicoes, setPosicoes] = useState(null);
  const [selectedCustomers1, setSelectedCustomers1] = useState(null);
  const [loading1, setLoading1] = useState(true);
  const [tipodata, setTipodata] = useState();
  const [datareferencia, setDatareferencia] = useState('');
  const [prejuizosavista, setPrejuizosavista] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosopcoes, setPrejuizosopcoes] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosfuturodolar, setPrejuizosfuturodolar] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosfuturoindice, setPrejuizosfuturoindice] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosfundosimobiliarios, setPrejuizosfundosimobiliarios] =
    useState('');
  const [swingtrade, setSwingtrade] = useState('');
  const [daytrade, setDaytrade] = useState('');
  const [avista, setAvista] = useState('');
  const [mensageminconsistencia, setMensageminconsistencia] = useState('');
  const [inconsistenciaDialog, setInconsistenciaDialog] = useState(false);
  const { showToast } = useToast();
  const informarInconsistenciaFileRef = useRef(null);

  const hideDialog = () => {
    setInconsistenciaDialog(false);
  };

  const dataCorretoras = data => {
    return <label>{data.corretora.name}</label>;
  };

  const dataPosicoes = data => {
    return <label>{data.posicao.name}</label>;
  };

  const dataTickers = data => {
    return <label>{data.ticker}</label>;
  };

  const dataQtde = data => {
    return <label>{data.quantidade}</label>;
  };

  const dataPrecomedio = data => {
    const isPontos = data.pontos !== '0,00' && data.pontos !== '';

    return (
      <label>
        {isPontos
          ? '-'
          : data.precomedio.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
      </label>
    );
  };

  const dataPontos = data => {
    const isPontos = data.pontos !== '0,00' && data.pontos !== '';
    const pontosFormatado = formatStringValueToNumber(data.pontos).toFixed(4);

    return <label>{isPontos ? pontosFormatado : '-'}</label>;
  };

  async function carregarTipoData(configUsuario) {
    if (
      configUsuario !== null &&
      configUsuario !== undefined &&
      configUsuario !== 'undefined' &&
      configUsuario !== '<empty string>' &&
      configUsuario !== ''
    ) {
      if (
        configUsuario.idTipoCorteCalculo !== null &&
        configUsuario.idTipoCorteCalculo !== undefined &&
        configUsuario.idTipoCorteCalculo !== 'undefined' &&
        configUsuario.idTipoCorteCalculo !== '<empty string>' &&
        configUsuario.idTipoCorteCalculo !== '' &&
        configUsuario.idTipoCorteCalculo !== 0
      ) {
        try {
          const tipo = await apiOperacoes.get(
            `TipoCorteCalculo/${configUsuario.idTipoCorteCalculo}`,
          );
          setTipodata(tipo.data.descricaoTipoCorteCalculo);
        } catch (error) {
          console.error(error);
          showToast();
        }
      }
    }
  }

  function carregarDataReferencia(configUsuario) {
    setDatareferencia(
      configUsuario.dataPosicaoInicial &&
        configUsuario.dataPosicaoInicial
          .split('T')[0]
          .split('-')
          .reverse()
          .join('/'),
    );
  }

  const carregarPosicoesIniciais = async () => {
    let idUser = Usuario.id;
    let cadInicial = true;

    try {
      const posicoes = await axios.get(
        `${process.env.REACT_APP_API_RELATORIOS}/PosicoesAbertas/v2/GetListaPosicoesAbertasPorUsuario/${idUser}/${cadInicial}`,
      );

      if (posicoes.data?.length > 0) {
        const newPosicoes = posicoes.data.map(p => {
          const ticker = p.instrumento;
          if (ticker !== null || ticker !== undefined)
            return {
              corretora: {
                code: p.corretora.idCorretora,
                name: p.corretora.nome,
              },
              posicao: {
                code: p.vendaCompra,
                name: p.vendaCompra === 'C' ? 'Compra' : 'Venda',
              },
              precomedio: p.pmBruto || 'R$ 0.00',
              pontos: p.pontos || 'R$ 0.00',
              quantidade: p.quantidadeTotal || '0',
              ticker: ticker.ticker || p.idInstrumento,
            };
        });

        if (newPosicoes.length > 0) {
          setPosicoes(newPosicoes);
          setLoading1(false);
        } else {
          setPosicoes([]);
          setLoading1(false);
        }
      } else {
        setPosicoes([]);
        setLoading1(false);
      }
    } catch (error) {
      console.error(error);
      showToast();
    }
  };

  function carregarPrejuizos(configUsuario) {
    setPrejuizosavista({
      comum: configUsuario.prejuizoAcumuladoMercadoAvistaComum,
      daytrade: configUsuario.prejuizoAcumuladoMercadoAvistaDt,
    });
    setPrejuizosopcoes({
      comum: configUsuario.prejuizoAcumuladoMercadoOpcoesComum,
      daytrade: configUsuario.prejuizoAcumuladoMercadoOpcoesDt,
    });
    setPrejuizosfuturodolar({
      comum: configUsuario.prejuizoAcumuladoMercadoFuturoMoedaComum,
      daytrade: configUsuario.prejuizoAcumuladoMercadoFuturoMoedaDt,
    });
    setPrejuizosfuturoindice({
      comum: configUsuario.prejuizoAcumuladoMercadoFuturoIndicesComum,
      daytrade: configUsuario.prejuizoAcumuladoMercadoFuturoIndicesDt,
    });
    setPrejuizosfundosimobiliarios(
      configUsuario.prejuizoAcumuladoFundosInvestimentosImobiliarios,
    );
  }

  function carregarIRRF(configUsuario) {
    setSwingtrade(configUsuario.irrfretidoComum);
    setDaytrade(configUsuario.irrfretidoDt);
    setAvista(configUsuario.irrfmercadoVista);
  }

  function configUsuario(config) {
    if (!config.configuracao) history.push('/cadastroinicial');
  }

  const informarInconsistencia = async () => {
    if (!informarInconsistenciaFileRef.current?.files) {
      showToast(NO_ATTACHED_FILES);
      return;
    } else if (!mensageminconsistencia) {
      showToast(EMPTY_MESSAGE);
      return;
    }

    try {
      let loginUsuario = state.usuario.username;
      let emailusuario = state.usuario.email;
      let dateNow = new Date().toLocaleString('sv-SE');

      const formData = new FormData();

      const obj = {
        loginUsuario: loginUsuario,
        emailusuario: emailusuario,
        planoUsuario: 'sem plano',
        mensagemDoUsuario: mensageminconsistencia,
        DataHoraInconcistencia: dateNow,
        TipoInconcistencia: 'cadastro inicial',
      };

      const myJSON = JSON.stringify(obj);

      formData.append('jsonData', myJSON);

      informarInconsistenciaFileRef.current.files.forEach((file, index) =>
        formData.append(`file-${index}`, file),
      );

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      await apiUsuarios.post(
        `/EnvioEmailSistemico/v1/PostEmailInconcistenciaGenerico`,
        formData,
        config,
      );

      setInconsistenciaDialog(false);
      setMensageminconsistencia('');
      informarInconsistenciaFileRef.current.clear();
      showToast(SEND_EMAIL_SUCCESS);
    } catch (error) {
      setStatusInformarInconsistenciaDialog(false);
      setMensageminconsistencia('');
      informarInconsistenciaFileRef.current.clear();
      showToast(SEND_EMAIL_ERROR);
    }
  };

  const inconsistenciaDialogFooter = (
    <>
      <DialogFooter>
        <Button
          label="Fechar"
          className="p-button-text without-background"
          onClick={hideDialog}
        />
        <Button
          label="Informar Inconsistência"
          onClick={informarInconsistencia}
        />
      </DialogFooter>
    </>
  );

  useEffect(() => {
    setUsuario(true);
    carregarPosicoesIniciais();
  }, []);

  useEffect(() => {
    state && configUsuario(state);
  }, [state]);

  useEffect(() => {
    carregarPrejuizos(state.configuracao);
    carregarIRRF(state.configuracao);
    carregarDataReferencia(state.configuracao);
    carregarTipoData(state.configuracao);
  }, [state.configuracao]);

  function finalizar(rota) {
    history.push(rota);
  }

  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-12">
        <Dialog
          visible={inconsistenciaDialog}
          style={{ width: '50vw' }}
          header="Informar Inconsistência"
          blockScroll={false}
          className="p-fluid"
          footer={inconsistenciaDialogFooter}
          onHide={hideDialog}
          modal
        >
          <div className="p-formgrid p-grid">
            <div className="p-field p-col">
              <p>
                Caso tenha percebido alguma inconsistência no cadastro de suas
                notas de corretagem, anexe abaixo os arquivos originais e
                informe o ocorrido para nossa equipe de suporte.
              </p>

              <h3>Mensagem</h3>
              <InputTextarea
                value={mensageminconsistencia}
                onChange={e => setMensageminconsistencia(e.target.value)}
                rows={5}
                cols={10}
                autoResize
              />
              <FileUpload
                chooseLabel="Anexar"
                name="invoice"
                accept="image/*"
                ref={informarInconsistenciaFileRef}
                customUpload={true}
                mode="basic"
                multiple
              />
            </div>
          </div>
        </Dialog>
        <Box2>
          <section>
            <div className="card">
              <div className="p-col-12 p-md-12">
                <h2>
                  Aqui estão as informações do seu{' '}
                  <strong>cadastro inicial</strong>.
                </h2>
              </div>
              <div className="p-col-12 p-md-12">
                <Box100d>
                  <div>
                    <p className="heading">Tipo de data utilizado</p>
                    <p className="heading">Data de referência</p>
                  </div>
                  <div>
                    <p>{tipodata}</p>
                    <p>{datareferencia}</p>
                  </div>
                </Box100d>
              </div>
              <div className="p-col-12 p-md-12 table-demo">
                <Box100cTable>
                  <div>
                    <p className="heading">Posições Iniciais</p>
                  </div>
                  <PosicoesIniciaisContainer>
                    <DataTable
                      value={posicoes}
                      paginator
                      className="p-datatable-customers"
                      rows={10}
                      dataKey="id"
                      rowHover
                      selection={selectedCustomers1}
                      onSelectionChange={e => setSelectedCustomers1(e.value)}
                      emptyMessage="Sem posições iniciais."
                      loading={loading1}
                    >
                      <Column
                        field="corretoras"
                        header="Corretora"
                        body={dataCorretoras}
                      ></Column>
                      <Column
                        field="posicoes"
                        header="Posição C/V"
                        body={dataPosicoes}
                      ></Column>
                      <Column
                        field="tickers"
                        header="Ticker"
                        body={dataTickers}
                      ></Column>
                      <Column
                        field="status"
                        header="Qtde"
                        body={dataQtde}
                      ></Column>
                      <Column
                        field="activity"
                        header="Preço médio"
                        body={dataPrecomedio}
                      ></Column>
                      <Column
                        field="activity"
                        header="Pontos"
                        body={dataPontos}
                      ></Column>
                    </DataTable>
                  </PosicoesIniciaisContainer>
                </Box100cTable>
              </div>
              <div className="p-col-12 p-md-12">
                <Box101a>
                  <div>
                    <p className="heading">Prejuízos acumulados</p>
                  </div>
                  <div>
                    <p className="heading">Tipos de mercado</p>
                    <p>&nbsp;</p>
                    <p className="heading">Operações comuns</p>
                    <p className="heading">Day-Trade</p>
                  </div>
                  <div>
                    <p className="heading">
                      Mercado à vista - ações, BDRs e ETFs
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      {prejuizosavista.comum &&
                        prejuizosavista.comum.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </p>
                    <p>
                      {prejuizosavista.daytrade &&
                        prejuizosavista.daytrade.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </p>
                  </div>
                  <div>
                    <p className="heading">Mercado opções - ações e ETFs</p>
                    <p>&nbsp;</p>
                    <p>
                      {prejuizosopcoes.comum &&
                        prejuizosopcoes.comum.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </p>
                    <p>
                      {prejuizosopcoes.daytrade &&
                        prejuizosopcoes.daytrade.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </p>
                  </div>
                  <div>
                    <p className="heading">Mercado futuro - dólar dos EUA</p>
                    <p>&nbsp;</p>
                    <p>
                      {prejuizosfuturodolar.comum &&
                        prejuizosfuturodolar.comum.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </p>
                    <p>
                      {prejuizosfuturodolar.daytrade &&
                        prejuizosfuturodolar.daytrade.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </p>
                  </div>
                  <div>
                    <p className="heading">Mercado futuro - índices</p>
                    <p>&nbsp;</p>
                    <p>
                      {prejuizosfuturoindice.comum &&
                        prejuizosfuturoindice.comum.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </p>
                    <p>
                      {prejuizosfuturoindice.daytrade &&
                        prejuizosfuturoindice.daytrade.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </p>
                  </div>
                  <div>
                    <p className="heading">Total</p>
                    <p>&nbsp;</p>
                    <p>
                      {(
                        prejuizosavista.comum +
                        prejuizosopcoes.comum +
                        prejuizosfuturodolar.comum +
                        prejuizosfuturoindice.comum
                      ).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </p>
                    <p>
                      {(
                        prejuizosavista.daytrade +
                        prejuizosopcoes.daytrade +
                        prejuizosfuturodolar.daytrade +
                        prejuizosfuturoindice.daytrade
                      ).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </p>
                  </div>
                </Box101a>

                <Box101b>
                  <div>
                    <p className="heading">Prejuízos acumulados</p>
                  </div>
                  <div>
                    <p className="heading">Fundos Imobiliários</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                  </div>
                  <div>
                    <p className="heading">Prejuízos acumulados</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>
                      {prejuizosfundosimobiliarios &&
                        prejuizosfundosimobiliarios.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </p>
                  </div>
                </Box101b>
              </div>
              <div className="p-col-12 p-md-12">
                <Box101a>
                  <div>
                    <p className="heading">IRRF acumulado</p>
                  </div>
                  <div>
                    <p className="heading">Detalhe</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p className="heading">Total</p>
                  </div>
                  <div>
                    <p className="heading">
                      IR retido acumulado em operações normais (Swing trade)
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>
                      {swingtrade &&
                        swingtrade.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </p>
                  </div>
                  <div>
                    <p className="heading">
                      IR retido acumulado em operações day trade
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>
                      {daytrade &&
                        daytrade.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </p>
                  </div>
                  <div>
                    <p className="heading">
                      IR retido acumulado em operações com Fundos Imobiliários
                    </p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>
                      {avista &&
                        avista.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </p>
                  </div>
                  <div>
                    <p className="heading">Total</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p>
                      {(swingtrade + daytrade + avista).toLocaleString(
                        'pt-br',
                        { style: 'currency', currency: 'BRL' },
                      )}
                    </p>
                  </div>
                </Box101a>
              </div>
              <div className="p-col-12 p-md-6">
                {/* <ButtonBDI
                  label="Informar inconsistência"
                  className={`
                        p-button-primary
                        p-button-primary-cancel
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => setInconsistenciaDialog(true)}
                  disabled={false}
                /> */}

                <ButtonBDI
                  label="Patrimônio"
                  className={`
                        ${ButtonBDI.bdi_classe.severities}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => finalizar('/')}
                  disabled={false}
                />
              </div>
            </div>
          </section>
        </Box2>
      </div>
    </div>
  );
};
