/* eslint-disable */
import {
  ButtonBDI,
  InputTextBDI,
  RadioButtonBDI,
} from '@dev_bdi/bdi-front-dspack';
import { Button } from 'primereact/button';
import { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../context/config';
import history from '../../service/history';
import { formatCurrency } from '../../utils/functions';
import { CENTRAL_AJUDA_PREJUIZOS_ACUMULADOS_LINK } from '../../utils/linksCentralAjuda';
import { Box1, Box100a, Box100b, Small } from './styles';

export const PrejuizosAcumulados = () => {
  const { state, setState } = useContext(ConfigContext);
  const { usuarioAntigo } = state.configuracao;
  const [prejuizosavista, setPrejuizosavista] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosopcoes, setPrejuizosopcoes] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosfuturodolar, setPrejuizosfuturodolar] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosfuturoindice, setPrejuizosfuturoindice] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosfundosimobiliarios, setPrejuizosfundosimobiliarios] =
    useState('');
  const [semprejuizos, setSemprejuizos] = useState(true);
  const [datareferencia, setDatareferencia] = useState();

  function salvarDados() {
    localStorage.setItem('prejuizosavista', JSON.stringify(prejuizosavista));
    localStorage.setItem('prejuizosopcoes', JSON.stringify(prejuizosopcoes));
    localStorage.setItem(
      'prejuizosfuturodolar',
      JSON.stringify(prejuizosfuturodolar),
    );
    localStorage.setItem(
      'prejuizosfuturoindice',
      JSON.stringify(prejuizosfuturoindice),
    );
    localStorage.setItem(
      'prejuizosfundosimobiliarios',
      JSON.stringify(prejuizosfundosimobiliarios),
    );
    localStorage.setItem('semprejuizos', semprejuizos);
  }

  function avancar() {
    salvarDados();
    history.push(`/cadastroinicial/irrfacumulado`);
  }

  function voltar() {
    salvarDados();
    window.history.back();
  }

  function viewMenu(estado) {
    setState({
      ...state,
      menu: estado,
    });
  }

  function handleValorPrejuizo(e) {
    const moedaFormatada = formatCurrency(e.target.value);

    if (e.target.id === 'prejuizosavistacomum')
      setPrejuizosavista({ ...prejuizosavista, comum: moedaFormatada });
    if (e.target.id === 'prejuizosavistadaytrade')
      setPrejuizosavista({ ...prejuizosavista, daytrade: moedaFormatada });
    if (e.target.id === 'prejuizosopcoescomum')
      setPrejuizosopcoes({ ...prejuizosopcoes, comum: moedaFormatada });
    if (e.target.id === 'prejuizosopcoesdaytrade')
      setPrejuizosopcoes({ ...prejuizosopcoes, daytrade: moedaFormatada });
    if (e.target.id === 'prejuizosfuturodolarcomum')
      setPrejuizosfuturodolar({
        ...prejuizosfuturodolar,
        comum: moedaFormatada,
      });
    if (e.target.id === 'prejuizosfuturodolardaytrade')
      setPrejuizosfuturodolar({
        ...prejuizosfuturodolar,
        daytrade: moedaFormatada,
      });
    if (e.target.id === 'prejuizosfuturoindicecomum')
      setPrejuizosfuturoindice({
        ...prejuizosfuturoindice,
        comum: moedaFormatada,
      });
    if (e.target.id === 'prejuizosfuturoindicedaytrade')
      setPrejuizosfuturoindice({
        ...prejuizosfuturoindice,
        daytrade: moedaFormatada,
      });
    if (e.target.id === 'prejuizosfundosimobiliarios')
      setPrejuizosfundosimobiliarios(moedaFormatada);

    setSemprejuizos(false);
  }

  function configUsuario(config) {
    if (config.configuracao && !config.configuracao.usuarioAntigo) {
      history.push('/cadastroinicial/relatorio');
    }
  }

  useEffect(() => {
    state.configuracao && configUsuario(state);
  }, [state.configuracao]);

  useEffect(() => {
    viewMenu(false);
  }, [state.menu]);

  useEffect(() => {
    if (localStorage.getItem('prejuizosavista'))
      setPrejuizosavista(JSON.parse(localStorage.getItem('prejuizosavista')));
    if (localStorage.getItem('prejuizosopcoes'))
      setPrejuizosopcoes(JSON.parse(localStorage.getItem('prejuizosopcoes')));
    if (localStorage.getItem('prejuizosfuturodolar'))
      setPrejuizosfuturodolar(
        JSON.parse(localStorage.getItem('prejuizosfuturodolar')),
      );
    if (localStorage.getItem('prejuizosfuturoindice'))
      setPrejuizosfuturoindice(
        JSON.parse(localStorage.getItem('prejuizosfuturoindice')),
      );
    if (localStorage.getItem('prejuizosfundosimobiliarios'))
      setPrejuizosfundosimobiliarios(
        JSON.parse(localStorage.getItem('prejuizosfundosimobiliarios')),
      );
    localStorage.getItem('semprejuizos') &&
    localStorage.getItem('semprejuizos') === 'false'
      ? setSemprejuizos(false)
      : setSemprejuizos(true);
    if (localStorage.getItem('datareferencia'))
      setDatareferencia(localStorage.getItem('datareferencia'));
  }, []);

  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-12">
        <Box1>
          <section className="card">
            <div className="timeline">
              <div className="container checked left">
                <div className="content checked">
                  <h3>Tipo de data utilizado</h3>
                </div>
              </div>
              <div className="container checked left">
                <div className="content checked">
                  <h3>Data de referência</h3>
                </div>
              </div>
              <div className="container checked left">
                <div className="content checked">
                  <h3>Posições iniciais</h3>
                </div>
              </div>
              <div className="container active left">
                <div className="content active">
                  <h3>Prejuízos acumulados</h3>
                </div>
              </div>
              <div className="container left">
                <div className="content">
                  <h3>IRRF acumulado</h3>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="card">
              <div className="p-col-12 p-md-12">
                <h2>
                  {usuarioAntigo ? (
                    <strong>
                      Informação importante: meus prejuízos acumulados.
                    </strong>
                  ) : (
                    <>
                      <strong>Por favor,</strong> informe quais são seus{' '}
                      <strong>prejuízos acumulados.</strong>
                    </>
                  )}
                  <a
                    href={CENTRAL_AJUDA_PREJUIZOS_ACUMULADOS_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      icon="pi pi-question-circle"
                      style={{ top: '9px' }}
                      className="p-button-rounded p-button-text colorIcon"
                    />
                  </a>
                </h2>
                <br />
                {usuarioAntigo ? (
                  <p className="subTituloBov">
                    O processo de migração para a SmarttIR contempla os seus
                    prejuízos acumulados.
                  </p>
                ) : (
                  <p className="subTituloBov">
                    Os prejuízos acumulados deverão ser cadastrados de acordo
                    com a data de referência escolhida{' '}
                    <strong>{datareferencia}</strong>. Se você ainda não tem
                    nenhum valor para cadastrar, clique na opção{' '}
                    <strong>
                      Não tenho prejuízos acumulados para cadastrar
                    </strong>
                    .
                  </p>
                )}
              </div>
              <br />

              <div
                className="p-col-12 p-md-8"
                style={{ display: `${usuarioAntigo ? 'none' : 'block'}` }}
              >
                <RadioButtonBDI
                  label="Não tenho prejuízos acumulados para cadastrar.*"
                  inputId="prejuizosacumulados"
                  name="prejuizosacumulados"
                  valor="semprejuizos"
                  checked={semprejuizos}
                  acao={() => {
                    setSemprejuizos(true);
                    setPrejuizosavista({ comum: '', daytrade: '' });
                    setPrejuizosopcoes({ comum: '', daytrade: '' });
                    setPrejuizosfuturodolar({ comum: '', daytrade: '' });
                    setPrejuizosfuturoindice({ comum: '', daytrade: '' });
                    setPrejuizosfundosimobiliarios('');
                  }}
                />
                <RadioButtonBDI
                  label="Desejo cadastrar prejuízos acumulados*"
                  inputId="prejuizosacumulados1"
                  name="prejuizosacumulados"
                  valor="1"
                  checked={!semprejuizos}
                  acao={() => {
                    setSemprejuizos(false);
                    setPrejuizosavista({ comum: '', daytrade: '' });
                    setPrejuizosopcoes({ comum: '', daytrade: '' });
                    setPrejuizosfuturodolar({ comum: '', daytrade: '' });
                    setPrejuizosfuturoindice({ comum: '', daytrade: '' });
                    setPrejuizosfundosimobiliarios('');
                  }}
                />
              </div>
              {!semprejuizos && (
                <div className="p-col-12 p-md-12">
                  <Box100a>
                    <div>
                      <p>
                        <label>Tipos de mercado</label>
                      </p>
                      <p>
                        <label>Operações comuns</label>
                      </p>
                      <p>
                        <label>Day-Trade</label>
                      </p>
                    </div>
                    <div>
                      <p>
                        <label>Mercado à vista - ações, BDRs e ETFs</label>
                      </p>
                      <p>
                        <InputTextBDI
                          type="text"
                          placeholder="0000000,00"
                          id="prejuizosavistacomum"
                          valor={prejuizosavista.comum}
                          disabled={semprejuizos}
                          acao={handleValorPrejuizo}
                        />
                      </p>
                      <p>
                        <InputTextBDI
                          type="text"
                          placeholder="0000000,00"
                          id="prejuizosavistadaytrade"
                          valor={prejuizosavista.daytrade}
                          disabled={semprejuizos}
                          acao={handleValorPrejuizo}
                        />
                      </p>
                    </div>
                    <div>
                      <p>
                        <label>Mercado opções - ações e ETFs</label>
                      </p>
                      <p>
                        <InputTextBDI
                          type="text"
                          placeholder="0000000,00"
                          id="prejuizosopcoescomum"
                          valor={prejuizosopcoes.comum}
                          disabled={semprejuizos}
                          acao={handleValorPrejuizo}
                        />
                      </p>
                      <p>
                        <InputTextBDI
                          type="text"
                          placeholder="0000000,00"
                          id="prejuizosopcoesdaytrade"
                          valor={prejuizosopcoes.daytrade}
                          disabled={semprejuizos}
                          acao={handleValorPrejuizo}
                        />
                      </p>
                    </div>
                    <div>
                      <p>
                        <label>Mercado futuro - dólar dos EUA</label>
                      </p>
                      <p>
                        <InputTextBDI
                          type="text"
                          placeholder="0000000,00"
                          id="prejuizosfuturodolarcomum"
                          valor={prejuizosfuturodolar.comum}
                          disabled={semprejuizos}
                          acao={handleValorPrejuizo}
                        />
                      </p>
                      <p>
                        <InputTextBDI
                          type="text"
                          placeholder="0000000,00"
                          id="prejuizosfuturodolardaytrade"
                          valor={prejuizosfuturodolar.daytrade}
                          disabled={semprejuizos}
                          acao={handleValorPrejuizo}
                        />
                      </p>
                    </div>
                    <div>
                      <p>
                        <label>Mercado futuro - índices</label>
                      </p>
                      <p>
                        <InputTextBDI
                          type="text"
                          placeholder="0000000,00"
                          id="prejuizosfuturoindicecomum"
                          valor={prejuizosfuturoindice.comum}
                          disabled={semprejuizos}
                          acao={handleValorPrejuizo}
                        />
                      </p>
                      <p>
                        <InputTextBDI
                          type="text"
                          placeholder="0000000,00"
                          id="prejuizosfuturoindicedaytrade"
                          valor={prejuizosfuturoindice.daytrade}
                          disabled={semprejuizos}
                          acao={handleValorPrejuizo}
                        />
                      </p>
                    </div>
                    <div>
                      <p>
                        <label>Total</label>
                      </p>
                      <p>
                        {prejuizosavista.comum ||
                        prejuizosopcoes.comum ||
                        prejuizosfuturodolar.comum ||
                        prejuizosfuturoindice.comum
                          ? (
                              Number(
                                prejuizosavista.comum
                                  .split('.')
                                  .join('')
                                  .split(',')
                                  .join('.'),
                              ) +
                              Number(
                                prejuizosopcoes.comum
                                  .split('.')
                                  .join('')
                                  .split(',')
                                  .join('.'),
                              ) +
                              Number(
                                prejuizosfuturodolar.comum
                                  .split('.')
                                  .join('')
                                  .split(',')
                                  .join('.'),
                              ) +
                              Number(
                                prejuizosfuturoindice.comum
                                  .split('.')
                                  .join('')
                                  .split(',')
                                  .join('.'),
                              )
                            ).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })
                          : '0000000,00'}
                      </p>
                      <p>
                        {prejuizosavista.daytrade ||
                        prejuizosopcoes.daytrade ||
                        prejuizosfuturodolar.daytrade ||
                        prejuizosfuturoindice.daytrade
                          ? (
                              Number(
                                prejuizosavista.daytrade
                                  .split('.')
                                  .join('')
                                  .split(',')
                                  .join('.'),
                              ) +
                              Number(
                                prejuizosopcoes.daytrade
                                  .split('.')
                                  .join('')
                                  .split(',')
                                  .join('.'),
                              ) +
                              Number(
                                prejuizosfuturodolar.daytrade
                                  .split('.')
                                  .join('')
                                  .split(',')
                                  .join('.'),
                              ) +
                              Number(
                                prejuizosfuturoindice.daytrade
                                  .split('.')
                                  .join('')
                                  .split(',')
                                  .join('.'),
                              )
                            ).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })
                          : '0000000,00'}
                      </p>
                    </div>
                  </Box100a>

                  <Box100b>
                    <div>
                      <p>
                        <label>Fundos Imobiliários</label>
                      </p>
                      <p>
                        <label>&nbsp;</label>
                      </p>
                    </div>
                    <div>
                      <p>
                        <label>Prejuízos acumulados</label>
                      </p>
                      <p>
                        <InputTextBDI
                          type="text"
                          placeholder="0000000,00"
                          id="prejuizosfundosimobiliarios"
                          valor={prejuizosfundosimobiliarios}
                          disabled={semprejuizos}
                          acao={handleValorPrejuizo}
                        />
                      </p>
                    </div>
                  </Box100b>
                </div>
              )}
              <div
                className="p-col-12 p-md-6 preenchimentoobrigatorio"
                style={{ display: `${usuarioAntigo ? 'none' : 'block'}` }}
              >
                <Small>*Preenchimento obrigatório</Small>
              </div>
              <div className="p-col-12 p-md-6">
                <ButtonBDI
                  label="Voltar"
                  className={`
                        ${ButtonBDI.bdi_classe.outlined}
                        ${ButtonBDI.bdi_subclasse.secondary}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => voltar()}
                />
                <ButtonBDI
                  label="Avançar"
                  className={`
                        ${ButtonBDI.bdi_classe.severities}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => avancar()}
                  disabled={
                    prejuizosavista.comum ||
                    prejuizosavista.daytrade ||
                    prejuizosopcoes.comum ||
                    prejuizosopcoes.daytrade ||
                    prejuizosfuturodolar.comum ||
                    prejuizosfuturodolar.daytrade ||
                    prejuizosfuturoindice.comum ||
                    prejuizosfuturoindice.daytrade ||
                    prejuizosfundosimobiliarios ||
                    semprejuizos
                      ? false
                      : true
                  }
                />
              </div>
            </div>
          </section>
        </Box1>
      </div>
    </div>
  );
};
