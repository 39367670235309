import { AlienacoesSkeleton } from './Alienacoes/AlienacoesSkeleton';
import { ImpostosSkeleton } from './Impostos/ImpostosSkeleton';
import { PosicoesAbertasSkeleton } from './PosicoesAbertas/PosicoesAbertasSkeleton';
import { WidgetGraficoSkeleton } from './commons/WidgetGrafico/WidgetGraficoSkeleton';

export function WidgetsSkeleton() {
  return (
    <>
      <WidgetGraficoSkeleton slotDireito />
      <WidgetGraficoSkeleton />
      <ImpostosSkeleton />
      <AlienacoesSkeleton />
      <PosicoesAbertasSkeleton />
    </>
  );
}
