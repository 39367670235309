import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    .p-dropdown {
      width: 100%;
    }

    .p-multiselect {
      width: 100%;
    }

    .p-skeleton:last-child {
      margin-top: 0.25rem;
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;
