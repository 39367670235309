import { QueryFunctionContext } from '@tanstack/react-query';

import Usuario from '../../../../../components/Usuario';
import { getMesAnoECorretoraOperacoesV2 } from '../../../../../service/ApiRelatorios/FiltroBusca';
import { ListaMesAnoCorretoras } from '../../../../../service/ApiRelatorios/FiltroBusca/types';
import { getYearMonthWith0 } from '../../../../../utils/functions/dates';

export type ListaCorretoras = {
  idCorretora: number;
  nome: string;
}[];

const getCorretoras = (corretoras: ListaCorretoras) => {
  if (!corretoras) return [];

  return corretoras.map(corretora => ({
    nome: corretora.nome,
    id: corretora.idCorretora,
  }));
};

const geraArrayMesAno = (listaMesAnoCorretoras: ListaMesAnoCorretoras) => {
  if (!listaMesAnoCorretoras) return [];

  return listaMesAnoCorretoras.map(mesAnoCorretoras => mesAnoCorretoras.mesAno);
};

export const filtraCorretoras = (
  data: string | null,
  listaMesAnoCorretoras?: ListaMesAnoCorretoras,
) => {
  if (data && listaMesAnoCorretoras?.length) {
    const [mesAnoCorretoras] = listaMesAnoCorretoras.filter(
      item => item.mesAno === data,
    );
    return getCorretoras(mesAnoCorretoras.lsCorretoras);
  }

  return [];
};

export const handleRequest = async ({ signal }: QueryFunctionContext) => {
  const response = await getMesAnoECorretoraOperacoesV2(Usuario.id, { signal });

  const { listaMesAnoCorretoras } = response.data;
  const listaMesAnoCorretorasFormatted = listaMesAnoCorretoras?.map(each => {
    const newMesAno = getYearMonthWith0(each.mesAno);

    return { ...each, mesAno: newMesAno };
  });

  return {
    listaMesAno: geraArrayMesAno(listaMesAnoCorretorasFormatted),
    listaMesAnoCorretorasFormatted,
  };
};
