import { useMemo } from 'react';

import { AccordionToggle } from '../../AccordionToggle';
import { NotaCorretagemRodapeBMF } from '../../types';
import * as S from './styles';

interface BMFDialogFooterProps {
  data?: NotaCorretagemRodapeBMF;
}

export function BMFDialogFooter({ data }: BMFDialogFooterProps) {
  const formattedData = useMemo(() => {
    if (data) {
      return {
        idNotaCorretagemRodapeBmf: data[0].idNotaCorretagemRodapeBmf,
        vendaDisponivel: data[0].vendaDisponivel,
        compraDisponivel: data[0].compraDisponivel,
        vendaOpcoes: data[0].vendaOpcoes,
        compraOpcoes: data[0].compraOpcoes,
        valorDosNegocios: data[0].valorDosNegocios,
        valorDosNegociosDc: data[0].valorDosNegociosDc,
        irrf: data[0].irrf,
        irrfdayTrade: data[0].irrfdayTrade,
        taxaOperacional: data[0].taxaOperacional,
        taxaRegistroBmf: data[0].taxaRegistroBmf,
        taxaBmf: data[0].taxaBmf,
        outrosCustos: data[0].outrosCustos,
        iss: data[0].iss,
        ajusteDePosicao: data[0].ajusteDePosicao,
        ajusteDePosicaoDc: data[0].ajusteDePosicaoDc,
        ajusteDayTrade: data[0].ajusteDayTrade,
        ajusteDayTradeDc: data[0].ajusteDayTradeDc,
        totalDasDespesas: data[0].totalDasDespesas,
        totalDasDespesasDc: data[0].totalDasDespesasDc,
        outros: data[0].outros,
        irrfcorretagem: data[0].irrfcorretagem,
        totalContaInvestimento: data[0].totalContaInvestimento,
        totalContaInvestimentoDc: data[0].totalContaInvestimentoDc,
        totalContaNormal: data[0].totalContaNormal,
        totalContaNormalDc: data[0].totalContaNormalDc,
        totalLiquido: data[0].totalLiquido,
        totalLiquidoDc: data[0].totalLiquidoDc,
        totalLiquidoNota: data[0].totalLiquidoNota,
        totalLiquidoNotaDc: data[0].totalLiquidoNotaDc,
      };
    }
  }, [data]);

  if (!formattedData) return <p>Carregando...</p>;

  return (
    <>
      <AccordionToggle headerName="Rodapé" className="mobile">
        <S.BMFDialogFooterContainer>
          <div>
            <div>
              <h3>Venda disponível</h3>
              <p>000000,00</p>
            </div>
            <div>
              <h3>IRRF</h3>
              <p>00000,00</p>
            </div>
            <div></div>
            <S.TwoColumnsDiv>
              <div>
                <h3>Outros</h3>
                <p>00000,00</p>
              </div>
              <div>
                <h3>IRRF corretagem</h3>
                <p>00000,00</p>
              </div>
            </S.TwoColumnsDiv>
          </div>
          <div>
            <div>
              <h3>Compra disponível</h3>
              <p>00000,00</p>
            </div>
            <div>
              <h3>IRRF daytrade (projeção)</h3>
              <p>00000,00</p>
            </div>
            <S.TwoColumnsDiv>
              <div>
                <h3>+ outros</h3>
                <p>00000,00</p>
              </div>
              <div>
                <h3>Imposto (ISS)</h3>
                <p>00000,00</p>
              </div>
            </S.TwoColumnsDiv>
            <div>
              <h3>Total conta investimento</h3>
              <p>
                00000,00 <span>D/C</span>
              </p>
            </div>
          </div>
          <div>
            <div>
              <h3>Venda opções</h3>
              <p>0000,00</p>
            </div>
            <div>
              <h3>Taxa operacional</h3>
              <p>00000,00</p>
            </div>
            <div>
              <h3>Ajuste de posição</h3>
              <p>00000,00</p>
            </div>
            <div>
              <h3>Total c. normal</h3>
              <p>
                00000,00 <span>D/C</span>
              </p>
            </div>
          </div>
          <div>
            <div>
              <h3>Compra opções</h3>
              <p>00000,00</p>
            </div>
            <div>
              <h3>Taxa registro</h3>
              <p>00000,00</p>
            </div>
            <div>
              <h3>Ajuste daytrade</h3>
              <p>
                00000,00 <span>D/C</span>
              </p>
            </div>
            <div>
              <h3>Total líquido</h3>
              <p>
                00000,00 <span>D/C</span>
              </p>
            </div>
          </div>
          <div>
            <div>
              <h3>Valor dos negócios</h3>
              <p>
                00000,00 <span>D/C</span>
              </p>
            </div>
            <div>
              <h3>Taxas BM&F (emol+f.gar)</h3>
              <p>
                00000,00 <span>D/C</span>
              </p>
            </div>
            <div>
              <h3>Totas das despesas</h3>
              <p>
                00000,00 <span>D/C</span>
              </p>
            </div>
            <div>
              <h3>Total líq. da nota</h3>
              <p>
                00000,00 <span>D/C</span>
              </p>
            </div>
          </div>
        </S.BMFDialogFooterContainer>
      </AccordionToggle>
      <S.BMFDialogFooterContainer className="desktop">
        <div>
          <div>
            <h3>Venda disponível</h3>
            <p>{formattedData.vendaDisponivel}</p>
          </div>
          <div>
            <h3>IRRF</h3>
            <p>{formattedData.irrf}</p>
          </div>
          <div></div>
          <S.TwoColumnsDiv>
            <div>
              <h3>Outros</h3>
              <p>{formattedData.outros}</p>
            </div>
            <div>
              <h3>IRRF corretagem</h3>
              <p>{formattedData.irrfcorretagem}</p>
            </div>
          </S.TwoColumnsDiv>
        </div>
        <div>
          <div>
            <h3>Compra disponível</h3>
            <p>{formattedData.compraDisponivel}</p>
          </div>
          <div>
            <h3>IRRF daytrade (projeção)</h3>
            <p>{formattedData.irrfdayTrade}</p>
          </div>
          <S.TwoColumnsDiv>
            <div>
              <h3>+ outros</h3>
              <p>{formattedData.outrosCustos}</p>
            </div>
            <div>
              <h3>Imposto (ISS)</h3>
              <p>{formattedData.iss}</p>
            </div>
          </S.TwoColumnsDiv>
          <div>
            <h3>Total conta investimento</h3>
            <p>
              {formattedData.totalContaInvestimento}{' '}
              <span>{formattedData.totalContaInvestimentoDc}</span>
            </p>
          </div>
        </div>
        <div>
          <div>
            <h3>Venda opções</h3>
            <p>{formattedData.vendaOpcoes}</p>
          </div>
          <div>
            <h3>Taxa operacional</h3>
            <p>{formattedData.taxaOperacional}</p>
          </div>
          <div>
            <h3>Ajuste de posição</h3>
            <p>
              {formattedData.ajusteDePosicao}{' '}
              <span>{formattedData.ajusteDePosicaoDc}</span>
            </p>
          </div>
          <div>
            <h3>Total c. normal</h3>
            <p>
              {formattedData.totalContaNormal}{' '}
              <span>{formattedData.totalContaNormalDc}</span>
            </p>
          </div>
        </div>
        <div>
          <div>
            <h3>Compra opções</h3>
            <p>{formattedData.compraOpcoes}</p>
          </div>
          <div>
            <h3>Taxa registro</h3>
            <p>{formattedData.taxaRegistroBmf}</p>
          </div>
          <div>
            <h3>Ajuste daytrade</h3>
            <p>
              {formattedData.ajusteDayTrade}{' '}
              <span>{formattedData.ajusteDayTradeDc}</span>
            </p>
          </div>
          <div>
            <h3>Total líquido</h3>
            <p>
              {formattedData.totalLiquido}{' '}
              <span>{formattedData.totalLiquidoDc}</span>
            </p>
          </div>
        </div>
        <div>
          <div>
            <h3>Valor dos negócios</h3>
            <p>
              {formattedData.valorDosNegocios}{' '}
              <span>{formattedData.valorDosNegociosDc}</span>
            </p>
          </div>
          <div>
            <h3>Taxas BM&F (emol+f.gar)</h3>
            <p>{formattedData.taxaBmf}</p>
          </div>
          <div>
            <h3>Totas das despesas</h3>
            <p>
              {formattedData.totalDasDespesas}{' '}
              <span>{formattedData.totalDasDespesasDc}</span>
            </p>
          </div>
          <div>
            <h3>Total líq. da nota</h3>
            <p>
              {formattedData.totalLiquidoNota}{' '}
              <span>{formattedData.totalLiquidoNotaDc}</span>
            </p>
          </div>
        </div>
      </S.BMFDialogFooterContainer>
    </>
  );
}
