import { useEffect, useRef } from 'react';

export const useEventListener = (eventType, handler, options) => {
  const { enabled = true, target = document } = options;
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    function internalHandler(event) {
      return handlerRef.current(event);
    }

    target.addEventListener(eventType, internalHandler);

    return () => target.removeEventListener(eventType, internalHandler);
  }, [eventType, enabled, target]);
};
