import { QueryFunctionContext, useQueries } from '@tanstack/react-query';
import axios from 'axios';

import { useToast } from '../../../../../context/toast/useToast';
import { ListInstrumentosOpcaoResponse } from '../types';

type FormFieldsResponse = {
  id: number;
  descricao: string;
}[];

async function getOpcoes({ signal, queryKey }: QueryFunctionContext) {
  const filterObj = {
    idUsuario: queryKey[4],
    idCorretora: 0,
    dataPregao: '',
    idSubTipoInstrumento: 0,
    idInstrumento: 0,
    idTipoNegocio: 0,
    callPut: '',
    status: '',
    posicao: 0,
    quantidade: '3000',
  };

  const { data } = await axios.post<ListInstrumentosOpcaoResponse[]>(
    `${process.env.REACT_APP_API_EVENTOS}/AjusteOpcoes/v1/ListInstrumentosOpcaoExecucaoFiltro`,
    filterObj,
    { signal },
  );

  return data.map(opcao => ({
    id: opcao.idPosicaoAberta,
    callPut: opcao.callPut,
    idCorretora: opcao.idCorretora,
    corretora: opcao.corretora,
    dc: opcao.dc,
    cv: opcao.vc,
    dataPregao: opcao.dataPregao,
    dataVencimento: opcao.dataVencimento,
    quantidade: opcao.quantidade,
    status: opcao.status.descricao,
    ticker: opcao.ticker,
    idInstrumento: opcao.idInstrumento,
    tipoNegocio: opcao.tipoNegocio,
    tipoAtivo: 'Opção de ação',
  }));
}

async function getFormField({ signal, queryKey }: QueryFunctionContext) {
  const formField = queryKey[3];
  const idUser = queryKey[4];

  const { data } = await axios.get<FormFieldsResponse>(
    `${process.env.REACT_APP_API_EVENTOS}/AjusteOpcoes/v1/${formField}/${
      idUser ? idUser : ''
    }`,
    { signal },
  );

  return data.map(opcao => ({
    label: opcao.descricao,
    value: opcao.id,
  }));
}

async function getDataPregao({ signal, queryKey }: QueryFunctionContext) {
  const formField = queryKey[3];
  const idUser = queryKey[4];

  const { data } = await axios.get<FormFieldsResponse>(
    `${process.env.REACT_APP_API_EVENTOS}/AjusteOpcoes/v1/${formField}/${
      idUser ? idUser : ''
    }`,
    { signal },
  );

  const formatDataPregao = (date: string) => {
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  };

  return data.map(opcao => ({
    label: formatDataPregao(opcao.descricao),
    value: formatDataPregao(opcao.descricao),
  }));
}

export function useAjusteOpcoesQueries(userId: number | null) {
  const { showToast } = useToast();

  return useQueries({
    queries: [
      {
        queryKey: [
          'eventos',
          'AjusteOpcoes',
          'v1',
          'ListInstrumentosOpcao',
          userId,
        ],
        queryFn: getOpcoes,
        enabled: !!userId,
        onError: () => showToast(),
      },
      {
        queryKey: [
          'eventos',
          'AjusteOpcoes',
          'v1',
          'GetCorretoraOpcoes',
          userId,
        ],
        queryFn: getFormField,
        enabled: !!userId,
        onError: () => showToast(),
      },
      {
        queryKey: ['eventos', 'AjusteOpcoes', 'v1', 'GetTickersOpcoes', userId],
        queryFn: getFormField,
        enabled: !!userId,
        onError: () => showToast(),
      },
      {
        queryKey: [
          'eventos',
          'AjusteOpcoes',
          'v1',
          'GetDataPregaoOpcoes',
          userId,
        ],
        queryFn: getDataPregao,
        enabled: !!userId,
        onError: () => showToast(),
      },
    ],
  });
}
