import axios from 'axios';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';

import { BaseModule } from '../../../../components/BaseModule';
import { RELATORIO_RENDIMENTOS_ISENTOS } from '../../../../components/BaseModule/constants';
import Paymentplan from '../../../../components/PaymentPlan';
import Usuario from '../../../../components/Usuario';
import { useToast } from '../../../../context/toast/useToast';
import { useOperationYearsFilter } from '../../../../hooks/reports/useOperationYearsFilter';
import * as ES from '../styles';
import './isento.css';

const CURRENT_YEAR = new Date().getFullYear();

export const RelatorioIsentos = () => {
  const [dadosDividendos, setDadosDividendos] = useState([]);
  const [dadosBonificacao, setDadosBonificacao] = useState([]);
  const [dadosFII, setDadosFII] = useState([]);
  const [ganhos20mil, setGanhos20mil] = useState('');
  const [selectedYear, setSelectedYear] = useState({
    name: String(CURRENT_YEAR),
    code: CURRENT_YEAR,
  });
  const [bloquearBonificacao, setBloquearBonificacao] = useState(false);
  const { showToast } = useToast();

  const operationYearsQuery = useOperationYearsFilter({ userId: Usuario.id });

  useEffect(() => {
    if (
      Paymentplan === undefined ||
      Paymentplan === 'gratis' ||
      Paymentplan === 'avulso_ir'
    ) {
      setBloquearBonificacao(true);
    }
  }, []);

  useEffect(() => {
    fetchInitialData(selectedYear.name);
  }, [selectedYear.name]);

  const fetchInitialData = async year => {
    const idUser = Usuario.id;
    const abortController = new AbortController();
    const signal = abortController.signal;

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_RELATORIOS}/RelatorioRendimentoIsentoNaoTributaveis/v1/GetRelatorioRendimentoIsentoNaoTributaveis/${idUser}/${year}`,
        { signal },
      );

      const ganhosAte20mil = `R$ ${data.ganhosLuiquidosIsentosAte20mil}`;

      const dadosDividendos = data.dividendos.map(item => {
        return {
          id: item.idCodigoRF,
          ticker: item.idInstrumentoNavigation.ticker,
          cnpj: item.idEmpresaNavigation.cnpj,
          fontePagadora: item.idEmpresaNavigation.nomeEmpresa,
          valor: `R$ ${item.valorLiquido}`,
        };
      });

      const dadosBonificacao = data.bonificacoes.map(item => {
        return {
          id: item.idCodigoRF,
          ticker: item.idInstrumentoNavigation.ticker,
          cnpj: item.idEmpresaNavigation.cnpj,
          fontePagadora: item.idEmpresaNavigation.nomeEmpresa,
          valor: `R$ ${item.valorLiquido}`,
        };
      });

      const dadosFII = data.rendimentoFIIs.map(item => {
        return {
          id: item.idCodigoRF,
          ticker: item.idInstrumentoNavigation.ticker,
          cnpj: item.idEmpresaNavigation.cnpj,
          fontePagadora: item.idEmpresaNavigation.nomeEmpresa,
          valor: `R$ ${item.valorLiquido}`,
        };
      });

      setGanhos20mil(ganhosAte20mil);
      setDadosDividendos(dadosDividendos);
      setDadosBonificacao(dadosBonificacao);
      setDadosFII(dadosFII);
    } catch (error) {
      console.error(error);
      showToast();
    }
  };

  return (
    <BaseModule
      tipo={RELATORIO_RENDIMENTOS_ISENTOS}
      props={{ nome: 'rendimentos isentos' }}
      warn={
        <ES.WarningParagraph>
          *Ano-calendário: é o período de 12 meses (1º de janeiro a 31 de
          dezembro) em que foram registrados os rendimentos e despesas do
          contribuinte.
        </ES.WarningParagraph>
      }
    >
      {!operationYearsQuery.isLoading && (
        <ES.FilterByDateContainer>
          <label htmlFor="operationYears">Ano-calendário:</label>
          <Dropdown
            id="operationYears"
            value={selectedYear}
            options={operationYearsQuery.data}
            onChange={e => setSelectedYear(e.value)}
            optionLabel="name"
            placeholder="Selecione o ano"
          />
        </ES.FilterByDateContainer>
      )}

      <DataTable
        value={dadosDividendos}
        header="Dividendos"
        dataKey="id"
        responsiveLayout="scroll"
        emptyMessage="Lista vazia."
      >
        <Column field="id" header="Código" className="corTextoDados" />
        <Column field="ticker" header="Ticker" className="corTextoDados" />
        <Column field="cnpj" header="CNPJ" className="corTextoDados" />
        <Column
          field="fontePagadora"
          header="Nome da Fonte Pagadora"
          className="corTextoDados"
        />
        <Column field="valor" header="Valor" className="corTextoDados" />
      </DataTable>

      <DataTable
        value={dadosBonificacao}
        header="Bonificações"
        dataKey="id"
        responsiveLayout="scroll"
        emptyMessage="Lista vazia."
        style={{
          marginTop: 34,
          display: bloquearBonificacao ? 'none' : 'block',
        }}
      >
        <Column field="id" header="Código" className="corTextoDados" />
        <Column field="ticker" header="Ticker" className="corTextoDados" />
        <Column field="cnpj" header="CNPJ" className="corTextoDados" />
        <Column
          field="fontePagadora"
          header="Nome da Fonte Pagadora"
          className="corTextoDados"
        />
        <Column field="valor" header="Valor" className="corTextoDados" />
      </DataTable>

      <DataTable
        value={dadosFII}
        header="Rendimentos de FII"
        dataKey="id"
        responsiveLayout="scroll"
        emptyMessage="Lista vazia."
        style={{ marginTop: 34 }}
      >
        <Column field="id" header="Código" className="corTextoDados" />
        <Column field="ticker" header="Ticker" className="corTextoDados" />
        <Column field="cnpj" header="CNPJ" className="corTextoDados" />
        <Column
          field="fontePagadora"
          header="Nome da Fonte Pagadora"
          className="corTextoDados"
        />
        <Column field="valor" header="Valor" className="corTextoDados" />
      </DataTable>

      <DataTable
        value={[
          {
            id: '20 - Ganhos líquidos em operações no mercado à vista de ações negociadas em bolsas de valores nas alienações realizadas até R$ 20.000,00 em cada mês, para o conjunto de ações',
            valor: ganhos20mil,
          },
        ]}
        header="Ganhos líquidos nas alienações realizadas até R$ 20.000,00"
        dataKey="id"
        responsiveLayout="scroll"
        emptyMessage="Lista vazia."
        style={{ marginTop: 34 }}
      >
        <Column field="id" header="Código" className="corTextoDados" />
        <Column field="valor" header="Valor" className="corTextoDados" />
      </DataTable>
    </BaseModule>
  );
};
