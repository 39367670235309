import styled from 'styled-components';

export const GraficoB3Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  h1 {
    margin: 0;
  }

  button {
    padding: 0 !important;
    border-radius: 50%;
    background-color: transparent !important;
    color: #53c3c2 !important;

    &:hover {
      opacity: 0.5;
    }
  }
`;
