import { formatCurrency } from '../../../utils/functions/index';

export const CAMPOS_OBRIGATORIOS = {
  exercicioDeOpcoes: ['tickerPregao', 'precoMedio'],
  encerramentoDeOpcoes: ['dataDeEncerramento', 'valorDoPremio'],
};

export const CAMPOS_MOEDA = ['precoMedio', 'valorDoPremio'];

export const EXERCICIO_DE_OPCOES = 'exercicioDeOpcoes';
export const ENCERRAMENTO_DE_OPCOES = 'encerramentoDeOpcoes';

export const FORMULARIO_VAZIO = {
  exercicioDeOpcoes: {
    tickerExercicioDeOpcao: '',
    tickerPregao: '',
    precoMedio: formatCurrency('0', true),
  },
  encerramentoDeOpcoes: {
    tickerDaOpcao: '',
    dataDeEncerramento: '',
    valorDoPremio: formatCurrency('0', true),
  },
};

const InformacoesModal = new Map([
  [EXERCICIO_DE_OPCOES, { titulo: 'Exercício de opção', acao: 'Aplicar' }],
  [
    ENCERRAMENTO_DE_OPCOES,
    { titulo: 'Informar encerramento de opção', acao: 'Encerrar' },
  ],
]);

export const getInformacoesModal = key => InformacoesModal.get(key);
