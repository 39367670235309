import { apiStatusProcessamento } from '../api';

export const getStatusNotaCorretagemByUserUploadV1 = (
  userId: string,
  serviceId: string,
  position: string,
  amount: string,
) =>
  `${process.env.REACT_APP_API_STATUSPROCESSAMENTO}/StatusNotaCorretagem/v1/RetornaStatusNotaCorretagemPorUploadUsuario/${userId}/${serviceId}/${position}/${amount}`;

export const getStatusNotaCorretagemByUserUploadV2 = (
  userId: string,
  serviceId: string,
  position: string,
  amount: string,
) =>
  `${apiStatusProcessamento}/StatusNotaCorretagem/v2/RetornaStatusNotaCorretagemPorUploadUsuario/${userId}/${serviceId}/${position}/${amount}`;

export const getStatusNotaCorretagemByFilter = () =>
  `${process.env.REACT_APP_API_STATUSPROCESSAMENTO}/StatusNotaCorretagem/v3/RetornaStatusNotaCorretagemPorFiltro`;
