import styled from 'styled-components';

import { pxToRem } from '../../../utils/functions/styles';

export const FilterByDateContainer = styled.section`
  width: 100%;
  border: 1px solid rgba(83, 195, 194, 0.4);
  padding: 2rem;
  border-radius: 0.3rem;
  margin: 3rem 0;

  label {
    margin-right: 1rem;
  }
`;

export const WarningParagraph = styled.p`
  font-size: ${pxToRem(12, 14)} !important;
  color: ${({ theme }) => theme.salmon};
`;
