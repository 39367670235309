import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { useRef, useState } from 'react';

import {
  EMPTY_MESSAGE,
  NO_ATTACHED_FILES,
  SEND_EMAIL_ERROR,
  SEND_EMAIL_SUCCESS,
} from '../../../../context/toast/constants';
import { useToast } from '../../../../context/toast/useToast';
import { StyledButton } from '../../../Button/styles';
import * as S from './styles';

type InputFileUpload = HTMLInputElement & FileUpload;

interface InconsistenciaDialogProps {
  visible: boolean;
  onHide: () => void;
  onInformarInconsistencia: (
    message: string,
    files: FileList,
  ) => Promise<boolean>;
}

export function InconsistenciaDialog({
  visible,
  onHide,
  onInformarInconsistencia,
}: InconsistenciaDialogProps) {
  const [mensagemInconsistencia, setMensagemInconsistencia] = useState('');
  const informarInconsistenciaFileRef = useRef<InputFileUpload>(null);
  const { showToast } = useToast();

  async function informarInconsistenciaValidation() {
    if (!informarInconsistenciaFileRef.current?.files) {
      showToast(NO_ATTACHED_FILES);
      return;
    } else if (!mensagemInconsistencia) {
      showToast(EMPTY_MESSAGE);
      return;
    }

    const files = informarInconsistenciaFileRef.current.files;

    const result = await onInformarInconsistencia(
      mensagemInconsistencia,
      files,
    );

    setMensagemInconsistencia('');
    informarInconsistenciaFileRef.current.clear();
    result ? showToast(SEND_EMAIL_SUCCESS) : showToast(SEND_EMAIL_ERROR);
  }

  const inconsistenciaDialogFooter = (
    <S.InconsistenciaDialogFooterContainer>
      <StyledButton label="Cancelar" className="tertiary" onClick={onHide} />
      <Button
        label="Informar Inconsistência"
        onClick={informarInconsistenciaValidation}
      />
    </S.InconsistenciaDialogFooterContainer>
  );

  return (
    <S.InconsistenciaDialogContainer
      header="Algo não está certo?"
      visible={visible}
      onHide={onHide}
      footer={inconsistenciaDialogFooter}
      style={{ width: 'min(40rem, 80vw)' }}
      modal
    >
      <p>
        Caso tenha percebido alguma inconsistência em sua nota de corretagem,
        informe o ocorrido para nossa equipe de suporte. Você também pode enviar
        uma imagem, caso seja necessário.
      </p>
      <h3>Conte-nos o ocorrido:</h3>
      <InputTextarea
        value={mensagemInconsistencia}
        onChange={e => setMensagemInconsistencia(e.target.value)}
        rows={5}
        autoResize
      />
      <FileUpload
        chooseLabel="Anexar"
        name="invoice"
        accept="image/*"
        ref={informarInconsistenciaFileRef}
        customUpload={true}
        mode="basic"
        multiple
      />
    </S.InconsistenciaDialogContainer>
  );
}
