import {
  QueryFunction,
  QueryFunctionContext,
  useQuery,
} from '@tanstack/react-query';
import { Button } from 'primereact/button';

import Usuario from '../../../../../components/Usuario';
import { useToast } from '../../../../../context/toast/useToast';
import { getDadosEmissaoDarf } from '../../../../../service/ApiRelatorios/RelatorioEmissaoDarf';
import { GreenBorderBox } from '../../../../../styles/elements/GreenBorderBox';
import { formatCurrency, irPara } from '../../../../../utils/functions';
import { formatStringValueToNumber } from '../../../../../utils/functions/currency';
import { getAnoMesAnterior } from '../../../../../utils/functions/dates';
import { ImpostosSkeleton } from './ImpostosSkeleton';
import * as S from './styles';

type ImpostosProps = {
  mesAno: string | null;
};

export function Impostos({ mesAno }: ImpostosProps) {
  const { showToast } = useToast();

  const handleDadosEmissaoDarfRequest = async ({
    signal,
  }: QueryFunctionContext) => {
    const response = await getDadosEmissaoDarf(
      Usuario.id,
      getAnoMesAnterior(mesAno),
      { signal },
    );

    const { data } = response;

    return {
      mes: data?.mes,
      ano: data?.ano,
      valor: formatCurrency(data.valorImposto?.toString(), true),
      vencimento: data.dataVencimento.dataOperacao1,
      valorEstaZerado: !formatStringValueToNumber(data.valorImposto),
    };
  };

  const onQueryError = () => {
    showToast();
  };

  const { data, isLoading, error } = useQuery(
    ['getDadosEmissaoDarf', mesAno],
    handleDadosEmissaoDarfRequest,
    {
      enabled: !!mesAno,
      retry: false,
      onError: onQueryError,
    },
  );

  if (isLoading || error) return <ImpostosSkeleton />;

  return (
    <GreenBorderBox>
      <S.Container>
        <div className="titulo">
          <h2>
            <strong>Pagamentos pendentes</strong>
          </h2>
          <span>Apuração do mês anterior</span>
        </div>
        <div className="imposto">
          <span>Pagar até o dia {data?.vencimento}</span>
          <span>
            <strong>{data?.valor}</strong>
          </span>
        </div>
        <div className="botoes">
          <Button
            label="Emitir DARF"
            onClick={irPara('/impostos/emissao-darf')}
            disabled={data?.valorEstaZerado}
          />
          <Button
            label="Confirmar Pagamento"
            onClick={irPara('/impostos/historico-darf')}
            disabled={data?.valorEstaZerado}
          />
        </div>
      </S.Container>
    </GreenBorderBox>
  );
}
