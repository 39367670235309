import { DataTable } from 'primereact/datatable';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  h2 {
    font-size: 18px !important;
    font-weight: 500 !important;
    margin-bottom: 0;
  }

  .p-skeleton {
    margin-top: 8px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 0.8rem;
`;

export const TabelaPosicoesAbertas = styled(DataTable)<{ children: ReactNode }>`
  &.p-datatable {
    margin: 0px -12px -12px -12px;
    border: none;

    .p-datatable-thead {
      background-color: rgba(150, 183, 199, 0.3);

      tr > th {
        background: unset;
        color: rgba(17, 44, 80, 1);
      }
    }

    .p-datatable-tbody {
      tr {
        background: rgba(150, 183, 199, 0.1);

        &:not(:last-child) {
          border-bottom: 1px solid rgba(83, 195, 194, 0.4);
        }
      }

      .p-datatable-row-expansion {
        & > td {
          padding: 0;
        }
      }
    }
  }
`;

export const TabelaExpandida = styled(DataTable)<{ children: ReactNode }>`
  &.p-datatable {
    border: none;

    .p-datatable-thead {
      background-color: rgba(77, 79, 161, 0.3);

      tr > th {
        background: unset;
      }
    }

    .p-datatable-tbody {
      tr {
        background: white;

        td {
          color: rgba(17, 44, 80, 0.5);
        }
      }
    }
  }
`;
