import { AxiosRequestConfig } from 'axios';

import { apiRelatorios } from '../api';
import { GetDadosEmissaoDarfData } from './types';

export const getDadosEmissaoDarf = (
  idUsuario: string,
  filtroData: string,
  options: AxiosRequestConfig,
) =>
  apiRelatorios.get<GetDadosEmissaoDarfData>(
    `/RelatorioEmissaoDarf/v1/GetDadosEmissaoDarf/${idUsuario}/${filtroData}`,
    options,
  );
