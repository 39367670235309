import axios from 'axios';

export default class ProductService {
  getProductsSmall() {
    return axios
      .get('assets/demo/data/products-small.json')
      .then(res => res.data.data);
  }

  getProducts() {
    return axios
      .get('assets/demo/data/products.json')
      .then(res => res.data.data);
  }
}
