import { ReactNode } from 'react';

import * as S from './styles';

interface GreenBoxContainerProps {
  children?: ReactNode;
}

export function GreenBoxContainer({ children }: GreenBoxContainerProps) {
  return <S.Container>{children}</S.Container>;
}
