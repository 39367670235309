import { Dialog } from 'primereact/dialog';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { devices } from '../../../../utils/functions/styles';

interface InconsistenciaDialogContainerProps {
  children: ReactNode;
}

export const InconsistenciaDialogContainer = styled(
  Dialog,
)<InconsistenciaDialogContainerProps>`
  .p-dialog-header,
  .p-dialog-content {
    border-bottom: 1px solid #53c3c26b;
  }

  p {
    margin-top: 1rem;
    color: #112c5080;
  }

  h3 {
    color: #112c50 !important;
  }

  textarea {
    width: 100%;
  }
`;

export const InconsistenciaDialogFooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding-top: 1rem;

  @media ${devices.mobileL} {
    flex-direction: column;
  }

  button {
    margin: 0 !important;
  }
`;
