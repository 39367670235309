import styled from 'styled-components';

import { devices, pxToRem } from '../../utils/functions/styles';

export const Container = styled.div`
  .titulo2 {
    font-size: 25px !important;
    padding-bottom: 2rem;
    margin-top: 0px;
    line-height: normal !important;
  }

  .subtitulo {
    font-size: 16px !important;
    margin-top: -30px;
    margin-bottom: 50px;
  }

  .p-fileupload-content {
    background: rgba(150, 183, 199, 0.1) !important;

    div.p-d-flex.p-ai-center.p-flex-wrap {
      padding: 0 !important;
      flex-direction: row;
    }
  }

  .p-message.p-message-error {
    margin-top: 2.9rem !important;
  }

  .p-button.p-button-icon-only {
    width: 2.357rem;
    padding-top: 0.5rem;
    padding-right: 5px;
    padding-bottom: 0.5rem;
    padding-left: 5px;
  }

  .subtitulo2 {
    font-size: 16px !important;
    padding-bottom: 2rem;
  }

  .fontBotao {
    background: #112c50 !important;
    font-family: 'Poppins' !important;
    text-align: center !important;
  }

  .fontBotao2 {
    background: #112c50 !important;
    font-family: 'Poppins' !important;
    float: right;
    text-align: center !important;
  }

  .btnCancelarProventos {
    top: 2px !important;
  }

  .fontBotaoCancelar {
    background: transparent !important;
    color: #a8c0ce !important;
    border: 1px solid #a8c0ce !important;
    font-family: 'Poppins' !important;
  }

  .p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .botaoTamanhoArquivo {
    background-color: #33589300 !important;
    font-family: 'Poppins';
    font-size: 14px;
  }

  .botaoApagar {
    border: 0px solid #335893 !important;
    border-radius: 70% !important;
    height: 2.057rem !important;
  }

  hr {
    border-color: #53c3c2 !important;
  }

  .espacamentoBtnCancelar {
    margin-right: 40px !important;
  }

  .botoes {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 1920px) {
    .alinhamentoTextoImportacao {
      left: 773px !important;
    }

    .alinhamentoTexto {
      margin-left: -10px !important;
    }
  }

  @media screen and (min-width: 1440px) {
    .alinhamentoTextoImportacao {
      left: 573px;
    }

    .alinhamentoTexto {
      margin-left: -10px !important;
    }
  }

  @media screen and (min-width: 1280px) {
    .alinhamentoTexto {
      margin-left: -10px !important;
    }
  }

  @media ${devices.tablet} {
    .titulo2 {
      font-size: ${pxToRem(20)} !important;
    }

    .subtitulo {
      font-size: ${pxToRem(16)} !important;
    }
  }

  @media ${devices.mobileM} {
    .titulo2 {
      font-size: ${pxToRem(18)} !important;
    }

    .subtitulo {
      font-size: ${pxToRem(14)} !important;
    }
  }

  @media ${devices.mobileS} {
    padding: 0 !important;
  }
`;

export const EmptyTemplate = styled.div`
  p {
    display: flex;
    align-items: center;

    i {
      margin-right: 0.8rem;
    }

    strong {
      margin-right: 0.3rem;
    }
  }

  @media ${devices.mobileM} {
    p {
      font-size: 12px !important;
    }
  }
`;

export const ItemTemplate = styled.div`
  padding: 0 !important;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 3fr 2fr 1fr;
  align-items: center;

  > div:first-child span {
    text-align: left;
    word-break: break-all;
  }

  @media ${devices.mobileM} {
    span {
      font-size: ${pxToRem(12)};
    }
  }
`;
