import { DataTable } from 'primereact/datatable';
import styled from 'styled-components';

import { devices } from '../../../../utils/functions/styles';

export const AcoesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3rem 0;
`;

export const DialogFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.6rem;
  margin-top: 2rem;
`;

export const Section = styled.section`
  margin-top: 2rem;
`;

export const DataTableContainer = styled(DataTable)`
  tr.p-highlight.p-selectable-row {
    color: #ffffff !important;
  }
`;

export const FormContainer = styled.div`
  input {
    width: 100%;
  }

  @media ${devices.mobileL} {
    > div.p-formgrid,
    > div.p-field {
      flex-direction: column !important;
    }
  }
`;

export const ButtonAddContainer = styled.div`
  display: flex;
  justify-content: center;

  button {
    border-radius: 50%;
  }
`;
