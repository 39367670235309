import { Column } from 'primereact/column';

import {
  ClasseAtivo,
  PosicaoAtivo,
} from '../../../../../service/ApiRelatorios/types';
import { formataValorDecimal } from '../../../../../utils/functions';
import { formatStringValueToNumber } from '../../../../../utils/functions/currency';
import { isMesAnoAtual } from '../../../../../utils/functions/dates';
import { CONTRATOS_FUTUROS, VENDA } from '../commons/constants';
import { MINI_CONTRATOS } from '../commons/constants';
import { INDISPONIVEL } from '../commons/constants';
import { Ativos, Cotacao } from '../types';
import { Rentabilidade } from './Rentabilidade';
import {
  AdicionaPatrimonioAtualAsPosicoes,
  AtivoAtualizado,
  AtualizaAtivo,
  CalculaRentabilidadeDoAtivo,
  Coluna,
  GeraAtivo,
  GeraPosicao,
  PosicaoAtualizada,
  ValorOuIndisponivel,
} from './types';

const getTickersDoAtivo = (ativo: ClasseAtivo) =>
  ativo.posicoes!.map(posicao => posicao.instrumento.ticker!);

export const getTickersDosAtivos = (ativos: Ativos) => {
  if (ativos.length) {
    return ativos.reduce<string[]>((acumulador, ativo) => {
      const tickers = getTickersDoAtivo(ativo);

      if (tickers?.length) {
        return [...acumulador, ...tickers];
      }

      return acumulador;
    }, []);
  }

  return [];
};

const isPosicaoMercadoFuturo = (posicao: PosicaoAtivo) => {
  if (posicao.instrumento.ticker) {
    return MINI_CONTRATOS.includes(posicao.instrumento.ticker);
  }

  return false;
};

const valorOuIndisponivel: ValorOuIndisponivel = (condicao, valor) => {
  if (!valor) return INDISPONIVEL;

  return condicao ? valor : INDISPONIVEL;
};

const isCotacaoDaPosicao = (ticker?: string, cotacao?: Cotacao) => {
  return ticker === cotacao?.ticker;
};

const calculaPorcentagem = (numerador: number, denominador: number) => {
  return ((numerador / denominador - 1) * 100).toFixed(2);
};

const getCotacaoAtualDaPosicao = (posicao: PosicaoAtivo, cotacao?: Cotacao) => {
  const {
    instrumento: { ticker },
  } = posicao;

  return valorOuIndisponivel(
    isCotacaoDaPosicao(ticker, cotacao),
    cotacao?.valor,
  );
};

const calculaPatrimonioDaPosicao = (
  quantidadeTotal: number,
  precoMedioBruto?: number,
) => {
  if (!precoMedioBruto) return valorOuIndisponivel(false);

  const valor = precoMedioBruto * quantidadeTotal;

  return valorOuIndisponivel(!!precoMedioBruto, valor);
};

const calculaPatrimonioAtualDaPosicao = (
  posicao: PosicaoAtivo,
  cotacao?: Cotacao,
) => {
  const {
    instrumento: { ticker },
    quantidadeTotal,
  } = posicao;

  if (isPosicaoMercadoFuturo(posicao)) return '-';

  if (!cotacao?.valor) return valorOuIndisponivel(false);

  const valor = quantidadeTotal * cotacao?.valor;

  return valorOuIndisponivel(isCotacaoDaPosicao(ticker, cotacao), valor);
};

const calculaPatrimonioCompraVenda = (
  posicao: PosicaoAtivo,
  cotacao?: Cotacao,
) => {
  const {
    quantidadeTotal,
    vendaCompra,
    instrumento: { ticker },
  } = posicao;

  if (isPosicaoMercadoFuturo(posicao)) return '-';

  if (!cotacao?.valor) return valorOuIndisponivel(false);

  let valor = 0;

  if (vendaCompra === VENDA) {
    valor = cotacao?.valor * quantidadeTotal * -1;
  }

  valor = cotacao?.valor * quantidadeTotal;

  return valorOuIndisponivel(isCotacaoDaPosicao(ticker, cotacao), valor);
};

const calculaRentabilidadeDaPosicao = (
  posicao: PosicaoAtivo,
  cotacao?: Cotacao,
) => {
  const {
    pmBruto,
    instrumento: { ticker },
  } = posicao;

  const precoMedioBruto = formatStringValueToNumber(pmBruto);

  if (!precoMedioBruto || !cotacao?.valor) return valorOuIndisponivel(false);

  const valor = calculaPorcentagem(cotacao.valor, precoMedioBruto);

  return valorOuIndisponivel(isCotacaoDaPosicao(ticker, cotacao), valor);
};

const calculaValorRentabilidadeDaPosicao = (
  posicao: PosicaoAtivo,
  cotacao?: Cotacao,
) => {
  const {
    pmBruto,
    quantidadeTotal,
    instrumento: { ticker },
  } = posicao;

  const precoMedioBruto = formatStringValueToNumber(pmBruto);

  if (!precoMedioBruto || !cotacao?.valor) return valorOuIndisponivel(false);

  const patrimonio = precoMedioBruto * quantidadeTotal;

  const patrimonioAtual = cotacao?.valor * quantidadeTotal;

  const rentabilidade = formataValorDecimal(patrimonioAtual - patrimonio);

  return valorOuIndisponivel(
    isCotacaoDaPosicao(ticker, cotacao),
    rentabilidade,
  );
};

const calculaDiferencaPontosDaPosicao = (
  posicao: PosicaoAtivo,
  cotacao?: Cotacao,
) => {
  const {
    pontos,
    quantidadeTotal,
    instrumento: { ticker },
  } = posicao;

  const pontosFormatado = formatStringValueToNumber(pontos);

  if (!cotacao?.valor || !pontosFormatado) return valorOuIndisponivel(false);

  const diferencaPontos = (cotacao.valor - pontosFormatado) * quantidadeTotal;

  return valorOuIndisponivel(
    isCotacaoDaPosicao(ticker, cotacao),
    diferencaPontos,
  );
};

const geraPosicao: GeraPosicao = (cotacao?) => (posicao, index) => {
  const { instrumento, vendaCompra, pmBruto, quantidadeTotal, corretora } =
    posicao;
  const { ticker } = instrumento;

  const precoMedioBruto = formatStringValueToNumber(pmBruto);

  return {
    ...posicao,
    id: index,
    ticker: ticker,
    posicao: vendaCompra,
    precoMedio: precoMedioBruto,
    nomeCorretora: corretora.nome,
    cotacaoAtual: getCotacaoAtualDaPosicao(posicao, cotacao),
    patrimonio: calculaPatrimonioDaPosicao(quantidadeTotal, precoMedioBruto),
    patrimonioAtual: calculaPatrimonioAtualDaPosicao(posicao, cotacao),
    patrimonioCompraVenda: calculaPatrimonioCompraVenda(posicao, cotacao),
    diferencaPontos: calculaDiferencaPontosDaPosicao(posicao, cotacao),
    rentabilidade: calculaRentabilidadeDaPosicao(posicao, cotacao),
    valorRentabilidade: calculaValorRentabilidadeDaPosicao(posicao, cotacao),
    carteira: 0,
  };
};

const geraPosicoes = (ativo: ClasseAtivo, cotacao?: Cotacao) => {
  return ativo.posicoes?.map(geraPosicao(cotacao));
};

const atualizaPosicoes = (ativo: AtivoAtualizado, cotacao?: Cotacao) => {
  return ativo.posicoes?.map((posicao, index) => {
    if (isCotacaoDaPosicao(posicao.ticker, cotacao)) {
      return geraPosicao(cotacao)(posicao, index);
    }

    return posicao;
  });
};

const calculaPatrimonioAtualDoAtivo = (posicoes?: PosicaoAtualizada[]) => {
  return posicoes?.reduce<number | 'Indisponível'>((acumulador, posicao) => {
    const { patrimonioCompraVenda } = posicao;

    if (
      typeof patrimonioCompraVenda === 'number' &&
      typeof acumulador === 'number'
    ) {
      return acumulador + patrimonioCompraVenda;
    }

    return INDISPONIVEL;
  }, 0);
};

const adicionaPatrimonioAtualAsPosicoes: AdicionaPatrimonioAtualAsPosicoes = (
  patrimonioAtualDoAtivo,
  posicoes,
) => {
  return posicoes?.map(posicao => ({ ...posicao, patrimonioAtualDoAtivo }));
};

const calculaRentabilidadeDoAtivo: CalculaRentabilidadeDoAtivo = (
  patrimonioAtual,
  ativo,
) => {
  const patrimonioInvestido = formatStringValueToNumber(
    ativo.patrimonioInvestido,
  );

  if (
    !patrimonioInvestido ||
    patrimonioAtual === INDISPONIVEL ||
    !patrimonioAtual
  ) {
    return valorOuIndisponivel(false);
  }

  const valor = calculaPorcentagem(patrimonioAtual, patrimonioInvestido);

  return valorOuIndisponivel(true, valor);
};

const geraAtivo: GeraAtivo = cotacao => (ativo, index) => {
  const posicoes = geraPosicoes(ativo, cotacao);

  const patrimonioAtual = calculaPatrimonioAtualDoAtivo(posicoes);

  const tickersDoAtivo = getTickersDoAtivo(ativo);

  return {
    ...ativo,
    id: index,
    patrimonioAtual,
    rentabilidade: calculaRentabilidadeDoAtivo(patrimonioAtual, ativo),
    tickersDoAtivo,
    posicoes: adicionaPatrimonioAtualAsPosicoes(patrimonioAtual, posicoes),
  };
};

const atualizaAtivo: AtualizaAtivo = cotacao => ativo => {
  const posicoes = atualizaPosicoes(ativo, cotacao);

  const patrimonioAtual = calculaPatrimonioAtualDoAtivo(posicoes);

  return {
    ...ativo,
    patrimonioAtual,
    rentabilidade: calculaRentabilidadeDoAtivo(patrimonioAtual, ativo),
    posicoes: adicionaPatrimonioAtualAsPosicoes(patrimonioAtual, posicoes),
  };
};

export const geraTemplateDaTabela = (ativos: Ativos, cotacao?: Cotacao) => {
  return ativos.map(geraAtivo(cotacao));
};

const adicionaPatrimonioDaCarteira = (
  posicoes?: PosicaoAtualizada[],
  patrimonioDaCarteira?: number,
) => {
  if (posicoes) {
    return posicoes.map(posicao => {
      return {
        ...posicao,
        patrimonioDaCarteira,
      };
    });
  }
};

const atualizaAtivoDaTabela = (ativos: AtivoAtualizado[], cotacao: Cotacao) => {
  const ativosFiltrados = ativos.filter(ativo =>
    ativo.tickersDoAtivo?.includes(cotacao.ticker),
  );
  const [ativoAtualizado] = ativosFiltrados.map(atualizaAtivo(cotacao));

  const ativosAtualizados = ativos.map(ativo => {
    return ativo.tipoAtivo === ativoAtualizado.tipoAtivo
      ? ativoAtualizado
      : ativo;
  });

  const patrimonioDaCarteira = ativosAtualizados.reduce((acumulador, ativo) => {
    if (ativo.patrimonioAtual) {
      if (
        ativo.patrimonioAtual !== INDISPONIVEL &&
        ativo.tipoAtivo !== CONTRATOS_FUTUROS
      ) {
        return acumulador + ativo.patrimonioAtual;
      }
    }

    return acumulador;
  }, 0);

  const ativosComPatrimonioCarteiraAtualizados = ativosAtualizados.map(
    ativo => {
      return {
        ...ativo,
        posicoes: adicionaPatrimonioDaCarteira(
          ativo.posicoes,
          patrimonioDaCarteira,
        ),
      };
    },
  );

  return ativosComPatrimonioCarteiraAtualizados;
};

export const atualizaAtivosComCotacoes = (
  ativos: AtivoAtualizado[],
  cotacoes: Cotacao[],
) => {
  return cotacoes.reduce((acumulador, cotacao) => {
    if (cotacao) {
      const ativoAtualizado = atualizaAtivoDaTabela(acumulador, cotacao);

      return ativoAtualizado;
    }

    return acumulador;
  }, ativos);
};

const renderizaPorcentagemDaCarteira = (posicao: PosicaoAtualizada) => {
  const { patrimonioAtual, patrimonioDaCarteira } = posicao;

  if ([patrimonioAtual, patrimonioDaCarteira].includes(INDISPONIVEL))
    return INDISPONIVEL;

  const valor = (
    ((patrimonioAtual as number) / (patrimonioDaCarteira as number)) *
    100
  ).toFixed(2);

  return `${formataValorDecimal(valor)} %`;
};

export const formataCelulaNumeroParaDinheiro =
  (key: keyof PosicaoAtualizada) => (posicao: PosicaoAtualizada) => {
    const valor = posicao[key];

    if (valor === '-') return '-';

    if (valor === INDISPONIVEL) return INDISPONIVEL;

    return formataValorDecimal(valor);
  };

const COLUNA_RENTABILIDADE = {
  header: 'Rentabilidade',
  body: (data: PosicaoAtualizada) => (
    <Rentabilidade
      valor={data.valorRentabilidade}
      porcentagem={data.rentabilidade}
    />
  ),
};

const COLUNA_PORCENTAGEM_DA_CARTEIRA = {
  field: 'carteira',
  header: '% carteira',
  body: renderizaPorcentagemDaCarteira,
};

const COLUNA_DIFERENCA_PONTOS = {
  field: 'diferencaPontos',
  header: 'Diferença (Pontos)',
  body: (data: PosicaoAtualizada) => (
    <Rentabilidade
      valor={formataValorDecimal(data.diferencaPontos)}
      porcentagem={data.rentabilidade}
    />
  ),
};

const COLUNA_PORCENTAGEM_DA_CARTEIRA_CONTRATO_FUTURO = {
  ...COLUNA_PORCENTAGEM_DA_CARTEIRA,
  body: '-',
};

const geraColunas = (mesAno: string) => {
  return [
    { field: 'nomeCorretora', header: 'Corretora' },
    { field: 'posicao', header: 'C/V' },
    { field: 'ticker', header: 'Ativo' },
    {
      field: 'precoMedio',
      header: 'Preço médio',
      body: formataCelulaNumeroParaDinheiro('precoMedio'),
    },
    {
      field: 'cotacaoAtual',
      header: isMesAnoAtual(mesAno) ? 'Cotação atual' : 'Cotação no mês',
      body: formataCelulaNumeroParaDinheiro('cotacaoAtual'),
    },
    {
      field: 'quantidadeTotal',
      header: 'Qtde',
    },
    {
      field: 'patrimonioAtual',
      header: isMesAnoAtual(mesAno) ? 'Patrimônio atual' : 'Patrimônio no mês',
      body: formataCelulaNumeroParaDinheiro('patrimonioAtual'),
    },
    COLUNA_RENTABILIDADE,
    COLUNA_PORCENTAGEM_DA_CARTEIRA,
  ] as Coluna[];
};

const geraArrayColunasContratosFuturos = (colunas: Coluna[]) => {
  const novaTabelaExpandida = [...colunas];

  novaTabelaExpandida.splice(5, 1, COLUNA_DIFERENCA_PONTOS);
  novaTabelaExpandida.splice(
    6,
    2,
    COLUNA_PORCENTAGEM_DA_CARTEIRA_CONTRATO_FUTURO,
  );

  return novaTabelaExpandida;
};

const geraJSXColumns = (colunas: Coluna[]) =>
  colunas.map(coluna => (
    <Column
      key={coluna.field}
      field={coluna.field}
      header={coluna.header}
      body={coluna.body}
    />
  ));

export const geraColunasTabelaExpandida = (
  rowData: AtivoAtualizado,
  mesAno: string,
) => {
  const colunas = geraColunas(mesAno);

  if (rowData.tipoAtivo !== CONTRATOS_FUTUROS) {
    return geraJSXColumns(colunas);
  }

  const colunasContratosFuturos = geraArrayColunasContratosFuturos(colunas);

  return geraJSXColumns(colunasContratosFuturos);
};
