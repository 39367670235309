import styled from 'styled-components';

import { devices, pxToRem } from '../../../../../utils/functions/styles';

export const FormContainer = styled.div`
  border: 1px solid ${({ theme }) => theme['cyan-opacity']};
  padding: 1rem 0.5rem;

  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(
    auto-fit,
    minmax(min(100%, ${pxToRem(225, 14)}), 1fr)
  );

  > div {
    display: flex;
    flex-direction: column;
  }

  input {
    width: 100%;
    border-color: rgb(13, 37, 80) !important;

    &:disabled {
      pointer-events: all;
      cursor: not-allowed !important;
    }
  }
`;

export const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${devices.mobileL} {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const DateErrorParagraph = styled.p`
  color: ${({ theme }) => theme.salmon};
  font-size: ${pxToRem(12, 14)} !important;
  margin-top: 0.2rem;
`;
