import { useEffect } from 'react';

import Usuario from '../Usuario';

const getTipoPlano = tipoPlano => {
  return tipoPlano ? `&TipoPlano=${tipoPlano}` : '';
};

const getURL = ({
  nome,
  email,
  parceiro = 'BDI&plano',
  plano = 'gratuito',
  tipoPlano,
}) => {
  return `https://conteudo.bussoladoinvestidor.com.br/onbf1?nome=${nome}&email=${email}&parceiro=${parceiro}&plano=${plano}${getTipoPlano(
    tipoPlano,
  )}`;
};

export const Onboarding = () => {
  const getUrlOnboarding = () => {
    let nome = Usuario.nome.split(' ')[0];
    let email = Usuario.email;
    let tipoPagamento = Usuario.payment.tipoPagamento;
    let plano = Usuario.payment.paymentPlan;

    if (tipoPagamento?.includes('PARCEIRO')) {
      if (tipoPagamento === 'PARCEIRO_BANCO INTER') {
        tipoPagamento = 'INTER';
      }

      return getURL({
        nome,
        email,
        parceiro: tipoPagamento,
        plano: plano.split('_')[0],
      });
    }

    if (tipoPagamento?.length > 0) {
      let hoje = new Date();
      let dataFimSplit = Usuario.payment.dataFim.split('/');

      let diaFim = dataFimSplit[0];
      let mesFim = dataFimSplit[1];
      let anoFim = dataFimSplit[2].substring(0, 4);

      let dataFimConvertido = new Date(anoFim, mesFim, diaFim);

      if (dataFimConvertido > hoje) {
        return getURL({
          nome,
          email,
          plano,
          tipoPlano: tipoPagamento.trim(),
        });
      }
    }

    return getURL({ nome, email, tipoPlano: 'gratuito' });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-grid p-fluid input-demo">
      <div className="p-col-1 p-md-12">
        <iframe
          title="Onboarding"
          src={getUrlOnboarding()}
          width="100%"
          height="1200px"
          style={{ border: 0 }}
        ></iframe>
      </div>
    </div>
  );
};
