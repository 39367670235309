import styled from 'styled-components';

import { pxToRem } from '../../utils/functions/styles';

export const Container = styled.div`
  width: 100%;
  max-width: ${pxToRem(1920, 14)};
  padding: ${pxToRem(32, 14)} ${pxToRem(40, 14)} !important;
  border-radius: 3px;
  border: 1px solid rgba(83, 195, 194, 0.5);
  background-color: #fff;
`;
