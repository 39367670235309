import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';

import { CENTRAL_AJUDA_LINK_RTD_LINK } from '../../utils/linksCentralAjuda';
import PaymentPlan from '../PaymentPlan';
import Usuario from '../Usuario';
import './linkrtd.css';

export const Linkrtd = () => {
  const [usuario, setUsuario] = useState({});
  const [pagamento, setPagamento] = useState({});
  const [rtdDialog, setRtdDialog] = useState(false);

  useEffect(() => {
    setUsuario(Usuario);
    setPagamento(PaymentPlan);
  }, []);

  const modalRTD = () => {
    setRtdDialog(true);
  };

  const fecharRtd = () => {
    setRtdDialog(false);
  };
  return (
    <div className="p-grid p-fluid input-demo">
      <div className="p-col-1 p-md-12">
        <div className="card cardLinhaVerde">
          <h2>
            <b className="tamanhoTitulo">Link RTD</b>
            <Button
              icon="pi pi-question-circle"
              style={{ top: '9px' }}
              className="p-button-rounded p-button-text colorIcon"
              tooltip=""
              onClick={modalRTD}
            />
          </h2>

          <table>
            <tr>
              <td>
                <p className="alinhamento">
                  Instale o plugin abaixo para o funcionamento da ferramenta:
                </p>
                <a
                  href="https://bdi-link-rtd.s3.amazonaws.com/Setup_32.msi"
                  download
                >
                  <Button
                    label="Versão 32 Bits"
                    className="p-button-severities p-button-primary p-mr-2 p-mb-2 buttomDownload"
                  />
                </a>
                <a
                  href="https://bdi-link-rtd.s3.amazonaws.com/Setup_64.msi"
                  download
                >
                  <Button
                    label="Versão 64 Bits"
                    className="p-button-severities p-button-primary p-mr-2 p-mb-2 buttomDownload"
                  />
                </a>
              </td>
              <td></td>
              <td>
                <p>Requisitos Mínimos</p>
                <ul>
                  <li className="corUl">Plataforma Microsoft Windows</li>
                  <li className="corUl">
                    Sistema Operacional Windows XP ou superior
                  </li>
                  <li className="corUl">Microsoft Office 2007 ou superior</li>
                  <li className="corUl">
                    Usuário de Windows com privilégios de Administrador
                  </li>
                  <li className="corUl">Habilitar as Macros no Excel</li>
                </ul>
              </td>
            </tr>
          </table>

          <div>
            <h2>Planilha modelo</h2>
            <p>
              Através da planilha, você personaliza suas análises com
              informações, realiza cálculos de maneira simples para tomada de
              decisão, calcula lucro e perda nas operações, controla o risco,
              entre outras funcionalidades disponíveis.
            </p>
            <br />
            <p>Funcionalidades:</p>
            <ul>
              <li className="corUl">DATA - data última transação</li>
              <li className="corUl">NOME - nome do ativo</li>
              <li className="corUl">ULT - último preço</li>
              <li className="corUl">MIN - maior preço do dia</li>
              <li className="corUl">MAX - menor preço do dia</li>
              <li className="corUl">ABE - preço abertura</li>
              <li className="corUl">FEC - preço último fechamento</li>
              <li className="corUl">QUANT - quantidade de ações no trade</li>
              <li className="corUl">NEG - quantidade de negócios no dia</li>
              <li className="corUl">VARP - variação percentual</li>
              <li className="corUl">VARV - variação em valor</li>
              <li className="corUl">VOLT - volume de títulos no dia</li>
              <li className="corUl">VOLF - volume financeiro no dia</li>
            </ul>
            <a
              href="https://s3.amazonaws.com/pub.bussoladoinvestidor.com.br/Link_RTD_Bussola_do_Investidor.xlsx"
              download
            >
              <Button
                label="Baixe a Planilha"
                className="p-button-severities p-button-primary p-mr-2 p-mb-2 buttomDownload2"
              />
            </a>
          </div>
        </div>
      </div>
      <Dialog
        visible={rtdDialog}
        style={{ width: '41vw' }}
        header="O que é Link RTD"
        blockScroll={false}
        className="p-fluid"
        modal
        onHide={fecharRtd}
      >
        <div>
          <p>
            O Link RTD da Bússola do Investidor é um programa que permite
            receber atualizações em tempo real dentro de suas planilhas Excel,
            permitindo usar fórmulas, fazer análises e criar uma área de
            trabalho personalizada para acompanhar seus trades.
          </p>
          <p>
            Quer saber como usá-lo?
            <a
              href={CENTRAL_AJUDA_LINK_RTD_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <u>
                <strong> Clique aqui.</strong>
              </u>
            </a>
          </p>
        </div>
      </Dialog>
    </div>
  );
};
