import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { EXERCICIO_OPCAO_SUCCESS_KEY } from '../../../../../context/toast/constants';
import { useToast } from '../../../../../context/toast/useToast';
import { ExecutaOpcaoModalObj } from '../types';

async function executeExercicioOpcao(obj: ExecutaOpcaoModalObj) {
  const abortController = new AbortController();
  const signal = abortController.signal;

  await axios.post(
    `${process.env.REACT_APP_API_EVENTOS}/AjusteOpcoes/v1/ExecutaExercicioOpcao`,
    obj,
    { signal },
  );
}

export function useExercicioOpcaoMutation() {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation(executeExercicioOpcao, {
    onError: () => showToast(),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([
        'eventos',
        'AjusteOpcoes',
        'v1',
        'ListInstrumentosOpcao',
        variables.idUsuario,
      ]);
      showToast(EXERCICIO_OPCAO_SUCCESS_KEY);
    },
  });
}
