import { DataTable } from 'primereact/datatable';
import { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { Button } from '../../../../components/Button';
import { pxToRem } from '../../../../utils/functions/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

interface DataTableContainerProps {
  children: ReactNode;
}

export const DataTableContainer = styled(DataTable)<DataTableContainerProps>``;

const STATUS_TAG_COLORS = {
  Exercida: '#53C3C2',
  Pendente: '#4D4FA1',
  Encerrada: '#96B7C7',
  Vencida: '#EA736A',
} as const;

interface StatusTagProps extends HTMLAttributes<HTMLDivElement> {
  status: keyof typeof STATUS_TAG_COLORS;
}

export const StatusTag = styled.div<StatusTagProps>`
  width: 8rem;
  max-width: ${pxToRem(150, 14)};
  padding: 0.5rem;
  font-size: 12px;
  background-color: ${({ status }) => STATUS_TAG_COLORS[status]};
  color: ${({ theme }) => theme.white};
  border-radius: 3px;
  border-color: ${({ status }) => STATUS_TAG_COLORS[status]} !important;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
`;

export const AdjustButton = styled(Button)`
  pointer-events: all;

  &:disabled:hover {
    cursor: not-allowed !important;
  }
`;
