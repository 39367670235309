import { Accordion } from 'primereact/accordion';
import styled from 'styled-components';

export const AccordionToggle = styled(Accordion)`
  margin-block: 3rem 1rem;

  .p-accordion-header-link {
    background-color: #112c50 !important;
    color: #fff !important;
  }
`;
