import Cookies from 'js-cookie';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useEffect, useReducer, useRef, useState } from 'react';

import { BaseModule } from '../../../../components/BaseModule';
import { AJUSTE_DE_OPCOES } from '../../../../components/BaseModule/constants';
import { ContainerFiltro } from '../../../../components/ContainerFiltro';
import * as ES from '../../../../styles/elements/MenuButton';
import { formatDateUS } from '../../../../utils/functions/dates';
import { STATUS, TIPO_NEGOCIO } from './constants';
import { EncerrarOpcaoDialog } from './dialogs/EncerrarOpcao';
import { ExercerOpcaoDialog } from './dialogs/ExercerOpcao/index';
import { useAjusteOpcoesQueries } from './hooks/useAjusteOpcoesQueries';
import { useFilterOpcoesMutation } from './hooks/useFilterOpcoesMutation';
import * as S from './styles';
import { CallPut, EventsRow, FilterList, Status } from './types';

type ReducerActionPayload =
  | { idUsuario: number }
  | { idCorretora: number }
  | { idInstrumento: number }
  | { idTipoNegocio: number }
  | { callPut: CallPut }
  | { status: Status }
  | { dataPregao: string };

type ReducerAction = {
  type: 'clear' | 'change';
  payload?: ReducerActionPayload;
};

function filterReducer(state: FilterList, action: ReducerAction) {
  switch (action.type) {
    case 'change':
      return {
        ...state,
        ...action.payload,
      };
    case 'clear':
      return {
        ...state,
        idCorretora: 0,
        dataPregao: '',
        idInstrumento: 0,
        idTipoNegocio: 0,
        callPut: '' as CallPut,
        status: '' as Status,
      };
    default:
      throw new Error('Action type not supported');
  }
}

const statusTemplate = (rowData: EventsRow) => (
  <S.StatusTag status={rowData.status}>{rowData.status}</S.StatusTag>
);

const initial_reducer_value = {
  idUsuario: null,
  idCorretora: 0,
  dataPregao: '',
  idSubTipoInstrumento: 0,
  idInstrumento: 0,
  idTipoNegocio: 0,
  callPut: '' as CallPut,
  status: '' as Status,
  posicao: 0,
  quantidade: '3000',
};

export const AjusteDeOpcoes = () => {
  const userId = Cookies.get('Id');
  const menuRefs = useRef<Map<number, Menu> | null>(null);
  const [isModalExercerOpen, setIsModalExercerOpen] = useState(false);
  const [isModalEncerrarOpen, setIsModalEncerrarOpen] = useState(false);
  const [rowData, setRowData] = useState({} as EventsRow);
  const [filterState, dispatch] = useReducer(
    filterReducer,
    initial_reducer_value,
  );

  // React Query Hooks
  const [
    listOpcoesQuery,
    corretoraOpcoesQuery,
    tickerOpcoesQuery,
    pregaoOpcoesQuery,
  ] = useAjusteOpcoesQueries(filterState.idUsuario);
  const changeFilterEvent = useFilterOpcoesMutation();

  useEffect(() => {
    if (userId)
      dispatch({
        type: 'change',
        payload: { idUsuario: Number(userId) },
      });
  }, [userId]);

  useEffect(() => {
    if (isModalExercerOpen || isModalEncerrarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isModalExercerOpen, isModalEncerrarOpen]);

  function getMenuMap() {
    if (!menuRefs.current) menuRefs.current = new Map();
    return menuRefs.current;
  }

  function getCurrentMenu(id: number) {
    const map = getMenuMap();
    const node = map.get(id);
    return node;
  }

  function handleFilterEvent() {
    const newFilter: FilterList = {
      ...filterState,
      dataPregao: formatDateUS(filterState.dataPregao),
    };

    changeFilterEvent.mutate(newFilter);
  }

  const handleClearFilter = () => dispatch({ type: 'clear' });

  const handleOpenModal = (
    rowData: EventsRow,
    type: 'exercer' | 'encerrar',
  ) => {
    setRowData(rowData);

    type === 'exercer'
      ? setIsModalExercerOpen(true)
      : setIsModalEncerrarOpen(true);
  };

  const menuTemplate = (rowData: EventsRow) => {
    const isMenuDisabled =
      rowData.status === 'Exercida' || rowData.status === 'Encerrada';
    const overlayItems: MenuItem[] = [
      {
        label: 'Exercer opção',
        command: () => handleOpenModal(rowData, 'exercer'),
      },
      {
        label: 'Encerrar opção',
        command: () => handleOpenModal(rowData, 'encerrar'),
      },
    ];

    return (
      <>
        <Menu
          id="popup_menu"
          model={overlayItems}
          ref={node => {
            const map = getMenuMap();
            if (node) {
              map.set(rowData.id, node);
            } else {
              map.delete(rowData.id);
            }
          }}
          popup
        />
        <ES.MenuButton
          aria-controls="popup_menu"
          icon="pi pi-ellipsis-v"
          disabled={isMenuDisabled}
          onClick={e => {
            const currentRef = getCurrentMenu(rowData.id);
            !!currentRef && currentRef.toggle(e);
          }}
        />
      </>
    );
  };

  return (
    <BaseModule tipo={AJUSTE_DE_OPCOES}>
      <S.Container>
        <ContainerFiltro
          onLeftButtonClick={handleClearFilter}
          onRightButtonClick={handleFilterEvent}
          rightButtonDisabled={changeFilterEvent.isLoading}
        >
          <div>
            <label htmlFor="corretora">Corretora</label>
            <Dropdown
              id="corretora"
              emptyMessage="Nenhuma corretora encontrada"
              value={filterState.idCorretora}
              options={corretoraOpcoesQuery.data}
              onChange={e =>
                dispatch({
                  type: 'change',
                  payload: { idCorretora: e.value },
                })
              }
            />
          </div>
          <div>
            <label htmlFor="pregao">Data</label>
            <Dropdown
              id="pregao"
              emptyMessage="Nenhuma data encontrada"
              value={filterState.dataPregao}
              options={pregaoOpcoesQuery.data}
              onChange={e =>
                dispatch({ type: 'change', payload: { dataPregao: e.value } })
              }
            />
          </div>
          <div>
            <label htmlFor="ticker">Ticker</label>
            <Dropdown
              id="ticker"
              emptyMessage="Nenhum ticker encontrado"
              value={filterState.idInstrumento}
              options={tickerOpcoesQuery.data}
              onChange={e =>
                dispatch({
                  type: 'change',
                  payload: { idInstrumento: e.value },
                })
              }
            />
          </div>
          <div>
            <label htmlFor="tipoNegociacao">Tipo de negociação</label>
            <Dropdown
              id="tipoNegociacao"
              value={filterState.idTipoNegocio}
              options={TIPO_NEGOCIO}
              onChange={e =>
                dispatch({
                  type: 'change',
                  payload: { idTipoNegocio: e.value },
                })
              }
            />
          </div>
          <div>
            <label htmlFor="callPut">Call/Put</label>
            <Dropdown
              id="callPut"
              value={filterState.callPut}
              options={[
                { label: 'Call', value: 'Call' },
                { label: 'Put', value: 'Put' },
              ]}
              onChange={e =>
                dispatch({ type: 'change', payload: { callPut: e.value } })
              }
            />
          </div>
          <div>
            <label htmlFor="status">Status</label>
            <Dropdown
              id="status"
              value={filterState.status}
              options={STATUS}
              onChange={e =>
                dispatch({ type: 'change', payload: { status: e.value } })
              }
            />
          </div>
        </ContainerFiltro>

        <S.DataTableContainer
          value={listOpcoesQuery.data}
          loading={listOpcoesQuery.isLoading}
          responsiveLayout="scroll"
          emptyMessage="Lista vazia."
          paginator
          currentPageReportTemplate="Mostrando de {first} até {last} - Total de {totalRecords} registros"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
          sortMode="multiple"
          multiSortMeta={[
            { field: 'status', order: -1 },
            { field: 'dataPregao', order: -1 },
          ]}
        >
          <Column header="Corretora" field="corretora" sortable />
          <Column header="Pregão" field="dataPregao" sortable />
          <Column header="Tipo de ativo" field="tipoAtivo" sortable />
          <Column header="Quantidade" field="quantidade" sortable />
          <Column header="Ticker" field="ticker" sortable />
          <Column header="Tipo de neg." field="tipoNegocio" sortable />
          <Column header="Call/Put" field="callPut" sortable />
          <Column header="V/C" field="cv" sortable />
          <Column
            header="Status"
            field="status"
            sortable
            body={statusTemplate}
          />
          <Column header="Ajustar" body={menuTemplate} />
        </S.DataTableContainer>
      </S.Container>

      {isModalExercerOpen && (
        <ExercerOpcaoDialog
          visible={isModalExercerOpen}
          onHide={() => setIsModalExercerOpen(false)}
          opcaoData={rowData}
        />
      )}

      {isModalEncerrarOpen && (
        <EncerrarOpcaoDialog
          visible={isModalEncerrarOpen}
          onHide={() => setIsModalEncerrarOpen(false)}
          opcaoData={rowData}
        />
      )}
    </BaseModule>
  );
};
