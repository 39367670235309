import React from 'react';

import SIRLogo from '../../../../assets/Logotipo_SIR_white.png';
import smarttIRLogo from '../../../../assets/Logotipo_SmarttIR_white.png';
import miniLogoInter from '../../../../assets/mini-logo-inter.png';
import { formataDataLocal } from '../../../../utils/functions';
import { Container } from './styles';

const separaData = data => data.split(' ')[0];

const getLogo = planCode => {
  if (
    [
      'irpro_inter_mensal',
      'irpro_inter_anual',
      'investpro_inter_mensal',
    ].includes(planCode)
  ) {
    return { logo: miniLogoInter, alt: 'Logo Inter' };
  }

  return { logo: SIRLogo, alt: 'Logo Smartt IR' };
};

const getDataValidade = (inicio, fim) => {
  if (!inicio && !fim) return;

  const dataInicial = separaData(formataDataLocal(inicio));
  const dataFinal = !!fim ? separaData(formataDataLocal(fim)) : '--/--/----';

  return `${dataInicial} - ${dataFinal}`;
};

export function Cartao({ subscricaoAtual }) {
  const {
    createdAt,
    expiresAt,
    plano: { nome, billingPlanCode },
  } = subscricaoAtual;

  const { logo, alt } = getLogo(billingPlanCode);

  return (
    <Container planCode={billingPlanCode}>
      <div>
        <img src={logo} alt={alt} width={80} />
        <strong>{nome}</strong>
      </div>

      <div>
        <span>{getDataValidade(createdAt, expiresAt)}</span>
        <img src={smarttIRLogo} alt="Logo Logo Smartt IR" width={120} />
      </div>
    </Container>
  );
}
