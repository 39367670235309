import Paymentplan from '../PaymentPlan';
import RotasAnual from './RotasAnual';
import RotasAvancado from './RotasAvancado';
import RotasAvulso from './RotasAvulso';
import RotasDegustacao from './RotasDegustacao';
import RotasGratis from './RotasGratis';
import RotasInter from './RotasInter';

let Rotas = null;

switch (Paymentplan) {
  case 'gratis':
    Rotas = RotasGratis;
    break;
  case 'degustacao':
    Rotas = RotasDegustacao;
    break;
  case 'avulso_ir':
    Rotas = RotasAvulso;
    break;
  case 'anual_ir':
    Rotas = RotasAnual;
    break;
  case 'anual_ir_avancado':
    Rotas = RotasAvancado;
    break;
  case 'irpro_inter_mensal':
    Rotas = RotasInter;
    break;
  case 'irpro_inter_anual':
    Rotas = RotasInter;
    break;
  case 'adm':
    Rotas = RotasAvancado;
    break;
  default:
    Rotas = RotasAvancado;
    break;
}

export default Rotas;
