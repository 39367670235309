import styled from 'styled-components';

export const ResultadosContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  margin: 3rem 0;

  > div {
    height: 7rem;
    border: 1px solid rgba(83, 195, 194, 0.4);
    box-sizing: border-box;
    border-radius: 3px;

    padding: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      font-weight: 400;
    }

    span {
      font-size: 18px;
      font-weight: 500;
    }
  }

  @media (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;
