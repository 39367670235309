import styled from 'styled-components';

import { devices } from '../../../../utils/functions/styles';

export const Container = styled.div`
  .p-avatar.p-component.p-avatar-circle.p-avatar-xl {
    background: #112c50 !important;
  }

  #avatar-upload {
    display: none;
  }

  label {
    margin-top: 1rem;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  > div.container-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 3rem 0;
  }
`;

export const Box = styled.article`
  padding: 1.5rem;

  h2 {
    font-size: 1.5rem !important;
  }

  div.container-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    margin: 2rem 0;

    .field input {
      width: 100%;
    }

    @media ${devices.tablet} {
      grid-template-columns: repeat(2, 1fr);
    }

    @media ${devices.mobileS} {
      grid-template-columns: 1fr;
    }

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  div.container-grid.edit {
    @media ${devices.mobileL} {
      grid-template-columns: 1fr;
    }
  }

  & + article {
    margin-top: 3rem;

    border-top: 1px solid #f1f1f1;
    padding: 2rem 0;
  }

  footer {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0 !important;

    @media ${devices.mobileL} {
      justify-content: space-evenly;
    }

    @media ${devices.mobileM} {
      flex-direction: column;
    }
  }
`;

export const DialogFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.6rem;
  margin-top: 2rem;
`;
