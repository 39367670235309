import { DataTable } from 'primereact/datatable';
import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  border: 1px solid rgba(83, 195, 194, 0.4);
  padding: 2rem;
  border-radius: 0.3rem;
  margin: 3rem 0;

  display: flex;
  align-items: flex-end;
  gap: 1rem;

  @media (max-width: 992px) {
    flex-direction: column;

    div {
      width: 100%;
    }

    button {
      text-align: center !important;
    }
  }
`;

export const DialogDarfIssuanceDataContainer = styled.article`
  width: 100%;
  border: 1px solid rgba(83, 195, 194, 0.4);
  padding: 2rem;
  border-radius: 0.3rem;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  strong {
    color: #112c50;
  }

  > p {
    display: inline-block;
    margin-top: 1rem;
  }

  .corTextoPopUp {
    color: #112c50 !important;
  }
`;

export const DialogFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.6rem;
  margin-top: 2rem;

  .without-background {
    background: none !important;
    color: #96b7c7;
    border: 1px solid #96b7c7 !important;
  }
`;

export const StyledDataTable = styled(DataTable)`
  thead th {
    color: #112c50 !important;
    background: rgba(150, 183, 199, 0.1) !important;
    font-weight: bold;
  }

  .status-em {
    width: 8rem;
    height: 2rem;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background-color: #4d4fa1;
  }

  .status-ir {
    width: 8rem;
    height: 2rem;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background-color: #53c3c2;
  }

  .status-não {
    width: 8rem;
    height: 2rem;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background-color: rgba(51, 88, 147, 0.5);
  }

  .status-isento {
    width: 8rem;
    height: 2rem;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    background-color: rgba(51, 88, 147, 0.5);
  }

  .botaoImpressora {
    background: #53c3c2;
    border: 0 #53c3c2 !important;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
`;
