import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import {
  OPERATION_ACCEPTED_ERROR,
  OPERATION_ACCEPTED_SUCCESS,
  OPERATION_REJECTED_SUCCESS,
} from '../../../../../context/toast/constants';
import { useToast } from '../../../../../context/toast/useToast';

type ExecuteEvent = {
  idUsuario: string;
  idDesdobramentoGrupamento: number;
  autorizacao: boolean;
  corretora: number;
  precoMedio?: number;
};

async function handleExecuteEvent({
  idUsuario,
  idDesdobramentoGrupamento,
  autorizacao,
  precoMedio,
  corretora,
}: ExecuteEvent) {
  const abortController = new AbortController();
  const signal = abortController.signal;

  const url = new URL(
    `${process.env.REACT_APP_API_EVENTOS}/DesdobramentoGrupamento/execute`,
  );

  const response = await axios.post(url.toString(), null, {
    signal,
    params: {
      idUsuario,
      corretora: corretora.toString(),
      idDesdobramentoGrupamento: idDesdobramentoGrupamento.toString(),
      autorizacao: autorizacao.toString(),
      precoMedio: !!precoMedio ? precoMedio.toString() : '0',
    },
  });

  return response.data;
}

export function useExecuteEventMutation(userId?: string) {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation(handleExecuteEvent, {
    onError: () => showToast(OPERATION_ACCEPTED_ERROR),
    onSuccess: (data, variables) => {
      const authorization = variables.autorizacao;

      if (authorization) {
        showToast(OPERATION_ACCEPTED_SUCCESS);
      } else {
        showToast(OPERATION_REJECTED_SUCCESS);
      }

      queryClient.invalidateQueries([
        'eventos',
        'DesdobramentoGrupamento',
        userId,
      ]);
    },
  });
}
