import axios from 'axios';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useCallback, useEffect, useState } from 'react';

import Usuario from '../../../../components/Usuario';
import {
  SEND_EMAIL_ERROR,
  SEND_EMAIL_SUCCESS,
} from '../../../../context/toast/constants';
import { useToast } from '../../../../context/toast/useToast';
import { useRequest } from '../../../../hooks/useRequest';
import { usuarios } from '../../../../service/ApiUsuarios/Subscriptions';
import { formataDataCelula } from '../../../../utils/functions';
import { Cartao } from '../Cartao';
import { RowExpensiveTable } from './RowExpensiveTable';
import { DESCRICAO_PLANO } from './constants';
import { CancelarRenovacaoDialog } from './dialogs';
import './meuplano.css';
import { Container, DialogFooter, SimpleButton } from './styles';

const getSubscricaoAtual = subscriptions =>
  subscriptions.find(subscription => subscription.status === 'ATIVO');

export function MeuPlano() {
  const [linhasExpandidas, setLinhasExpandidas] = useState(null);
  const [listaSubscriptions, setListaSubscriptions] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [first1, setFirst1] = useState(0);
  const [rows1, setRows1] = useState(5);

  const [deletarDialog, setDeletarDialog] = useState(false);
  const [cancelarRenovacaoDialog, setCancelarRenovacaoDialog] = useState(false);
  const [retornoStatus, setRetornoStatus] = useState('');
  const [retornoStatusErro, setRetornoStatusErro] = useState('');
  const [subscricaoAtual, setSubscricaoAtual] = useState({});

  const { showToast } = useToast();
  const [getSubscricoes, { loading }] = useRequest();

  const onGetSubscricoesPorUsuarioSuccess = useCallback(data => {
    setListaSubscriptions(data);
    setSubscricaoAtual(getSubscricaoAtual(data));
  }, []);

  const onGetSubscricoesPorUsuarioError = useCallback(() => {
    showToast();
  }, [showToast]);

  const getSubscricoesPorUsuario = useCallback(async () => {
    await getSubscricoes({
      config: {
        url: usuarios.subscriptions.getSubscriptionsByUserId(Usuario.id),
      },
      onSuccess: onGetSubscricoesPorUsuarioSuccess,
      onError: onGetSubscricoesPorUsuarioError,
    });
  }, [
    getSubscricoes,
    onGetSubscricoesPorUsuarioError,
    onGetSubscricoesPorUsuarioSuccess,
  ]);

  useEffect(() => {
    getSubscricoesPorUsuario();
  }, [getSubscricoesPorUsuario]);

  const onCustomPage1 = event => {
    setFirst1(event.first);
    setRows1(event.rows);
    setCurrentPage(event.page + 1);
  };

  const template2 = {
    layout:
      'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
    RowsPerPageDropdown: options => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 15, value: 15 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <>
          <span
            className="p-mx-1"
            style={{ color: 'var(--text-color)', userSelect: 'none' }}
          >
            Itens por página:{' '}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            appendTo={document.body}
          />
        </>
      );
    },
    CurrentPageReport: options => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            width: '120px',
            textAlign: 'center',
          }}
        >
          {options.first} - {options.last} de {options.totalRecords}
        </span>
      );
    },
  };

  const statusTemplate = rowData => {
    return (
      <span
        className={`status-${
          rowData.status ? rowData.status.toLowerCase() : ''
        }`}
      >
        {rowData.status}
      </span>
    );
  };

  const urlTrocarPlano = () => {
    let url = 'https://app.bussoladoinvestidor.com.br/nb/auth/login/v2';
    window.open(url);
  };

  const handleCancelarRenovacao = async selectedOption => {
    const data = {
      DataHoraInconcistencia: new Date().toLocaleString('sv-SE'),
      LoginUsuario: Usuario.username,
      Emailusuario: Usuario.email,
      PlanoUsuario: Usuario.payment.paymentPlan,
      MensagemDoUsuario: selectedOption,
      TipoInconcistencia: 'Cancelar Renovação',
      Titulo: 'Cancelamento de renovação',
      TituloCorpo: `E-mail de Cancelamento automático solicitado pelo usuário ${Usuario.nome}`,
    };

    const formData = new FormData();
    const jsonData = JSON.stringify(data);
    formData.append('jsonData', jsonData);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_USUARIOS}/EnvioEmailSistemico/v1/PostEmailInconcistenciaGenerico`,
        formData,
        config,
      );

      return true;
    } catch (error) {
      console.log(error);
      return false;
    } finally {
      setCancelarRenovacaoDialog(false);
    }
  };

  useEffect(() => {
    if (retornoStatus == 200) {
      showToast(SEND_EMAIL_SUCCESS);
    }
  }, [retornoStatus]);

  useEffect(() => {
    if (retornoStatusErro != 200 && retornoStatusErro != '') {
      showToast(SEND_EMAIL_ERROR);
    }
  }, [retornoStatusErro]);

  const modalExcluir = () => {
    setDeletarDialog(true);
  };

  const fecharModalExcluir = () => {
    setDeletarDialog(false);
  };

  const getDescricaoPlano = () => {
    if (subscricaoAtual?.plano) {
      const descricaoPlano = DESCRICAO_PLANO.get(
        subscricaoAtual.plano?.billingPlanCode,
      );

      if (descricaoPlano) return descricaoPlano;
    }

    return DESCRICAO_PLANO.get('gratis');
  };

  const getSubscricao = () => {
    if (subscricaoAtual && Object.keys(subscricaoAtual).length !== 0)
      return subscricaoAtual;

    return DESCRICAO_PLANO.get('gratis').subscricaoAtual;
  };

  return (
    <Container>
      <header>
        <h1>Meu plano</h1>
        <p>
          Verifique a vigencia do seu plano, seu pagamento e se necessário
          também é possível fazer a troca do seu plano.
        </p>
      </header>

      <main>
        {!loading && (
          <section className="container-card">
            <section className="container-card">
              <Cartao subscricaoAtual={getSubscricao()} />
            </section>

            <div className="plan-info">
              <p>
                O seu plano é: <strong>{getDescricaoPlano().titulo}</strong>
              </p>
              <p>{getDescricaoPlano().descricao}</p>

              <div className="container-buttons">
                <Button label="Trocar de plano" onClick={urlTrocarPlano} />
                {subscricaoAtual !== undefined &&
                  subscricaoAtual.plano?.billingPlanCode !== 'gratis' && (
                    <SimpleButton onClick={modalExcluir}>
                      Cancelar renovação
                    </SimpleButton>
                  )}
              </div>
            </div>
          </section>
        )}

        <Dialog
          header="Tem certeza que deseja cancelar renovação?"
          visible={deletarDialog}
          onHide={fecharModalExcluir}
          style={{ width: '40rem' }}
        >
          <p>
            Você confirma que deseja cancelar a renovação da sua assinatura?
            Esta ação não poderá ser desfeita.
          </p>

          <DialogFooter>
            <Button
              label="Cancelar"
              className="p-button-text botaoFechar"
              onClick={fecharModalExcluir}
            />
            <Button
              label="Prosseguir"
              onClick={() => {
                setDeletarDialog(false);
                setCancelarRenovacaoDialog(true);
              }}
            />
          </DialogFooter>
        </Dialog>
        <CancelarRenovacaoDialog
          visible={cancelarRenovacaoDialog}
          onHide={() => setCancelarRenovacaoDialog(false)}
          onCancelarRenovacao={handleCancelarRenovacao}
        />

        <section className="container-table">
          <div>
            <h2>Histórico de planos</h2>
            <p>Acompanhe a vigência de seus planos</p>
          </div>

          <DataTable
            value={listaSubscriptions}
            rowHover
            selection={listaSubscriptions}
            onSelectionChange={e => setListaSubscriptions(e.value)}
            paginator
            dataKey="id"
            emptyMessage="Lista Vazia."
            paginatorTemplate={template2}
            first={first1}
            rows={rows1}
            responsiveLayout="scroll"
            onPage={onCustomPage1}
            className="dataTable"
            rowExpansionTemplate={listaSubscriptions => (
              <RowExpensiveTable
                data={listaSubscriptions.invoices}
                columns={[
                  {
                    field: 'paymentMethod',
                    header: 'Forma pagamento',
                    className: 'corTextoTitulo',
                  },
                  {
                    field: 'price',
                    header: 'Valor',
                    className: 'corTextoTitulo',
                  },
                  {
                    field: 'dataCriacao',
                    header: 'Início',
                    className: 'corTextoTitulo',
                  },
                  {
                    field: 'dataCriacaoFim',
                    header: 'Expiração',
                    className: 'corTextoTitulo',
                  },
                  {
                    field: 'status',
                    header: 'Status',
                    className: 'corTextoTitulo',
                  },
                ]}
              />
            )}
            expandedRows={linhasExpandidas}
            onRowToggle={e => setLinhasExpandidas(e.data)}
          >
            <Column expander />
            <Column
              field="mesAno"
              header="Mes/Ano"
              className="corTextoTitulo"
            />
            <Column field="plan" header="Plano" className="corTextoTitulo" />
            <Column
              field="createdAt"
              header="Início"
              className="corTextoTitulo"
              body={formataDataCelula('createdAt')}
            />
            <Column
              field="expiresAt"
              header="Expiração"
              className="corTextoTitulo"
              body={formataDataCelula('expiresAt')}
            />
            <Column
              field="status"
              header="Status"
              body={statusTemplate}
              className="corTextoTitulo"
            />
          </DataTable>
        </section>
      </main>
    </Container>
  );
}
