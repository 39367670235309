import { apiOperacoes } from '../service/ApiOperacoes/api';
import Usuario from './Usuario';

async function posicoesUsuario() {
  try {
    const posicoes = await apiOperacoes.get(
      `PosicoesAberta/v1/GetPosicoesAbertaPorIdUsuario/${Usuario.id}`,
    );

    return posicoes;
  } catch (error) {
    console.error(error);

    return false;
  }
}

export default posicoesUsuario;
