const LINK_CENTRAL_AJUDA =
  'https://comunidadebussoladoinvestidor.zendesk.com/hc/pt-br/';

// Cadastro Inicial
export const CENTRAL_AJUDA_TIPO_DATA_UTILIZADO_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRsKwhw0w7UUwA6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBe2h0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMzYwMDQwMDI2OTkyLU8tcXVlLSVDMyVBOS1kYXRhLWRlLWxpcXVpZGElQzMlQTclQzMlQTNvLQY7CFQ6DnNlYXJjaF9pZEkiKWI2Yjc0YjQ4LWZkMWEtNDIyZi05ZGY0LTNkOTNhMGQ0Y2NjOAY7CEY6CXJhbmtpBjoLbG9jYWxlSSIKcHQtYnIGOwhUOgpxdWVyeUkiG2RhdGEgZGUgbGlxdWlkYcOnw6NvID8GOwhUOhJyZXN1bHRzX2NvdW50aR4%3D--9a8ee8ddd29ec4b82c82d4ac7309640bf70a489c`;

export const CENTRAL_AJUDA_DATA_REFERENCIA_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRpBHXdVww6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBfGh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMjA3MDg0OTE3LVByaW1laXJvcy1wYXNzb3MtcGFyYS11c2FyLWEtQ2FsY3VsYWRvcmEtZGUtSVIGOwhUOg5zZWFyY2hfaWRJIiljNzQxNTk5MS04YTIzLTQ0ZDUtOGI1Mi0yOGUyNTk2NDQ4NWIGOwhGOglyYW5raQY6C2xvY2FsZUkiCnB0LWJyBjsIVDoKcXVlcnlJIjNQcmltZWlyb3MgcGFzc29zIHBhcmEgdXNhciBhIENhbGN1bGFkb3JhIGRlIElSBjsIVDoScmVzdWx0c19jb3VudGke--bd9295c8406f74821ccc5ebcf986b4427be31f49`;

export const CENTRAL_AJUDA_POSICOES_INICIAIS_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRpBHXdVww6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBfGh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMjA3MDg0OTE3LVByaW1laXJvcy1wYXNzb3MtcGFyYS11c2FyLWEtQ2FsY3VsYWRvcmEtZGUtSVIGOwhUOg5zZWFyY2hfaWRJIiljNzQxNTk5MS04YTIzLTQ0ZDUtOGI1Mi0yOGUyNTk2NDQ4NWIGOwhGOglyYW5raQY6C2xvY2FsZUkiCnB0LWJyBjsIVDoKcXVlcnlJIjNQcmltZWlyb3MgcGFzc29zIHBhcmEgdXNhciBhIENhbGN1bGFkb3JhIGRlIElSBjsIVDoScmVzdWx0c19jb3VudGke--bd9295c8406f74821ccc5ebcf986b4427be31f49`;

export const CENTRAL_AJUDA_PREJUIZOS_ACUMULADOS_LINK = `${LINK_CENTRAL_AJUDA}articles/207084857-Como-configurar-preju%C3%ADzos-anteriores-%C3%A0s-opera%C3%A7%C3%B5es-cadastradas-na-plataforma-`;

export const CENTRAL_AJUDA_IRRF_ACUMULADO_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRsKwiHYBTUUwA6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSJoaHR0cHM6Ly9jb211bmlkYWRlYnVzc29sYWRvaW52ZXN0aWRvci56ZW5kZXNrLmNvbS9oYy9wdC1ici9hcnRpY2xlcy8zNjAwNDAzOTA3OTEtTy1xdWUtJUMzJUE5LUlSUkYtBjsIVDoOc2VhcmNoX2lkSSIpY2E4YWIwOGUtOTM1Mi00YTZlLWJlMTgtOGYwMmM4YjE1MTI3BjsIRjoJcmFua2kGOgtsb2NhbGVJIgpwdC1icgY7CFQ6CnF1ZXJ5SSISbyBxdWUgw6kgSVJSRgY7CFQ6EnJlc3VsdHNfY291bnRpAaE%3D--b546f38392be307049b4c3711dfd6a7eb1ad6c00`;

// Plataforma
export const CENTRAL_AJUDA_MINHA_CONTA_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRsKwg3clPTUwA6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSJpaHR0cHM6Ly9jb211bmlkYWRlYnVzc29sYWRvaW52ZXN0aWRvci56ZW5kZXNrLmNvbS9oYy9wdC1ici9hcnRpY2xlcy8zNjAwMjc3NDY4NzEtRXNxdWVjaS1taW5oYS1zZW5oYQY7CFQ6DnNlYXJjaF9pZEkiKTY0ZGFiNzgwLTMwNWYtNDVjZC04ZWRlLTNmZDI5M2I1Y2UxYgY7CEY6CXJhbmtpBjoLbG9jYWxlSSIKcHQtYnIGOwhUOgpxdWVyeUkiGEVzcXVlY2kgbWluaGEgc2VuaGEGOwhUOhJyZXN1bHRzX2NvdW50aQ0%3D--9430b9abc4e0568bc5a6d96eeab2f93b647d4c9e`;

export const CENTRAL_AJUDA_IMPORTACAO_NOTAS_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRpBO0jWAw6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSJ%2BaHR0cHM6Ly9jb211bmlkYWRlYnVzc29sYWRvaW52ZXN0aWRvci56ZW5kZXNrLmNvbS9oYy9wdC1ici9hcnRpY2xlcy8yMDcxMDI5NTctQ29tby1pbXBvcnRvLWFzLW1pbmhhcy1vcGVyYSVDMyVBNyVDMyVCNWVzLQY7CFQ6DnNlYXJjaF9pZEkiKWVlZjlmMzgyLWE0MjAtNDhhOS05ZTA0LTMzZmQxOWNiMDU4ZgY7CEY6CXJhbmtpBjoLbG9jYWxlSSIKcHQtYnIGOwhUOgpxdWVyeUkiKENvbW8gaW1wb3J0byBhcyBtaW5oYXMgb3BlcmHDp8O1ZXM%2FBjsIVDoScmVzdWx0c19jb3VudGlz--6be61304f75d7ad2e53782e958c30abbb01e30e8`;

export const CENTRAL_AJUDA_HISTORICO_NOTAS_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRpBM6UTQw6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBhWh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMjA2NDEwOTU4LUNvbW8tdmVyaWZpY28tc2UtbWluaGEtbm90YS1mb2ktaW1wb3J0YWRhLWNvcnJldGFtZW50ZS0GOwhUOg5zZWFyY2hfaWRJIik1ZjExZDBjMC1mODc0LTRkNTMtYjcxMi00ZWRiYjMzODg0YzEGOwhGOglyYW5raQY6C2xvY2FsZUkiCnB0LWJyBjsIVDoKcXVlcnlJIjxDb21vIHZlcmlmaWNvIHNlIG1pbmhhIG5vdGEgZm9pIGltcG9ydGFkYSBjb3JyZXRhbWVudGU%2FBjsIVDoScmVzdWx0c19jb3VudGkN--1d044e2cfc58f9a8566aca93045c088d707b215e`;

export const CENTRAL_AJUDA_HISTORICO_OPERACOES_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRpBFI%2BTQw6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBpGh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMjA2Mzg4ODE4LUNvbW8tZWRpdGFyLXVtYS1vcGVyYSVDMyVBNyVDMyVBM28tbmEtQ2FsY3VsYWRvcmEtZGUtSVItZGEtQiVDMyVCQXNzb2xhLWRvLUludmVzdGlkb3ItBjsIVDoOc2VhcmNoX2lkSSIpMGY5ZTBjMTUtOTc0Yy00MTQyLTlmZGQtYzUyNWY5M2U0NGJhBjsIRjoJcmFua2kGOgtsb2NhbGVJIgpwdC1icgY7CFQ6CnF1ZXJ5SSIgQ29tbyBlZGl0YXIgdW1hIG9wZXJhw6fDo28%2FBjsIVDoScmVzdWx0c19jb3VudGlU--e0f3bcadfede27875e27797160e8c351c8ced16e`;

export const CENTRAL_AJUDA_PROVENTOS_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRpBB1xWAw6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBlWh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMjA3MTIyNzE3LUNvbW8taW5mb3Jtby1xdWUtcmVjZWJpLWRpdmlkZW5kb3Mtb3UtSnVyb3Mtc29icmUtQ2FwaXRhbC1QciVDMyVCM3ByaW8tBjsIVDoOc2VhcmNoX2lkSSIpNDNmNTk3ZGEtZDY5ZC00OGM0LTg0NDctMGVjMmM3OWY3YTg0BjsIRjoJcmFua2kGOgtsb2NhbGVJIgpwdC1icgY7CFQ6CnF1ZXJ5SSJIQ29tbyBpbmZvcm1vIHF1ZSByZWNlYmkgZGl2aWRlbmRvcyBvdSBKdXJvcyBzb2JyZSBDYXBpdGFsIFByw7NwcmlvPwY7CFQ6EnJlc3VsdHNfY291bnRpHg%3D%3D--a5a4104ba03c59014e8859a78fd638b839a19c3d`;

export const CENTRAL_AJUDA_DESDOBRAMENTO_GRUPAMENTO_BONIFICACAO_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRpBCAbTQw6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBpWh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMjA2Mzc5ODA4LU8tcXVlLWZhJUMzJUE3by1jYXNvLW9jb3JyYS11bS1kZXNkb2JyYW1lbnRvLW91LWFncnVwYW1lbnRvLW5hcy1taW5oYXMtYSVDMyVBNyVDMyVCNWVzLQY7CFQ6DnNlYXJjaF9pZEkiKTY5NzJkYTM3LWU2ZTAtNDQyZi1iMDg1LTkyNGFmODgyYzJkMgY7CEY6CXJhbmtpBjoLbG9jYWxlSSIKcHQtYnIGOwhUOgpxdWVyeUkiE2Rlc2RvYnJhbWVudG9zBjsIVDoScmVzdWx0c19jb3VudGkK--bd3b4150fab2e0ac6d964cdbe56741271e267c3a`;

export const CENTRAL_AJUDA_ALTERACAO_CODIGO_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRsKwibtgpnBAQ6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBomh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvNDQxNjk1NTEzNTY0My1Db21vLWNhZGFzdHJhci1hbHRlcmElQzMlQTclQzMlQjVlcy1kZS1jJUMzJUIzZGlnb3MtZGUtYXRpdm9zLWRhLW1pbmhhLWNhcnRlaXJhLQY7CFQ6DnNlYXJjaF9pZEkiKTdjYzdkZjVhLWZiOGQtNDBiMS1hNzgwLTI2MGQ4M2Q2ZTkyYQY7CEY6CXJhbmtpBjoLbG9jYWxlSSIKcHQtYnIGOwhUOgpxdWVyeUkiSUNvbW8gY2FkYXN0cmFyIGFsdGVyYcOnw7VlcyBkZSBjw7NkaWdvcyBkZSBhdGl2b3MgZGEgbWluaGEgY2FydGVpcmE%2FBjsIVDoScmVzdWx0c19jb3VudGls--1b003100bec74a3eac89f763fe359e45b0a36c90`;

export const CENTRAL_AJUDA_CADASTRO_IPO_SUBSCRICAO_LINK = `${LINK_CENTRAL_AJUDA}articles/206502418-Como-cadastro-a-compra-de-uma-a%C3%A7%C3%A3o-em-IPO-`;

export const CENTRAL_AJUDA_GRAFICO_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRpBI%2BUWQw6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBlWh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMjA3MTk3MzI3LUNvbW8taW5zaXJvLW91LWVkaXRvLWluZGljYWRvcmVzLWdyJUMzJUExZmljb3MtZS10JUMzJUE5Y25pY29zLWVzdHVkb3MtBjsIVDoOc2VhcmNoX2lkSSIpYTdjY2FiNTUtMjNlNC00ZDJjLTlhNzItZGM3NTU3MDFkNDc0BjsIRjoJcmFua2kGOgtsb2NhbGVJIgpwdC1icgY7CFQ6CnF1ZXJ5SSJSQ29tbyBpbnNlcmlyLCBmb3JtYXRhciBvdSBhcGFnYXIgaW5kaWNhZG9yZXMgZ3LDoWZpY29zIGUgdMOpY25pY29zIChlc3R1ZG9zKT8GOwhUOhJyZXN1bHRzX2NvdW50aSY%3D--ed098651708a740dc89f91bbfb5b108f18eaa032`;

export const CENTRAL_AJUDA_EMITIR_DARF_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRsKwjrYBTUUwA6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSJoaHR0cHM6Ly9jb211bmlkYWRlYnVzc29sYWRvaW52ZXN0aWRvci56ZW5kZXNrLmNvbS9oYy9wdC1ici9hcnRpY2xlcy8zNjAwNDAzOTA4OTEtTy1xdWUtJUMzJUE5LURBUkYtBjsIVDoOc2VhcmNoX2lkSSIpN2M1NzQ2ZjctZTYzMy00MTk0LWIwYWItN2E0OTNjYmFjZGZkBjsIRjoJcmFua2kGOgtsb2NhbGVJIgpwdC1icgY7CFQ6CnF1ZXJ5SSISbyBxdWUgw6kgRGFyZgY7CFQ6EnJlc3VsdHNfY291bnRpAaE%3D--a72dfe6c0a1166d029055764b9a32aa2e3ff363e`;

export const CENTRAL_AJUDA_CONFIRMAR_PAGAMENTO_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRsKwjkE7TUUwA6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBfGh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMzYwMDUwODU2OTMyLVBvc3NvLXBhZ2FyLXVtLURBUkYtYXAlQzMlQjNzLW8tdmVuY2ltZW50by0GOwhUOg5zZWFyY2hfaWRJIikxMzBiMTc3Ni04MGI4LTQzOTktOWZmYy1iODM4Y2Q5NjdiNzgGOwhGOglyYW5raQY6C2xvY2FsZUkiCnB0LWJyBjsIVDoKcXVlcnlJIixQb3NzbyBwYWdhciB1bSBEQVJGIGFww7NzIG8gdmVuY2ltZW50bz8GOwhUOhJyZXN1bHRzX2NvdW50aSc%3D--4d6ca774cd3a242794a03d08ddd4a42797618292`;

export const CENTRAL_AJUDA_BENS_DIREITO_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRpBKIBTgw6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBlGh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMjA2NDM4ODE4LU8tc2lzdGVtYS0lQzMlQTktY29tcGF0JUMzJUFEdmVsLWNvbS1vcGVyYSVDMyVBNyVDMyVCNWVzLSVDMyVBMC10ZXJtby0GOwhUOg5zZWFyY2hfaWRJIilmYTJlNDllNy05N2E5LTQyZGQtODI1Yi1jNTkzMzg5ZDhhYmIGOwhGOglyYW5raQY6C2xvY2FsZUkiCnB0LWJyBjsIVDoKcXVlcnlJIjZPIHNpc3RlbWEgw6kgY29tcGF0w612ZWwgY29tIG9wZXJhw6fDtWVzIGEgdGVybW8%2FBjsIVDoScmVzdWx0c19jb3VudGl%2F--3942b75cc16898d5a83030e893354418601d31ff`;

export const CENTRAL_AJUDA_OPERACOES_COMUM_DAYTRADE_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRpBKIBTgw6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBlGh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMjA2NDM4ODE4LU8tc2lzdGVtYS0lQzMlQTktY29tcGF0JUMzJUFEdmVsLWNvbS1vcGVyYSVDMyVBNyVDMyVCNWVzLSVDMyVBMC10ZXJtby0GOwhUOg5zZWFyY2hfaWRJIilmYTJlNDllNy05N2E5LTQyZGQtODI1Yi1jNTkzMzg5ZDhhYmIGOwhGOglyYW5raQY6C2xvY2FsZUkiCnB0LWJyBjsIVDoKcXVlcnlJIjZPIHNpc3RlbWEgw6kgY29tcGF0w612ZWwgY29tIG9wZXJhw6fDtWVzIGEgdGVybW8%2FBjsIVDoScmVzdWx0c19jb3VudGl%2F--3942b75cc16898d5a83030e893354418601d31ff`;

export const CENTRAL_AJUDA_FUNDOS_IMOBILIARIOS_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRpBKIBTgw6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBlGh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMjA2NDM4ODE4LU8tc2lzdGVtYS0lQzMlQTktY29tcGF0JUMzJUFEdmVsLWNvbS1vcGVyYSVDMyVBNyVDMyVCNWVzLSVDMyVBMC10ZXJtby0GOwhUOg5zZWFyY2hfaWRJIilmYTJlNDllNy05N2E5LTQyZGQtODI1Yi1jNTkzMzg5ZDhhYmIGOwhGOglyYW5raQY6C2xvY2FsZUkiCnB0LWJyBjsIVDoKcXVlcnlJIjZPIHNpc3RlbWEgw6kgY29tcGF0w612ZWwgY29tIG9wZXJhw6fDtWVzIGEgdGVybW8%2FBjsIVDoScmVzdWx0c19jb3VudGl%2F--3942b75cc16898d5a83030e893354418601d31ff`;

export const CENTRAL_AJUDA_RENDIMENTOS_ISENTOS_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRpBKIBTgw6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBlGh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMjA2NDM4ODE4LU8tc2lzdGVtYS0lQzMlQTktY29tcGF0JUMzJUFEdmVsLWNvbS1vcGVyYSVDMyVBNyVDMyVCNWVzLSVDMyVBMC10ZXJtby0GOwhUOg5zZWFyY2hfaWRJIilmYTJlNDllNy05N2E5LTQyZGQtODI1Yi1jNTkzMzg5ZDhhYmIGOwhGOglyYW5raQY6C2xvY2FsZUkiCnB0LWJyBjsIVDoKcXVlcnlJIjZPIHNpc3RlbWEgw6kgY29tcGF0w612ZWwgY29tIG9wZXJhw6fDtWVzIGEgdGVybW8%2FBjsIVDoScmVzdWx0c19jb3VudGl%2F--3942b75cc16898d5a83030e893354418601d31ff`;

export const CENTRAL_AJUDA_LINK_RTD_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRpBKb3Tgw6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBkGh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMjA2NTAxNzk4LUNvbW8taW5zdGFsYXItZS11dGlsaXphci1vLUxpbmstUlRELWRhLUIlQzMlQkFzc29sYS1kby1JbnZlc3RpZG9yLQY7CFQ6DnNlYXJjaF9pZEkiKWI5MWJkY2JmLTM4NjEtNDk5MS05YWRlLTUwMTZkMDhhMzJkOQY7CEY6CXJhbmtpBjoLbG9jYWxlSSIKcHQtYnIGOwhUOgpxdWVyeUkiQ0NvbW8gaW5zdGFsYXIgZSB1dGlsaXphciBvIExpbmsgUlREIGRhIELDunNzb2xhIGRvIEludmVzdGlkb3I%2FBjsIVDoScmVzdWx0c19jb3VudGll--9c960a5b33ade575b999b2879c56a1c049909f0b`;

export const CENTRAL_AJUDA_RENDIMENTOS_TRIBUTACAO_EXCLUSIVA_LINK = `${LINK_CENTRAL_AJUDA}search/click?data=BAh7DjoHaWRpBKIBTgw6D2FjY291bnRfaWRpA5HXAjoJdHlwZUkiDGFydGljbGUGOgZFVDoIdXJsSSIBlGh0dHBzOi8vY29tdW5pZGFkZWJ1c3NvbGFkb2ludmVzdGlkb3IuemVuZGVzay5jb20vaGMvcHQtYnIvYXJ0aWNsZXMvMjA2NDM4ODE4LU8tc2lzdGVtYS0lQzMlQTktY29tcGF0JUMzJUFEdmVsLWNvbS1vcGVyYSVDMyVBNyVDMyVCNWVzLSVDMyVBMC10ZXJtby0GOwhUOg5zZWFyY2hfaWRJIilmYTJlNDllNy05N2E5LTQyZGQtODI1Yi1jNTkzMzg5ZDhhYmIGOwhGOglyYW5raQY6C2xvY2FsZUkiCnB0LWJyBjsIVDoKcXVlcnlJIjZPIHNpc3RlbWEgw6kgY29tcGF0w612ZWwgY29tIG9wZXJhw6fDtWVzIGEgdGVybW8%2FBjsIVDoScmVzdWx0c19jb3VudGl%2F--3942b75cc16898d5a83030e893354418601d31ff`;
