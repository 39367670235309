import axios from 'axios';
import { useCallback, useState } from 'react';

export const useRequest = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const lazyRequest = useCallback(async ({ config, onSuccess, onError }) => {
    const controller = new AbortController();
    const configWithCancelToken = { ...config, signal: controller.signal };

    try {
      setLoading(true);
      setError(null);
      setData(null);

      const response = await axios(config, configWithCancelToken);

      setData(response.data);
      onSuccess && onSuccess(response.data);

      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);

      onError && onError(error);
      controller.abort();
      console.error(error);
    }
  }, []);

  return [lazyRequest, { data, loading, error }];
};
