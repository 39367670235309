import classNames from 'classnames';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useEffect, useRef, useState } from 'react';

import {
  DELETE_EARNINGS_ERROR,
  DELETE_EARNINGS_SUCCESS,
  EDIT_EARNINGS_ERROR,
  EDIT_EARNINGS_SUCCESS,
  SAVE_EARNINGS_ERROR,
  SAVE_EARNINGS_SUCCESS,
} from '../../context/toast/constants';
import { useToast } from '../../context/toast/useToast';
import apiSincronizaCache from '../../service/apiSincronizaCache';
import { BaseModule } from '../BaseModule';
import { PROVENTOS } from '../BaseModule/constants';
import Usuario from '../Usuario';
import api from './api';
import './proventos.css';
import * as S from './styles';

export const Proventos = () => {
  const [productDialog, setProductDialog] = useState(false);
  const [editarDialog, setEditarDialog] = useState(false);
  const toast = useRef(null);
  const [listaStatus, setListaStatus] = useState([]);
  const [editarProventos, setEditarProvento] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [dataInicio, setVal3] = useState();
  const [ticker, setTicker] = useState(null);
  const [valorLiquido, setValorLiquido] = useState(null);
  const [tipoprovento, setProvento] = useState(null);
  const [listaProventos, setListaProventos] = useState([]);
  const [corretora, setCorretora] = useState(null);
  const [listaCorretoras, setListaCorretoras] = useState([]);
  const [erroDados1, setErroDados1] = useState(true);
  const [erroDados2, setErroDados2] = useState(false);
  const [respostaGetinstrumentos, SetRespostaGetinstrumentos] = useState([]);
  const [listainstrumentos, setListainstrumentos] = useState([]);
  const [listaInstrumentosLocais, setListaInstrumentosLocais] = useState([]);
  /* #region Paginação Itens por página */
  //Paginação Itens por página
  const [first1, setFirst1] = useState(0);
  const [rows1, setRows1] = useState(5);

  const { showToast } = useToast();

  const onCustomPage1 = event => {
    setFirst1(event.first);
    setRows1(event.rows);
  };
  const template2 = {
    layout:
      'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
    RowsPerPageDropdown: options => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 15, value: 15 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <>
          <span
            className="p-mx-1"
            style={{ color: 'var(--text-color)', userSelect: 'none' }}
          >
            Itens por página:{' '}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            appendTo={document.body}
          />
        </>
      );
    },
    CurrentPageReport: options => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            width: '120px',
            textAlign: 'center',
          }}
        >
          {options.first} - {options.last} de {options.totalRecords}
        </span>
      );
    },
  };

  //FIM Paginação Itens por página
  /* #endregion */

  /* #region LISTA de PROVENTOS */
  //LISTA de PROVENTOS
  function loadListUser(data) {
    const newListUser = data.map(user => {
      return {
        name: user.nome,
        code: user.idTipoProvento,
      };
    });
    setListaProventos(newListUser);
  }

  useEffect(() => {
    async function createListUser() {
      fetch(`${process.env.REACT_APP_API_OPERACOES}/TipoProvento`)
        .then(result => result.json())
        .then(data => {
          loadListUser(data);
        })
        .catch(error => {
          console.error(error);
          showToast();
        });
    }
    createListUser();
  }, []);
  //FIM LISTA de PROVENTOS
  /* #endregion */

  /* #region LISTA de CORRETORAS */
  //LISTA de CORRETORAS
  function loadListCorretoras(data) {
    const newListUser = data.map(user => {
      return {
        name: user.apelido,
        code: user.idCorretora,
      };
    });
    setListaCorretoras(newListUser);
  }

  useEffect(() => {
    async function createListCorretoras() {
      fetch(`${process.env.REACT_APP_API_OPERACOES}/Corretoras`)
        .then(result => result.json())
        .then(data => {
          loadListCorretoras(data);
        })
        .catch(error => {
          console.error(error);
          showToast();
        });
    }
    createListCorretoras();
  }, []);
  //FIM LISTA de CORRETORAS
  /* #endregion */

  const consultaInstrumentos = async event => {
    const query = event && event.query.toUpperCase();

    async function ExecutaRequestBuscaInstrumento() {
      try {
        const response = await apiSincronizaCache.get(
          'v1/GetBuscaInstrumentosAll/' + query,
        );

        SetRespostaGetinstrumentos(response.data);

        const filterList = response.data.instrumentos.filter(dado => {
          return dado.ticker;
        });

        const obj = filterList.map(dado => {
          return {
            name: dado.ticker,
            code: dado.idInstrumento,
          };
        });

        setListainstrumentos(obj);
      } catch (error) {
        console.error(error);
        showToast();
      }
    }

    if (query !== null || query !== undefined) {
      if (query.length >= 3) {
        ExecutaRequestBuscaInstrumento();
      } else {
        if (
          respostaGetinstrumentos !== null &&
          respostaGetinstrumentos !== undefined
        ) {
          if (
            respostaGetinstrumentos.instrumentos !== null &&
            respostaGetinstrumentos.instrumentos !== undefined
          ) {
            if (respostaGetinstrumentos.instrumentos.length > 0) {
              const filterList = respostaGetinstrumentos.instrumentos.filter(
                dado => {
                  return dado.ticker.toUpperCase().indexOf(query) === 0;
                },
              );
              if (filterList.length > 0) {
                const obj = filterList.map(dado => {
                  return {
                    name: dado.ticker,
                    code: dado.idInstrumento,
                  };
                });

                setListainstrumentos(obj);
              } else {
                ExecutaRequestBuscaInstrumento();
              }
            } else {
              ExecutaRequestBuscaInstrumento();
            }
          } else {
            ExecutaRequestBuscaInstrumento();
          }
        } else {
          ExecutaRequestBuscaInstrumento();
        }
      }
    }
  };

  const consultaInstrumentoId = async id => {
    if (id !== null || id !== undefined) {
      var instrumentoEncontrado = listaInstrumentosLocais.filter(
        e => e.idInstrumento === id,
      );
      let response = instrumentoEncontrado[0];
      if (response !== null || response !== undefined || response !== '') {
        setTicker({ name: response.ticker, code: response.idInstrumento });
      }
    }
  };

  /* #region GET API de Proventos por IDUsuario - Listagem na página principal */
  //Consultar dados GET API de Proventos por IDUsuario - Listagem na página principal
  function loadListStatus(data) {
    const listaInstrumentos = [];
    const newListStatus = data.map(sp => {
      if (
        listaInstrumentos.filter(
          e => e.idInstrumento === sp.idInstrumentoNavigation.idInstrumento,
        ).length === 0
      ) {
        listaInstrumentos.push(sp.idInstrumentoNavigation);
      }

      return {
        idProvento: sp.idProvento,
        idUsuario: sp.idUsuario,
        idCorretora: sp.corretora.apelido,
        idInstrumento: sp.idInstrumentoNavigation.ticker,
        numeroidTicker: sp.idInstrumento,
        idTipoprovento: sp.tipoProvento.nome,
        dataInicio: new Date(sp.dataInicio)
          .toLocaleString('pt-BR')
          .substring(0, 10),
        valorLiquido: sp.valorLiquido.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        dataCadastro: new Date(sp.dataCadastro).toLocaleString('pt-BR'),
      };
    });

    setListaInstrumentosLocais(listaInstrumentos);
    setListaStatus(newListStatus);
  }

  //Consultar dados GET API de Proventos por Usuário
  useEffect(() => {
    async function createListStatus() {
      let id = Usuario.id;
      fetch(
        `${process.env.REACT_APP_API_USUARIOS}/Provento/v1/GetProventosByIdUsuario/${id}`,
      )
        .then(result => result.json())
        .then(data => {
          loadListStatus(data);
        });
    }
    createListStatus();
  }, []);

  // FIM Consultar dados GET API de Proventos por Usuário
  /* #endregion */

  async function testeGetProvento(idProvento) {
    let provento = listaStatus.filter(
      status => status.idProvento === idProvento,
    );
    provento[0].valorLiquido = parseFloat(
      provento[0].valorLiquido.replace(',', '.').replace('R$', ''),
    ).toFixed(2);

    let corretoraEditar = listaCorretoras.filter(
      corretora => corretora.name === provento[0].idCorretora,
    );
    let tipoProventoEditar = listaProventos.filter(
      tipoprovento => tipoprovento.name === provento[0].idTipoprovento,
    );

    // let tickerEditar = listainstrumentos.filter(
    //   (ticker) => ticker.name === provento[0].idInstrumento
    // );

    consultaInstrumentoId(provento[0].numeroidTicker);

    setCorretora(corretoraEditar[0]);
    setProvento(tipoProventoEditar[0]);
    setEditarProvento(provento[0]);
  }

  /* #region SALVAR PROVENTO */
  //SALVAR PROVENTO
  async function handleSubmit() {
    setErroDados1('');
    setErroDados2('');
    setSubmitted(true);

    if (
      tipoprovento == null ||
      corretora == null ||
      valorLiquido == null ||
      dataInicio == null ||
      ticker == null
    ) {
      setProductDialog(true);
      setErroDados1(false);
      setErroDados2(true);
    } else {
      var dataHoje = new Date().toLocaleString('sv-SE');
      var dataHojeFormatada = dataHoje.split('').slice(0, 10).join('');

      var datai = dataInicio;
      var ano = datai.split('').slice(6, 10).join('');
      var mes = datai.split('').slice(3, 5).join('');
      var dia = datai.split('').slice(0, 2).join('');

      var dataInicioFormatada = ano + '-' + mes + '-' + dia;

      let id = Usuario.id;

      try {
        await api.post(
          `${process.env.REACT_APP_API_USUARIOS}/Provento/v1/PostProvento`,
          {
            idUsuario: id,
            idCorretora: corretora.code,
            idInstrumento: ticker.code,
            idTipoprovento: tipoprovento.code,
            dataInicio: dataInicioFormatada,
            valorLiquido: valorLiquido,
            dataCadastro: dataHojeFormatada,
          },
        );

        setProductDialog(false);
        setCorretora(null);
        setVal3();
        setTicker(null);
        setValorLiquido(null);
        setProvento(null);
        setErroDados1(true);
        setErroDados2(false);

        showToast(SAVE_EARNINGS_SUCCESS);
      } catch (error) {
        console.error(error);
        showToast(SAVE_EARNINGS_ERROR);
      }
    }
    async function createListStatus() {
      let id = Usuario.id;
      fetch(
        `${process.env.REACT_APP_API_USUARIOS}/Provento/v1/GetProventosByIdUsuario/${id}`,
      )
        .then(result => result.json())
        .then(data => {
          loadListStatus(data);
        })
        .catch(error => {
          console.error(error);
          showToast();
        });
    }
    createListStatus();
  }

  // FIM SALVAR PROVENTO
  /* #endregion */

  /* #region DELETAR PROVENTO */
  //DELETAR PROVENTO
  async function excluirProvento(idProvento) {
    try {
      await api.delete(
        `${process.env.REACT_APP_API_USUARIOS}/Provento/v1/DeleteProvento/${idProvento}`,
        {},
      );

      showToast(DELETE_EARNINGS_SUCCESS);
    } catch (error) {
      console.error(error);
      showToast(DELETE_EARNINGS_ERROR);
    }

    async function createListStatus() {
      let id = Usuario.id;
      fetch(
        `${process.env.REACT_APP_API_USUARIOS}/Provento/v1/GetProventosByIdUsuario/${id}`,
      )
        .then(result => result.json())
        .then(data => {
          loadListStatus(data);
        })
        .catch(() => {
          setListaStatus([]);
        });
    }

    createListStatus();
  }

  // FIM DELETAR PROVENTO
  /* #endregion */

  //EDITAR PROVENTOS PUT
  async function editarMetodoProvento(provento) {
    setErroDados1('');
    setErroDados2('');

    setSubmitted(true);

    if (
      tipoprovento.code == null ||
      corretora.code == null ||
      provento.valorLiquido == null ||
      provento.dataInicio == null ||
      ticker.code == null
    ) {
      setEditarDialog(true);
      setErroDados1(false);
      setErroDados2(true);
    } else {
      var dataHoje = new Date().toLocaleString('sv-SE');
      var dataHojeFormatada = dataHoje.split('').slice(0, 10).join('');

      var datai = provento.dataInicio;
      var ano = datai.split('').slice(6, 10).join('');
      var mes = datai.split('').slice(3, 5).join('');
      var dia = datai.split('').slice(0, 2).join('');

      var dataInicioFormatada = ano + '-' + mes + '-' + dia;

      try {
        await api.put(
          `${process.env.REACT_APP_API_USUARIOS}/provento/v1/PutProvento`,
          {
            idProvento: provento.idProvento,
            idUsuario: provento.idUsuario,
            idCorretora: corretora.code,
            idInstrumento: ticker.code,
            idTipoprovento: tipoprovento.code,
            dataInicio: dataInicioFormatada,
            valorLiquido: provento.valorLiquido,
            dataCadastro: dataHojeFormatada,
          },
        );

        setEditarDialog(false);
        setCorretora(null);
        //setTicker2(null);
        setVal3();
        setTicker(null);
        setValorLiquido(null);
        setProvento(null);
        setErroDados1(true);
        setErroDados2(false);
        showToast(EDIT_EARNINGS_SUCCESS);
      } catch (error) {
        console.error(error);
        showToast(EDIT_EARNINGS_ERROR);
      }
    }
    async function createListStatus() {
      let id = Usuario.id;
      fetch(
        `${process.env.REACT_APP_API_USUARIOS}/Provento/v1/GetProventosByIdUsuario/${id}`,
      )
        .then(result => result.json())
        .then(data => {
          loadListStatus(data);
        })
        .catch(error => {
          console.error(error);
          showToast();
        });
    }
    createListStatus();
  }

  // FIM EDITAR PROVENTOS

  const openNew = () => {
    setSubmitted(false);
    setProductDialog(true);
  };

  const editarProvento = idProvento => {
    testeGetProvento(idProvento);
    setSubmitted(false);
    setEditarDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
    setErroDados1('');
    setErroDados2('');
    setErroDados1(true);
    setErroDados2(false);
    setCorretora(null);
    setVal3();
    setTicker(null);
    setValorLiquido(null);
    setProvento(null);
  };

  const fecharDialog = () => {
    setSubmitted(false);
    setEditarDialog(false);
    setCorretora(null);
    setVal3();
    setTicker(null);
    setValorLiquido(null);
    setProvento(null);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Cadastrar Provento"
          style={{ top: '3px' }}
          className="p-button-default p-mr-2 p-mb-2"
          onClick={openNew}
        />
      </React.Fragment>
    );
  };

  const productDialogFooter = (
    <>
      <Button
        label="Cancelar"
        className="p-button-outlined p-button-info p-mr-2 p-mb-2 fontBotaoCancelar"
        onClick={hideDialog}
      />
      <Button
        label="Salvar"
        className="p-button-severities p-button-primary p-mr-2 p-mb-2"
        onClick={handleSubmit}
      />
    </>
  );

  const editarDialogFooter = provento => {
    return (
      <React.Fragment>
        <Button
          label="Cancelar"
          className="p-button-outlined p-button-info p-mr-2 p-mb-2 fontBotaoCancelar"
          onClick={fecharDialog}
        />
        <Button
          label="Salvar"
          className="p-button-severities p-button-primary p-mr-2 p-mb-2"
          onClick={() => editarMetodoProvento(provento)}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = idProvento => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2 botoesAcoes"
          onClick={() => editarProvento(idProvento.idProvento)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => excluirProvento(idProvento.idProvento)}
        />
      </React.Fragment>
    );
  };

  return (
    <BaseModule tipo={PROVENTOS}>
      <Toolbar
        className="p-mb-4"
        left={leftToolbarTemplate}
        style={{
          border: '2px solid rgba(83, 195, 194, 0.42)',
          boxSizing: 'border-box',
          padding: '10px',
          borderRadius: '5px',
        }}
      ></Toolbar>

      <div className="p-grid table-demo">
        <div className="p-col-12">
          <S.ProventosTableContainer className="">
            <Toast ref={toast} />
            <DataTable
              value={listaStatus}
              rowHover
              selection={listaStatus}
              onSelectionChange={e => setListaStatus(e.value)}
              paginator
              dataKey="id"
              emptyMessage="Lista Vazia."
              paginatorTemplate={template2}
              first={first1}
              rows={rows1}
              onPage={onCustomPage1}
              className="dataTable"
            >
              <Column
                className="tituloColunasLista"
                field="idCorretora"
                header="Corretora"
                sortable
              ></Column>
              <Column
                className="tituloColunasLista"
                field="dataInicio"
                header="Data Pagamento"
                sortable
              ></Column>
              <Column
                className="tituloColunasLista"
                field="idTipoprovento"
                header="Tipo de provento"
                sortable
              ></Column>
              <Column
                className="tituloColunasLista"
                field="idInstrumento"
                header="Ticker"
                sortable
              ></Column>
              <Column
                className="tituloColunasLista"
                field="valorLiquido"
                header="Valor líquido"
                sortable
              ></Column>
              <Column body={actionBodyTemplate}></Column>
            </DataTable>
          </S.ProventosTableContainer>
        </div>
      </div>

      <Dialog
        visible={productDialog}
        style={{ width: 'min(40rem, 80vw)' }}
        header="Provento"
        blockScroll={false}
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
        modal
      >
        <div
          style={{
            border: '2px solid rgba(83, 195, 194, 0.42)',
            boxSizing: 'border-box',
            padding: '10px',
          }}
          className="modalContainer"
        >
          <div className="p-formgrid p-grid">
            <div className="p-field p-col">
              <label htmlFor="corretora" className="subTitulosDentroModal">
                Corretora*
              </label>
              <Dropdown
                id="corretora"
                value={corretora}
                onChange={e => setCorretora(e.value)}
                options={listaCorretoras}
                optionLabel="name"
                filter
                placeholder="Selecione a corretora"
                style={{ display: erroDados1 ? 'flex' : 'none' }}
              ></Dropdown>
              <Dropdown
                id="corretora"
                value={corretora}
                onChange={e => setCorretora(e.value)}
                options={listaCorretoras}
                optionLabel="name"
                filter
                placeholder="Selecione a corretora"
                className={classNames({
                  'p-invalid erroDropdown': submitted && !corretora,
                })}
                style={{ display: erroDados2 ? 'flex' : 'none' }}
              ></Dropdown>
              {submitted && !corretora && (
                <small className="p-invalid">Corretora é obrigatório.</small>
              )}
            </div>
          </div>
          <div className="p-formgrid p-grid">
            <div className="p-field p-col">
              <label htmlFor="dataInicio" className="subTitulosDentroModal">
                Data de recebimento*
              </label>
              <InputMask
                id="dataInicio"
                mask="99-99-9999"
                value={dataInicio}
                placeholder="99-99-9999"
                slotChar="dd/mm/yyyy"
                onChange={e => setVal3(e.value)}
                className={classNames({
                  'p-invalid': submitted && !dataInicio,
                })}
              ></InputMask>

              {submitted && !dataInicio && (
                <small className="p-invalid">Data é obrigatório.</small>
              )}
            </div>
            <div className="p-field p-col">
              <label htmlFor="provento" className="subTitulosDentroModal">
                Tipo do provento*
              </label>
              <Dropdown
                id="provento"
                value={tipoprovento}
                onChange={e => setProvento(e.value)}
                options={listaProventos}
                optionLabel="name"
                filter
                placeholder="Selecione o tipo de provento"
                style={{ display: erroDados1 ? 'flex' : 'none' }}
              ></Dropdown>
              <Dropdown
                id="provento"
                value={tipoprovento}
                onChange={e => setProvento(e.value)}
                options={listaProventos}
                optionLabel="name"
                filter
                placeholder="Selecione o tipo de provento"
                className={classNames({
                  'p-invalid erroDropdown': submitted && !tipoprovento,
                })}
                style={{ display: erroDados2 ? 'flex' : 'none' }}
              ></Dropdown>
              {submitted && !tipoprovento && (
                <small className="p-invalid">Provento é obrigatório.</small>
              )}
            </div>
          </div>
          <div className="p-formgrid p-grid">
            <div className="p-field p-col">
              <label htmlFor="ticker" className="subTitulosDentroModal">
                Ticker*
              </label>
              {
                <AutoComplete
                  id="ticker"
                  placeholder="Espec. de título*"
                  forceSelection
                  value={ticker}
                  suggestions={listainstrumentos}
                  completeMethod={consultaInstrumentos}
                  field="name"
                  onChange={e => setTicker(e.value)}
                />
              }
              {submitted && !ticker && (
                <small className="p-invalid">Ticker é obrigatório.</small>
              )}
            </div>
            <div className="p-field p-col">
              <label htmlFor="ValorLiquido" className="subTitulosDentroModal">
                Valor líquido*
              </label>
              <InputNumber
                id="valorLiquido"
                required
                value={valorLiquido}
                mode="decimal"
                minFractionDigits={2}
                inputId="locale-user"
                onChange={e => setValorLiquido(e.value)}
                style={{ display: erroDados1 ? 'inline-flex' : 'none' }}
              />
              <InputNumber
                id="valorLiquido"
                required
                value={valorLiquido}
                mode="decimal"
                minFractionDigits={2}
                inputId="locale-user"
                onChange={e => setValorLiquido(e.value)}
                className={classNames({
                  'p-invalid erroInputValorLiquido': submitted && !valorLiquido,
                })}
                style={{ display: erroDados2 ? 'inline-flex' : 'none' }}
              />
              {submitted && !valorLiquido && (
                <small className="p-invalid erroInputValorLiquido">
                  Valor Líquido é obrigatório.
                </small>
              )}
            </div>
          </div>
        </div>
        <br />
        <p
          style={{ display: erroDados1 ? 'block' : 'none' }}
          className="subTitulosDentroModal"
        >
          *Preenchimento obrigatório
        </p>
        <p
          style={{ display: erroDados2 ? 'block' : 'none' }}
          className="erroPreenchimento"
        >
          *Preenchimento obrigatório
        </p>
      </Dialog>

      <Dialog
        visible={editarDialog}
        style={{ width: 'min(40rem, 80vw)' }}
        header="Editar Provento"
        blockScroll={false}
        className="p-fluid"
        footer={editarDialogFooter(editarProventos)}
        onHide={fecharDialog}
        modal
      >
        <div
          style={{
            border: '2px solid rgba(83, 195, 194, 0.42)',
            boxSizing: 'border-box',
            padding: '10px',
          }}
          className="modalContainer"
        >
          <div className="p-formgrid p-grid">
            <div className="p-field p-col">
              <label htmlFor="corretora" className="subTitulosDentroModal">
                Corretora*
              </label>
              <Dropdown
                id="corretora"
                onChange={e => setCorretora(e.value)}
                options={listaCorretoras}
                optionLabel="name"
                value={corretora}
                filter
                placeholder="Selecione a corretora"
                className={classNames({
                  'p-invalid': submitted && !corretora,
                })}
              ></Dropdown>
              {submitted && !corretora && (
                <small className="p-invalid">Corretora é obrigatório.</small>
              )}
            </div>
          </div>

          <div className="p-formgrid p-grid">
            <div className="p-field p-col">
              <label htmlFor="dataInicio" className="subTitulosDentroModal">
                Data de recebimento*
              </label>
              <InputMask
                id="dataInicio"
                mask="99-99-9999"
                placeholder="99-99-9999"
                slotChar="dd/mm/yyyy"
                value={editarProventos.dataInicio}
                onChange={value => (editarProventos.dataInicio = value.value)}
                className={classNames({
                  'p-invalid': submitted && !editarProventos.dataInicio,
                })}
              ></InputMask>

              {submitted && !editarProventos.dataInicio && (
                <small className="p-invalid">Data é obrigatório.</small>
              )}
            </div>
            <div className="p-field p-col">
              <label htmlFor="provento" className="subTitulosDentroModal">
                Tipo do provento*
              </label>
              <Dropdown
                id="provento"
                onChange={e => setProvento(e.value)}
                value={tipoprovento}
                options={listaProventos}
                optionLabel="name"
                filter
                placeholder="Selecione o tipo de provento"
                className={classNames({
                  'p-invalid': submitted && !tipoprovento,
                })}
              ></Dropdown>
              {submitted && !tipoprovento && (
                <small className="p-invalid">Provento é obrigatório.</small>
              )}
            </div>
          </div>
          <div className="p-formgrid p-grid">
            <div className="p-field p-col">
              <label htmlFor="ticker" className="subTitulosDentroModal">
                Ticker*
              </label>
              <AutoComplete
                id="ticker"
                placeholder="Espec. de título*"
                value={ticker}
                suggestions={listainstrumentos}
                completeMethod={consultaInstrumentos}
                field="name"
                onChange={e => setTicker(e.value)}
              />
              {submitted && !ticker && (
                <small className="p-invalid">Ticker é obrigatório.</small>
              )}
            </div>

            <div className="p-field p-col">
              <label htmlFor="ValorLiquido" className="subTitulosDentroModal">
                Valor líquido*
              </label>
              <InputNumber
                id="valorLiquido"
                required
                mode="decimal"
                minFractionDigits={2}
                inputId="locale-user"
                value={editarProventos.valorLiquido}
                onChange={value => (editarProventos.valorLiquido = value.value)}
                className={classNames({
                  'p-invalid': submitted && !editarProventos.valorLiquido,
                })}
              />
              {submitted && !editarProventos.valorLiquido && (
                <small className="p-invalid">
                  Valor Líquido é obrigatório.
                </small>
              )}
            </div>
          </div>
        </div>

        <br />
        <p
          style={{ display: erroDados1 ? 'block' : 'none' }}
          className="subTitulosDentroModal"
        >
          *Preenchimento obrigatório
        </p>
        <p
          style={{ display: erroDados2 ? 'block' : 'none' }}
          className="erroPreenchimento"
        >
          *Preenchimento obrigatório
        </p>
      </Dialog>
    </BaseModule>
  );
};
