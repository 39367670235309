import { DataTable } from 'primereact/datatable';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  gap: 16px;

  h2 {
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 21px !important;
  }

  & > div:first-child {
    display: flex;
    gap: 16px;
    flex-direction: column;

    .p-skeleton {
      margin-top: 8px;
    }

    & > div:nth-child(2) {
      display: flex;
      align-items: center;
      flex-direction: column;

      & > span:first-of-type {
        color: rgba(17, 44, 80, 1);
        font-weight: 500;
      }
    }

    @media (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;

export const TabelaAlienacoes = styled(DataTable)<{ children: ReactNode }>`
  &.p-datatable {
    margin: 0px -12px -12px -12px;
    border: none;
    font-size: 12px;

    .p-datatable-thead {
      background-color: rgba(150, 183, 199, 0.1);
      border-top: 1px solid rgba(83, 195, 194, 0.4);

      tr > th {
        background: unset;
        color: rgba(17, 44, 80, 1);
      }
    }

    .p-datatable-tbody {
      tr {
        background-color: transparent;

        td {
          color: rgba(17, 44, 80, 0.5);
        }

        &:not(:last-child) {
          border-bottom: 1px solid rgba(83, 195, 194, 0.4);
        }

        &:last-child {
          font-weight: 600;
        }
      }
    }
  }
`;
