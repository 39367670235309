import {
  ButtonBDI,
  DialogBDI,
  InputTextBDI,
  RadioButtonBDI,
} from '@dev_bdi/bdi-front-dspack';
import { Button } from 'primereact/button';
import React, { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../context/config';
import { useToast } from '../../context/toast/useToast';
import { apiOperacoes } from '../../service/ApiOperacoes/api';
import apiUsuarios from '../../service/apiUsuarios';
import history from '../../service/history';
import { formatCurrency } from '../../utils/functions';
import { CENTRAL_AJUDA_IRRF_ACUMULADO_LINK } from '../../utils/linksCentralAjuda';
import Usuario from '../Usuario';
import posicoesUsuario from '../posicoesUsuario';
import { Box1, Box100e, Small } from './styles';

const getAnoAnterior = () => {
  const anoAtual = Number(new Date().toISOString().split('-')[0]);

  return anoAtual - 1;
};

export const IrrfAcumulado = () => {
  const { state, setState } = useContext(ConfigContext);
  const { usuarioAntigo } = state.configuracao;
  const [swingtrade, setSwingtrade] = useState(formatCurrency('0'));
  const [daytrade, setDaytrade] = useState(formatCurrency('0'));
  const [avista, setAvista] = useState(formatCurrency('0'));
  const [semirrfacumulado, setSemirrfacumulado] = useState(true);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [tipodata, setTipodata] = useState();
  const [datareferencia, setDatareferencia] = useState('');
  const [posicoesiniciais, setPosicoesiniciais] = useState([]);
  const [semPosicaoInicial, setSemPosicaoInicial] = useState(true);
  const [prejuizosavista, setPrejuizosavista] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosopcoes, setPrejuizosopcoes] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosfuturodolar, setPrejuizosfuturodolar] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosfuturoindice, setPrejuizosfuturoindice] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosfundosimobiliarios, setPrejuizosfundosimobiliarios] =
    useState('');
  const [usuario, setUsuario] = useState({});

  const { showToast } = useToast();

  function voltar() {
    localStorage.setItem('swingtrade', swingtrade);
    localStorage.setItem('daytrade', daytrade);
    localStorage.setItem('avista', avista);
    localStorage.setItem('semirrfacumulado', semirrfacumulado);
    window.history.back();
  }

  function viewMenu(estado) {
    setState({
      ...state,
      menu: estado,
    });
  }

  function handleValorIR(e) {
    const moedaFormatada = formatCurrency(e.target.value);

    if (e.target.id === 'swingtrade') setSwingtrade(moedaFormatada);
    if (e.target.id === 'daytrade') setDaytrade(moedaFormatada);
    if (e.target.id === 'avista') setAvista(moedaFormatada);

    setSemirrfacumulado(false);
  }

  function configUsuario(config) {
    if (config.configuracao && !config.configuracao.usuarioAntigo) {
      history.push('/cadastroinicial/relatorio');
    }
  }

  async function salvar() {
    try {
      const dataAtual = new Date().toISOString();
      const dataRef = datareferencia
        ? new Date(datareferencia.split('/').reverse().join('-')).toISOString()
        : null;

      if (semPosicaoInicial === 'false') {
        const newPosicoesIniciais = posicoesiniciais.map(posicoes => {
          return {
            idPosicaoAberta: 0,
            idUsuario: usuario.id,
            idInstrumento: posicoes.ticker.code,
            idCorretora: posicoes.corretora.code,
            data: dataRef,
            quantidadeTotal: posicoes.quantidade,
            pmLiquido: 0,
            pmBruto: posicoes.precomedio
              ? Number(
                  posicoes.precomedio.split('.').join('').split(',').join('.'),
                )
              : 0,
            pontos: posicoes.pontos
              ? Number(posicoes.pontos.split('.').join('').split(',').join('.'))
              : 0,
            vendaCompra: posicoes.posicao.code,
            idEventos: null,
            autorizacao: null,
            idCorretoraNavigation: null,
            cadastroInicial: true,
          };
        });

        await apiOperacoes.post(
          'PosicoesAberta/v1/PostListaPosicoesAberta',
          newPosicoesIniciais,
        );
      }

      const setConfiguracao = await apiUsuarios.post(
        '/Configuracao/v1/PostUsuarioConfiguracao',
        {
          idUsuarioConfiguracao: 0,
          idUsuario: usuario.id,
          dataCadastro: dataAtual,
          dataAtualizacao: dataAtual,
          idTipoCorteCalculo: Number(tipodata),
          dataPosicaoInicial: dataRef,
          prejuizoAcumuladoMercadoAvistaComum: prejuizosavista.comum
            ? Number(
                prejuizosavista.comum.split('.').join('').split(',').join('.'),
              )
            : 0,
          prejuizoAcumuladoMercadoAvistaDt: prejuizosavista.daytrade
            ? Number(
                prejuizosavista.daytrade
                  .split('.')
                  .join('')
                  .split(',')
                  .join('.'),
              )
            : 0,
          prejuizoAcumuladoMercadoOpcoesComum: prejuizosopcoes.comum
            ? Number(
                prejuizosopcoes.comum.split('.').join('').split(',').join('.'),
              )
            : 0,
          prejuizoAcumuladoMercadoOpcoesDt: prejuizosopcoes.daytrade
            ? Number(
                prejuizosopcoes.daytrade
                  .split('.')
                  .join('')
                  .split(',')
                  .join('.'),
              )
            : 0,
          prejuizoAcumuladoMercadoFuturoMoedaComum: prejuizosfuturodolar.comum
            ? Number(
                prejuizosfuturodolar.comum
                  .split('.')
                  .join('')
                  .split(',')
                  .join('.'),
              )
            : 0,
          prejuizoAcumuladoMercadoFuturoMoedaDt: prejuizosfuturodolar.daytrade
            ? Number(
                prejuizosfuturodolar.daytrade
                  .split('.')
                  .join('')
                  .split(',')
                  .join('.'),
              )
            : 0,
          prejuizoAcumuladoMercadoFuturoIndicesComum:
            prejuizosfuturoindice.comum
              ? Number(
                  prejuizosfuturoindice.comum
                    .split('.')
                    .join('')
                    .split(',')
                    .join('.'),
                )
              : 0,
          prejuizoAcumuladoMercadoFuturoIndicesDt:
            prejuizosfuturoindice.daytrade
              ? Number(
                  prejuizosfuturoindice.daytrade
                    .split('.')
                    .join('')
                    .split(',')
                    .join('.'),
                )
              : 0,
          prejuizoAcumuladoFundosInvestimentosImobiliarios:
            prejuizosfundosimobiliarios
              ? Number(
                  prejuizosfundosimobiliarios
                    .split('.')
                    .join('')
                    .split(',')
                    .join('.'),
                )
              : 0,
          irrfretidoComum: swingtrade
            ? Number(swingtrade.split('.').join('').split(',').join('.'))
            : 0,
          irrfretidoDt: daytrade
            ? Number(daytrade.split('.').join('').split(',').join('.'))
            : 0,
          irrfmercadoVista: avista
            ? Number(avista.split('.').join('').split(',').join('.'))
            : 0,
          idTipoCorteCalculoNavigation: null,
        },
      );

      const posicoes = await posicoesUsuario();

      setState({
        ...state,
        configuracao: setConfiguracao.data ? setConfiguracao.data : '',
        posicoesabertas: posicoes.data ? posicoes.data : [],
      });
      //setDisplayBasic(true);
      finalizar('/cadastroinicial/relatorio');
    } catch (error) {
      console.error(error);
      showToast();
    }
  }

  function finalizar(rota) {
    history.push(rota);
  }

  function carregarUsuario(usuario) {
    setUsuario(usuario);
  }

  function carregarTipoData() {
    if (localStorage.getItem('tipodata'))
      setTipodata(localStorage.getItem('tipodata'));
  }

  function carregarDataReferencia() {
    localStorage.getItem('semdatareferencia') === 'true'
      ? setDatareferencia(null)
      : setDatareferencia(localStorage.getItem('datareferencia'));
  }

  function carregarPosicoesIniciais() {
    const posicoes_iniciais = localStorage.getItem('posicoesiniciais')
      ? JSON.parse(localStorage.getItem('posicoesiniciais'))
      : [];
    if (posicoes_iniciais) setPosicoesiniciais(posicoes_iniciais);

    const semPosicoesIniciais = localStorage.getItem('semposicaoinicial')
      ? localStorage.getItem('semposicaoinicial')
      : true;

    setSemPosicaoInicial(semPosicoesIniciais);
  }

  function carregarPrejuizos() {
    if (localStorage.getItem('semprejuizos') === 'true') {
      setPrejuizosavista({ comum: 0, daytrade: 0 });
      setPrejuizosopcoes({ comum: 0, daytrade: 0 });
      setPrejuizosfuturodolar({ comum: 0, daytrade: 0 });
      setPrejuizosfuturoindice({ comum: 0, daytrade: 0 });
      setPrejuizosfundosimobiliarios(0);
    } else {
      localStorage.getItem('prejuizosavista')
        ? setPrejuizosavista(
            JSON.parse(localStorage.getItem('prejuizosavista')),
          )
        : setPrejuizosavista({ comum: 0, daytrade: 0 });
      localStorage.getItem('prejuizosopcoes')
        ? setPrejuizosopcoes(
            JSON.parse(localStorage.getItem('prejuizosopcoes')),
          )
        : setPrejuizosopcoes({ comum: 0, daytrade: 0 });
      localStorage.getItem('prejuizosfuturodolar')
        ? setPrejuizosfuturodolar(
            JSON.parse(localStorage.getItem('prejuizosfuturodolar')),
          )
        : setPrejuizosfuturodolar({ comum: 0, daytrade: 0 });
      localStorage.getItem('prejuizosfuturoindice')
        ? setPrejuizosfuturoindice(
            JSON.parse(localStorage.getItem('prejuizosfuturoindice')),
          )
        : setPrejuizosfuturoindice({ comum: 0, daytrade: 0 });
      localStorage.getItem('prejuizosfundosimobiliarios')
        ? setPrejuizosfundosimobiliarios(
            JSON.parse(localStorage.getItem('prejuizosfundosimobiliarios')),
          )
        : setPrejuizosfundosimobiliarios(0);
    }
  }

  const basicDialogFooter = (
    <React.Fragment>
      <ButtonBDI
        label="Verificar dados"
        className={`
                        ${ButtonBDI.bdi_classe.severities}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
        funcao={() => finalizar('/cadastroinicial/relatorio')}
      />
    </React.Fragment>
  );

  useEffect(() => {
    carregarUsuario(Usuario);
    carregarTipoData();
    carregarDataReferencia();
    carregarPosicoesIniciais();
    carregarPrejuizos();
  }, []);

  useEffect(() => {
    state.configuracao && configUsuario(state);
  }, []);

  useEffect(() => {
    viewMenu(false);
  }, [state.menu]);

  useEffect(() => {
    if (localStorage.getItem('swingtrade'))
      setSwingtrade(localStorage.getItem('swingtrade'));
    if (localStorage.getItem('daytrade'))
      setDaytrade(localStorage.getItem('daytrade'));
    if (localStorage.getItem('avista'))
      setAvista(localStorage.getItem('avista'));
    localStorage.getItem('semirrfacumulado') &&
    localStorage.getItem('semirrfacumulado') === 'false'
      ? setSemirrfacumulado(false)
      : setSemirrfacumulado(true);
  }, []);

  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-12">
        {displayBasic && (
          <DialogBDI
            header="Dados Salvos"
            visible={displayBasic}
            style={{ width: '400px' }}
            footer={basicDialogFooter}
            funcao={() => setDisplayBasic(false)}
          >
            Dados salvos com sucesso!
            <br />
            <br />
            Se quiser verificar os dados cadastrados, basta clicar na opção
            “verificar dados”. Se precisar alterar alguma informação, entre em
            contato com nosso suporte.
          </DialogBDI>
        )}
        <Box1>
          <section className="card">
            <div className="timeline">
              <div className="container checked left">
                <div className="content checked">
                  <h3>Tipo de data utilizado</h3>
                </div>
              </div>
              <div className="container checked left">
                <div className="content checked">
                  <h3>Data de referência</h3>
                </div>
              </div>
              <div className="container checked left">
                <div className="content checked">
                  <h3>Posições iniciais</h3>
                </div>
              </div>
              <div className="container checked left">
                <div className="content checked">
                  <h3>Prejuízos acumulados</h3>
                </div>
              </div>
              <div className="container active left">
                <div className="content active">
                  <h3>IRRF acumulado</h3>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="card">
              <div className="p-col-12 p-md-12">
                <h2>
                  {usuarioAntigo ? (
                    <strong>
                      Informação importante: meus valores de IRRF acumulados.
                    </strong>
                  ) : (
                    <>
                      Estamos no final,{' '}
                      <strong>
                        {usuario.nome && usuario.nome.split(' ')[0]}.
                      </strong>{' '}
                      Agora, informe o <strong>IRRF acumulado.</strong>
                    </>
                  )}
                  <a
                    href={CENTRAL_AJUDA_IRRF_ACUMULADO_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      icon="pi pi-question-circle"
                      style={{ top: '9px' }}
                      className="p-button-rounded p-button-text colorIcon"
                    />
                  </a>
                </h2>
                <br />
                {usuarioAntigo ? (
                  <p className="subTituloBov">
                    O processo de migração para a SmarttIR contempla os seus
                    valores de IRRF acumulados.
                  </p>
                ) : (
                  <p className="subTituloBov">
                    Caso possua Imposto de Renda retido na fonte (IRRF){' '}
                    <strong>
                      entre 01/{getAnoAnterior()} até 12/{getAnoAnterior()}
                    </strong>{' '}
                    e que ainda não foi compensado no pagamento de DARFs em{' '}
                    <strong>{getAnoAnterior()}</strong>, o saldo deverá
                    informado abaixo para ser compensado com ganhos futuros. Se
                    você ainda não tem nenhum valor para cadastrar, clique na
                    opção
                    <strong> Não tenho IRRF acumulado para cadastrar</strong>.
                  </p>
                )}
              </div>
              <br />

              <div
                className="p-col-12 p-md-8"
                style={{ display: `${usuarioAntigo ? 'none' : 'block'}` }}
              >
                <RadioButtonBDI
                  label="Não tenho IRRF acumulado para cadastrar*"
                  inputId="semirrf"
                  name="irrfacumulado"
                  valor="1"
                  checked={semirrfacumulado}
                  acao={() => {
                    setSemirrfacumulado(true);
                    if (!semirrfacumulado) setAvista('');
                    if (!semirrfacumulado) setDaytrade('');
                    if (!semirrfacumulado) setSwingtrade('');
                  }}
                />
                <RadioButtonBDI
                  label="Desejo cadastrar IRRF acumulado*"
                  inputId="semirrf1"
                  name="irrfacumulado"
                  valor="1"
                  checked={!semirrfacumulado}
                  acao={() => {
                    setSemirrfacumulado(false);
                  }}
                />
              </div>
              {!semirrfacumulado && (
                <div className="p-col-12 p-md-12">
                  <Box100e>
                    <div>
                      <p className="heading">Detalhe</p>
                      <p className="heading">Total</p>
                    </div>
                    <div>
                      <p className="heading">
                        IR retido acumulado em operações normais (Swing trade)
                      </p>
                      <p>
                        <InputTextBDI
                          type="text"
                          placeholder="0000000,00"
                          id="swingtrade"
                          valor={swingtrade}
                          disabled={semirrfacumulado}
                          acao={handleValorIR}
                        />
                      </p>
                    </div>
                    <div>
                      <p className="heading">
                        IR retido acumulado em operações day trade
                      </p>
                      <p>
                        <InputTextBDI
                          type="text"
                          placeholder="0000000,00"
                          id="daytrade"
                          valor={daytrade}
                          disabled={semirrfacumulado}
                          acao={handleValorIR}
                        />
                      </p>
                    </div>
                    <div>
                      <p className="heading">
                        IR retido acumulado em operações com Fundos Imobiliários
                      </p>
                      <p>
                        <InputTextBDI
                          type="text"
                          placeholder="0000000,00"
                          id="avista"
                          valor={avista}
                          disabled={semirrfacumulado}
                          acao={handleValorIR}
                        />
                      </p>
                    </div>
                    <div>
                      <p className="heading">Total</p>
                      <p>
                        {swingtrade || daytrade || avista
                          ? (
                              Number(
                                swingtrade
                                  .split('.')
                                  .join('')
                                  .split(',')
                                  .join('.'),
                              ) +
                              Number(
                                daytrade
                                  .split('.')
                                  .join('')
                                  .split(',')
                                  .join('.'),
                              ) +
                              Number(
                                avista.split('.').join('').split(',').join('.'),
                              )
                            ).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })
                          : '0000000,00'}
                      </p>
                    </div>
                  </Box100e>
                </div>
              )}
              <div
                className="p-col-12 p-md-6 preenchimentoobrigatorio"
                style={{ display: `${usuarioAntigo ? 'none' : 'block'}` }}
              >
                <Small>*Preenchimento obrigatório</Small>
              </div>
              <div className="p-col-12 p-md-6">
                <ButtonBDI
                  label="Voltar"
                  className={`
                        ${ButtonBDI.bdi_classe.outlined}
                        ${ButtonBDI.bdi_subclasse.secondary}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => voltar()}
                />
                <ButtonBDI
                  label="Salvar"
                  className={`
                        ${ButtonBDI.bdi_classe.severities}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => salvar()}
                  disabled={
                    swingtrade || daytrade || avista || semirrfacumulado
                      ? false
                      : true
                  }
                />
              </div>
            </div>
          </section>
        </Box1>
      </div>
    </div>
  );
};
