import {
  CENTRAL_AJUDA_ALTERACAO_CODIGO_LINK,
  CENTRAL_AJUDA_BENS_DIREITO_LINK,
  CENTRAL_AJUDA_CADASTRO_IPO_SUBSCRICAO_LINK,
  CENTRAL_AJUDA_CONFIRMAR_PAGAMENTO_LINK,
  CENTRAL_AJUDA_DESDOBRAMENTO_GRUPAMENTO_BONIFICACAO_LINK,
  CENTRAL_AJUDA_EMITIR_DARF_LINK,
  CENTRAL_AJUDA_FUNDOS_IMOBILIARIOS_LINK,
  CENTRAL_AJUDA_HISTORICO_NOTAS_LINK,
  CENTRAL_AJUDA_HISTORICO_OPERACOES_LINK,
  CENTRAL_AJUDA_IMPORTACAO_NOTAS_LINK,
  CENTRAL_AJUDA_MINHA_CONTA_LINK,
  CENTRAL_AJUDA_OPERACOES_COMUM_DAYTRADE_LINK,
  CENTRAL_AJUDA_PROVENTOS_LINK,
  CENTRAL_AJUDA_RENDIMENTOS_ISENTOS_LINK,
  CENTRAL_AJUDA_RENDIMENTOS_TRIBUTACAO_EXCLUSIVA_LINK,
} from '../../utils/linksCentralAjuda';

type BaseModuleValues = {
  titulo: <T>(props?: T) => JSX.Element;
  descricao: <T>(props?: T) => JSX.Element;
  tooltip: string;
  link: string;
};

export const IMPORTACAO_DE_NOTAS = 'importacaoDeNotas';
const IMPORTACAO_DE_NOTAS_VALUES = {
  titulo: () => (
    <h1>
      <strong>Para calcular seu IR</strong>, envie os PDFs das suas notas de
      corretagem
    </h1>
  ),
  descricao: () => (
    <p>
      As notas devem estar no padrão SINACOR e você pode importar até 5 arquivos
      por vez, desde que estejam sem senha e contenham até 50 páginas ou 3 MB.
    </p>
  ),
  tooltip: 'Importação de notas',
  link: `${CENTRAL_AJUDA_IMPORTACAO_NOTAS_LINK}`,
};

export const CADASTRO_MANUAL = 'cadastroDeNotas';
const CADASTRO_MANUAL_VALUES = {
  titulo: ({ nome }: { nome: string }) => (
    <h1>
      <strong>{nome}</strong>, aqui você pode{' '}
      <strong>cadastrar manualmente</strong> suas notas de corretagem
    </h1>
  ),
  descricao: ({ redirectLink }: { redirectLink: string }) => (
    <p>
      Esta função permite cadastrar manualmente suas operações de acordo com as
      informações contidas em sua nota de corretagem.{' '}
      <strong>{redirectLink}</strong>.
    </p>
  ),
  tooltip: 'Cadastro manual de notas',
  link: 'https://comunidadebussoladoinvestidor.zendesk.com/hc/pt-br/articles/5610983723419',
} as BaseModuleValues;

export const EMISSAO_DARF = 'emissaoDarf';
const EMISSAO_DARF_VALUES = {
  titulo: () => <h1>Emissão de DARF</h1>,
  descricao: () => (
    <p>
      Nesta tela, você pode acompanhar o resultado da apuração mensal dos seus
      impostos de forma detalhada e gerar DARFs sem sair da plataforma.
    </p>
  ),
  tooltip: 'Emissão de DARF',
  link: `${CENTRAL_AJUDA_EMITIR_DARF_LINK}`,
};

export const HISTORICO_DE_PAGAMENTO_DE_DARF = 'historicoDePagamentoDeDarf';
const HISTORICO_DE_PAGAMENTO_DE_DARF_VALUES = {
  titulo: () => <h1>Histórico de pagamento de DARF</h1>,
  descricao: () => (
    <p>
      Aqui você poderá acompanhar todos os seus DARFs gerados na plataforma, bem
      como informar o pagamento e enviar por e-mail o DARF mais recente gerado
      em cada mês.
    </p>
  ),
  tooltip: 'Histórico de pagamento de DARF',
  link: `${CENTRAL_AJUDA_CONFIRMAR_PAGAMENTO_LINK}`,
};

export const HISTORICO_DE_NOTAS = 'historicoDeNotas';
const HISTORICO_DE_NOTAS_VALUES = {
  titulo: () => <h1>Histórico de notas</h1>,
  descricao: () => (
    <p>
      Consulte suas notas de corretagem e acompanhe o status do processamento de
      cada uma.
    </p>
  ),
  tooltip: 'Histórico de notas',
  link: `${CENTRAL_AJUDA_HISTORICO_NOTAS_LINK}`,
};

export const PATRIMONIO = 'patrimonio';
const PATRIMONIO_VALUES = {
  titulo: () => <h1>Patrimônio</h1>,
  descricao: () => (
    <>
      <p>
        Acompanhe seu patrimônio e os impostos relacionados aos seus
        investimentos.
      </p>
      <span>
        No item &quot;Minha carteira&quot; não estão inclusos valores em pontos
        de contratos futuros.
      </span>
    </>
  ),
  tooltip: 'Patrimônio',
  link: 'https://comunidadebussoladoinvestidor.zendesk.com/hc/pt-br/articles/5610929273883',
};

export const HISTORICO_DE_OPERACOES = 'historicoDeOperacoes';
const HISTORICO_DE_OPERACOES_VALUES = {
  titulo: () => <h1>Histórico de operações</h1>,
  descricao: () => (
    <p>
      Nesta tela são disponibilizadas as operações cadastradas em sua conta.
      Para visualizar dados específicos, utilize os filtros abaixo.
    </p>
  ),
  tooltip: 'Histórico de operações',
  link: `${CENTRAL_AJUDA_HISTORICO_OPERACOES_LINK}`,
};

export const DESDOBRAMENTO_GRUPAMENTO_E_BONIFICACAO =
  'desdobramentoGrupamentoEBonificacao';
const DESDOBRAMENTO_GRUPAMENTO_E_BONIFICACAO_VALUES = {
  titulo: () => <h1>Desdobramento, grupamento e bonificação de ação</h1>,
  descricao: () => (
    <p>
      Esta é a tela de controle de desdobramentos, grupamentos e bonificações de
      ação.
      <br />
      Aqui, você deverá aceitar ou recusar os eventos que identificamos para a
      sua carteira, além de adicionar o valor do ativo recebido quando
      solicitado.
    </p>
  ),
  tooltip: 'Desdobramento, grupamento e bonificação',
  link: `${CENTRAL_AJUDA_DESDOBRAMENTO_GRUPAMENTO_BONIFICACAO_LINK}`,
};

export const ALTERAR_CODIGO = 'alterarCodigo';
const ALTERAR_CODIGO_VALUES = {
  titulo: () => <h1>Alterar código</h1>,
  descricao: () => (
    <p>
      Esta é a tela de controle de alterações de código de negociação. Aqui,
      você deverá aceitar ou recusar os eventos que identificamos para a sua
      carteira.
    </p>
  ),
  tooltip: 'Alterar código',
  link: `${CENTRAL_AJUDA_ALTERACAO_CODIGO_LINK}`,
};

export const CADASTRO_IPO_SUBSCRICAO = 'cadastroIPOSubscricao';
const CADASTRO_IPO_SUBSCRICAO_VALUES = {
  titulo: () => <h1>Cadastro de IPO e subscrição</h1>,
  descricao: () => (
    <p>
      O cadastro de IPOs e subscrições deve ser realizado manualmente, pois eles
      não são disponibilizados em notas de corretagem. Utilize o cadastro abaixo
      para adicionar ativos adquiridos nesses formatos.
    </p>
  ),
  tooltip: 'Cadastro de IPO e subscrição',
  link: `${CENTRAL_AJUDA_CADASTRO_IPO_SUBSCRICAO_LINK}`,
};

export const AJUSTE_DE_OPCOES = 'ajusteDeOpcoes';
const AJUSTE_DE_OPCOES_VALUES = {
  titulo: () => <h1>Ajuste de opções</h1>,
  descricao: () => (
    <p>
      Realize o exercício e encerramento das opções adquiridas em sua carteira,
      mantenha o histórico das ações em exercidas ou encerradas.
    </p>
  ),
  tooltip: 'Ajuste de opções',
  link: 'https://comunidadebussoladoinvestidor.zendesk.com/hc/pt-br/articles/5610922539419',
};

export const RELATORIO_DE_PERFORMANCE = 'relatorioDePerformance';
const RELATORIO_DE_PERFORMANCE_VALUES = {
  titulo: () => <h1>Relatório de performance</h1>,
  descricao: () => (
    <p>
      Este relatório apresenta o resultado financeiro dos fechamentos de
      posições da sua carteira.
    </p>
  ),
  tooltip: 'Relatório de performance',
  link: 'https://comunidadebussoladoinvestidor.zendesk.com/hc/pt-br/articles/5610870333467',
};

export const PROVENTOS = 'proventos';
const PROVENTOS_VALUES = {
  titulo: () => <h1>Proventos</h1>,
  descricao: () => (
    <p>
      Nesta tela é possível cadastrar os <b>proventos recebidos</b> em suas
      corretoras.
    </p>
  ),
  tooltip: 'Proventos',
  link: `${CENTRAL_AJUDA_PROVENTOS_LINK}`,
};

export const MINHA_CONTA = 'minhaConta';
const MINHA_CONTA_VALUES = {
  titulo: () => <h1>Minha conta</h1>,
  descricao: () => (
    <p>Confira seus dados pessoais, seu plano e altere sua senha.</p>
  ),
  tooltip: 'Minha conta',
  link: `${CENTRAL_AJUDA_MINHA_CONTA_LINK}`,
};

const RELATORIOS_BASE_VALUES = {
  titulo: ({ nome }: { nome: string }) => <h1>Relatório de {nome}</h1>,
  descricao: () => (
    <p>
      Os relatórios de Imposto de Renda são divididos de acordo com as seções a
      serem declaradas para a Receita Federal. Para preencher corretamente sua
      Declaração, selecione o ano-calendário*, copie as informações desejadas e
      cole no respectivo campo do programa da Receita Federal.
    </p>
  ),
  tooltip: 'Relatórios de imposto de renda',
} as BaseModuleValues;

export const RELATORIO_BENS_DIREITOS = 'relatorioBensDireitos';
const RELATORIO_BENS_DIREITOS_VALUES = {
  ...RELATORIOS_BASE_VALUES,
  link: CENTRAL_AJUDA_BENS_DIREITO_LINK,
} as BaseModuleValues;

export const RELATORIO_OPERACOES_COMUM_DAYTRADE =
  'relatorioOperacoesComumDaytrade';
const RELATORIO_OPERACOES_COMUM_DAYTRADE_VALUES = {
  ...RELATORIOS_BASE_VALUES,
  link: CENTRAL_AJUDA_OPERACOES_COMUM_DAYTRADE_LINK,
} as BaseModuleValues;

export const RELATORIO_FUNDOS_IMOBILIARIOS = 'relatorioFundosImobiliarios';
const RELATORIO_FUNDOS_IMOBILIARIOS_VALUES = {
  ...RELATORIOS_BASE_VALUES,
  link: CENTRAL_AJUDA_FUNDOS_IMOBILIARIOS_LINK,
} as BaseModuleValues;

export const RELATORIO_RENDIMENTOS_ISENTOS = 'relatorioRendimentosIsentos';
const RELATORIO_RENDIMENTOS_ISENTOS_VALUES = {
  ...RELATORIOS_BASE_VALUES,
  link: CENTRAL_AJUDA_RENDIMENTOS_ISENTOS_LINK,
} as BaseModuleValues;

export const RELATORIO_TRIBUTACAO_EXCLUSIVA = 'relatorioTributacaoExclusiva';
const RELATORIO_TRIBUTACAO_EXCLUSIVA_VALUES = {
  ...RELATORIOS_BASE_VALUES,
  link: CENTRAL_AJUDA_RENDIMENTOS_TRIBUTACAO_EXCLUSIVA_LINK,
} as BaseModuleValues;

const baseModuleValues = new Map<string, BaseModuleValues>([
  [IMPORTACAO_DE_NOTAS, IMPORTACAO_DE_NOTAS_VALUES],
  [CADASTRO_MANUAL, CADASTRO_MANUAL_VALUES],
  [EMISSAO_DARF, EMISSAO_DARF_VALUES],
  [HISTORICO_DE_PAGAMENTO_DE_DARF, HISTORICO_DE_PAGAMENTO_DE_DARF_VALUES],
  [HISTORICO_DE_NOTAS, HISTORICO_DE_NOTAS_VALUES],
  [PATRIMONIO, PATRIMONIO_VALUES],
  [HISTORICO_DE_OPERACOES, HISTORICO_DE_OPERACOES_VALUES],
  [
    DESDOBRAMENTO_GRUPAMENTO_E_BONIFICACAO,
    DESDOBRAMENTO_GRUPAMENTO_E_BONIFICACAO_VALUES,
  ],
  [ALTERAR_CODIGO, ALTERAR_CODIGO_VALUES],
  [CADASTRO_IPO_SUBSCRICAO, CADASTRO_IPO_SUBSCRICAO_VALUES],
  [AJUSTE_DE_OPCOES, AJUSTE_DE_OPCOES_VALUES],
  [RELATORIO_DE_PERFORMANCE, RELATORIO_DE_PERFORMANCE_VALUES],
  [PROVENTOS, PROVENTOS_VALUES],
  [MINHA_CONTA, MINHA_CONTA_VALUES],
  [RELATORIO_BENS_DIREITOS, RELATORIO_BENS_DIREITOS_VALUES],
  [
    RELATORIO_OPERACOES_COMUM_DAYTRADE,
    RELATORIO_OPERACOES_COMUM_DAYTRADE_VALUES,
  ],
  [RELATORIO_FUNDOS_IMOBILIARIOS, RELATORIO_FUNDOS_IMOBILIARIOS_VALUES],
  [RELATORIO_RENDIMENTOS_ISENTOS, RELATORIO_RENDIMENTOS_ISENTOS_VALUES],
  [RELATORIO_TRIBUTACAO_EXCLUSIVA, RELATORIO_TRIBUTACAO_EXCLUSIVA_VALUES],
]);

export const getBaseModuleValues = (key: string) => {
  if (!key) {
    return {
      titulo: () => '',
      descricao: () => '',
      tooltip: '',
      link: '',
    };
  }

  return baseModuleValues.get(key)!;
};
