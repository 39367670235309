const geracaoDarfBaseURL = process.env.REACT_APP_API_GERACAODARF;
const relatoriosBaseURL = process.env.REACT_APP_API_RELATORIOS;

export const geracaoDarf = {
  historicoDarfs: {
    postConfirmacaoPagamento: () =>
      `${geracaoDarfBaseURL}/HistoricoDarfs/v1/PostConfirmacaoPagamento`,
    getHistoricoDarfPorUsuarioAno: (idUsuario, ano) =>
      `${geracaoDarfBaseURL}/HistoricoDarfs/v1/GetHistoricoDarfPorUsuarioAno/${idUsuario}/${ano}`,
    postEmailEmissaoDarf: () =>
      `${geracaoDarfBaseURL}/HistoricoDarfs/v1/PostEmailEmissaoDarf`,
  },
};

export const relatorios = {
  filtroBusca: {
    getAnosDeOperacaoPorUsuario: idUsuario =>
      `${relatoriosBaseURL}/FiltroBusca/v1/GetAnosDeOperacaoPorUsuario/${idUsuario}`,
  },
};
