import axios from 'axios';
import Cookies from 'js-cookie';
import { Avatar } from 'primereact/avatar';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useToast } from '../context/toast/useToast';
import { useRequest } from '../hooks/useRequest';
import { usuarios } from '../service/ApiUsuarios/Subscriptions';
import { getCurrentSubscription } from '../utils/functions/subscriptions';
import Paymentplan from './PaymentPlan';
import Usuario from './Usuario';
import * as S from './topMenuStyle';

const getPrimeiraLetra = palavra => palavra.substr(0, 1);

const Topmenu = () => {
  const [dadosUsuario, setDadosUsuario] = useState({});
  const [usuario, setUsuario] = useState({});
  const [letraUser, setLetraUser] = useState('');
  const [bloquearTesteGratis, setBloquearTesteGratis] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({});
  const [getSubscriptions, { loading }] = useRequest();
  const { showToast } = useToast();

  useEffect(() => {
    let isMounted = true;

    (async () => {
      await getSubscriptions({
        config: {
          url: usuarios.subscriptions.getSubscriptionsByUserId(Usuario.id),
        },
        onSuccess: data =>
          isMounted && setCurrentPlan(getCurrentSubscription(data)),
        onError: showToast,
      });
    })();

    return () => {
      isMounted = false;
    };
  }, [getSubscriptions, showToast]);

  useEffect(() => {
    setUsuario(Usuario);

    if (Paymentplan !== undefined && Paymentplan !== 'gratis') {
      setBloquearTesteGratis(true);
    }
  }, []);

  useEffect(() => {
    async function createListUser() {
      let idUsuario = Usuario.id;

      fetch(
        `${process.env.REACT_APP_API_USUARIOS}/DadosPessoais/v1/GetUsuarioDadosPessoaisByIdUsuario/${idUsuario}`,
      )
        .then(result => result.json())
        .then(data => {
          setDadosUsuario(data);

          if (data?.nomeCompleto) {
            setLetraUser(getPrimeiraLetra(data.nomeCompleto));
            return;
          }

          if (Usuario?.nome) {
            setLetraUser(getPrimeiraLetra(Usuario.nome));
            return;
          }

          setLetraUser('');
        })
        .catch(error => {
          Usuario && setLetraUser(getPrimeiraLetra(Usuario.nome));
          console.error(error);
        });
    }
    createListUser();
  }, []);

  async function activeFreeTrial() {
    let user = JSON.parse(Cookies.get('usuario'));

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_AUTH}/Plan/ativar-teste-gratis`,
        {
          idUsuario: user.id,
        },
      );

      if (response.status === 200) {
        let cookieOptions = {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          expires: 1,
          priority: 'High',
          SameSite: process.env.REACT_APP_COOKIE_SAMESITE,
          Secure: process.env.REACT_APP_COOKIE_SAFE === 'true',
          path: '/',
        };
        Cookies.set('pro', '2', cookieOptions);
        Cookies.set('paymentPlan', 'degustacao', cookieOptions);

        let userLogged = JSON.parse(Cookies.get('usuario'));
        userLogged.pro = '2';
        Cookies.set('usuario', JSON.stringify(userLogged), cookieOptions);

        setBloquearTesteGratis(true);
        window.location.href = process.env.REACT_APP_URL_CALCULADORA;
      }
    } catch (error) {
      console.error(error);
      showToast();
    }
  }

  function logout() {
    localStorage.clear();
    window.location.href = process.env.REACT_APP_LOGIN;
  }

  return (
    <S.TopMenuContainer>
      <span className="user-info-container" id="user-info">
        {!loading && (
          <li>
            {dadosUsuario.urlFotoPerfil ? (
              <>
                <img
                  src={
                    `${process.env.REACT_APP_URL_CALCULADORA2}` +
                    dadosUsuario.urlFotoPerfil
                  }
                  alt="Foto de perfil"
                />
                <h2>{Usuario.nome}</h2>
                <p>{currentPlan?.plano?.nome}</p>
              </>
            ) : (
              <S.AvatarContainer className="avatar-container">
                <Avatar label={letraUser} size="large" shape="circle" />
                <h2>{Usuario.nome}</h2>
                <p>{currentPlan?.plano?.nome}</p>
              </S.AvatarContainer>
            )}
          </li>
        )}
      </span>
      <span className="perfil-menu-span">
        <li>
          <Link to="/perfil">
            <i className="pi pi-fw pi-icon-minhacontaAtivo8"></i>
            Minha conta
          </Link>
        </li>
        <li>
          <Link to="/cadastroinicial/relatorio">
            <i className="pi pi-fw pi-icon-configuracao"></i>
            Configurações
          </Link>
        </li>

        <li
          className="teste-gratis"
          style={{ display: bloquearTesteGratis ? 'none' : 'block' }}
        >
          <button type="button" className="p-link" onClick={activeFreeTrial}>
            Ativar teste grátis
          </button>
        </li>

        <li>
          <button onClick={logout} type="button" className="p-link">
            <i className="pi pi-fw pi-icon-sairAtivo1"></i>
            Sair
          </button>
        </li>
      </span>
    </S.TopMenuContainer>
  );
};

export default Topmenu;
