/* eslint-disable */
import {
  ButtonBDI,
  DialogBDI,
  InputMaskBDI,
  InputTextBDI,
} from '@dev_bdi/bdi-front-dspack';
import React, { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../context/config';
import { useToast } from '../../context/toast/useToast';
import { apiOperacoes } from '../../service/ApiOperacoes/api';
import apiCorreios from '../../service/apiCorreios';
import apiUsuarios from '../../service/apiUsuarios';
import history from '../../service/history';
import Usuario from '../Usuario';
import posicoesUsuario from '../posicoesUsuario';
import { Box1, Box50, Small } from './styles';

export const DadosPessoais = () => {
  const { state, setState } = useContext(ConfigContext);
  const [usuario, setUsuario] = useState({});
  const [dadospessoais, setDadospessoais] = useState({});
  const [displayBasic, setDisplayBasic] = useState(false);
  const [tipodata, setTipodata] = useState();
  const [datareferencia, setDatareferencia] = useState('');
  const [posicoesiniciais, setPosicoesiniciais] = useState([]);
  const [prejuizosavista, setPrejuizosavista] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosopcoes, setPrejuizosopcoes] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosfuturodolar, setPrejuizosfuturodolar] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosfuturoindice, setPrejuizosfuturoindice] = useState({
    comum: '',
    daytrade: '',
  });
  const [prejuizosfundosimobiliarios, setPrejuizosfundosimobiliarios] =
    useState('');
  const [swingtrade, setSwingtrade] = useState('');
  const [daytrade, setDaytrade] = useState('');
  const [avista, setAvista] = useState('');

  const { showToast } = useToast();

  function carregarUsuario(usuario) {
    setUsuario(usuario);
  }

  function carregarDadosPessoais(usuario) {
    setDadospessoais({
      ...dadospessoais,
      nome: usuario.nome,
      cpf: usuario.cpf,
      nascimento: usuario.nascimento,
      celular: usuario.telefone,
    });
    if (
      localStorage.getItem('dadospessoais') &&
      localStorage.getItem('dadospessoais') !== ''
    )
      setDadospessoais(JSON.parse(localStorage.getItem('dadospessoais')));
  }

  function carregarTipoData() {
    if (localStorage.getItem('tipodata'))
      setTipodata(localStorage.getItem('tipodata'));
  }

  function carregarDataReferencia() {
    localStorage.getItem('semdatareferencia') === 'true'
      ? setDatareferencia(null)
      : setDatareferencia(localStorage.getItem('datareferencia'));
  }

  function carregarPosicoesIniciais() {
    const posicoes_iniciais = localStorage.getItem('posicoesiniciais')
      ? JSON.parse(localStorage.getItem('posicoesiniciais'))
      : [];
    if (posicoes_iniciais) setPosicoesiniciais(posicoes_iniciais);
  }

  function carregarPrejuizos() {
    if (localStorage.getItem('semprejuizos') === 'true') {
      setPrejuizosavista({ comum: 0, daytrade: 0 });
      setPrejuizosopcoes({ comum: 0, daytrade: 0 });
      setPrejuizosfuturodolar({ comum: 0, daytrade: 0 });
      setPrejuizosfuturoindice({ comum: 0, daytrade: 0 });
      setPrejuizosfundosimobiliarios(0);
    } else {
      localStorage.getItem('prejuizosavista')
        ? setPrejuizosavista(
            JSON.parse(localStorage.getItem('prejuizosavista')),
          )
        : setPrejuizosavista({ comum: 0, daytrade: 0 });
      localStorage.getItem('prejuizosopcoes')
        ? setPrejuizosopcoes(
            JSON.parse(localStorage.getItem('prejuizosopcoes')),
          )
        : setPrejuizosopcoes({ comum: 0, daytrade: 0 });
      localStorage.getItem('prejuizosfuturodolar')
        ? setPrejuizosfuturodolar(
            JSON.parse(localStorage.getItem('prejuizosfuturodolar')),
          )
        : setPrejuizosfuturodolar({ comum: 0, daytrade: 0 });
      localStorage.getItem('prejuizosfuturoindice')
        ? setPrejuizosfuturoindice(
            JSON.parse(localStorage.getItem('prejuizosfuturoindice')),
          )
        : setPrejuizosfuturoindice({ comum: 0, daytrade: 0 });
      localStorage.getItem('prejuizosfundosimobiliarios')
        ? setPrejuizosfundosimobiliarios(
            JSON.parse(localStorage.getItem('prejuizosfundosimobiliarios')),
          )
        : setPrejuizosfundosimobiliarios(0);
    }
  }

  function carregarIRRF() {
    if (localStorage.getItem('semirrfacumulado') === 'true') {
      setSwingtrade(0);
      setDaytrade(0);
      setAvista(0);
    } else {
      localStorage.getItem('swingtrade')
        ? setSwingtrade(localStorage.getItem('swingtrade'))
        : setSwingtrade(0);
      localStorage.getItem('daytrade')
        ? setDaytrade(localStorage.getItem('daytrade'))
        : setDaytrade(0);
      localStorage.getItem('avista')
        ? setAvista(localStorage.getItem('avista'))
        : setAvista(0);
    }
  }

  async function salvar() {
    try {
      localStorage.setItem('dadospessoais', JSON.stringify(dadospessoais));

      const dataAtual = new Date().toISOString();
      const nascimento = new Date(
        dadospessoais.nascimento.split('/').reverse().join('-'),
      ).toISOString();
      const dataRef = datareferencia
        ? new Date(datareferencia.split('/').reverse().join('-')).toISOString()
        : null;

      const newPosicoesIniciais = posicoesiniciais.map(posicoes => {
        return {
          idPosicaoAberta: 0,
          idUsuario: usuario.id,
          idInstrumento: posicoes.ticker.code,
          idCorretora: posicoes.corretora.code,
          data: dataRef,
          quantidadeTotal: posicoes.quantidade,
          pmLiquido: 0,
          pmBruto: posicoes.precomedio
            ? Number(
                posicoes.precomedio.split('.').join('').split(',').join('.'),
              )
            : 0,
          pontos: posicoes.pontos
            ? Number(posicoes.pontos.split('.').join('').split(',').join('.'))
            : 0,
          vendaCompra: posicoes.posicao.code,
          idEventos: null,
          autorizacao: null,
          idCorretoraNavigation: null,
        };
      });

      await apiOperacoes.post(
        'PosicoesAberta/v1/PostListaPosicoesAberta',
        newPosicoesIniciais,
      );

      const setConfiguracao = await apiUsuarios.post(
        '/Configuracao/v1/PostUsuarioConfiguracao',
        {
          idUsuarioConfiguracao: 0,
          idUsuario: usuario.id,
          dataCadastro: dataAtual,
          dataAtualizacao: dataAtual,
          idTipoCorteCalculo: Number(tipodata),
          dataPosicaoInicial: dataRef,
          prejuizoAcumuladoMercadoAvistaComum: prejuizosavista.comum
            ? Number(
                prejuizosavista.comum.split('.').join('').split(',').join('.'),
              )
            : 0,
          prejuizoAcumuladoMercadoAvistaDt: prejuizosavista.daytrade
            ? Number(
                prejuizosavista.daytrade
                  .split('.')
                  .join('')
                  .split(',')
                  .join('.'),
              )
            : 0,
          prejuizoAcumuladoMercadoOpcoesComum: prejuizosopcoes.comum
            ? Number(
                prejuizosopcoes.comum.split('.').join('').split(',').join('.'),
              )
            : 0,
          prejuizoAcumuladoMercadoOpcoesDt: prejuizosopcoes.daytrade
            ? Number(
                prejuizosopcoes.daytrade
                  .split('.')
                  .join('')
                  .split(',')
                  .join('.'),
              )
            : 0,
          prejuizoAcumuladoMercadoFuturoMoedaComum: prejuizosfuturodolar.comum
            ? Number(
                prejuizosfuturodolar.comum
                  .split('.')
                  .join('')
                  .split(',')
                  .join('.'),
              )
            : 0,
          prejuizoAcumuladoMercadoFuturoMoedaDt: prejuizosfuturodolar.daytrade
            ? Number(
                prejuizosfuturodolar.daytrade
                  .split('.')
                  .join('')
                  .split(',')
                  .join('.'),
              )
            : 0,
          prejuizoAcumuladoMercadoFuturoIndicesComum:
            prejuizosfuturoindice.comum
              ? Number(
                  prejuizosfuturoindice.comum
                    .split('.')
                    .join('')
                    .split(',')
                    .join('.'),
                )
              : 0,
          prejuizoAcumuladoMercadoFuturoIndicesDt:
            prejuizosfuturoindice.daytrade
              ? Number(
                  prejuizosfuturoindice.daytrade
                    .split('.')
                    .join('')
                    .split(',')
                    .join('.'),
                )
              : 0,
          prejuizoAcumuladoFundosInvestimentosImobiliarios:
            prejuizosfundosimobiliarios
              ? Number(
                  prejuizosfundosimobiliarios
                    .split('.')
                    .join('')
                    .split(',')
                    .join('.'),
                )
              : 0,
          irrfretidoComum: swingtrade
            ? Number(swingtrade.split('.').join('').split(',').join('.'))
            : 0,
          irrfretidoDt: daytrade
            ? Number(daytrade.split('.').join('').split(',').join('.'))
            : 0,
          irrfmercadoVista: avista
            ? Number(avista.split('.').join('').split(',').join('.'))
            : 0,
          idTipoCorteCalculoNavigation: null,
        },
      );

      const setDadospessoais = await apiUsuarios.post(
        '/DadosPessoais/v1/PostUsuarioDadosPessoais',
        {
          idUsuarioDadosPessoais: 0,
          idUsuario: usuario.id,
          nomeCompleto: dadospessoais.nome,
          cpf: dadospessoais.cpf,
          dataNascimento: nascimento,
          enderecoCep: dadospessoais.cep,
          enderecoLogradouro: dadospessoais.rua,
          enderecoNumero: dadospessoais.numero,
          enderecoComplemento: dadospessoais.complemento,
          enderecoCidade: dadospessoais.cidade,
          enderecoEstado: dadospessoais.estado,
        },
      );

      const posicoes = await posicoesUsuario();

      setState({
        ...state,
        usuario: { ...usuario, nome: setDadospessoais.data.nomeCompleto },
        dadospessoais: setDadospessoais.data ? setDadospessoais.data : '',
        configuracao: setConfiguracao.data ? setConfiguracao.data : '',
        posicoesabertas: posicoes.data ? posicoes.data : [],
      });

      setDisplayBasic(true);
    } catch (error) {
      console.error(error);
      showToast();
    }
  }

  function finalizar(rota) {
    history.push(rota);
  }

  function abrirLink(url) {
    if (!dadospessoais.cep) window.open(url, '_blank');
  }

  function voltar() {
    localStorage.setItem('dadospessoais', JSON.stringify(dadospessoais));
    window.history.back();
  }

  async function consultaCEP() {
    try {
      const response = await apiCorreios.get(`${dadospessoais.cep}/json`);
      setDadospessoais({
        ...dadospessoais,
        rua: response.data.logradouro,
        bairro: response.data.bairro,
        cidade: response.data.localidade,
        estado: response.data.uf,
      });
    } catch (error) {
      console.error(error);
      showToast();
    }
  }

  const basicDialogFooter = (
    <React.Fragment>
      <ButtonBDI
        label="Onboarding"
        className={`
                        p-button-primary
                        p-button-primary-cancel
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
        funcao={() => finalizar('/')}
      />
      <ButtonBDI
        label="Verificar dados"
        className={`
                        ${ButtonBDI.bdi_classe.severities}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
        funcao={() => finalizar('/cadastroinicial/relatorio')}
      />
    </React.Fragment>
  );

  function viewMenu(estado) {
    setState({
      ...state,
      menu: estado,
    });
  }

  function configUsuario(config) {
    if (config.configuracao && !config.configuracao.usuarioAntigo) {
      history.push('/cadastroinicial/relatorio');
    }
  }

  function validaCpfCnpj(val) {
    const qtdNumeros = val
      .split('')
      .filter(n => Number(n) || n == 0)
      .join('');

    if (qtdNumeros.length === 11) {
      var cpf = val.trim();

      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        if (qtdNumeros.length === 11)
          setDadospessoais({ ...dadospessoais, cpf: '' });
        return false;
      }

      for (var i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = (v1 * 10) % 11;

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        if (qtdNumeros.length === 11)
          setDadospessoais({ ...dadospessoais, cpf: '' });
        return false;
      }

      for (var i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = (v2 * 10) % 11;

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        if (qtdNumeros.length === 11)
          setDadospessoais({ ...dadospessoais, cpf: '' });
        return false;
      } else {
        if (qtdNumeros.length === 11)
          setDadospessoais({ ...dadospessoais, cpf: val });
        return true;
      }
    } else if (val.length == 18) {
      var cnpj = val.trim();

      cnpj = cnpj.replace(/\./g, '');
      cnpj = cnpj.replace('-', '');
      cnpj = cnpj.replace('/', '');
      cnpj = cnpj.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cnpj.length > i; i++) {
        if (cnpj[i - 1] != cnpj[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
        if (p1 >= 2) {
          v1 += cnpj[i] * p1;
        } else {
          v1 += cnpj[i] * p2;
        }
      }

      v1 = v1 % 11;

      if (v1 < 2) {
        v1 = 0;
      } else {
        v1 = 11 - v1;
      }

      if (v1 != cnpj[12]) {
        return false;
      }

      for (var i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
        if (p1 >= 2) {
          v2 += cnpj[i] * p1;
        } else {
          v2 += cnpj[i] * p2;
        }
      }

      v2 = v2 % 11;

      if (v2 < 2) {
        v2 = 0;
      } else {
        v2 = 11 - v2;
      }

      if (v2 != cnpj[13]) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  function validarNascimento(data) {
    const hoje = new Date().toISOString();

    let nascimento = data;
    const qtdNumeros = nascimento
      .split('')
      .filter(n => Number(n) || n == 0)
      .join('');
    if (qtdNumeros.length === 8) {
      if (
        qtdNumeros === '00000000' ||
        qtdNumeros === '11111111' ||
        qtdNumeros === '22222222' ||
        qtdNumeros === '33333333' ||
        qtdNumeros === '44444444' ||
        qtdNumeros === '55555555' ||
        qtdNumeros === '66666666' ||
        qtdNumeros === '77777777' ||
        qtdNumeros === '88888888' ||
        qtdNumeros === '99999999'
      ) {
        setDadospessoais({ ...dadospessoais, nascimento: '' });
        return false;
      }
      if (data.split('/')[1] > 12) {
        setDadospessoais({ ...dadospessoais, nascimento: '' });
        return false;
      }
      nascimento = new Date(data.split('/').reverse().join('-')).toISOString();
      if (hoje < nascimento) {
        setDadospessoais({ ...dadospessoais, nascimento: '' });
        return false;
      }
    }

    setDadospessoais({ ...dadospessoais, nascimento: data });
  }

  function validarCelular(celular) {
    const qtdNumeros = celular
      .split('')
      .filter(n => Number(n) || n == 0)
      .join('')
      .split(' ')
      .join('');

    if (qtdNumeros.length === 11) {
      if (
        qtdNumeros === '00000000000' ||
        qtdNumeros === '11111111111' ||
        qtdNumeros === '22222222222' ||
        qtdNumeros === '33333333333' ||
        qtdNumeros === '44444444444' ||
        qtdNumeros === '55555555555' ||
        qtdNumeros === '66666666666' ||
        qtdNumeros === '77777777777' ||
        qtdNumeros === '88888888888' ||
        qtdNumeros === '99999999999'
      ) {
        setDadospessoais({ ...dadospessoais, celular: '' });
        return false;
      }
    }

    setDadospessoais({ ...dadospessoais, celular });
  }

  useEffect(() => {
    state.configuracao && configUsuario(state);
  }, []);

  useEffect(() => {
    viewMenu(false);
  }, [state.menu]);

  useEffect(() => {
    carregarUsuario(Usuario);
    carregarDadosPessoais(Usuario);
    carregarTipoData();
    carregarDataReferencia();
    carregarPosicoesIniciais();
    carregarPrejuizos();
    carregarIRRF();
  }, []);

  return (
    <div className="p-grid overlay-demo">
      <div className="p-col-12 p-md-12">
        {displayBasic && (
          <DialogBDI
            header="Dados salvos"
            visible={displayBasic}
            style={{ width: '400px' }}
            footer={basicDialogFooter}
            funcao={() => setDisplayBasic(false)}
          >
            Seus dados foram salvos com sucesso. Se quiser verificar os dados
            cadastrados, clicar na opção “verificar dados”. Caso deseje alterar
            alguma informação, informe nossa equipe de suporte
          </DialogBDI>
        )}
        <Box1>
          <section className="card">
            <div className="timeline">
              <div className="container checked left">
                <div className="content checked">
                  <h3>Tipo de data utilizado</h3>
                </div>
              </div>
              <div className="container checked left">
                <div className="content checked">
                  <h3>Data de referência</h3>
                </div>
              </div>
              <div className="container checked left">
                <div className="content checked">
                  <h3>Posições iniciais</h3>
                </div>
              </div>
              <div className="container checked left">
                <div className="content checked">
                  <h3>Prejuízos acumulados</h3>
                </div>
              </div>
              <div className="container checked left">
                <div className="content checked">
                  <h3>IRRF acumulado</h3>
                </div>
              </div>
              <div className="container active left">
                <div className="content active">
                  <h3>Dados pessoais</h3>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="card">
              <div className="p-col-12 p-md-12">
                <h2>
                  <strong>Para finalizar</strong>, precisamos de alguns{' '}
                  <strong>dados pessoais</strong>.
                </h2>
                <br />
                <p>
                  O preenchimento dos dados abaixo é necessário para o processo
                  de validação, cálculo e emissão de sua DARF referente ao seu
                  imposto de renda.
                </p>
              </div>

              <div className="p-col-12 p-md-12">
                <Box50>
                  <div className="p-fluid">
                    <div className="p-fluid">
                      <div>
                        <label>Nome completo*</label>
                        <InputTextBDI
                          type="text"
                          placeholder="Nome completo"
                          id="nome"
                          valor={dadospessoais.nome}
                          acao={e =>
                            setDadospessoais({
                              ...dadospessoais,
                              nome: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="p-fluid">
                      <div>
                        <label>CPF*</label>
                        <InputMaskBDI
                          id="cpf"
                          mask="999.999.999-99"
                          placeholder="000.000.000-00"
                          valor={dadospessoais.cpf}
                          acao={e => validaCpfCnpj(e.value)}
                        ></InputMaskBDI>
                      </div>
                      <div>
                        <label>Nascimento*</label>
                        <InputMaskBDI
                          id="nascimento"
                          mask="99/99/9999"
                          placeholder="00/00/0000"
                          valor={dadospessoais.nascimento}
                          acao={e => validarNascimento(e.value)}
                        ></InputMaskBDI>
                      </div>
                      <div>
                        <label>Celular*</label>
                        <InputMaskBDI
                          id="celular"
                          mask="(99) 99999-9999"
                          placeholder="(99) 99999-9999"
                          valor={dadospessoais.celular}
                          acao={e => validarCelular(e.value)}
                        ></InputMaskBDI>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div>
                        <label>CEP*</label>
                        <span className="p-input-icon-right">
                          <InputMaskBDI
                            name="cep"
                            id="cep"
                            mask="99999-999"
                            placeholder="00000-000"
                            valor={
                              usuario.cep ? usuario.cep : dadospessoais.cep
                            }
                            acao={e =>
                              setDadospessoais({
                                ...dadospessoais,
                                cep: e.value,
                              })
                            }
                            acao2={() => consultaCEP()}
                          ></InputMaskBDI>
                          <i
                            onClick={() =>
                              abrirLink(
                                'https://buscacepinter.correios.com.br/app/endereco/index.php',
                              )
                            }
                            className="pi pi-search"
                          />
                        </span>
                      </div>
                      <div>
                        <label>Logradouro*</label>
                        <InputTextBDI
                          type="text"
                          placeholder="Rua"
                          name="rua"
                          id="rua"
                          valor={dadospessoais.rua}
                          acao={e =>
                            setDadospessoais({
                              ...dadospessoais,
                              rua: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="p-fluid">
                      <div>
                        <label>Número*</label>
                        <InputTextBDI
                          type="number"
                          placeholder="000000"
                          name="numero"
                          id="numero"
                          valor={dadospessoais.numero}
                          acao={e =>
                            setDadospessoais({
                              ...dadospessoais,
                              numero: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div>
                        <label>Complemento</label>
                        <InputTextBDI
                          type="text"
                          placeholder="Complemento"
                          id="complemento"
                          valor={dadospessoais.complemento}
                          acao={e =>
                            setDadospessoais({
                              ...dadospessoais,
                              complemento: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div>
                        <label>Bairro*</label>
                        <InputTextBDI
                          type="text"
                          placeholder="Bairro"
                          name="bairro"
                          id="bairro"
                          valor={dadospessoais.bairro}
                          acao={e =>
                            setDadospessoais({
                              ...dadospessoais,
                              bairro: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="p-fluid">
                      <div>
                        <label>Cidade*</label>
                        <InputTextBDI
                          type="text"
                          placeholder="Cidade"
                          name="cidade"
                          id="cidade"
                          valor={dadospessoais.cidade}
                          acao={e =>
                            setDadospessoais({
                              ...dadospessoais,
                              cidade: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div>
                        <label>Estado*</label>
                        <InputTextBDI
                          type="text"
                          placeholder="Estado"
                          name="uf"
                          id="uf"
                          valor={dadospessoais.estado}
                          acao={e =>
                            setDadospessoais({
                              ...dadospessoais,
                              estado: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Box50>
              </div>
              <div className="p-col-12 p-md-6 preenchimentoobrigatorio">
                <Small>*Preenchimento obrigatório</Small>
              </div>
              <div className="p-col-12 p-md-6">
                <ButtonBDI
                  label="Voltar"
                  className={`
                        ${ButtonBDI.bdi_classe.outlined}
                        ${ButtonBDI.bdi_subclasse.secondary}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => voltar()}
                />
                <ButtonBDI
                  label="Salvar"
                  className={`
                        ${ButtonBDI.bdi_classe.severities}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => salvar()}
                  disabled={
                    dadospessoais.nome &&
                    dadospessoais.nome.split(' ')[1] &&
                    dadospessoais.cpf &&
                    dadospessoais.nascimento &&
                    dadospessoais.celular &&
                    dadospessoais.cep &&
                    dadospessoais.rua &&
                    dadospessoais.numero &&
                    dadospessoais.cidade &&
                    dadospessoais.estado
                      ? false
                      : true
                  }
                />
              </div>
            </div>
          </section>
        </Box1>
      </div>
    </div>
  );
};
