import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Cookies from 'js-cookie';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import App from './App';
import GlobalContext from './context';
import ModalProvider from './context/modal/ModalProvider';
import { SubscriptionsProvider } from './context/subscriptions/SubscriptionsContext';
import ToastProvider from './context/toast/ToastProvider';
import history from './service/history';
import { GlobalStyle } from './styles/global';
import { defaultTheme } from './styles/themes/default';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const logado = Cookies.get('usuario');

if (!logado || logado === '')
  window.location.href = process.env.REACT_APP_LOGIN;
else {
  ReactDOM.render(
    <React.StrictMode>
      <SubscriptionsProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={defaultTheme}>
            <GlobalContext>
              <Router history={history}>
                <ToastProvider>
                  <ModalProvider>
                    <App />
                  </ModalProvider>
                </ToastProvider>
              </Router>
            </GlobalContext>

            <ReactQueryDevtools />
            <GlobalStyle />
          </ThemeProvider>
        </QueryClientProvider>
      </SubscriptionsProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
