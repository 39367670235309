import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';

import Usuario from '../../../../components/Usuario';
import {
  PASSWORD_ERROR,
  SUCCESS_KEY,
  UNKNOWN_USER_ERROR,
} from '../../../../context/toast/constants';
import { useToast } from '../../../../context/toast/useToast';
import api from './api';
import './logarcomo.css';
import { Container, DialogFooter } from './styles';

export function LogarComo() {
  const [dadosUsuario, setDadosUsuario] = useState('');
  const [dadosSenha, setDadosSenha] = useState('');
  const [dadosConfirmarDialog, setDadosConfirmarDialog] = useState(false);
  const [retornoExiste, setRetornoExiste] = useState('');
  const [retornoNaoExiste, setRetornoNaoExiste] = useState('');
  const [retornoDados, setRetornoDados] = useState('');
  const { showToast } = useToast();

  async function verificarUser() {
    fetch(
      `${process.env.REACT_APP_API_USUARIOS}/Auth/check-internal-user?Acesso=${dadosUsuario}`,
    )
      .then(result => result.json())
      .then(data => {
        setRetornoExiste(data.existe);
        setRetornoNaoExiste(data.existe);
        setRetornoDados(data);
      })
      .catch(error => {
        console.error(error);
        showToast();
      });
  }

  useEffect(() => {
    if (retornoExiste === true) {
      showToast(SUCCESS_KEY);
      setDadosConfirmarDialog(true);
      setRetornoExiste('');
    }
  }, [retornoExiste]);

  useEffect(() => {
    if (retornoNaoExiste === false) {
      showToast(UNKNOWN_USER_ERROR);
      setDadosConfirmarDialog(false);
      setRetornoNaoExiste('');
    }
  }, [retornoNaoExiste]);

  const fecharModal = () => {
    setDadosConfirmarDialog(false);
  };

  function criarObjUser(user, payment) {
    const userPayment = {
      id: payment?.id || 175961,
      usuario: payment?.usuario || user.username,
      paymentPlan: payment?.paymentPlan || 'adm',
      tipoPagamento: payment?.tipoPagamento || 'SUBSCRIPTION_IUGU',
      statusTransacao: payment?.statusTransacao || 'Cancelado',
      transacaoID: payment?.transacaoID || 'II::5FFH4NDGKRA8AXSLNF2G',
      cliNome: payment?.cliNome || 'user_id:316082',
      cliEmail: payment?.cliEmail || '',
      cliEndereco: payment?.cliEndereco || '',
      cliNumero: payment?.cliNumero || '',
      cliComplemento: payment?.cliComplemento || '',
      cliBairro: payment?.cliBairro || '',
      cliCidade: payment?.cliCidade || '',
      cliEstado: payment?.cliEstado || '',
      cliCEP: payment?.cliCEP || '',
      cliTelefone: payment?.cliTelefone || '',
      dataTransacao: payment?.dataTransacao || '19/10/2022 00:00:00',
      dataFim: payment?.dataFim || '19/10/2023 23:59:59',
      prodDescricao_x: payment?.prodDescricao_x || '',
      prodValor_x: payment?.prodValor_x || '750',
      email: payment?.email || null,
      discountCode: payment?.discountCode || null,
      aditionals: payment?.aditionals || null,
    };

    return {
      id: user.id,
      nome: user.name,
      username: user.username,
      telefone: user.telefone,
      email: user.email,
      cpf: user.cpf,
      nascimento: user.nascimento,
      foto: user.foto,
      datacadastro: user.data,
      cidade: user.cidade,
      estado: user.estado,
      pais: user.pais,
      pro: user.pro,
      payment: userPayment,
    };
  }

  async function logarComo() {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_API_USUARIOS}/Auth/login-internal`,
        {
          email: Usuario.email,
          password: dadosSenha,
          emailDestino: retornoDados.email,
        },
      );
      //Usuario = response.data.user;

      //Usuario.id = response.data.user.id;
      //Usuario.email = response.data.user.email;
      //Usuario.username = response.data.user.username;

      //let cookieOptions = { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: 1, priority: "High", SameSite: process.env.REACT_APP_COOKIE_SAMESITE, Secure: process.env.REACT_APP_COOKIE_SAFE === "true", path: "/" };

      Cookies.remove('usuario');
      Cookies.remove('graficosTempoReal');
      Cookies.remove('paymentPlan');
      Cookies.remove('estado');
      Cookies.remove('pais');
      Cookies.remove('Id');
      Cookies.remove('tokenm');
      Cookies.remove('cpf');
      Cookies.remove('tokenh');
      Cookies.remove('pro');
      Cookies.remove('payment');
      Cookies.remove('datacadastro');
      Cookies.remove('cidade');
      Cookies.remove('username');

      let cookieOptions = {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: 1,
        priority: 'High',
        SameSite: process.env.REACT_APP_COOKIE_SAMESITE,
        Secure: process.env.REACT_APP_COOKIE_SAFE === 'true',
        path: '/',
      };

      const objUser = criarObjUser(response.data.user, response.data.payment);
      const paymentPlan = response.data?.payment?.paymentPlan || 'adm';

      Cookies.set('usuario', JSON.stringify(objUser), cookieOptions);
      Cookies.set(
        'graficosTempoReal',
        response.data.user.graficosTempoReal,
        cookieOptions,
      );
      Cookies.set('paymentPlan', paymentPlan, cookieOptions);
      Cookies.set('estado', response.data.user.estado, cookieOptions);
      Cookies.set('pais', response.data.user.pais, cookieOptions);
      Cookies.set('Id', response.data.user.id, cookieOptions);
      Cookies.set('tokenm', response.data.tokenm, cookieOptions);
      Cookies.set('cpf', response.data.user.cpf, cookieOptions);
      Cookies.set('tokenh', response.data.tokenh, cookieOptions);
      Cookies.set('pro', response.data.user.pro, cookieOptions);
      Cookies.set(
        'payment',
        JSON.stringify(response.data?.payment),
        cookieOptions,
      );
      Cookies.set('datacadastro', response.data.user.data, cookieOptions);
      Cookies.set('cidade', response.data.user.cidade, cookieOptions);
      Cookies.set('username', response.data.user.username, cookieOptions);

      /*Cookies.set('Usuario.payment.dataTransacao', response.data.payment.dataTransacao,cookieOptions);
      Cookies.set('Usuario.payment.dataFim', response.data.payment.dataFim,cookieOptions);
      Cookies.set('Usuario.payment.tipoPagamento', response.data.payment.tipoPagamento,cookieOptions);*/

      window.location.href = process.env.REACT_APP_URL_CALCULADORA;
      //window.location.href = "/";

      showToast(SUCCESS_KEY);

      setDadosConfirmarDialog(false);

      //Usuario = response.data.user;

      //history.push("/minha-carteira/patrimonio");

      //window.location.href = "/minha-carteira/patrimonio"
    } catch (error) {
      console.log(error);
      setDadosConfirmarDialog(false);
      showToast(PASSWORD_ERROR);
    }
  }

  return (
    <>
      <Container>
        <header>
          <h1>Digite os dados do usuário abaixo</h1>
        </header>
        <main>
          <div>
            <InputText
              type="text"
              placeholder="Dados do usuário"
              //value={dadosUsuario}
              onChange={e => setDadosUsuario(e.target.value)}
              style={{ width: '42%' }}
            ></InputText>
          </div>

          <Button label="Enviar" onClick={verificarUser} />
        </main>
      </Container>

      <Dialog
        header="Dados Usuário"
        visible={dadosConfirmarDialog}
        onHide={fecharModal}
        style={{ width: '40rem' }}
      >
        <p>ID: {retornoDados.id}</p>
        <p>Nome: {retornoDados.nome}</p>
        <p>Email: {retornoDados.email}</p>
        <p>Username: {retornoDados.username}</p>
        <p>CPF: {retornoDados.cpf}</p>

        <DialogFooter>
          <InputText
            type="password"
            placeholder="Digite a sua senha"
            onChange={e => setDadosSenha(e.target.value)}
            className="campoSenha"
          ></InputText>
          <Button
            label="Cancelar"
            className="p-button-text botaoFechar"
            onClick={fecharModal}
          />
          <Button label="Logar" onClick={logarComo} />
        </DialogFooter>
      </Dialog>
    </>
  );
}
