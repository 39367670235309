import { Chart } from 'primereact/chart';
import { ReactNode, useState } from 'react';

import { GreenBorderBox } from '../../../../../../styles/elements/GreenBorderBox';
import { CHART_OPTIONS } from '../constants';
import { Legenda } from './Legenda';
import { Container } from './styles';

type WidgetGraficoProps = {
  dataGrafico: {
    datasets: {
      data: number[];
      backgroundColor: string[];
      hoverBackgroundColor: string[];
    }[];
    labels: string[];
  };
  type?: string;
  titulo: ReactNode;
  subtitulo: string;
  slotDireito?: ReactNode;
};

export function WidgetGrafico({
  dataGrafico,
  type = 'pie',
  titulo,
  subtitulo,
  slotDireito,
}: WidgetGraficoProps) {
  const [data] = useState(dataGrafico);

  return (
    <GreenBorderBox>
      <Container>
        <div className="header">
          <div>
            <h2>{titulo}</h2>
            <span>{subtitulo}</span>
          </div>
          <div>{slotDireito}</div>
        </div>
        <div className="grafico">
          <Legenda labels={data.labels} />
          <Chart
            type={type}
            data={data}
            options={CHART_OPTIONS}
            style={{ width: '240px', margin: '0 auto' }}
          />
        </div>
      </Container>
    </GreenBorderBox>
  );
}
