import { useCallback, useState } from 'react';

import { getModal } from '../../components/Dialogs';
import { ModalContext } from './useModal';

const ModalProvider = ({ children }) => {
  const [{ Component, props }, setModal] = useState({
    Component: null,
    props: null,
  });
  const [isVisible, setIsVisible] = useState(false);

  const openModal = useCallback((key, props = {}) => {
    setModal({ Component: getModal(key), props });
    setIsVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsVisible(false);
    setModal({ Component: null, props: null });
  }, []);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {Component && (
        <Component
          visible={isVisible}
          onHide={closeModal}
          closeModal={closeModal}
          {...props}
        />
      )}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
