export const LETRAS_PERFIL = {
  A: require('./A.png').default,
  B: require('./B.png').default,
  C: require('./C.png').default,
  D: require('./D.png').default,
  E: require('./E.png').default,
  F: require('./F.png').default,
  G: require('./G.png').default,
  H: require('./H.png').default,
  I: require('./I.png').default,
  J: require('./J.png').default,
  K: require('./K.png').default,
  L: require('./L.png').default,
  M: require('./M.png').default,
  N: require('./N.png').default,
  O: require('./O.png').default,
  P: require('./P.png').default,
  Q: require('./Q.png').default,
  R: require('./R.png').default,
  S: require('./S.png').default,
  T: require('./T.png').default,
  U: require('./U.png').default,
  V: require('./V.png').default,
  W: require('./W.png').default,
  X: require('./X.png').default,
  Y: require('./Y.png').default,
  Z: require('./Z.png').default,
};

export default LETRAS_PERFIL;
