export const LIFE = 3000;

export const SUCCESS_KEY = 'success';
const SUCCESS_VALUE = {
  severity: 'success',
  summary: 'Sucesso!',
  detail: '',
};

export const ERROR_KEY = 'error';
const ERROR_VALUE = {
  severity: 'error',
  summary: 'Ops! Algo deu errado.',
  detail: '',
};

export const INFO_KEY = 'info';
const INFO_VALUE = {
  severity: 'info',
  summary: 'Informação!',
  detail: '',
};

export const WARN_KEY = 'warn';
const WARN_VALUE = {
  severity: 'warn',
  summary: 'Atenção!',
  detail: '',
};

export const NO_EMPTY_FIELDS_KEY = 'noEmptyFields';
const NO_EMPTY_FIELDS_VALUE = {
  ...WARN_VALUE,
  detail: 'Preencha todos os campos',
};

export const EXERCICIO_OPCAO_SUCCESS_KEY = 'exercicioOpcaoSuccess';
const EXERCICIO_OPCAO_SUCCESS_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Exercício de opção aplicado',
};

export const ENCERRAR_OPCAO_SUCCESS_KEY = 'encerrarOpcaoSuccess';
const ENCERRAR_OPCAO_SUCCESS_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Encerramento de opção aplicado',
};

export const REQUIRED_FIELD_ERROR = 'requiredFieldError';
const REQUIRED_FIELD_ERROR_VALUE = {
  severity: 'error',
  summary: 'Campo Obrigatório Não Preenchido!',
  detail: '',
};

export const DELETE_INVOICE_HISTORY_SUCCESS = 'deleteInvoiceHistorySuccess';
const DELETE_INVOICE_HISTORY_SUCCESS_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Histórico apagado.',
};

export const DELETE_INVOICE_HISTORY_ERROR = 'deleteInvoiceHistoryError';
const DELETE_INVOICE_HISTORY_ERROR_VALUE = {
  ...ERROR_VALUE,
  detail: 'Erro ao apagar a nota.',
};
export const EDIT_INVOICE_SUCCESS = 'editInvoiceSuccess';
const EDIT_INVOICE_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Nota editada!',
};

export const EDIT_INVOICE_ERROR = 'editInvoiceError';
const EDIT_INVOICE_ERROR_VALUE = {
  ...ERROR_VALUE,
  detail: 'Erro ao editar a nota!',
};

export const SAVE_EARNINGS_SUCCESS = 'saveEarningsSuccess';
const SAVE_EARNINGS_SUCCESS_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Provento salvo.',
};

export const SAVE_EARNINGS_ERROR = 'saveEarningsError';
const SAVE_EARNINGS_ERROR_VALUE = {
  ...ERROR_VALUE,
  detail: 'Erro ao salvar o provento',
};

export const DELETE_EARNINGS_SUCCESS = 'deleteEarningsSuccess';
const DELETE_EARNINGS_SUCCESS_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Provento deletado.',
};

export const DELETE_EARNINGS_ERROR = 'deleteEarningsError';
const DELETE_EARNINGS_ERROR_VALUE = {
  ...ERROR_VALUE,
  detail: 'Erro ao deletar o provento.',
};

export const EDIT_EARNINGS_SUCCESS = 'editEarningsSuccess';
const EDIT_EARNINGS_SUCCESS_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Provento editado.',
};

export const EDIT_EARNINGS_ERROR = 'editEarningsError';
const EDIT_EARNINGS_ERROR_VALUE = {
  ...ERROR_VALUE,
  detail: 'Erro ao editar o provento.',
};

export const DARF_PAYMENT_SUCCESS = 'darfPaymentSuccess';
const DARF_PAYMENT_SUCCESS_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Pagamento de DARF confirmado com sucesso.',
};

export const DARF_PAYMENT_ERROR = 'darfPaymentError';
const DARF_PAYMENT_ERROR_VALUE = {
  ...ERROR_VALUE,
  detail: 'Ocorreu um erro ao confirmar o pagamento.',
};

export const SEND_EMAIL_SUCCESS = 'sendEmailSuccess';
const SEND_EMAIL_SUCCESS_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Email enviado.',
};

export const SEND_EMAIL_ERROR = 'sendEmailError';
const SEND_EMAIL_ERROR_VALUE = {
  ...ERROR_VALUE,
  detail: 'Ocorreu um erro ao enviar o e-mail.',
};

export const OPERATION_ACCEPTED_INFO = 'operationAcceptedInfo';
const OPERATION_ACTION_INFO_VALUE = {
  ...ERROR_VALUE,
  detail: 'Não é possível alterar uma operação que já está aceita/recusada.',
};

export const TICKER_MIM_SELECTION = 'tickerMinSelectionInfo';
const TICKER_MIM_SELECTION_VALUE = {
  ...ERROR_VALUE,
  detail: 'É necessario selecionar ao menos um registro.',
};

export const OPERATION_REJECTED_INFO = 'operationRejectedInfo';
const OPERATION_REJECTED_INFO_VALUE = {
  ...INFO_VALUE,
  detail: 'Não é possível recusar uma operação que já está recusada.',
};

export const OPERATION_MIN_SELECTION = 'operationMinSelectionInfo';
const OPERATION_MIN_SELECTION_VALUE = {
  ...INFO_VALUE,
  detail: 'É necessario selecionar ao menos uma operação.',
};

export const OPERATION_ACCEPTED_SUCCESS = 'operationAcceptedSuccess';
const OPERATION_ACCEPTED_SUCCESS_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Operação aceita com sucesso',
};

export const OPERATION_REJECTED_SUCCESS = 'operationRejectedSuccess';
const OPERATION_REJECTED_SUCCESS_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Operação rejeitada com sucesso',
};

export const OPERATION_ACCEPTED_ERROR = 'operationAcceptedError';
const OPERATION_ACCEPTED_ERROR_VALUE = {
  ...ERROR_VALUE,
  detail: 'Ocorreu um erro ao atualizar a operação.',
};

export const AVERAGE_PRICE_ERROR = 'averagePriceError';
const AVERAGE_PRICE_ERROR_VALUE = {
  ...WARN_VALUE,
  detail: 'Não é possível salvar um valor igual a 0,00',
};

export const DARF_PAYMENT_WARN = 'darfPaymentWarn';
const DARF_PAYMENT_WARN_VALUE = {
  ...WARN_VALUE,
  detail: 'Não é possível emitir uma darf com valor igual a zero.',
};

export const UNKNOWN_USER_ERROR = 'unknownUserError';
const UNKNOWN_USER_ERROR_VALUE = {
  ...ERROR_VALUE,
  detail: 'O usuário não existe.',
};

export const PASSWORD_ERROR = 'passwordError';
const PASSWORD_ERROR_VALUE = {
  ...ERROR_VALUE,
  summary: 'Erro ao logar!',
  detail: 'Senha errada!',
};

export const EDIT_ADDRESS_SUCCESS = 'editAddressSuccess';
const EDIT_ADDRESS_SUCCESS_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Endereço editado com sucesso.',
};

export const EDIT_ADDRESS_ERROR = 'editAddressError';
const EDIT_ADDRESS_ERROR_VALUE = {
  ...ERROR_VALUE,
  detail: 'Erro ao editar o Endereço.',
};

export const EDIT_PRIVATE_DATA_SUCCESS = 'editPrivateDataSuccess';
const EDIT_PRIVATE_DATA_SUCCESS_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Dados pessoais editado com sucesso.',
};

export const EDIT_PRIVATE_DATA_ERROR = 'editPrivateDataError';
const EDIT_PRIVATE_DATA_ERROR_VALUE = {
  ...ERROR_VALUE,
  detail: 'Erro ao editar os dados pessoais.',
};

export const SEND_PHOTO_SUCCESS = 'sendPhotoSuccess';
const SEND_PHOTO_SUCCESS_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Foto enviada com sucesso.',
};

export const SEND_PHOTO_ERROR = 'sendPhotoError';
const SEND_PHOTO_ERROR_VALUE = {
  ...ERROR_VALUE,
  detail: 'Erro ao enviar foto.',
};

export const DOWNLOAD_DATA_ERROR = 'downloadDataError';
const DOWNLOAD_DATA_ERROR_VALUE = {
  ...ERROR_VALUE,
  detail: 'Ocorreu um erro ao trazer os dados.',
};

export const BROKERAGE_FIELD_ERROR = 'brokerageFieldError';
const BROKERAGE_FIELD_ERROR_VALUE = {
  ...REQUIRED_FIELD_ERROR_VALUE,
  detail: 'Campo Corretora inválido.',
};

export const DATE_FIELD_ERROR = 'dateFieldError';
const DATE_FIELD_ERROR_VALUE = {
  ...REQUIRED_FIELD_ERROR_VALUE,
  detail: 'Campo Data inválido.',
};

export const TYPE_FIELD_ERROR = 'typeFieldError';
const TYPE_FIELD_ERROR_VALUE = {
  ...REQUIRED_FIELD_ERROR_VALUE,
  detail: 'Campo Tipo inválido.',
};

export const MARKET_TYPE_FIELD_ERROR = 'marketTypeFieldError';
const MARKET_TYPE_FIELD_ERROR_VALUE = {
  ...REQUIRED_FIELD_ERROR_VALUE,
  detail: 'Campo Tipo Mercado inválido.',
};

export const TICKER_FIELD_ERROR = 'tickerFieldError';
const TICKER_FIELD_ERROR_VALUE = {
  ...REQUIRED_FIELD_ERROR_VALUE,
  detail: 'Campo Ticker inválido.',
};

export const QUANTITY_FIELD_ERROR = 'quantityFieldError';
const QUANTITY_FIELD_ERROR_VALUE = {
  ...REQUIRED_FIELD_ERROR_VALUE,
  detail: 'Campo Quantidade inválido.',
};

export const PRICE_FIELD_ERROR = 'priceFieldError';
const PRICE_FIELD_ERROR_VALUE = {
  ...REQUIRED_FIELD_ERROR_VALUE,
  detail: 'Campo Preço inválido.',
};

export const NO_RECORD_OPTIONS = 'noRecordOptions';
const NO_RECORD_OPTIONS_VALUE = {
  ...ERROR_VALUE,
  summary: 'Sem opções adicionadas para gravação.',
  detail: 'Por favor adicione itens para gravação.',
};

export const CHANGED_ITEM_SUCCESS = 'changedItemSuccess';
const CHANGED_ITEM_SUCCESS_VALUE = {
  ...SUCCESS_VALUE,
  detail: 'Item alterado com sucesso.',
};

export const NO_ATTACHED_FILES = 'noAttachedFiles';
const NO_ATTACHED_FILES_VALUE = {
  severity: 'warn',
  summary: 'Nenhum arquivo foi anexado!',
  detail: '',
};

export const EMPTY_MESSAGE = 'emptyMessage';
const EMPTY_MESSAGE_VALUE = {
  ...REQUIRED_FIELD_ERROR_VALUE,
  detail: 'Por favor, conte-nos o ocorrido.',
};

export const CANCEL_SUBSCRIPTION_EMAIL = 'cancelSubscription';
const CANCEL_SUBSCRIPTION_EMAIL_VALUE = {
  ...SUCCESS_VALUE,
  detail:
    'Recebemos sua solicitação de cancelamento e em até 72 horas sua renovação será cancelada',
};

const TOAST_DETAILS = new Map([
  [SUCCESS_KEY, SUCCESS_VALUE],
  [ERROR_KEY, ERROR_VALUE],
  [DELETE_INVOICE_HISTORY_SUCCESS, DELETE_INVOICE_HISTORY_SUCCESS_VALUE],
  [DELETE_INVOICE_HISTORY_ERROR, DELETE_INVOICE_HISTORY_ERROR_VALUE],
  [EDIT_INVOICE_SUCCESS, EDIT_INVOICE_VALUE],
  [EDIT_INVOICE_ERROR, EDIT_INVOICE_ERROR_VALUE],
  [SAVE_EARNINGS_SUCCESS, SAVE_EARNINGS_SUCCESS_VALUE],
  [SAVE_EARNINGS_ERROR, SAVE_EARNINGS_ERROR_VALUE],
  [DELETE_EARNINGS_SUCCESS, DELETE_EARNINGS_SUCCESS_VALUE],
  [DELETE_EARNINGS_ERROR, DELETE_EARNINGS_ERROR_VALUE],
  [EDIT_EARNINGS_SUCCESS, EDIT_EARNINGS_SUCCESS_VALUE],
  [EDIT_EARNINGS_ERROR, EDIT_EARNINGS_ERROR_VALUE],
  [DARF_PAYMENT_SUCCESS, DARF_PAYMENT_SUCCESS_VALUE],
  [DARF_PAYMENT_ERROR, DARF_PAYMENT_ERROR_VALUE],
  [SEND_EMAIL_SUCCESS, SEND_EMAIL_SUCCESS_VALUE],
  [SEND_EMAIL_ERROR, SEND_EMAIL_ERROR_VALUE],
  [OPERATION_ACCEPTED_INFO, OPERATION_ACTION_INFO_VALUE],
  [TICKER_MIM_SELECTION, TICKER_MIM_SELECTION_VALUE],
  [OPERATION_MIN_SELECTION, OPERATION_MIN_SELECTION_VALUE],
  [OPERATION_REJECTED_INFO, OPERATION_REJECTED_INFO_VALUE],
  [OPERATION_ACCEPTED_SUCCESS, OPERATION_ACCEPTED_SUCCESS_VALUE],
  [OPERATION_REJECTED_SUCCESS, OPERATION_REJECTED_SUCCESS_VALUE],
  [OPERATION_ACCEPTED_ERROR, OPERATION_ACCEPTED_ERROR_VALUE],
  [DARF_PAYMENT_WARN, DARF_PAYMENT_WARN_VALUE],
  [UNKNOWN_USER_ERROR, UNKNOWN_USER_ERROR_VALUE],
  [PASSWORD_ERROR, PASSWORD_ERROR_VALUE],
  [EDIT_ADDRESS_SUCCESS, EDIT_ADDRESS_SUCCESS_VALUE],
  [EDIT_ADDRESS_ERROR, EDIT_ADDRESS_ERROR_VALUE],
  [EDIT_PRIVATE_DATA_SUCCESS, EDIT_PRIVATE_DATA_SUCCESS_VALUE],
  [EDIT_PRIVATE_DATA_ERROR, EDIT_PRIVATE_DATA_ERROR_VALUE],
  [SEND_PHOTO_SUCCESS, SEND_PHOTO_SUCCESS_VALUE],
  [SEND_PHOTO_ERROR, SEND_PHOTO_ERROR_VALUE],
  [DOWNLOAD_DATA_ERROR, DOWNLOAD_DATA_ERROR_VALUE],
  [BROKERAGE_FIELD_ERROR, BROKERAGE_FIELD_ERROR_VALUE],
  [DATE_FIELD_ERROR, DATE_FIELD_ERROR_VALUE],
  [TYPE_FIELD_ERROR, TYPE_FIELD_ERROR_VALUE],
  [MARKET_TYPE_FIELD_ERROR, MARKET_TYPE_FIELD_ERROR_VALUE],
  [TICKER_FIELD_ERROR, TICKER_FIELD_ERROR_VALUE],
  [QUANTITY_FIELD_ERROR, QUANTITY_FIELD_ERROR_VALUE],
  [PRICE_FIELD_ERROR, PRICE_FIELD_ERROR_VALUE],
  [NO_RECORD_OPTIONS, NO_RECORD_OPTIONS_VALUE],
  [CHANGED_ITEM_SUCCESS, CHANGED_ITEM_SUCCESS_VALUE],
  [NO_ATTACHED_FILES, NO_ATTACHED_FILES_VALUE],
  [EMPTY_MESSAGE, EMPTY_MESSAGE_VALUE],
  [CANCEL_SUBSCRIPTION_EMAIL, CANCEL_SUBSCRIPTION_EMAIL_VALUE],
  [AVERAGE_PRICE_ERROR, AVERAGE_PRICE_ERROR_VALUE],
  [NO_EMPTY_FIELDS_KEY, NO_EMPTY_FIELDS_VALUE],
  [EXERCICIO_OPCAO_SUCCESS_KEY, EXERCICIO_OPCAO_SUCCESS_VALUE],
  [ENCERRAR_OPCAO_SUCCESS_KEY, ENCERRAR_OPCAO_SUCCESS_VALUE],
]);

export const getToastInfo = (key: string) => {
  const toastInfo = TOAST_DETAILS.get(key);

  if (toastInfo) return toastInfo;

  return TOAST_DETAILS.get(ERROR_KEY)!;
};
