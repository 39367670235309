import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { useContext, useEffect, useReducer, useState } from 'react';

import { BaseModule } from '../../../../components/BaseModule';
import { HISTORICO_DE_OPERACOES } from '../../../../components/BaseModule/constants';
import { ContainerFiltro } from '../../../../components/ContainerFiltro';
import ConfigContext from '../../../../context/config';
import { useToast } from '../../../../context/toast/useToast';
import { useLocale } from '../../../../hooks/useLocale';
import {
  getOperationsBussolaData,
  useBussolaOperations,
} from './hooks/useBussolaOperations';
import {
  getOperationsSmarttIRData,
  useSmarttIROperations,
} from './hooks/useSmarttIROperations';
import * as S from './styles';

function operationsFilterEventsReducer(prev, next) {
  const newEvent = { ...prev, ...next };

  if (
    newEvent.dataInicial !== '' &&
    newEvent.dataFinal !== '' &&
    newEvent.dataInicial > newEvent.dataFinal
  ) {
    newEvent.dataInicial = newEvent.dataFinal;
  }

  return newEvent;
}

const INITIAL_OPERATIONS_REDUCER_VALUE = {
  corretora: 0,
  tipoAtivo: 0,
  instrumento: { name: '', code: 0 },
  tipoNegocio: '',
  dataInicial: '',
  dataFinal: '',
};

const INITIAL_SMARTTIR_FILTER = {
  idUsuario: null,
  posicao: 0,
  quantidade: 20,
  corretora: 0,
  dataInicial: null,
  dataFinal: null,
  tipoAtivo: 0,
  tipoNegocio: 0,
  instrumento: 0,
};

const INITIAL_BUSSOLA_FILTER = {
  idUsuario: null,
  posicao: 0,
  quantidade: 20,
  idCorretora: 0,
  dataInicial: null,
  dataFinal: null,
  idSubTipoAtivo: 0,
  tipoNegocio: '',
  idInstrumento: 0,
};

export function HistoricoDeOperacoes() {
  const [smarttIRFilterData, updateSmarttIRFilterData] = useReducer(
    operationsFilterEventsReducer,
    INITIAL_OPERATIONS_REDUCER_VALUE,
  );
  const [bussolaFilterData, updateBussolaFilterData] = useReducer(
    operationsFilterEventsReducer,
    INITIAL_OPERATIONS_REDUCER_VALUE,
  );
  const [listaInstrumentosBussola, setListaInstrumentosBussola] = useState([]);
  const [listaInstrumentosSmarttIR, setListaInstrumentosSmarttIR] = useState(
    [],
  );
  const [migrationDataVisible, setMigrationDataVisible] = useState(false);
  const [userMigrated, setUserMigrated] = useState(false);
  const [smarttIRFilter, setSmarttIRFilter] = useState(INITIAL_SMARTTIR_FILTER);
  const [bussolaFilter, setBussolaFilter] = useState(INITIAL_BUSSOLA_FILTER);

  const { state } = useContext(ConfigContext);
  const { statusMigracao, idUsuario } = state.configuracao;
  const { locale } = useLocale();
  const { showToast } = useToast();

  // React Query Hooks
  const [
    operacoesSmarttIR,
    corretorasSmarttIR,
    subTiposSmarttIR,
    tipoNegociosSmarttIR,
  ] = useSmarttIROperations(smarttIRFilter);

  const [
    operacoesBussola,
    corretorasBussola,
    subTiposBussola,
    tipoNegociosBussola,
  ] = useBussolaOperations({ bussolaFilter, migrationDataVisible });

  const queryClient = useQueryClient();

  useEffect(() => {
    setSmarttIRFilter(prevFilter => ({ ...prevFilter, idUsuario }));

    if (statusMigracao === 'MIGRADO') {
      setBussolaFilter(prevFilter => ({ ...prevFilter, idUsuario }));
      setUserMigrated(true);
    } else {
      setUserMigrated(false);
    }
  }, [idUsuario, statusMigracao]);

  useEffect(() => {
    // prefetching next page
    if (!migrationDataVisible && smarttIRFilter.idUsuario) {
      const filter = {
        ...smarttIRFilter,
        posicao: smarttIRFilter.posicao + 20,
      };

      queryClient.prefetchQuery(
        [
          'operacoes',
          'notaCorretagem',
          'v3',
          'notaCorretagemUsuarioId',
          filter,
        ],
        getOperationsSmarttIRData,
        { staleTime: 1000 * 60 },
      );
    } else if (migrationDataVisible && bussolaFilter.idUsuario) {
      const filter = {
        ...bussolaFilter,
        posicao: bussolaFilter.posicao + 20,
      };

      queryClient.prefetchQuery(
        ['operacoes', 'legado', 'v1', 'notaCorretagemUsuarioId', filter],
        getOperationsBussolaData,
        {
          staleTime: Infinity,
          cacheTime: 1000 * 60,
        },
      );
    }
  }, [queryClient, smarttIRFilter, bussolaFilter, migrationDataVisible]);

  const getInstrumentosSmarttIR = async event => {
    try {
      const query = event && event.query.toLowerCase();

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_OPERACOES}/NotaCorretagem/v1/GetInstrumentosAtivasOperacoes/${smarttIRFilter.idUsuario}`,
      );

      const filterList = data.filter(
        dado => dado.ticker.toLowerCase().indexOf(query) === 0,
      );
      const result = filterList.map(dado => ({
        name: dado.ticker,
        code: dado.idInstrumento,
      }));

      setListaInstrumentosSmarttIR(result);
    } catch (error) {
      console.error(error);
      showToast();
    }
  };

  const getInstrumentosBussola = async event => {
    try {
      const query = event && event.query.toLowerCase();
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_OPERACOES}/OperacoesLegado/v1/GetComboInstrumentoPorIdUsuario/${bussolaFilter.idUsuario}`,
      );
      const filterList = data.dados.filter(
        dado => dado.nome.toLowerCase().indexOf(query) === 0,
      );
      const result = filterList.map(dado => ({
        name: dado.nome,
        code: dado.id,
      }));

      setListaInstrumentosBussola(result);
    } catch (error) {
      console.error(error);
      showToast();
    }
  };

  const filtraDados = () => {
    const formatDate = date => new Date(date).toISOString().split('T')[0];

    if (migrationDataVisible) {
      const {
        corretora,
        dataInicial,
        dataFinal,
        tipoAtivo,
        tipoNegocio,
        instrumento,
      } = bussolaFilterData;

      const newBussolaFilter = {
        idUsuario,
        posicao: 0,
        quantidade: 20,
        idCorretora: corretora || 0,
        dataInicial: !!dataInicial ? formatDate(dataInicial) : null,
        dataFinal: !!dataFinal ? formatDate(dataFinal) : null,
        idSubTipoAtivo: tipoAtivo || 0,
        tipoNegocio: tipoNegocio || '',
        idInstrumento: instrumento.code || 0,
      };

      setBussolaFilter(newBussolaFilter);
    } else {
      const {
        corretora,
        dataInicial,
        dataFinal,
        tipoAtivo,
        tipoNegocio,
        instrumento,
      } = smarttIRFilterData;

      const newSmarttIRFilter = {
        idUsuario,
        posicao: 0,
        quantidade: 20,
        corretora: corretora || 0,
        dataInicial: !!dataInicial ? formatDate(dataInicial) : null,
        dataFinal: !!dataFinal ? formatDate(dataFinal) : null,
        tipoAtivo: tipoAtivo || 0,
        tipoNegocio: tipoNegocio || 0,
        instrumento: instrumento.code || 0,
      };

      setSmarttIRFilter(newSmarttIRFilter);
    }
  };

  const limpaCamposFiltro = () => {
    if (migrationDataVisible) {
      updateBussolaFilterData({
        corretora: 0,
        tipoAtivo: 0,
        instrumento: 0,
        tipoNegocio: '',
        dataInicial: '',
        dataFinal: '',
      });
    } else {
      updateSmarttIRFilterData({
        corretora: 0,
        tipoAtivo: 0,
        instrumento: 0,
        tipoNegocio: '',
        dataInicial: '',
        dataFinal: '',
      });
    }
  };

  // Paginator
  const smarttIRPaginatorTemplate = {
    layout: 'PrevPageLink CurrentPageReport NextPageLink',
    PrevPageLink: () => {
      const isFirstPosition = smarttIRFilter.posicao === 0;

      return (
        <button
          type="button"
          className={`p-paginator-prev p-paginator-element p-link ${
            isFirstPosition && ' p-disabled'
          }`}
          onClick={() => {
            setSmarttIRFilter(prevState => ({
              ...prevState,
              posicao: prevState.posicao - 20,
            }));
          }}
          disabled={isFirstPosition}
        >
          <span className="pi pi-caret-left"></span>
        </button>
      );
    },
    CurrentPageReport: () => {
      const firstOperation = smarttIRFilter.posicao + 1;
      const lastOperation =
        smarttIRFilter.posicao + 20 > operacoesSmarttIR.data?.quantidade
          ? operacoesSmarttIR.data?.quantidade
          : smarttIRFilter.posicao + 20;

      if (
        !operacoesSmarttIR?.data ||
        operacoesSmarttIR.data.operations.length <= 0
      )
        return <span />;

      return (
        <>
          {operacoesSmarttIR.isFetching ? (
            <span>Mostrando ... até ... de ...</span>
          ) : (
            <span>
              Mostrando {firstOperation} até {lastOperation} de{' '}
              {operacoesSmarttIR.data?.quantidade}
            </span>
          )}
        </>
      );
    },
    NextPageLink: () => {
      const lastOperation =
        smarttIRFilter.posicao + 20 > operacoesSmarttIR.data?.quantidade
          ? operacoesSmarttIR.data?.quantidade
          : smarttIRFilter.posicao + 20;

      const isLastPosition =
        lastOperation === operacoesSmarttIR.data?.quantidade;

      return (
        <button
          type="button"
          className={`p-paginator-next p-paginator-element p-link ${
            isLastPosition && ' p-disabled'
          }`}
          onClick={() => {
            setSmarttIRFilter(prevState => ({
              ...prevState,
              posicao: prevState.posicao + 20,
            }));
          }}
          disabled={!operacoesSmarttIR.data || isLastPosition}
        >
          <span className="pi pi-caret-right"></span>
        </button>
      );
    },
  };

  const bussolaPaginatorTemplate = {
    layout: 'PrevPageLink CurrentPageReport NextPageLink',
    PrevPageLink: () => {
      const isFirstPosition = bussolaFilter.posicao === 0;

      return (
        <button
          type="button"
          className={`p-paginator-prev p-paginator-element p-link ${
            isFirstPosition && ' p-disabled'
          }`}
          onClick={() => {
            setBussolaFilter(prevState => ({
              ...prevState,
              posicao: prevState.posicao - 20,
            }));
          }}
          disabled={isFirstPosition}
        >
          <span className="pi pi-caret-left"></span>
        </button>
      );
    },
    CurrentPageReport: () => {
      const firstOperation = bussolaFilter.posicao + 1;
      const lastOperation =
        bussolaFilter.posicao + 20 > operacoesBussola.data?.quantidade
          ? operacoesBussola.data?.quantidade
          : bussolaFilter.posicao + 20;

      if (
        !operacoesBussola?.data ||
        operacoesBussola.data.operations.length <= 0
      )
        return <span />;

      return (
        <>
          {operacoesBussola.isFetching ? (
            <span>Mostrando ... até ... de ...</span>
          ) : (
            <span>
              Mostrando {firstOperation} até {lastOperation} de{' '}
              {operacoesBussola.data?.quantidade}
            </span>
          )}
        </>
      );
    },
    NextPageLink: () => {
      const lastOperation =
        bussolaFilter.posicao + 20 > operacoesBussola.data?.quantidade
          ? operacoesBussola.data?.quantidade
          : bussolaFilter.posicao + 20;

      const isLastPosition =
        lastOperation === operacoesBussola.data?.quantidade;

      return (
        <button
          type="button"
          className={`p-paginator-next p-paginator-element p-link ${
            isLastPosition && ' p-disabled'
          }`}
          onClick={() => {
            setBussolaFilter(prevState => ({
              ...prevState,
              posicao: prevState.posicao + 20,
            }));
          }}
          disabled={!operacoesBussola.data || isLastPosition}
        >
          <span className="pi pi-caret-right"></span>
        </button>
      );
    },
  };

  const isDataLoading =
    (operacoesBussola.isLoading && operacoesBussola.fetchStatus !== 'idle') ||
    (operacoesSmarttIR.isLoading && operacoesSmarttIR.fetchStatus !== 'idle');

  return (
    <BaseModule
      tipo={HISTORICO_DE_OPERACOES}
      bussola={
        userMigrated && (
          <S.MigratedParagraphWarning>
            *Os dados de origem Bussola são dados estáticos migrados da
            plataforma antiga e os dados SmarttIR são todos aqueles com upload
            na nova plataforma.
          </S.MigratedParagraphWarning>
        )
      }
    >
      <ContainerFiltro
        onLeftButtonClick={limpaCamposFiltro}
        onRightButtonClick={filtraDados}
        rightButtonDisabled={isDataLoading}
        bussola={
          userMigrated && (
            <>
              <S.SwitchTitle>*Origem dos dados</S.SwitchTitle>
              <S.SwitchContainer>
                <p>SmarttIR</p>
                <S.PrimeReactInputSwitch
                  checked={migrationDataVisible}
                  onChange={e => setMigrationDataVisible(e.value)}
                />
                <p>Bussola</p>
              </S.SwitchContainer>
            </>
          )
        }
      >
        <div>
          <label htmlFor="corretora">Corretora</label>
          <Dropdown
            id="corretora"
            value={
              migrationDataVisible
                ? bussolaFilterData.corretora
                : smarttIRFilterData.corretora
            }
            onChange={e => {
              migrationDataVisible
                ? updateBussolaFilterData({ corretora: e.value })
                : updateSmarttIRFilterData({ corretora: e.value });
            }}
            options={
              migrationDataVisible
                ? corretorasBussola.data
                : corretorasSmarttIR.data
            }
            emptyMessage="Nenhuma corretora encontrada"
          />
        </div>
        <div>
          <label htmlFor="tipoDeAtivo">Tipo de Ativo</label>
          <Dropdown
            id="tipoDeAtivo"
            value={
              migrationDataVisible
                ? bussolaFilterData.tipoAtivo
                : smarttIRFilterData.tipoAtivo
            }
            onChange={e => [
              migrationDataVisible
                ? updateBussolaFilterData({ tipoAtivo: e.value })
                : updateSmarttIRFilterData({ tipoAtivo: e.value }),
            ]}
            options={
              migrationDataVisible
                ? subTiposBussola.data
                : subTiposSmarttIR.data
            }
            emptyMessage="Nenhum tipo ativo encontrado"
          />
        </div>
        <div>
          <label htmlFor="tipoDeNeg">Tipo de Neg</label>
          <Dropdown
            id="tipoDeNeg"
            value={
              migrationDataVisible
                ? bussolaFilterData.tipoNegocio
                : smarttIRFilterData.tipoNegocio
            }
            onChange={e => {
              migrationDataVisible
                ? updateBussolaFilterData({ tipoNegocio: e.value })
                : updateSmarttIRFilterData({ tipoNegocio: e.value });
            }}
            options={
              migrationDataVisible
                ? tipoNegociosBussola.data
                : tipoNegociosSmarttIR.data
            }
            emptyMessage="Nenhum tipo negócio encontrado"
          />
        </div>
        <div>
          <label htmlFor="ticker">Ticker</label>
          <AutoComplete
            id="ticker"
            value={
              migrationDataVisible
                ? bussolaFilterData.instrumento
                : smarttIRFilterData.instrumento
            }
            onChange={e => {
              migrationDataVisible
                ? updateBussolaFilterData({ instrumento: e.value })
                : updateSmarttIRFilterData({ instrumento: e.value });
            }}
            suggestions={
              migrationDataVisible
                ? listaInstrumentosBussola
                : listaInstrumentosSmarttIR
            }
            completeMethod={
              migrationDataVisible
                ? getInstrumentosBussola
                : getInstrumentosSmarttIR
            }
            field="name"
          />
        </div>
        <div>
          <label htmlFor="dataInicial">Data Inicial</label>
          <Calendar
            id="dataInicial"
            dateFormat="dd/mm/yy"
            locale={locale}
            value={
              migrationDataVisible
                ? bussolaFilterData.dataInicial
                : smarttIRFilterData.dataInicial
            }
            onChange={e => {
              migrationDataVisible
                ? updateBussolaFilterData({ dataInicial: e.value })
                : updateSmarttIRFilterData({ dataInicial: e.value });
            }}
          />
        </div>
        <div>
          <label htmlFor="DataFinal">Data final</label>
          <Calendar
            id="DataFinal"
            dateFormat="dd/mm/yy"
            locale={locale}
            value={
              migrationDataVisible
                ? bussolaFilterData.dataFinal
                : smarttIRFilterData.dataFinal
            }
            onChange={e => {
              migrationDataVisible
                ? updateBussolaFilterData({ dataFinal: e.value })
                : updateSmarttIRFilterData({ dataFinal: e.value });
            }}
          />
        </div>
      </ContainerFiltro>

      <S.DataTableContainer className="p-mt-3">
        <DataTable
          value={
            migrationDataVisible
              ? operacoesBussola.data?.operations
              : operacoesSmarttIR.data?.operations
          }
          sortMode="multiple"
          responsiveLayout="scroll"
          emptyMessage="Lista vazia."
          dataKey="id"
          loading={isDataLoading}
          scrollable
          scrollDirection="vertical"
          scrollHeight="70vh"
          paginator
          rows={20}
          paginatorTemplate={
            migrationDataVisible
              ? bussolaPaginatorTemplate
              : smarttIRPaginatorTemplate
          }
        >
          <Column
            field="corretora"
            header="Corretora"
            sortable
            className="corTitulos"
          />
          <Column
            field="pregao"
            header="Pregão"
            sortable
            className="corTitulos"
          />
          <Column
            field="tipo_ativo"
            header="Tipo de ativo"
            sortable
            className="corTitulos"
          />
          <Column
            field="ticker"
            header="Ticker"
            sortable
            className="corTitulos"
          />
          <Column field="qtde" header="Qtde" sortable className="corTitulos" />
          <Column
            field="preco_ajuste"
            header="Preço/Ajuste"
            sortable
            className="corTitulos"
          />
          <Column
            field="custos"
            header="Custos"
            sortable
            className="corTitulos"
          />
          <Column
            field="tipo_neg"
            header="Tipo de neg."
            sortable
            className="corTitulos"
          />
          <Column
            field="operacao"
            header="Operação"
            sortable
            className="corTitulos"
          />
          <Column field="dc" header="D/C" sortable className="corTitulos" />
        </DataTable>
      </S.DataTableContainer>
    </BaseModule>
  );
}
