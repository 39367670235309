import { Dialog } from 'primereact/dialog';
import { ReactNode } from 'react';
import styled from 'styled-components';

type CancelarRenovacaoContainerProps = {
  children: ReactNode;
};

export const CancelarRenovacaoContainer = styled(
  Dialog,
)<CancelarRenovacaoContainerProps>``;

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  label {
    margin-left: 0.5rem;
  }
`;
