import { Button as PrimeReactButton } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

import { Button } from '../../../../../components/Button';
import { AVERAGE_PRICE_ERROR } from '../../../../../context/toast/constants';
import { useToast } from '../../../../../context/toast/useToast';
import { formatCurrency } from '../../../../../utils/functions';
import { formatStringValueToNumber } from '../../../../../utils/functions/currency';
import { useExecuteEventMutation } from '../hooks/useExecuteEventMutation';
import { EventModalData } from '../types';
import * as S from './styles';

interface AddBoniPriceDialogProps {
  visible: boolean;
  showDialogForm: boolean;
  eventData: EventModalData;
  onHide: () => void;
}

export function AddBoniPriceDialog({
  visible,
  showDialogForm,
  eventData,
  onHide,
}: AddBoniPriceDialogProps) {
  const [tickerPrice, setTickerPrice] = useState('');
  const executeEvent = useExecuteEventMutation(eventData.idUsuario);

  const { showToast } = useToast();

  function handleHideDialog() {
    setTickerPrice('');
    onHide();
  }

  function handleExecuteEvent(data: EventModalData, authorization: boolean) {
    const tickerPriceParsed = formatStringValueToNumber(tickerPrice);

    if (tickerPriceParsed <= 0) {
      showToast(AVERAGE_PRICE_ERROR);
      return;
    }

    const eventObj = {
      idUsuario: data.idUsuario,
      idDesdobramentoGrupamento: data.id,
      corretora: data.idCorretora,
      autorizacao: authorization,
      precoMedio: tickerPriceParsed,
    };

    executeEvent.mutate(eventObj);
    handleHideDialog();
  }

  const addBoniPriceDialogFooter = (
    <>
      <Button
        className="tertiary"
        label="Recusar"
        onClick={() => handleExecuteEvent(eventData, false)}
      />
      <PrimeReactButton
        label="Aceitar"
        onClick={() => handleExecuteEvent(eventData, true)}
      />
    </>
  );

  return (
    <S.AddBoniPriceDialogContainer
      visible={visible}
      onHide={handleHideDialog}
      header="Cadastro de bonificação: Preço do ativo"
      footer={showDialogForm ? addBoniPriceDialogFooter : null}
      style={{ width: 'min(40rem, 80vw)' }}
      modal
    >
      <S.FormContainer>
        <label htmlFor="tickerPrice">Preço do ativo recebido:</label>
        <InputText
          id="tickerPrice"
          placeholder="R$ 0,00"
          value={showDialogForm ? String(tickerPrice) : eventData.precoUsuario}
          onChange={e => setTickerPrice(formatCurrency(e.target.value))}
          disabled={!showDialogForm}
        />
      </S.FormContainer>
    </S.AddBoniPriceDialogContainer>
  );
}
