export const defaultTheme = {
  'blue-600': '#112c50',
  'blue-400': '#1b3667',
  'blue-200': '#335893',
  'blue-100': '#96b7c7',
  gray: '#F4F4F4',
  cyan: '#53c3c2',
  'cyan-opacity': '#53c3c280',
  purple: '#4d4fa1',
  salmon: '#ea736a',
  white: '#fff',
} as const;
