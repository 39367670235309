import { apiRelatorios } from '../api';
import { GetHistoricoPosicaoAbertaPorMesFiltros } from './types';

export const getHistoricoPosicaoAbertaPorMesFiltros: GetHistoricoPosicaoAbertaPorMesFiltros =
  ({ idUsuario, filtroData, data, signal }) =>
    apiRelatorios.post(
      `/HistoricoPosicaoAbertaPorMes/GetHistoricoPosicaoAbertaPorMesFiltros/${idUsuario}/${filtroData}`,
      data,
      { signal },
    );
