import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

import {
  DARF_PAYMENT_ERROR,
  DARF_PAYMENT_SUCCESS,
} from '../../../context/toast/constants';
import { useToast } from '../../../context/toast/useToast';
import { useRequest } from '../../../hooks/useRequest';
import { geracaoDarf } from '../../../service/api';
import {
  formatCurrency,
  formataStringParaDecimal,
} from '../../../utils/functions';
import Usuario from '../../Usuario';
import { ButtonContainer, Content, StyledDialog } from './styles';

export const ConfirmarPagamento = props => {
  const { closeModal, linhaSelecionada, getHistoricoDarf, mesEAno } = props;
  const valorPagoInicial = linhaSelecionada?.valorDarf
    ? linhaSelecionada.valorDarf.toString()
    : '0,00';

  const [date, setDate] = useState(new Date(linhaSelecionada.dataPagamento));
  const [valorPago, setValorPago] = useState(formatCurrency(valorPagoInicial));
  const { showToast } = useToast();
  const [postConfirmarPagamento, { loading }] = useRequest();

  const onSuccess = async () => {
    showToast(DARF_PAYMENT_SUCCESS);
    await getHistoricoDarf();
    closeModal();
  };

  const onError = () => {
    showToast(DARF_PAYMENT_ERROR);
    closeModal();
  };

  const confirmarPagamento = async () => {
    const body = {
      idUsuario: Usuario.id,
      idEmissaoDarf: linhaSelecionada.idEmissaoDarf,
      valorPago: formataStringParaDecimal(valorPago),
      dataPagamento: date,
    };

    await postConfirmarPagamento({
      config: {
        url: geracaoDarf.historicoDarfs.postConfirmacaoPagamento(),
        method: 'post',
        data: body,
      },
      onSuccess,
      onError,
    });
  };

  const salvaData = e => setDate(e.value);

  const formataValorPago = e => {
    setValorPago(formatCurrency(e.target.value));
  };

  return (
    <StyledDialog header="Confirmar pagamento do seu DARF" {...props}>
      <p>
        {Usuario.nome}, ao emitir seu DARF não será possível adicionar outras
        operações neste mês. Caso você tenha valor ou data diferente, favor
        informar abaixo.
      </p>

      <Content>
        <ul>
          <li>
            <span>Mês de apuração do DARF: </span>
            <strong>{mesEAno}</strong>
          </li>
          <li>
            <span>Nome: </span>
            <strong>{Usuario.nome}</strong>
          </li>
          <li>
            <span>CPF: </span>
            <strong>{Usuario.cpf}</strong>
          </li>
          <li>
            <span>Vencimento: </span>
            <strong>{linhaSelecionada.dataVencimento}</strong>
          </li>
          <li>
            <span>Valor da darf: </span>
            <strong>
              {formatCurrency(linhaSelecionada.valorDarf.toString(), true)}
            </strong>
          </li>
        </ul>

        <div>
          <div className="p-d-flex">
            <div className="p-flex-column p-d-flex">
              <label htmlFor="calendario">Data de pagamento</label>
              <Calendar
                id="calendario"
                dateFormat="dd/mm/yy"
                value={date}
                onChange={salvaData}
              />
            </div>
          </div>
          <div className="p-d-flex">
            <div className="p-flex-column p-d-flex">
              <label htmlFor="valorPago">Valor pago</label>
              <InputText
                id="valorPago"
                value={valorPago}
                onChange={formataValorPago}
              />
            </div>
          </div>
        </div>
      </Content>

      <ButtonContainer>
        <Button label="Cancelar" onClick={closeModal} />
        <Button
          label="Confirmar"
          onClick={confirmarPagamento}
          loading={loading}
        />
      </ButtonContainer>
    </StyledDialog>
  );
};
