import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getDayMonthFullYear } from 'smarttbot-utils';

import { BaseModule } from '../../../../components/BaseModule';
import { HISTORICO_DE_PAGAMENTO_DE_DARF } from '../../../../components/BaseModule/constants';
import { CustomTag } from '../../../../components/CustomTag';
import {
  CONFIRMAR_PAGAMENTO_DARF,
  EMAIL_ENVIADO,
} from '../../../../components/Dialogs';
import Usuario from '../../../../components/Usuario';
import { useModal } from '../../../../context/modal/useModal';
import { SEND_EMAIL_ERROR } from '../../../../context/toast/constants';
import { useToast } from '../../../../context/toast/useToast';
import { useOperationYearsFilter } from '../../../../hooks/reports/useOperationYearsFilter';
import { useRequest } from '../../../../hooks/useRequest';
import { geracaoDarf } from '../../../../service/api';
import { formataStringParaDecimal } from '../../../../utils/functions';
import {
  getValueCelula,
  getValueFinanceiroCelula,
} from '../../../../utils/functions';
import { formatStringValueToNumber } from '../../../../utils/functions/currency';
import { Container, MenuContainer, StyledRowExpansionTemplate } from './styles';

const getMesEAno = rowData => {
  const { ano, mesDesc } = rowData;

  return `${mesDesc}/${ano}`;
};

const TIPOS_TAG = [
  {
    type: '4',
    label: 'Pago com Atraso',
  },
  {
    type: '1',
    label: 'Pago',
  },
  {
    type: '2',
    label: 'Pendente',
  },
];
const CURRENT_YEAR = new Date().getFullYear();

const estaPendente = rowData => rowData.status === 'Pendente';

const valorDarfNaoEstaZerado = rowData =>
  !!formatStringValueToNumber(rowData.valorDarf);

export function HistoricoDARF() {
  const [selectedYear, setSelectedYear] = useState({
    name: String(CURRENT_YEAR),
    code: CURRENT_YEAR,
  });
  const [historico, setHistorico] = useState();
  const [linhasExpandidas, setLinhasExpandidas] = useState(null);
  const [linhaSelecionada, setLinhaSelecionada] = useState({});

  const operationYearsQuery = useOperationYearsFilter({ userId: Usuario.id });

  const { showToast } = useToast();
  const { openModal } = useModal();

  const [getHistorico] = useRequest();
  const [postEmail] = useRequest();

  const menuDarf = useRef(null);
  const menuEmissoes = useRef(null);

  const expandirTudo = () => {
    let _linhasExpandidas = {};
    historico.forEach(
      value => (_linhasExpandidas[`${value.idEmissao}`] = true),
    );

    setLinhasExpandidas(_linhasExpandidas);
  };

  const getHistoricoDarfSuccess = data => {
    const novoHistorico = data.dados.map(historico => {
      const { mesDesc } = historico;
      const novasEmissoes = historico.emissoes.map(emissao => ({
        ...emissao,
        mesDesc,
      }));

      return { ...historico, emissoes: novasEmissoes };
    });
    setHistorico(novoHistorico);
  };

  const getHistoricoDarf = useCallback(async () => {
    await getHistorico({
      config: {
        url: geracaoDarf.historicoDarfs.getHistoricoDarfPorUsuarioAno(
          Usuario.id,
          selectedYear.name,
        ),
        method: 'get',
      },
      onSuccess: getHistoricoDarfSuccess,
      onError: () => showToast(),
    });
  }, [getHistorico, selectedYear.name, showToast]);

  const recolherTudo = () => {
    setLinhasExpandidas(null);
  };

  const handleExpandirOuRecolherTudo = () => {
    !linhasExpandidas ? expandirTudo() : recolherTudo();
  };

  function alterarLinhaMostrarDetalhes(rowData, event, ref) {
    setLinhaSelecionada(rowData);
    ref.current.toggle(event);
  }

  const onEnviaEmailSuccess = () => {
    openModal(EMAIL_ENVIADO);
  };

  const onEnviaEmailError = () => {
    showToast(SEND_EMAIL_ERROR);
  };

  const enviaEmail = async () => {
    const [dia, mes, ano] = getDayMonthFullYear(
      linhaSelecionada.dataEmissao,
    ).map(data => Number(data));

    const body = {
      idUsuario: Usuario.id,
      idEmissao: linhaSelecionada.idEmissao,
      email: Usuario.email,
      dataEmissao: new Date(ano, mes - 1, dia).toISOString(),
      dadosArquivoPdf: {
        periodoApuracao: linhaSelecionada.dataApuracao,
        numeroCpfCnpj: Usuario.cpf,
        codigoDaReceita: '6015',
        numeroReferencia: '0',
        dataVencimento: linhaSelecionada.dataVencimento,
        valorPrincipal: linhaSelecionada.impostoPagar,
        valorDaMulta: formataStringParaDecimal(linhaSelecionada.multa),
        valorDosJurosEncargos: formataStringParaDecimal(linhaSelecionada.juros),
        valorTotal: formataStringParaDecimal(linhaSelecionada.valorDarf),
        autenticacao: '-',
        nome: Usuario.nome,
        telefone: Usuario.telefone,
      },
    };

    await postEmail({
      config: {
        url: geracaoDarf.historicoDarfs.postEmailEmissaoDarf(),
        method: 'post',
        data: body,
      },
      onSuccess: onEnviaEmailSuccess,
      onError: onEnviaEmailError,
    });
  };

  const itensMenuDarf = [
    {
      label: 'Enviar DARF por e-mail',
      command: () => {
        enviaEmail();
      },
    },
  ];

  const itensMenuEmissoes = [
    {
      label: 'Confirmar pagamento',
      command: () => {
        openModal(CONFIRMAR_PAGAMENTO_DARF, {
          linhaSelecionada: linhaSelecionada,
          mesEAno: getMesEAno(linhaSelecionada),
          getHistoricoDarf,
        });
      },
    },
  ];

  useEffect(() => {
    if (selectedYear.name) {
      getHistoricoDarf();
    }
  }, [getHistoricoDarf, selectedYear.name]);

  const renderMenu = (rowData, model, ref) => {
    return (
      <MenuContainer>
        <Menu model={model} popup ref={ref} id="popup_menu" />
        <Button
          label=""
          icon="pi pi-ellipsis-v"
          className="p-button-severities p-button-danger2 p-mr-2 p-mb-2"
          onClick={event => alterarLinhaMostrarDetalhes(rowData, event, ref)}
          aria-controls="popup_menu"
          aria-haspopup
          model={model}
        />
      </MenuContainer>
    );
  };

  const renderMenuDarf = rowData =>
    estaPendente(rowData) && valorDarfNaoEstaZerado(rowData)
      ? renderMenu(rowData, itensMenuDarf, menuDarf)
      : null;

  const renderMenuEmissao = rowData =>
    estaPendente(rowData) && valorDarfNaoEstaZerado(rowData)
      ? renderMenu(rowData, itensMenuEmissoes, menuEmissoes)
      : null;

  const statusTagBody = rowData => {
    const tag = TIPOS_TAG.find(tipo => tipo.label === rowData.status);

    return <CustomTag status={tag} mostrarBotaoOpcoes />;
  };

  const renderDefaultColumns = () => [
    <Column key="expander" expander />,
    <Column key="mes" header="Mês" body={getMesEAno} />,
    <Column
      key="dataEmissao"
      header="Data de emissão"
      body={getValueCelula('dataEmissao')}
    />,
    <Column
      key="vencimento"
      header="Vencimento"
      body={getValueCelula('dataVencimento')}
    />,
    <Column
      key="impostoAPagar"
      header="Imposto a pagar"
      body={getValueFinanceiroCelula('impostoPagar')}
    />,
    <Column
      key="dataDeConsolidacao"
      header="Data de consolidação"
      body={getValueCelula('dataInformada')}
    />,
    <Column
      key="multas"
      header="Multas"
      body={getValueFinanceiroCelula('multa')}
    />,
    <Column
      key="juros"
      header="Juros"
      body={getValueFinanceiroCelula('juros')}
    />,
    <Column
      key="valorDarfAPagar"
      header="Valor DARF a Pagar"
      body={getValueFinanceiroCelula('valorDarf')}
    />,
    <Column
      key="valorPago"
      header="Valor pago"
      body={getValueFinanceiroCelula('valorPago')}
    />,
    <Column
      key="pagamento"
      header="Pagamento"
      body={getValueCelula('dataPagamento')}
    />,
    <Column key="status" header="Status" body={statusTagBody} />,
  ];

  const rowExpansionTemplate = rowData => {
    return (
      <StyledRowExpansionTemplate value={rowData.emissoes} autoLayout>
        {renderDefaultColumns()}
        <Column
          body={renderMenuEmissao}
          className="cabecalhoHistorico"
        ></Column>
      </StyledRowExpansionTemplate>
    );
  };

  return (
    <BaseModule tipo={HISTORICO_DE_PAGAMENTO_DE_DARF}>
      {!operationYearsQuery.isLoading && (
        <Container>
          <div className="p-d-flex">
            <div className="p-flex-column p-d-flex">
              <Dropdown
                value={selectedYear}
                options={operationYearsQuery.data}
                onChange={e => setSelectedYear(e.value)}
                optionLabel="name"
                placeholder="Selecione o ano"
              />
            </div>
          </div>

          <Button onClick={handleExpandirOuRecolherTudo}>
            Expandir/Recolher
          </Button>
        </Container>
      )}

      <section>
        <DataTable
          value={historico}
          emptyMessage="Lista vazia."
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={linhasExpandidas}
          onRowToggle={e => setLinhasExpandidas(e.data)}
          dataKey="idEmissao"
          responsiveLayout="scroll"
        >
          {renderDefaultColumns()}
          <Column body={renderMenuDarf} className="cabecalhoHistorico"></Column>
        </DataTable>
      </section>
    </BaseModule>
  );
}
