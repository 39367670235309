import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  border: 1px solid rgba(83, 195, 194, 0.4);
  padding: 2rem;
  border-radius: 0.3rem;
  margin: 3rem 0;

  display: flex;
  align-items: flex-end;
  gap: 1rem;

  @media (max-width: 992px) {
    flex-direction: column;

    div {
      width: 100%;
    }

    button {
      text-align: center !important;
    }
  }
`;

export const FilterByDateContainer = styled.section`
  width: 100%;
  border: 1px solid rgba(83, 195, 194, 0.4);
  padding: 2rem;
  border-radius: 0.3rem;
  margin: 3rem 0;
`;

export const MonthContainer = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;

  li {
    button {
      border: 0;
      width: 4rem;
      height: 6rem;

      cursor: pointer;

      background: rgba(150, 183, 199, 0.2);
      transition: all 0.2s;

      &:hover {
        background: #335893;
        color: #fff;
      }
    }

    & + li {
      margin-top: 0.1rem;
    }
  }
`;

export const WarningMigratedUser = styled.p`
  font-weight: 300 !important ;
`;
