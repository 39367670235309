import axios from 'axios';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';

import { BaseModule } from '../../../../components/BaseModule';
import { RELATORIO_TRIBUTACAO_EXCLUSIVA } from '../../../../components/BaseModule/constants';
import Usuario from '../../../../components/Usuario';
import { useToast } from '../../../../context/toast/useToast';
import { useOperationYearsFilter } from '../../../../hooks/reports/useOperationYearsFilter';
import * as ES from '../styles';
import './tributacao.css';

const CURRENT_YEAR = new Date().getFullYear();

export const RelatorioTributacaoExclusiva = () => {
  const [dados, setDados] = useState([]);
  const [selectedYear, setSelectedYear] = useState({
    name: String(CURRENT_YEAR),
    code: CURRENT_YEAR,
  });
  const { showToast } = useToast();

  const operationYearsQuery = useOperationYearsFilter({ userId: Usuario.id });

  useEffect(() => {
    fetchInitialData(selectedYear.name);
  }, [selectedYear.name]);

  const fetchInitialData = async year => {
    const idUser = Usuario.id;
    const abortController = new AbortController();
    const signal = abortController.signal;

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_RELATORIOS}/RelatorioRendimentoTributacaoExclusiva/v1/GetRelatorioRendimentoTributacaoExclusiva/${idUser}/${year}`,
        { signal },
      );

      const dadosResponse = data.proventos.map(item => {
        return {
          id: item.idCodigoRF,
          ticker: item.idInstrumentoNavigation.ticker,
          cnpj: item.idEmpresaNavigation.cnpj,
          fontePagadora: item.idEmpresaNavigation.nomeEmpresa,
          valor: `R$ ${item.valorLiquido}`,
        };
      });

      setDados(dadosResponse);
    } catch (error) {
      console.error(error);
      showToast();
    }
  };

  return (
    <BaseModule
      tipo={RELATORIO_TRIBUTACAO_EXCLUSIVA}
      props={{ nome: 'rendimentos de tributação exclusiva' }}
      warn={
        <ES.WarningParagraph>
          *Ano-calendário: é o período de 12 meses (1º de janeiro a 31 de
          dezembro) em que foram registrados os rendimentos e despesas do
          contribuinte.
        </ES.WarningParagraph>
      }
    >
      {!operationYearsQuery.isLoading && (
        <ES.FilterByDateContainer>
          <label htmlFor="operationYears">Ano-calendário:</label>
          <Dropdown
            id="operationYears"
            value={selectedYear}
            options={operationYearsQuery.data}
            onChange={e => setSelectedYear(e.value)}
            optionLabel="name"
            placeholder="Selecione o ano"
          />
        </ES.FilterByDateContainer>
      )}

      <DataTable
        value={dados}
        dataKey="id"
        header="Juros Sobre Capital Próprio"
        responsiveLayout="scroll"
        emptyMessage="Lista vazia."
      >
        <Column field="id" header="Código" className="corTextoDados" />
        <Column field="ticker" header="Ticker" className="corTextoDados" />
        <Column field="cnpj" header="CNPJ" className="corTextoDados" />
        <Column
          field="fontePagadora"
          header="Nome da Fonte Pagadora"
          className="corTextoDados"
        />
        <Column field="valor" header="Valor" className="corTextoDados" />
      </DataTable>
    </BaseModule>
  );
};
