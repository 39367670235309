import { Dialog } from 'primereact/dialog';
import { ReactNode } from 'react';
import styled from 'styled-components';

type StatusDialogContainerProps = {
  children: ReactNode;
};

export const StatusDialogContainer = styled(Dialog)<StatusDialogContainerProps>`
  p {
    padding-block: 3rem;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }
`;
