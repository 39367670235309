import { AccordionTab } from 'primereact/accordion';

import * as S from './styles';

export function AccordionToggle({ children, headerName, ...rest }) {
  return (
    <S.AccordionToggle activeIndex={0} {...rest}>
      <AccordionTab header={headerName}>{children}</AccordionTab>
    </S.AccordionToggle>
  );
}
