import { QueryFunctionContext, useQueries } from '@tanstack/react-query';
import axios from 'axios';

import { useToast } from '../../../../../context/toast/useToast';
import { formatFilterData } from '../helpers';
import {
  DesdobramentoAgrupamentoPorFiltroResponse,
  InstrumentosUsuario,
} from '../types';

async function getDesdobramentoGrupamento({
  queryKey,
  signal,
}: QueryFunctionContext) {
  const userId = queryKey[2];

  const { data } = await axios.post<
    DesdobramentoAgrupamentoPorFiltroResponse[]
  >(
    `${process.env.REACT_APP_API_EVENTOS}/DesdobramentoGrupamento/listDesdobramentoAgrupamentoPorFiltro`,
    { idUsuario: userId },
    { signal },
  );

  return formatFilterData(data);
}

async function getInstrumentosUsuario({
  queryKey,
  signal,
}: QueryFunctionContext) {
  const userId = queryKey[3];

  const { data } = await axios.get<InstrumentosUsuario[]>(
    `${process.env.REACT_APP_API_EVENTOS}/DesdobramentoGrupamento/InstrumentosUsuario/${userId}`,
    { signal },
  );

  const formattedData = data.map(item => {
    return { label: item.ticker, value: item.idInstrumento };
  });

  return formattedData;
}

export function useDesdobramentoGrupamento(userId?: string) {
  const { showToast } = useToast();

  return useQueries({
    queries: [
      {
        queryKey: ['eventos', 'DesdobramentoGrupamento', userId],
        queryFn: getDesdobramentoGrupamento,
        enabled: !!userId,
        onError: () => showToast(),
      },
      {
        queryKey: [
          'eventos',
          'DesdobramentoGrupamento',
          'Instrumentos',
          userId,
        ],
        queryFn: getInstrumentosUsuario,
        enabled: !!userId,
        onError: () => showToast(),
      },
    ],
  });
}
