import styled from 'styled-components';

import { devices } from '../../utils/functions/styles';

export const ProventosTableContainer = styled.div`
  @media ${devices.tablet} {
    .p-datatable-tbody tr td:last-child {
      justify-content: flex-end !important;
    }
  }
`;
