import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';

import { BaseModule } from '../../../../components/BaseModule';
import { EMISSAO_DARF } from '../../../../components/BaseModule/constants';
import Usuario from '../../../../components/Usuario';
import {
  DARF_PAYMENT_WARN,
  ERROR_KEY,
  SUCCESS_KEY,
} from '../../../../context/toast/constants';
import { useToast } from '../../../../context/toast/useToast';
import { useOperationYearsFilter } from '../../../../hooks/reports/useOperationYearsFilter';
import { RowExpensiveTable } from '../../components/RowExpensiveTable';
import api from './api';
import {
  Container,
  DialogDarfIssuanceDataContainer,
  DialogFooter,
  StyledDataTable,
} from './styles';

const TOAST_LIFE = 8000;
const CURRENT_YEAR = new Date().getFullYear();

export function EmissaoDarf() {
  const [dadosUsuario, setDadosUsuario] = useState({});
  const [linhasExpandidas, setLinhasExpandidas] = useState(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenDialog2, setIsOpenDialog2] = useState(false);
  const [values, setValues] = useState([]);
  const [selectedYear, setSelectedYear] = useState({
    name: String(CURRENT_YEAR),
    code: CURRENT_YEAR,
  });
  const [darfValue, setDarfValue] = useState({});
  const [dataCon, setDataCon] = useState(null);
  const operationYearsQuery = useOperationYearsFilter({ userId: Usuario.id });

  const { showToast } = useToast();

  useEffect(() => {
    createListUser();
  }, []);

  useEffect(() => {
    listaDarfs(selectedYear.code);
  }, [selectedYear.code]);

  async function createListUser() {
    let idUsuario = Usuario.id;
    try {
      fetch(
        `${process.env.REACT_APP_API_USUARIOS}/DadosPessoais/v1/GetUsuarioDadosPessoaisByIdUsuario/${idUsuario}`,
      )
        .then(result => result.json())
        .then(data => {
          setDadosUsuario(data);
        });
    } catch (error) {
      console.error(error);
    }
  }

  async function listaDarfs(year) {
    let newValues = [];

    fetch(
      `${process.env.REACT_APP_API_RELATORIOS}/RelatorioEmissaoDarf/v1/GetRelatorioEmissaoDarfPorUsuario/${Usuario.id}/${year}`,
    )
      .then(result => result.json())
      .then(data => {
        data.dados.forEach(dado => {
          let value = {
            id: dado.mes,
            ano: dado.ano,
            mes: dado.mesDesc,
            status: dado.status,
            resultado: 'R$ ' + dado.resultadoTotal,
            prejuizo_a_compensar: 'R$ ' + dado.prejuizoCompensarTotal,
            alienacoes: 'R$ ' + dado.alienacoesSwing,
            base_calculo: 'R$ ' + dado.baseCalculoTotal,
            imposto_devido: 'R$ ' + dado.impostoDevidoTotal,
            irrf: 'R$ ' + dado.irrfAcumuladoTotal,
            darf: 'R$ ' + dado.darfAcumuladoTotal,
            imposto_a_pagar: 'R$ ' + dado.impostoPagarTotal,
            darf_paga: 'R$ ' + dado.darfPagasTotal,
            dataVencimento: dado.dataVencimento,
            dataApuracao: dado.dataApuracao,
            detalhes: [
              {
                label: 'Op. Comuns',
                resultado: 'R$ ' + dado.resultadoSwing,
                prejuizo_a_compensar: 'R$ ' + dado.prejuizoCompensarSwing,
                alienacoes: 'R$ ' + dado.alienacoesSwing,
                base_calculo: 'R$ ' + dado.baseCalculoSwing,
                imposto_devido: 'R$ ' + dado.impostoDevidoSwing,
                irrf: 'R$ ' + dado.irrfAcumuladoSwing,
                darf: 'R$ ' + dado.darfAcumuladoSwing,
                imposto_a_pagar: 'R$ ' + dado.impostoPagarSwing,
              },
              {
                label: 'Day Trade',
                resultado: 'R$ ' + dado.resultadoDayTrade,
                prejuizo_a_compensar: 'R$ ' + dado.prejuizoCompensarDayTrade,
                alienacoes: 'R$ ' + dado.alienacoesDayTrade,
                base_calculo: 'R$ ' + dado.baseCalculoDayTrade,
                imposto_devido: 'R$ ' + dado.impostoDevidoDaytrade,
                irrf: 'R$ ' + dado.irrfAcumuladoDayTrade,
                darf: 'R$ ' + dado.darfAcumuladoDayTrade,
                imposto_a_pagar: 'R$ ' + dado.impostoPagarDayTrade,
              },
              {
                label: 'F. Inv. Imob.',
                resultado: 'R$ ' + dado.resultadoFundos,
                prejuizo_a_compensar: 'R$ ' + dado.prejuizoCompensarFundos,
                alienacoes: 'R$ ' + dado.alienacoesFundos,
                base_calculo: 'R$ ' + dado.baseCalculoFundos,
                imposto_devido: 'R$ ' + dado.impostoDevidoFundos,
                irrf: 'R$ ' + dado.irrfAcumuladoFundos,
                darf: 'R$ ' + dado.darfAcumuladoFundos,
                imposto_a_pagar: 'R$ ' + dado.impostoPagarFundos,
              },
            ],
          };

          newValues.push(value);
        });

        setValues(newValues);
      })
      .catch(error => {
        showToast();
        console.error(error);
      });
  }

  const expandirTudo = () => {
    let _linhasExpandidas = {};
    values.forEach(value => (_linhasExpandidas[`${value.id}`] = true));

    setLinhasExpandidas(_linhasExpandidas);
  };

  const recolherTudo = () => {
    setLinhasExpandidas(null);
  };

  const expandirOuRecolherTudo = () => {
    !linhasExpandidas ? expandirTudo() : recolherTudo();
  };

  const lidarComEmissaoDarf = id => {
    var newDarfValue = values.find(v => v.id === id);

    setDarfValue(newDarfValue);
    setIsOpenDialog(true);
  };

  const lidarComEmissaoDarf2 = id => {
    var newDarfValue = values.find(v => v.id === id);

    setDarfValue(newDarfValue);
    setIsOpenDialog2(true);
  };

  const statusTemplate = rowData => {
    return (
      <span
        className={`status-${
          rowData.status ? rowData.status.toLowerCase() : ''
        }`}
      >
        {rowData.status}
      </span>
    );
  };

  function showModal(rowData) {
    let dataSplit = rowData.dataVencimento.split('/');
    let dia = Number.parseInt(dataSplit[0]);
    let mes = Number.parseInt(dataSplit[1]);
    let ano = Number.parseInt(dataSplit[2]);

    if (new Date(ano, mes - 1, dia) <= new Date()) {
      lidarComEmissaoDarf(rowData.id);
    } else {
      lidarComEmissaoDarf2(rowData.id);
    }
  }

  const botaoEmisaoDarf = rowData => {
    return (
      <Button onClick={() => showModal(rowData)} className="botaoImpressora">
        <i
          className="pi pi-print"
          style={{
            fontSize: '1.1rem',
          }}
        ></i>
      </Button>
    );
  };

  function onChangeHandler(e) {
    console.log(e.value);
    setSelectedYear(e.value);
  }

  async function EnviarDarfEmitidoAtraso() {
    if (
      darfValue.imposto_a_pagar === 'R$ 0,00' ||
      darfValue.imposto_a_pagar === '0,00' ||
      darfValue.imposto_a_pagar === null
    ) {
      showToast(DARF_PAYMENT_WARN, { life: TOAST_LIFE });
      setIsOpenDialog(false);
      return;
    }

    const DataInformada = new Date(dataCon);

    try {
      const darfResponse = await api.get(
        `${process.env.REACT_APP_API_GERACAODARF}/Darf/emissao?IdUsuario=${
          Usuario.id
        }&DataApuracao=${
          darfValue.dataApuracao
        }&DataInformada=${DataInformada.toLocaleDateString(
          'pt-BR',
        )}&DataVencimento=${darfValue.dataVencimento}`,
      );

      if (darfResponse.data.sucesso) {
        const idEmissao = darfResponse.data.idEmissao;
        const emailUser = Usuario.email;
        const numeroCpfCnpj = !dadosUsuario.cpf
          ? Usuario.cpf
          : dadosUsuario.cpf;

        const responsePostEmailEmissaoDarf = await api.post(
          `${process.env.REACT_APP_API_GERACAODARF}/HistoricoDarfs/v1/PostEmailEmissaoDarf`,
          {
            idUsuario: Usuario.id,
            idEmissao,
            email: emailUser,
            dataEmissao: new Date(),
            dadosArquivoPdf: {
              periodoApuracao: darfValue.dataApuracao,
              numeroCpfCnpj: numeroCpfCnpj,
              codigoDaReceita: '6015',
              numeroReferencia: '0',
              dataVencimento: darfValue.dataVencimento,
              valorPrincipal: darfValue.imposto_devido,
              valorDaMulta: darfResponse.data.valorDaMulta,
              valorDosJurosEncargos: darfResponse.data.valorDosJurosEncargos,
              valorTotal: darfResponse.data.valorTotal,
              autenticacao: '0',
              nome: Usuario.nome,
              telefone: Usuario.telefone,
            },
          },
        );

        if (responsePostEmailEmissaoDarf.status === 200) {
          showToast(SUCCESS_KEY, {
            summary: 'Enviado para o seu e-mail',
            detail: `O DARF solicitado foi enviado para o seu e-mail cadastrado na plataforma ${Usuario.email}. Lembre-se de realizar o pagamento dentro do prazo!`,
            life: TOAST_LIFE,
          });
        }
        setIsOpenDialog(false);
      }
    } catch (error) {
      console.error(error);
      showToast(ERROR_KEY, {
        detail: `Não existe imposto a pagar! Status DARF: ${darfValue.status}`,
        life: TOAST_LIFE,
      });

      setIsOpenDialog(false);
    }
  }

  async function EnviarDarfEmitidoEmDia() {
    if (
      darfValue.imposto_a_pagar === 'R$ 0,00' ||
      darfValue.imposto_a_pagar === '0,00' ||
      darfValue.imposto_a_pagar === null
    ) {
      showToast(DARF_PAYMENT_WARN, { life: TOAST_LIFE });
      setIsOpenDialog2(false);
      return;
    }

    try {
      const darfResponse = await api.get(
        `${process.env.REACT_APP_API_GERACAODARF}/Darf/emissao?IdUsuario=${Usuario.id}&DataApuracao=${darfValue.dataApuracao}&DataVencimento=${darfValue.dataVencimento}`,
      );

      if (darfResponse.data.sucesso) {
        const idEmissao = darfResponse.data.idEmissao;
        const emailUser = Usuario.email;
        const numeroCpfCnpj = !dadosUsuario.cpf
          ? Usuario.cpf
          : dadosUsuario.cpf;

        const responsePostEmailEmissaoDarf = await api.post(
          `${process.env.REACT_APP_API_GERACAODARF}/HistoricoDarfs/v1/PostEmailEmissaoDarf`,
          {
            idUsuario: Usuario.id,
            idEmissao,
            email: emailUser,
            dataEmissao: new Date(),
            dadosArquivoPdf: {
              periodoApuracao: darfValue.dataApuracao,
              numeroCpfCnpj: numeroCpfCnpj,
              codigoDaReceita: '6015',
              numeroReferencia: '0',
              dataVencimento: darfValue.dataVencimento,
              valorPrincipal: darfValue.imposto_devido,
              valorDaMulta: darfResponse.data.valorDaMulta,
              valorDosJurosEncargos: darfResponse.data.valorDosJurosEncargos,
              valorTotal: darfResponse.data.valorTotal,
              autenticacao: '0',
              nome: Usuario.nome,
              telefone: Usuario.telefone,
            },
          },
        );

        if (responsePostEmailEmissaoDarf.status === 200) {
          showToast(SUCCESS_KEY, {
            summary: 'Enviado para o seu e-mail',
            detail: `O DARF solicitado foi enviado para o seu e-mail cadastrado na plataforma ${Usuario.email}. Lembre-se de realizar o pagamento dentro do prazo!`,
            life: TOAST_LIFE,
          });
        }
        setIsOpenDialog2(false);
      }
    } catch (error) {
      console.error(error);
      showToast(ERROR_KEY, {
        detail: `Não existe imposto a pagar! Status DARF: ${darfValue.status}`,
        life: TOAST_LIFE,
      });
      setIsOpenDialog2(false);
    }
  }

  return (
    <>
      <BaseModule tipo={EMISSAO_DARF}>
        {!operationYearsQuery.isLoading && (
          <Container>
            <div className="p-d-flex">
              <div className="p-flex-column p-d-flex">
                <Dropdown
                  value={selectedYear}
                  options={operationYearsQuery.data}
                  onChange={e => onChangeHandler(e)}
                  optionLabel="name"
                  placeholder="Selecione o ano"
                />
              </div>
            </div>

            <Button onClick={expandirOuRecolherTudo}>Expandir/Recolher</Button>
          </Container>
        )}

        <section>
          <StyledDataTable
            value={values}
            responsiveLayout="scroll"
            emptyMessage="Lista vazia."
            rowExpansionTemplate={data => (
              <RowExpensiveTable
                data={data.detalhes}
                columns={[
                  { field: 'label' },
                  {
                    field: 'resultado',
                    header: 'Resultado',
                  },
                  {
                    field: 'prejuizo_a_compensar',
                    header: 'Prejuízo à compensar',
                  },
                  {
                    field: 'base_calculo',
                    header: 'Base de cálculo',
                  },
                  {
                    field: 'imposto_devido',
                    header: 'Imposto devido',
                  },
                  {
                    field: 'irrf',
                    header: 'IRRF acumulado',
                  },
                  {
                    field: 'imposto_a_pagar',
                    header: 'Imposto a pagar',
                  },
                ]}
              />
            )}
            expandedRows={linhasExpandidas}
            onRowToggle={e => setLinhasExpandidas(e.data)}
            dataKey="id"
          >
            <Column expander />
            <Column field="mes" header="Mês" />
            <Column
              field="resultado"
              header="Resultado"
              style={{ fontWeight: 'bold' }}
            />
            <Column
              field="prejuizo_a_compensar"
              header="Prejuízo à compensar"
            />
            <Column field="alienacoes" header="Alienações Isentas" />
            <Column
              field="base_calculo"
              header="Base de cálculo"
              style={{ fontWeight: 'bold' }}
            />
            <Column
              field="imposto_devido"
              header="Imposto devido"
              style={{ fontWeight: 'bold' }}
            />
            <Column field="irrf" header="IRRF acumulado" />
            <Column field="darf_paga" header="DARF Paga" />
            <Column
              field="imposto_a_pagar"
              header="Imposto a pagar"
              style={{ fontWeight: 'bold' }}
            />
            <Column field="status" header="Status" body={statusTemplate} />
            <Column field="" header="" body={botaoEmisaoDarf} />
          </StyledDataTable>
        </section>
      </BaseModule>

      <Dialog
        header={
          'Deseja fechar a apuração de ' +
          darfValue.mes +
          '/' +
          darfValue.ano +
          ' e emitir o DARF?'
        }
        visible={isOpenDialog}
        onHide={() => setIsOpenDialog(false)}
        style={{ width: '50rem' }}
      >
        <p>
          <strong>{Usuario.nome}, atenção:</strong> ao emitir seu DARF a
          apuração de IR até o mês selecionado será consolidada e{' '}
          <strong>não será possível alterar nenhuma operação anterior a</strong>{' '}
          {darfValue.mes}/{darfValue.ano}
        </p>

        <DialogDarfIssuanceDataContainer>
          <ul>
            <li>
              <span>Mês de apuração do DARF: </span>
              <strong className="corTextoPopUp">
                {darfValue.mes}/{darfValue.ano}
              </strong>
            </li>
            <li>
              <span>Nome: </span>
              <strong>{Usuario.nome}</strong>
            </li>
            <li>
              <span>CPF: </span>
              <strong>{dadosUsuario.cpf || Usuario.cpf}</strong>
            </li>
            <li>
              <span>Telefone: </span>
              <strong>{dadosUsuario.numeroCelular || Usuario.telefone}</strong>
            </li>
            <li>
              <span>Vencimento: </span>
              <strong>{darfValue.dataVencimento}</strong>
            </li>
            <li>
              <span>Valor do imposto: </span>
              <strong>{darfValue.imposto_a_pagar}</strong>
            </li>
          </ul>

          <p className="corTextoPopUp">
            A data de vencimento do imposto do mês selecionado já passou. Por
            isso, serão calculados multas e juros adicionais ao valor principal
            do imposto.
          </p>

          <div className="p-d-flex">
            <div className="p-flex-column p-d-flex">
              <label htmlFor="dataCon">
                Quando você deseja pagar seu DARF?
              </label>
              <Calendar
                showIcon
                locale="br"
                dateFormat="dd/mm/yy"
                name="dataCon"
                id="dataCon"
                value={dataCon}
                onChange={e => setDataCon(e.value)}
              />
            </div>
          </div>
        </DialogDarfIssuanceDataContainer>

        <DialogFooter>
          <Button
            label="Cancelar"
            className="p-button-text without-background"
            onClick={() => setIsOpenDialog(false)}
          />
          <Button label="Emitir DARF" onClick={EnviarDarfEmitidoAtraso} />
        </DialogFooter>
      </Dialog>

      <Dialog
        header={
          'Deseja fechar a apuração de ' +
          darfValue.mes +
          '/' +
          darfValue.ano +
          ' e emitir o DARF?'
        }
        visible={isOpenDialog2}
        onHide={() => setIsOpenDialog2(false)}
        style={{ width: '50rem' }}
      >
        <p>
          <strong>{Usuario.nome}, atenção:</strong> ao emitir seu DARF a
          apuração de IR até o mês selecionado será consolidada e{' '}
          <strong>não será possível alterar nenhuma operação anterior a</strong>{' '}
          {darfValue.mes}/{darfValue.ano}
        </p>

        <DialogDarfIssuanceDataContainer>
          <ul>
            <li>
              <span>Mês de apuração do DARF: </span>
              <strong>
                {darfValue.mes}/{darfValue.ano}
              </strong>
            </li>
            <li>
              <span>Nome: </span>
              <strong>{Usuario.nome}</strong>
            </li>
            <li>
              <span>CPF: </span>
              <strong>{dadosUsuario.cpf || Usuario.cpf}</strong>
            </li>
            <li>
              <span>Telefone: </span>
              <strong>{dadosUsuario.numeroCelular || Usuario.telefone}</strong>
            </li>
            <li>
              <span>Vencimento: </span>
              <strong>{darfValue.dataVencimento}</strong>
            </li>
            <li>
              <span>Valor do imposto: </span>
              <strong>{darfValue.imposto_a_pagar}</strong>
            </li>
          </ul>
          <p className="corTextoPopUp">
            Lembre-se de pagar o DARF gerado até o seu vencimento. Após essa
            data, serão cobrados juros e multas pelo atraso.
          </p>
        </DialogDarfIssuanceDataContainer>

        <DialogFooter>
          <Button
            label="Cancelar"
            className="p-button-text without-background"
            onClick={() => setIsOpenDialog2(false)}
          />
          <Button label="Emitir DARF" onClick={EnviarDarfEmitidoEmDia} />
        </DialogFooter>
      </Dialog>
    </>
  );
}
