import { useQuery } from '@tanstack/react-query';

import Usuario from '../components/Usuario';
import { getUsuarioConfiguracaoByIdUsuario } from '../service/ApiUsuarios/Configuracao';

export const useConfiguracaoUsuario = () => {
  const handlePosicoesAbertasMesAnoAtualRequest = async () => {
    const response = await getUsuarioConfiguracaoByIdUsuario(Usuario.id);

    return response.data;
  };

  const { isLoading, data, error } = useQuery(
    ['getUsuarioConfiguracaoByIdUsuario'],
    handlePosicoesAbertasMesAnoAtualRequest,
    {
      retry: false,
    },
  );

  return {
    loadingConfigUsuario: isLoading,
    configUsuario: data,
    errorConfigUsuario: error,
  };
};
