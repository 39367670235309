import Cookies from 'js-cookie';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { useEffect, useReducer, useState } from 'react';

import { Button } from '../../../../../../components/Button';
import {
  AVERAGE_PRICE_ERROR,
  NO_EMPTY_FIELDS_KEY,
} from '../../../../../../context/toast/constants';
import { useToast } from '../../../../../../context/toast/useToast';
import { useCacheInstrumentos } from '../../../../../../hooks/reports/useCacheInstrumentos';
import { useLocale } from '../../../../../../hooks/useLocale';
import { formatCurrency } from '../../../../../../utils/functions';
import { formatStringValueToNumber } from '../../../../../../utils/functions/currency';
import {
  formatDateInstance,
  formatDateUS,
} from '../../../../../../utils/functions/dates';
import { useExercicioOpcaoMutation } from '../../hooks/useExercicioOpcaoMutation';
import { EventsRow, ExecutaOpcaoModalObj } from '../../types';
import { CallPut } from '../../types';
import * as ES from '../styles';
import * as S from './styles';

interface ExercicioOpcaoDialogProps {
  visible: boolean;
  opcaoData: EventsRow;
  onHide: () => void;
}

type ModalValue =
  | { idUsuario: number }
  | { idCorretora: number }
  | { idInstrumentoResultado: number }
  | { idInstrumentoOpcaoParaExercicio: number }
  | { callPut: CallPut }
  | { quantidade: number | null }
  | { precoMedio: string }
  | { dataVencimento: string }
  | { dataExercicio?: Date | Date[] };

const initial_reducer_state = {
  idUsuario: null,
  idCorretora: 0,
  idInstrumentoOpcaoParaExercicio: 0,
  callPut: '' as CallPut,
  dataVencimento: '',
  dataExercicio: '',
  idInstrumentoResultado: 0,
  quantidade: null,
  precoMedio: '',
};

export function ExercerOpcaoDialog({
  visible,
  opcaoData,
  onHide,
}: ExercicioOpcaoDialogProps) {
  const userId = Cookies.get('Id');
  const [isDateError, setIsDateError] = useState(false);
  const [modalState, dispatch] = useReducer(
    (state: ExecutaOpcaoModalObj, value: ModalValue): ExecutaOpcaoModalObj => ({
      ...state,
      ...value,
    }),
    initial_reducer_state,
  );
  const { locale } = useLocale();
  const { showToast } = useToast();

  // React Query Hooks
  const instrumentosCacheQuery = useCacheInstrumentos(opcaoData.ticker);
  const exercicioOpcaoMutation = useExercicioOpcaoMutation();

  useEffect(() => {
    dispatch({
      idInstrumentoOpcaoParaExercicio: opcaoData.idInstrumento,
      dataVencimento: opcaoData.dataVencimento,
    });
  }, [opcaoData.idInstrumento, opcaoData.dataVencimento]);

  const handleNoEmptyFieldsValidation = () => {
    const {
      idInstrumentoOpcaoParaExercicio,
      idInstrumentoResultado,
      dataExercicio,
      quantidade,
      precoMedio,
    } = modalState;

    if (
      !idInstrumentoOpcaoParaExercicio ||
      !idInstrumentoResultado ||
      !dataExercicio ||
      !quantidade ||
      !precoMedio
    ) {
      showToast(NO_EMPTY_FIELDS_KEY);
      return false;
    }

    return true;
  };

  function handleDateValidation() {
    if (!modalState.dataExercicio) return;

    const startDate = new Date(formatDateInstance(opcaoData.dataPregao));
    const endDate = new Date(formatDateInstance(modalState.dataVencimento));

    const startDateParsed = Date.parse(startDate.toString());
    const endDateParsed = Date.parse(endDate.toString());
    const currentDateParsed = Date.parse(modalState.dataExercicio.toString());

    if (
      currentDateParsed >= startDateParsed &&
      currentDateParsed <= endDateParsed
    ) {
      setIsDateError(false);
      return true;
    } else {
      setIsDateError(true);
      return false;
    }
  }

  function handleAmountValidation() {
    if (!modalState.quantidade) return false;
    if (modalState.quantidade > Number(opcaoData.quantidade)) return false;
    return true;
  }

  function handleGreaterThanZeroValidation() {
    if (formatStringValueToNumber(modalState.precoMedio as string) <= 0) {
      showToast(AVERAGE_PRICE_ERROR);
      return false;
    }
    return true;
  }

  function handleAplicarExercicio() {
    if (!handleNoEmptyFieldsValidation()) return;
    if (!handleDateValidation()) return;
    if (!handleGreaterThanZeroValidation()) return;

    const dateExercicio = modalState.dataExercicio?.toLocaleString('pt-BR', {
      dateStyle: 'short',
    });
    const dateExercicioFormatted = dateExercicio && formatDateUS(dateExercicio);

    const mutationObj: ExecutaOpcaoModalObj = {
      ...modalState,
      idUsuario: Number(userId),
      idCorretora: opcaoData.idCorretora,
      callPut: opcaoData.callPut,
      dataExercicio: dateExercicioFormatted,
      dataVencimento: formatDateUS(modalState.dataVencimento),
      precoMedio: formatStringValueToNumber(modalState.precoMedio as string),
      quantidade: handleAmountValidation()
        ? modalState.quantidade
        : Number(opcaoData.quantidade),
    };

    exercicioOpcaoMutation.mutate(mutationObj);
    handleOnHide();
  }

  function handleOnHide() {
    dispatch(initial_reducer_state);
    setIsDateError(false);
    onHide();
  }

  const exercicioOpcaoDialogFooter = (
    <ES.FooterContainer>
      <span>*Preenchimento obrigatório</span>

      <div>
        <Button
          type="button"
          label="Cancelar"
          className="tertiary"
          onClick={handleOnHide}
        />
        <Button
          type="button"
          label="Aplicar"
          onClick={handleAplicarExercicio}
        />
      </div>
    </ES.FooterContainer>
  );

  return (
    <S.ExercicioOpcaoDialogContainer
      visible={visible}
      onHide={handleOnHide}
      header="Exercício de opção"
      footer={exercicioOpcaoDialogFooter}
      style={{ width: 'min(40rem, 80vw)' }}
      modal
    >
      <ES.FormContainer>
        <div>
          <label htmlFor="tickerExercicio">Ticker exercício de opção*</label>
          <InputText
            id="tickerExercicio"
            value={opcaoData.ticker}
            required
            disabled
          />
        </div>
        <div>
          <label htmlFor="tickerAdquirido">Ticker adquirido*</label>
          <Dropdown
            id="tickerAdquirido"
            options={instrumentosCacheQuery.data}
            value={modalState.idInstrumentoResultado}
            onChange={e => dispatch({ idInstrumentoResultado: e.value })}
            disabled={instrumentosCacheQuery.isLoading}
            placeholder="Selecione um ticker"
            emptyFilterMessage="Nenhum ticker encontrado"
            required
            filter
            filterInputAutoFocus
            resetFilterOnHide
          />
        </div>
        <div>
          <label htmlFor="dataExercicio">Data do exercício*</label>
          <Calendar
            id="dataExercicio"
            dateFormat="dd/mm/yy"
            locale={locale}
            value={modalState.dataExercicio as Date}
            onChange={e => dispatch({ dataExercicio: e.value })}
            required
          />
          {isDateError && (
            <ES.DateErrorParagraph>
              A data do exercício tem que contemplar um período entre a data
              pregão e o encerramento da opção.
            </ES.DateErrorParagraph>
          )}
        </div>
        <div>
          <label htmlFor="quantidade">Quantidade*</label>
          <InputNumber
            id="quantidade"
            value={modalState.quantidade}
            onChange={e => dispatch({ quantidade: e.value })}
            min={0}
            max={Number(opcaoData.quantidade)}
            placeholder={`Valor máximo: ${opcaoData.quantidade}`}
            required
          />
        </div>
        <div>
          <label htmlFor="precoMedio">Preco médio*</label>
          <InputText
            id="precomedio"
            placeholder="0,00"
            value={modalState.precoMedio}
            onChange={e =>
              dispatch({ precoMedio: formatCurrency(e.target.value) })
            }
          />
        </div>
      </ES.FormContainer>
    </S.ExercicioOpcaoDialogContainer>
  );
}
