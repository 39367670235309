import { Button as PrimeReactButton } from 'primereact/button';

import { Button } from '../../../Button';
import * as S from './styles';

const variants = [
  {
    type: 'canBeDeleted',
    header: 'Tem certeza que deseja excluir esta nota?',
    body: 'Você está prestes a excluir permanentemente uma nota de corretagem. Essa ação excluirá também todos os dados contidos nela, as operações vinculadas em conta e no cálculo, e pode alterar valores da plataforma. Você tem certeza que deseja excluí-la?',
  },
  {
    type: 'cannotBeDeleted',
    header: 'Excluir nota:',
    body: 'Esta nota não pode ser excluída, pois o imposto respectivo ao período destas operações já foi consolidado. Entre em contato com nossa equipe caso necessite de ajuda.',
  },
];

type ExcluirNotaDialogProps = {
  visible: boolean;
  canBeDeleted: 'canBeDeleted' | 'cannotBeDeleted';
  onHide: () => void;
  onDeleteHistory: () => Promise<void>;
};

export function DeleteNotaDialog({
  visible,
  canBeDeleted,
  onHide,
  onDeleteHistory,
}: ExcluirNotaDialogProps) {
  const data = variants.find(variant => variant.type === canBeDeleted);

  const canBeDeletedDialogFooter = (
    <>
      <Button
        className="tertiary"
        label="Não, voltar ao histórico de notas"
        onClick={onHide}
      />
      <PrimeReactButton label="Sim, excluir nota" onClick={onDeleteHistory} />
    </>
  );
  const cannotBeDeletedDialogFooter = (
    <>
      <PrimeReactButton label="Fechar" onClick={onHide} />
    </>
  );

  return (
    <S.DeleteNotaDialogContainer
      visible={visible}
      onHide={onHide}
      header={data?.header}
      footer={
        canBeDeleted ? canBeDeletedDialogFooter : cannotBeDeletedDialogFooter
      }
      style={{ width: 'min(40rem,  80vw)' }}
      modal
    >
      <p>{data?.body}</p>
    </S.DeleteNotaDialogContainer>
  );
}
