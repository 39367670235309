import {
  QueryFunctionContext,
  useIsFetching,
  useQuery,
} from '@tanstack/react-query';
import { useCallback, useState } from 'react';

import { BaseModule } from '../../../../components/BaseModule';
import { PATRIMONIO } from '../../../../components/BaseModule/constants';
import Usuario from '../../../../components/Usuario';
import { useToast } from '../../../../context/toast/useToast';
import { getHistoricoPosicaoAbertaPorMesFiltros } from '../../../../service/ApiRelatorios/HistoricoPosicaoAbertaPorMes';
import { getPosicoesAbertasPorUsuarioFiltroMultCorretora } from '../../../../service/ApiRelatorios/PosicoesAbertas';
import { getAnoMes } from '../../../../utils/functions/dates';
import { getMesAnoAtual } from '../../../../utils/functions/dates';
import { Alienacoes } from './Alienacoes';
import { Filtro } from './Filtro';
import { Impostos } from './Impostos';
import { MinhaCarteira } from './MinhaCarteira';
import { MinhasCorretoras } from './MinhasCorretoras';
import { PosicoesAbertas } from './PosicoesAbertas';
import { WidgetsSkeleton } from './WidgetsSkeleton';
import * as S from './styles';
import {
  Ativos,
  Corretoras,
  FiltroSelecionado,
  PosicoesAbertasData,
} from './types';

const filtraTiposDeAtivoPorQuantidade = (posicoes: Ativos) =>
  posicoes.filter(posicao => posicao.quantidade > 0);

const getIdDasCorretoras = (corretoras: Corretoras) =>
  corretoras.map(corretora => corretora.id);

export function Patrimonio() {
  const [filtroSelecionado, setFiltroSelecionado] = useState<FiltroSelecionado>(
    {
      corretoras: [],
      mesAno: null,
    },
  );

  const [ativosDoUsuario, setAtivosUsuario] = useState<Ativos>([]);

  const isLoadingFiltro = useIsFetching(['MesAnoECorretoraOperacoes']);

  const { showToast } = useToast();

  const enableFiltroQuery = () =>
    !!filtroSelecionado.corretoras?.length && !!filtroSelecionado.mesAno;

  const isMesAnoAtual = () => getMesAnoAtual() === filtroSelecionado.mesAno;

  const handleChangeFiltro = useCallback(
    (filtroSelecionado: FiltroSelecionado) => {
      setFiltroSelecionado(filtroSelecionado);
    },
    [],
  );

  const handlePosicoesAbertasSuccess = (data: PosicoesAbertasData) => {
    const { posicoes } = data;
    const ativosFiltrados = filtraTiposDeAtivoPorQuantidade(posicoes);

    setAtivosUsuario(ativosFiltrados);
  };

  const handlePosicoesAbertasMesAnoAtualRequest = async ({
    signal,
  }: QueryFunctionContext) => {
    const response = await getPosicoesAbertasPorUsuarioFiltroMultCorretora({
      idUsuario: Usuario.id,
      filtroData: getAnoMes(filtroSelecionado.mesAno),
      data: getIdDasCorretoras(filtroSelecionado.corretoras),
      signal,
    });

    return response.data;
  };

  const handleQueryError = (error: Error) => {
    showToast();
    console.error(error);
  };

  const enablePosicoesAbertasMesAnoAtualQuery = () =>
    enableFiltroQuery() && isMesAnoAtual();

  const {
    isLoading: isLoadingPosicoesAbertasMesAnoAtual,
    isFetching: isFetchingPosicoesAbertasMesAnoAtual,
  } = useQuery(
    [
      'getPosicoesAbertasPorUsuarioFiltroMultCorretora',
      filtroSelecionado.corretoras,
      filtroSelecionado.mesAno,
    ],
    handlePosicoesAbertasMesAnoAtualRequest,
    {
      onSuccess: handlePosicoesAbertasSuccess,
      onError: handleQueryError,
      enabled: enablePosicoesAbertasMesAnoAtualQuery(),
      retry: false,
      cacheTime: 0,
    },
  );

  const handlePosicoesAbertasRequest = async ({
    signal,
  }: QueryFunctionContext) => {
    const response = await getHistoricoPosicaoAbertaPorMesFiltros({
      idUsuario: Usuario.id,
      filtroData: getAnoMes(filtroSelecionado.mesAno),
      data: getIdDasCorretoras(filtroSelecionado.corretoras),
      signal,
    });

    return response.data;
  };

  const enablePosicoesAbertasQuery = () =>
    enableFiltroQuery() && !isMesAnoAtual();

  const {
    isLoading: isLoadingPosicoesAbertas,
    isFetching: isFetchingPosicoesAbertas,
  } = useQuery(
    [
      'getHistoricoPosicaoAbertaPorMesFiltros',
      filtroSelecionado.corretoras,
      filtroSelecionado.mesAno,
    ],
    handlePosicoesAbertasRequest,
    {
      onSuccess: handlePosicoesAbertasSuccess,
      onError: handleQueryError,
      enabled: enablePosicoesAbertasQuery(),
      retry: false,
      cacheTime: 0,
    },
  );

  const isLoading = () => {
    return (
      (isLoadingPosicoesAbertas && isFetchingPosicoesAbertas) ||
      (isLoadingPosicoesAbertasMesAnoAtual &&
        isFetchingPosicoesAbertasMesAnoAtual) ||
      !!isLoadingFiltro
    );
  };

  const renderWidgets = () => {
    const { mesAno, corretoras } = filtroSelecionado;

    if (isLoading()) return <WidgetsSkeleton />;

    return ativosDoUsuario.length !== 0 ? (
      <>
        <MinhaCarteira ativos={ativosDoUsuario} />
        <MinhasCorretoras
          corretorasSelecionadas={corretoras}
          ativos={ativosDoUsuario}
        />
        <Impostos mesAno={mesAno} />
        <Alienacoes mesAno={mesAno} />
        <PosicoesAbertas ativos={ativosDoUsuario} mesAno={mesAno} />
      </>
    ) : (
      <S.SemPosicoesAtivasContainer className="larguraCompleta">
        Para visualizar esse relatório é necessário ter posições abertas em sua
        carteira
      </S.SemPosicoesAtivasContainer>
    );
  };

  return (
    <BaseModule tipo={PATRIMONIO}>
      <S.Container>
        <Filtro
          onSetFiltro={handleChangeFiltro}
          filtroSelecionado={filtroSelecionado}
        />
        {renderWidgets()}
      </S.Container>
    </BaseModule>
  );
}
