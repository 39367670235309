import { Column } from 'primereact/column';
import { DataTableRowToggleParams } from 'primereact/datatable';
import { useEffect, useState } from 'react';

import { DelayRealtime } from '../../../../../components/DelayRealtime';
import { useSubscriptions } from '../../../../../context/subscriptions/SubscriptionsContext';
import { GreenBorderBox } from '../../../../../styles/elements/GreenBorderBox';
import { isMesAnoAtual } from '../../../../../utils/functions/dates';
import { Ativos } from '../types';
import { Rentabilidade } from './Rentabilidade';
import { useMarketData } from './hooks/useMarketData';
import {
  Container,
  TabelaExpandida,
  TabelaPosicoesAbertas,
  TitleContainer,
} from './styles';
import { AtivoAtualizado } from './types';
import {
  atualizaAtivosComCotacoes,
  formataCelulaNumeroParaDinheiro,
  geraColunasTabelaExpandida,
  geraTemplateDaTabela,
  getTickersDosAtivos,
} from './utils';

type PosicoesAbertasProps = {
  ativos: Ativos;
  mesAno: string | null;
};

const COTACOES_REALTIME = ['anual_ir_avancado', 'degustacao'];

export function PosicoesAbertas({ ativos, mesAno }: PosicoesAbertasProps) {
  const [dadosTabela, setDadosTabela] = useState(geraTemplateDaTabela(ativos));
  const [linhasExpandidas, setLinhasExpandidas] = useState<AtivoAtualizado[]>(
    [],
  );
  const [currentSubscription, setCurrentSubscription] = useState<
    'delay' | 'realtime'
  >('delay');
  const { setCodes, cotacoes } = useMarketData(mesAno);
  const { getCurrentSubscription } = useSubscriptions();

  useEffect(() => {
    const subscription = getCurrentSubscription();

    if (subscription !== 'NOT_FOUND') {
      if (COTACOES_REALTIME.includes(subscription.plano.billingPlanCode)) {
        setCurrentSubscription('realtime');
      }
    }
  }, [getCurrentSubscription]);

  useEffect(() => {
    const tickers = getTickersDosAtivos(ativos);

    setCodes(tickers);
  }, [ativos, setCodes]);

  useEffect(() => {
    if (cotacoes.length && dadosTabela.length) {
      const tabelaAtualizada = atualizaAtivosComCotacoes(dadosTabela, cotacoes);
      setDadosTabela(tabelaAtualizada);
    }
  }, [ativos, cotacoes]);

  const expandeTabela = (e: DataTableRowToggleParams) => {
    setLinhasExpandidas(e.data);
  };

  const templateTabelaExpandida = (rowData: AtivoAtualizado) => {
    return (
      <TabelaExpandida
        value={rowData.posicoes}
        responsiveLayout="scroll"
        emptyMessage="Lista vazia."
      >
        {geraColunasTabelaExpandida(rowData, mesAno!)}
      </TabelaExpandida>
    );
  };

  return (
    <GreenBorderBox>
      <Container>
        <div>
          <TitleContainer>
            <h2>
              <b>Posições</b> abertas
            </h2>
            <DelayRealtime variant={currentSubscription} />
          </TitleContainer>
          <div>
            <span>
              Acompanhe a rentabilidade individual de cada ativo de sua
              carteira.
            </span>
          </div>
        </div>
        <TabelaPosicoesAbertas
          value={dadosTabela}
          responsiveLayout="scroll"
          emptyMessage="Lista vazia."
          rowExpansionTemplate={templateTabelaExpandida}
          dataKey="id"
          expandedRows={linhasExpandidas}
          onRowToggle={expandeTabela}
        >
          <Column key="expander" expander />
          <Column key="tipoAtivo" field="tipoAtivo" header="Tipo de ativo" />
          <Column
            key="quantidade"
            field="quantidade"
            header="Quantidade de ativos"
          />
          <Column field="patrimonioInvestido" header="Patrimônio investido" />
          <Column
            key="patrimonioAtual"
            field="patrimonioAtual"
            header={
              isMesAnoAtual(mesAno) ? 'Patrimônio atual' : 'Patrimônio no mês'
            }
            body={formataCelulaNumeroParaDinheiro('patrimonioAtual')}
          />
          <Column
            key="rentabilidade"
            header="Rentabilidade"
            body={data =>
              data && (
                <Rentabilidade
                  porcentagem={data.rentabilidade}
                  somentePorcentagem
                />
              )
            }
          />
        </TabelaPosicoesAbertas>
      </Container>
    </GreenBorderBox>
  );
}
