import { useMemo } from 'react';

import { AccordionToggle } from '../../AccordionToggle';
import { NotaCorretagemRodapeB3 } from '../../types';
import * as S from './styles';

interface BovespaDialogFooterProps {
  data?: NotaCorretagemRodapeB3;
}

export function BovespaDialogFooter({ data }: BovespaDialogFooterProps) {
  const dataFormatted = useMemo(() => {
    if (data) {
      return {
        idNotaCorretagemRodapeB3: data[0].idNotaCorretagemRodapeB3,
        irrfdaytradeBase: data[0].irrfdaytradeBase,
        debentures: data[0].debentures,
        vendasAvista: data[0].vendasAvista,
        comprasAvista: data[0].comprasAvista,
        opcoesCompras: data[0].opcoesCompras,
        opcoesVendas: data[0].opcoesVendas,
        operacoesTermo: data[0].operacoesTermo,
        valorOperacoesTituloPublico: data[0].valorOperacoesTituloPublico,
        valorOperacoes: data[0].valorOperacoes,
        projecao: data[0].projecao,
        totalCblcdc: data[0].totalCblcdc,
        valorLiquidoOperacoes: data[0].valorLiquidoOperacoes,
        valorLiquidoOperacoesDc: data[0].valorLiquidoOperacoesDc,
        taxaRegistro: data[0].taxaRegistro,
        totalCblc: data[0].totalCblc,
        taxaLiquidacao: data[0].taxaLiquidacao,
        taxaTermoOpcoesFuturos: data[0].taxaTermoOpcoesFuturos,
        taxaAna: data[0].taxaAna,
        emolumentos: data[0].emolumentos,
        totalBovespaSoma: data[0].totalBovespaSoma,
        execucaoCorretagem: data[0].execucaoCorretagem,
        taxaOperacionalClearing: data[0].taxaOperacionalClearing,
        impostosIss: data[0].impostosIss,
        irrf: data[0].irrf,
        outros: data[0].outros,
        totalCustosDespesas: data[0].totalCustosDespesas,
        liquido: data[0].liquido,
        liquidoDc: data[0].liquidoDc,
        liquidoData: data[0].liquidoData,
      };
    } else {
      return null;
    }
  }, [data]);

  if (!dataFormatted) return <p>Carregando...</p>;

  return (
    <>
      <AccordionToggle className="mobile" headerName="Rodapé">
        <S.BovespaDialogFooterContainer>
          <div>
            <h3>Resumo dos Negócios</h3>
            <S.ResumoNegociosContainer>
              <div>
                <p>Debêntures</p>
                <p>Vendas à vista</p>
                <p>Compras à vista</p>
                <p>Opção - compras</p>
                <p>Opção - vendas</p>
                <p>Operações de termo</p>
                <p>Valor das oper. c/ títulos públ. (v. nom.)</p>
                <p>Valor das operações</p>
                <p>IRRF daytrade base</p>
                <p>Projeção</p>
              </div>
              <div>
                <div>
                  <p>{dataFormatted.debentures}</p>
                  <p>{dataFormatted.debentures}</p>
                  <p>{dataFormatted.vendasAvista}</p>
                  <p>{dataFormatted.comprasAvista}</p>
                  <p>{dataFormatted.opcoesCompras}</p>
                  <p>{dataFormatted.opcoesVendas}</p>
                  <p>{dataFormatted.operacoesTermo}</p>
                  <p>{dataFormatted.valorOperacoesTituloPublico}</p>
                  <p>{dataFormatted.valorOperacoes}</p>
                  <p>{dataFormatted.irrfdaytradeBase}</p>
                  <p>{dataFormatted.projecao}</p>
                </div>
              </div>
            </S.ResumoNegociosContainer>
          </div>
          <div>
            <h3>Resumo Financeiro</h3>
            <S.ResumoFinanceiroContainer>
              <div>
                <p>CBLC</p>
                <div>
                  <span></span>
                  <span>{dataFormatted.totalCblcdc}</span>
                </div>
              </div>
              <div>
                <p>Valor líquido das operações</p>
                <div>
                  <span>{dataFormatted.valorLiquidoOperacoes}</span>
                  <span>{dataFormatted.valorLiquidoOperacoesDc}</span>
                </div>
              </div>
              <div>
                <p>Taxa de liquidação*</p>
                <div>
                  <span>{dataFormatted.taxaLiquidacao}</span>
                  <span>D</span>
                </div>
              </div>
              <div>
                <p>Taxa de registro</p>
                <div>
                  <span>{dataFormatted.taxaRegistro}</span>
                  <span>D</span>
                </div>
              </div>
              <div>
                <p>Total CBLC</p>
                <div>
                  <span>{dataFormatted.totalCblc}</span>
                  <span>C</span>
                </div>
              </div>
              <div>
                <p>Bovespa/soma</p>
                <div>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div>
                <p>Taxa de termo/opções</p>
                <div>
                  <span>{dataFormatted.taxaTermoOpcoesFuturos}</span>
                  <span>D</span>
                </div>
              </div>
              <div>
                <p>Taxa A.N.A</p>
                <div>
                  <span>{dataFormatted.taxaAna}</span>
                  <span>D</span>
                </div>
              </div>
              <div>
                <p>Emolumentos</p>
                <div>
                  <span>{dataFormatted.emolumentos}</span>
                  <span>D</span>
                </div>
              </div>
              <div>
                <p>Total Bovespa/soma</p>
                <div>
                  <span>{dataFormatted.totalBovespaSoma}</span>
                  <span>D</span>
                </div>
              </div>
              <div>
                <p>Corretagem/despesas</p>
                <div>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div>
                <p>Taxa Operacional</p>
                <div>
                  <span>{dataFormatted.taxaOperacionalClearing}</span>
                  <span>D</span>
                </div>
              </div>
              <div>
                <p>Impostos (ISS)</p>
                <div>
                  <span>{dataFormatted.impostosIss}</span>
                  <span>D</span>
                </div>
              </div>
              <div>
                <p>I.R.R.F s/ operações, base R$ 0,00</p>
                <div>
                  <span>{dataFormatted.irrf}</span>
                  <span></span>
                </div>
              </div>
              <div>
                <p>Despesas/outras</p>
                <div>
                  <span>{dataFormatted.outros}</span>
                  <span>D</span>
                </div>
              </div>
              <div>
                <p>Total corretagem/despesa</p>
                <div>
                  <span>{dataFormatted.totalCustosDespesas}</span>
                  <span>D</span>
                </div>
              </div>
              <div>
                <p>
                  Líquido para * <span>{dataFormatted.liquidoData}</span>
                </p>
                <div>
                  <span>{dataFormatted.liquido}</span>
                  <span>{dataFormatted.liquidoDc}</span>
                </div>
              </div>
            </S.ResumoFinanceiroContainer>
          </div>
        </S.BovespaDialogFooterContainer>
      </AccordionToggle>
      <S.BovespaDialogFooterContainer className="desktop">
        <div>
          <h3>Resumo dos Negócios</h3>
          <S.ResumoNegociosContainer>
            <div>
              <p>Debêntures</p>
              <p>Vendas à vista</p>
              <p>Compras à vista</p>
              <p>Opção - compras</p>
              <p>Opção - vendas</p>
              <p>Operações de termo</p>
              <p>Valor das oper. c/ títulos públ. (v. nom.)</p>
              <p>Valor das operações</p>
              <p>IRRF daytrade base</p>
              <p>Projeção</p>
            </div>
            <div>
              <div>
                <p>{dataFormatted.debentures}</p>
                <p>{dataFormatted.vendasAvista}</p>
                <p>{dataFormatted.comprasAvista}</p>
                <p>{dataFormatted.opcoesCompras}</p>
                <p>{dataFormatted.opcoesVendas}</p>
                <p>{dataFormatted.operacoesTermo}</p>
                <p>{dataFormatted.valorOperacoesTituloPublico}</p>
                <p>{dataFormatted.valorOperacoes}</p>
                <p>{dataFormatted.irrfdaytradeBase}</p>
                <p>{dataFormatted.projecao}</p>
              </div>
            </div>
          </S.ResumoNegociosContainer>
        </div>
        <div>
          <h3>Resumo Financeiro</h3>
          <S.ResumoFinanceiroContainer>
            <div>
              <p>CBLC</p>
              <div>
                <span></span>
                <span>{dataFormatted.totalCblcdc}</span>
              </div>
            </div>
            <div>
              <p>Valor líquido das operações</p>
              <div>
                <span>{dataFormatted.valorLiquidoOperacoes}</span>
                <span>{dataFormatted.valorLiquidoOperacoesDc}</span>
              </div>
            </div>
            <div>
              <p>Taxa de liquidação*</p>
              <div>
                <span>{dataFormatted.taxaLiquidacao}</span>
                <span>D</span>
              </div>
            </div>
            <div>
              <p>Taxa de registro</p>
              <div>
                <span>{dataFormatted.taxaRegistro}</span>
                <span>D</span>
              </div>
            </div>
            <div>
              <p>Total CBLC</p>
              <div>
                <span>{dataFormatted.totalCblc}</span>
                <span>C</span>
              </div>
            </div>
            <div>
              <p>Bovespa/soma</p>
              <div>
                <span></span>
                <span></span>
              </div>
            </div>
            <div>
              <p>Taxa de termo/opções</p>
              <div>
                <span>{dataFormatted.taxaTermoOpcoesFuturos}</span>
                <span>D</span>
              </div>
            </div>
            <div>
              <p>Taxa A.N.A</p>
              <div>
                <span>{dataFormatted.taxaAna}</span>
                <span>D</span>
              </div>
            </div>
            <div>
              <p>Emolumentos</p>
              <div>
                <span>{dataFormatted.emolumentos}</span>
                <span>D</span>
              </div>
            </div>
            <div>
              <p>Total Bovespa/soma</p>
              <div>
                <span>{dataFormatted.totalBovespaSoma}</span>
                <span>D</span>
              </div>
            </div>
            <div>
              <p>Corretagem/despesas</p>
              <div>
                <span></span>
                <span></span>
              </div>
            </div>
            <div>
              <p>Taxa Operacional</p>
              <div>
                <span>{dataFormatted.taxaOperacionalClearing}</span>
                <span>D</span>
              </div>
            </div>
            <div>
              <p>Impostos (ISS)</p>
              <div>
                <span>{dataFormatted.impostosIss}</span>
                <span>D</span>
              </div>
            </div>
            <div>
              <p>I.R.R.F s/ operações, base R$ 0,00</p>
              <div>
                <span>{dataFormatted.irrf}</span>
                <span></span>
              </div>
            </div>
            <div>
              <p>Despesas/outras</p>
              <div>
                <span>{dataFormatted.outros}</span>
                <span>D</span>
              </div>
            </div>
            <div>
              <p>Total corretagem/despesa</p>
              <div>
                <span>{dataFormatted.totalCustosDespesas}</span>
                <span>D</span>
              </div>
            </div>
            <div>
              <p>
                Líquido para * <span>{dataFormatted.liquidoData}</span>
              </p>
              <div>
                <span>{dataFormatted.liquido}</span>
                <span>{dataFormatted.liquidoDc}</span>
              </div>
            </div>
          </S.ResumoFinanceiroContainer>
        </div>
      </S.BovespaDialogFooterContainer>
    </>
  );
}
