import { Dialog } from 'primereact/dialog';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface ExercicioOpcaoDialogContainerProps {
  children: ReactNode;
}

export const ExercicioOpcaoDialogContainer = styled(
  Dialog,
)<ExercicioOpcaoDialogContainerProps>``;
