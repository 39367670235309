import { Toast } from 'primereact/toast';
import { useRef } from 'react';

import { ERROR_KEY, LIFE, getToastInfo } from './constants';
import { ShowToast, ToastInfo, ToastProviderProps } from './types';
import { ToastContext } from './useToast';

const ToastProvider = ({ children }: ToastProviderProps) => {
  const toastRef = useRef<Toast>(null);

  const showToast: ShowToast = (key = ERROR_KEY, setInfo = {} as ToastInfo) => {
    const { severity, summary, detail } = getToastInfo(key);

    const {
      severity: setSeverity,
      summary: setSummary,
      detail: setDetail,
      life: setLife,
    } = setInfo;

    toastRef.current?.show({
      severity: setSeverity || severity,
      summary: setSummary || summary,
      detail: setDetail || detail,
      life: setLife || LIFE,
    });
  };

  return (
    <ToastContext.Provider
      value={{ showToast, currentToast: toastRef.current }}
    >
      {children}
      <Toast ref={toastRef} />
    </ToastContext.Provider>
  );
};

export default ToastProvider;
