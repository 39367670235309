import { Dropdown } from 'primereact/dropdown';
import { PaginatorTemplate } from 'primereact/paginator';

export const paginatorTemplate = {
  layout:
    'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
  FirstPageLink: options => {
    return (
      <button
        type="button"
        className={options.className}
        onClick={options.onClick}
        disabled={options.disabled}
      >
        <span className="pi pi-step-backward"></span>
      </button>
    );
  },
  LastPageLink: options => {
    return (
      <button
        type="button"
        className={options.className}
        onClick={options.onClick}
        disabled={options.disabled}
      >
        <span className="pi pi-step-forward"></span>
      </button>
    );
  },
  NextPageLink: options => {
    return (
      <button
        type="button"
        className={options.className}
        onClick={options.onClick}
        disabled={options.disabled}
      >
        <span className="pi pi-caret-right"></span>
      </button>
    );
  },
  PrevPageLink: options => {
    return (
      <button
        type="button"
        className={options.className}
        onClick={options.onClick}
        disabled={options.disabled}
      >
        <span className="pi pi-caret-left"></span>
      </button>
    );
  },
  RowsPerPageDropdown: options => {
    const dropdownOptions = [
      { label: 5, value: 5 },
      { label: 10, value: 10 },
      { label: 15, value: 15 },
      { label: 20, value: 20 },
      { label: 50, value: 50 },
    ];

    return (
      <Dropdown
        value={options.value}
        options={dropdownOptions}
        onChange={options.onChange}
      />
    );
  },
} as PaginatorTemplate;
