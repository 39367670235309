import styled from 'styled-components';

type ContainerType = { porcentagem: number | string };

export const Container = styled.div<ContainerType>`
  display: flex;
  align-items: center;
  gap: 1rem;

  > div.container-porcentagem {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    color: ${props => (props.porcentagem >= 0 ? '#4caf50' : '#ea736a')};
  }
`;
