import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { useState } from 'react';

import {
  CANCEL_SUBSCRIPTION_EMAIL,
  SEND_EMAIL_ERROR,
} from '../../../../../context/toast/constants';
import { useToast } from '../../../../../context/toast/useToast';
import * as S from './styles';

const options = [
  {
    key: 'option1',
    name: 'A versão gratuita me atende',
  },
  {
    key: 'option2',
    name: 'Vou usar outra ferramenta gratuita',
  },
  {
    key: 'option3',
    name: 'Vou usar outra ferramenta paga',
  },
  {
    key: 'option4',
    name: 'Parei de investir na Bolsa',
  },
  {
    key: 'option5',
    name: 'Não consegui usar a plataforma',
  },
  {
    key: 'option6',
    name: 'Não estou satisfeito com a plataforma',
  },
];

type CancelarRenovacaoDialogProps = {
  visible: boolean;
  onHide: () => void;
  onCancelarRenovacao: (selectedOption: string) => Promise<boolean>;
};

export function CancelarRenovacaoDialog({
  visible,
  onHide,
  onCancelarRenovacao,
}: CancelarRenovacaoDialogProps) {
  const [selectedOption, setSelectedOption] = useState(options[0].name);
  const { showToast } = useToast();

  const handleCancelarRenovacao = async () => {
    const result = await onCancelarRenovacao(selectedOption);

    result ? showToast(CANCEL_SUBSCRIPTION_EMAIL) : showToast(SEND_EMAIL_ERROR);
  };

  const cancelarRenovacaoFooter = (
    <footer>
      <Button type="button" onClick={handleCancelarRenovacao}>
        Cancelar renovação
      </Button>
    </footer>
  );

  return (
    <S.CancelarRenovacaoContainer
      header="Antes de prosseguir, poderia nos contar o motivo do cancelamento?"
      visible={visible}
      onHide={onHide}
      footer={cancelarRenovacaoFooter}
      style={{ width: 'min(40rem, 80vw)' }}
      modal
    >
      <S.RadioButtonContainer>
        {options.map(({ key, name }) => (
          <div key={key}>
            <RadioButton
              inputId={key}
              value={name}
              checked={selectedOption === name}
              onChange={e => setSelectedOption(e.value)}
            />
            <label htmlFor={key}>{name}</label>
          </div>
        ))}
      </S.RadioButtonContainer>
    </S.CancelarRenovacaoContainer>
  );
}
