import styled from 'styled-components';

import { CHART_BACKGROUND_COLOR } from '../constants';

const getChartColor = (index: number) => {
  if (index !== 0 && index >= CHART_BACKGROUND_COLOR.length) {
    return '#495057';
  }

  return CHART_BACKGROUND_COLOR[index];
};

export const Container = styled.div`
  .header {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h2 {
      font-size: 18px !important;
    }

    .p-skeleton {
      margin-top: 8px;
    }

    & > div:last-child {
      display: flex;
      flex-direction: column;
      width: fit-content;
      align-items: center;
      margin: 0 auto;
      font-size: 18px;

      span {
        color: #112c50;
        font-weight: 500;
      }
    }

    @media (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;

      & > div:last-child {
        margin-right: 0;
      }
    }
  }

  .grafico {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    .skeletonLegenda {
      display: flex;
      gap: 8px;
      margin-bottom: 24px;

      @media (min-width: 992px) {
        flex-direction: column;
        margin-bottom: 0px;
      }
    }

    .skeletonGrafico {
      align-self: center;
      margin: 0 auto;
    }

    @media (min-width: 992px) {
      flex-direction: row;
      align-items: center;
    }
  }
`;

export const ContainerLegenda = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 8px;

  @media (min-width: 992px) {
    flex-direction: column;
  }
`;

export const ItemLegenda = styled.li.attrs<
  { index: number },
  { backgroundColor: string }
>(props => ({
  backgroundColor: getChartColor(props.index),
}))<{ index: number }>`
  color: rgba(17, 44, 80, 1);
  font-size: 14px;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${props => props.backgroundColor};
    display: inline-block;
    margin-right: 4px;
  }
`;
