export const statusPendente = [
  'Processada',
  'Falha',
  'DARF Emitido',
  'DARF Gerado',
  'Sem Calculo IR',
  'Sem Fechamento Day Trade',
  'Sem Fechamento Swing Trade',
  'Sucesso',
  'Nota Duplicada',
  'Falha ao Processar Arquivo',
  'Limite Corretoras Plano ultrapassado',
  'Limite CPF Plano ultrapassado',
  'Limite Tipo Negócio Plano ultrapassado',
  'Limite Tipo Nota Plano ultrapassado',
  'Limite Operacoes Plano ultrapassado',
  'Limite Importacao Plano ultrapassado',
  'Nota Duplicada',
  'Emissao Efetuado apos Periodo',
  'Nota Posterior Data Referencia',
];

export const statusRejeitada = [
  'Nota DuplicadaNota',
  'DuplicadaNDPL',
  'Nota Duplicada',
  'Falha ao Processar Arquivo',
  'Limite Corretoras Plano ultrapassado',
  'Limite CPF Plano ultrapassado',
  'Limite Tipo Negócio Plano ultrapassado',
  'Limite Tipo Nota Plano ultrapassado',
  'Limite Operacoes Plano ultrapassado',
  'Limite Importacao Plano ultrapassado',
  'Nota Duplicada',
  'Emissao Efetuado apos Periodo',
  'Nota Posterior Data Referencia',
];

export const statusProcessada = ['Sucesso', 'Processada'];
