import styled from 'styled-components';

import { pxToRem } from '../../../../utils/functions/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 992px) {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(12, 1fr);

    & > div:nth-child(1) {
      grid-column: 1 / 13;
    }

    & > div:nth-child(2) {
      grid-column: 1 / 7;
    }

    & > div:nth-child(2).larguraCompleta {
      grid-column: 1 / 13;
    }

    & > div:nth-child(3) {
      grid-column: 7 / 13;
    }

    & > div:nth-child(4) {
      grid-column: 1 / 5;
    }

    & > div:nth-child(5) {
      grid-column: 5 / 13;
    }

    & > div:nth-child(6) {
      grid-column: 1 / 13;
    }
  }
`;

export const SemPosicoesAtivasContainer = styled.div`
  padding: 1rem 1.3rem;
  border-radius: 3px;
  border: 1px solid rgba(83, 195, 194, 0.4);
  background-color: #fff;
  font-weight: 300;
  font-size: 1rem;
  line-height: ${pxToRem(24)};
  color: #000;
`;
