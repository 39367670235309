import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { useToast } from '../../../context/toast/useToast';
import { NotaCorretagem } from '../types';

async function getOperacoesNotaCorretagem({
  queryKey,
  signal,
}: QueryFunctionContext): Promise<NotaCorretagem> {
  const idNotaCorretagem = queryKey[1];

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_OPERACOES}/NotaCorretagem/v2/GetNotaCorretagem/${idNotaCorretagem}`,
    { signal },
  );

  return data;
}

export function useNotaCorretagemData(idNotaCorretagem: number | null) {
  const { showToast } = useToast();

  return useQuery<NotaCorretagem, Error>(
    ['notaCorretagem', idNotaCorretagem],
    getOperacoesNotaCorretagem,
    {
      onError: () => showToast(),
      enabled: !!idNotaCorretagem,
      staleTime: 1000 * 30, // 30s
    },
  );
}
