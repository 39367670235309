import { AutoCompleteBDI } from '@dev_bdi/bdi-front-dspack';
import axios from 'axios';
import { addLocale } from 'primereact/api';
//#region  Import Componentes
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';

import Usuario from '../../../../../src/components/Usuario';
import { BaseModule } from '../../../../components/BaseModule';
import { RELATORIO_DE_PERFORMANCE } from '../../../../components/BaseModule/constants';
import { ContainerFiltro } from '../../../../components/ContainerFiltro';
import { useToast } from '../../../../context/toast/useToast';
//#endregion
//#region  Import Style Css
import * as S from './styles';

//#endregion

export function RelatorioPerformance() {
  //#region  Estados dos Componentes
  const [performance, SetPerformance] = useState([]);
  const [corretoras, SetCorretoras] = useState([]);
  const [corretora, SetCorretora] = useState(0);
  const [SubTipos, SetSubTipos] = useState([]);
  const [SubTipo, SetSubTipo] = useState(0);
  const [tiponegocios, SetTiponegocios] = useState([]);
  const [tiponegocio, SetTiponegocio] = useState(0);
  const [listainstrumentos, setListainstrumentos] = useState([]);
  const [instrumento, setinstrumento] = useState('');
  const [dataInicial, SetDataInicial] = useState('');
  const [dataFinal, SetDataFinal] = useState('');

  const [ResultadoSwingTotal, SetResultadoSwingTotal] =
    useState('R$ 000.000,00');
  const [ResultadoDayTradeTotal, SetResultadoDayTradeTotal] =
    useState('R$ 000.000,00');
  const [ResultadoTotal, SetResultadoTotal] = useState('R$ 000.000,00');

  const { showToast } = useToast();
  //#endregion

  //#region Objeto de consulta Inicial
  const objInicial = {
    idUsuario: Usuario.id,
    corretora: 0,
    subTipoInstrumento: 0,
    tipoNegocio: 0,
    instrumento: 0,
    dataInicial: null,
    dataFinal: null,
  };
  //#endregion

  addLocale('br', {
    firstDayOfWeek: 1,
    dayNames: [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'JAN',
      'FEV',
      'MAR',
      'ABR',
      'MAY',
      'JUN',
      'JUL',
      'AGO',
      'SET',
      'OUT',
      'NOV',
      'DEZ',
    ],
    today: 'Hoje',
  });

  //#region Métodos de Consulta API
  const GetDadosRelatorioPerformance = async dadosFiltro => {
    const retorno = [];
    let resultadoSwingLocal = 0.0;
    let resultadoDayTradeLocal = 0.0;
    let resultadoTotalLocal = 0.0;

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_RELATORIOS}/RelatorioPerformance/v1/GetRelatorioPerformancePorFiltro`,
        dadosFiltro,
      );

      if (data !== null) {
        resultadoSwingLocal = data.totalOperacoesComuns;
        resultadoDayTradeLocal = data.totalOperacoesDayTrade;
        resultadoTotalLocal = data.totalResultado;

        data.fechamentos.map(item => {
          if (item !== null) {
            let dataFormat = null;

            if (item.idNotaCorretagemNavigation !== null) {
              dataFormat = new Date(item.idNotaCorretagemNavigation.dataPregao);
            }

            let pmCompra = `R$ ${
              item.pmCompras === null ? '0.00' : item.pmCompras
            }`;
            let pmVenda = `R$ ${
              item.pmVendas === null ? '0.00' : item.pmVendas
            }`;
            let resultado = `R$ ${
              item.resultadoFinanceiro === null
                ? '0.00'
                : item.resultadoFinanceiro
            }`;

            let unidade = {
              corretora:
                item.idCorretoraNavigation === null
                  ? 'Não Identificado'
                  : item.idCorretoraNavigation.nome,
              fechamento:
                dataFormat === null
                  ? '--/--/--'
                  : dataFormat.toLocaleDateString(),
              tipo_ativo:
                item.idSubTipoInstrumentoNavigation === null
                  ? 'Não Identificado'
                  : item.idSubTipoInstrumentoNavigation.nome,
              ticker:
                item.idInstrumentoNavigation === null
                  ? 'Não Identificado'
                  : item.idInstrumentoNavigation.ticker,
              tipo_neg:
                item.idTipoNegocioNavigation === null
                  ? 'Não Identificado'
                  : item.idTipoNegocioNavigation.nome,
              pr_medio_compra: pmCompra,
              pr_medio_venda: pmVenda,
              resultadoF: resultado,
              resultado: `${
                item.resultadoFinanceiroPercentual === null
                  ? '0'
                  : item.resultadoFinanceiroPercentual
              } %`,
              dc: item.debitoCredito === null ? '-' : item.debitoCredito,
            };
            retorno.push(unidade);
          }
        });

        let saidaResultadoSwing = `R$ ${resultadoSwingLocal}`;
        SetResultadoSwingTotal(saidaResultadoSwing);

        let saidaResultadoDayTrade = `R$ ${resultadoDayTradeLocal}`;
        SetResultadoDayTradeTotal(saidaResultadoDayTrade);

        let saidaResultadoTotal = `R$ ${resultadoTotalLocal}`;
        SetResultadoTotal(saidaResultadoTotal);
      }

      SetPerformance(retorno);
    } catch (error) {
      console.error(error);
      showToast();
    }
  };

  const GetCorretorasFechamento = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_RELATORIOS}/RelatorioPerformance/v1/GetCorretorasRelatorioPerformancePorIdusuario/` +
          objInicial.idUsuario,
      );

      const dropdownOptions = [];
      data.corretoras.map(item => {
        let opcao = { label: item.nome, value: item.idCorretora };
        dropdownOptions.push(opcao);
      });

      SetCorretoras(dropdownOptions);
    } catch (error) {
      console.error(error);
      showToast();
    }
  };

  const GetSubTipoInstrumentos = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_RELATORIOS}/RelatorioPerformance/v1/GetSubTipoInstrumentoRelatorioPerformancePorIdusuario/` +
          objInicial.idUsuario,
      );
      const dropdownOptions = [];
      data.instrumentos.map(item => {
        let opcao = { label: item.nome, value: item.idSubTipoInstrumento };
        dropdownOptions.push(opcao);
      });
      SetSubTipos(dropdownOptions);
    } catch (error) {
      console.error(error);
      showToast();
    }
  };

  const GetTipoNegocio = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_RELATORIOS}/RelatorioPerformance/v1/GetTipoNegocioRelatorioPerformancePorIdusuario/` +
          objInicial.idUsuario,
      );
      const dropdownOptions = [];
      data.negocios.map(item => {
        let opcao = { label: item.nome, value: item.idTipoNegocio };
        dropdownOptions.push(opcao);
      });
      SetTiponegocios(dropdownOptions);
    } catch (error) {
      console.error(error);
      showToast();
    }
  };

  const consultaInstrumentos = async event => {
    try {
      const query = event && event.query.toLowerCase();
      const response = await axios.get(
        `${process.env.REACT_APP_API_RELATORIOS}/RelatorioPerformance/v1/GetInstrumentoRelatorioPerformancePorIdusuario/` +
          objInicial.idUsuario,
      );
      const filterList = response.data.instrumentos.filter(dado => {
        return dado.ticker.toLowerCase().indexOf(query) === 0;
      });
      const obj = filterList.map(dado => {
        return {
          name: dado.ticker,
          code: dado.idInstrumento,
        };
      });
      setListainstrumentos(obj);
    } catch (error) {
      console.error(error);
      showToast();
    }
  };

  const LimpaCamposFiltro = () => {
    SetCorretora(0);
    SetSubTipo(0);
    SetTiponegocio(0);
    setinstrumento([]);
    SetDataInicial('');
    SetDataFinal('');
  };

  const FiltraDados = () => {
    let datainicialFormatada = '';
    let dataFinalFormatada = '';
    if (dataInicial !== '') {
      let yourDate = new Date(dataInicial);
      datainicialFormatada = yourDate.toISOString().split('T')[0];
    }

    if (dataFinal !== '') {
      let yourDate = new Date(dataFinal);
      dataFinalFormatada = yourDate.toISOString().split('T')[0];
    }

    let dadosFiltroLocal = {
      idUsuario: Usuario.id,
      corretora: corretora === undefined ? 0 : corretora,
      subTipoInstrumento: SubTipo === undefined ? 0 : SubTipo,
      tipoNegocio: tiponegocio === undefined ? 0 : tiponegocio,
      instrumento:
        instrumento === '' || instrumento.code === undefined
          ? 0
          : instrumento.code,
      dataInicial: dataInicial === '' ? null : datainicialFormatada,
      dataFinal: dataFinal === '' ? null : dataFinalFormatada,
    };
    GetDadosRelatorioPerformance(dadosFiltroLocal);
    LimpaCamposFiltro();
  };
  //#endregion

  //#region  Use Effect
  useEffect(() => {
    GetDadosRelatorioPerformance(objInicial);
    GetCorretorasFechamento();
    GetSubTipoInstrumentos();
    GetTipoNegocio();
  }, []);
  //#endregion

  return (
    <BaseModule tipo={RELATORIO_DE_PERFORMANCE}>
      <ContainerFiltro
        onLeftButtonClick={LimpaCamposFiltro}
        onRightButtonClick={FiltraDados}
      >
        <div>
          <label htmlFor="corretora">Corretora</label>
          <Dropdown
            id="corretora"
            placeholder="Corretora"
            value={corretora}
            onChange={e => SetCorretora(e.value)}
            options={corretoras}
          />
        </div>
        <div>
          <label htmlFor="tipoDeAtivos">Tipo de Ativos</label>
          <Dropdown
            id="tipoDeAtivos"
            placeholder="Tipo de Ativos"
            value={SubTipo}
            onChange={e => SetSubTipo(e.value)}
            options={SubTipos}
          />
        </div>
        <div>
          <label htmlFor="tipoDeNegocio">Tipo de negocio</label>
          <Dropdown
            id="tipoDeNegocio"
            placeholder="Tipo de negocio"
            value={tiponegocio}
            onChange={e => SetTiponegocio(e.value)}
            options={tiponegocios}
          />
        </div>
        <div>
          <label htmlFor="ticker">Ticker</label>
          <AutoCompleteBDI
            id="ticker"
            placeholder="Ticker"
            valor={instrumento}
            acao={e => setinstrumento(e.value)}
            sugestoes={listainstrumentos}
            metodocompleto={consultaInstrumentos}
            campo="name"
          />
        </div>
        <div>
          <label htmlFor="dataInicial">Data Inicial</label>
          <Calendar
            id="dataInicial"
            placeholder="Data Inicial"
            value={dataInicial}
            dateFormat="dd/mm/yy"
            onChange={e => SetDataInicial(e.value)}
            required
            showIcon
            showButtonBar
          />
        </div>
        <div>
          <label htmlFor="dataPregao">Data do pregão</label>
          <Calendar
            name="dataPregao"
            id="dataPregao"
            required
            showIcon
            showButtonBar
            value={dataFinal}
            onChange={e => SetDataFinal(e.value)}
            locale="br"
            dateFormat="dd/mm/yy"
            placeholder="Data do pregão"
          ></Calendar>
        </div>
      </ContainerFiltro>

      <S.ResultadosContainer>
        <div>
          <h3>Resultado Operações comuns</h3>
          <span>{ResultadoSwingTotal}</span>
        </div>
        <div>
          <h3>Resultado Day-Trade</h3>
          <span>{ResultadoDayTradeTotal}</span>
        </div>
        <div>
          <h3>Resultado</h3>
          <span>{ResultadoTotal}</span>
        </div>
      </S.ResultadosContainer>

      <DataTable
        value={performance}
        selection={performance}
        sortMode="multiple"
        paginator
        responsiveLayout="scroll"
        emptyMessage="Lista vazia."
        dataKey="id"
        currentPageReportTemplate="Mostrando de {first} até {last} - Total de {totalRecords} registros"
        rows={10}
        rowsPerPageOptions={[10, 20, 50]}
      >
        <Column
          field="corretora"
          header="Corretora"
          sortable
          className="corTextoPopUp"
        />
        <Column
          field="fechamento"
          header="Fechamento"
          sortable
          className="corTextoPopUp"
        />
        <Column
          field="tipo_ativo"
          header="Tipo de ativo"
          sortable
          className="corTextoPopUp"
        />
        <Column
          field="ticker"
          header="Ticker"
          sortable
          className="corTextoPopUp"
        />
        <Column
          field="tipo_neg"
          header="Tipo de neg."
          sortable
          className="corTextoPopUp"
        />
        <Column
          field="pr_medio_compra"
          header="Pr. médio compra"
          sortable
          className="corTextoPopUp"
        />
        <Column
          field="pr_medio_venda"
          header="Pr. médio venda"
          sortable
          className="corTextoPopUp"
        />
        <Column
          field="resultadoF"
          header="Resultado"
          sortable
          className="corTextoPopUp"
        />
        <Column
          field="resultado"
          header="Resultado%"
          sortable
          className="corTextoPopUp"
        />
        <Column field="dc" header="D/C" sortable className="corTextoPopUp" />
      </DataTable>
    </BaseModule>
  );
}
