/* eslint-disable */
import {
  ButtonBDI,
  InputMaskBDI,
  InputTextBDI,
} from '@dev_bdi/bdi-front-dspack';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';

import { useToast } from '../../../context/toast/useToast';
import { apiOperacoes } from '../../../service/ApiOperacoes/api';
import apiSincronizaCache from '../../../service/apiSincronizaCache';
import history from '../../../service/history';
import { formatCurrency } from '../../../utils/functions';
import { formatStringValueToNumber } from '../../../utils/functions/currency';
import { Box1, Box101, Small, TopoTransparente } from './styles';

export const OperacoesBovespa = () => {
  const [posicaoinicial, setPosicaoinicial] = useState({});
  const [posicoesiniciais, setPosicoesiniciais] = useState([]);
  const [novocampo, setNovocampo] = useState(false);
  const [menu, setMenu] = useState({ ativo: false, linha: null });
  const [editar, setEditar] = useState({ ativo: false, linha: null });
  const [listatipos, setListatipos] = useState([]);
  const [listatiposnegocio, setListatiposnegocio] = useState([]);
  const [listainstrumentos, setListainstrumentos] = useState([]);
  const [modalTituloDialog, setModalTituloDialog] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  const [respostaGetinstrumentos, SetRespostaGetinstrumentos] = useState([]);

  const { showToast } = useToast();

  const posicao = [
    { name: 'Compra', code: 'C' },
    { name: 'Venda', code: 'V' },
  ];

  const dc = [
    { name: 'D', code: 'D' },
    { name: 'C', code: 'C' },
  ];

  async function getTiposdeNegocio() {
    try {
      const response = await apiOperacoes.get('TipoNegocio');

      const filterList = response.data.filter(
        data => data.nome === 'SWING' || data.nome === 'DAYTRADE',
      );

      const newList = filterList.map(dado => {
        return {
          name: dado.nome,
          code: dado.idTipoNegocio,
        };
      });
      setListatiposnegocio(newList);
    } catch (error) {
      console.error(error);
      showToast();
    }
  }

  async function getTiposdeMercado() {
    try {
      const response = await apiOperacoes.get('TipoMercado');

      const filterList = response.data.filter(data => data.nome !== 'FUTURO');

      const newList = filterList.map(dado => {
        return {
          name: dado.nome,
          code: dado.idTipoMercado,
        };
      });
      setListatipos(newList);
    } catch (error) {
      console.error(error);
      showToast();
    }
  }

  function avancar() {
    history.push(`/calculadora/cadastromanual/rodapebovespa`);
  }

  function voltar() {
    window.history.back();
  }

  function salvarPosicao(posicao) {
    setPosicaoinicial({});
    setNovocampo(false);

    var posicoes = localStorage.getItem('operacoesbovespa')
      ? JSON.parse(localStorage.getItem('operacoesbovespa'))
      : [];
    posicoes.push(posicao);
    localStorage.setItem('operacoesbovespa', JSON.stringify(posicoes));
    setPosicoesiniciais(JSON.parse(localStorage.getItem('operacoesbovespa')));
  }

  function editarPosicao(posicao) {
    setNovocampo(false);
    setEditar({ ativo: true, linha: posicao });
    var item = JSON.parse(localStorage.getItem('operacoesbovespa'))[posicao];
    setPosicaoinicial(item);
  }

  function salvarEdicao(posicao) {
    var itens = JSON.parse(localStorage.getItem('operacoesbovespa'));
    itens.splice(posicao, 1, posicaoinicial);
    localStorage.setItem('operacoesbovespa', JSON.stringify(itens));
    setPosicoesiniciais(JSON.parse(localStorage.getItem('operacoesbovespa')));
    setEditar({ ativo: false, linha: null });
    setPosicaoinicial({});
  }

  function removerPosicao(posicao) {
    var posicoes = JSON.parse(localStorage.getItem('operacoesbovespa'));
    posicoes.splice(posicao, 1);
    localStorage.setItem('operacoesbovespa', JSON.stringify(posicoes));
    setPosicoesiniciais(JSON.parse(localStorage.getItem('operacoesbovespa')));
  }

  function handleValorAjuste(e) {
    const moedaFormatada = formatCurrency(e.target.value);

    if (e.target.id === 'precoajuste')
      setPosicaoinicial({ ...posicaoinicial, precoajuste: moedaFormatada });

    if (e.target.id === 'vlropajuste')
      setPosicaoinicial({ ...posicaoinicial, vlropajuste: moedaFormatada });
  }

  function handleTicketObrigatorio(e, id) {
    if (posicaoinicial[id] && e.target.value) {
      if (posicaoinicial[id].name === e.target.value) {
        const newErrors = { ...fieldErrors, [id]: false };
        setFieldErrors(newErrors);
      }
    } else {
      const newErrors = { ...fieldErrors, [id]: true };
      setFieldErrors(newErrors);
    }
  }

  const consultaInstrumentos = async event => {
    const query = event && event.query.toUpperCase();

    async function ExecutaRequestBuscaInstrumento() {
      try {
        const response = await apiSincronizaCache.get(
          'v1/GetBuscaInstrumentosBovespa/' + query,
        );

        SetRespostaGetinstrumentos(response.data);

        const filterList = response.data.instrumentos.filter(dado => {
          return dado.ticker; //q.toLowerCase().indexOf(query) === 0;
        });

        const obj = filterList.map(dado => {
          return {
            name: dado.ticker,
            code: dado.idInstrumento,
          };
        });

        setListainstrumentos(obj);
      } catch (error) {
        console.error(error);
        showToast();
      }
    }

    if (query !== null || query !== undefined) {
      if (query.length >= 3) {
        ExecutaRequestBuscaInstrumento();
      } else {
        if (
          respostaGetinstrumentos !== null &&
          respostaGetinstrumentos !== undefined
        ) {
          if (
            respostaGetinstrumentos.instrumentos !== null &&
            respostaGetinstrumentos.instrumentos !== undefined
          ) {
            if (respostaGetinstrumentos.instrumentos.length > 0) {
              const filterList = respostaGetinstrumentos.instrumentos.filter(
                dado => {
                  return dado.ticker.toUpperCase().indexOf(query) === 0;
                },
              );
              if (filterList.length > 0) {
                const obj = filterList.map(dado => {
                  return {
                    name: dado.ticker,
                    code: dado.idInstrumento,
                  };
                });

                setListainstrumentos(obj);
              } else {
                ExecutaRequestBuscaInstrumento();
              }
            } else {
              ExecutaRequestBuscaInstrumento();
            }
          } else {
            ExecutaRequestBuscaInstrumento();
          }
        } else {
          ExecutaRequestBuscaInstrumento();
        }
      }
    }
  };

  useEffect(() => {
    const posicoes_iniciais = localStorage.getItem('operacoesbovespa')
      ? JSON.parse(localStorage.getItem('operacoesbovespa'))
      : [];
    posicoes_iniciais
      ? setPosicoesiniciais(posicoes_iniciais)
      : setNovocampo(true);
    if (!localStorage.getItem('operacoesbovespa')) setNovocampo(true);
    getTiposdeMercado();
    getTiposdeNegocio();
  }, []);

  const modalTitulo = () => {
    setModalTituloDialog(true);
  };

  const fecharModalTitulo = () => {
    setModalTituloDialog(false);
  };

  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-12">
        <Box1>
          <section>
            <div className="card">
              <div className="p-col-12 p-md-12">
                <h2>
                  <strong>Agora</strong>, adicione as <strong>operações</strong>{' '}
                  descritas na nota.
                  <Button
                    icon="pi pi-question-circle"
                    style={{ top: '9px' }}
                    className="p-button-rounded p-button-text colorIcon"
                    onClick={modalTitulo}
                    tooltip="Cadastro Bovespa"
                  />
                </h2>
                <br />
                <p className="subTituloBov">
                  Os campos abaixo correspondem ao tipo de nota de corretagem
                  (Bovespa), sendo possível preencher todas as informações
                  referentes às <b>operações realizadas</b>.
                </p>
                <Dialog
                  visible={modalTituloDialog}
                  style={{ width: '41vw' }}
                  header="Título"
                  blockScroll={false}
                  className="p-fluid"
                  modal
                  onHide={fecharModalTitulo}
                >
                  <p>Texto</p>
                </Dialog>
              </div>
              <br />
              <br />
              <div className="p-col-12 p-md-12">
                <Box101>
                  {posicoesiniciais.length > 0 && (
                    <TopoTransparente>
                      <p>
                        <label>C/V*</label>
                      </p>
                      <p>
                        <label>Tipo de Mercado*</label>
                      </p>
                      <p>
                        <label>Prazo</label>
                      </p>
                      <p>
                        <label>Espec. de título</label>
                      </p>
                      <p>
                        <label>Observação</label>
                      </p>
                      <p>
                        <label>Qtde*</label>
                      </p>
                      <p>
                        <label>Preço/Ajuste*</label>
                      </p>
                      <p>
                        <label>Vlr. op./Ajuste</label>
                      </p>
                      <p>
                        <label>D/C</label>
                      </p>
                      <p>
                        <label>&nbsp;</label>
                      </p>
                    </TopoTransparente>
                  )}
                  {posicoesiniciais &&
                    posicoesiniciais.map((p, indice) => (
                      <div key={indice}>
                        {editar.ativo && editar.linha === indice ? (
                          <>
                            <p>
                              <label>C/V*</label>
                              <Dropdown
                                id="posicao"
                                placeholder="C/V*"
                                value={posicaoinicial.posicao}
                                onChange={e =>
                                  setPosicaoinicial({
                                    ...posicaoinicial,
                                    posicao: e.value,
                                  })
                                }
                                options={posicao}
                                optionLabel="name"
                              />
                            </p>
                            <p>
                              <label>Tipo de Mercado*</label>
                              <Dropdown
                                id="tipomercado"
                                placeholder="Tipo de Mercado"
                                value={posicaoinicial.tipomercado}
                                onChange={e =>
                                  setPosicaoinicial({
                                    ...posicaoinicial,
                                    tipomercado: e.value,
                                  })
                                }
                                options={listatipos}
                                optionLabel="name"
                              />
                            </p>
                            <p>
                              <label>Prazo</label>
                              <InputMaskBDI
                                id="prazo"
                                mask="99/99/9999"
                                placeholder="00/00/0000"
                                valor={posicaoinicial.prazo}
                                acao={e =>
                                  setPosicaoinicial({
                                    ...posicaoinicial,
                                    prazo: e.value,
                                  })
                                }
                              ></InputMaskBDI>
                            </p>
                            <p>
                              <label>Espec. de título*</label>
                              <AutoComplete
                                id="espectitulo"
                                placeholder="Espec. de título*"
                                value={posicaoinicial.espectitulo}
                                suggestions={listainstrumentos}
                                completeMethod={consultaInstrumentos}
                                field="name"
                                forceSelection
                                onChange={e =>
                                  setPosicaoinicial({
                                    ...posicaoinicial,
                                    espectitulo: e.target.value,
                                  })
                                }
                                className={
                                  fieldErrors['espectitulo'] ? 'invalid' : ''
                                }
                                onBlur={e =>
                                  handleTicketObrigatorio(e, 'espectitulo')
                                }
                              />
                            </p>
                            <p>
                              <label>Observação*</label>
                              <Dropdown
                                id="observacao"
                                placeholder="Observação*"
                                value={posicaoinicial.observacao}
                                onChange={e =>
                                  setPosicaoinicial({
                                    ...posicaoinicial,
                                    observacao: e.value,
                                  })
                                }
                                options={listatiposnegocio}
                                optionLabel="name"
                              />
                            </p>
                            <p>
                              <label>Qtde*</label>
                              <InputNumber
                                placeholder="Qtde*"
                                id="quantidade"
                                value={posicaoinicial.quantidade}
                                onValueChange={e => {
                                  setPosicaoinicial({
                                    ...posicaoinicial,
                                    quantidade: e.target.value,
                                  });
                                }}
                                min={1}
                                required
                                useGrouping={false}
                                showButtons
                              />
                            </p>
                            <p>
                              <label>Preço/Ajuste*</label>
                              <InputTextBDI
                                type="text"
                                placeholder="Preço/Ajuste*"
                                id="precoajuste"
                                valor={posicaoinicial.precoajuste}
                                acao={handleValorAjuste}
                              />
                            </p>
                            <p>
                              <label>Vlr. Op./Ajuste*</label>
                              <InputText
                                placeholder="Vlr. Op./Ajuste*"
                                id="vlropajuste"
                                value={posicaoinicial.vlropajuste}
                                onChange={handleValorAjuste}
                              />
                            </p>
                            <p>
                              <label>D/C*</label>
                              <Dropdown
                                id="posicao"
                                placeholder="D/C*"
                                value={posicaoinicial.dc}
                                onChange={e =>
                                  setPosicaoinicial({
                                    ...posicaoinicial,
                                    dc: e.value,
                                  })
                                }
                                options={dc}
                                optionLabel="name"
                              />
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              <label>{p.posicao ? p.posicao.name : ' '}</label>
                            </p>
                            <p>
                              <label>
                                {p.tipomercado ? p.tipomercado.name : ' '}
                              </label>
                            </p>
                            <p>
                              <label>{p.prazo ? p.prazo : ' '}</label>
                            </p>
                            <p>
                              <label>
                                {p.espectitulo ? p.espectitulo.name : ' '}
                              </label>
                            </p>
                            <p>
                              <label>
                                {p.observacao.name ? p.observacao.name : ' '}
                              </label>
                            </p>
                            <p>
                              <label>{p.quantidade ? p.quantidade : ' '}</label>
                            </p>
                            <p>
                              <label>
                                {p.precoajuste ? p.precoajuste : ' '}
                              </label>
                            </p>
                            <p>
                              <label>
                                {p.vlropajuste ? p.vlropajuste : ' '}
                              </label>
                            </p>
                            <p>
                              <label>{p.dc ? p.dc.name : ' '}</label>
                            </p>
                          </>
                        )}
                        <p className="opcoes">
                          {menu.ativo && menu.linha === indice && (
                            <ul>
                              <li
                                onClick={() => {
                                  salvarPosicao(p);
                                  setMenu({ ativo: false, linha: 0 });
                                }}
                              >
                                Duplicar
                              </li>
                              <li
                                onClick={() => {
                                  editarPosicao(indice);
                                  setMenu({ ativo: false, linha: 0 });
                                }}
                              >
                                Editar
                              </li>
                              <li
                                onClick={() => {
                                  removerPosicao(indice);
                                  setMenu({ ativo: false, linha: 0 });
                                }}
                              >
                                Excluir
                              </li>
                            </ul>
                          )}
                          {menu.linha === indice && editar.linha !== indice && (
                            <i
                              onClick={() =>
                                setMenu({ ativo: !menu.ativo, linha: indice })
                              }
                              className="pi pi-ellipsis-v"
                            ></i>
                          )}
                          {menu.linha !== indice && editar.linha !== indice && (
                            <i
                              onClick={() =>
                                setMenu({ ativo: true, linha: indice })
                              }
                              className="pi pi-ellipsis-v"
                            ></i>
                          )}
                          {editar.ativo &&
                            editar.linha === indice &&
                            posicaoinicial.posicao &&
                            posicaoinicial.tipomercado &&
                            posicaoinicial.quantidade &&
                            posicaoinicial.espectitulo &&
                            posicaoinicial.observacao &&
                            formatStringValueToNumber(
                              posicaoinicial.vlropajuste,
                            ) !== 0 &&
                            posicaoinicial.dc && (
                              <React.Fragment>
                                <label>&nbsp;</label>
                                <br />
                                <i
                                  onClick={() => salvarEdicao(indice)}
                                  className="pi pi-save"
                                ></i>
                              </React.Fragment>
                            )}
                        </p>
                      </div>
                    ))}
                  {novocampo && (
                    <div>
                      <p>
                        <label>C/V*</label>
                        <Dropdown
                          id="posicao"
                          placeholder="C/V*"
                          value={posicaoinicial.posicao}
                          onChange={e =>
                            setPosicaoinicial({
                              ...posicaoinicial,
                              posicao: e.value,
                            })
                          }
                          options={posicao}
                          optionLabel="name"
                        />
                      </p>
                      <p>
                        <label>Tipo de Mercado*</label>
                        <Dropdown
                          id="tipomercado"
                          placeholder="Tipo de Mercado"
                          value={posicaoinicial.tipomercado}
                          onChange={e =>
                            setPosicaoinicial({
                              ...posicaoinicial,
                              tipomercado: e.value,
                            })
                          }
                          options={listatipos}
                          optionLabel="name"
                          filter
                        />
                      </p>
                      <p>
                        <label>Prazo</label>
                        <InputMaskBDI
                          id="prazo"
                          mask="99/99/9999"
                          placeholder="00/00/0000"
                          valor={posicaoinicial.prazo}
                          acao={e =>
                            setPosicaoinicial({
                              ...posicaoinicial,
                              prazo: e.value,
                            })
                          }
                        ></InputMaskBDI>
                      </p>
                      <p>
                        <label>Espec. de título*</label>
                        <AutoComplete
                          id="espectitulo"
                          placeholder="Espec. de título*"
                          value={posicaoinicial.espectitulo}
                          suggestions={listainstrumentos}
                          completeMethod={consultaInstrumentos}
                          field="name"
                          forceSelection
                          onChange={e =>
                            setPosicaoinicial({
                              ...posicaoinicial,
                              espectitulo: e.target.value,
                            })
                          }
                          className={
                            fieldErrors['espectitulo'] ? 'invalid' : ''
                          }
                          onBlur={e =>
                            handleTicketObrigatorio(e, 'espectitulo')
                          }
                        />
                      </p>
                      <p>
                        <label>Observação*</label>
                        <Dropdown
                          id="observacao"
                          placeholder="Observação*"
                          value={posicaoinicial.observacao}
                          onChange={e =>
                            setPosicaoinicial({
                              ...posicaoinicial,
                              observacao: e.value,
                            })
                          }
                          options={listatiposnegocio}
                          optionLabel="name"
                        />
                      </p>
                      <p>
                        <label>Qtde*</label>
                        <InputNumber
                          placeholder="Qtde*"
                          id="quantidade"
                          value={posicaoinicial.quantidade}
                          onValueChange={e => {
                            setPosicaoinicial({
                              ...posicaoinicial,
                              quantidade: e.target.value,
                            });
                          }}
                          min={1}
                          required
                          useGrouping={false}
                          showButtons
                        />
                      </p>
                      <p>
                        <label>Preço/Ajuste*</label>
                        <InputTextBDI
                          type="text"
                          placeholder="Preço/Ajuste*"
                          id="precoajuste"
                          valor={posicaoinicial.precoajuste}
                          acao={handleValorAjuste}
                        />
                      </p>
                      <p>
                        <label>Vlr. Op./Ajuste*</label>
                        <InputText
                          placeholder="Vlr. Op./Ajuste*"
                          id="vlropajuste"
                          value={posicaoinicial.vlropajuste}
                          onChange={handleValorAjuste}
                        />
                      </p>
                      <p>
                        <label>D/C*</label>
                        <Dropdown
                          id="posicao"
                          placeholder="D/C*"
                          value={posicaoinicial.dc}
                          onChange={e =>
                            setPosicaoinicial({
                              ...posicaoinicial,
                              dc: e.value,
                            })
                          }
                          options={dc}
                          optionLabel="name"
                        />
                      </p>
                      <p>
                        <label>&nbsp;</label>
                        {posicaoinicial.posicao &&
                          posicaoinicial.tipomercado &&
                          posicaoinicial.quantidade &&
                          posicaoinicial.espectitulo &&
                          posicaoinicial.observacao &&
                          formatStringValueToNumber(
                            posicaoinicial.vlropajuste,
                          ) !== 0 &&
                          posicaoinicial.dc && (
                            <React.Fragment>
                              <label>&nbsp;</label>
                              <br />
                              <i
                                onClick={() => salvarPosicao(posicaoinicial)}
                                className="pi pi-save"
                              ></i>
                            </React.Fragment>
                          )}
                      </p>
                    </div>
                  )}
                  <div>
                    <p>Quantidade: {posicoesiniciais.length}</p>
                    <p>
                      {!novocampo ? (
                        <ButtonBDI
                          icon={ButtonBDI.bdi_icon.plus}
                          className={`
                        ${ButtonBDI.bdi_classe.rounded}
                        ${ButtonBDI.bdi_subclasse.primary}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                          funcao={() => {
                            setPosicaoinicial({});
                            setNovocampo(true);
                            setEditar({ ativo: false, linha: null });
                          }}
                        />
                      ) : (
                        <ButtonBDI
                          icon={ButtonBDI.bdi_icon.minus}
                          className={`
                      ${ButtonBDI.bdi_classe.rounded}
                      ${ButtonBDI.bdi_subclasse.primary}
                      ${ButtonBDI.bdi_alinhamento_lateral}
                      ${ButtonBDI.bdi_alinhamento_vertical}
                      `}
                          funcao={() => setNovocampo(false)}
                        />
                      )}
                    </p>
                  </div>
                </Box101>
              </div>
              <div className="p-col-12 p-md-6 preenchimentoobrigatorio">
                <Small>*Preenchimento obrigatório</Small>
              </div>
              <div className="p-col-12 p-md-6">
                <ButtonBDI
                  label="Voltar"
                  className={`
                        ${ButtonBDI.bdi_classe.outlined}
                        ${ButtonBDI.bdi_subclasse.secondary}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => voltar()}
                />
                <ButtonBDI
                  label="Avançar"
                  className={`
                        ${ButtonBDI.bdi_classe.severities}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => avancar()}
                  disabled={posicoesiniciais.length ? false : true}
                />
              </div>
            </div>
          </section>
        </Box1>
      </div>
    </div>
  );
};
