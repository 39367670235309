import { Column } from 'primereact/column';
import { DataTablePageParams } from 'primereact/datatable';
import { useMemo, useState } from 'react';

import { RegraValorAjuste } from '../../../../utils/functions';
import { AccordionToggle } from '../../AccordionToggle';
import { paginatorTemplate } from '../../templates/PaginatorTemplate';
import { NotaCorretagem } from '../../types';
import { BMFDialogFooter } from '../BMFDialogFooter';
import { BovespaDialogFooter } from '../BovespaDialogFooter';
import * as S from './styles';

interface NotaDetailsDialogProps {
  visible: boolean;
  onHide: () => void;
  selectedLineData: NotaCorretagem;
  notaCorretagemData?: NotaCorretagem;
  isNotaCorretagemLoading: boolean;
}

export function NotaDetailsDialog({
  visible,
  onHide,
  selectedLineData,
  notaCorretagemData,
  isNotaCorretagemLoading,
}: NotaDetailsDialogProps) {
  const [firstPaginator, setFirstPaginator] = useState(0);
  const [rowsPaginator, setRowsPaginator] = useState(5);
  const isBovespa = selectedLineData.idTipoNota === 'Bovespa';

  // Paginator
  const handleChangePaginator = (event: DataTablePageParams) => {
    setFirstPaginator(event.first);
    setRowsPaginator(event.rows);
  };

  const formatNotaCorretagemData = useMemo(() => {
    return notaCorretagemData?.notasCorretagemItens.map(item => {
      return {
        idNotaCorretagemItem: item?.idNotaCorretagemItem || 0,
        idNotaCorretagem: item?.idNotaCorretagem || 0,
        ordenacao: item.ordenacao,
        negociacaoMercado: item.negociacaoMercado,
        compraVenda: item.compraVenda,
        debitoCredito: item.debitoCredito,
        tipoMercado: item?.idTipoMercadoNavigation?.descricao || '-',
        idTipoMercado: item.idTipoMercado,
        prazo: item?.prazo || '-',
        dataVencimento: item.dataVencimento,
        tipoNegocio: item?.idTipoNegocioNavigation?.descricao || '-',
        observacao: item?.idTipoNegocioNavigation?.descricao || '-',
        mercadoriaTituloPapelCodigo: item.mercadoriaTituloPapelCodigo,
        mercadoriaTituloPapelExtenso: item.mercadoriaTituloPapelExtenso,
        quantidade: item.quantidade,
        valorTaxaOperacional: item.valorTaxaOperacional,
        valorOperacaoAjuste: RegraValorAjuste(
          item.negociacaoMercado,
          item.pontosOperacaoAjuste,
          item.valorOperacaoAjuste,
        ),
        valorPrecoAjuste: RegraValorAjuste(
          item.negociacaoMercado,
          item.pontosPrecoAjuste,
          item.valorPrecoAjuste,
        ),
      };
    });
  }, [notaCorretagemData?.notasCorretagemItens]);

  return (
    <S.NotaDialogContainer
      visible={visible}
      onHide={onHide}
      maximizable
      maximized
      modal
    >
      <h2>Nota de corretagem</h2>
      <p className="paragraph">
        Neste relatório, é possível visualizar as informações detalhadas da sua
        nota de corretagem. Caso encontre alguma incoerência, feche esta janela
        e selecione a opção “Informar inconsistência”.
      </p>
      <main>
        <AccordionToggle headerName="Cabeçalho" className="mobile">
          <section>
            <table>
              <thead>
                <tr>
                  <th>CPF</th>
                  <th>Data do pregão</th>
                  <th>Corretora</th>
                  <th>Tipo de nota</th>
                  <th>Número da nota</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedLineData.cpf}</td>
                  <td>{selectedLineData.pregao}</td>
                  <td>{selectedLineData.corretora}</td>
                  <td>{selectedLineData.idTipoNota}</td>
                  <td>{selectedLineData.ndanota}</td>
                </tr>
              </tbody>
            </table>
          </section>
        </AccordionToggle>
        <section className="desktop">
          <table>
            <thead>
              <tr>
                <th>CPF</th>
                <th>Data do pregão</th>
                <th>Corretora</th>
                <th>Tipo de nota</th>
                <th>Número da nota</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{selectedLineData.cpf}</td>
                <td>{selectedLineData.pregao}</td>
                <td>{selectedLineData.corretora}</td>
                <td>{selectedLineData.idTipoNota}</td>
                <td>{selectedLineData.ndanota}</td>
              </tr>
            </tbody>
          </table>
        </section>

        <AccordionToggle headerName="Operações" className="mobile">
          <section>
            <S.DataTableContainer
              value={formatNotaCorretagemData}
              emptyMessage="Lista vazia"
              responsiveLayout="stack"
              breakpoint="768px"
              sortField="data"
              sortOrder={-1}
              loading={isNotaCorretagemLoading}
              paginator
              first={firstPaginator}
              rows={rowsPaginator}
              onPage={handleChangePaginator}
              paginatorTemplate={paginatorTemplate}
            >
              <Column field="compraVenda" header="C/V" sortable />
              <Column
                field={
                  isBovespa ? 'tipoMercado' : 'mercadoriaTituloPapelCodigo'
                }
                header={isBovespa ? 'Tipo de Mercado' : 'Mercadoria'}
                sortable
              />
              <Column
                field={isBovespa ? 'prazo' : 'dataVencimento'}
                header={isBovespa ? 'Prazo' : 'Vencimento'}
                sortable
              />
              <Column
                field={isBovespa ? 'mercadoriaTituloPapelCodigo' : 'quantidade'}
                header={isBovespa ? 'Espec. de titulo' : 'Qtde'}
                sortable
              />
              <Column
                field={isBovespa ? 'observacao' : 'valorPrecoAjuste'}
                header={isBovespa ? 'Observação' : 'Preço/Ajuste'}
                sortable
              />
              <Column
                field={isBovespa ? 'quantidade' : 'tipoNegocio'}
                header={isBovespa ? 'Qtde' : 'Tipo do Negócio'}
                sortable
              />
              <Column
                field={isBovespa ? 'valorPrecoAjuste' : 'valorOperacaoAjuste'}
                header={isBovespa ? 'Preço/Ajuste' : 'Vlr op./ajuste'}
                sortable
              />
              <Column
                field={isBovespa ? 'valorOperacaoAjuste' : 'debitoCredito'}
                header={isBovespa ? 'Vlr op./ajuste' : 'D/C'}
                sortable
              />
              <Column
                field={isBovespa ? 'debitoCredito' : 'valorTaxaOperacional'}
                header={isBovespa ? 'D/C' : 'Taxa Operacional'}
                sortable
              />
            </S.DataTableContainer>
          </section>
        </AccordionToggle>

        <section className="desktop">
          <S.DataTableContainer
            value={formatNotaCorretagemData}
            emptyMessage="Lista vazia"
            responsiveLayout="stack"
            breakpoint="768px"
            sortField="data"
            sortOrder={-1}
            paginator
            first={firstPaginator}
            rows={rowsPaginator}
            onPage={handleChangePaginator}
            paginatorTemplate={paginatorTemplate}
          >
            <Column field="compraVenda" header="C/V" sortable />
            <Column
              field={isBovespa ? 'tipoMercado' : 'mercadoriaTituloPapelCodigo'}
              header={isBovespa ? 'Tipo de Mercado' : 'Mercadoria'}
              sortable
            />
            <Column
              field={isBovespa ? 'prazo' : 'dataVencimento'}
              header={isBovespa ? 'Prazo' : 'Vencimento'}
              sortable
            />
            <Column
              field={isBovespa ? 'mercadoriaTituloPapelCodigo' : 'quantidade'}
              header={isBovespa ? 'Espec. de titulo' : 'Qtde'}
              sortable
            />
            <Column
              field={isBovespa ? 'observacao' : 'valorPrecoAjuste'}
              header={isBovespa ? 'Observação' : 'Preço/Ajuste'}
              sortable
            />
            <Column
              field={isBovespa ? 'quantidade' : 'tipoNegocio'}
              header={isBovespa ? 'Qtde' : 'Tipo do Negócio'}
              sortable
            />
            <Column
              field={isBovespa ? 'valorPrecoAjuste' : 'valorOperacaoAjuste'}
              header={isBovespa ? 'Preço/Ajuste' : 'Vlr op./ajuste'}
              sortable
            />
            <Column
              field={isBovespa ? 'valorOperacaoAjuste' : 'debitoCredito'}
              header={isBovespa ? 'Vlr op./ajuste' : 'D/C'}
              sortable
            />
            <Column
              field={isBovespa ? 'debitoCredito' : 'valorTaxaOperacional'}
              header={isBovespa ? 'D/C' : 'Taxa Operacional'}
              sortable
            />
          </S.DataTableContainer>
        </section>
        {isBovespa ? (
          <BovespaDialogFooter
            data={notaCorretagemData?.notasCorretagemRodapeB3s}
          />
        ) : (
          <BMFDialogFooter
            data={notaCorretagemData?.notasCorretagemRodapeBmfs}
          />
        )}
      </main>
    </S.NotaDialogContainer>
  );
}
