import { useQuery } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { useState } from 'react';

import { BaseModule } from '../../../../components/BaseModule';
import { RELATORIO_OPERACOES_COMUM_DAYTRADE } from '../../../../components/BaseModule/constants';
import Usuario from '../../../../components/Usuario';
import { useToast } from '../../../../context/toast/useToast';
import { useOperationYearsFilter } from '../../../../hooks/reports/useOperationYearsFilter';
import { getRelatorioOperacaoSwingDayTradeDateTime } from '../../../../service/ApiRelatorios/RelatorioOpComumDayTrade';
import history from '../../../../service/history';
import { MAP_MONTH_NUMBER } from '../../../../utils/constants';
import { formatStringValueToNumber } from '../../../../utils/functions/currency';
import {
  Container,
  ContainerTablesCommonOperations,
  MonthContainer,
  WarningMigratedUser,
} from './styles';

const MONTHS = Object.keys(MAP_MONTH_NUMBER);
const CURRENT_YEAR = new Date().getFullYear();

const getOperacoesComuns = async ({ queryKey, signal }) => {
  const userId = queryKey[2];
  const selectedYear = queryKey[3];
  const selectedMonth = queryKey[4];
  const month = MAP_MONTH_NUMBER[selectedMonth];
  const yearMonth = `${selectedYear}/${month}`;

  const { data } = await getRelatorioOperacaoSwingDayTradeDateTime(
    userId,
    yearMonth,
    { signal },
  );

  return data;
};

export const CommonOperationsAndDayTrade = () => {
  const [currentMonth, setCurrentMonth] = useState('Jan');
  const [selectedYear, setSelectedYear] = useState({
    name: String(CURRENT_YEAR),
    code: CURRENT_YEAR,
  });
  const { showToast, currentToast } = useToast();

  const operationYearsQuery = useOperationYearsFilter({ userId: Usuario.id });
  const { data: listaStatus } = useQuery(
    [
      'reports',
      'opComunsDayTrade',
      Usuario.id,
      selectedYear.name,
      currentMonth,
    ],
    getOperacoesComuns,
    { onError: () => showToast() },
  );

  const marketTypes = [
    {
      field: '',
      label: 'Mercado à vista - ações, BDRs e ETFs',
      commonOperations:
        'R$ ' + (listaStatus?.mercadoAVistaBDRsETFsSwing || 'Carregando'),
      dayTrade:
        'R$ ' + (listaStatus?.mercadoAVistaBDRsETFsDayTrade || 'Carregando'),
    },
    {
      field: '',
      label: 'Mercado opções - ações e ETFs',
      commonOperations:
        'R$ ' + (listaStatus?.mercadoOpcoesETFsSwing || 'Carregando'),
      dayTrade:
        'R$ ' + (listaStatus?.mercadoOpcoesETFsDayTrade || 'Carregando'),
    },
    {
      field: '',
      label: 'Mercado futuro - dólar dos EUA',
      commonOperations:
        'R$ ' + (listaStatus?.mercadoFuturoDolarEUASwing || 'Carregando'),
      dayTrade:
        'R$ ' + (listaStatus?.mercadoFuturoDolarEUADayTrade || 'Carregando'),
    },
    {
      field: '',
      label: 'Mercado futuro - índices',
      commonOperations:
        'R$ ' + (listaStatus?.mercadoFuturoIndicesSwing || 'Carregando'),
      dayTrade:
        'R$ ' + (listaStatus?.mercadoFuturoindicesDayTrade || 'Carregando'),
    },
  ];

  const results = [
    {
      field: '',
      label: 'Resultado líquido do mês',
      commonOperations:
        'R$ ' + (listaStatus?.resultadoLiquidoMesSwing || 'Carregando'),
      dayTrade:
        'R$ ' + (listaStatus?.resultadoLiquidoMesDayTrade || 'Carregando'),
    },
    {
      field: '',
      label: 'Resultado negativo até o mês anterior',
      commonOperations:
        'R$ ' +
        (listaStatus?.resultadoNegativoAteMesAnteriorSwing || 'Carregando'),
      dayTrade:
        'R$ ' +
        (listaStatus?.resultadoNegativoAteMesAnteriorDaytrade || 'Carregando'),
    },
    {
      field: '',
      label: 'Base de cálculo do imposto',
      commonOperations:
        'R$ ' + (listaStatus?.baseCalculoImpostoSwing || 'Carregando'),
      dayTrade:
        'R$ ' + (listaStatus?.baseCalculoImpostoDaytrade || 'Carregando'),
    },
    {
      field: '',
      label: 'Prejuízo a compensar',
      commonOperations:
        'R$ ' + (listaStatus?.prejuizoCompensarSwing || 'Carregando'),
      dayTrade:
        'R$ ' + (listaStatus?.prejuizoCompensarDayTrade || 'Carregando'),
    },
    {
      field: '',
      label: 'Alíquota do imposto',
      commonOperations: listaStatus?.aliquotaDoImpostoSwing
        ? `${
            formatStringValueToNumber(listaStatus.aliquotaDoImpostoSwing) * 100
          }%`
        : 'Carregando',
      dayTrade: listaStatus?.aliquotaDoImpostoDayTrade
        ? `${
            formatStringValueToNumber(listaStatus.aliquotaDoImpostoDayTrade) *
            100
          }%`
        : 'Carregando',
    },
    {
      field: '',
      label: 'Imposto devido',
      commonOperations:
        'R$ ' + (listaStatus?.impostoDevidoSwing || 'Carregando'),
      dayTrade: 'R$ ' + (listaStatus?.impostoDevidoDayTrade || 'Carregando'),
    },
  ];

  const monthConsolidation = [
    {
      field: '',
      label: 'Total do imposto devido',
      value: 'R$ ' + (listaStatus?.totalimpostoDevido || 'Carregando'),
    },
    {
      field: '',
      label: 'IR fonte de Day-Trade no mês',
      value: 'R$ ' + (listaStatus?.irFontDayTradeMes || 'Carregando'),
    },
    {
      field: '',
      label: 'IR fonte de Day-Trade no mês anterior',
      value: 'R$ ' + (listaStatus?.irFontDayTradeMesAnterior || 'Carregando'),
    },
    {
      field: '',
      label: 'IR fonte de Day-Trade a compensar',
      value: 'R$ ' + (listaStatus?.irFontDayTradeCompensar || 'Carregando'),
    },
    {
      field: '',
      label: 'IR fonte (Lei nº 11.033/004) no mês',
      value: 'R$ ' + (listaStatus?.irFontMes || 'Carregando'),
    },
    {
      field: '',
      label: 'IR fonte (Lei nº 11.033/004) no mês anterior',
      value: 'R$ ' + (listaStatus?.irFontMesAnterior || 'Carregando'),
    },
    {
      field: '',
      label: 'IR fonte (Lei nº 11.033/004) a compensar',
      value: 'R$ ' + (listaStatus?.irFontComnpensar || 'Carregando'),
    },
    {
      field: '',
      label: 'Imposto a pagar',
      value: 'R$ ' + (listaStatus?.impostoPagar || 'Carregando'),
    },
    {
      field: '',
      label: 'Imposto pago',
      value: 'R$ ' + (listaStatus?.impostoPago || 'Carregando'),
    },
  ];

  function onChangeHandler(e) {
    setSelectedYear(e.value);
    setCurrentMonth('Jan');
  }

  const abrirDarf = () => {
    if (currentToast) {
      currentToast.clear();
    }
    history.push('/impostos/emissao-darf');
  };

  return (
    <BaseModule
      tipo={RELATORIO_OPERACOES_COMUM_DAYTRADE}
      props={{ nome: 'operações comuns e day trade' }}
    >
      {listaStatus?.migracao && (
        <WarningMigratedUser>
          Novidade: Você possui dados migrados da versão 1.0 da plataforma.
          Agora os relatórios da SmarttIR contemplam informações que você não
          tinha antes. Devido a isso, alguns campos podem aparecer em branco, já
          que não existiam na antiga versão.
        </WarningMigratedUser>
      )}
      {!operationYearsQuery.isLoading && (
        <Container>
          <div className="p-d-flex">
            <div className="p-flex-column p-d-flex">
              <Dropdown
                value={selectedYear}
                options={operationYearsQuery.data}
                onChange={e => onChangeHandler(e)}
                optionLabel="name"
                placeholder="Selecione o ano"
              />
            </div>
          </div>
          <Button onClick={abrirDarf}>Emitir DARF</Button>
        </Container>
      )}
      <section className="p-d-flex">
        <MonthContainer>
          {MONTHS.map(month => (
            <li key={month}>
              <button
                type="button"
                onClick={() => setCurrentMonth(month)}
                value={month}
                name={month}
                id={month}
                className={`${month} ${
                  currentMonth === month ? 'selectedMonth' : ''
                }`}
              >
                {month}
              </button>
            </li>
          ))}
        </MonthContainer>

        <ContainerTablesCommonOperations>
          <DataTable
            value={marketTypes}
            responsiveLayout="scroll"
            emptyMessage="Lista vazia."
          >
            <Column
              field="label"
              header="Tipos de mercado"
              className="tituloRV"
            />
            <Column
              field="commonOperations"
              header="Operações comuns"
              className="tituloRV"
            />
            <Column field="dayTrade" header="Day Trade" className="tituloRV" />
          </DataTable>
          <DataTable
            value={results}
            responsiveLayout="scroll"
            emptyMessage="Lista vazia."
          >
            <Column field="label" header="Resultados" className="tituloRV" />
            <Column
              field="commonOperations"
              header="Operações comuns"
              className="tituloRV"
            />
            <Column field="dayTrade" header="Day Trade" className="tituloRV" />
          </DataTable>
          <DataTable
            value={monthConsolidation}
            responsiveLayout="scroll"
            emptyMessage="Lista vazia."
          >
            <Column
              field="label"
              header="Consolidaçao do mês"
              className="tituloRV"
            />
            <Column field="value" />
          </DataTable>
        </ContainerTablesCommonOperations>
      </section>
    </BaseModule>
  );
};
