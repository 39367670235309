import axios from 'axios';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import Usuario from '../../components/Usuario';
import { usuarios } from '../../service/ApiUsuarios/Subscriptions';

type Subscription = {
  status: string;
  plano: {
    nome: string;
    valor: number;
    ativo: boolean;
    periocidade: string;
    billingPlanCode: string;
    billingPlanId: number;
  };
};

type SubscriptionsContextData = {
  getCurrentSubscription: () => Subscription | 'NOT_FOUND';
};

interface SubscriptionsProviderProps {
  children: React.ReactNode;
}

const SubscriptionsContext = createContext({} as SubscriptionsContextData);

export function SubscriptionsProvider({
  children,
}: SubscriptionsProviderProps) {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  async function getSubscriptions(id: string) {
    const { data } = await axios.get(
      usuarios.subscriptions.getSubscriptionsByUserId(id),
    );

    setSubscriptions(data);
  }

  const getCurrentSubscription = useCallback(() => {
    const currentSubscription = subscriptions.find(
      subscription => subscription.status === 'ATIVO',
    );

    if (currentSubscription) return currentSubscription;

    return 'NOT_FOUND';
  }, [subscriptions]);

  useEffect(() => {
    getSubscriptions(Usuario.id);
  }, []);

  return (
    <SubscriptionsContext.Provider value={{ getCurrentSubscription }}>
      {children}
    </SubscriptionsContext.Provider>
  );
}

export const useSubscriptions = () => useContext(SubscriptionsContext);
