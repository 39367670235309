import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0px 2rem;

  @media screen and (max-width: 960px) {
    padding: 0px 0.5rem;
  }
`;

export const Container = styled.div`
  background-color: #fff;
  border: 1px solid rgba(83, 195, 194, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 40px 32px;
  height: 100%;

  @media screen and (max-width: 960px) {
    padding: 1rem;
  }
`;

export const Header = styled.header`
  margin-bottom: 32px;

  & > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    align-items: center;

    h1 {
      margin: 0 8px 0 0;
      font-weight: 500 !important;
      line-height: 2rem;
    }

    .slotDireito {
      margin-left: auto;
    }
  }

  p:not(:last-of-type) {
    font-size: 16px !important;
    font-weight: 300 !important;
  }
`;
