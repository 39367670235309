import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useCallback, useState } from 'react';

import Usuario from '../../../../components/Usuario';
import { useToast } from '../../../../context/toast/useToast';
import apiAuth from '../../../../service/apiAuth';
import { Container, DialogFooter } from './styles';

export function AlterarSenha() {
  const [mostrarDialogCodigoAuth, setMostrarDialogCodigoAuth] = useState(false);
  const { showToast } = useToast();

  const enviarCodigo = useCallback(async () => {
    try {
      await apiAuth.post('/Auth/forgot-password', {
        id: Usuario.id,
      });

      localStorage.setItem('Id', Usuario.id);
      setMostrarDialogCodigoAuth(true);
    } catch (error) {
      console.error(error);
      showToast();
    }
  }, []);

  function logouf() {
    localStorage.clear();
    window.location.href = process.env.REACT_APP_LOGIN;
  }

  const fecharConcluir = () => {
    setMostrarDialogCodigoAuth(false);
    logouf();
    let Id = Usuario.id;
    localStorage.setItem('Id', Id);
  };

  return (
    <>
      <Container>
        <header>
          <h1>Alterar senha</h1>
          <p>
            Para alterar a senha da sua conta será necessário a autenticação
            através do e-mail cadastrado.
          </p>
          <p>
            Será enviado um e-mail com um link para que seja realizada a troca
            da senha.
          </p>
        </header>
        <main>
          <div>
            <strong>E-mail cadastrado</strong>
            <span>{Usuario.email}</span>
          </div>

          <Button label="Enviar" onClick={enviarCodigo} />
        </main>
      </Container>

      <Dialog
        header="Código de autenticação"
        visible={mostrarDialogCodigoAuth}
        onHide={() => setMostrarDialogCodigoAuth(false)}
        style={{ width: '35rem' }}
      >
        <hr></hr>
        <br />
        <p>
          Verifique a caixa de entrada de seu e-mail cadastrado. <br />
          Você precisa acessar o link informado no e-mail enviado para cadastrar
          a nova senha.
        </p>
        <br />
        <hr></hr>

        <DialogFooter>
          <Button label="Concluir" onClick={fecharConcluir} />
        </DialogFooter>
      </Dialog>
    </>
  );
}
