import {
  ButtonBDI,
  DropdownBDI,
  RadioButtonBDI,
} from '@dev_bdi/bdi-front-dspack';
import { Button } from 'primereact/button';
import React, { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../context/config';
import { useToast } from '../../context/toast/useToast';
import { apiOperacoes } from '../../service/ApiOperacoes/api';
import history from '../../service/history';
import { formatISO } from '../../utils/functions/dates';
import { CENTRAL_AJUDA_DATA_REFERENCIA_LINK } from '../../utils/linksCentralAjuda';
import { Box1, Small } from './styles';

export const DataReferencia = () => {
  const { state, setState } = useContext(ConfigContext);
  const [datareferencia, setDatareferencia] = useState();
  const [datas, setDatas] = useState([]);
  const [semdatareferencia, setSemdatareferencia] = useState(true);
  const { showToast } = useToast();
  const { usuarioAntigo, dataPosicaoInicial } = state.configuracao;

  function avancar() {
    localStorage.setItem('semdatareferencia', semdatareferencia);
    localStorage.setItem('datareferencia', datareferencia);

    if (semdatareferencia) history.push(`/cadastroinicial/irrfacumulado`);
    else history.push(`/cadastroinicial/posicoesiniciais`);
  }

  function voltar() {
    localStorage.setItem('semdatareferencia', semdatareferencia);
    localStorage.setItem('datareferencia', datareferencia);
    window.history.back();
  }

  function viewMenu(estado) {
    setState({
      ...state,
      menu: estado,
    });
  }

  async function dataOperacao() {
    try {
      let newDatas;

      if (usuarioAntigo) {
        const dataReferenciaUsuarioMigracao = formatISO(dataPosicaoInicial);
        newDatas = [
          {
            name: dataReferenciaUsuarioMigracao,
            code: 1,
          },
        ];

        localStorage.setItem('datareferencia', dataReferenciaUsuarioMigracao);
        localStorage.setItem('semdatareferencia', false);
      } else {
        const response = await apiOperacoes.get(`DataOperacao`);

        const dataAtual = new Date().toISOString();
        const dataRetroativa = new Date('2015-12-01T00:00:00').toISOString();

        const filterDatas = response.data.filter(
          dt =>
            dt.dataOperacao1 > dataRetroativa && dt.dataOperacao1 < dataAtual,
        );

        newDatas = filterDatas.map(d => {
          return {
            name: formatISO(d.dataOperacao1),
            code: d.idDataOperacao,
          };
        });
      }

      setDatas(newDatas);
    } catch (error) {
      console.error(error);
      showToast();
    }
  }

  function configUsuario({ configuracao }) {
    if (configuracao && !configuracao.usuarioAntigo) {
      history.push('/cadastroinicial/relatorio');
    }
  }

  useEffect(() => {
    state.configuracao && configUsuario(state);
  }, [state.configuracao]);

  useEffect(() => {
    viewMenu(false);
  }, [state.menu]);

  useEffect(() => {
    dataOperacao();

    if (localStorage.getItem('datareferencia'))
      setDatareferencia(localStorage.getItem('datareferencia'));
    localStorage.getItem('semdatareferencia') &&
    localStorage.getItem('semdatareferencia') === 'false'
      ? setSemdatareferencia(false)
      : setSemdatareferencia(true);
  }, []);

  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-12">
        <Box1>
          <section className="card">
            <div className="timeline">
              <div className="container checked left">
                <div className="content checked">
                  <h3>Tipo de data utilizado</h3>
                </div>
              </div>
              <div className="container active left">
                <div className="content active">
                  <h3>Data de referência</h3>
                </div>
              </div>
              <div className="container left">
                <div className="content">
                  <h3>Posições iniciais</h3>
                </div>
              </div>
              <div className="container left">
                <div className="content">
                  <h3>Prejuízos acumulados</h3>
                </div>
              </div>
              <div className="container left">
                <div className="content">
                  <h3>IRRF acumulado</h3>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="card">
              <div className="p-col-12 p-md-12">
                <h2>
                  <strong>Agora</strong>, nos informe a{' '}
                  <strong>data de referência</strong>.{' '}
                  <a
                    href={CENTRAL_AJUDA_DATA_REFERENCIA_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      icon="pi pi-question-circle"
                      style={{ top: '9px' }}
                      className="p-button-rounded p-button-text colorIcon"
                    />
                  </a>
                </h2>
                <br />
                <p className="subTituloBov">
                  A data escolhida será utilizada como referência para cadastrar
                  a posição inicial, prejuízos e impostos acumudos da sua
                  carteira.
                </p>
                <br />
              </div>
              <div className="p-col-12 p-md-8">
                <article>
                  <RadioButtonBDI
                    label="Não tenho posições iniciais, prejuízos ou impostos acumulados para cadastrar.*"
                    inputId="semdatareferencia"
                    name="semdatareferencia"
                    valor="1"
                    checked={usuarioAntigo ? false : semdatareferencia}
                    acao={() => {
                      setSemdatareferencia(true);
                      if (!semdatareferencia) setDatareferencia('');
                    }}
                    disabled={usuarioAntigo}
                  />
                  <RadioButtonBDI
                    label="Desejo informar uma data para cadastrar posições iniciais*"
                    inputId="semdatareferencia1"
                    name="semdatareferencia1"
                    valor="1"
                    checked={usuarioAntigo ? true : !semdatareferencia}
                    acao={() => {
                      setSemdatareferencia(false);
                      if (!semdatareferencia) setDatareferencia('');
                    }}
                    disabled={usuarioAntigo}
                  />
                </article>
              </div>
              {!semdatareferencia && (
                <React.Fragment>
                  <div className="p-col-12 p-md-3">
                    <div className="p-field">
                      <DropdownBDI
                        id="dataRef"
                        placeholder={
                          datareferencia !== null &&
                          datareferencia !== undefined &&
                          datareferencia !== 'undefined' &&
                          datareferencia !== ''
                            ? datareferencia
                            : 'Data de Referência*'
                        }
                        valor={datareferencia}
                        disabled={semdatareferencia || usuarioAntigo}
                        acao={e => {
                          setDatareferencia(e.value.name);
                          setSemdatareferencia(false);
                        }}
                        options={datas}
                        optionLabel="name"
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}

              <div className="p-col-12 p-md-6 preenchimentoobrigatorio">
                <Small>*Preenchimento obrigatório</Small>
              </div>
              <div className="p-col-12 p-md-6">
                <ButtonBDI
                  label="Voltar"
                  className={`
                        ${ButtonBDI.bdi_classe.outlined}
                        ${ButtonBDI.bdi_subclasse.secondary}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => voltar()}
                />
                <ButtonBDI
                  label="Avançar"
                  className={`
                        ${ButtonBDI.bdi_classe.default}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => avancar()}
                  disabled={semdatareferencia || datareferencia ? false : true}
                />
              </div>
            </div>
          </section>
        </Box1>
      </div>
    </div>
  );
};
