import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  border: 1px solid rgba(83, 195, 194, 0.4);
  padding: 2rem;
  border-radius: 0.3rem;
  margin: 3rem 0;

  display: flex;
  align-items: flex-end;
  gap: 1rem;
`;

export const DialogDarfIssuanceDataContainer = styled.article`
  width: 100%;
  border: 1px solid rgba(83, 195, 194, 0.4);
  padding: 2rem;
  border-radius: 0.3rem;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  > p {
    display: inline-block;
    margin-top: 4rem;
  }
`;

export const DialogFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.6rem;
  margin-top: 2rem;
`;
