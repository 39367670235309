import { DataTable } from 'primereact/datatable';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: 1px solid rgba(83, 195, 194, 0.4);
  padding: 2rem;
  border-radius: 0.3rem;
  margin: 3rem 0;

  display: flex;
  align-items: flex-end;
  gap: 1rem;

  @media (max-width: 992px) {
    flex-direction: column;

    div,
    button {
      width: 100%;
    }

    button {
      text-align: center !important;
    }
  }
`;

export const MenuContainer = styled.div`
  button {
    background-color: transparent !important;
  }

  > button.p-button-danger2 {
    top: 0 !important;
    margin: 0 !important;
  }
`;

export const StyledRowExpansionTemplate = styled(DataTable)`
  .p-datatable-thead > tr > th:first-of-type,
  .p-datatable-tbody > tr > td:first-of-type {
    padding-left: 0rem;
  }

  .p-row-toggler {
    visibility: hidden;
  }
`;
