import { Button as PrimeReactButton } from 'primereact/button';

import { Button } from '../../../Button';
import Usuario from '../../../Usuario';
import * as S from './styles';

const getStatusDialogData = (value: string) => {
  switch (value) {
    case 'Período de apuração excedido':
      return {
        title: `Detalhamento do status: ${value}`,
        text: 'Neste plano, somente é permitido cadastrar notas de corretagem do mês de apuração em aberto. A nota cadastrada ultrapassa esse limite, portanto ela não foi incluída no seu cálculo de imposto de renda.Para cadastrar notas de meses anteriores, contrate agora mesmo um dos nossos planos anuais!',
        type: 'failed',
      };
    case 'Nota Duplicada':
      return {
        title: 'Falha: Nota duplicada',
        text: 'Você já importou essa nota anteriormente, portanto, essa versão não foi incluída no cálculo do imposto de renda. Por favor, faça a exclusão dela no Histórico de Notas.',
        type: 'failed',
      };
    case 'Falha':
      return {
        title: `Detalhamento do status: ${value}`,
        text: 'Falha ao importar a nota!',
        type: 'failed',
      };
    case 'Falha ao Processar Arquivo':
      return {
        title: 'Falha de leitura',
        text: 'As informações desta nota não foram lidas corretamente, portanto, não foram incluídas no cálculo do imposto de renda. Por favor, entre em contato com o suporte pelo chat ou por e-mail para checar o motivo.',
        type: 'failed',
      };
    case 'Sucesso':
      return {
        title: `Detalhamento do status: ${value}`,
        text: 'Sua nota foi processada com sucesso e agora está constando no seu cálculo do imposto de renda.',
        type: 'success',
      };
    case 'Limite Tipo Negócio Plano ultrapassado':
      return {
        title: 'Detalhamento do status: Day Trade não contemplado no plano',
        text: 'Seu plano não possui permissão para efetuar o cálculo de imposto de renda em day trade. Por isso, essa nota não foi incluída em seu cálculo de imposto de renda. Para cadastrar quantas operações precisar, de swing trade ou day trade, faça o upgrade de plano agora mesmo! ',
        type: 'success',
      };
    case 'Limite Corretoras Plano ultrapassado':
      return {
        title: `Detalhamento do status: ${value}`,
        text: 'Você ultrapassou o limite de corretoras permitidas nesse plano e, por isso, essa nota não foi utilizada no cálculo de imposto de renda. Faça agora a contratação de um plano para cadastrar quantas corretoras quiser!',
        type: 'success',
      };
    case 'Limite CPF Plano ultrapassado':
      return {
        title: `Detalhamento do status: ${value}`,
        text: `Somente é permitido o cálculo de imposto de renda para o CPF ${Usuario.cpf} cadastrado. Por isso, essa nota não foi utilizada no cálculo.Para mais informações, entre em contato com o nosso suporte pelo chat ou pelo e-mail comunidade@bussoladoinvestidor.com.`,
        type: 'success',
      };
    case 'Limite Operacoes Plano ultrapassado':
      return {
        title: `Detalhamento do status: ${value}`,
        text: 'Você ultrapassou a quantidade de operações permitidas nesse plano. Por isso, essa nota não foi utilizada no cálculo do imposto de renda. Para cadastrar outras operações, entre em contato com o nosso suporte pelo chat ou no e-mail comunidade@bussoladoinvestidor.com para conhecer os planos personalizados.',
        type: 'success',
      };
    case 'Limite Importacao Plano ultrapassado':
      return {
        title: `Detalhamento do status: ${value}`,
        text: 'Somente é permitido cadastrar notas de corretagem dentro do período contratado. A nota cadastrada ultrapassa o limite do seu plano, portanto ela não foi incluída no seu cálculo de imposto de renda. Para cadastrar notas de meses anteriores, contrate agora mesmo um dos nossos planos!',
        type: 'success',
      };
    case 'Limite Tipo Nota Plano ultrapassado':
      return {
        title: `Detalhamento do status: ${value}`,
        text: 'Você ultrapassou os limites desse plano. Essa nota não foi utlizada no cálculo. Para utilizar essa nota no seu cálculo é necessário fazer a contratação de um plano que atenda os requisitos da nota.',
        type: 'success',
      };
    case 'Emissao Efetuado apos Periodo':
      return {
        title: `Detalhamento do status: ${value}`,
        text: 'Somente é permitido processar notas com data de pregão posterior a última emissão darf efetuada.',
        type: 'success',
      };
    case 'Nota Posterior Data Referencia':
      return {
        title: `Detalhamento do status: ${value}`,
        text: 'Somente é permitido processar notas com data de pregão posterior a data de referencia de suas Posições Iniciais.',
        type: 'success',
      };
  }
};

type StatusDialogProps = {
  visible: boolean;
  variant: string;
  onHide: () => void;
  onShowInconsistenciaDialog: () => void;
};

export function StatusDialog({
  visible,
  variant,
  onHide,
  onShowInconsistenciaDialog,
}: StatusDialogProps) {
  const data = getStatusDialogData(variant);

  const dialogFooter = (
    <>
      <Button label="ENTENDI" className="tertiary" onClick={onHide} />
      <a
        href="https://smarttbot.com/planos-smarttir/"
        target="_blank"
        rel="noreferrer"
      >
        <PrimeReactButton label="CONHECER PLANOS" onClick={onHide} />
      </a>
    </>
  );

  const dialogFooterFailed = () => {
    if (data?.title === 'Falha ao Processar Arquivo') {
      return (
        <>
          <Button
            label="Deseja informar inconsistência?"
            className="tertiary"
            onClick={() => {
              onHide();
              onShowInconsistenciaDialog();
            }}
          />
          <PrimeReactButton label="Entendi" onClick={onHide} />
        </>
      );
    }

    return <PrimeReactButton label="Entendi" onClick={onHide} />;
  };

  return (
    <S.StatusDialogContainer
      visible={visible}
      onHide={onHide}
      header={data?.title}
      footer={data?.type === 'success' ? dialogFooter : dialogFooterFailed}
      style={{ width: 'min(40rem, 80vw)' }}
      modal
    >
      <p>{data?.text}</p>
    </S.StatusDialogContainer>
  );
}
