import Cookies from 'js-cookie';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useReducer, useRef, useState } from 'react';

import { BaseModule } from '../../../../components/BaseModule';
import { DESDOBRAMENTO_GRUPAMENTO_E_BONIFICACAO } from '../../../../components/BaseModule/constants';
import { ContainerFiltro } from '../../../../components/ContainerFiltro';
import { useToast } from '../../../../context/toast/useToast';
import { useLocale } from '../../../../hooks/useLocale';
import * as ES from '../../../../styles/elements/MenuButton';
import { EVENT_TYPE, STATUS_TYPES } from './constants';
import { AddBoniPriceDialog } from './dialogs/AddBoniPriceDialog';
import { useDesdobramentoGrupamento } from './hooks/useDesdobramentoGrupamento';
import { useExecuteEventMutation } from './hooks/useExecuteEventMutation';
import { useFilterEventMutation } from './hooks/useFilterEventMutation';
import * as S from './styles';
import {
  EventModalData,
  Status_Desdobramento_Grupamento,
  UserEvent,
} from './types';

type ReducerAction =
  | { ticker: number }
  | { status: Status_Desdobramento_Grupamento }
  | { dataEvento: string | null }
  | { tipoEvento: number };

type ReducerState = {
  ticker: number;
  status: Status_Desdobramento_Grupamento;
  tipoEvento: number;
  dataEvento: string | null;
};

const INITIAL_REDUCER_VALUE = {
  ticker: 0,
  status: '' as Status_Desdobramento_Grupamento,
  dataEvento: null,
  tipoEvento: 0,
};

export function DesdobramentoGrupamentoBonificacao() {
  const [filterState, dispatch] = useReducer(
    (state: ReducerState, action: ReducerAction) => ({ ...state, ...action }),
    INITIAL_REDUCER_VALUE,
  );
  const [isAddBoniPriceDialogOpen, setIsAddBoniPriceDialogOpen] =
    useState(false);
  const [showDialogForm, setShowDialogForm] = useState(true);
  const [currentRowData, setCurrentRowData] = useState({} as EventModalData);
  const menuRefs = useRef<Map<number, Menu> | null>(null);

  const userId = Cookies.get('Id');
  const { locale } = useLocale();
  const { showToast } = useToast();

  // React Query Hooks
  const [desdobramentosQuery, instrumentosQuery] =
    useDesdobramentoGrupamento(userId);
  const changeFilterEvent = useFilterEventMutation(userId);
  const executeEvent = useExecuteEventMutation(userId);

  function getMenuMap() {
    if (!menuRefs.current) menuRefs.current = new Map();
    return menuRefs.current;
  }

  function getCurrentMenu(id: number) {
    const map = getMenuMap();
    const node = map.get(id);

    return node;
  }

  function handleFilterEvent() {
    const { ticker, status, dataEvento: reducerDate, tipoEvento } = filterState;
    const formatDate = (date: string) =>
      new Date(date).toISOString().split('T')[0];
    const newFilter = {
      idUsuario: userId,
      idInstrumento: ticker,
      dataEvento: reducerDate ? formatDate(reducerDate) : null,
      idTipoEvento: tipoEvento,
      status,
    };

    changeFilterEvent.mutate(newFilter);
  }

  function handleExecuteEvent(data: UserEvent, authorization: boolean) {
    if (!userId) {
      showToast();
      return;
    }

    const eventObj = {
      idUsuario: userId,
      idDesdobramentoGrupamento: data.id,
      autorizacao: authorization,
      corretora: data.idCorretora,
    };

    executeEvent.mutate(eventObj);
  }

  function handleOpenAddBoniPriceDialog(rowData: UserEvent) {
    if (!userId) {
      showToast();
      return;
    }

    const addBoniPriceDataObj = {
      ...rowData,
      idUsuario: userId,
    };

    setCurrentRowData(addBoniPriceDataObj);
    setIsAddBoniPriceDialogOpen(true);
  }

  // Templates
  const statusTemplate = ({ status }: UserEvent) => (
    <S.StatusTag status={status}>{status}</S.StatusTag>
  );

  const menuTemplate = (rowData: UserEvent) => {
    const overlayItems: MenuItem[] = [];

    if (rowData.tipoEvento === 'Bonificação' && rowData.status === 'Pendente') {
      overlayItems.push({
        label: 'Adicionar Preço',
        command: () => {
          handleOpenAddBoniPriceDialog(rowData);
          setShowDialogForm(true);
        },
      });
    } else if (rowData.status === 'Aceito') {
      overlayItems.push({
        label: 'Exibir Preço',
        command: () => {
          handleOpenAddBoniPriceDialog(rowData);
          setShowDialogForm(false);
        },
      });
    } else {
      overlayItems.push(
        {
          label: 'Aceitar',
          command: () => handleExecuteEvent(rowData, true),
        },
        {
          label: 'Recusar',
          command: () => handleExecuteEvent(rowData, false),
        },
      );
    }

    const createMapKey = (numbers: number[]) => +numbers.join('');

    return (
      <>
        <Menu
          id="popup_menu"
          model={overlayItems}
          ref={node => {
            const map = getMenuMap();

            if (node) {
              map.set(createMapKey([rowData.id, rowData.idCorretora]), node);
            } else {
              map.delete(createMapKey([rowData.id, rowData.idCorretora]));
            }
          }}
          popup
        />
        <ES.MenuButton
          aria-controls="popup_menu"
          icon="pi pi-ellipsis-v"
          disabled={rowData.status === 'Negado'}
          onClick={event => {
            const currentRef = getCurrentMenu(
              createMapKey([rowData.id, rowData.idCorretora]),
            );
            !!currentRef && currentRef.toggle(event);
          }}
        />
      </>
    );
  };

  return (
    <>
      <BaseModule tipo={DESDOBRAMENTO_GRUPAMENTO_E_BONIFICACAO}>
        <ContainerFiltro
          onLeftButtonClick={() => dispatch(INITIAL_REDUCER_VALUE)}
          onRightButtonClick={handleFilterEvent}
          rightButtonDisabled={changeFilterEvent.isLoading}
        >
          <div>
            <label htmlFor="ticker">Ticker</label>
            <Dropdown
              id="ticker"
              name="ticker"
              value={filterState.ticker}
              onChange={e => dispatch({ ticker: e.value })}
              options={instrumentosQuery.data}
            />
          </div>
          <div>
            <label htmlFor="dataEvento">Data do Evento</label>
            <Calendar
              id="dataEvento"
              name="dataEvento"
              dateFormat="dd/mm/yy"
              locale={locale}
              value={filterState.dataEvento as unknown as Date}
              onChange={e =>
                !!e.value && dispatch({ dataEvento: e.value.toString() })
              }
            />
          </div>
          <div>
            <label htmlFor="status">Status</label>
            <Dropdown
              id="status"
              name="status"
              value={filterState.status}
              options={STATUS_TYPES}
              onChange={e => dispatch({ status: e.value })}
            />
          </div>
          <div>
            <label htmlFor="tipoEvento">Tipo de evento</label>
            <Dropdown
              id="tipoEvento"
              name="tipoEvento"
              value={filterState.tipoEvento}
              onChange={e => dispatch({ tipoEvento: e.value })}
              options={EVENT_TYPE}
            />
          </div>
        </ContainerFiltro>

        <S.DataTableWrapper>
          <S.DataTableContainer
            value={desdobramentosQuery.data}
            sortMode="multiple"
            responsiveLayout="scroll"
            emptyMessage="Lista vazia."
            paginator
            currentPageReportTemplate="Mostrando de {first} até {last} - Total de {totalRecords} registros"
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            loading={desdobramentosQuery.isLoading}
          >
            <Column field="corretora" header="Corretora" sortable />
            <Column field="dataEvento" header="Data de evento" sortable />
            <Column field="ticker" header="Ticker" sortable />
            <Column field="mudanca" header="Mudança" sortable />
            <Column field="quantidade" header="Qtde" sortable />
            <Column field="precoMedio" header="Preço médio" sortable />
            <Column field="tipoEvento" header="Tipo de evento" sortable />
            <Column
              field="novaQuantidadeAberto"
              header="Nova qtde em aberto"
              sortable
            />
            <Column
              field="novoPrecoAberto"
              header="Novo preço médio"
              sortable
            />
            <Column
              field="status"
              header="Status"
              sortable
              body={statusTemplate}
            />
            <Column body={menuTemplate} />
          </S.DataTableContainer>
        </S.DataTableWrapper>
      </BaseModule>

      <AddBoniPriceDialog
        visible={isAddBoniPriceDialogOpen}
        showDialogForm={showDialogForm}
        onHide={() => setIsAddBoniPriceDialogOpen(false)}
        eventData={currentRowData}
      />
    </>
  );
}
