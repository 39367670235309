import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

export function RowExpensiveTable({ data, columns }) {
  return (
    <DataTable
      value={Array.isArray(data) ? data : []}
      responsiveLayout="scroll"
      emptyMessage="Lista vazia."
    >
      {Array.isArray(columns) &&
        columns.map(({ field, header, className }, index) => (
          <Column
            field={field}
            header={header}
            key={`${index}-field`}
            className={className}
          />
        ))}
    </DataTable>
  );
}
