import { Button } from 'primereact/button';
import styled from 'styled-components';

export const MenuButton = styled(Button)`
  background-color: transparent;
  transition: background-color 300ms;

  &:hover {
    background-color: ${({ theme }) => theme.white} !important;
  }

  &:disabled {
    pointer-events: all;
    cursor: not-allowed !important;
  }
`;
