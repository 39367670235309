import { AxiosRequestConfig } from 'axios';

import { apiRelatorios } from '../api';
import { GetRelatorioOperacaoSwingDayTradeDateTimeData } from './types';

export const getRelatorioOperacaoSwingDayTradeDateTime = (
  idUsuario: string,
  filtroData: string,
  options: AxiosRequestConfig,
) =>
  apiRelatorios.get<GetRelatorioOperacaoSwingDayTradeDateTimeData>(
    `/RelatorioOpComumDayTrade/v1/GetRelatorioOperacaoSwingDayTradeDateTime/${idUsuario}/${filtroData}`,
    options,
  );
