import { useQuery } from '@tanstack/react-query';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { MultiSelect, MultiSelectChangeParams } from 'primereact/multiselect';

import { useToast } from '../../../../../context/toast/useToast';
import { ListaMesAnoCorretoras } from '../../../../../service/ApiRelatorios/FiltroBusca/types';
import { GreenBorderBox } from '../../../../../styles/elements/GreenBorderBox';
import { FiltroSelecionado } from '../types';
import { SkeletonFiltro } from './SkeletonFiltro';
import * as S from './styles';
import { filtraCorretoras, handleRequest } from './utils';

type FiltroProps = {
  filtroSelecionado: FiltroSelecionado;
  onSetFiltro: (filtroSelecionado: FiltroSelecionado) => void;
};

type HandleOnSuccessParams = {
  listaMesAno: string[];
  listaMesAnoCorretorasFormatted: ListaMesAnoCorretoras;
};

export function Filtro({ onSetFiltro, filtroSelecionado }: FiltroProps) {
  const { showToast } = useToast();

  const handleOnSuccess = ({
    listaMesAno,
    listaMesAnoCorretorasFormatted,
  }: HandleOnSuccessParams) => {
    const mesAno = listaMesAno[0];

    onSetFiltro({
      corretoras: filtraCorretoras(mesAno, listaMesAnoCorretorasFormatted),
      mesAno,
    });
  };

  const handleOnError = (error: Error) => {
    showToast();
    console.error(error);
  };

  const { data, isLoading, error } = useQuery(
    ['MesAnoECorretoraOperacoes'],
    handleRequest,
    {
      onSuccess: handleOnSuccess,
      onError: handleOnError,
    },
  );

  const handleOnChange = (
    e: DropdownChangeParams | MultiSelectChangeParams,
  ) => {
    const { value, id } = e.target;

    if (id === 'mesAno') {
      const newFiltro = {
        ...filtroSelecionado,
        [id]: value,
        corretoras: filtraCorretoras(
          value,
          data?.listaMesAnoCorretorasFormatted,
        ),
      };
      onSetFiltro(newFiltro);
    } else {
      const newFiltro = { ...filtroSelecionado, [id]: value };
      onSetFiltro(newFiltro);
    }
  };

  if (isLoading || error) {
    return <SkeletonFiltro />;
  }

  return (
    <GreenBorderBox>
      <S.Container>
        <div>
          <label htmlFor="mesAno">Mês/Ano</label>
          <Dropdown
            id="mesAno"
            options={data?.listaMesAno}
            value={filtroSelecionado.mesAno}
            onChange={handleOnChange}
            emptyMessage="Sem meses correspondentes"
          />
        </div>
        <div>
          <label htmlFor="corretoras">Corretora</label>
          <MultiSelect
            id="corretoras"
            options={filtraCorretoras(
              filtroSelecionado.mesAno,
              data?.listaMesAnoCorretorasFormatted,
            )}
            optionLabel="nome"
            value={filtroSelecionado.corretoras}
            onChange={handleOnChange}
            maxSelectedLabels={2}
            selectedItemsLabel={`{0} itens selecionados`}
          />
        </div>
      </S.Container>
    </GreenBorderBox>
  );
}
