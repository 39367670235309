import axios from 'axios';
import { addLocale } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';

import {
  DELETE_INVOICE_HISTORY_ERROR,
  DELETE_INVOICE_HISTORY_SUCCESS,
  EDIT_INVOICE_ERROR,
  EDIT_INVOICE_SUCCESS,
  EMPTY_MESSAGE,
  NO_ATTACHED_FILES,
  SEND_EMAIL_ERROR,
  SEND_EMAIL_SUCCESS,
} from '../../context/toast/constants';
import { useToast } from '../../context/toast/useToast';
import ProductService from '../../service/ProductService';
import { RegraValorAjuste } from '../../utils/functions';
import { CENTRAL_AJUDA_HISTORICO_NOTAS_LINK } from '../../utils/linksCentralAjuda';
import { ContainerFiltro } from '../ContainerFiltro';
import PaymentPlan from '../PaymentPlan';
import Usuario from '../Usuario';
import api from './api';
import './historiconotas.css';
import * as S from './styles';

const STATUS = new Map([
  [2, [1, 2, 6, 7, 8]],
  [3, [3, 15, 9, 16, 10, 11, 12, 13, 14, 17, 18]],
  [4, [4]],
  [5, [5]],
]);

export const HistoricoNotas = () => {
  const [dataInicio, setDataInicio] = useState(null);
  const [dataPregao, setDataPregao] = useState(null);
  const [numeroDaNota, setNumeroDaNota] = useState();
  const [corretora, setCorretora] = useState(null);
  const [listaCorretoras, setListaCorretoras] = useState([]);
  const [listaStatusDoProcesso, setListaStatusDoProcesso] = useState([]);
  const [statusDoProcesso, setStatusDoProcesso] = useState(null);
  const [dropdownValue, setDropdownValue] = useState(null);
  const toast = useRef(null);
  const [listaStatus, setListaStatus] = useState([]);
  const [listaStatusResultado, setListaStatusResultado] = useState([]);
  const [listaStatusResultadoRodapeB3, setListaStatusResultadoRodapeB3] =
    useState([]);
  const [listaStatusResultadoRodapeBmf, setListaStatusResultadoRodapeBmf] =
    useState([]);

  const menu = useRef(null);
  const [inconsistenciaDialog, setInconsistenciaDialog] = useState(false);
  const [deletarDialog, setDeletarDialog] = useState(false);
  const [deletarDialog2, setDeletarDialog2] = useState(false);
  const [mensagemInconsistencia, setMensagemInconsistencia] = useState('');
  const [detalhes, setDetalhes] = useState(false);
  const [products, setProducts] = useState([]);
  const productService = new ProductService();
  const [listaDetalhes, setListaDetalhes] = useState([]);
  const detalhesService = new ProductService();
  const [usuario, setUsuario] = useState({});
  const [planoUser, setPlanoUser] = useState('');
  const [linhaSelecionada, setLinhaSelecionada] = useState({});
  const [linhaSelecionadaManual, setLinhaSelecionadaManual] = useState({});
  const [irrfdayTrade, setIrrfdayTrade] = useState('');
  const [debentures, setDebentures] = useState('');
  const [vendasAvista, setVendasAvista] = useState('');
  const [comprasAvista, setComprasAvista] = useState('');
  const [opcoesCompras, setOpcoesCompras] = useState('');
  const [opcoesVendas, setOpcoesVendas] = useState('');
  const [operacoesTermo, setOperacoesTermo] = useState('');
  const [valorOperacoesTituloPublico, setValorOperacoesTituloPublico] =
    useState('');
  const [valorOperacoes, setValorOperacoes] = useState('');
  const [projecao, setProjecao] = useState('');
  const [totalCblcdc, setTotalCblcdc] = useState('');
  const [valorLiquidoOperacoes, setValorLiquidoOperacoes] = useState('');
  const [valorLiquidoOperacoesDc, setValorLiquidoOperacoesDc] = useState('');
  const [taxaRegistro, setTaxaRegistro] = useState('');
  const [totalCblc, setTotalCblc] = useState('');
  const [taxaLiquidacao, setTaxaLiquidacao] = useState('');
  const [taxaTermoOpcoesFuturos, setTaxaTermoOpcoesFuturos] = useState('');
  const [taxaAna, setTaxaAna] = useState('');
  const [emolumentos, setEmolumentos] = useState('');
  const [totalBovespaSoma, setTotalBovespaSoma] = useState('');
  const [execucaoCorretagem, setExecucaoCorretagem] = useState('');
  const [taxaOperacionalClearing, setTaxaOperacionalClearing] = useState('');
  const [impostosIss, setImpostosIss] = useState('');
  const [irrf, setIrrf] = useState('');
  const [outros, setOutros] = useState('');
  const [totalCustosDespesas, setTotalCustosDespesas] = useState('');
  const [liquido, setLiquido] = useState('');
  const [liquidoDc, setLiquidoDc] = useState('');
  const [liquidoData, setLiquidoData] = useState('');
  const [vendaDisponivel, setVendaDisponivel] = useState('');
  const [compraDisponivel, setCompraDisponivel] = useState('');
  const [vendaOpcoes, setVendaOpcoes] = useState('');
  const [compraOpcoes, setCompraOpcoes] = useState('');
  const [valorDosNegocios, setValorDosNegocios] = useState('');
  const [valorDosNegociosDc, setValorDosNegociosDc] = useState('');
  const [irrfbmf, setIrrfbmf] = useState('');
  const [irrfdayTradebmf, setIrrfdayTradebmf] = useState('');
  const [taxaOperacional, setTaxaOperacional] = useState('');
  const [taxaRegistroBmf, setTaxaRegistroBmf] = useState('');
  const [taxaBmf, setTaxaBmf] = useState('');
  const [outrosCustos, setOutrosCustos] = useState('');
  const [iss, setIss] = useState('');
  const [ajusteDePosicao, setAjusteDePosicao] = useState('');
  const [ajusteDePosicaoDc, setAjusteDePosicaoDc] = useState('');
  const [ajusteDayTrade, setAjusteDayTrade] = useState('');
  const [ajusteDayTradeDc, setAjusteDayTradeDc] = useState('');
  const [totalDasDespesas, setTotalDasDespesas] = useState('');
  const [totalDasDespesasDc, setTotalDasDespesasDc] = useState('');
  const [outrosbmf, setOutrosbmf] = useState('');
  const [irrfcorretagem, setIrrfcorretagem] = useState('');
  const [totalContaInvestimento, setTotalContaInvestimento] = useState('');
  const [totalContaInvestimentoDc, setTotalContaInvestimentoDc] = useState('');
  const [totalContaNormal, setTotalContaNormal] = useState('');
  const [totalContaNormalDc, setTotalContaNormalDc] = useState('');
  const [totalLiquido, setTotalLiquido] = useState('');
  const [totalLiquidoDc, setTotalLiquidoDc] = useState('');
  const [totalLiquidoNota, setTotalLiquidoNota] = useState('');
  const [totalLiquidoNotaDc, setTotalLiquidoNotaDc] = useState('');
  const [displayMaximizable, setDisplayMaximizable] = useState(false);
  const [displayMaximizable2, setDisplayMaximizable2] = useState(false);
  const [textDialog, setTextDialog] = useState('');
  const [retornoStatus, setRetornoStatus] = useState('');
  const [retornoStatusErro, setRetornoStatusErro] = useState('');

  const [tipoMercado, setTipoMercado] = useState(null);
  const [listaTipoMercado, setListaTipoMercado] = useState([]);

  const [tipoNegocio, setTipoNegocio] = useState(null);
  const [listaTipoNegocio, setListaTipoNegocio] = useState([]);
  const [statusDialog, setStatusDialog] = useState(false);
  const [statusDialogFalha, setStatusDialogFalha] = useState(false);
  const [tituloDialogStatus, setTituloDialogStatus] = useState('');
  const [valorLiquido, setValorLiquido] = useState(null);
  const [podeExcluir, setPodeExcluir] = useState(false);

  const [testeUpload, setTesteUpload] = useState('');

  const [selectedStatus, setSelectedStatus] = useState('');

  const { showToast } = useToast();
  const informarInconsistenciaFileRef = useRef(null);

  const dialogFuncMap = {
    displayMaximizable: setDisplayMaximizable,
  };

  const dialogFuncMap2 = {
    displayMaximizable2: setDisplayMaximizable2,
  };

  const onHide = name => {
    dialogFuncMap[`${name}`](false);
  };

  const fecharStatusDialog = () => {
    setStatusDialog(false);
  };

  const fecharStatusDialogFalha = () => {
    setStatusDialogFalha(false);
  };

  const fechaDialogFooter = (
    <>
      <Button
        label="ENTENDI"
        onClick={fecharStatusDialog}
        className="p-button-outlined p-button-info p-mb-2 fontBotaoCancelar espacamentoBtnCancelar"
      />
      <a
        href="https://smarttbot.com/planos-smarttir/"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          label="CONHECER PLANOS"
          className="p-button-severities p-button-primary p-mb-2"
          onClick={fecharStatusDialog}
        />
      </a>
    </>
  );

  const fechaDialogFooterFalha = () => {
    if (selectedStatus === 'Falha ao Processar Arquivo') {
      return (
        <>
          <Button
            label="Informar inconsistência"
            className="p-button-outlined p-button-info p-mb-2 fontBotaoCancelar espacamentoBtnCancelar"
            onClick={() => setInconsistenciaDialog(true)}
          />
          <Button
            label="Entendi"
            onClick={fecharStatusDialogFalha}
            className="p-button-severities p-button-primary p-mr-2 p-mb-2"
          />
        </>
      );
    }

    return (
      <Button
        label="Entendi"
        onClick={fecharStatusDialogFalha}
        className="p-button-severities p-button-primary p-mr-2 p-mb-2"
      />
    );
  };

  const onHide2 = name => {
    dialogFuncMap2[`${name}`](false);
  };

  useEffect(() => {
    setUsuario(Usuario);
    setPlanoUser(PaymentPlan);
  }, []);

  useEffect(() => {
    productService.getProductsSmall().then(data => setProducts(data));
    detalhesService.getProducts().then(data => setListaDetalhes(data));
  }, []);

  function alterarLinhaMostrarDetalhes(rowData, event) {
    alterarLinhaSelecionada(rowData);
    menu.current.toggle(event);
  }

  const teste = rowData => {
    return (
      <div>
        <Menu
          model={overlayMenuItems}
          popup
          ref={el => {
            menu.current = el;
          }}
          id="popup_menu"
        />
        <Button
          label=""
          icon="pi pi-ellipsis-v"
          className="p-button-severities p-button-danger2 p-mr-2 p-mb-2"
          onClick={event => alterarLinhaMostrarDetalhes(rowData, event)}
          aria-controls="popup_menu"
          aria-haspopup
          model={overlayMenuItems}
        />
      </div>
    );
  };

  //Paginação Itens por página
  const [currentPage, setCurrentPage] = useState(1);
  const [first1, setFirst1] = useState(0);
  const [rows1, setRows1] = useState(5);

  const onCustomPage1 = event => {
    setFirst1(event.first);
    setRows1(event.rows);
    setCurrentPage(event.page + 1);
  };
  const template2 = {
    layout: 'CurrentPageReport PrevPageLink NextPageLink RowsPerPageDropdown',
    RowsPerPageDropdown: options => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 15, value: 15 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <>
          <span
            className="p-mx-1"
            style={{ color: 'var(--text-color)', userSelect: 'none' }}
          >
            Itens por página:{' '}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            appendTo={document.body}
          />
        </>
      );
    },
    CurrentPageReport: options => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            width: '120px',
            textAlign: 'center',
          }}
        >
          {options.first} - {options.last} de {options.totalRecords}
        </span>
      );
    },
  };

  //FIM Paginação Itens por página

  //Paginação Itens por página
  const [currentPage2, setCurrentPage2] = useState(1);
  const [first2, setFirst2] = useState(0);
  const [rows2, setRows2] = useState(5);

  const onCustomPage2 = event => {
    setFirst2(event.first);
    setRows2(event.rows);
    setCurrentPage2(event.page + 1);
  };
  const template3 = {
    layout: 'CurrentPageReport PrevPageLink NextPageLink RowsPerPageDropdown',
    RowsPerPageDropdown: options => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 15, value: 15 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <>
          <span
            className="p-mx-1"
            style={{ color: 'var(--text-color)', userSelect: 'none' }}
          >
            Itens por página:{' '}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            appendTo={document.body}
          />
        </>
      );
    },
    CurrentPageReport: options => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            width: '120px',
            textAlign: 'center',
          }}
        >
          {options.first} - {options.last} de {options.totalRecords}
        </span>
      );
    },
  };

  //FIM Paginação Itens por página

  addLocale('br', {
    firstDayOfWeek: 1,
    dayNames: [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'JAN',
      'FEV',
      'MAR',
      'ABR',
      'MAY',
      'JUN',
      'JUL',
      'AGO',
      'SET',
      'OUT',
      'NOV',
      'DEZ',
    ],
    today: 'Hoje',
  });

  //LISTA de TIPO MERCADO
  function loadListTipoMercado(data) {
    const newListUser = data.map(user => {
      return {
        name: user.nome,
        code: user.idTipoMercado,
      };
    });
    setListaTipoMercado(newListUser);
  }

  useEffect(() => {
    async function createListTipoMercado() {
      fetch(`${process.env.REACT_APP_API_OPERACOES}/TipoMercado`)
        .then(result => result.json())
        .then(data => {
          loadListTipoMercado(data);
        })
        .catch(error => {
          console.error(error);
          showToast();
        });
    }
    createListTipoMercado();
  }, []);
  //FIM LISTA de TIPO MERCADO

  //LISTA de TIPO NEGÓCIO
  function loadListTipoNegocio(data) {
    const newListUser = data.map(user => {
      return {
        name: user.nome,
        code: user.idTipoNegocio,
      };
    });
    setListaTipoNegocio(newListUser);
  }

  useEffect(() => {
    async function createListTipoNegocio() {
      fetch(`${process.env.REACT_APP_API_OPERACOES}/TipoNegocio`)
        .then(result => result.json())
        .then(data => {
          loadListTipoNegocio(data);
        })
        .catch(error => {
          console.error(error);
          showToast();
        });
    }
    createListTipoNegocio();
  }, []);
  //FIM LISTA de TIPO NEGÓCIO

  //LISTA de CORRETORAS
  function loadListCorretoras(data) {
    const newListUser = data.map(user => {
      return {
        name: user.apelido,
        code: user.idCorretora,
      };
    });
    setListaCorretoras(newListUser);
  }

  useEffect(() => {
    async function createListCorretoras() {
      fetch(`${process.env.REACT_APP_API_OPERACOES}/Corretoras`)
        .then(result => result.json())
        .then(data => {
          loadListCorretoras(data);
        })
        .catch(error => {
          console.error(error);
          showToast();
        });
    }
    createListCorretoras();
  }, []);
  //FIM LISTA de CORRETORAS

  //LISTA de StatusDoProcesso
  function loadListStatusDoProcesso(data) {
    const newListUser = data.map(user => {
      return {
        name: user.nome,
        code: user.idStatusProcesso,
      };
    });

    const newListUserFilter = [];
    newListUser.map(item => {
      if (item !== null) {
        if (item.code !== null) {
          if (
            item.code === 2 ||
            item.code === 3 ||
            item.code === 4 ||
            item.code === 5
          ) {
            newListUserFilter.push(item);
          }
        }
      }
    });

    setListaStatusDoProcesso(newListUserFilter);
  }

  useEffect(() => {
    async function createListStatusDoProcesso() {
      fetch(`${process.env.REACT_APP_API_OPERACOES}/StatusDoProcesso`)
        .then(result => result.json())
        .then(data => {
          loadListStatusDoProcesso(data);
        })
        .catch(error => {
          console.error(error);

          showToast();
        });
    }
    createListStatusDoProcesso();
  }, []);
  //FIM LISTA de StatusDoProcesso
  function loadListStatus(data) {
    const newListStatus = data.statusList.map(sp => {
      setTesteUpload(sp);
      return {
        idStatusProcessamento: sp.idStatusProcessamento,
        idNotaCorretagem:
          sp.nota === null || sp.nota === undefined
            ? 0
            : sp.nota.idNotaCorretagem === null ||
              sp.nota.idNotaCorretagem == undefined
            ? 0
            : sp.nota.idNotaCorretagem,
        data: sp.data,
        arquivoNovo:
          sp.nota === null || sp.nota === undefined
            ? ''
            : sp.nota.idNotaCorretagemArquivoNavigation === null ||
              sp.nota.idNotaCorretagemArquivoNavigation === undefined
            ? 'Cadastro Manual'
            : sp.nota.idNotaCorretagemArquivoNavigation.arquivoOriginal,
        detalhe: sp.detalhe.trim(),
        corretora:
          sp.nota === null || sp.nota === undefined
            ? ''
            : sp.nota.idCorretoraNavigation?.apelido,
        ndanota:
          sp.nota === null || sp.nota == undefined
            ? ''
            : sp.nota.numeroNotaCorretora === null ||
              sp.nota.numeroNotaCorretora === undefined
            ? ''
            : sp.nota.numeroNotaCorretora,
        pregao:
          sp.nota === null || sp.nota === undefined ? '' : sp.nota.dataPregao,
        //pregao: sp.nota.dataPregao ? sp.nota.dataPregao : "",
        idTipoNota:
          sp.nota === null || sp.nota === undefined
            ? ''
            : sp.nota.idTipoNotaNavigation?.nome,
        status: sp.status,
        cpf: sp.nota === null || sp.nota === undefined ? '' : sp.nota.cpfNota,
        cnpjNota:
          sp.nota === null || sp.nota === undefined ? '' : sp.nota.cnpjNota,
        ativo: sp.ativo,
        notaAtiva:
          sp.nota === null || sp.nota === undefined ? 1 : sp.nota.notaAtiva,
      };
    });
    setListaStatus(newListStatus);
  }

  //Consultar dados GET API de Status Processamento por Usuário
  useEffect(() => {
    async function createListStatus() {
      let idusuario = Usuario.id;
      fetch(
        `${process.env.REACT_APP_API_STATUSPROCESSAMENTO}/StatusNotaCorretagem/v1/RetornaStatusNotaCorretagemPorUploadUsuario/${idusuario}/7/0/9999`,
      )
        .then(result => result.json())
        .then(data => {
          loadListStatus(data);
        })
        .catch(error => {
          console.error(error);
          showToast();
        });
    }
    createListStatus();
  }, []);

  const statusTemplate = rowData => {
    var textClass = '';
    if (
      rowData.status !== 'Processada' &&
      rowData.status !== 'Falha' &&
      rowData.status !== 'DARF Emitido' &&
      rowData.status !== 'DARF Gerado' &&
      rowData.status !== 'Sem Calculo IR' &&
      rowData.status !== 'Sem Fechamento Day Trade' &&
      rowData.status !== 'Sem Fechamento Swing Trade' &&
      rowData.status !== 'Sucesso' &&
      rowData.status !== 'Nota Duplicada' &&
      rowData.status !== 'Falha ao Processar Arquivo' &&
      rowData.status !== 'Limite Corretoras Plano ultrapassado' &&
      rowData.status !== 'Limite CPF Plano ultrapassado' &&
      rowData.status !== 'Limite Tipo Negócio Plano ultrapassado' &&
      rowData.status !== 'Limite Tipo Nota Plano ultrapassado' &&
      rowData.status !== 'Limite Operacoes Plano ultrapassado' &&
      rowData.status !== 'Limite Importacao Plano ultrapassado' &&
      rowData.status !== 'Nota Duplicada' &&
      rowData.status !== 'Emissao Efetuado apos Periodo' &&
      rowData.status !== 'Nota Posterior Data Referencia'
    ) {
      textClass = 'pendente';
      setPodeExcluir(true);
    } else if (
      rowData.status === 'Sucesso' ||
      rowData.status === 'Processada'
    ) {
      textClass = 'processada';
    } else if (
      rowData.status === 'Nota DuplicadaNota' ||
      rowData.status === 'DuplicadaNDPL' ||
      rowData.status === 'Nota Duplicada' ||
      rowData.status === 'Falha ao Processar Arquivo' ||
      rowData.status === 'Limite Corretoras Plano ultrapassado' ||
      rowData.status === 'Limite CPF Plano ultrapassado' ||
      rowData.status === 'Limite Tipo Negócio Plano ultrapassado' ||
      rowData.status === 'Limite Tipo Nota Plano ultrapassado' ||
      rowData.status === 'Limite Operacoes Plano ultrapassado' ||
      rowData.status === 'Limite Importacao Plano ultrapassado' ||
      rowData.status === 'Nota Duplicada' ||
      rowData.status === 'Emissao Efetuado apos Periodo' ||
      rowData.status === 'Nota Posterior Data Referencia'
    ) {
      textClass = 'falha';
    } else {
      textClass = rowData.status ? rowData.status.toLowerCase() : '';
    }

    const isTeste =
      rowData.detalhe === 'Sucesso' && rowData.status === 'Processada';

    return (
      <Button
        value={rowData.status}
        className={`product-badge status-${textClass}`}
        onClick={!isTeste && abrirStatusDialog}
      >
        {' '}
        {textClass}
      </Button>
    );
  };

  const abrirStatusDialog = e => {
    setSelectedStatus(e.target.value);
    switch (e.target.value) {
      case 'Período de apuração excedido':
        setTextDialog(
          'Neste plano, somente é permitido cadastrar notas de corretagem do mês de apuração em aberto. A nota cadastrada ultrapassa esse limite, portanto ela não foi incluída no seu cálculo de imposto de renda.Para cadastrar notas de meses anteriores, contrate agora mesmo um dos nossos planos anuais!',
        );
        setTituloDialogStatus(`Detalhamento do status: ${e.target.value}`);
        setStatusDialog(true);
        break;
      case 'Nota Duplicada':
        setTituloDialogStatus('Falha: Nota duplicada');
        setTextDialog(
          `Você já importou essa nota anteriormente, portanto, essa versão não foi incluída no cálculo do imposto de renda. Por favor, faça a exclusão dela no Histórico de Notas.`,
        );
        setStatusDialogFalha(true);
        break;
      case 'Falha':
        setTituloDialogStatus(`Detalhamento do status: ${e.target.value}`);
        setTextDialog('Falha ao importar a nota!');
        setStatusDialogFalha(true);
        break;
      case 'Falha ao Processar Arquivo':
        setTituloDialogStatus(`Falha de leitura`);
        setTextDialog(
          'As informações desta nota não foram lidas corretamente, portanto, não foram incluídas no cálculo do imposto de renda. Por favor, entre em contato com o suporte pelo chat ou por e-mail para checar o motivo.',
        );
        setStatusDialogFalha(true);
        break;
      case 'Sucesso':
        setTextDialog(
          'Sua nota foi processada com sucesso e agora está constando no seu cálculo do imposto de renda.',
        );
        setTituloDialogStatus(`Detalhamento do status: ${e.target.value}`);
        setStatusDialog(true);
        break;
      case 'Limite Tipo Negócio Plano ultrapassado':
        setTextDialog(
          'Seu plano não possui permissão para efetuar o cálculo de imposto de renda em day trade. Por isso, essa nota não foi incluída em seu cálculo de imposto de renda. Para cadastrar quantas operações precisar, de swing trade ou day trade, faça o upgrade de plano agora mesmo! ',
        );
        setTituloDialogStatus(
          'Detalhamento do status: Day Trade não contemplado no plano',
        );
        setStatusDialog(true);
        break;
      case 'Limite Corretoras Plano ultrapassado':
        setTextDialog(
          'Você ultrapassou o limite de corretoras permitidas nesse plano e, por isso, essa nota não foi utilizada no cálculo de imposto de renda. Faça agora a contratação de um plano para cadastrar quantas corretoras quiser!',
        );
        setTituloDialogStatus(`Detalhamento do status: ${e.target.value}`);
        setStatusDialog(true);
        break;
      case 'Limite CPF Plano ultrapassado':
        setTextDialog(
          `Somente é permitido o cálculo de imposto de renda para o CPF ${Usuario.cpf} cadastrado. Por isso, essa nota não foi utilizada no cálculo.Para mais informações, entre em contato com o nosso suporte pelo chat ou pelo e-mail comunidade@bussoladoinvestidor.com.`,
        );
        setTituloDialogStatus(`Detalhamento do status: ${e.target.value}`);
        setStatusDialog(true);
        break;
      case 'Limite Operacoes Plano ultrapassado':
        setTextDialog(
          'Você ultrapassou a quantidade de operações permitidas nesse plano. Por isso, essa nota não foi utilizada no cálculo do imposto de renda. Para cadastrar outras operações, entre em contato com o nosso suporte pelo chat ou no e-mail comunidade@bussoladoinvestidor.com para conhecer os planos personalizados. ',
        );
        setTituloDialogStatus(`Detalhamento do status: ${e.target.value}`);
        setStatusDialog(true);
        break;
      case 'Limite Importacao Plano ultrapassado':
        setTextDialog(
          'Somente é permitido cadastrar notas de corretagem dentro do período contratado. A nota cadastrada ultrapassa o limite do seu plano, portanto ela não foi incluída no seu cálculo de imposto de renda. Para cadastrar notas de meses anteriores, contrate agora mesmo um dos nossos planos!',
        );
        setTituloDialogStatus(`Detalhamento do status: ${e.target.value}`);
        setStatusDialog(true);
        break;
      case 'Limite Tipo Nota Plano ultrapassado':
        setTextDialog(
          'Você ultrapassou os limites desse plano. Essa nota não foi utlizada no cálculo. Para utilizar essa nota no seu cálculo é necessário fazer a contratação de um plano que atenda os requisitos da nota.',
        );
        setTituloDialogStatus(`Detalhamento do status: ${e.target.value}`);
        setStatusDialog(true);
        break;
      case 'Emissao Efetuado apos Periodo':
        setTextDialog(
          'Somente é permitido processar notas com data de pregão posterior a última emissão darf efetuada.',
        );
        setTituloDialogStatus(`Detalhamento do status: ${e.target.value}`);
        setStatusDialog(true);
        break;
      case 'Nota Posterior Data Referencia':
        setTextDialog(
          'Somente é permitido processar notas com data de pregão posterior a data de referencia de suas Posições Iniciais.',
        );
        setTituloDialogStatus(`Detalhamento do status: ${e.target.value}`);
        setStatusDialog(true);
        break;
      default:
        setTituloDialogStatus('');
        setTextDialog('');
        setStatusDialog(false);
    }
  };

  const dropdownValues = [
    { name: 'Bovespa', code: '1' },
    { name: 'BMF', code: '2' },
  ];

  const inputNumeroDaNota = e => {
    setNumeroDaNota(e.value);
  };

  const limparCampos = () => {
    setDataInicio('');
    setDataPregao('');
    setCorretora('');
    setNumeroDaNota(null);
    setStatusDoProcesso('');
    setDropdownValue('');
  };

  function loadListStatusResultado(data) {
    const newListStatus = data.notasCorretagemItens.map(sp => {
      return {
        idNotaCorretagemItem:
          sp.idNotaCorretagemItem === null ||
          sp.idNotaCorretagemItem === undefined
            ? 0
            : sp.idNotaCorretagemItem,
        idNotaCorretagem:
          sp.idNotaCorretagem === null || sp.idNotaCorretagem === undefined
            ? 0
            : sp.idNotaCorretagem,
        ordenacao: sp.ordenacao,
        negociacaoMercado: sp.negociacaoMercado,
        compraVenda: sp.compraVenda,
        debitoCredito: sp.debitoCredito,
        tipoMercado:
          sp.idTipoMercadoNavigation.descricao === undefined ||
          sp.idTipoMercadoNavigation.descricao === null
            ? '-'
            : sp.idTipoMercadoNavigation.descricao,
        idTipoMercado: sp.idTipoMercado,
        prazo: sp.prazo,
        dataVencimento: sp.dataVencimento,
        tipoNegocio:
          sp.idTipoNegocioNavigation.descricao === null ||
          sp.idTipoNegocioNavigation.descricao === undefined
            ? '-'
            : sp.idTipoNegocioNavigation.descricao,
        observacao:
          sp.idTipoNegocioNavigation.descricao === null ||
          sp.idTipoNegocioNavigation.descricao === undefined
            ? '-'
            : sp.idTipoNegocioNavigation.descricao,
        mercadoriaTituloPapelCodigo: sp.mercadoriaTituloPapelCodigo,
        mercadoriaTituloPapelExtenso: sp.mercadoriaTituloPapelExtenso,
        quantidade: sp.quantidade,
        valorTaxaOperacional: sp.valorTaxaOperacional,
        valorOperacaoAjuste: RegraValorAjuste(
          sp.negociacaoMercado,
          sp.pontosOperacaoAjuste,
          sp.valorOperacaoAjuste,
        ),
        valorPrecoAjuste: RegraValorAjuste(
          sp.negociacaoMercado,
          sp.pontosPrecoAjuste,
          sp.valorPrecoAjuste,
        ),
      };
    });
    setListaStatusResultado(newListStatus);
  }

  function loadListStatusResultadoRodapeB3(data) {
    const newListStatus = data.notasCorretagemRodapeB3s.map(sp => {
      return {
        idNotaCorretagemRodapeB3: sp.idNotaCorretagemRodapeB3,
        irrfdaytradeBase: sp.irrfdaytradeBase,
        debentures: sp.debentures,
        vendasAvista: sp.vendasAvista,
        comprasAvista: sp.comprasAvista,
        opcoesCompras: sp.opcoesCompras,
        opcoesVendas: sp.opcoesVendas,
        operacoesTermo: sp.operacoesTermo,
        valorOperacoesTituloPublico: sp.valorOperacoesTituloPublico,
        valorOperacoes: sp.valorOperacoes,
        projecao: sp.projecao,
        totalCblcdc: sp.totalCblcdc,
        valorLiquidoOperacoes: sp.valorLiquidoOperacoes,
        valorLiquidoOperacoesDc: sp.valorLiquidoOperacoesDc,
        taxaRegistro: sp.taxaRegistro,
        totalCblc: sp.totalCblc,
        taxaLiquidacao: sp.taxaLiquidacao,
        taxaTermoOpcoesFuturos: sp.taxaTermoOpcoesFuturos,
        taxaAna: sp.taxaAna,
        emolumentos: sp.emolumentos,
        totalBovespaSoma: sp.totalBovespaSoma,
        execucaoCorretagem: sp.execucaoCorretagem,
        taxaOperacionalClearing: sp.taxaOperacionalClearing,
        impostosIss: sp.impostosIss,
        irrf: sp.irrf,
        outros: sp.outros,
        totalCustosDespesas: sp.totalCustosDespesas,
        liquido: sp.liquido,
        liquidoDc: sp.liquidoDc,
        liquidoData: sp.liquidoData,
      };
    });
    setListaStatusResultadoRodapeB3(newListStatus);

    if (newListStatus != 0) {
      setIrrfdayTrade(newListStatus[0].irrfdaytradeBase);
      setDebentures(newListStatus[0].debentures);
      setVendasAvista(newListStatus[0].vendasAvista);
      setComprasAvista(newListStatus[0].comprasAvista);
      setOpcoesCompras(newListStatus[0].opcoesCompras);
      setOpcoesVendas(newListStatus[0].opcoesVendas);
      setOperacoesTermo(newListStatus[0].operacoesTermo);
      setValorOperacoesTituloPublico(
        newListStatus[0].valorOperacoesTituloPublico,
      );
      setValorOperacoes(newListStatus[0].valorOperacoes);
      setProjecao(newListStatus[0].projecao);
      setTotalCblcdc(newListStatus[0].totalCblcdc);
      setValorLiquidoOperacoes(newListStatus[0].valorLiquidoOperacoes);
      setValorLiquidoOperacoesDc(newListStatus[0].valorLiquidoOperacoesDc);
      setTaxaRegistro(newListStatus[0].taxaRegistro);
      setTotalCblc(newListStatus[0].totalCblc);
      setTaxaLiquidacao(newListStatus[0].taxaLiquidacao);
      setTaxaTermoOpcoesFuturos(newListStatus[0].taxaTermoOpcoesFuturos);
      setTaxaAna(newListStatus[0].taxaAna);
      setEmolumentos(newListStatus[0].emolumentos);
      setTotalBovespaSoma(newListStatus[0].totalBovespaSoma);
      setExecucaoCorretagem(newListStatus[0].execucaoCorretagem);
      setTaxaOperacionalClearing(newListStatus[0].taxaOperacionalClearing);
      setImpostosIss(newListStatus[0].impostosIss);
      setIrrf(newListStatus[0].irrf);
      setOutros(newListStatus[0].outros);
      setTotalCustosDespesas(newListStatus[0].totalCustosDespesas);
      setLiquido(newListStatus[0].liquido);
      setLiquidoDc(newListStatus[0].liquidoDc);
      setLiquidoData(newListStatus[0].liquidoData);
    } else {
      setIrrfdayTrade('0,00');
      setDebentures('0,00');
      setVendasAvista('0,00');
      setComprasAvista('0,00');
      setOpcoesCompras('0,00');
      setOpcoesVendas('0,00');
      setOperacoesTermo('0,00');
      setValorOperacoesTituloPublico('0,00');
      setValorOperacoes('0,00');
      setProjecao('0,00');
      setTotalCblcdc('0,00');
      setValorLiquidoOperacoes('0,00');
      setValorLiquidoOperacoesDc('0,00');
      setTaxaRegistro('0,00');
      setTotalCblc('0,00');
      setTaxaLiquidacao('0,00');
      setTaxaTermoOpcoesFuturos('0,00');
      setTaxaAna('0,00');
      setEmolumentos('0,00');
      setTotalBovespaSoma('0,00');
      setExecucaoCorretagem('0,00');
      setImpostosIss('0,00');
      setIrrf('0,00');
      setOutros('0,00');
      setTotalCustosDespesas('0,00');
      setLiquido('0,00');
      setLiquidoDc('0,00');
      setLiquidoData('0,00');
    }
  }

  function loadListStatusResultadoRodapeBmf(data) {
    const newListStatus = data.notasCorretagemRodapeBmfs.map(sp => {
      return {
        idNotaCorretagemRodapeBmf: sp.idNotaCorretagemRodapeBmf,
        vendaDisponivel: sp.vendaDisponivel,
        compraDisponivel: sp.compraDisponivel,
        vendaOpcoes: sp.vendaOpcoes,
        compraOpcoes: sp.compraOpcoes,
        valorDosNegocios: sp.valorDosNegocios,
        valorDosNegociosDc: sp.valorDosNegociosDc,
        irrf: sp.irrf,
        irrfdayTrade: sp.irrfdayTrade,
        taxaOperacional: sp.taxaOperacional,
        taxaRegistroBmf: sp.taxaRegistroBmf,
        taxaBmf: sp.taxaBmf,
        outrosCustos: sp.outrosCustos,
        iss: sp.iss,
        ajusteDePosicao: sp.ajusteDePosicao,
        ajusteDePosicaoDc: sp.ajusteDePosicaoDc,
        ajusteDayTrade: sp.ajusteDayTrade,
        ajusteDayTradeDc: sp.ajusteDayTradeDc,
        totalDasDespesas: sp.totalDasDespesas,
        totalDasDespesasDc: sp.totalDasDespesasDc,
        outros: sp.outros,
        irrfcorretagem: sp.irrfcorretagem,
        totalContaInvestimento: sp.totalContaInvestimento,
        totalContaInvestimentoDc: sp.totalContaInvestimentoDc,
        totalContaNormal: sp.totalContaNormal,
        totalContaNormalDc: sp.totalContaNormalDc,
        totalLiquido: sp.totalLiquido,
        totalLiquidoDc: sp.totalLiquidoDc,
        totalLiquidoNota: sp.totalLiquidoNota,
        totalLiquidoNotaDc: sp.totalLiquidoNotaDc,
      };
    });
    setListaStatusResultadoRodapeBmf(newListStatus);

    if (newListStatus != 0) {
      setVendaDisponivel(newListStatus[0].vendaDisponivel);
      setCompraDisponivel(newListStatus[0].compraDisponivel);
      setVendaOpcoes(newListStatus[0].vendaOpcoes);
      setCompraOpcoes(newListStatus[0].compraOpcoes);
      setValorDosNegocios(newListStatus[0].valorDosNegocios);
      setValorDosNegociosDc(newListStatus[0].valorDosNegociosDc);
      setIrrfbmf(newListStatus[0].irrf);
      setIrrfdayTradebmf(newListStatus[0].irrfdayTrade);
      setTaxaOperacional(newListStatus[0].taxaOperacional);
      setTaxaRegistroBmf(newListStatus[0].taxaRegistroBmf);
      setTaxaBmf(newListStatus[0].taxaBmf);
      setOutrosCustos(newListStatus[0].outrosCustos);
      setIss(newListStatus[0].iss);
      setAjusteDePosicao(newListStatus[0].ajusteDePosicao);
      setAjusteDePosicaoDc(newListStatus[0].ajusteDePosicaoDc);
      setAjusteDayTrade(newListStatus[0].ajusteDayTrade);
      setAjusteDayTradeDc(newListStatus[0].ajusteDayTradeDc);
      setTotalDasDespesas(newListStatus[0].totalDasDespesas);
      setTotalDasDespesasDc(newListStatus[0].totalDasDespesasDc);
      setOutrosbmf(newListStatus[0].outros);
      setIrrfcorretagem(newListStatus[0].irrfcorretagem);
      setTotalContaInvestimento(newListStatus[0].totalContaInvestimento);
      setTotalContaInvestimentoDc(newListStatus[0].totalContaInvestimentoDc);
      setTotalContaNormal(newListStatus[0].totalContaNormal);
      setTotalContaNormalDc(newListStatus[0].totalContaNormalDc);
      setTotalLiquido(newListStatus[0].totalLiquido);
      setTotalLiquidoDc(newListStatus[0].totalLiquidoDc);
      setTotalLiquidoNota(newListStatus[0].totalLiquidoNota);
      setTotalLiquidoNotaDc(newListStatus[0].totalLiquidoNotaDc);
    } else {
      setVendaDisponivel('0,00');
      setCompraDisponivel('0,00');
      setVendaOpcoes('0,00');
      setCompraOpcoes('0,00');
      setValorDosNegocios('0,00');
      setValorDosNegociosDc('0,00');
      setIrrfbmf('0,00');
      setIrrfdayTradebmf('0,00');
      setTaxaOperacional('0,00');
      setTaxaRegistroBmf('0,00');
      setTaxaBmf('0,00');
      setOutrosCustos('0,00');
      setIss('0,00');
      setAjusteDePosicao('0,00');
      setAjusteDePosicaoDc('0,00');
      setAjusteDayTrade('0,00');
      setAjusteDayTradeDc('0,00');
      setTotalDasDespesas('0,00');
      setTotalDasDespesasDc('0,00');
      setOutrosbmf('0,00');
      setIrrfcorretagem('0,00');
      setTotalContaInvestimento('0,00');
      setTotalContaInvestimentoDc('0,00');
      setTotalContaNormal('0,00');
      setTotalContaNormalDc('0,00');
      setTotalLiquido('0,00');
      setTotalLiquidoDc('0,00');
      setTotalLiquidoNota('0,00');
      setTotalLiquidoNotaDc('0,00');
    }
  }

  async function operacoesNotaCorretagem(value) {
    //let idNotaCorretagem = rowData.idNotaCorretagem;
    //let idNotaCorretagem = value.rowData.idNotaCorretagem;
    let idNotaCorretagem = value.rowData
      ? value.rowData.idNotaCorretagem
      : value.idNotaCorretagem;
    if (
      value.idNotaCorretagem !== null &&
      value.idNotaCorretagem !== undefined &&
      value.idNotaCorretagem !== 0
    ) {
      fetch(
        `${process.env.REACT_APP_API_OPERACOES}/NotaCorretagem/v2/GetNotaCorretagem/${idNotaCorretagem}`,
      )
        .then(result => result.json())
        .then(data => {
          loadListStatusResultado(data);
          loadListStatusResultadoRodapeB3(data);
          loadListStatusResultadoRodapeBmf(data);
        })
        .catch(error => {
          console.error(error);

          showToast();
        });
    }
  }

  function alterarLinhaSelecionada(value) {
    setLinhaSelecionada(value);
    operacoesNotaCorretagem(value);
  }

  function alterarLinhaSelecionadaManual(value) {
    setLinhaSelecionadaManual(value);
  }

  const deletarDialogFooter = () => {
    return (
      <React.Fragment>
        <Button
          label="Não, voltar ao histórico de notas"
          className="p-button-outlined p-button-info p-mr-2 p-mb-2 botaoFechar"
          onClick={fecharDeletarDialog}
        />
        <Button
          label="Sim, excluir nota"
          className="p-button-severities p-button-primary p-mr-2 p-mb-2"
          onClick={deletarHistorico}
        />
      </React.Fragment>
    );
  };

  async function excluirNotaFalha() {
    let idNotaCorretagemArquivo = testeUpload.idUpload;
    let idusuario = Usuario.id;
    let idServico = 7;

    //let statusNota = linhaSelecionada.status;
    try {
      const response = await api.put(
        `${process.env.REACT_APP_API_STATUSPROCESSAMENTO}/StatusArquivo/v1/DesativaArquivoStatus/${idusuario}/${idServico}/${idNotaCorretagemArquivo}`,
        {},
      );
      showToast(DELETE_INVOICE_HISTORY_SUCCESS);
    } catch (error) {
      console.error(error);
      showToast(DELETE_INVOICE_HISTORY_ERROR);
    }

    async function createListStatus() {
      let idusuario = Usuario.id;
      fetch(
        `${process.env.REACT_APP_API_STATUSPROCESSAMENTO}/StatusNotaCorretagem/v1/RetornaStatusNotaCorretagemPorUploadUsuario/${idusuario}/7/0/9999`,
      )
        .then(result => result.json())
        .then(data => {
          loadListStatus(data);
        })
        .catch(error => {
          console.error(error);
          showToast();
        });
    }
    createListStatus();
    setDeletarDialog(false);
  }

  async function deletaStatusProcessamento() {
    const { idStatusProcessamento } = linhaSelecionada;

    try {
      await api.delete(
        `${process.env.REACT_APP_API_OPERACOES}/NotaCorretagem/v1/DeleteStatusProcessamento/${idStatusProcessamento}`,
        {},
      );
      showToast(DELETE_INVOICE_HISTORY_SUCCESS);
    } catch (error) {
      console.error(error);
      showToast(DELETE_INVOICE_HISTORY_ERROR);
    }

    async function createListStatus() {
      let idusuario = Usuario.id;
      fetch(
        `${process.env.REACT_APP_API_STATUSPROCESSAMENTO}/StatusNotaCorretagem/v1/RetornaStatusNotaCorretagemPorUploadUsuario/${idusuario}/7/0/9999`,
      )
        .then(result => result.json())
        .then(data => {
          loadListStatus(data);
        })
        .catch(error => {
          console.error(error);
          showToast();
        });
    }
    createListStatus();
    setDeletarDialog(false);
  }

  async function deletarHistorico() {
    const { idNotaCorretagem, status } = linhaSelecionada;

    if (status !== 'DARF Emitido') {
      try {
        const response = await api.delete(
          `${process.env.REACT_APP_API_OPERACOES}/NotaCorretagem/v1/DeleteNotaCorretagem/${idNotaCorretagem}`,
          {},
        );
        //setDeletarDialog("false");
        showToast(DELETE_INVOICE_HISTORY_SUCCESS);
      } catch (error) {
        console.error(error);
        showToast(DELETE_INVOICE_HISTORY_ERROR);
      }

      //setDeletarDialog(false);

      async function createListStatus() {
        let idusuario = Usuario.id;
        fetch(
          `${process.env.REACT_APP_API_STATUSPROCESSAMENTO}/StatusNotaCorretagem/v2/RetornaStatusNotaCorretagemPorUploadUsuario/${idusuario}/7/0/9999`,
        )
          .then(result => result.json())
          .then(data => {
            loadListStatus(data);
          })
          .catch(error => {
            console.error(error);
            showToast();
          });
      }
      createListStatus();
      setDeletarDialog(false);
    }
  }

  useEffect(() => {
    if (retornoStatus === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Email enviado.',
        life: 8000,
      });
    }
  }, [retornoStatus]);

  useEffect(() => {
    if (retornoStatusErro !== 200 && retornoStatusErro !== '') {
      toast.current.show({
        severity: 'error',
        summary: 'Erro!',
        detail: 'Erro ao enviar o email.',
        life: 8000,
      });
    }
  }, [retornoStatusErro]);

  const informarInconsistencia = async () => {
    if (!informarInconsistenciaFileRef.current?.files) {
      showToast(NO_ATTACHED_FILES);
      return;
    } else if (!mensagemInconsistencia) {
      showToast(EMPTY_MESSAGE);
      return;
    }

    let loginUsuario = usuario.username;
    let emailusuario = usuario.email;
    let planoUsuario = planoUser;
    let nomeArquivo = linhaSelecionada.arquivoNovo;
    let tipoCadastro = linhaSelecionada.detalhe;
    let corretora = linhaSelecionada.corretora;
    let numeroDanota = linhaSelecionada.idNotaCorretagem;
    let tipoNota = linhaSelecionada.idTipoNota;
    let statusNota = linhaSelecionada.status;
    let mensagemDoUsuario = mensagemInconsistencia;
    let dataPregao = linhaSelecionada.pregao;
    let dataCadastroNota = linhaSelecionada.data;
    let dateNow = new Date().toLocaleString('sv-SE');

    var datai = dataPregao;
    var ano = datai.split('').slice(6, 10).join('');
    var mes = datai.split('').slice(3, 5).join('');
    var dia = datai.split('').slice(0, 2).join('');
    var dataPregaoFormatada = ano + '-' + mes + '-' + dia;

    var datai2 = dataCadastroNota;
    var ano = datai2.split('').slice(6, 10).join('');
    var mes = datai2.split('').slice(3, 5).join('');
    var dia = datai2.split('').slice(0, 2).join('');
    var dataCadastroNotaFormatada = ano + '-' + mes + '-' + dia;

    try {
      const formData = new FormData();

      const obj = {
        loginUsuario: loginUsuario,
        emailusuario: emailusuario,
        planoUsuario: planoUsuario,
        nomeArquivo: nomeArquivo,
        tipoCadastro: tipoCadastro,
        corretora: corretora,
        numeroDanota: numeroDanota,
        tipoNota: tipoNota,
        status: statusNota,
        mensagemDoUsuario: mensagemDoUsuario,
        dataPregao: dataPregaoFormatada,
        dataCadastroNota: dataCadastroNotaFormatada,
        DataHoraInconcistencia: dateNow,
      };

      const myJSON = JSON.stringify(obj);
      formData.append('jsonData', myJSON);

      informarInconsistenciaFileRef.current.files.forEach((file, index) =>
        formData.append(`file-${index}`, file),
      );

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      if (statusNota === 'Falha ao Processar Arquivo') {
        axios
          .post(
            `${process.env.REACT_APP_API_USUARIOS}/EnvioEmailSistemico/v1/PostEmailInconcistenciaGenerico`,
            formData,
            config,
          )
          .then(response => {
            setRetornoStatus(response.status);
            setRetornoStatusErro(response.status);
          });

        setInconsistenciaDialog(false);
        setRetornoStatus('');
        setRetornoStatusErro('');
        setMensagemInconsistencia('');

        informarInconsistenciaFileRef.current.clear();
        showToast(SEND_EMAIL_SUCCESS);
      } else {
        axios
          .post(
            `${process.env.REACT_APP_API_USUARIOS}/EnvioEmailSistemico/v1/PostEmailInconcistencia`,
            formData,
            config,
          )
          .then(response => {
            setRetornoStatus(response.status);
            setRetornoStatusErro(response.status);
          });

        setInconsistenciaDialog(false);
        setRetornoStatus('');
        setRetornoStatusErro('');
        setMensagemInconsistencia('');
        informarInconsistenciaFileRef.current.clear();
        showToast(SEND_EMAIL_SUCCESS);
      }
    } catch (error) {
      console.error(error);
      setInconsistenciaDialog(false);
      setRetornoStatus('');
      setRetornoStatusErro('');
      setMensagemInconsistencia('');
      informarInconsistenciaFileRef.current.clear();
      showToast(SEND_EMAIL_ERROR);
    }
  };

  function abrirDetalhes() {
    if (linhaSelecionada.detalhe === 'Manual') {
      setDetalhes(true);
      setDisplayMaximizable2(true);
    } else {
      setDetalhes(true);
      setDisplayMaximizable(true);
    }
  }

  function excluirNotasAtivas() {
    const { status, notaAtiva, detalhe } = linhaSelecionada;

    if (notaAtiva === true) {
      if (status !== 'DARF Emitido') {
        setDeletarDialog(true);
      } else {
        setDeletarDialog2(true);
      }
    } else if (podeExcluir === true) {
      toast.current.show({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Não é possível deletar uma nota com status pendente.',
        life: 8000,
      });
    } else if (status === 'Falha ao Processar Arquivo') {
      excluirNotaFalha();
    } else if (detalhe === 'Erro ao processar Arquivo') {
      deletaStatusProcessamento();
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Nota já foi apagada anteriormente.',
        life: 8000,
      });
    }
  }

  let overlayMenuItems = [
    {
      label: 'Detalhes',
      command: () => {
        abrirDetalhes();
      },
    },
    {
      label: 'Excluir',
      command: () => {
        excluirNotasAtivas();
      },
    },
    // {
    //   label: 'Informar inconsistência',
    //   command: () => {
    //     setInconsistenciaDialog(true);
    //   },
    // },
  ];

  const hideDialog = () => {
    setInconsistenciaDialog(false);
  };

  const fecharDeletarDialog = () => {
    setDeletarDialog(false);
  };

  const fecharDeletarDialog2 = () => {
    setDeletarDialog2(false);
  };

  const inconsistenciaDialogFooter = (
    <>
      <Button
        label="Cancelar"
        className="p-button-outlined p-button-info p-mr-2 p-mb-2 botaoFechar"
        onClick={hideDialog}
      />
      {/* <Button
        label="Informar Inconsistência"
        className="p-button-severities p-button-primary p-mr-2 p-mb-2"
        onClick={() => setInconsistenciaDialog(true)}
      /> */}
    </>
  );

  const deletarDialogFooter2 = (
    <>
      <Button
        label="Fechar"
        className="p-button-severities p-button-primary p-mr-2 p-mb-2 botaoFechar"
        onClick={fecharDeletarDialog2}
      />
    </>
  );

  async function filtroLista() {
    let idusuario = Usuario.id;
    let idServico = 7;

    let dataCad = new Date(dataInicio).toLocaleString('sv-SE').substr(0, 10);
    let dataPregao2 = new Date(dataPregao)
      .toLocaleString('sv-SE')
      .substr(0, 10);

    let arrayStatus = [];

    if (STATUS.has(statusDoProcesso?.code)) {
      arrayStatus = STATUS.get(statusDoProcesso?.code);
    }

    const response = await api.post(
      `${process.env.REACT_APP_API_STATUSPROCESSAMENTO}/StatusNotaCorretagem/v3/RetornaStatusNotaCorretagemPorFiltro`,
      {
        idUsuario: idusuario,
        idServico: idServico,
        numeroNotaCorretora: numeroDaNota ? numeroDaNota : 0,
        dataCadastro: dataInicio ? dataCad : '',
        corretora: corretora ? corretora.code : 0,
        dataPregao: dataPregao ? dataPregao2 : '',
        tipoNota: dropdownValue ? dropdownValue.code : 0,
        status: arrayStatus,
      },
    );
    setListaStatus([]);
    loadListStatusFiltro(response.data);
  }

  function loadListStatusFiltro(data) {
    const newListStatus = data.statusList.map(sp => {
      return {
        idStatusProcessamento: sp.idStatusProcessamento,
        idNotaCorretagem:
          sp.nota === null || sp.nota === undefined
            ? 0
            : sp.nota.idNotaCorretagem,
        data: sp.data,
        arquivoNovo:
          sp.nota === null || sp.nota === undefined
            ? '--'
            : sp.nota.idNotaCorretagemArquivoNavigation === null ||
              sp.nota.idNotaCorretagemArquivoNavigation === undefined ||
              sp.nota.idNotaCorretagemArquivoNavigation === ''
            ? ''
            : sp.nota.idNotaCorretagemArquivoNavigation.arquivoOriginal,
        detalhe: sp.detalhe,
        corretora:
          sp.nota === null || sp.nota === undefined
            ? ''
            : sp.nota.idCorretoraNavigation.apelido,
        ndanota:
          sp.nota === null || sp.nota === undefined
            ? ''
            : sp.nota.numeroNotaCorretora,
        //pregao: sp.nota.dataPregao,
        //pregao: sp.nota.dataPregao ? sp.nota.dataPregao : "",
        //pregao: sp.nota.dataPregao === null || sp.nota.dataPregao === undefined ? "" : sp.nota.dataPregao,
        pregao:
          sp.nota === null || sp.nota === undefined ? '' : sp.nota.dataPregao,
        idTipoNota:
          sp.nota === null || sp.nota === undefined
            ? ''
            : sp.nota.idTipoNotaNavigation.nome,
        status: sp.status,
        cpf: sp.nota === null || sp.nota === undefined ? '' : sp.nota.cpfNota,
        cnpjNota:
          sp.nota === null || sp.nota === undefined ? '' : sp.nota.cnpjNota,
        ativo: sp.ativo,
        notaAtiva:
          sp.nota === null || sp.nota === undefined ? 1 : sp.nota.notaAtiva,
      };
    });
    setListaStatus(newListStatus);
    setDetalhes(false);
    setDisplayMaximizable(false);
    setDisplayMaximizable2(false);
    setListaStatusResultadoRodapeB3([]);
    setListaStatusResultadoRodapeBmf([]);
  }

  const textEditorEspecificacaoTitulo = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
        style={{ width: '90px' }}
      />
    );
  };

  const textEditorEspecificacaoTituloBovespa = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
        style={{ width: '90px' }}
      />
    );
  };

  const textEditorValorOpAjuste = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        mode="decimal"
        minFractionDigits={2}
        inputId="locale-user"
        onChange={e => options.editorCallback(e.target.value)}
        style={{ width: '100px' }}
      />
    );
  };

  const textEditorValorOpAjusteBovespa = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        mode="decimal"
        minFractionDigits={2}
        inputId="locale-user"
        onChange={e => options.editorCallback(e.target.value)}
        style={{ width: '100px' }}
      />
    );
  };

  const textEditorPrecoAjuste = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        mode="decimal"
        minFractionDigits={2}
        inputId="locale-user"
        onChange={e => options.editorCallback(e.target.value)}
        style={{ width: '100px' }}
      />
    );
  };

  const textEditorPrecoAjusteBovespa = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        mode="decimal"
        minFractionDigits={2}
        inputId="locale-user"
        onChange={e => options.editorCallback(e.target.value)}
        style={{ width: '100px' }}
      />
    );
  };

  const textEditorVencimento = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
        style={{ width: '90px' }}
      />
    );
  };

  const textEditorQtd = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
        style={{ width: '100px' }}
      />
    );
  };

  const textEditorQtdBovespa = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
        style={{ width: '100px' }}
      />
    );
  };

  const textEditorCompraVenda = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
        style={{ width: '30px' }}
      />
    );
  };

  const textEditorCompraVendaBovespa = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
        style={{ width: '30px' }}
      />
    );
  };

  const textEditorDebitoCredito = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
        style={{ width: '30px' }}
      />
    );
  };

  const textEditorDebitoCreditoBovespa = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
        style={{ width: '30px' }}
      />
    );
  };

  const textEditorTipoNegocio = () => {
    return (
      <Dropdown
        id="tipoNegocio"
        value={tipoNegocio}
        onChange={e => setTipoNegocio(e.value)}
        options={listaTipoNegocio}
        optionLabel="name"
        filter
        placeholder="Selecione o Tipo Mercado"
      ></Dropdown>
    );
  };

  const textEditorTipoNegocioBovespa = () => {
    return (
      <Dropdown
        id="tipoNegocio"
        value={tipoNegocio}
        onChange={e => setTipoNegocio(e.value)}
        options={listaTipoNegocio}
        optionLabel="name"
        filter
        placeholder="Tipo Negócio"
        style={{ width: '189px' }}
      ></Dropdown>
    );
  };

  const textEditorTipoMercado = () => {
    return (
      <Dropdown
        id="tipoMercado"
        value={tipoMercado}
        onChange={e => setTipoMercado(e.value)}
        options={listaTipoMercado}
        optionLabel="name"
        filter
        placeholder="Tipo Mercado"
        style={{ width: '190px' }}
      ></Dropdown>
    );
  };

  const textEditorTaxaOperacional = () => {
    return (
      <InputNumber
        id="valorLiquido"
        required
        value={valorLiquido}
        mode="decimal"
        minFractionDigits={2}
        inputId="locale-user"
        onChange={e => setValorLiquido(e.value)}
      />
    );
  };

  const textEditorPrazo = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
        style={{ width: '70px' }}
      />
    );
  };

  async function onRowEditComplete1(options) {
    let compraVenda = options.newData.compraVenda;
    let mercadoriaTituloPapelCodigo =
      options.newData.mercadoriaTituloPapelCodigo;
    let id = options.data.idNotaCorretagemItem;
    let idNotaCorretagem = options.data.idNotaCorretagem;
    let valorOperacaoAjuste = options.newData.valorOperacaoAjuste.replace(
      ',',
      '.',
    );
    let valorPrecoAjuste = options.newData.valorPrecoAjuste.replace(',', '.');
    let quantidade = options.newData.quantidade;
    let prazo = options.data.prazo;
    let ordenacao = options.data.ordenacao;
    let debitoCredito = options.newData.debitoCredito;
    let mercadoriaTituloPapelExtenso =
      options.data.mercadoriaTituloPapelExtenso;
    let negociacaoMercado = options.data.negociacaoMercado;
    let nomeTipoMercado = options.data.tipoMercado;
    let numeroTipoMercado = options.data.idTipoMercado;
    let nomeTipoNegocio = tipoNegocio.name;
    let numeroTipoNegocio = tipoNegocio.code;

    try {
      await api.put(
        `${process.env.REACT_APP_API_OPERACOES}/NotasCorretagemItens/${id}`,
        {
          idNotaCorretagemItem: id,
          idNotaCorretagem: idNotaCorretagem,
          prazo: prazo,
          ordenacao: ordenacao,
          compraVenda: compraVenda,
          debitoCredito: debitoCredito,
          idTipoMercado: numeroTipoMercado,
          tipoMercado: nomeTipoMercado,
          idTipoNegocio: numeroTipoNegocio,
          tipoNegocio: nomeTipoNegocio,
          negociacaoMercado: negociacaoMercado,
          mercadoriaTituloPapelCodigo: mercadoriaTituloPapelCodigo,
          valorOperacaoAjuste: valorOperacaoAjuste,
          valorPrecoAjuste: valorPrecoAjuste,
          quantidade: quantidade,
          mercadoriaTituloPapelExtenso: mercadoriaTituloPapelExtenso,
        },
      );

      showToast(EDIT_INVOICE_SUCCESS);
      setDisplayMaximizable2(false);
    } catch (error) {
      console.error(error);
      showToast(EDIT_INVOICE_ERROR);
      setDisplayMaximizable2(false);
    }
  }

  async function onRowEditComplete2(options) {
    let compraVenda = options.newData.compraVenda;
    let mercadoriaTituloPapelCodigo =
      options.newData.mercadoriaTituloPapelCodigo;
    let id = options.data.idNotaCorretagemItem;
    let idNotaCorretagem = options.data.idNotaCorretagem;
    let valorOperacaoAjuste = options.newData.valorOperacaoAjuste.replace(
      ',',
      '.',
    );
    let valorPrecoAjuste = options.newData.valorPrecoAjuste.replace(',', '.');
    let quantidade = options.newData.quantidade;
    let prazo = options.newData.prazo;
    let ordenacao = options.data.ordenacao;
    let debitoCredito = options.newData.debitoCredito;
    let mercadoriaTituloPapelExtenso =
      options.data.mercadoriaTituloPapelExtenso;
    let negociacaoMercado = options.data.negociacaoMercado;
    let nomeTipoMercado = tipoMercado.name;
    let numeroTipoMercado = tipoMercado.code;
    let nomeTipoNegocio = tipoNegocio.name;
    let numeroTipoNegocio = tipoNegocio.code;

    try {
      await api.put(
        `${process.env.REACT_APP_API_OPERACOES}/NotasCorretagemItens/${id}`,
        {
          idNotaCorretagemItem: id,
          idNotaCorretagem: idNotaCorretagem,
          prazo: prazo,
          ordenacao: ordenacao,
          compraVenda: compraVenda,
          debitoCredito: debitoCredito,
          idTipoMercado: numeroTipoMercado,
          tipoMercado: nomeTipoMercado,
          idTipoNegocio: numeroTipoNegocio,
          tipoNegocio: nomeTipoNegocio,
          negociacaoMercado: negociacaoMercado,
          mercadoriaTituloPapelCodigo: mercadoriaTituloPapelCodigo,
          valorOperacaoAjuste: valorOperacaoAjuste,
          valorPrecoAjuste: valorPrecoAjuste,
          quantidade: quantidade,
          mercadoriaTituloPapelExtenso: mercadoriaTituloPapelExtenso,
        },
      );

      showToast(EDIT_INVOICE_SUCCESS);
      setDisplayMaximizable2(false);
    } catch (error) {
      console.error(error);
      showToast(EDIT_INVOICE_ERROR);
      setDisplayMaximizable2(false);
    }
  }

  return (
    <div className="p-grid datatable-style-demo">
      <div className="p-col-12 p-md-12">
        <div className="card">
          <div className="p-col-12 p-md-12">
            <h2 className="titulo">
              <strong>Histórico de Notas</strong>
              <a
                href={CENTRAL_AJUDA_HISTORICO_NOTAS_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  icon="pi pi-question-circle"
                  style={{ top: '9px' }}
                  className="p-button-rounded p-button-text colorIcon"
                  tooltip="Histórico de notas"
                />
              </a>
            </h2>

            <br />
            <p className="subtituloHistorico">
              Consulte suas notas de corretagem e acompanhe o status do
              processamento de cada uma.
            </p>
            <br />

            <ContainerFiltro
              onLeftButtonClick={limparCampos}
              onRightButtonClick={filtroLista}
            >
              <div>
                <label htmlFor="dataInicio">Data do cadastro</label>
                <Calendar
                  name="dataInicio"
                  id="dataInicio"
                  required
                  showIcon
                  showButtonBar
                  value={dataInicio}
                  onChange={e => setDataInicio(e.value)}
                  locale="br"
                  dateFormat="dd/mm/yy"
                  placeholder="Data do cadastro"
                ></Calendar>
              </div>
              <div>
                <label htmlFor="numeroDaNota">N° da nota</label>
                <InputNumber
                  id="numeroDaNota"
                  type="text"
                  placeholder="N° da nota"
                  value={numeroDaNota}
                  onChange={inputNumeroDaNota}
                  useGrouping={false}
                ></InputNumber>
              </div>
              <div>
                <label htmlFor="corretora">Corretora</label>
                <Dropdown
                  id="corretora"
                  value={corretora}
                  onChange={e => setCorretora(e.value)}
                  options={listaCorretoras}
                  optionLabel="name"
                  filter
                  placeholder="Selecione a corretora"
                ></Dropdown>
              </div>
              <div>
                <label htmlFor="dataPregao">Data do pregão</label>
                <Calendar
                  name="dataPregao"
                  id="dataPregao"
                  required
                  showIcon
                  showButtonBar
                  value={dataPregao}
                  onChange={e => setDataPregao(e.value)}
                  locale="br"
                  dateFormat="dd/mm/yy"
                  placeholder="Data do pregão"
                ></Calendar>
              </div>
              <div>
                <label htmlFor="tipoDeNota">Tipo de nota</label>
                <Dropdown
                  id="tipoDeNota"
                  filter
                  value={dropdownValue}
                  onChange={e => setDropdownValue(e.value)}
                  options={dropdownValues}
                  optionLabel="name"
                  placeholder="Tipo de nota"
                />
              </div>
              <div>
                <label htmlFor="statusDoProcesso">Status</label>
                <Dropdown
                  id="statusDoProcesso"
                  value={statusDoProcesso}
                  onChange={e => setStatusDoProcesso(e.value)}
                  options={listaStatusDoProcesso}
                  optionLabel="name"
                  filter
                  placeholder="Selecione o tipo de Status"
                ></Dropdown>
              </div>
            </ContainerFiltro>

            <br />
            <div className="p-grid table-demo">
              <div className="p-col-12">
                <Toast ref={toast} />
                <DataTable
                  value={listaStatus}
                  responsiveLayout="scroll"
                  dataKey="id"
                  emptyMessage="Lista Vazia."
                  paginator
                  paginatorTemplate={template2}
                  first={first1}
                  rows={rows1}
                  onPage={onCustomPage1}
                  rowHover
                  selectionMode="single"
                  onSelectionChange={e => alterarLinhaSelecionada(e.value)}
                  cellSelection
                  style={{
                    border: '2px solid rgba(83, 195, 194, 0.42)',
                    boxSizing: 'border-box',
                    padding: '0px',
                    borderRadius: '5px',
                  }}
                  sortField="data"
                  sortOrder={-1}
                >
                  <Column
                    field="data"
                    header="Cadastro"
                    sortable
                    dataType="date"
                    className="cabecalhoHistorico"
                  ></Column>
                  <Column
                    field="detalhe"
                    header="Importação"
                    sortable
                    className="cabecalhoHistorico"
                  ></Column>
                  <Column
                    field="corretora"
                    header="Corretora"
                    sortable
                    className="cabecalhoHistorico"
                  ></Column>
                  <Column
                    field="ndanota"
                    header="Nº da Nota"
                    sortable
                    className="cabecalhoHistorico"
                  ></Column>
                  <Column
                    field="pregao"
                    header="Pregão"
                    sortable
                    dataType="date"
                    className="cabecalhoHistorico"
                  ></Column>
                  <Column
                    field="idTipoNota"
                    header="Tipo de nota"
                    sortable
                    className="cabecalhoHistorico"
                  ></Column>
                  <Column
                    field="status"
                    header="Status"
                    sortable
                    body={statusTemplate}
                    className="cabecalhoHistorico"
                  ></Column>
                  <Column body={teste} className="cabecalhoHistorico"></Column>
                </DataTable>
              </div>
            </div>
            <br />

            <Dialog
              visible={displayMaximizable}
              maximized
              maximizable
              modal
              style={{ width: '85%' }}
              onHide={() => onHide('displayMaximizable')}
            >
              <S.ContainerDialog
                style={{ display: detalhes ? 'block' : 'none' }}
              >
                <br />
                <div>
                  <h1 className="subtituloModal tituloModal">
                    Nota de corretagem
                  </h1>
                  <p className="subtituloModal">
                    Neste relatório, é possível visualizar as informações
                    detalhadas da sua nota de corretagem. Caso encontre alguma
                    incoerência, feche esta janela e selecione a opção “Informar
                    inconsistência”.
                  </p>
                </div>
                <br />
                <div className="p-col-12 p-md-12 div1Modal">
                  <S.Box100 className="cabecalho">
                    <div>
                      <p className="heading">
                        <strong>CPF</strong>
                      </p>
                      <p className="heading">
                        <strong>Data do pregão</strong>
                      </p>
                      <p className="heading">
                        <strong>Corretora</strong>
                      </p>
                      <p className="heading">
                        <strong>Tipo de nota</strong>
                      </p>
                      <p className="heading">
                        <strong>Número da nota</strong>
                      </p>
                      <p>&nbsp;</p>
                    </div>
                    <div>
                      <p>
                        <label>
                          {linhaSelecionada ? linhaSelecionada.cpf : ''}
                        </label>
                      </p>
                      <p>
                        <label>
                          {linhaSelecionada ? linhaSelecionada.pregao : ''}
                        </label>
                      </p>
                      <p>
                        <label>
                          {linhaSelecionada ? linhaSelecionada.corretora : ''}
                        </label>
                      </p>
                      <p>
                        <label>
                          {linhaSelecionada ? linhaSelecionada.idTipoNota : ''}
                        </label>
                      </p>
                      <p>
                        <label>
                          {linhaSelecionada ? linhaSelecionada.ndanota : ''}
                        </label>
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </S.Box100>
                </div>

                <div
                  className="p-col-12 p-md-12 div1Modal"
                  style={{
                    display:
                      linhaSelecionada.idTipoNota == 'Bovespa'
                        ? 'block'
                        : 'none',
                  }}
                >
                  <DataTable
                    value={listaStatusResultado}
                    responsiveLayout="scroll"
                    dataKey="id"
                    emptyMessage="Lista Vazia."
                    paginator
                    paginatorTemplate={template3}
                    first={first2}
                    rows={rows2}
                    onPage={onCustomPage2}
                    rowHover
                    selection={listaStatusResultado}
                    className="tabela2"
                  >
                    <Column
                      field="compraVenda"
                      header="C/V"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="tipoMercado"
                      header="Tipo de Mercado"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="prazo"
                      header="Prazo"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="mercadoriaTituloPapelExtenso"
                      header="Espec. de titulo"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="observacao"
                      header="Observação"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="quantidade"
                      header="Qtde"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="valorPrecoAjuste"
                      header="Preço/Ajuste"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="valorOperacaoAjuste"
                      header="Vlr op./ajuste"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="debitoCredito"
                      header="D/C"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                  </DataTable>
                </div>

                <div
                  className="p-col-12 p-md-12 div1Modal"
                  style={{
                    display:
                      linhaSelecionada.idTipoNota == 'BM&F' ? 'block' : 'none',
                  }}
                >
                  <DataTable
                    value={listaStatusResultado}
                    responsiveLayout="scroll"
                    dataKey="id"
                    emptyMessage="Lista Vazia."
                    paginator
                    paginatorTemplate={template3}
                    first={first2}
                    rows={rows2}
                    onPage={onCustomPage2}
                    rowHover
                    selection={listaStatusResultado}
                    className="tabela2"
                  >
                    <Column
                      field="compraVenda"
                      header="C/V"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="mercadoriaTituloPapelExtenso"
                      header="Mercadoria"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="dataVencimento"
                      header="Vencimento"
                      dataType="date"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="quantidade"
                      header="Qtde"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="valorPrecoAjuste"
                      header="Preço/Ajuste"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="tipoNegocio"
                      header="Tipo do Negócio"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="valorOperacaoAjuste"
                      header="Vlr op./ajuste"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="debitoCredito"
                      header="D/C"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                    <Column
                      field="valorTaxaOperacional"
                      header="Taxa Operacional"
                      sortable
                      className="cabecalhoHistoricoModal"
                    ></Column>
                  </DataTable>
                </div>

                <div
                  className="p-col-12 p-md-12 div1Modal"
                  style={{
                    display:
                      linhaSelecionada.idTipoNota == 'Bovespa'
                        ? 'block'
                        : 'none',
                  }}
                >
                  <S.Box50>
                    <div className="colunasResumoDosNegocios">
                      <div className="headerResumoNegocios">
                        <strong>Resumo dos Negócios</strong>
                      </div>
                      <table>
                        <tr>
                          <td>Debêntures</td>
                          <td className="debentures">{debentures}</td>
                        </tr>
                        <tr>
                          <td>Vendas à vista</td>
                          <td className="debentures">{vendasAvista}</td>
                        </tr>
                        <tr>
                          <td>Compras à vista</td>
                          <td className="debentures">{comprasAvista}</td>
                        </tr>
                        <tr>
                          <td>Opção - compras</td>
                          <td className="debentures">{opcoesCompras}</td>
                        </tr>
                        <tr>
                          <td>Opção - vendas</td>
                          <td className="debentures">{opcoesVendas}</td>
                        </tr>
                        <tr>
                          <td>Operações de termo</td>
                          <td className="debentures">{operacoesTermo}</td>
                        </tr>
                        <tr>
                          <td>Valor das oper. c/ títulos públ. (v. nom.)</td>
                          <td className="debentures">
                            {valorOperacoesTituloPublico}
                          </td>
                        </tr>
                        <tr>
                          <td>Valor das operações</td>
                          <td className="debentures">{valorOperacoes}</td>
                        </tr>
                        <tr>
                          <td>IRRF daytrade base</td>
                          <td className="debentures">{irrfdayTrade}</td>
                        </tr>
                        <tr>
                          <td>Projeção</td>
                          <td className="debentures">{projecao}</td>
                        </tr>
                      </table>
                    </div>
                    <div className="colunasResumoDosNegocios">
                      <div className="headerResumoNegocios">
                        <strong>Resumo Financeiro</strong>
                      </div>
                      <table>
                        <tr>
                          <td>CBLC</td>
                          <td></td>
                          <td className="colunaUm">{totalCblcdc}</td>
                        </tr>
                        <tr>
                          <td>Valor líquido das operações</td>
                          <td className="colunaDois">
                            {valorLiquidoOperacoes}
                          </td>
                          <td className="colunaTres">
                            {valorLiquidoOperacoesDc}
                          </td>
                        </tr>
                        <tr>
                          <td>Taxa de liquidação*</td>
                          <td className="colunaDois">{taxaLiquidacao}</td>
                          <td className="colunaTres">D</td>
                        </tr>
                        <tr>
                          <td>Taxa de registro</td>
                          <td className="colunaDois">{taxaRegistro}</td>
                          <td className="colunaTres">D</td>
                        </tr>
                      </table>

                      <S.Box100 className="testeCblc totalCblc">
                        <table>
                          <tr>
                            <td>Total CBLC</td>
                            <td className="table2colunaDois">{totalCblc}</td>
                            <td className="table2colunaTres">D</td>
                          </tr>
                        </table>
                      </S.Box100>

                      <table>
                        <tr>
                          <td>Bovespa/soma</td>
                        </tr>
                        <tr>
                          <td>Taxa de termo/opções</td>
                          <td className="table3colunaDois">
                            {taxaTermoOpcoesFuturos}
                          </td>
                          <td className="table2colunaTres">D</td>
                        </tr>
                        <tr>
                          <td>Taxa A.N.A.</td>
                          <td className="table3colunaDois">{taxaAna}</td>
                          <td className="table2colunaTres">D</td>
                        </tr>
                        <tr>
                          <td>Emolumentos</td>
                          <td className="table3colunaDois">{emolumentos}</td>
                          <td className="table2colunaTres">D</td>
                        </tr>
                      </table>

                      <S.Box100 className="testeCblc totalCblc">
                        <table>
                          <tr>
                            <td>Total Bovespa/soma</td>
                            <td className="table4colunaDois">
                              {totalBovespaSoma}
                            </td>
                            <td className="table2colunaTres">D</td>
                          </tr>
                        </table>
                      </S.Box100>
                      <table>
                        <tr>
                          <td>Corretagem/despesas</td>
                        </tr>
                        <tr>
                          <td>Taxa Operacional</td>
                          <td className="table5colunaDois">
                            {taxaOperacionalClearing}
                          </td>
                          <td className="table2colunaTres">D</td>
                        </tr>
                        <tr>
                          <td>Impostos (ISS)</td>
                          <td className="table5colunaDois">{impostosIss}</td>
                          <td className="table2colunaTres">D</td>
                        </tr>
                        <tr>
                          <td>I.R.R.F s/ operações, base R$ 0,00</td>
                          <td className="table5colunaDois">{irrf}</td>
                        </tr>
                        <tr>
                          <td>Despesas/outras</td>
                          <td className="table5colunaDois">{outros}</td>
                          <td className="table2colunaTres">D</td>
                        </tr>
                      </table>

                      <S.Box100 className="testeCblc totalCblc">
                        <table>
                          <tr>
                            <td>Total corretagem/despesa</td>
                            <td className="table6colunaDois">
                              {totalCustosDespesas}
                            </td>
                            <td className="table2colunaTres">D</td>
                          </tr>
                        </table>
                      </S.Box100>

                      <S.Box100 className="testeCblc totalCblc">
                        <table>
                          <tr>
                            <td>Líquido para* </td>
                            <td className="table7colunaDois">{liquidoData}</td>
                            <td className="table8colunaDois">{liquido}</td>
                            <td className="table2colunaTres">{liquidoDc}</td>
                          </tr>
                        </table>
                      </S.Box100>
                    </div>
                  </S.Box50>
                </div>

                <div
                  className="p-col-12 p-md-12"
                  style={{
                    display:
                      linhaSelecionada.idTipoNota == 'BM&F' ? 'block' : 'none',
                  }}
                >
                  <S.OperacoesBfmContainer
                    style={{
                      border: '2px solid rgba(83, 195, 194, 0.42)',
                      boxSizing: 'border-box',
                      padding: '10px',
                    }}
                    className="p-col-12 p-mb-12 p-lg-12 p-mb-lg-12"
                  >
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                        <div className="p-col-12 p-md-12">
                          <S.Box100 className="div1Bmf">
                            <div className="div1Bmf">
                              <p className="heading labelRodapeBmf">
                                Venda disponível
                              </p>
                              <p className="heading labelRodapeBmf">
                                Compra disponível
                              </p>
                              <p className="heading labelRodapeBmf">
                                Venda opções
                              </p>
                              <p className="heading labelRodapeBmf">
                                Compra opções
                              </p>
                              <p className="heading labelRodapeBmf">
                                Valor dos negócios
                              </p>
                              <p className="heading">&nbsp;</p>
                            </div>
                            <div className="div2Bmf">
                              <p>
                                <label>{vendaDisponivel}</label>
                              </p>
                              <p>
                                <label>{compraDisponivel}</label>
                              </p>
                              <p>
                                <label>{vendaOpcoes}</label>
                              </p>
                              <p>
                                <label>{compraOpcoes}</label>
                              </p>
                              <p>
                                <label>
                                  {valorDosNegocios}&nbsp;{valorDosNegociosDc}
                                </label>
                              </p>
                              <p>&nbsp;</p>
                            </div>
                            <div className="div1Bmf">
                              <p className="heading labelRodapeBmf">IRRF</p>
                              <p className="heading labelRodapeBmf">
                                IRRF daytrade (projeção)
                              </p>
                              <p className="heading labelRodapeBmf">
                                Taxa operacional
                              </p>
                              <p className="heading labelRodapeBmf">
                                Taxa registro
                              </p>
                              <p className="heading labelRodapeBmf">
                                Taxas BM&F (emol+f.gar)
                              </p>
                              <p>&nbsp;</p>
                            </div>
                            <div className="div2Bmf">
                              <p>
                                <label>{irrfbmf}</label>
                              </p>
                              <p>
                                <label>{irrfdayTradebmf}</label>
                              </p>
                              <p>
                                <label>{taxaOperacional}</label>
                              </p>
                              <p>
                                <label>{taxaRegistroBmf}</label>
                              </p>
                              <p>
                                <label>{taxaBmf}</label>
                              </p>
                              <p>&nbsp;</p>
                            </div>
                            <div className="div1Bmf">
                              <p>&nbsp;</p>
                              <p className="heading">
                                <span className="labelRodapeBmf2">
                                  + outros
                                </span>
                                <span>Imposto (ISS)</span>
                              </p>
                              <p className="heading labelRodapeBmf">
                                Ajuste de posição
                              </p>
                              <p className="heading labelRodapeBmf">
                                Ajuste daytrade
                              </p>
                              <p className="heading labelRodapeBmf">
                                Total das despesas
                              </p>
                              <p>&nbsp;</p>
                            </div>
                            <div className="div2Bmf">
                              <p>&nbsp;</p>
                              <p>
                                <label className="labelRodapeBmf3">
                                  {outrosCustos}
                                </label>
                                <label>{iss}</label>
                              </p>
                              <p>
                                <label>
                                  {ajusteDePosicao}&nbsp;{ajusteDePosicaoDc}
                                </label>
                              </p>
                              <p>
                                <label>
                                  {ajusteDayTrade}&nbsp;{ajusteDayTradeDc}
                                </label>
                              </p>
                              <p>
                                <label>
                                  {totalDasDespesas}&nbsp;{totalDasDespesasDc}
                                </label>
                              </p>
                              <p>&nbsp;</p>
                            </div>
                            <div className="div1Bmf">
                              <p className="heading labelRodapeBmf">Outros</p>
                              <p className="heading labelRodapeBmf">
                                IRRF corretagem
                              </p>
                              <p className="heading labelRodapeBmf">
                                Total conta investimento
                              </p>
                              <p className="heading labelRodapeBmf">
                                Total c. normal
                              </p>
                              <p className="heading labelRodapeBmf">
                                Total líquido
                              </p>
                              <p className="heading labelRodapeBmf">
                                Total líq. da nota
                              </p>
                              <p>&nbsp;</p>
                            </div>
                            <div className="div2Bmf">
                              <p>
                                <label>{outrosbmf}</label>
                              </p>
                              <p>
                                <label>{irrfcorretagem}</label>
                              </p>
                              <p>
                                <label>
                                  {totalContaInvestimento}&nbsp;
                                  {totalContaInvestimentoDc}
                                </label>
                              </p>
                              <p>
                                <label>
                                  {totalContaNormal}&nbsp;{totalContaNormalDc}
                                </label>
                              </p>
                              <p>
                                <label>
                                  {totalLiquido}&nbsp;{totalLiquidoDc}
                                </label>
                              </p>
                              <p>
                                <label>
                                  {totalLiquidoNota}&nbsp;{totalLiquidoNotaDc}
                                </label>
                              </p>
                              <p>&nbsp;</p>
                            </div>
                          </S.Box100>
                        </div>
                      </div>
                    </div>
                  </S.OperacoesBfmContainer>
                </div>
              </S.ContainerDialog>
            </Dialog>
            <br />
          </div>
          <Dialog
            visible={inconsistenciaDialog}
            style={{ width: 'min(40rem, 80vw)' }}
            header="Algo não está certo?"
            blockScroll={false}
            className="p-fluid"
            footer={inconsistenciaDialogFooter}
            onHide={hideDialog}
            modal
          >
            <div className="p-formgrid p-grid">
              <div className="p-field p-col">
                <p>
                  Caso tenha percebido alguma inconsistência em sua nota de
                  corretagem, informe o ocorrido para nossa equipe de suporte.
                  Você também pode enviar uma imagem, caso seja necessário.
                </p>

                <h3>Conte-nos o ocorrido:</h3>
                <InputTextarea
                  value={mensagemInconsistencia}
                  onChange={e => setMensagemInconsistencia(e.target.value)}
                  rows={5}
                  cols={10}
                  autoResize
                />
                <FileUpload
                  chooseLabel="Anexar"
                  name="invoice"
                  accept="image/*"
                  ref={informarInconsistenciaFileRef}
                  customUpload={true}
                  mode="basic"
                  multiple
                />
              </div>
            </div>
          </Dialog>
          <Dialog
            visible={deletarDialog}
            style={{
              width: 'min(40rem, 80vw)',
            }}
            header="Tem certeza que deseja excluir esta nota?"
            blockScroll={false}
            className="p-fluid"
            footer={deletarDialogFooter}
            onHide={fecharDeletarDialog}
            modal
          >
            <div className="p-formgrid p-grid">
              <div className="p-field p-col">
                <hr />
                <br />
                <br />
                <p>
                  Você está prestes a excluir permanentemente uma nota de
                  corretagem. Essa ação excluirá também todos os dados contidos
                  nela, as operações vinculadas em conta e no cálculo, e pode
                  alterar valores da plataforma. Você tem certeza que deseja
                  excluí-la?
                </p>
              </div>
            </div>
            <br />
            <hr />
          </Dialog>

          <Dialog
            visible={deletarDialog2}
            style={{ width: '45vw' }}
            header="Excluir nota:"
            blockScroll={false}
            className="p-fluid"
            footer={deletarDialogFooter2}
            onHide={fecharDeletarDialog2}
            modal
          >
            <div className="p-formgrid p-grid">
              <div className="p-field p-col">
                <hr />
                <br />
                <br />
                <p>
                  Esta nota não pode ser excluída, pois o imposto respectivo ao
                  período destas operações já foi consolidado. Entre em contato
                  com nossa equipe caso necessite de ajuda.
                </p>
              </div>
            </div>
            <br />
            <hr />
          </Dialog>

          <Dialog
            visible={displayMaximizable2}
            maximized
            maximizable
            modal
            style={{ width: '85%' }}
            onHide={() => onHide2('displayMaximizable2')}
          >
            <div
              className="p-col-1 p-md-12"
              style={{ display: detalhes ? 'block' : 'none' }}
            >
              <br />
              <p className="subtituloModal">Editar Operações manuais.</p>
              <br />
              <div className="p-col-12 p-md-12 div1Modal">
                <S.Box100>
                  <div>
                    <p className="heading">
                      <strong>CPF</strong>
                    </p>
                    <p className="heading">
                      <strong>Data do pregão</strong>
                    </p>
                    <p className="heading">
                      <strong>Corretora</strong>
                    </p>
                    <p className="heading">
                      <strong>Tipo de nota</strong>
                    </p>
                    <p className="heading">
                      <strong>Número da nota</strong>
                    </p>
                    <p>&nbsp;</p>
                  </div>
                  <div>
                    <p>
                      <label>
                        {linhaSelecionada ? linhaSelecionada.cpf : ''}
                      </label>
                    </p>
                    <p>
                      <label>
                        {linhaSelecionada
                          ? new Date(linhaSelecionada.pregao)
                              .toLocaleString('pt-BR')
                              .substr(0, 10)
                          : ''}
                      </label>
                    </p>
                    <p>
                      <label>
                        {linhaSelecionada ? linhaSelecionada.corretora : ''}
                      </label>
                    </p>
                    <p>
                      <label>
                        {linhaSelecionada ? linhaSelecionada.idTipoNota : ''}
                      </label>
                    </p>
                    <p>
                      <label>
                        {linhaSelecionada ? linhaSelecionada.ndanota : ''}
                      </label>
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </S.Box100>
              </div>

              <div
                className="p-col-12 p-md-12 div1Modal"
                style={{
                  display:
                    linhaSelecionada.idTipoNota == 'Bovespa' ? 'block' : 'none',
                }}
              >
                <DataTable
                  value={listaStatusResultado}
                  responsiveLayout="scroll"
                  dataKey="id"
                  emptyMessage="Lista Vazia."
                  paginator
                  paginatorTemplate={template3}
                  first={first2}
                  rows={rows2}
                  onPage={onCustomPage2}
                  rowHover
                  selection={listaStatusResultado}
                  className="tabela2"
                  editMode="row"
                  onRowEditComplete={onRowEditComplete2}
                  selectionMode="single"
                  onSelectionChange={e =>
                    alterarLinhaSelecionadaManual(e.value)
                  }
                  cellSelection
                >
                  <Column
                    field="compraVenda"
                    header="C/V"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorCompraVendaBovespa(options)}
                  ></Column>
                  <Column
                    field="tipoMercado"
                    header="Tipo de Mercado"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorTipoMercado(options)}
                  ></Column>
                  <Column
                    field="prazo"
                    header="Prazo"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorPrazo(options)}
                  ></Column>
                  <Column
                    field="mercadoriaTituloPapelExtenso"
                    header="Espec. de titulo"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options =>
                      textEditorEspecificacaoTituloBovespa(options)
                    }
                  ></Column>
                  <Column
                    field="tipoNegocio"
                    header="Observação"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorTipoNegocioBovespa(options)}
                  ></Column>
                  <Column
                    field="quantidade"
                    header="Qtde"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorQtdBovespa(options)}
                  ></Column>
                  <Column
                    field="valorPrecoAjuste"
                    header="Preço/Ajuste"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorPrecoAjusteBovespa(options)}
                  ></Column>
                  <Column
                    field="valorOperacaoAjuste"
                    header="Vlr op./ajuste"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorValorOpAjusteBovespa(options)}
                  ></Column>
                  <Column
                    field="debitoCredito"
                    header="D/C"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorDebitoCreditoBovespa(options)}
                  ></Column>
                  <Column
                    rowEditor
                    headerStyle={{ width: '10%', minWidth: '8rem' }}
                    bodyStyle={{ textAlign: 'center' }}
                  ></Column>
                </DataTable>
              </div>

              <div
                className="p-col-12 p-md-12 div1Modal"
                style={{
                  display:
                    linhaSelecionada.idTipoNota == 'BM&F' ? 'block' : 'none',
                }}
              >
                <DataTable
                  value={listaStatusResultado}
                  responsiveLayout="scroll"
                  dataKey="id"
                  emptyMessage="Lista Vazia."
                  paginator
                  paginatorTemplate={template3}
                  first={first2}
                  rows={rows2}
                  onPage={onCustomPage2}
                  rowHover
                  selection={listaStatusResultado}
                  className="tabela2"
                  editMode="row"
                  onRowEditComplete={onRowEditComplete1}
                  selectionMode="single"
                  onSelectionChange={e =>
                    alterarLinhaSelecionadaManual(e.value)
                  }
                  cellSelection
                >
                  <Column
                    field="compraVenda"
                    header="C/V"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorCompraVenda(options)}
                  ></Column>
                  <Column
                    field="mercadoriaTituloPapelExtenso"
                    header="Espec titulo"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorEspecificacaoTitulo(options)}
                  ></Column>
                  <Column
                    field="dataVencimento"
                    header="Vencimento"
                    dataType="date"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorVencimento(options)}
                  ></Column>
                  <Column
                    field="quantidade"
                    header="Qtde"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorQtd(options)}
                  ></Column>
                  <Column
                    field="valorPrecoAjuste"
                    header="Preço/Ajuste"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorPrecoAjuste(options)}
                  ></Column>
                  <Column
                    field="tipoNegocio"
                    header="Tipo do Negócio"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorTipoNegocio(options)}
                  ></Column>
                  <Column
                    field="valorOperacaoAjuste"
                    header="Vlr op./ajuste"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorValorOpAjuste(options)}
                  ></Column>
                  <Column
                    field="debitoCredito"
                    header="D/C"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorDebitoCredito(options)}
                  ></Column>
                  <Column
                    field="valorTaxaOperacional"
                    header="Taxa Operacional"
                    sortable
                    className="cabecalhoHistoricoModal"
                    editor={options => textEditorTaxaOperacional(options)}
                  ></Column>
                  <Column
                    rowEditor
                    headerStyle={{ width: '10%', minWidth: '8rem' }}
                    bodyStyle={{ textAlign: 'center' }}
                  ></Column>
                </DataTable>
              </div>
            </div>
          </Dialog>
          <Dialog
            visible={statusDialog}
            style={{
              maxWidth: '600px',
              margin: '1rem',
            }}
            header={tituloDialogStatus}
            blockScroll={false}
            className="p-fluid"
            footer={fechaDialogFooter}
            onHide={fecharStatusDialog}
            modal
          >
            <div className="p-formgrid p-grid">
              <div className="p-field p-col">
                <hr />
                <br />
                <br />
                <p>{textDialog}</p>
              </div>
            </div>
            <br />
            <hr />
          </Dialog>
          <Dialog
            visible={statusDialogFalha}
            style={{
              maxWidth: '800px',
              margin: '2rem',
            }}
            header={tituloDialogStatus}
            blockScroll={false}
            className="p-fluid"
            footer={fechaDialogFooterFalha}
            onHide={fecharStatusDialogFalha}
            modal
          >
            <div className="p-formgrid p-grid">
              <div className="p-field p-col">
                <hr />
                <br />
                <br />
                <p>{textDialog}</p>
              </div>
            </div>
            <br />
            <hr />
          </Dialog>
        </div>
      </div>
    </div>
  );
};
