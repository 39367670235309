import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { devices, pxToRem } from '../../../../utils/functions/styles';

interface NotaDialogContainerProps {
  children: ReactNode;
}

export const NotaDialogContainer = styled(Dialog)<NotaDialogContainerProps>`
  .desktop {
    display: none;

    @media (min-width: 426px) {
      display: flex;
    }
  }

  .mobile {
    display: none;

    @media ${devices.mobileL} {
      display: block;
    }
  }

  h2 {
    font-weight: 500 !important;
    line-height: ${pxToRem(37, 14)} !important;
    color: #000 !important;

    @media ${devices.mobileL} {
      font-size: ${pxToRem(20, 14)} !important;
    }
  }

  div > p.paragraph {
    font-weight: 300 !important;
    color: #000;
    font-size: ${pxToRem(18, 14)} !important;
    line-height: ${pxToRem(27, 14)} !important;

    @media ${devices.mobileL} {
      font-size: ${pxToRem(15, 14)} !important;
    }
  }

  main {
    margin-top: ${pxToRem(60, 14)};

    section:first-of-type table {
      width: 100%;
      border-collapse: collapse !important;
      border: 1px solid #53c3c26b;
      margin-bottom: 2rem;

      @media ${devices.mobileL} {
        display: flex;
        justify-content: space-between;
      }

      th,
      td {
        padding: 0.8rem;
      }

      thead {
        background-color: rgba(150, 183, 199, 0.1);
        border-bottom: 1px solid #53c3c26b;

        @media ${devices.mobileL} {
          background-color: transparent;
        }

        tr {
          @media ${devices.mobileL} {
            display: flex;
            flex-direction: column;
          }

          th {
            color: #112c50;
            font-weight: 400;
          }
        }
      }

      tbody tr {
        @media ${devices.mobileL} {
          display: flex;
          flex-direction: column;
        }

        td {
          text-align: center;
          color: #112c50a3;
          font-weight: 400;
        }
      }
    }
  }
`;

interface DataTableContainerProps {
  children: ReactNode;
}

export const DataTableContainer = styled(DataTable)<DataTableContainerProps>`
  th {
    color: #112c50 !important;
  }

  td {
    color: #112c50a3 !important;
  }
`;
