import { Dialog } from 'primereact/dialog';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)`
  max-width: 500px;
`;

export const Content = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.42);
  padding: 16px;
  margin: 24px 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #112c50;
  }

  & > div {
    margin-top: 24px;
  }

  & > div > div {
    margin-top: 24px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: flex-end;

  & > :first-child {
    background-color: #fff;
    color: #112c50;
  }
`;
