import styled from 'styled-components';

import { devices } from '../../../../utils/functions/styles';

export const Container = styled.div`
  margin: 3rem 0;

  main {
    margin: 5rem 0 2rem;

    > section.container-card {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem;
      width: 100%;

      @media ${devices.tablet} {
        grid-template-columns: 1fr;
      }

      div.plan-info {
        p {
          display: inline-block;
          margin-bottom: 3rem;
        }

        div.container-buttons {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 2rem;

          > button {
            width: 100%;
          }
        }
      }
    }

    > section.container-table {
      margin-top: 3rem;

      > div p {
        margin-bottom: 3rem;
      }
    }
  }
`;

export const SimpleButton = styled.button`
  width: '100%';
  border: 0;
  background: none;
  cursor: pointer;
  color: #112c50;
  font-weight: 500;
`;

export const DialogFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.6rem;
  margin-top: 2rem;
`;
