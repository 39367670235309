import styled from 'styled-components';

interface DelayRealtimeContainerProps {
  variant: 'delay' | 'realtime';
}

export const DelayRealtimeContainer = styled.div<DelayRealtimeContainerProps>`
  display: flex;
  gap: 0.3rem;
  align-items: center;
  font-size: 0.8rem;
  color: ${({ variant }) => (variant === 'delay' ? '#FF9247' : 'green')};

  > div {
    background-color: ${({ variant }) =>
      variant === 'delay' ? '#FF9247' : 'green'};
    border-radius: 9999px;
    width: 0.8rem;
    height: 0.8rem;

    animation-name: changeColor;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  @keyframes changeColor {
    0% {
      opacity: 0.3;
    }
    25% {
      opacity: 0.5;
    }
    75% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
`;
