import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';

import { CustomTag } from '../../../../components/CustomTag';
import Usuario from '../../../../components/Usuario';
import { useToast } from '../../../../context/toast/useToast';
import { RowExpensiveTable } from '../../components/RowExpensiveTable';
import {
  Container,
  DialogDarfIssuanceDataContainer,
  DialogFooter,
} from './styles';

export function EmissaoDarf2() {
  const [usuario, setUsuario] = useState({});
  const [linhasExpandidas, setLinhasExpandidas] = useState(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenDialog2, setIsOpenDialog2] = useState(false);
  const [values, setValues] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedYear, setSelectedYear] = useState(null);
  const [years, setYears] = useState([]);
  const [darfValue, setDarfValue] = useState({});

  const { showToast } = useToast();

  useEffect(() => {
    listaAnos();
    listaDarfs();
    setUsuario(Usuario);
  }, []);

  useEffect(() => {
    listaDarfs();
  }, [year]);

  async function listaAnos() {
    let idUsuario = 10;

    fetch(
      `http://bdi.api.net.relatorios.bussola.internal/v1/GetAnosDeOperacaoPorUsuario/${idUsuario}`,
    )
      .then(response => response.json())
      .then(data => {
        let newYears = [];

        data.dados.forEach(dado => {
          let newYear = {
            name: dado.toString(),
            code: dado,
          };

          newYears.push(newYear);
        });
        let novoano = {
          name: '2022',
          code: 2022,
        };

        const anoAtual = newYears.filter(e => e.name === novoano.name);

        if (
          anoAtual.name === null &&
          anoAtual.name === undefined &&
          anoAtual.name === ''
        ) {
          newYears.push(novoano);
        }

        setYears(newYears);
      })
      .catch(error => {
        console.error(error);
        showToast();
      });
  }

  async function listaDarfs() {
    //let IdUsuario = Usuario.id;

    let IdUsuario = 10;

    let newValues = [];

    fetch(
      `http://bdi.api.net.relatorios.bussola.internal/v1/GetRelatorioEmissaoDarfPorUsuario/${IdUsuario}/${year}`,
    )
      .then(result => result.json())
      .then(data => {
        data.dados.forEach(dado => {
          let value = {
            id: dado.mes,
            ano: dado.ano,
            mes: dado.mesDesc,
            status: dado.status,
            resultado: dado.resultadoTotal,
            prejuizo_a_compensar: dado.prejuizoCompensarTotal,
            alienacoes: dado.alienacoesTotal,
            base_calculo: dado.baseCalculoTotal,
            imposto_devido: dado.impostoDevidoTotal,
            irrf: dado.irrfAcumuladoTotal,
            darf: dado.darfAcumuladoTotal,
            imposto_a_pagar: dado.impostoPagarTotal,
            dataVencimento: dado.dataVencimento,
            detalhes: [
              {
                label: 'Op. Comuns',
                resultado: dado.resultadoSwing,
                prejuizo_a_compensar: dado.prejuizoCompensarSwing,
                alienacoes: dado.alienacoesSwing,
                base_calculo: dado.baseCalculoSwing,
                imposto_devido: dado.impostoDevidoSwing,
                irrf: dado.irrfAcumuladoSwing,
                darf: dado.darfAcumuladoSwing,
                imposto_a_pagar: dado.impostoPagarSwing,
              },
              {
                label: 'Day Trade',
                resultado: dado.resultadoDayTrade,
                prejuizo_a_compensar: dado.prejuizoCompensarDayTrade,
                alienacoes: dado.alienacoesDayTrade,
                base_calculo: dado.baseCalculoDayTrade,
                imposto_devido: dado.impostoDevidoDayTrade,
                irrf: dado.irrfAcumuladoDayTrade,
                darf: dado.darfAcumuladoDayTrade,
                imposto_a_pagar: dado.impostoPagarDayTrade,
              },
              {
                label: 'F. Inv. Imob.',
                resultado: dado.resultadoFundos,
                prejuizo_a_compensar: dado.prejuizoCompensarFundos,
                alienacoes: dado.alienacoesFundos,
                base_calculo: dado.baseCalculoFundos,
                imposto_devido: dado.impostoDevidoFundos,
                irrf: dado.irrfAcumuladoFundos,
                darf: dado.darfAcumuladoFundos,
                imposto_a_pagar: dado.impostoPagarFundos,
              },
            ],
          };

          newValues.push(value);
        });

        setValues(newValues);
      })
      .catch(error => {
        console.error(error);
        showToast();
      });
  }

  const expandirTudo = () => {
    let _linhasExpandidas = {};
    values.forEach(value => (_linhasExpandidas[`${value.id}`] = true));

    setLinhasExpandidas(_linhasExpandidas);
  };

  const recolherTudo = () => {
    setLinhasExpandidas(null);
  };

  const expandirOuRecolherTudo = () => {
    !linhasExpandidas ? expandirTudo() : recolherTudo();
  };

  const lidarComEmissaoDarf = id => {
    var newDarfValue = values.find(v => v.id === id);

    setDarfValue(newDarfValue);
    setIsOpenDialog(true);
  };

  const lidarComEmissaoDarf2 = id => {
    var newDarfValue = values.find(v => v.id === id);

    setDarfValue(newDarfValue);
    setIsOpenDialog2(true);
  };

  const statusTemplate = rowData => {
    return (
      <span
        className={`status-${
          rowData.status ? rowData.status.toLowerCase() : ''
        }`}
      >
        {rowData.status}
      </span>
    );
  };

  function showModal(rowData) {
    console.log(rowData);
    if (new Date(rowData.dataVencimento) <= new Date()) {
      lidarComEmissaoDarf(rowData.id);
    } else {
      lidarComEmissaoDarf2(rowData.id);
    }
  }

  const botaoEmisaoDarf = rowData => {
    debugger;
    return (
      <CustomTag
        status={rowData.status}
        mostrarBotaoEmissao
        acaoDoBotao={() => showModal(rowData)}
      />
    );
  };

  function onChangeHandler(e) {
    setSelectedYear(e.value);
    setYear(e.value.code);
  }

  return (
    <>
      <Card>
        <h1>Emissão de DARF</h1>

        <p>
          Na tabela abaixo você pode acompanhar o resultado da apuração mensal
          dos seus impostos. Ao clicar no ícone de porcentagem do lado direito
          aparecerá uma caixa de mensagem, onde será gerado uma nova guia de
          imposto e o mês selecionado não poderá sofrer mais alterações.
        </p>

        <Container>
          <div className="p-d-flex">
            <div className="p-flex-column p-d-flex">
              <Dropdown
                value={selectedYear}
                options={years}
                onChange={e => onChangeHandler(e)}
                optionLabel="name"
                placeholder="Selecione o ano"
              />
            </div>
          </div>

          <Button onClick={expandirOuRecolherTudo}>Expandir/Recolher</Button>
        </Container>

        <section>
          <DataTable
            value={values}
            responsiveLayout="scroll"
            emptyMessage="Lista vazia."
            rowExpansionTemplate={data => (
              <RowExpensiveTable
                data={data.detalhes}
                columns={[
                  { field: 'label' },
                  { field: 'resultado', header: 'Resultado' },
                  {
                    field: 'prejuizo_a_compensar',
                    header: 'Prejuízo à compensar',
                  },
                  { field: 'alienacoes', header: 'Alienações' },
                  { field: 'base_calculo', header: 'Base de cálculo' },
                  { field: 'imposto_devido', header: 'Imposto devido' },
                  { field: 'irrf', header: 'IRRF acumulado' },
                  { field: 'darf', header: 'DARF acumulado' },
                  { field: 'imposto_a_pagar', header: 'Imposto a pagar' },
                ]}
              />
            )}
            expandedRows={linhasExpandidas}
            onRowToggle={e => setLinhasExpandidas(e.data)}
            dataKey="id"
          >
            <Column expander />
            <Column field="mes" header="Mês" />
            <Column field="resultado" header="Resultado" />
            <Column
              field="prejuizo_a_compensar"
              header="Prejuízo à compensar"
            />
            <Column field="alienacoes" header="Alienações" />
            <Column field="base_calculo" header="Base de cálculo" />
            <Column field="imposto_devido" header="Imposto devido" />
            <Column field="irrf" header="IRRF acumulado" />
            <Column field="darf" header="DARF acumulado" />
            <Column field="imposto_a_pagar" header="Imposto a pagar" />
            <Column field="status" header="Status" body={statusTemplate} />
            <Column
              field=""
              header=""
              body={botaoEmisaoDarf}
              selectionMode="single"
            />
          </DataTable>
        </section>
      </Card>

      <Dialog
        header="Fechar apuração e emitir DARF"
        visible={isOpenDialog}
        onHide={() => setIsOpenDialog(false)}
        style={{ width: '50rem' }}
      >
        <p>
          @Cliente, ao emitir seu DARF não será possível adicionar outras
          operações neste mês.
        </p>

        <DialogDarfIssuanceDataContainer>
          <ul>
            <li>
              <span>Mês de apuração do DARF: </span>
              <strong>
                {darfValue.mes}/{darfValue.ano}
              </strong>
            </li>
            <li>
              <span>Nome: </span>
              <strong>{Usuario.nome}</strong>
            </li>
            <li>
              <span>CPF: </span>
              <strong>{Usuario.cpf}</strong>
            </li>
            <li>
              <span>Telefone: </span>
              <strong>{Usuario.telefone}</strong>
            </li>
            <li>
              <span>Vencimento: </span>
              <strong>30/10/2021</strong>
            </li>
            <li>
              <span>Valor do imposto: </span>
              <strong>{darfValue.imposto_devido}</strong>
            </li>
          </ul>

          <p>
            Seu imposto está com recolhimento atrasado e não foi informado
            pagamento. Para gerar um DARF e pagá-lo, escolha uma data para a
            consolidação dos encargos.
          </p>

          <div className="p-d-flex">
            <div className="p-flex-column p-d-flex">
              <label htmlFor="dataCon">Data da consolidação</label>
              <Calendar id="dataCon" showIcon />
            </div>
          </div>
        </DialogDarfIssuanceDataContainer>

        <DialogFooter>
          <Button
            label="Cancelar"
            className="p-button-text"
            onClick={() => setIsOpenDialog(false)}
          />
          <Button label="Emitir DARF" />
        </DialogFooter>
      </Dialog>

      <Dialog
        header="Fechar apuração e emitir DARF"
        visible={isOpenDialog2}
        onHide={() => setIsOpenDialog2(false)}
        style={{ width: '50rem' }}
      >
        <p>
          {Usuario.nome}, ao emitir seu DARF não será possível adicionar outras
          operações neste mês.
        </p>

        <DialogDarfIssuanceDataContainer>
          <ul>
            <li>
              <span>Mês de apuração do DARF: </span>
              <strong>
                {darfValue.mes}/{darfValue.ano}
              </strong>
            </li>
            <li>
              <span>Nome: </span>
              <strong>{Usuario.nome}</strong>
            </li>
            <li>
              <span>CPF: </span>
              <strong>{Usuario.cpf}</strong>
            </li>
            <li>
              <span>Telefone: </span>
              <strong>{Usuario.telefone}</strong>
            </li>
            <li>
              <span>Vencimento: </span>
              <strong>30/10/2021</strong>
            </li>
            <li>
              <span>Valor do imposto: </span>
              <strong>{darfValue.imposto_devido}</strong>
            </li>
          </ul>
        </DialogDarfIssuanceDataContainer>

        <DialogFooter>
          <Button
            label="Cancelar"
            className="p-button-text"
            onClick={() => setIsOpenDialog2(false)}
          />
          <Button label="Emitir DARF" />
        </DialogFooter>
      </Dialog>
    </>
  );
}
