import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  div.field {
    display: flex;
    flex-direction: column;

    textarea {
      width: 100%;
      resize: none;
    }

    div.upload-button {
      display: flex;
    }
  }
`;

export const Footer = styled.footer`
  margin-top: 3rem;

  display: flex;
  justify-content: flex-end;
`;
