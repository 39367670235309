import { Skeleton } from 'primereact/skeleton';

import { GreenBorderBox } from '../../../../../styles/elements/GreenBorderBox';
import * as S from './styles';

export function SkeletonFiltro() {
  return (
    <GreenBorderBox>
      <S.Container>
        <div>
          <Skeleton width={'80px'} height={'16px'} />
          <Skeleton width={'100%'} height={'32px'} />
        </div>
        <div>
          <Skeleton width={'80px'} height={'16px'} />
          <Skeleton width={'100%'} height={'32px'} />
        </div>
      </S.Container>
    </GreenBorderBox>
  );
}
