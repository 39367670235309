export const STATUS_TYPES = [
  { label: 'Aceito', value: 'Aceito' },
  { label: 'Pendente', value: 'Pendente' },
  { label: 'Negado', value: 'Negado' },
];

export const EVENT_TYPE = [
  { label: 'Desdobramento', value: 1 },
  { label: 'Grupamento', value: 2 },
  { label: 'Bonificação', value: 6 },
];
