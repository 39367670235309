import * as S from './styles';

interface DelayRealtimeProps {
  variant?: 'delay' | 'realtime';
}

export function DelayRealtime({ variant = 'delay' }: DelayRealtimeProps) {
  return (
    <S.DelayRealtimeContainer variant={variant}>
      <div />
      {variant === 'delay' ? 'Com atraso' : 'Tempo real'}
    </S.DelayRealtimeContainer>
  );
}
