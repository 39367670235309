import Base64 from 'crypto-js/enc-base64';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import 'moment/min/moment-with-locales.min';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import 'smarttchart/scripts/utils';

import { useSubscriptions } from '../../context/subscriptions/SubscriptionsContext';
import { CENTRAL_AJUDA_GRAFICO_LINK } from '../../utils/linksCentralAjuda';
import { DelayRealtime } from '../DelayRealtime';
import Usuario from '../Usuario';
import * as S from './styles';

const ORIGIN = process.env.REACT_APP_MARKET_DATA_ORIGIN;
const SIGNING_KEY = process.env.REACT_APP_MARKET_DATA_PASSWORD;
const GRAFICO_TEMPO_REAL = ['anual_ir_avancado', 'degustacao'];

export const GraficoB3 = () => {
  const [delayOrRealtime, setDelayOrRealtime] = useState('delay');
  const { getCurrentSubscription } = useSubscriptions();

  useEffect(() => {
    const currentSubscription = getCurrentSubscription();

    const expiration_time = new Date().setDate(new Date().getDate() + 1);
    const signing_string = `${Usuario.username};${delayOrRealtime};${ORIGIN};${expiration_time}`;
    const auth_token = Base64.stringify(
      hmacSHA256(signing_string, SIGNING_KEY),
    );

    const credentials = {
      username: Usuario.username,
      data_type: delayOrRealtime,
      origin: ORIGIN,
      expiration_date: expiration_time,
      token: auth_token,
    };

    if (
      currentSubscription !== 'NOT_FOUND' &&
      GRAFICO_TEMPO_REAL.includes(currentSubscription.plano.billingPlanCode)
    ) {
      setDelayOrRealtime('realtime');
    }

    async function getMarketDataAuth(credentialsFn) {
      credentialsFn(credentials);
    }

    window.SBDatafeed.Static.dataURL =
      'https://mdstreamer.bussoladoinvestidor.com.br';
    window.SBDatafeed.Static.streamURL =
      'wss://mdstreamer.bussoladoinvestidor.com.br/rtdata';

    getMarketDataAuth(credentials => {
      window.TVWidgetStrategies = window.createChart(
        credentials,
        {
          fullscreen: false,
          autosize: true,
          symbol: 'PETR4',
          interval: '15',
          container_id: 'chart',
          library_path: '/assets/charting_library/',
        },
        `chart-${Usuario.username}`,
      );
    });
  }, [delayOrRealtime, getCurrentSubscription]);

  return (
    <>
      <S.GraficoB3Container>
        <h1>Gráfico B3</h1>
        <a
          href={CENTRAL_AJUDA_GRAFICO_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button icon="pi pi-question-circle" tooltip="Gráfico B3" />
        </a>
        <DelayRealtime variant={delayOrRealtime} />
      </S.GraficoB3Container>
      <div
        id="chart"
        style={{ width: '100%', height: 'calc(100vh - 10rem)' }}
      />
    </>
  );
};
