import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { ENCERRAR_OPCAO_SUCCESS_KEY } from '../../../../../context/toast/constants';
import { useToast } from '../../../../../context/toast/useToast';
import { EncerrarOpcaoModalObj } from '../types';

async function executeEncerrarOpcao(obj: EncerrarOpcaoModalObj) {
  const abortController = new AbortController();
  const signal = abortController.signal;

  await axios.post(
    `${process.env.REACT_APP_API_EVENTOS}/AjusteOpcoes/v1/ExecutaEncerraOpcao`,
    obj,
    { signal },
  );
}

export function useEncerrarOpcaoMutation() {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation(executeEncerrarOpcao, {
    onError: () => showToast(),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([
        'eventos',
        'AjusteOpcoes',
        'v1',
        'ListInstrumentosOpcao',
        variables.idUsuario,
      ]);
      showToast(ENCERRAR_OPCAO_SUCCESS_KEY);
    },
  });
}
