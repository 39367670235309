import styled from 'styled-components';

import { pxToRem } from '../../../../utils/functions/styles';

export const Container = styled.section`
  width: 100%;
  border: 1px solid rgba(83, 195, 194, 0.4);
  padding: 2rem;
  border-radius: 0.3rem;
  margin: 3rem 0;

  display: flex;
  align-items: flex-end;
  gap: 1rem;

  @media (max-width: 992px) {
    flex-direction: column;

    div {
      width: 100%;
    }

    button {
      text-align: center !important;
    }
  }
`;

export const FilterByDateContainer = styled.section`
  width: 100%;
  border: 1px solid rgba(83, 195, 194, 0.4);
  padding: 2rem;
  border-radius: 0.3rem;
  margin: 3rem 0;
`;

export const MonthContainer = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  background-color: rgba(150, 183, 199, 0.2);

  li {
    button {
      border: 0;
      width: 4rem;
      height: 6rem;
      background: rgba(150, 183, 199, 0.2);
      color: #112c50;

      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background: #335893;
        color: #fff;
      }
    }

    .Jan,
    .Fev,
    .Mar,
    .Abr,
    .Mai,
    .Jun,
    .Jul,
    .Ago,
    .Set,
    .Out,
    .Nov,
    .Dez {
      height: ${pxToRem(102)} !important;

      &.selectedMonth {
        background-color: #335893 !important;
        color: #fff;
      }
    }

    & + li {
      margin-top: 0.1rem;
    }
  }
`;

export const ContainerTablesCommonOperations = styled.div`
  width: 100%;
  overflow-x: hidden;

  .tituloRV {
    color: rgba(17, 44, 80, 1) !important;
  }

  .p-datatable {
    border: 1px solid rgba(83, 195, 194, 0.42);
    border-radius: 5px;

    .p-datatable-tbody > tr {
      color: rgba(17, 44, 80, 1);
      border-bottom: 0px solid rgba(83, 195, 194, 0.42);

      > td {
        border: none !important;
      }
    }
  }
`;

export const WarningMigratedUser = styled.p`
  font-weight: 300 !important ;
`;
