export const STATUS = [
  {
    label: 'Exercida',
    value: 'Exercida',
  },
  {
    label: 'Encerrada',
    value: 'Encerrada',
  },
  {
    label: 'Pendente',
    value: 'Pendente',
  },
  // {
  //   label: 'Vencida',
  //   value: 'Vencida',
  // },
];

export const SUB_TIPO = [
  {
    label: 'OPÇÃO DE AÇÃO',
    value: 13,
  },
];

export const TIPO_NEGOCIO = [
  { label: 'Swing', value: 1 },
  { label: 'DayTrade', value: 2 },
];
