import { Button, ButtonProps } from 'primereact/button';
import { DataTable, DataTableProps } from 'primereact/datatable';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { devices, pxToRem } from '../../utils/functions/styles';

export const Container = styled.main`
  .desktop {
    display: none;

    @media (min-width: 426px) {
      display: block;
    }
  }

  .mobile {
    display: none;

    @media ${devices.mobileL} {
      display: block;
    }
  }
`;

export const Header = styled.header`
  div {
    display: flex;
    align-items: center;
    gap: 1rem;

    h2 {
      margin: 0;
      font-weight: 500 !important;
      color: #000 !important;
      font-size: ${pxToRem(25, 14)} !important;
      line-height: 2rem;
    }

    a button {
      color: rgba(83, 195, 194, 1) !important;
      background-color: transparent;

      &:not(.tag):hover {
        opacity: 0.5;
        background-color: transparent !important;
        color: #53c3c2 !important;
      }
    }
  }

  p {
    margin-top: ${pxToRem(24, 14)};
    font-size: 1rem !important;
    color: #000;
    font-weight: 300 !important;
    line-height: ${pxToRem(24, 14)};
  }
`;

export const FormSection = styled.section`
  margin-block: 3rem 1rem;
  padding: 1.5rem 1rem;
  border: 1px solid #53c3c266;
  display: block;

  @media ${devices.mobileL} {
    margin-block: 0;
  }

  > div:first-child {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(
      auto-fit,
      minmax(min(100%, ${pxToRem(225, 14)}), 1fr)
    );

    input {
      border-color: #112c50;
    }

    input::placeholder {
      color: #112c50a3;
    }

    > div {
      display: flex;
      flex-direction: column;

      input {
        max-width: 100%;
      }
    }
  }

  > div:last-child {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
    gap: 2rem;

    button {
      display: initial;
      width: 100%;
      max-width: ${pxToRem(150, 14)};
    }
  }
`;

interface PrimeDataTableProps extends DataTableProps {
  children: ReactNode;
}

export const PrimeDataTable = styled(DataTable)<PrimeDataTableProps>`
  span.p-column-title {
    color: #112c50;
  }

  .p-datatable-tbody tr td[role='cell'] {
    color: #335893;

    &:last-child button {
      background-color: transparent;

      &:hover {
        background-color: transparent !important;
      }

      .p-button-icon {
        color: #112c50 !important;
        font-size: 1.5rem;
      }
    }
  }
`;

interface StatusButtonProps extends ButtonProps {
  status: 'Pendente' | 'Falha' | 'Processada';
}

const statusMap = new Map([
  ['Pendente', '#4D4FA1'],
  ['Falha', '#EA736A'],
  ['Processada', '#53C3C2'],
]);

export const StatusButton = styled(Button)<StatusButtonProps>`
  width: 100%;
  max-width: ${pxToRem(150, 14)};
  padding: 0.5rem;
  font-size: 12px;
  display: initial;
  background-color: ${({ status }) => statusMap.get(status)};
  border-radius: 3px;
  border-color: ${({ status }) => statusMap.get(status)} !important;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    border-color: transparent !important;
  }
`;
