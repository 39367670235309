export const ALL_ROUTES_KEY = 'all_routes';
export const PLANO_ANUAL_IR_AVANCADO_KEY = 'anual_ir_avancado';
export const PLANO_BI_ANUAL_IR_AVANCADO_KEY = 'bi_anual_ir_avancado';
export const PLANO_DEGUSTACAO_KEY = 'degustacao';
const ALL_ROUTES = [
  {
    label: 'Calculadora',
    icon: 'pi pi-fw pi-icon-CalculadoraIR',
    items: [
      {
        label: 'Importação de notas',
        icon: 'pi pi-fw pi-icon-importacaoNotas',
        to: '/ImportacaoNotas',
      },
      {
        label: 'Histórico de Notas',
        icon: 'pi pi-fw pi-icon-verificacaoNota',
        to: '/HistoricoNotas',
      },
      {
        label: 'Histórico de operações',
        icon: 'pi pi-fw pi-icon-historicoDeOperacoes',
        to: '/calculadora-ir/historico-operacoes',
      },
      {
        label: 'Proventos',
        icon: 'pi pi-fw pi-icon-proventos',
        to: '/proventos',
      },
      {
        label: 'Eventos',
        icon: 'pi pi-fw pi-icon-desdobramentoAgrupamento',
        to: '/calculadora-ir/Eventos',
      },
      {
        label: 'Alteração de código',
        icon: 'pi pi-fw pi-icon-alteracaoCodigo',
        to: '/calculadora-ir/alteracao-codigo',
      },
      {
        label: 'Cadastro IPO/Subscrição',
        icon: 'pi pi-fw pi-icon-IPO',
        to: '/calculadora-ir/cadastro-ipo-subscricao',
      },
      {
        label: 'Ajuste de opções',
        icon: 'pi pi-fw pi-icon-ajustes',
        to: '/calculadora-ir/ajuste-de-opcoes',
      },
    ],
  },
  { separator: true },
  {
    label: 'Carteira',
    icon: 'pi pi-fw pi-icon-minhaCarteira',
    items: [
      {
        label: 'Patrimônio',
        icon: 'pi pi-fw pi-icon-PatrimonioTR',
        to: '/minha-carteira/patrimonio',
      },
      {
        label: 'Performace',
        icon: 'pi pi-fw pi-icon-PatrimonioTR',
        to: '/minha-carteira/relatorio-performance',
      },
      {
        label: 'Gráfico',
        icon: 'pi pi-fw pi-icon-graficoB3',
        to: '/graficob3',
      },
    ],
  },
  { separator: true },
  {
    label: 'Impostos',
    icon: 'pi pi-fw pi-icon-impostos',
    items: [
      {
        label: 'Emitir DARF',
        icon: 'pi pi-fw pi-icon-emitirDarf',
        to: '/impostos/emissao-darf',
      },
      {
        label: 'Confirmar Pagamento',
        icon: 'pi pi-fw pi-icon-informarPagamento',
        to: '/impostos/historico-darf',
      },
    ],
  },
  { separator: true },
  {
    label: 'Relatórios',
    icon: 'pi pi-fw pi-icon-relatorios',
    items: [
      {
        label: 'Bens e direitos',
        icon: 'pi pi-fw pi-icon-rendaVariavel',
        to: '/relatorios/bens-direitos',
      },
      {
        label: 'Operações comuns/Day trade',
        icon: 'pi pi-fw pi-icon-rendaVariavel',
        to: '/relatorios/operacoes-comuns-e-day-trade',
      },
      {
        label: 'Fundos imobiliários',
        icon: 'pi pi-fw pi-icon-fundosImobiliarios',
        to: '/relatorios/fundosImobiliarios',
      },
      {
        label: 'Rendimentos isentos',
        icon: 'pi pi-fw pi-icon-posicaoMensal',
        to: '/relatorios/isento',
      },
      {
        label: 'Rendimentos tributação exclusiva',
        icon: 'pi pi-fw pi-icon-posicaoMensal',
        to: '/relatorios/tributacao-exclusiva',
      },
    ],
  },
  { separator: true },
  {
    label: 'Ferramentas',
    icon: 'pi pi-fw pi-icon-ferramentas',
    items: [
      {
        label: 'ABC do Investidor',
        icon: 'pi pi-fw pi-icon-abc',
        url: 'https://www.bussoladoinvestidor.com.br/conteudos/abc-do-investidor/',
        target: '_blank',
      },
      {
        label: 'Biblioteca do investidor',
        icon: 'pi pi-fw pi-icon-biblioteca',
        url: 'https://www.bussoladoinvestidor.com.br/biblioteca-do-investidor/',
        target: '_blank',
      },
      {
        label: 'Guia análise técnica',
        icon: 'pi pi-fw pi-icon-guiaanalises',
        url: 'https://www.bussoladoinvestidor.com.br/guia-analise-tecnica/',
        target: '_blank',
      },
      {
        label: 'Guia de corretoras',
        icon: 'pi pi-fw pi-icon-guiacorretoras',
        url: 'https://www.bussoladoinvestidor.com.br/corretora/',
        target: '_blank',
      },
      // {
      //   label: 'Guia de empresas',
      //   icon: 'pi pi-fw pi-icon-guiaempresas',
      //   url: 'https://www.bussoladoinvestidor.com.br/guia-empresas/',
      //   target: '_blank',
      // },
      { label: 'Link RTD', icon: 'pi pi-fw pi-icon-linkRTD', to: '/linkrtd' },
    ],
  },
  { separator: true },
  // {
  //   label: "Configurações",
  //   icon: "pi pi-fw pi-icon-configuracao",
  //   items: [
  //     { label: "Cadastro inicial", icon: "pi pi-fw pi-icon-cadastroInicial", to: '/cadastroinicial/relatorio' },
  //     /*{ label: "Data Referência", icon: "pi pi-fw pi-icon-cadastroInicial", to: '/datareferencia' },
  //     { label: "Integração de corretoras", icon: "pi pi-fw pi-icon-intCorretoras", to: "/input" },
  //     { label: "Integração com CEI", icon: "pi pi-fw pi-icon-Cei", to: "/floatlabel" },
  //     { label: "Liquidação de termos", icon: "pi pi-fw pi-icon-liquiTermos", to: "/floatlabel" },
  //     { label: "Transferência entre corretoras", icon: "pi pi-fw pi-icon-transferenciaCorretoras", to: "/floatlabel" },
  //     { label: "Apagar operações", icon: "pi pi-fw pi-icon-apagar", to: "/floatlabel" },*/
  //   ],
  // },
  { separator: true },
  {
    label: 'Suporte',
    icon: 'pi pi-fw pi-icon-suporte',
    items: [
      // {
      //   label: 'Onboarding',
      //   icon: 'pi pi-fw pi-icon-onboarding',
      //   to: '/onboarding',
      // },
      {
        label: 'Ajuda zendesk',
        icon: 'pi pi-fw pi-icon-centralAjuda',
        url: 'https://comunidadebussoladoinvestidor.zendesk.com/hc/pt-br ',
        target: '_blank',
      },
    ],
  },
];

export const SUPORTE_KEY = 'adm';
const SUPORTE_ROUTES = [
  {
    label: 'Calculadora',
    icon: 'pi pi-fw pi-icon-CalculadoraIR',
    items: [
      {
        label: 'Importação de notas',
        icon: 'pi pi-fw pi-icon-importacaoNotas',
        to: '/ImportacaoNotas',
      },
      {
        label: 'Cadastro de notas',
        icon: 'pi pi-fw pi-icon-cadastroManual',
        to: '/calculadora/cadastromanual',
      },
      {
        label: 'Histórico de Notas',
        icon: 'pi pi-fw pi-icon-verificacaoNota',
        to: '/HistoricoNotas',
      },
      {
        label: 'Histórico de Notas v2',
        icon: 'pi pi-fw pi-icon-verificacaoNota',
        to: '/HistoricoNotasV2',
      },
      {
        label: 'Histórico de operações',
        icon: 'pi pi-fw pi-icon-historicoDeOperacoes',
        to: '/calculadora-ir/historico-operacoes',
      },
      {
        label: 'Proventos',
        icon: 'pi pi-fw pi-icon-proventos',
        to: '/proventos',
      },
      {
        label: 'Eventos',
        icon: 'pi pi-fw pi-icon-desdobramentoAgrupamento',
        to: '/calculadora-ir/eventos',
      },
      {
        label: 'Alteração de código',
        icon: 'pi pi-fw pi-icon-alteracaoCodigo',
        to: '/calculadora-ir/alteracao-codigo',
      },
      {
        label: 'Cadastro IPO/Subscrição',
        icon: 'pi pi-fw pi-icon-IPO',
        to: '/calculadora-ir/cadastro-ipo-subscricao',
      },
      {
        label: 'Ajuste de opções',
        icon: 'pi pi-fw pi-icon-ajustes',
        to: '/calculadora-ir/ajuste-de-opcoes',
      },
    ],
  },
  { separator: true },
  {
    label: 'Carteira',
    icon: 'pi pi-fw pi-icon-minhaCarteira',
    items: [
      {
        label: 'Patrimônio',
        icon: 'pi pi-fw pi-icon-PatrimonioTR',
        to: '/minha-carteira/patrimonio',
      },
      {
        label: 'Performace',
        icon: 'pi pi-fw pi-icon-PatrimonioTR',
        to: '/minha-carteira/relatorio-performance',
      },
      {
        label: 'Gráfico',
        icon: 'pi pi-fw pi-icon-graficoB3',
        to: '/graficob3',
      },
    ],
  },
  { separator: true },
  {
    label: 'Impostos',
    icon: 'pi pi-fw pi-icon-impostos',
    items: [
      {
        label: 'Emitir DARF',
        icon: 'pi pi-fw pi-icon-emitirDarf',
        to: '/impostos/emissao-darf',
      },
      {
        label: 'Confirmar Pagamento',
        icon: 'pi pi-fw pi-icon-informarPagamento',
        to: '/impostos/historico-darf',
      },
    ],
  },
  { separator: true },
  {
    label: 'Relatórios',
    icon: 'pi pi-fw pi-icon-relatorios',
    items: [
      {
        label: 'Bens e direitos',
        icon: 'pi pi-fw pi-icon-rendaVariavel',
        to: '/relatorios/bens-direitos',
      },
      {
        label: 'Operações comuns/Day trade',
        icon: 'pi pi-fw pi-icon-rendaVariavel',
        to: '/relatorios/operacoes-comuns-e-day-trade',
      },
      {
        label: 'Fundos imobiliários',
        icon: 'pi pi-fw pi-icon-fundosImobiliarios',
        to: '/relatorios/fundosImobiliarios',
      },
      {
        label: 'Rendimentos isentos',
        icon: 'pi pi-fw pi-icon-posicaoMensal',
        to: '/relatorios/isento',
      },
      {
        label: 'Rendimentos tributação exclusiva',
        icon: 'pi pi-fw pi-icon-posicaoMensal',
        to: '/relatorios/tributacao-exclusiva',
      },
    ],
  },
  { separator: true },
  {
    label: 'Ferramentas',
    icon: 'pi pi-fw pi-icon-ferramentas',
    items: [
      {
        label: 'ABC do Investidor',
        icon: 'pi pi-fw pi-icon-abc',
        url: 'https://www.bussoladoinvestidor.com.br/conteudos/abc-do-investidor/',
        target: '_blank',
      },
      {
        label: 'Biblioteca do investidor',
        icon: 'pi pi-fw pi-icon-biblioteca',
        url: 'https://www.bussoladoinvestidor.com.br/biblioteca-do-investidor/',
        target: '_blank',
      },
      {
        label: 'Guia análise técnica',
        icon: 'pi pi-fw pi-icon-guiaanalises',
        url: 'https://www.bussoladoinvestidor.com.br/guia-analise-tecnica/',
        target: '_blank',
      },
      {
        label: 'Guia de corretoras',
        icon: 'pi pi-fw pi-icon-guiacorretoras',
        url: 'https://www.bussoladoinvestidor.com.br/corretora/',
        target: '_blank',
      },
      {
        label: 'Guia de empresas',
        icon: 'pi pi-fw pi-icon-guiaempresas',
        url: 'https://www.bussoladoinvestidor.com.br/guia-empresas/',
        target: '_blank',
      },
      { label: 'Link RTD', icon: 'pi pi-fw pi-icon-linkRTD', to: '/linkrtd' },
    ],
  },
  { separator: true },
  // {
  //   label: "Configurações",
  //   icon: "pi pi-fw pi-icon-configuracao",
  //   items: [
  //     { label: "Cadastro inicial", icon: "pi pi-fw pi-icon-cadastroInicial", to: '/cadastroinicial/relatorio' },
  //     /*{ label: "Data Referência", icon: "pi pi-fw pi-icon-cadastroInicial", to: '/datareferencia' },
  //     { label: "Integração de corretoras", icon: "pi pi-fw pi-icon-intCorretoras", to: "/input" },
  //     { label: "Integração com CEI", icon: "pi pi-fw pi-icon-Cei", to: "/floatlabel" },
  //     { label: "Liquidação de termos", icon: "pi pi-fw pi-icon-liquiTermos", to: "/floatlabel" },
  //     { label: "Transferência entre corretoras", icon: "pi pi-fw pi-icon-transferenciaCorretoras", to: "/floatlabel" },
  //     { label: "Apagar operações", icon: "pi pi-fw pi-icon-apagar", to: "/floatlabel" },*/
  //   ],
  // },
  { separator: true },
  {
    label: 'Suporte',
    icon: 'pi pi-fw pi-icon-suporte',
    items: [
      {
        label: 'Onboarding',
        icon: 'pi pi-fw pi-icon-onboarding',
        to: '/onboarding',
      },
      {
        label: 'Ajuda zendesk',
        icon: 'pi pi-fw pi-icon-centralAjuda',
        url: 'https://comunidadebussoladoinvestidor.zendesk.com/hc/pt-br ',
        target: '_blank',
      },
    ],
  },
];

export const PLANO_GRATIS_KEY = 'gratis';
const PLANO_GRATIS_ROUTES = [
  {
    label: 'Calculadora',
    icon: 'pi pi-fw pi-icon-CalculadoraIR',
    items: [
      {
        label: 'Importação de notas',
        icon: 'pi pi-fw pi-icon-importacaoNotas',
        to: '/ImportacaoNotas',
      },
      {
        label: 'Histórico de Notas',
        icon: 'pi pi-fw pi-icon-verificacaoNota',
        to: '/HistoricoNotas',
      },
      {
        label: 'Eventos',
        icon: 'pi pi-fw pi-icon-desdobramentoAgrupamento',
        to: '/calculadora-ir/eventos',
      },
      {
        label: 'Alteração de código',
        icon: 'pi pi-fw pi-icon-alteracaoCodigo',
        to: '/calculadora-ir/alteracao-codigo',
      },
    ],
  },
  { separator: true },
  {
    label: 'Carteira',
    icon: 'pi pi-fw pi-icon-minhaCarteira',
    items: [
      {
        label: 'Patrimônio',
        icon: 'pi pi-fw pi-icon-PatrimonioTR',
        to: '/minha-carteira/patrimonio',
      },
    ],
  },
  { separator: true },
  {
    label: 'Impostos',
    icon: 'pi pi-fw pi-icon-impostos',
    items: [
      {
        label: 'Emitir DARF',
        icon: 'pi pi-fw pi-icon-emitirDarf',
        to: '/impostos/emissao-darf',
      },
      {
        label: 'Confirmar Pagamento',
        icon: 'pi pi-fw pi-icon-informarPagamento',
        to: '/impostos/historico-darf',
      },
    ],
  },
  { separator: true },
  {
    label: 'Relatórios',
    icon: 'pi pi-fw pi-icon-relatorios',
    items: [
      {
        label: 'Bens e direitos',
        icon: 'pi pi-fw pi-icon-rendaVariavel',
        to: '/relatorios/bens-direitos',
      },
      {
        label: 'Operações comuns/Day trade',
        icon: 'pi pi-fw pi-icon-rendaVariavel',
        to: '/relatorios/operacoes-comuns-e-day-trade',
      },
      {
        label: 'Fundos imobiliários',
        icon: 'pi pi-fw pi-icon-fundosImobiliarios',
        to: '/relatorios/fundosImobiliarios',
      },
      {
        label: 'Rendimentos isentos',
        icon: 'pi pi-fw pi-icon-posicaoMensal',
        to: '/relatorios/isento',
      },
      {
        label: 'Rendimentos tributação exclusiva',
        icon: 'pi pi-fw pi-icon-posicaoMensal',
        to: '/relatorios/tributacao-exclusiva',
      },
    ],
  },
  { separator: true },
  {
    label: 'Ferramentas',
    icon: 'pi pi-fw pi-icon-ferramentas',
    items: [
      {
        label: 'ABC do Investidor',
        icon: 'pi pi-fw pi-icon-abc',
        url: 'https://www.bussoladoinvestidor.com.br/conteudos/abc-do-investidor/',
        target: '_blank',
      },
      {
        label: 'Biblioteca do investidor',
        icon: 'pi pi-fw pi-icon-biblioteca',
        url: 'https://www.bussoladoinvestidor.com.br/biblioteca-do-investidor/',
        target: '_blank',
      },
      {
        label: 'Guia análise técnica',
        icon: 'pi pi-fw pi-icon-guiaanalises',
        url: 'https://www.bussoladoinvestidor.com.br/guia-analise-tecnica/',
        target: '_blank',
      },
      {
        label: 'Guia de corretoras',
        icon: 'pi pi-fw pi-icon-guiacorretoras',
        url: 'https://www.bussoladoinvestidor.com.br/corretora/',
        target: '_blank',
      },
    ],
  },
  { separator: true },
  { separator: true },
  {
    label: 'Suporte',
    icon: 'pi pi-fw pi-icon-suporte',
    items: [
      // {
      //   label: 'Onboarding',
      //   icon: 'pi pi-fw pi-icon-onboarding',
      //   to: '/onboarding',
      // },
      {
        label: 'Ajuda zendesk',
        icon: 'pi pi-fw pi-icon-centralAjuda',
        url: 'https://comunidadebussoladoinvestidor.zendesk.com/hc/pt-br ',
        target: '_blank',
      },
    ],
  },
];

export const PLANO_AVULSO_IR_KEY = 'avulso_ir';
const PLANO_AVULSO_IR_ROUTES = [
  {
    label: 'Calculadora',
    icon: 'pi pi-fw pi-icon-CalculadoraIR',
    items: [
      {
        label: 'Importação de notas',
        icon: 'pi pi-fw pi-icon-importacaoNotas',
        to: '/ImportacaoNotas',
      },
      {
        label: 'Histórico de Notas',
        icon: 'pi pi-fw pi-icon-verificacaoNota',
        to: '/HistoricoNotas',
      },
      {
        label: 'Histórico de operações',
        icon: 'pi pi-fw pi-icon-historicoDeOperacoes',
        to: '/calculadora-ir/historico-operacoes',
      },
      {
        label: 'Eventos',
        icon: 'pi pi-fw pi-icon-desdobramentoAgrupamento',
        to: '/calculadora-ir/eventos',
      },
      {
        label: 'Alteração de código',
        icon: 'pi pi-fw pi-icon-alteracaoCodigo',
        to: '/calculadora-ir/alteracao-codigo',
      },
      {
        label: 'Cadastro IPO/Subscrição',
        icon: 'pi pi-fw pi-icon-IPO',
        to: '/calculadora-ir/cadastro-ipo-subscricao',
      },
      {
        label: 'Ajuste de opções',
        icon: 'pi pi-fw pi-icon-ajustes',
        to: '/calculadora-ir/ajuste-de-opcoes',
      },
    ],
  },
  { separator: true },
  {
    label: 'Carteira',
    icon: 'pi pi-fw pi-icon-minhaCarteira',
    items: [
      {
        label: 'Patrimônio',
        icon: 'pi pi-fw pi-icon-PatrimonioTR',
        to: '/minha-carteira/patrimonio',
      },
      {
        label: 'Gráfico',
        icon: 'pi pi-fw pi-icon-graficoB3',
        to: '/graficob3',
      },
    ],
  },
  { separator: true },
  {
    label: 'Impostos',
    icon: 'pi pi-fw pi-icon-impostos',
    items: [
      {
        label: 'Emitir DARF',
        icon: 'pi pi-fw pi-icon-emitirDarf',
        to: '/impostos/emissao-darf',
      },
      {
        label: 'Confirmar Pagamento',
        icon: 'pi pi-fw pi-icon-informarPagamento',
        to: '/impostos/historico-darf',
      },
    ],
  },
  { separator: true },
  {
    label: 'Relatórios',
    icon: 'pi pi-fw pi-icon-relatorios',
    items: [
      {
        label: 'Bens e direitos',
        icon: 'pi pi-fw pi-icon-rendaVariavel',
        to: '/relatorios/bens-direitos',
      },
      {
        label: 'Operações comuns/Day trade',
        icon: 'pi pi-fw pi-icon-rendaVariavel',
        to: '/relatorios/operacoes-comuns-e-day-trade',
      },
      {
        label: 'Fundos imobiliários',
        icon: 'pi pi-fw pi-icon-fundosImobiliarios',
        to: '/relatorios/fundosImobiliarios',
      },
      {
        label: 'Rendimentos isentos',
        icon: 'pi pi-fw pi-icon-posicaoMensal',
        to: '/relatorios/isento',
      },
      {
        label: 'Rendimentos tributação exclusiva',
        icon: 'pi pi-fw pi-icon-posicaoMensal',
        to: '/relatorios/tributacao-exclusiva',
      },
    ],
  },
  { separator: true },
  {
    label: 'Ferramentas',
    icon: 'pi pi-fw pi-icon-ferramentas',
    items: [
      {
        label: 'ABC do Investidor',
        icon: 'pi pi-fw pi-icon-abc',
        url: 'https://www.bussoladoinvestidor.com.br/conteudos/abc-do-investidor/',
        target: '_blank',
      },
      {
        label: 'Biblioteca do investidor',
        icon: 'pi pi-fw pi-icon-biblioteca',
        url: 'https://www.bussoladoinvestidor.com.br/biblioteca-do-investidor/',
        target: '_blank',
      },
      {
        label: 'Guia análise técnica',
        icon: 'pi pi-fw pi-icon-guiaanalises',
        url: 'https://www.bussoladoinvestidor.com.br/guia-analise-tecnica/',
        target: '_blank',
      },
      {
        label: 'Guia de corretoras',
        icon: 'pi pi-fw pi-icon-guiacorretoras',
        url: 'https://www.bussoladoinvestidor.com.br/corretora/',
        target: '_blank',
      },
    ],
  },
  { separator: true },
  { separator: true },
  {
    label: 'Suporte',
    icon: 'pi pi-fw pi-icon-suporte',
    items: [
      // {
      //   label: 'Onboarding',
      //   icon: 'pi pi-fw pi-icon-onboarding',
      //   to: '/onboarding',
      // },
      {
        label: 'Ajuda zendesk',
        icon: 'pi pi-fw pi-icon-centralAjuda',
        url: 'https://comunidadebussoladoinvestidor.zendesk.com/hc/pt-br ',
        target: '_blank',
      },
    ],
  },
];

export const PLANO_INTER_IRPRO_MENSAL_KEY = 'irpro_inter_mensal';
export const PLANO_INTER_IRPRO_ANUAL_KEY = 'irpro_inter_anual';
export const PLANO_INTER_INVESTPRO_MENSAL_KEY = 'investpro_inter_mensal';
const PLANOS_INTER_ROUTES = [
  {
    label: 'Calculadora',
    icon: 'pi pi-fw pi-icon-CalculadoraIR',
    items: [
      {
        label: 'Importação de notas',
        icon: 'pi pi-fw pi-icon-importacaoNotas',
        to: '/ImportacaoNotas',
      },
      {
        label: 'Histórico de Notas',
        icon: 'pi pi-fw pi-icon-verificacaoNota',
        to: '/HistoricoNotas',
      },
      {
        label: 'Histórico de operações',
        icon: 'pi pi-fw pi-icon-historicoDeOperacoes',
        to: '/calculadora-ir/historico-operacoes',
      },
      {
        label: 'Proventos',
        icon: 'pi pi-fw pi-icon-proventos',
        to: '/proventos',
      },
      {
        label: 'Eventos',
        icon: 'pi pi-fw pi-icon-desdobramentoAgrupamento',
        to: '/calculadora-ir/eventos',
      },
      {
        label: 'Alteração de código',
        icon: 'pi pi-fw pi-icon-alteracaoCodigo',
        to: '/calculadora-ir/alteracao-codigo',
      },
      {
        label: 'Cadastro IPO/Subscrição',
        icon: 'pi pi-fw pi-icon-IPO',
        to: '/calculadora-ir/cadastro-ipo-subscricao',
      },
      {
        label: 'Ajuste de opções',
        icon: 'pi pi-fw pi-icon-ajustes',
        to: '/calculadora-ir/ajuste-de-opcoes',
      },
    ],
  },
  { separator: true },
  {
    label: 'Carteira',
    icon: 'pi pi-fw pi-icon-minhaCarteira',
    items: [
      {
        label: 'Patrimônio',
        icon: 'pi pi-fw pi-icon-PatrimonioTR',
        to: '/minha-carteira/patrimonio',
      },
      {
        label: 'Gráfico',
        icon: 'pi pi-fw pi-icon-graficoB3',
        to: '/graficob3',
      },
    ],
  },
  { separator: true },
  {
    label: 'Impostos',
    icon: 'pi pi-fw pi-icon-impostos',
    items: [
      {
        label: 'Emitir DARF',
        icon: 'pi pi-fw pi-icon-emitirDarf',
        to: '/impostos/emissao-darf',
      },
      {
        label: 'Confirmar Pagamento',
        icon: 'pi pi-fw pi-icon-informarPagamento',
        to: '/impostos/historico-darf',
      },
    ],
  },
  { separator: true },
  {
    label: 'Relatórios',
    icon: 'pi pi-fw pi-icon-relatorios',
    items: [
      {
        label: 'Bens e direitos',
        icon: 'pi pi-fw pi-icon-rendaVariavel',
        to: '/relatorios/bens-direitos',
      },
      {
        label: 'Operações comuns/Day trade',
        icon: 'pi pi-fw pi-icon-rendaVariavel',
        to: '/relatorios/operacoes-comuns-e-day-trade',
      },
      {
        label: 'Fundos imobiliários',
        icon: 'pi pi-fw pi-icon-fundosImobiliarios',
        to: '/relatorios/fundosImobiliarios',
      },
      {
        label: 'Rendimentos isentos',
        icon: 'pi pi-fw pi-icon-posicaoMensal',
        to: '/relatorios/isento',
      },
      {
        label: 'Rendimentos tributação exclusiva',
        icon: 'pi pi-fw pi-icon-posicaoMensal',
        to: '/relatorios/tributacao-exclusiva',
      },
    ],
  },
  { separator: true },
  {
    label: 'Ferramentas',
    icon: 'pi pi-fw pi-icon-ferramentas',
    items: [
      {
        label: 'ABC do Investidor',
        icon: 'pi pi-fw pi-icon-abc',
        url: 'https://www.bussoladoinvestidor.com.br/conteudos/abc-do-investidor/',
        target: '_blank',
      },
      {
        label: 'Biblioteca do investidor',
        icon: 'pi pi-fw pi-icon-biblioteca',
        url: 'https://www.bussoladoinvestidor.com.br/biblioteca-do-investidor/',
        target: '_blank',
      },
      {
        label: 'Guia análise técnica',
        icon: 'pi pi-fw pi-icon-guiaanalises',
        url: 'https://www.bussoladoinvestidor.com.br/guia-analise-tecnica/',
        target: '_blank',
      },
      {
        label: 'Guia de corretoras',
        icon: 'pi pi-fw pi-icon-guiacorretoras',
        url: 'https://www.bussoladoinvestidor.com.br/corretora/',
        target: '_blank',
      },
    ],
  },
  { separator: true },
  { separator: true },
  {
    label: 'Suporte',
    icon: 'pi pi-fw pi-icon-suporte',
    items: [
      // {
      //   label: 'Onboarding',
      //   icon: 'pi pi-fw pi-icon-onboarding',
      //   to: '/onboarding',
      // },
      {
        label: 'Ajuda zendesk',
        icon: 'pi pi-fw pi-icon-centralAjuda',
        url: 'https://comunidadebussoladoinvestidor.zendesk.com/hc/pt-br ',
        target: '_blank',
      },
    ],
  },
];

export const PLANO_ANUAL_IR_KEY = 'anual_ir';
export const PLANO_BI_ANUAL_IR_KEY = 'bi_anual_ir';
const PLANO_ANUAL_IR_ROUTES = [
  {
    label: 'Calculadora',
    icon: 'pi pi-fw pi-icon-CalculadoraIR',
    items: [
      {
        label: 'Importação de notas',
        icon: 'pi pi-fw pi-icon-importacaoNotas',
        to: '/ImportacaoNotas',
      },
      {
        label: 'Histórico de Notas',
        icon: 'pi pi-fw pi-icon-verificacaoNota',
        to: '/HistoricoNotas',
      },
      {
        label: 'Histórico de operações',
        icon: 'pi pi-fw pi-icon-historicoDeOperacoes',
        to: '/calculadora-ir/historico-operacoes',
      },
      {
        label: 'Proventos',
        icon: 'pi pi-fw pi-icon-proventos',
        to: '/proventos',
      },
      {
        label: 'Eventos',
        icon: 'pi pi-fw pi-icon-desdobramentoAgrupamento',
        to: '/calculadora-ir/eventos',
      },
      {
        label: 'Alteração de código',
        icon: 'pi pi-fw pi-icon-alteracaoCodigo',
        to: '/calculadora-ir/alteracao-codigo',
      },
      {
        label: 'Cadastro IPO/Subscrição',
        icon: 'pi pi-fw pi-icon-IPO',
        to: '/calculadora-ir/cadastro-ipo-subscricao',
      },
      {
        label: 'Ajuste de opções',
        icon: 'pi pi-fw pi-icon-ajustes',
        to: '/calculadora-ir/ajuste-de-opcoes',
      },
    ],
  },
  { separator: true },
  {
    label: 'Carteira',
    icon: 'pi pi-fw pi-icon-minhaCarteira',
    items: [
      {
        label: 'Patrimônio',
        icon: 'pi pi-fw pi-icon-PatrimonioTR',
        to: '/minha-carteira/patrimonio',
      },
      {
        label: 'Gráfico',
        icon: 'pi pi-fw pi-icon-graficoB3',
        to: '/graficob3',
      },
    ],
  },
  { separator: true },
  {
    label: 'Impostos',
    icon: 'pi pi-fw pi-icon-impostos',
    items: [
      {
        label: 'Emitir DARF',
        icon: 'pi pi-fw pi-icon-emitirDarf',
        to: '/impostos/emissao-darf',
      },
      {
        label: 'Confirmar Pagamento',
        icon: 'pi pi-fw pi-icon-informarPagamento',
        to: '/impostos/historico-darf',
      },
    ],
  },
  { separator: true },
  {
    label: 'Relatórios',
    icon: 'pi pi-fw pi-icon-relatorios',
    items: [
      {
        label: 'Bens e direitos',
        icon: 'pi pi-fw pi-icon-rendaVariavel',
        to: '/relatorios/bens-direitos',
      },
      {
        label: 'Operações comuns/Day trade',
        icon: 'pi pi-fw pi-icon-rendaVariavel',
        to: '/relatorios/operacoes-comuns-e-day-trade',
      },
      {
        label: 'Fundos imobiliários',
        icon: 'pi pi-fw pi-icon-fundosImobiliarios',
        to: '/relatorios/fundosImobiliarios',
      },
      {
        label: 'Rendimentos isentos',
        icon: 'pi pi-fw pi-icon-posicaoMensal',
        to: '/relatorios/isento',
      },
      {
        label: 'Rendimentos tributação exclusiva',
        icon: 'pi pi-fw pi-icon-posicaoMensal',
        to: '/relatorios/tributacao-exclusiva',
      },
    ],
  },
  { separator: true },
  {
    label: 'Ferramentas',
    icon: 'pi pi-fw pi-icon-ferramentas',
    items: [
      {
        label: 'ABC do Investidor',
        icon: 'pi pi-fw pi-icon-abc',
        url: 'https://www.bussoladoinvestidor.com.br/conteudos/abc-do-investidor/',
        target: '_blank',
      },
      {
        label: 'Biblioteca do investidor',
        icon: 'pi pi-fw pi-icon-biblioteca',
        url: 'https://www.bussoladoinvestidor.com.br/biblioteca-do-investidor/',
        target: '_blank',
      },
      {
        label: 'Guia análise técnica',
        icon: 'pi pi-fw pi-icon-guiaanalises',
        url: 'https://www.bussoladoinvestidor.com.br/guia-analise-tecnica/',
        target: '_blank',
      },
      {
        label: 'Guia de corretoras',
        icon: 'pi pi-fw pi-icon-guiacorretoras',
        url: 'https://www.bussoladoinvestidor.com.br/corretora/',
        target: '_blank',
      },
    ],
  },
  { separator: true },
  { separator: true },
  {
    label: 'Suporte',
    icon: 'pi pi-fw pi-icon-suporte',
    items: [
      // {
      //   label: 'Onboarding',
      //   icon: 'pi pi-fw pi-icon-onboarding',
      //   to: '/onboarding',
      // },
      {
        label: 'Ajuda zendesk',
        icon: 'pi pi-fw pi-icon-centralAjuda',
        url: 'https://comunidadebussoladoinvestidor.zendesk.com/hc/pt-br ',
        target: '_blank',
      },
    ],
  },
];

const PLANO_MAP = new Map([
  [ALL_ROUTES_KEY, ALL_ROUTES],
  [SUPORTE_KEY, SUPORTE_ROUTES],
  [PLANO_GRATIS_KEY, PLANO_GRATIS_ROUTES],
  [PLANO_AVULSO_IR_KEY, PLANO_AVULSO_IR_ROUTES],
  [PLANO_INTER_INVESTPRO_MENSAL_KEY, PLANOS_INTER_ROUTES],
  [PLANO_INTER_IRPRO_MENSAL_KEY, PLANOS_INTER_ROUTES],
  [PLANO_INTER_IRPRO_ANUAL_KEY, PLANOS_INTER_ROUTES],
  [PLANO_ANUAL_IR_KEY, PLANO_ANUAL_IR_ROUTES],
  [PLANO_BI_ANUAL_IR_KEY, PLANO_ANUAL_IR_ROUTES],
  [PLANO_ANUAL_IR_AVANCADO_KEY, ALL_ROUTES],
  [PLANO_BI_ANUAL_IR_AVANCADO_KEY, ALL_ROUTES],
  [PLANO_DEGUSTACAO_KEY, ALL_ROUTES],
]);

export const getMenuRoutes = (key: string) => {
  const routes = PLANO_MAP.get(key);

  if (routes) return routes;

  return PLANO_MAP.get(PLANO_GRATIS_KEY);
};
