import { formataValorDecimal } from '../../../../../../utils/functions';
import { INDISPONIVEL } from '../../commons/constants';
import { Container } from './styles';

type RentabilidadeProps = {
  valor?: string | number;
  somentePorcentagem?: boolean;
  porcentagem: string | number;
};

export function Rentabilidade({
  valor,
  somentePorcentagem,
  porcentagem,
}: RentabilidadeProps) {
  if ([valor, porcentagem].includes(INDISPONIVEL))
    return <span>{INDISPONIVEL}</span>;

  return (
    <Container porcentagem={porcentagem}>
      {!somentePorcentagem && <span>{valor}</span>}

      {porcentagem === '-' ? (
        '-'
      ) : (
        <div className="container-porcentagem">
          <i className={`pi pi-arrow-${porcentagem < 0 ? 'down' : 'up'}`}></i>
          <span className="porcentagem">
            {formataValorDecimal(porcentagem)}%
          </span>
        </div>
      )}
    </Container>
  );
}
