import styled from 'styled-components';

export const Box1 = styled.div`
  display: flex;
  justify-content: space-betwwen;

  section {
    width: 100%;

    h2 {
      margin-top: 1.2rem;
      font-size: 25px !important;
    }

    article {
      margin-bottom: 2rem;

      span {
        label {
          font-size: 16px !important;
        }
      }

      p {
        font-size: 16px !important;
      }
    }
  }
`;

export const Box100 = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.4);
  border-radius: 4px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-around;

    input {
      width: 100% !important;
    }

    button {
      margin: 1rem;
    }

    p {
      margin: 0.5rem 1rem;
      width: 20%;

      i {
        font-size: 2rem;
      }

      button {
        margin: 0;
      }

      input {
        text-align: center;
      }
    }
  }

  @media (max-width: 992px) {
    div {
      flex-direction: column;

      p {
        padding: 1rem;
        width: 100%;

        div {
          display: flex;

          span {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
`;

export const Box101 = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.4);
  border-radius: 4px;
  background-color: rgba(150, 183, 199, 0.1);

  div {
    border-top: 1px solid rgba(83, 195, 194, 0.4);
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);

    input {
      width: 100% !important;
    }

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      justify-content: space-between !important;
    }

    button {
      margin: 1rem;
    }

    p {
      margin: 1rem 0 1rem 1rem;
      position: relative;
      width: 10.8%;
      text-align: left;

      &:last-child {
        width: 2.8%;

        ul {
          margin: 0;
          padding: 0;
          position: absolute;
          right: 0;
          top: 100%;
          box-shadow: 1px 2px 5px rgba(50, 50, 50, 0.2);
          border-radius: 3px;
          z-index: 100;

          li {
            list-style: none;
            cursor: pointer;
            padding: 0.5rem 2rem 0.5rem 0.5rem;
          }
        }
      }

      i {
        font-size: 2rem;
        cursor: pointer;
      }

      button {
        margin: 0;
      }

      input {
        text-align: center;
        width: 100% !important;
      }
    }
  }

  @media (max-width: 992px) {
    div {
      flex-direction: column;

      p {
        padding: 1rem;
        width: 100%;
        margin: 0 1rem;

        &:last-child {
          width: 100%;
        }

        input {
          width: 100% !important;
        }

        div {
          display: flex;

          span {
            width: 100%;
            text-align: center;
          }
        }
      }

      &:last-child {
        flex-direction: row;
      }
    }
  }
`;

export const Opcoes = styled.p`
  background: transparent !important;
`;

export const TopoTransparente = styled.div`
  background: transparent !important;
`;

export const Box102 = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.4);
  border-radius: 4px;
  background-color: rgba(150, 183, 199, 0.1);

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    padding: 0 1.1rem;

    h4 {
      margin: 1rem;
    }

    &:first-child {
      background: transparent;
      border-top: 0;
    }

    &:nth-child(2) {
      border-top: 1px solid rgba(83, 195, 194, 0.4);
    }

    input {
      width: 100% !important;
    }

    p {
      padding: 0.3rem;
      margin: 0;
      position: relative;
      width: 12%;
      margin-top: 0.3rem;

      &:first-child {
        width: 35%;
      }

      &:nth-child(3) {
        width: 35%;
        left: 10px;
      }

      &:last-child {
        width: 6%;
        text-align: center;
      }

      i {
        font-size: 2rem;
        cursor: pointer;
      }

      button {
        margin: 0;
      }

      input {
        text-align: center;
        width: 100% !important;
      }
    }
  }

  @media (max-width: 992px) {
    div {
      flex-direction: column;

      p {
        padding: 0.5rem;
        width: 100%;
        margin: 0 0.5rem;

        &:first-child {
          width: 100%;
        }

        &:nth-child(3) {
          width: 100%;
        }

        &:last-child {
          width: 100%;
          text-align: left;
        }

        input {
          width: 100% !important;
        }

        div {
          display: flex;
          text-align: center;

          span {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const Phidden = styled.p`
  @media (max-width: 992px) {
    display: none !important;
  }
`;

export const Box103 = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.4);
  border-radius: 4px;
  background-color: rgba(150, 183, 199, 0.1);

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    padding: 0 0.2rem;

    &:first-child {
      background: transparent;
      border-top: 0;
    }

    &:nth-child(2) {
      border-top: 1px solid rgba(83, 195, 194, 0.4);
    }

    h4 {
      margin: 1rem;
    }

    input {
      width: 100% !important;
    }

    p {
      padding: 0.3rem;
      margin: 0;
      position: relative;
      width: 20%;
      margin-top: 0.3rem;

      nav {
        display: flex;
        justify-content: center;
        align-items: center;

        input {
          margin: 0.2rem;

          &:last-child {
            width: 3rem !important;
          }
        }
      }

      aside {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          margin-left: 0.4rem;

          &:first-child {
            margin-left: 0;
          }
        }
      }

      i {
        font-size: 2rem;
        cursor: pointer;
      }

      button {
        margin: 0;
      }

      input {
        text-align: center;
        width: 100% !important;
      }
    }
  }

  @media (max-width: 992px) {
    div {
      flex-direction: column;

      p {
        padding: 0.5rem;
        width: 100%;
        margin: 0 0.5rem;

        &:first-child {
          width: 100%;
        }

        &:nth-child(3) {
          width: 100%;
        }

        &:last-child {
          width: 100%;
          text-align: left;
        }

        input {
          width: 100% !important;
        }

        div {
          display: flex;
          text-align: center;

          span {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const TextCenter = styled.p`
  text-align: center;
`;

export const TextCenter2 = styled.p`
  text-align: center;
  background-color: rgba(150, 183, 199, 0.1);
`;

export const BgTransparent = styled.p`
  background-color: rgba(150, 183, 199, 0.1);
`;

export const BgTransparent2 = styled.p`
  background-color: rgba(150, 183, 199, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    width: 33%;
  }
`;

export const InputLeft = styled.p`
  input {
    text-align: left !important;
  }

  @media (max-width: 992px) {
    input {
      text-align: center !important;
    }
  }
`;

export const InputCenter = styled.p`
  input {
    text-align: center !important;
  }
`;

export const InputRight = styled.p`
  input {
    text-align: right !important;
  }

  @media (max-width: 992px) {
    input {
      text-align: center !important;
    }
  }
`;

export const Icon = styled.span`
  i {
    color: #53c3c2 !important;
    font-size: 1.5rem;
    margin-left: 0.5rem;
  }
`;

export const Small = styled.small``;
