import axios from 'axios';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';

import { BaseModule } from '../../../../components/BaseModule';
import { RELATORIO_BENS_DIREITOS } from '../../../../components/BaseModule/constants';
import Usuario from '../../../../components/Usuario';
import { useToast } from '../../../../context/toast/useToast';
import { useOperationYearsFilter } from '../../../../hooks/reports/useOperationYearsFilter';
import * as ES from '../styles';
import './bensdireitos.css';

const CURRENT_YEAR = new Date().getFullYear();

export const RelatorioBensDireitos = () => {
  const [listaBens, setListaBens] = useState([]);
  const [listaDividas, setListaDividas] = useState([]);
  const [selectedYear, setSelectedYear] = useState({
    name: String(CURRENT_YEAR),
    code: CURRENT_YEAR,
  });
  const [stringYears, setStringYears] = useState('');
  const [stringYearsAnterior, setStringYearsAnterior] = useState('');
  const { showToast } = useToast();

  const operationYearsQuery = useOperationYearsFilter({ userId: Usuario.id });

  useEffect(() => {
    fetchInitialData(selectedYear.name);
  }, [selectedYear.name]);

  useEffect(() => {
    setStringYears('Situação em 31/12/' + [selectedYear.name]);
    setStringYearsAnterior('Situação em 31/12/' + [selectedYear.code - 1]);
  }, [selectedYear.name, selectedYear.code]);

  const fetchInitialData = async year => {
    const idUser = Usuario.id;
    const abortController = new AbortController();
    const signal = abortController.signal;

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_RELATORIOS}/RelatorioBensDireito/v1/GetRelatorioBensDireitoPosicoesAbertas/${idUser}/${year}`,
        { signal },
      );

      const dadosBensResponse = [];
      const dadosDividasResponse = [];

      data.posicoesCompra.forEach(item => {
        const objBens = {
          id: item.idCodigoRF,
          ticker: item.instrumento.ticker,
          cnpj: item.empresa.cnpj,
          discriminacao: item.subtipoinstrumento.descricao,
          situacaoAnoAnterior: 'R$ ' + item.ultimoMesAnoPassado,
          situacaoAnoAtual: 'R$ ' + item.ultimoMesAnoAtual,
          grupo: item.idGrupoRF,
        };
        dadosBensResponse.push(objBens);
      });

      data.posicoesVenda.forEach(item => {
        const objDividas = {
          id: item.idCodigoRF,
          ticker: item.instrumento.ticker,
          cnpj: item.empresa.cnpj,
          discriminacao: item.subtipoinstrumento.descricao,
          situacaoAnoAnterior: 'R$ ' + item.ultimoMesAnoPassado,
          situacaoAnoAtual: 'R$ ' + item.ultimoMesAnoAtual,
        };

        dadosDividasResponse.push(objDividas);
      });

      setListaBens(dadosBensResponse);
      setListaDividas(dadosDividasResponse);
    } catch (error) {
      showToast();
      console.error(error);
    }
  };

  return (
    <BaseModule
      tipo={RELATORIO_BENS_DIREITOS}
      props={{ nome: 'bens e direitos' }}
      warn={
        <ES.WarningParagraph>
          *Ano-calendário: é o período de 12 meses (1º de janeiro a 31 de
          dezembro) em que foram registrados os rendimentos e despesas do
          contribuinte.
        </ES.WarningParagraph>
      }
    >
      {!operationYearsQuery.isLoading && (
        <ES.FilterByDateContainer>
          <label htmlFor="operationYears">Ano-calendário:</label>
          <Dropdown
            id="operationYears"
            value={selectedYear}
            options={operationYearsQuery.data}
            onChange={e => setSelectedYear(e.value)}
            optionLabel="name"
            placeholder="Selecione o ano"
          />
        </ES.FilterByDateContainer>
      )}

      <DataTable
        value={listaBens}
        dataKey="id"
        header="Bens e direitos"
        responsiveLayout="scroll"
        emptyMessage="Lista vazia."
      >
        <Column field="id" header="Código" />
        <Column field="grupo" header="Grupo" />
        <Column field="ticker" header="Ticker" />
        <Column field="cnpj" header="CNPJ" />
        <Column field="discriminacao" header="Discriminação" />
        <Column field="situacaoAnoAnterior" header={stringYearsAnterior} />
        <Column field="situacaoAnoAtual" header={stringYears} />
      </DataTable>

      <DataTable
        value={listaDividas}
        header="Dívidas e Ônus"
        dataKey="id"
        responsiveLayout="scroll"
        emptyMessage="Lista vazia."
        style={{ marginTop: 64 }}
      >
        <Column field="id" header="Código" />
        <Column field="ticker" header="Ticker" />
        <Column field="cnpj" header="CNPJ" />
        <Column field="discriminacao" header="Discriminação" />
        <Column field="situacaoAnoAnterior" header={stringYearsAnterior} />
        <Column field="situacaoAnoAtual" header={stringYears} />
      </DataTable>
    </BaseModule>
  );
};
