import {
  CHART_BACKGROUND_COLOR,
  CHART_HOVER_BACKGROUND_COLOR,
} from './commons/constants';

export const geraObjetoDataDoGrafico = (labels: string[], data: number[]) => ({
  labels,
  datasets: [
    {
      data,
      backgroundColor: CHART_BACKGROUND_COLOR,
      hoverBackgroundColor: CHART_HOVER_BACKGROUND_COLOR,
    },
  ],
});
