import { Dialog } from 'primereact/dialog';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface AddBoniPriceDialogContainerProps {
  children: ReactNode;
}

export const AddBoniPriceDialogContainer = styled(
  Dialog,
)<AddBoniPriceDialogContainerProps>``;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid ${({ theme }) => theme['cyan-opacity']};
  padding: 1rem 0.5rem;

  input:disabled {
    pointer-events: all;
    cursor: not-allowed !important;
  }
`;
