export const MINI_CONTRATOS = ['WIN', 'WDO'];

export const COMPRA = 'C';

export const VENDA = 'V';

export const CHART_BACKGROUND_COLOR = [
  '#4D4FA1',
  '#EA736A',
  '#53C3C2',
  '#96B7C7',
  '#1B3667',
  '#335893',
  '#E6E5E6',
];

export const CHART_HOVER_BACKGROUND_COLOR = [
  'rgba(77, 79, 161, 0.8)',
  'rgba(234, 115, 106, 0.8)',
  'rgba(83, 195, 194, 0.8)',
  'rgba(150, 183, 199, 0.8)',
  'rgba(27, 54, 103, 0.8)',
  'rgba(51, 88, 147, 0.8)',
  'rgba(230, 229, 230, 0.8)',
];

export const CHART_OPTIONS = {
  plugins: {
    responsive: true,
    legend: {
      display: false,
    },
  },
};

export const INDISPONIVEL = 'Indisponível';

export const CONTRATOS_FUTUROS = 'Contratos Futuros';
