import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';

import { GreenBorderBox } from '../../../../../styles/elements/GreenBorderBox';
import { Container, TabelaPosicoesAbertas } from './styles';

export function PosicoesAbertasSkeleton() {
  const bodyTemplate = () => {
    return <Skeleton></Skeleton>;
  };

  return (
    <GreenBorderBox>
      <Container>
        <div>
          <Skeleton width={'85px'} height={'25px'} />
          <Skeleton width={'218px'} height={'20px'} />
        </div>
        <TabelaPosicoesAbertas
          value={[1, 2, 3, 4, 5, 6]}
          responsiveLayout="scroll"
          dataKey="id"
        >
          <Column expander />
          <Column
            field="tipoAtivo"
            header="Tipo de ativo"
            body={bodyTemplate}
          />
          <Column
            field="quantidade"
            header="Quantidade de ativos"
            body={bodyTemplate}
          />
          <Column
            field="patrimonioInvestido"
            header="Patrimônio investido"
            body={bodyTemplate}
          />
          <Column
            field="patrimonioAtual"
            header="Patrimônio atual"
            body={bodyTemplate}
          />
          <Column header="Rentabilidade" body={bodyTemplate} />
        </TabelaPosicoesAbertas>
      </Container>
    </GreenBorderBox>
  );
}
