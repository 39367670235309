import { addLocale } from 'primereact/api';

import { PT_BR_CALENDAR } from '../constants';

export const useBRLocale = () => addLocale('br', PT_BR_CALENDAR);

const getAnoAnterior = (ano, mes) => {
  if (mes === '01') {
    return Number(ano) - 1;
  }

  return ano;
};

export const transformaDataEmMesAno = data => {
  const mesAno = `${data.toISOString().split('T')[0].split('-')[1]}/${
    data.toISOString().split('T')[0].split('-')[0]
  }
  `;

  const mesAnoComZeroNaFrente = mesAno.startsWith('0');

  return mesAnoComZeroNaFrente ? mesAno.slice(1) : mesAno;
};

export const formatISO = date =>
  date.split('T')[0].split('-').reverse().join('/');

export const getMesAnoAtual = () =>
  `${new Date().toISOString().split('T')[0].split('-')[1]}/${
    new Date().toISOString().split('T')[0].split('-')[0]
  }`;

const getMesAnterior = mes => {
  if (mes === '01') {
    return 12;
  }

  return Number(mes) - 1;
};

export const getAnoMesAnterior = mesAno => {
  const [mes, ano] = mesAno.split('/');

  return `${getAnoAnterior(ano, mes)}/${getMesAnterior(mes)}`;
};

export const getAnoMes = mesAno => {
  const [mes, ano] = mesAno.split('/');

  return `${ano}/${mes}`;
};

export const getYearMonthWith0 = monthAndYear => {
  const [month, year] = monthAndYear.split('/');
  const newMonth = addPadStartToMonth(month);

  return `${newMonth}/${year}`;
};

export const getAno = mesAno => {
  const [_, ano] = mesAno.split('/');

  return `${ano}`;
};

export const getMes = mesAno => {
  const [mes] = mesAno.split('/');

  return `${mes}`;
};

export const getMonthWith0 = monthAndYear => {
  const [month] = monthAndYear.split('/');
  const monthFormatted = addPadStartToMonth(month);

  return `${monthFormatted}`;
};

export const isMesAnoAtual = mesAno => {
  if (!mesAno) return false;

  const dataAtual = transformaDataEmMesAno(new Date());

  const mesAtual = Number(getMes(dataAtual));
  const anoAtual = Number(getAno(dataAtual));

  const mesArgumento = Number(getMes(mesAno));
  const anoArgumento = Number(getAno(mesAno));

  return mesAtual === mesArgumento && anoAtual === anoArgumento;
};

export const addPadStartToMonth = (month, value = '0') =>
  month.padStart(2, value);

export const formatDateUS = date => {
  if (!date) return '';
  const [day, month, year] = date.split('/');

  return `${year}-${month}-${day}`;
};

export const formatDateInstance = date => {
  const [day, month, year] = date.split('/');

  return new Date(Number(year), Number(month) - 1, Number(day));
};
