import { QueryFunctionContext, useQueries } from '@tanstack/react-query';
import axios from 'axios';

import { useToast } from '../../../../../context/toast/useToast';
import { RegraValorAjuste } from '../../../../../utils/functions';
import {
  CorretorasSmarttIRAtivasOperacoesResponse,
  NotasCorretagemSmarttIRPorIdUsuarioFiltrosResponse,
  SmarttIROperations,
  SmarttIROperationsFilter,
  SubTipoInstrumentosSmarttIRAtivasOperacoesResponse,
  TipoNegocioSmarttIRResponse,
} from '../types';

export const getOperationsSmarttIRData = async ({
  queryKey,
  signal,
}: QueryFunctionContext) => {
  const filterValue = queryKey[4];

  const { data } =
    await axios.post<NotasCorretagemSmarttIRPorIdUsuarioFiltrosResponse>(
      `${process.env.REACT_APP_API_OPERACOES}/NotaCorretagem/v3/GetNotasCorretagemPorIdUsuarioFiltros`,
      filterValue,
      { signal },
    );

  const result = {
    operations: [],
    quantidade: data.quantidade,
  } as SmarttIROperations;

  data.dados.forEach(nota => {
    const precoAjusteFormat = RegraValorAjuste(
      nota.negociacaoMercado,
      nota.pontosPrecoAjuste,
      nota.valorPrecoAjuste,
    );

    const [pregaoDate] = nota.dataPregao.split(' ');

    const notaFormatted = {
      corretora: nota.corretora || 'Corretora não identificada',
      pregao: pregaoDate || '--/--/--',
      tipo_ativo: nota.nomeSubTipoInstrumento || 'Tipo Ativo não Identificado',
      ticker: nota.nomeInstrumento || 'Ticker não identificado',
      qtde: nota.quantidade || 0,
      preco_ajuste: `R$ ${precoAjusteFormat}`,
      custos: !!nota.custoCalculado ? `R$ ${nota.custoCalculado}` : '0.00',
      tipo_neg: nota.nomeTipoNegocio || 'Não identificado',
      operacao: !!nota.compraVenda
        ? nota.compraVenda === 'V'
          ? 'Venda'
          : 'Compra'
        : 'Não identificado',
      dc: nota.debitoCredito || '-',
    };

    result.operations.push(notaFormatted);
  });

  return result;
};

const getCorretorasSmarttIR = async ({
  queryKey,
  signal,
}: QueryFunctionContext) => {
  const idUser = queryKey[4];

  const { data } = await axios.get<CorretorasSmarttIRAtivasOperacoesResponse[]>(
    `${process.env.REACT_APP_API_OPERACOES}/NotaCorretagem/v1/GetCorretorasAtivasOperacoes/${idUser}`,
    { signal },
  );

  return data.map(item => {
    if (item !== null) {
      return { label: item.nome, value: item.idCorretora };
    }
  });
};

const getSubTipoInstrumentosSmarttIR = async ({
  queryKey,
  signal,
}: QueryFunctionContext) => {
  const idUser = queryKey[4];

  const { data } = await axios.get<
    SubTipoInstrumentosSmarttIRAtivasOperacoesResponse[]
  >(
    `${process.env.REACT_APP_API_OPERACOES}/NotaCorretagem/v1/GetSubTipoInstrumentosAtivasOperacoes/${idUser}`,
    { signal },
  );

  return data.map(item => ({
    label: item.nome,
    value: item.idSubTipoInstrumento,
  }));
};

const getTipoNegocioSmarttIR = async ({ signal }: QueryFunctionContext) => {
  const { data } = await axios.get<TipoNegocioSmarttIRResponse[]>(
    `${process.env.REACT_APP_API_OPERACOES}/TipoNegocio`,
    { signal },
  );
  return data.map(item => ({
    label: item.nome,
    value: item.idTipoNegocio,
  }));
};

export function useSmarttIROperations(
  smarttIRFilter: SmarttIROperationsFilter,
) {
  const { showToast } = useToast();

  return useQueries({
    queries: [
      {
        queryKey: [
          'operacoes',
          'notaCorretagem',
          'v3',
          'notaCorretagemUsuarioId',
          smarttIRFilter,
        ],
        queryFn: getOperationsSmarttIRData,
        enabled: !!smarttIRFilter.idUsuario,
        onError: () => showToast(),
        staleTime: 1000 * 60,
        keepPreviousData: true,
      },
      {
        queryKey: [
          'operacoes',
          'notaCorretagem',
          'v1',
          'corretorasAtivas',
          smarttIRFilter.idUsuario,
        ],
        queryFn: getCorretorasSmarttIR,
        onError: () => showToast(),
        enabled: !!smarttIRFilter.idUsuario,
      },
      {
        queryKey: [
          'operacoes',
          'notaCorretagem',
          'v1',
          'subTipoInstrumentosAtivas',
          smarttIRFilter.idUsuario,
        ],
        queryFn: getSubTipoInstrumentosSmarttIR,
        onError: () => showToast(),
        enabled: !!smarttIRFilter.idUsuario,
      },
      {
        queryKey: ['operacoes', 'tipoNegocio'],
        queryFn: getTipoNegocioSmarttIR,
        onError: () => showToast(),
      },
    ],
  });
}
