import { DataTable } from 'primereact/datatable';
import { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { pxToRem } from '../../../../utils/functions/styles';

export const DataTableWrapper = styled.section`
  margin-top: 2rem;
`;

type DataTableContainerProps = {
  children: ReactNode;
};

export const DataTableContainer = styled(DataTable)<DataTableContainerProps>`
  thead {
    th {
      color: ${({ theme }) => theme['blue-600']} !important;
    }

    .p-sortable-column.p-highlight {
      background-color: #f8f9fa !important;
    }
  }
`;

const STATUS_TAG_COLORS = {
  Aceito: '#53C3C2',
  Pendente: '#4D4FA1',
  Negado: '#EA736A',
} as const;

interface StatusTagProps extends HTMLAttributes<HTMLDivElement> {
  status: keyof typeof STATUS_TAG_COLORS;
}

export const StatusTag = styled.div<StatusTagProps>`
  width: 8rem;
  max-width: ${pxToRem(150, 14)};
  padding: 0.5rem;
  font-size: 12px;
  background-color: ${({ status }) => STATUS_TAG_COLORS[status]};
  color: ${({ theme }) => theme.white};
  border-radius: 3px;
  border-color: ${({ status }) => STATUS_TAG_COLORS[status]} !important;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
`;
