import { Button } from '../../Button';
import * as S from './styles';

export const ConfirmacaoBaseDialog = ({
  header = 'Atenção!',
  textoBotaoEsquerda = 'Cancelar',
  textoBotaoDireita = 'Aceitar',
  onClickBotaoEsquerda,
  onClickBotaoDireita,
  desabilitaBotaoEsquerda,
  desabilitaBotaoDireita,
  temCamposObrigatorios,
  children,
  ...modalProps
}) => {
  const acaoBotaoEsquerda = () => {
    onClickBotaoEsquerda?.();
  };

  const acaoBotaoDireita = () => {
    onClickBotaoDireita?.();
  };

  const renderFooter = (
    <S.DialogFooter>
      {temCamposObrigatorios && <span>*Preenchimento obrigatório</span>}
      <div>
        <Button
          label={textoBotaoEsquerda}
          bdiType="secondary"
          onClick={acaoBotaoEsquerda}
          disabled={desabilitaBotaoEsquerda}
        />
        <Button
          label={textoBotaoDireita}
          onClick={acaoBotaoDireita}
          disabled={desabilitaBotaoDireita}
        />
      </div>
    </S.DialogFooter>
  );

  return (
    <S.DialogContainer header={header} footer={renderFooter} {...modalProps}>
      {children}
    </S.DialogContainer>
  );
};
