import styled from 'styled-components';

import { devices, pxToRem } from '../../utils/functions/styles';

export const Box50 = styled.div`
  display: flex;
  gap: 1rem;

  div {
    width: 100%;
    border: 1px solid rgba(83, 195, 194, 0.4);
    border-radius: 4px;

    input {
      width: 100% !important;
    }

    span {
      i {
        cursor: pointer;
      }
    }

    label {
      display: block;
    }

    div {
      border: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      margin: 1.5rem 0;
      width: 100%;

      div {
        margin: 0 0 0 1rem;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:first-child {
          margin: 0;
        }

        input {
          width: 100% !important;
        }
      }
    }

    div.headerResumoNegocios {
      background-color: rgba(150, 183, 199, 0.1);
      border-radius: 3px;
      margin: 0;
      margin-bottom: ${pxToRem(14)};
      padding: ${pxToRem(14)};
      border-bottom: 1px solid rgba(83, 195, 194, 0.42);
    }
  }

  table {
    box-sizing: border-box;
    padding-inline: 0.7rem;
    margin: 0;
    width: 100%;

    tr {
      width: 100%;

      td:not(:first-child) {
        text-align: right;
      }
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;

    div div {
      margin: 1rem 0;
      flex-direction: column;

      div {
        margin: 1rem 0;

        &:first-child {
          margin: 1rem 0;
        }
      }
    }
  }
`;

export const Box100 = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.4);
  border-radius: 4px;
  background-color: rgba(150, 183, 199, 0.1);

  p.heading {
    color: #112c50 !important;
    font-size: 1rem !important;
  }

  &.cabecalho {
    @media ${devices.tablet} {
      display: flex;
    }

    div {
      @media ${devices.tablet} {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      p {
        @media ${devices.tablet} {
          width: 100%;
        }

        &:last-child {
          @media ${devices.tablet} {
            display: none;
          }
        }
      }
    }
  }

  @media ${devices.tablet} {
    .div1Bmf,
    .div2Bmf {
      gap: 2rem;
      min-width: ${pxToRem(150)};

      p {
        min-width: ${pxToRem(100)};

        &:last-child {
          display: none;
        }
      }
    }
  }

  div {
    border-top: 1px solid rgba(83, 195, 194, 0.4);
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);

    @media ${devices.tablet} {
      justify-content: initial !important;
    }

    &:first-child {
      border-top: 0;
      background: transparent;
    }

    button {
      margin: 1rem;
    }

    p {
      margin: 1rem 0 1rem 1rem;
      position: relative;
      width: 14%;
      text-align: center;

      &:last-child {
        width: 2%;

        ul {
          margin: 0;
          padding: 0;
          position: absolute;
          right: 0;
          top: 100%;
          box-shadow: 1px 2px 5px rgba(50, 50, 50, 0.2);
          border-radius: 3px;
          z-index: 100;

          li {
            list-style: none;
            cursor: pointer;
            padding: 0.5rem 2rem 0.5rem 0.5rem;
          }
        }
      }

      i {
        font-size: 2rem;
        cursor: pointer;
      }

      button {
        margin: 0;
      }

      input {
        text-align: center;
        width: 100% !important;
      }
    }
  }
`;

export const ContainerDialog = styled.div`
  width: 100%;

  > div:first-of-type {
    @media screen and (min-width: 1300px) {
      width: 1400px;
      padding-inline: 1rem;
    }

    h1 {
      margin-bottom: 1.5rem;
    }
  }
`;

export const OperacoesBfmContainer = styled.div`
  overflow-x: scroll;
`;
