import axios from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useRef, useState } from 'react';

import { useToast } from '../../context/toast/useToast';
import history from '../../service/history';
import { CENTRAL_AJUDA_IMPORTACAO_NOTAS_LINK } from '../../utils/linksCentralAjuda';
import Usuario from '../Usuario';
import * as S from './styles';

export const ImportacaoNotas = () => {
  const toast = useRef(null);
  const [retornoStatus, setRetornoStatus] = useState('');
  const [retornoStatusErro, setRetornoStatusErro] = useState('');
  const [statusBarra, setStatusBarra] = useState('determinate');
  const [sucessoDialog, setSucessoDialog] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [clearUpload, setClearUpload] = useState(null);
  const [usuario, setUsuario] = useState({});
  const { showToast } = useToast();

  useEffect(() => {
    setUsuario(Usuario);
  }, []);

  useEffect(() => {
    setStatusBarra('determinate');

    if (retornoStatus == 200) {
      setSucessoDialog(true);
    }
  }, [retornoStatus]);

  useEffect(() => {
    if (retornoStatusErro != 200 && retornoStatusErro != '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error!',
        detail: 'Erro ao fazer o upload',
        life: 10000,
      });
    }
  }, [retornoStatusErro]);

  const abrirHistorico = () => {
    setSucessoDialog(false);
    toast.current.clear();
    history.push('/HistoricoNotas');
  };

  const abrirImportacao = () => {
    setSucessoDialog(false);
    toast.current.clear();
    setStatusBarra('determinate');
    setRetornoStatus('');
    setRetornoStatusErro('');
    emptyTemplate();
    setTotalSize(0);
    clearUpload.clear();
    history.push('/ImportacaoNotas');
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const fechaDialogFooter = (
    <>
      <Button
        label="Histórico de notas"
        className="p-button-outlined p-button-info p-mb-2 fontBotaoCancelar espacamentoBtnCancelar"
        onClick={abrirHistorico}
      />
      <Button
        label="Importar notas"
        className="p-button-severities p-button-primary p-mb-2"
        onClick={abrirImportacao}
      />
    </>
  );

  const fecharDialog = () => {
    setSucessoDialog(false);
  };

  const invoiceUploadHandler = async ({ files }) => {
    setStatusBarra('indeterminate');

    let idUsuario = Usuario.id;
    let tipoUsuario = 'bdi';
    let extensao = 'pdf';

    files.forEach(file => {
      const { name } = file;

      axios
        .get(
          `${process.env.REACT_APP_API_AUTORIZAS3}/AutorizeS3/GetAutorizateS3Tolken/${idUsuario}/${tipoUsuario}/${extensao}?fileName=${name}`,
        )
        .then(res => {
          const url = res.headers['url'];

          const options = {
            headers: {
              'Content-Type': '',
            },
          };

          axios
            .put(url, file, options)
            .then(res => {
              setRetornoStatus(res.status);
              setRetornoStatusErro(res.status);
            })
            .catch(error => {
              console.error(error);
              showToast();
            });
        })
        .catch(error => {
          console.error(error);
          showToast();
        });
    });
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const itemTemplate = (file, props) => {
    return (
      <S.ItemTemplate>
        <div className="p-d-flex p-ai-center">
          <span>{file.name}</span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="botaoTamanhoArquivo"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-warning p-ml-auto botaoApagar"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </S.ItemTemplate>
    );
  };

  const emptyTemplate = () => {
    return (
      <S.EmptyTemplate className="p-d-flex p-ai-center p-dir-col">
        <p className="p-m-0 alinhamentoTextoImportacao">
          <i
            className="pi pi-fw pi-icon-importacaoAzul5"
            style={{
              fontSize: '15px',
            }}
          />
          <strong>Arraste o arquivo</strong> para importar.
        </p>
      </S.EmptyTemplate>
    );
  };

  const chooseOptions = {
    icon: 'pi',
    iconOnly: false,
    className: 'p-button-default p-mr-2 p-mb-2 fontBotao',
  };
  const uploadOptions = {
    icon: 'pi',
    iconOnly: false,
    className: 'p-button-default p-mr-2 p-mb-2 fontBotao2',
  };
  const cancelOptions = {
    icon: 'pi',
    iconOnly: false,
    className:
      'p-button-outlined p-button-info p-mr-2 p-mb-2 btnCancelarProventos',
  };

  return (
    <S.Container className="p-grid datatable-style-demo">
      <div className="p-col-12 p-md-12">
        <div className="card">
          <div>
            <Toast ref={toast}></Toast>
            <Tooltip
              target=".custom-cancel-btn"
              content="Clear"
              position="bottom"
            />
            <h3 className="titulo2">
              <b>Para calcular seu IR,</b> envie os PDFs das suas notas de
              corretagem
              <a
                href={CENTRAL_AJUDA_IMPORTACAO_NOTAS_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  icon="pi pi-question-circle"
                  style={{ top: '0px' }}
                  className="p-button-rounded p-button-text colorIcon"
                  tooltip="Importação de notas"
                />
              </a>
            </h3>
            <p className="subtitulo">
              As notas devem estar no padrão SINACOR e você pode importar até 5
              arquivos por vez, desde que estejam sem senha e contenham até 50
              páginas ou 3 MB.
            </p>

            <div>
              <ProgressBar mode={statusBarra} />
              <FileUpload
                name="invoice"
                ref={ref => setClearUpload(ref)}
                accept="application/pdf"
                customUpload={true}
                uploadHandler={invoiceUploadHandler}
                mode="advanced"
                multiple
                chooseLabel="Adicionar"
                uploadLabel="Enviar"
                cancelLabel="Cancelar"
                chooseOptions={chooseOptions}
                uploadOptions={uploadOptions}
                cancelOptions={cancelOptions}
                itemTemplate={itemTemplate}
                maxFileSize={3000000}
                invalidFileSizeMessageSummary="Arquivo maior que 3 mb"
                invalidFileSizeMessageDetail=""
                emptyTemplate={emptyTemplate}
                onClear={onTemplateClear}
              />
            </div>
          </div>
          <Dialog
            visible={sucessoDialog}
            style={{
              maxWidth: '600px',
              margin: '1rem',
            }}
            header="Notas importadas com sucesso!"
            blockScroll={false}
            className="p-fluid"
            footer={fechaDialogFooter}
            onHide={fecharDialog}
            modal
          >
            <div className="p-formgrid p-grid">
              <div className="p-field p-col">
                <hr />
                <br />
                <br />
                <p>
                  Clique em “importar notas” para adicionar novos arquivos ou
                  acesse seu histórico de notas para visualizar todas as
                  importações.
                </p>
              </div>
            </div>
            <br />
            <hr />
          </Dialog>
        </div>
      </div>
    </S.Container>
  );
};
