import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { useToast } from '../../../../../context/toast/useToast';
import { formatFilterData } from '../helpers';
import {
  DesdobramentoAgrupamentoPorFiltroResponse,
  FilterEventsObj,
} from '../types';

async function filterEvents(filterEventsObj: FilterEventsObj) {
  const abortController = new AbortController();
  const signal = abortController.signal;

  const { data } = await axios.post<
    DesdobramentoAgrupamentoPorFiltroResponse[]
  >(
    `${process.env.REACT_APP_API_EVENTOS}/DesdobramentoGrupamento/listDesdobramentoAgrupamentoPorFiltro`,
    filterEventsObj,
    { signal },
  );

  return formatFilterData(data);
}

export function useFilterEventMutation(userId?: string) {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation(filterEvents, {
    onError: () => showToast(),
    onSuccess: data =>
      queryClient.setQueryData(
        ['eventos', 'DesdobramentoGrupamento', userId],
        data,
      ),
  });
}
