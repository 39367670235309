import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';

import { GreenBorderBox } from '../../../../../styles/elements/GreenBorderBox';
import * as S from './styles';

export function AlienacoesSkeleton() {
  const bodyTemplate = () => {
    return <Skeleton></Skeleton>;
  };

  return (
    <GreenBorderBox>
      <S.Container>
        <div>
          <div>
            <Skeleton width={'200px'} height={'25px'} />
            <Skeleton width={'250px'} height={'25px'} />
          </div>

          <div>
            <Skeleton size={'100px'} shape="circle" />
            <Skeleton width={'200px'} height={'21px'} />
          </div>
        </div>

        <S.TabelaAlienacoes
          value={[1, 2, 3, 4, 5, 6]}
          responsiveLayout="scroll"
          emptyMessage="Lista vazia."
          size="small"
        >
          <Column
            field="descricao"
            header="Descrição"
            body={bodyTemplate}
          ></Column>
          <Column
            field="resultado"
            header="Resultado"
            body={bodyTemplate}
          ></Column>
          <Column
            field="aliquota"
            header="Alíquota"
            body={bodyTemplate}
          ></Column>
          <Column field="imposto" header="Imposto" body={bodyTemplate}></Column>
        </S.TabelaAlienacoes>
      </S.Container>
    </GreenBorderBox>
  );
}
