import { StyledButton } from './styles';

const getClassName = bdiType => {
  if (bdiType === 'icon') return 'p-button-rounded p-button-text';

  return bdiType;
};

export const Button = props => {
  const { bdiType, fullWidth, ...buttonProps } = props;

  return (
    <StyledButton
      $bdiType={bdiType}
      $fullWidth={fullWidth}
      className={getClassName(bdiType)}
      {...buttonProps}
    />
  );
};
