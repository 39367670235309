import { QueryFunctionContext, useQueries } from '@tanstack/react-query';
import axios from 'axios';

import { useToast } from '../../../context/toast/useToast';
import {
  getStatusNotaCorretagemByFilter,
  getStatusNotaCorretagemByUserUploadV1,
} from '../../../service/ApiStatusProcessamento/StatusNotaCorretagem';
import '../../../service/ApiStatusProcessamento/StatusNotaCorretagem';
import {
  NotaCorretagemFilter,
  StatusNotaCorretagem,
} from '../../../service/ApiStatusProcessamento/types';
import { Corretora, StatusProcessamento } from '../types';

async function getNotasCorretagem({ queryKey, signal }: QueryFunctionContext) {
  const userId = queryKey[1] as string;
  const notaCorretagemFilter = queryKey[2] as NotaCorretagemFilter;
  let response;

  if (Object.keys(notaCorretagemFilter).length <= 0) {
    response = await axios.get<StatusNotaCorretagem>(
      getStatusNotaCorretagemByUserUploadV1(userId, '7', '0', '9999'),
      { signal },
    );
  } else {
    response = await axios.post<StatusNotaCorretagem>(
      getStatusNotaCorretagemByFilter(),
      notaCorretagemFilter,
      { signal },
    );
  }

  return response.data.statusList.map(notaCorretagem => {
    return {
      idStatusProcessamento: notaCorretagem.idStatusProcessamento,
      idNotaCorretagem: notaCorretagem?.nota?.idNotaCorretagem || 0,
      idUpload: notaCorretagem.idUpload,
      data: notaCorretagem.data,
      arquivoNovo:
        notaCorretagem?.nota?.idNotaCorretagemArquivoNavigation.arquivoNovo ||
        'Cadastro Manual',
      detalhe: notaCorretagem.detalhe.trim(),
      corretora: notaCorretagem?.nota?.idCorretoraNavigation.apelido || '',
      ndanota: notaCorretagem?.nota?.numeroNotaCorretora || '',
      pregao: notaCorretagem?.nota?.dataPregao || '',
      idTipoNota: notaCorretagem?.nota?.idTipoNotaNavigation.nome || '',
      status: notaCorretagem.status,
      cpf: notaCorretagem?.nota?.cpfNota || '',
      cnpjNota: notaCorretagem?.nota?.cnpjNota || '',
      ativo: notaCorretagem.ativo,
      notaAtiva: notaCorretagem?.nota?.notaAtiva || 1,
    };
  });
}

async function getCorretoras({ signal }: QueryFunctionContext) {
  const { data } = await axios.get<Corretora[]>(
    `${process.env.REACT_APP_API_OPERACOES}/Corretoras`,
    { signal },
  );

  return data.map(corretora => ({
    name: corretora.apelido,
    code: corretora.idCorretora,
  }));
}

async function getStatusProcessamento({ signal }: QueryFunctionContext) {
  const { data } = await axios.get<StatusProcessamento[]>(
    `${process.env.REACT_APP_API_OPERACOES}/StatusDoProcesso`,
    { signal },
  );

  const result = data.map(status => ({
    name: status.nome,
    code: status.idStatusProcesso,
  }));

  const validCodes = [2, 3, 4, 5];

  return result.filter(
    status => !!status && !!status.code && validCodes.includes(status.code),
  );
}

export function useHistoricoNotasData(
  idUser: string,
  notaCorretagemFilter: NotaCorretagemFilter,
) {
  const { showToast } = useToast();

  return useQueries({
    queries: [
      {
        queryKey: ['notasCorretagem', idUser, notaCorretagemFilter],
        queryFn: getNotasCorretagem,
        onError: () => showToast(),
      },
      {
        queryKey: ['corretoras'],
        queryFn: getCorretoras,
        onError: () => showToast(),
      },
      {
        queryKey: ['tipoNotas'],
        queryFn: getStatusProcessamento,
        onError: () => showToast(),
      },
    ],
  });
}
