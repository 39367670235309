import axios from 'axios';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
//#region Import Componentes
import { Menu } from 'primereact/menu';
import { useCallback, useEffect, useRef, useState } from 'react';

import { BaseModule } from '../../../../components/BaseModule';
import { CADASTRO_IPO_SUBSCRICAO } from '../../../../components/BaseModule/constants';
import Usuario from '../../../../components/Usuario';
import {
  BROKERAGE_FIELD_ERROR,
  CHANGED_ITEM_SUCCESS,
  DATE_FIELD_ERROR,
  MARKET_TYPE_FIELD_ERROR,
  NO_RECORD_OPTIONS,
  PRICE_FIELD_ERROR,
  QUANTITY_FIELD_ERROR,
  TICKER_FIELD_ERROR,
  TYPE_FIELD_ERROR,
} from '../../../../context/toast/constants';
import { useToast } from '../../../../context/toast/useToast';
//#endregion
import { useEventListener } from '../../../../hooks/useEventListener';
import { formatCurrency } from '../../../../utils/functions';
//#region Imports Styles Css
import * as S from './styles';

//#endregion

const getDataFormatada = data => {
  if (!data) return;

  const dataFormatada = new Date(data).toISOString().split('T')[0];

  return dataFormatada || '--/--/--';
};

const valorOuNaoIdentificado = valor => valor || 'Não Identificado';

const valorOuZero = valor => valor || 0;

const TIPO_IPO = {
  IPO: 'ipo',
  Subscrição: 'sub',
};

export function CadastroIPOSubscricao() {
  //#region  Variaveis de Alto Escopo
  const dropdownRef = useRef(null);
  const menu = useRef(null);
  //#endregion

  //#region   Estados dos Componentes Visuais
  const [itensIposSubsSalvos, SetItensIposSubsSalvos] = useState([]);

  const [modalCadastroAberto, setModalCadastroAberto] = useState(false);
  const [modalEdicaoAberto, setModalEdicaoAberto] = useState(false);
  const [modalExcluirRegistro, setModalExcluirRegistro] = useState(false);

  const [dropdownEstaAberto, setDropdownEstaAberto] = useState(false);
  const [linhaSelecionada, setLinhaSelecionada] = useState(null);

  const [dataCadastro, SetDataCadastro] = useState('');
  const [quantidade, SetQuantidade] = useState(0);
  const [precoMedio, SetPrecoMedio] = useState(formatCurrency('0'));

  const [tipoIpoSub, SetTipoIpoSub] = useState(0);
  const [tiposIpoSub, SetTiposIpoSub] = useState([]);
  const [tiposIpoSubNome, SetTiposIpoSubNome] = useState([]);

  const [corretora, SetCorretora] = useState(0);
  const [corretoras, SetCorretoras] = useState([]);
  const [corretoraNome, SetCorretoraNome] = useState('');

  const [tipoMercado, SetTipoMercado] = useState(0);
  const [tipoMercados, SetTipoMercados] = useState([]);
  const [tipoMercadoNome, SetTipoMercadoNome] = useState(0);

  const [instrumento, setinstrumento] = useState('');
  const [listainstrumentos, setListainstrumentos] = useState([]);
  const [instrumentoNome, setinstrumentoNome] = useState('');

  const [opcoesConfirmacao, SetOpcoesConfirmacao] = useState([]);

  const { showToast } = useToast();
  //#endregion

  //#region  Métodos de Consulta Dados
  const getIposSubscricoes = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_EVENTOS}/IpoSubscricao/v1/ListIpoSubscricaoRelationship/` +
          Usuario.id,
      );
      const dropdownOptions = [];
      if (data) {
        data.forEach(item => {
          if (item) {
            let dataFormat = null;
            if (item.dataEvento) {
              dataFormat = item.dataEvento;
            }

            let precoMedio = `R$ ${item.preco === null ? '0.00' : item.preco}`;

            let opcao = {
              data: dataFormat || '--/--/--',
              corretora: valorOuNaoIdentificado(
                item.idCorretoraNavigation?.nome,
              ),
              tipo: valorOuNaoIdentificado(
                item.idTipoEventoNavigation?.nomeEvento,
              ),
              tipo_mercado: valorOuNaoIdentificado(
                item.idTipoMercadoNavigation?.nome,
              ),
              ticker: valorOuNaoIdentificado(
                item.idInstrumentoNavigation?.ticker,
              ),
              qtde: valorOuZero(item.quantidade),
              preco_medio: precoMedio,
              corretoraId: item.idCorretora,
              instrumentoId: item.idInstrumento,
              id: item.idIpoSubscricao,
              tipoeventoId: item.idTipoEvento,
              tipomercadoId: item.idTipoMercado,
              usuarioId: item.idUsuario,
              preco: item.preco,
              dataOriginal: item.dataEvento,
            };
            dropdownOptions.push(opcao);
          }
        });
      }
      SetItensIposSubsSalvos(dropdownOptions);
    } catch (error) {
      console.error(error);
      showToast();
    }
  };

  const GetTiposEventos = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_OPERACOES}/TipoEvento`,
      );
      const dropdownOptions = [];
      if (data !== null) {
        data.forEach(item => {
          let opcao = { label: item.nomeEvento, value: item.idTipoEvento };
          if (opcao.label === 'Subscrição' || opcao.label === 'IPO') {
            dropdownOptions.push(opcao);
          }
        });
      }
      SetTiposIpoSub(dropdownOptions);
    } catch (error) {
      console.error(error);
      showToast();
    }
  };

  const GetCorretorasOperacoes = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_OPERACOES}/Corretoras`,
      );
      const dropdownOptions = [];
      if (data !== null) {
        data.forEach(item => {
          let opcao = { label: item.nome, value: item.idCorretora };
          dropdownOptions.push(opcao);
        });
      }
      SetCorretoras(dropdownOptions);
    } catch (error) {
      console.error(error);
      showToast();
    }
  };

  const GetTipoMercadosOperacoes = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_OPERACOES}/TipoMercado`,
      );
      const dropdownOptions = [];
      if (data !== null) {
        data.forEach(item => {
          let opcao = { label: item.nome, value: item.idTipoMercado };
          dropdownOptions.push(opcao);
        });
      }
      SetTipoMercados(dropdownOptions);
    } catch (error) {
      console.error(error);
      showToast();
    }
  };

  const GetInstrumentosOperacoes = async key => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_EVENTOS}/IpoSubscricao/v1/ListInstrumentosIpoSubscricao/${Usuario.id}/${TIPO_IPO[key]}`,
      );

      const dropdownOptions = [];
      if (data !== null) {
        data.map(item => {
          let opcao = { label: item.ticker, value: item.idInstrumento };
          dropdownOptions.push(opcao);
        });
      }
      setListainstrumentos(dropdownOptions);
    } catch (error) {
      console.error(error);
      showToast();
    }
  };
  //#endregion

  //#region Métodos para manipulação
  const SetItemListaParaGravacaoIPOSub = () => {
    if (
      !corretoraNome ||
      !dataCadastro ||
      !tiposIpoSubNome ||
      !tipoMercadoNome ||
      !instrumentoNome ||
      !quantidade ||
      !precoMedio
    ) {
      !corretoraNome && showToast(BROKERAGE_FIELD_ERROR);
      !dataCadastro && showToast(DATE_FIELD_ERROR);
      !tiposIpoSubNome && showToast(TYPE_FIELD_ERROR);
      !tipoMercadoNome && showToast(MARKET_TYPE_FIELD_ERROR);
      !instrumentoNome && showToast(TICKER_FIELD_ERROR);
      !quantidade && showToast(QUANTITY_FIELD_ERROR);
      !precoMedio && showToast(PRICE_FIELD_ERROR);
    } else {
      const dados = {
        data: getDataFormatada(dataCadastro),
        corretora: valorOuNaoIdentificado(corretoraNome),
        corretoraId: valorOuZero(corretora),
        tipo: valorOuNaoIdentificado(tiposIpoSubNome),
        TipoEventoId: valorOuZero(tipoIpoSub),
        tipo_de_mercado: valorOuNaoIdentificado(tipoMercadoNome),
        tipoMercadoId: valorOuZero(tipoMercado),
        ticker: valorOuNaoIdentificado(instrumentoNome),
        InstrumentoId: valorOuZero(instrumento),
        qtde: valorOuZero(quantidade),
        preco_medio: valorOuZero(precoMedio),
      };

      const newLista = [...opcoesConfirmacao, dados];

      SetOpcoesConfirmacao(newLista);
    }
  };

  const GravaListaIpoSubscricao = async () => {
    if (opcoesConfirmacao.length > 0) {
      let listaParaGravacaoIpo = [];
      let listaParaGravacaoSubs = [];
      opcoesConfirmacao.forEach(item => {
        let unidade = {
          idInstrumento: item.InstrumentoId,
          idUsuario: Usuario.id,
          dataEvento: item.data,
          idTipoEvento: item.TipoEventoId,
          ticker: item.ticker,
          quantidade: item.qtde,
          preco: parseFloat(`${item.preco_medio}`.replace(',', '.')),
          idCorretora: item.corretoraId,
          idTipoMercado: item.tipoMercadoId,
        };

        if (item.tipo === 'Subscrição') {
          listaParaGravacaoSubs.push(unidade);
        }
        if (item.tipo === 'IPO') {
          listaParaGravacaoIpo.push(unidade);
        }
      });

      if (listaParaGravacaoSubs.length > 0) {
        try {
          await axios.post(
            `${process.env.REACT_APP_API_EVENTOS}/Subscricao/CreateListaSubscricao`,
            listaParaGravacaoSubs,
          );
        } catch (error) {
          console.error(error);
          showToast();
        }
      }

      if (listaParaGravacaoIpo.length > 0) {
        try {
          await axios.post(
            `${process.env.REACT_APP_API_EVENTOS}/Ipo/CreateListaIpo`,
            listaParaGravacaoIpo,
          );
        } catch (error) {
          console.error(error);
          showToast();
        }
      }

      setModalCadastroAberto(false);
      LimpaCampos();
      SetOpcoesConfirmacao([]);

      getIposSubscricoes();
    } else {
      showToast(NO_RECORD_OPTIONS);
    }
  };

  function handlePrecoMedio(e) {
    const moedaFormatada = formatCurrency(e.target.value);

    SetPrecoMedio(moedaFormatada);
  }

  const LimpaCampos = () => {
    SetDataCadastro('');
    SetQuantidade(0);
    SetPrecoMedio(0);
    SetTipoIpoSub(0);
    SetCorretora(0);
    SetTipoMercado(0);
    setinstrumento('');
  };

  const DuplicarItem = async () => {
    let listaParaGravacaoIpo = [];
    let listaParaGravacaoSubs = [];
    if (linhaSelecionada) {
      let dados = linhaSelecionada;
      let dataFormat = null;
      let datainicialFormatada = '';

      if (dados.dataOriginal !== null || dados.dataOriginal !== '') {
        dataFormat = new Date(dados.dataOriginal);
        datainicialFormatada = dataFormat.toISOString().split('T')[0];
      }

      let unidade = {
        idInstrumento: dados.instrumentoId,
        idUsuario: Usuario.id,
        dataEvento: datainicialFormatada,
        idTipoEvento: dados.tipoeventoId,
        ticker: dados.ticker,
        quantidade: dados.qtde,
        preco: parseFloat(`${dados.preco}`.replace(',', '.')),
        idCorretora: dados.corretoraId,
        idTipoMercado: dados.tipomercadoId,
      };

      if (dados.tipo === 'Subscrição') {
        listaParaGravacaoSubs.push(unidade);
      }
      if (dados.tipo === 'IPO') {
        listaParaGravacaoIpo.push(unidade);
      }

      if (listaParaGravacaoSubs.length > 0) {
        try {
          await axios.post(
            `${process.env.REACT_APP_API_EVENTOS}/Subscricao/CreateListaSubscricao`,
            listaParaGravacaoSubs,
          );
        } catch (error) {
          console.error(error);
          showToast();
        }
      }

      if (listaParaGravacaoIpo.length > 0) {
        try {
          await axios.post(
            `${process.env.REACT_APP_API_EVENTOS}/Ipo/CreateListaIpo`,
            listaParaGravacaoIpo,
          );
        } catch (error) {
          console.error(error);
          showToast();
        }
      }
      getIposSubscricoes();
    }
  };

  const RemoveItem = async () => {
    if (linhaSelecionada) {
      let dados = linhaSelecionada;
      if (dados.tipo === 'Subscrição') {
        try {
          await axios.delete(
            `${process.env.REACT_APP_API_EVENTOS}/Subscricao/delete?idSubscricao=` +
              dados.id,
          );
        } catch (error) {
          console.error(error);
          showToast();
        }
      }
      if (dados.tipo === 'IPO') {
        try {
          await axios.delete(
            `${process.env.REACT_APP_API_EVENTOS}/Ipo/delete?idIPO=` + dados.id,
          );
        } catch (error) {
          console.error(error);
          showToast();
        }
      }
      getIposSubscricoes();
    }
  };

  const EditarItem = async () => {
    if (linhaSelecionada) {
      if (
        !corretora ||
        !dataCadastro ||
        !tipoMercado ||
        !instrumento ||
        !quantidade ||
        !precoMedio
      ) {
        !corretora && showToast(BROKERAGE_FIELD_ERROR);
        !dataCadastro && showToast(DATE_FIELD_ERROR);
        !tipoMercado && showToast(MARKET_TYPE_FIELD_ERROR);
        !instrumentoNome && showToast(TICKER_FIELD_ERROR);
        !quantidade && showToast(QUANTITY_FIELD_ERROR);
        !precoMedio && showToast(PRICE_FIELD_ERROR);
      } else {
        const dados = linhaSelecionada;

        const unidadeDefault = {
          idUsuario: Usuario.id,
          dataEvento: getDataFormatada(dataCadastro),
          idCorretora: valorOuZero(corretora),
          idInstrumento: valorOuZero(instrumento),
          idTipoEvento: valorOuZero(tipoIpoSub),
          ticker: valorOuNaoIdentificado(instrumentoNome),
          quantidade: valorOuZero(quantidade),
          preco: !precoMedio
            ? 0
            : parseFloat(`${precoMedio}`.replace(',', '.')),
          idTipoMercado: valorOuZero(tipoMercado),
          idInstrumentoNavigation: null,
          idTipoEventoNavigation: null,
          idCorretoraNavigation: null,
          idTipoMercadoNavigation: null,
        };

        if (dados.tipo === 'Subscrição') {
          try {
            await axios.put(
              `${process.env.REACT_APP_API_EVENTOS}/Subscricao/update`,
              { ...unidadeDefault, idSubscricao: dados.id },
            );
          } catch (error) {
            console.error(error);
            showToast();
          }
        }

        if (dados.tipo === 'IPO') {
          try {
            await axios.put(`${process.env.REACT_APP_API_EVENTOS}/Ipo/update`, {
              ...unidadeDefault,
              idIPO: dados.id,
            });
          } catch (error) {
            console.error(error);
            showToast();
          }
        }

        showToast(CHANGED_ITEM_SUCCESS);

        setTimeout(() => {
          setModalEdicaoAberto(false);
          LimpaCampos();
          getIposSubscricoes();
        }, 3000);
      }
    }
  };
  //#endregion

  //#region  Eventos de Comportamento Componentes Visuais
  const fecharModal = useCallback(event => {
    if (dropdownRef.current?.contains(event.target)) {
      return;
    }

    setDropdownEstaAberto(false);
  }, []);

  useEventListener('click', fecharModal, {
    enabled: dropdownEstaAberto,
  });
  //#endregion

  //#region  Use Efect para execução inicial
  useEffect(() => {
    getIposSubscricoes();
    GetTiposEventos();
    GetCorretorasOperacoes();
    GetTipoMercadosOperacoes();
  }, []);

  //#endregion

  const overlayMenuItems = [
    // {
    //   label: 'Duplicar',
    //   command: () => {
    //     DuplicarItem();
    //   },
    // },
    // {
    //   label: 'Editar',
    //   command: () => {
    //     SetDataCadastro(new Date(linhaSelecionada.dataOriginal));
    //     SetCorretora(linhaSelecionada.corretoraId);
    //     SetTipoIpoSub(linhaSelecionada.tipoeventoId);
    //     SetTipoMercado(linhaSelecionada.tipomercadoId);
    //     setinstrumento(linhaSelecionada.instrumentoId);
    //     SetQuantidade(linhaSelecionada.qtde);
    //     SetPrecoMedio(linhaSelecionada.preco);
    //     setModalEdicaoAberto(true);
    //   },
    // },
    {
      label: 'Excluir',
      command: () => {
        setModalExcluirRegistro(true);
      },
    },
  ];

  function alterarLinhaSelecionada(value) {
    //
    setLinhaSelecionada(value);

    //
    //operacoesNotaCorretagem(value);
    //deletarDialogFooter(value);
  }

  function alterarLinhaMostrarDetalhes(rowData, event) {
    alterarLinhaSelecionada(rowData);
    //setModalDetalhesAberto(true);
    //toggleMenu(event);
    //setDropdownEstaAberto(!dropdownEstaAberto);

    if (menu?.current) {
      menu.current.toggle(event);
    }
  }

  const opcoes = rowData => {
    return (
      <>
        <div>
          <Button
            label=""
            icon="pi pi-ellipsis-v"
            className="p-button-severities p-button-danger2 p-mr-2 p-mb-2"
            onClick={event => {
              alterarLinhaMostrarDetalhes(rowData, event);
            }}
            style={{ top: 0, lineHeight: 0 }}
            aria-controls="popup_menu"
            aria-haspopup
            model={overlayMenuItems}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <BaseModule tipo={CADASTRO_IPO_SUBSCRICAO}>
        <section>
          <S.AcoesContainer>
            <Button
              label="Cadastrar IPO ou subscrição"
              onClick={() => setModalCadastroAberto(true)}
            />
            <Menu
              model={overlayMenuItems}
              popup={true}
              ref={menu}
              autoZIndex
              id="popup_menu"
            />
          </S.AcoesContainer>

          <div className="p-grid table-demo">
            <div className="p-col-12">
              <div className="">
                <S.DataTableContainer
                  value={itensIposSubsSalvos}
                  sortMode="single"
                  paginator
                  responsiveLayout="scroll"
                  emptyMessage="Lista vazia."
                  dataKey="id"
                  currentPageReportTemplate="Mostrando de {first} até {last} - Total de {totalRecords} registros"
                  rows={10}
                  selectionMode="single"
                  rowsPerPageOptions={[10, 20, 50]}
                  onSelectionChange={e => setLinhaSelecionada(e.value)}
                  selection={linhaSelecionada}
                  rowHover
                >
                  <Column field="data" header="Data" sortable />
                  <Column field="corretora" header="Corretora" sortable />
                  <Column
                    field="tipo"
                    header="Tipo (Ipo ou Subscrição)"
                    sortable
                  />
                  <Column
                    field="tipo_mercado"
                    header="Tipo de Mercado"
                    sortable
                  />
                  <Column field="ticker" header="Ticker" sortable />
                  <Column field="qtde" header="Qtde" sortable />
                  <Column field="preco_medio" header="Preço médio" sortable />
                  <Column
                    rowEditor={true}
                    body={opcoes}
                    bodyStyle={{ textAlign: 'center' }}
                  />
                </S.DataTableContainer>
              </div>
            </div>
          </div>
        </section>
      </BaseModule>

      <Dialog
        style={{ width: 'min(40rem, 80vw)' }}
        modal
        header="Cadastro de IPO e subscrição"
        visible={modalCadastroAberto}
        onHide={() => setModalCadastroAberto(false)}
      >
        <S.FormContainer
          style={{
            border: '2px solid rgba(83, 195, 194, 0.42)',
            boxSizing: 'border-box',
            padding: '10px',
          }}
        >
          <div className="p-formgrid p-grid alinhamentoGrid">
            <div className="p-field p-col">
              <label htmlFor="tipoipo">Tipo*</label>
              <Dropdown
                id="tipoipo"
                placeholder="Tipo IPO ou Subscrição"
                required
                value={tipoIpoSub}
                onChange={e => {
                  SetTipoIpoSub(e.value);
                  SetTiposIpoSubNome(e.originalEvent.target.textContent);
                  GetInstrumentosOperacoes(e.originalEvent.target.textContent);
                }}
                options={tiposIpoSub}
                style={{ width: '100%' }}
              />
            </div>
            <div className="p-field p-col">
              <label htmlFor="ticker">Ticker*</label>
              <Dropdown
                id="ticker"
                value={instrumento}
                required
                onChange={e => {
                  setinstrumento(e.value);
                  setinstrumentoNome(e.originalEvent.target.textContent);
                }}
                options={listainstrumentos}
                optionLabel="label"
                filter
                placeholder="Selecione o ticker"
                className="campoTicker"
                style={{ width: '100%' }}
                disabled={!tipoIpoSub}
              />
            </div>
          </div>

          <div className="p-formgrid p-grid alinhamentoGrid">
            <div className="p-field p-col">
              <label htmlFor="datacadastro">Data*</label>
              <Calendar
                id="datacadastro"
                value={dataCadastro}
                required
                placeholder="Data"
                style={{ width: '100%' }}
                dateFormat="dd/mm/yy"
                onChange={e => SetDataCadastro(e.value)}
              />
            </div>
            <div className="p-field p-col">
              <label htmlFor="corretora">Corretora*</label>
              <Dropdown
                id="corretora"
                placeholder="Corretora"
                required
                filter
                value={corretora}
                onChange={e => {
                  SetCorretora(e.value);
                  SetCorretoraNome(e.originalEvent.target.textContent);
                }}
                options={corretoras}
                style={{ width: '100%' }}
              />
            </div>
          </div>

          <div className="p-formgrid p-grid alinhamentoGrid">
            <div className="p-field p-col">
              <label htmlFor="precomedio">Preço Médio*</label>
              <InputText
                id="precomedio"
                value={precoMedio}
                placeholder="Preço Médio"
                required
                max={14}
                onChange={handlePrecoMedio}
                style={{ width: '100%' }}
              />
            </div>
            <div className="p-field p-col">
              <label htmlFor="quantidade">Qtde*</label>
              <InputNumber
                id="quantidade"
                placeholder="Quantidade"
                value={quantidade}
                required
                onValueChange={e => SetQuantidade(e.value)}
                style={{ width: '100%' }}
                mode="decimal"
                useGrouping={false}
              />
            </div>
          </div>

          <div className="p-formgrid p-grid alinhamentoGrid">
            <div className="p-field p-col">
              <label htmlFor="tipomercado">Tipo de Mercado*</label>
              <Dropdown
                id="tipomercado"
                placeholder="Tipo Mercados"
                required
                value={tipoMercado}
                onChange={e => {
                  SetTipoMercado(e.value);
                  SetTipoMercadoNome(e.originalEvent.target.textContent);
                }}
                options={tipoMercados}
                style={{ width: '100%' }}
              />
            </div>
          </div>

          <S.ButtonAddContainer>
            <Button
              type="button"
              icon="pi pi-plus"
              onClick={SetItemListaParaGravacaoIPOSub}
              className="botaoAdd"
            />
          </S.ButtonAddContainer>
        </S.FormContainer>

        {opcoesConfirmacao.length > 0 && (
          <S.Section>
            <S.DataTableContainer
              value={opcoesConfirmacao}
              selection={opcoesConfirmacao}
              responsiveLayout="scroll"
              emptyMessage="Lista vazia."
              sortMode="multiple"
              paginator
              dataKey="idOpcoesConfirmacao"
              currentPageReportTemplate="Mostrando de {first} até {last} - Total de {totalRecords} registros"
              rows={10}
              rowsPerPageOptions={[10, 20, 50]}
            >
              <Column field="data" header="Data" />
              <Column field="corretora" header="Corretora" />
              <Column field="tipo" header="Tipo" />
              <Column field="tipo_de_mercado" header="Tipo de mercado" />
              <Column field="ticker" header="Ticker" />
              <Column field="qtde" header="Qtde" />
              <Column field="preco_medio" header="Preço médios" />
            </S.DataTableContainer>
          </S.Section>
        )}

        <br />
        <S.DialogFooter>
          <Button
            type="button"
            label="Voltar"
            className="p-button-text botaoFechar"
            onClick={() => {
              setModalCadastroAberto(false);
              LimpaCampos();
            }}
          />
          <Button
            type="button"
            label="Salvar"
            onClick={() => {
              GravaListaIpoSubscricao();
            }}
          />
        </S.DialogFooter>
      </Dialog>

      <Dialog
        style={{ width: 'min(40rem, 80vw)' }}
        modal
        header="Cadastro de IPO e subscrição"
        visible={modalEdicaoAberto}
        onHide={() => setModalEdicaoAberto(false)}
      >
        <S.FormContainer
          style={{
            border: '2px solid rgba(83, 195, 194, 0.42)',
            boxSizing: 'border-box',
            padding: '10px',
          }}
        >
          <div className="p-formgrid p-grid">
            <div className="p-field p-col">
              <label htmlFor="datacadastro">Data*</label>
              <Calendar
                id="datacadastro"
                value={dataCadastro}
                required
                placeholder="Data"
                style={{ width: '100%' }}
                dateFormat="dd/mm/yy"
                onChange={e => {
                  SetDataCadastro(e.value);
                }}
              />
            </div>
            <div className="p-field p-col">
              <label htmlFor="ticker">Ticker*</label>
              <Dropdown
                id="ticker"
                value={instrumento}
                required
                onChange={e => {
                  setinstrumento(e.value);
                  setinstrumentoNome(e.originalEvent.target.textContent);
                }}
                options={listainstrumentos}
                optionLabel="label"
                filter
                placeholder="Selecione o ticker"
                style={{ width: '100%' }}
              />
            </div>
          </div>

          <div className="p-formgrid p-grid alinhamentoGrid">
            <div className="p-field p-col">
              <label htmlFor="corretora">Corretora*</label>
              <Dropdown
                id="corretora"
                placeholder="Corretora"
                required
                filter
                value={corretora}
                onChange={e => {
                  SetCorretora(e.value);
                }}
                options={corretoras}
                style={{ width: '100%' }}
              />
            </div>
            <div className="p-field p-col">
              <label htmlFor="tipomercado">Tipo de Mercado*</label>
              <Dropdown
                id="tipomercado"
                placeholder="Tipo Mercados"
                required
                value={tipoMercado}
                onChange={e => {
                  SetTipoMercado(e.value);
                  SetTipoMercadoNome(e.originalEvent.target.textContent);
                }}
                options={tipoMercados}
                style={{ width: '100%' }}
              />
            </div>
          </div>

          <div className="p-formgrid p-grid alinhamentoGrid">
            <div className="p-field p-col">
              <label htmlFor="precomedio">Preço Médio*</label>
              <InputText
                id="precomedio"
                value={precoMedio}
                placeholder="Preço Médio"
                required
                max={14}
                onChange={handlePrecoMedio}
                style={{ width: '100%', borderColor: '#112C50' }}
              />
            </div>
            <div className="p-field p-col">
              <label htmlFor="quantidade">Qtde*</label>
              <InputNumber
                id="quantidade"
                placeholder="Quantidade"
                value={quantidade}
                required
                onValueChange={e => SetQuantidade(e.value)}
                mode="decimal"
                useGrouping={false}
                style={{ width: '100%', borderColor: '#112C50' }}
              />
            </div>
          </div>
        </S.FormContainer>

        <br />
        <S.DialogFooter>
          <Button
            type="button"
            label="Voltar"
            className="p-button-outlined p-button-info p-mr-2 p-mb-2 fontBotaoCancelar"
            onClick={() => {
              setModalEdicaoAberto(false);
              LimpaCampos();
            }}
          />
          <Button
            type="button"
            label="Salvar"
            className="p-button-severities p-button-primary p-mr-2 p-mb-2"
            onClick={() => {
              EditarItem();
            }}
          />
        </S.DialogFooter>
      </Dialog>

      <Dialog
        header="Excluir IPO ou subscrição"
        visible={modalExcluirRegistro}
        onHide={() => setModalExcluirRegistro(false)}
        style={{ width: '50rem' }}
      >
        <hr />
        <br />
        <br />
        <p>Deseja excluir a ordem cadastrada?</p>

        <S.DialogFooter>
          <Button
            label="Não"
            className="p-button-outlined p-button-info p-mr-2 p-mb-2 fontBotaoCancelar"
            onClick={() => {
              setModalExcluirRegistro(false);
            }}
          />
          <Button
            label="Sim"
            onClick={() => {
              RemoveItem();
              setModalExcluirRegistro(false);
            }}
            className="p-button-severities p-button-primary p-mr-2 p-mb-2"
          />
        </S.DialogFooter>
        <br />
        <hr />
      </Dialog>
    </>
  );
}
