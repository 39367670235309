import styled from 'styled-components';

export const Container = styled.form`
  display: grid;
  grid-template-columns: minmax(100%, 1fr);
  gap: 1rem;
  align-items: center;

  input {
    width: 100%;
    border-color: rgb(13, 37, 80) !important;
  }

  & > div {
    display: flex;
    flex-direction: column;
  }

  & > div.doubleColumn {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    & > div {
      display: flex;
      flex-direction: column;
    }
  }

  & > div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
    height: 33px;
  }

  button {
    opacity: 1;
    transition: opacity 400ms;
    pointer-events: all;

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed !important;
    }
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);

    & > div:last-child {
      grid-column: 4;
    }
  }
`;
