import { apiRelatorios } from '../api';
import {
  GetPosicoesAbertasPorUsuarioFiltroMultCorretora,
  PosicoesAbertasPorUsuarioFiltroMultCorretoraData,
} from './types';

export const getPosicoesAbertasPorUsuarioFiltroMultCorretora: GetPosicoesAbertasPorUsuarioFiltroMultCorretora =
  ({ idUsuario, filtroData, data, signal }) =>
    apiRelatorios.post<PosicoesAbertasPorUsuarioFiltroMultCorretoraData>(
      `/PosicoesAbertas/v1/GetPosicoesAbertasPorUsuarioFiltroMultCorretora/${idUsuario}/${filtroData}`,
      data,
      { signal },
    );
