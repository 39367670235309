import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import history from '../../../service/history';
import Usuario from '../../Usuario';
import { DialogFooter } from './styles';

export const EmailEnviado = props => {
  const { closeModal } = props;

  const abrirHistorico = () => {
    history.push('/HistoricoNotas');
    closeModal();
  };

  const abrirImportacao = () => {
    history.push('/ImportacaoNotas');
    closeModal();
  };

  return (
    <Dialog
      header="Enviado para o seu e-mail"
      style={{ width: '35rem' }}
      {...props}
    >
      <p>
        O DARF solicitado foi enviado para o seu e-mail cadastrado na plataforma{' '}
        <strong>{Usuario.email}</strong>. Lembre-se de realizar o pagamento
        dentro do prazo!
      </p>
      <DialogFooter>
        <Button
          label="Historico de notas"
          onClick={abrirHistorico}
          className="p-button-outlined p-button-info p-mb-2 fontBotaoCancelar espacamentoBtnCancelar"
        />
        <Button
          label="Importar notas"
          onClick={abrirImportacao}
          className="p-button-severities p-button-primary p-mb-2"
        />
      </DialogFooter>
    </Dialog>
  );
};
