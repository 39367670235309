import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import { useToast } from '../../context/toast/useToast';
import apiRelatorios from '../../service/apiRelatorios';

type UseOperationYearsFilterProps = {
  userId: string;
};

type OperationYears = {
  dados: number[];
};

async function getYears({ queryKey, signal }: QueryFunctionContext) {
  const userId = queryKey[3];
  const { data } = await apiRelatorios.get<OperationYears>(
    `/FiltroBusca/v1/GetAnosDeOperacaoPorUsuario/${userId}`,
    { signal },
  );

  const currentYearObj = {
    name: new Date().getFullYear().toString(),
    code: new Date().getFullYear(),
  };

  const yearsObj = data.dados.map(year => {
    return {
      name: year.toString(),
      code: year,
    };
  });

  const isThereCurrentYear = yearsObj.some(
    year => year.name === currentYearObj.name,
  );

  if (!isThereCurrentYear) yearsObj.push(currentYearObj);

  yearsObj.sort((a, b) => (a.name < b.name ? 1 : -1));

  return yearsObj;
}

export function useOperationYearsFilter({
  userId,
}: UseOperationYearsFilterProps) {
  const { showToast } = useToast();

  return useQuery(
    ['reports', 'searchFilter', 'operationYears', userId],
    getYears,
    {
      onError: () => showToast(),
    },
  );
}
