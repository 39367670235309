import { InputMaskBDI, InputTextBDI } from '@dev_bdi/bdi-front-dspack';
import axios from 'axios';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { useCallback, useEffect, useRef, useState } from 'react';

import Usuario from '../../../../components/Usuario';
import {
  EDIT_ADDRESS_ERROR,
  EDIT_ADDRESS_SUCCESS,
  SEND_PHOTO_ERROR,
  SEND_PHOTO_SUCCESS,
} from '../../../../context/toast/constants';
import { useToast } from '../../../../context/toast/useToast';
import apiCorreios from '../../../../service/apiCorreios';
import api from './api';
import './meuperfil.css';
import { Box, Container, DialogFooter } from './styles';

export function PerfilTemplate() {
  const [edicaoEnderecoHabilitado, setEdicaoEnderecoHabilitado] =
    useState(false);
  const [edicaoDadosHabilitado, setEdicaoDadosHabilitado] = useState(false);

  const habilitarEdicaoEndereco = useCallback(() => {
    setEdicaoEnderecoHabilitado(true);
  }, []);

  const desabilitarEdicaoEndereco = useCallback(() => {
    setEdicaoEnderecoHabilitado(false);
  }, []);

  const habilitarEdicaoDados = useCallback(() => {
    setEdicaoDadosHabilitado(true);
  }, []);

  const [dadosUsuario, setDadosUsuario] = useState({});
  const [usuario, setUsuario] = useState({});
  const [dadospessoais, setDadospessoais] = useState({});
  const toast = useRef(null);
  const [clearUpload, setClearUpload] = useState(null);
  const [retornoStatus, setRetornoStatus] = useState('');
  const [retornoStatusErro, setRetornoStatusErro] = useState('');
  const [letraUser, setLetraUser] = useState('');
  const [deletarDialog, setDeletarDialog] = useState(false);

  const { showToast } = useToast();

  useEffect(() => {
    setUsuario(Usuario);

    carregarDadosPessoais(Usuario);
  }, []);

  function carregarDadosPessoais(usuario) {
    setDadospessoais({
      ...dadospessoais,
      nome: usuario.nome,
      cpf: usuario.cpf,
      nascimento: usuario.nascimento,
      celular: usuario.telefone,
    });
  }

  //LISTA GET Dados Pessoais

  useEffect(() => {
    async function createListUser() {
      let idUsuario = Usuario.id;
      fetch(
        `${process.env.REACT_APP_API_USUARIOS}/DadosPessoais/v1/GetUsuarioDadosPessoaisByIdUsuario/${idUsuario}`,
      )
        .then(result => result.json())
        .then(data => {
          setDadosUsuario(data);

          setLetraUser(data.nomeCompleto.substr(0, 1));
        })
        .catch(error => {
          showToast();
          console.error(error);
        });
    }
    createListUser();
  }, []);
  //FIM LISTA GET Dados Pessoais

  async function consultaCEP() {
    try {
      const response = await apiCorreios.get(`${dadospessoais.cep}/json`);
      setDadospessoais({
        ...dadospessoais,
        rua: response.data.logradouro,
        bairro: response.data.bairro,
        cidade: response.data.localidade,
        estado: response.data.uf,
      });
    } catch (error) {
      console.error(error);
      showToast();
    }
  }

  async function editarEndereco() {
    let idUsuario = Usuario.id;

    try {
      await api.put(
        `${process.env.REACT_APP_API_USUARIOS}/DadosPessoais/v1/PutUsuarioDadosPessoais`,
        {
          idUsuarioDadosPessoais: dadosUsuario.idUsuarioDadosPessoais,
          idUsuario: idUsuario,
          enderecoCep: dadospessoais.cep,
          enderecoLogradouro: dadospessoais.rua,
          enderecoNumero: dadospessoais.numero,
          enderecoComplemento: dadospessoais.complemento,
          enderecoCidade: dadospessoais.cidade,
          enderecoEstado: dadospessoais.estado,
          bairro: dadospessoais.bairro,
          dataNascimento: dadosUsuario.dataNascimento,
          cpf: dadosUsuario.cpf,
          nomeCompleto: dadosUsuario.nomeCompleto,
          numeroCelular: dadosUsuario.numeroCelular,
        },
      );
      showToast(EDIT_ADDRESS_SUCCESS);

      setEdicaoEnderecoHabilitado(false);

      async function createListUser() {
        let idUsuario = Usuario.id;
        fetch(
          `${process.env.REACT_APP_API_USUARIOS}/DadosPessoais/v1/GetUsuarioDadosPessoaisByIdUsuario/${idUsuario}`,
        )
          .then(result => result.json())
          .then(data => {
            setDadosUsuario(data);
          })
          .catch(error => {
            console.error(error);
            showToast();
          });
      }

      createListUser();
    } catch (error) {
      console.error(error);
      showToast(EDIT_ADDRESS_ERROR);
    }
  }

  const chooseOptions = {
    icon: 'pi',
    iconOnly: false,
    className: 'p-button-default p-mr-2 p-mb-2 fontBotao2',
  };

  const invoiceUploadHandler = async ({ files }) => {
    let idUsuario = Usuario.id;
    let fileName = Usuario.id + Usuario.username;

    files.forEach(file => {
      axios
        .get(
          `${process.env.REACT_APP_API_AUTORIZAS3}/AutorizeS3/GetURLFotoPerfil/${idUsuario}/${process.env.REACT_APP_API_BUCKET}/${fileName}`,
        )
        .then(res => {
          const url = res.headers['url'];
          const filename = res.headers['filename'];

          const urlFoto = `/bdi-api-net-autorizas3/api/v1/AutorizeS3/DowloadFile/${process.env.REACT_APP_API_BUCKET}/${filename}`;

          const options = {
            headers: {
              'Content-Type': '',
            },
          };

          axios
            .put(url, file, options)
            .then(() => {
              showToast(SEND_PHOTO_SUCCESS);
            })
            .catch(error => {
              console.error(error);
              showToast(SEND_PHOTO_ERROR);
            });

          let urlPut = `${process.env.REACT_APP_API_USUARIOS}/DadosPessoais/v1/PutUsuarioDadosPessoais`;

          let idUsuario = Usuario.id;

          let dataNascimento = dadosUsuario.dataNascimentoFormatada;
          var ano = dataNascimento.split('').slice(6, 10).join('');
          var mes = dataNascimento.split('').slice(3, 5).join('');
          var dia = dataNascimento.split('').slice(0, 2).join('');
          var dataNascimento2 = ano + '-' + mes + '-' + dia;

          const dados = {
            idUsuarioDadosPessoais: dadosUsuario.idUsuarioDadosPessoais,
            idUsuario: idUsuario,
            enderecoCep: dadosUsuario.enderecoCep,
            enderecoLogradouro: dadosUsuario.enderecoLogradouro,
            enderecoNumero: dadosUsuario.enderecoNumero,
            enderecoComplemento: dadosUsuario.enderecoComplemento,
            enderecoCidade: dadosUsuario.enderecoCidade,
            enderecoEstado: dadosUsuario.enderecoEstado,
            bairro: dadosUsuario.bairro,
            dataNascimento: dataNascimento2,
            cpf: dadosUsuario.cpf,
            nomeCompleto: dadosUsuario.nomeCompleto,
            numeroCelular: dadosUsuario.numeroCelular,
            urlFotoPerfil: urlFoto,
          };

          axios.put(urlPut, dados).catch(error => {
            console.error(error);
            showToast();
          });

          async function createListUser() {
            let idUsuario = Usuario.id;
            fetch(
              `${process.env.REACT_APP_API_USUARIOS}/DadosPessoais/v1/GetUsuarioDadosPessoaisByIdUsuario/${idUsuario}`,
            )
              .then(result => result.json())
              .then(data => {
                setDadosUsuario(data);
              })
              .catch(error => {
                console.error(error);
                showToast();
              });
          }
          createListUser();
        });
    });
  };

  const emailExcluir = async () => {
    let dateNow = new Date().toLocaleString('sv-SE');
    let loginUsuario = Usuario.username;
    let emailusuario = Usuario.email;
    let planoUsuario = Usuario.payment.paymentPlan;
    let mensagemDoUsuario = 'Excluir meus dados';
    let TipoInconcistencia = 'Excluir meu perfil';

    const formData = new FormData();

    const obj = {
      DataHoraInconcistencia: dateNow,
      loginUsuario: loginUsuario,
      emailusuario: emailusuario,
      planoUsuario: planoUsuario,
      mensagemDoUsuario: mensagemDoUsuario,
      TipoInconcistencia: TipoInconcistencia,
    };

    const myJSON = JSON.stringify(obj);
    formData.append('jsonData', myJSON);
    const article = formData;

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    axios
      .post(
        `${process.env.REACT_APP_API_USUARIOS}/EnvioEmailSistemico/v1/PostEmailInconcistenciaGenerico`,
        article,
        { headers },
      )
      .then(response => {
        setRetornoStatus(response.status);
        setRetornoStatusErro(response.status);
        setDeletarDialog(false);
      });
  };

  useEffect(() => {
    if (retornoStatus == 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Email enviado.',
        life: 8000,
      });
    }
  }, [retornoStatus]);

  useEffect(() => {
    if (retornoStatusErro != 200 && retornoStatusErro != '') {
      toast.current.show({
        severity: 'error',
        summary: 'Erro!',
        detail: 'Erro ao enviar o email.',
        life: 8000,
      });
    }
  }, [retornoStatusErro]);

  const modalExcluir = () => {
    setDeletarDialog(true);
  };

  const fecharModalExcluir = () => {
    setDeletarDialog(false);
  };

  return (
    <>
      <Container>
        <Toast ref={toast} />
        <div
          style={{
            border: '2px solid rgba(83, 195, 194, 0.42)',
            boxSizing: 'border-box',
            borderRadius: '5px',
          }}
        >
          <br />
          <div className="container-avatar">
            {dadosUsuario.urlFotoPerfil ? (
              <img
                src={
                  `${process.env.REACT_APP_URL_CALCULADORA2}` +
                  dadosUsuario.urlFotoPerfil
                }
                alt="foto de perfil"
                style={{
                  width: '100px',
                  height: '100px',
                  margin: '0 auto',
                  border: 'none',
                  borderRadius: '50%',
                }}
              />
            ) : (
              <div className="">
                <Avatar
                  label={letraUser}
                  className="p-mr-2"
                  size="xlarge"
                  shape="circle"
                />
              </div>
            )}

            <br />
            <br />
            <FileUpload
              name="invoice"
              ref={ref => setClearUpload(ref)}
              accept="image/*"
              customUpload={true}
              uploadHandler={invoiceUploadHandler}
              mode="basic"
              auto
              chooseLabel="Editar foto de perfil"
              chooseOptions={chooseOptions}
              maxFileSize={3000000}
              invalidFileSizeMessageSummary="Arquivo maior que 3 mb"
              invalidFileSizeMessageDetail=" "
            />
          </div>
          <br />
          <br />

          <Box>
            <h2>Dados pessoais</h2>

            <div className="container-grid">
              {edicaoDadosHabilitado ? (
                <>
                  <div className="field">
                    <label className="corTextoDados" htmlFor="nomeCompleto">
                      Nome completo
                    </label>
                    <InputTextBDI
                      type="text"
                      disabled
                      placeholder="Nome completo"
                      name="nomeCompleto"
                      id="nomeCompleto"
                      valor={dadosUsuario.nomeCompleto}
                      acao={e =>
                        setDadosUsuario({
                          ...dadosUsuario,
                          nomeCompleto: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="cpf">CPF</label>
                    <InputTextBDI
                      type="text"
                      disabled
                      placeholder="CPF"
                      name="cpf"
                      id="cpf"
                      valor={dadosUsuario.cpf}
                      acao={e =>
                        setDadosUsuario({
                          ...dadosUsuario,
                          cpf: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="dataNascimento">Data de nascimento</label>
                    <InputMaskBDI
                      type="text"
                      mask="99/99/9999"
                      disabled
                      placeholder="Data de nascimento"
                      name="dataNascimento"
                      id="dataNascimento"
                      valor={dadosUsuario.dataNascimentoFormatada}
                      acao={e =>
                        setDadosUsuario({
                          ...dadosUsuario,
                          dataNascimentoFormatada: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="numeroCelular">Celular</label>
                    <InputMaskBDI
                      type="text"
                      mask="(99) 9 9999-9999"
                      placeholder="Celular"
                      name="numeroCelular"
                      id="numeroCelular"
                      valor={dadosUsuario.numeroCelular}
                      acao={e =>
                        setDadosUsuario({
                          ...dadosUsuario,
                          numeroCelular: e.target.value,
                        })
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <strong className="corTextoDados">Nome completo</strong>
                    <span>{dadosUsuario.nomeCompleto}</span>
                  </div>
                  <div>
                    <strong className="corTextoDados">CPF</strong>
                    <span>{dadosUsuario.cpf}</span>
                  </div>
                  <div>
                    <strong className="corTextoDados">
                      Data de nascimento
                    </strong>
                    <span>{dadosUsuario.dataNascimentoFormatada}</span>
                  </div>
                  <div>
                    <strong className="corTextoDados">Celular</strong>
                    <span>{dadosUsuario.numeroCelular}</span>
                  </div>
                </>
              )}
            </div>

            {/* {edicaoDadosHabilitado ? (
            <footer>
              <Button
                label="Cancelar"
                className="p-button-text"
                onClick={desabilitarEdicaoDados}
              />
              <Button label="Salvar" onClick={editarDados} />
            </footer>
          ) : (
            <Button label="Alterar dados" onClick={habilitarEdicaoDados} />
          )}*/}
          </Box>
        </div>
        <br />
        <br />
        <div
          style={{
            border: '2px solid rgba(83, 195, 194, 0.42)',
            boxSizing: 'border-box',
            borderRadius: '5px',
          }}
        >
          <Box>
            <h2 style={{ marginTop: '1rem' }}>Endereço</h2>

            <div className="container-grid edit">
              {edicaoEnderecoHabilitado ? (
                <>
                  <div className="field">
                    <label htmlFor="cep">CEP</label>
                    <InputMaskBDI
                      name="cep"
                      id="cep"
                      mask="99999-999"
                      placeholder="00000-000"
                      valor={usuario.cep ? usuario.cep : dadospessoais.cep}
                      acao={e =>
                        setDadospessoais({ ...dadospessoais, cep: e.value })
                      }
                      acao2={() => consultaCEP()}
                    ></InputMaskBDI>
                  </div>
                  <div className="field">
                    <label htmlFor="rua">Logradouro</label>
                    <InputTextBDI
                      type="text"
                      placeholder="Rua"
                      name="rua"
                      id="rua"
                      valor={dadospessoais.rua}
                      acao={e =>
                        setDadospessoais({
                          ...dadospessoais,
                          rua: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="numero">Número</label>
                    <InputTextBDI
                      type="number"
                      placeholder="000000"
                      name="numero"
                      id="numero"
                      valor={dadospessoais.numero}
                      acao={e =>
                        setDadospessoais({
                          ...dadospessoais,
                          numero: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="complemento">Complemento</label>
                    <InputTextBDI
                      type="text"
                      placeholder="Complemento"
                      id="complemento"
                      valor={dadospessoais.complemento}
                      acao={e =>
                        setDadospessoais({
                          ...dadospessoais,
                          complemento: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="bairro">Bairro</label>
                    <InputTextBDI
                      type="text"
                      placeholder="Bairro"
                      name="bairro"
                      id="bairro"
                      valor={dadospessoais.bairro}
                      acao={e =>
                        setDadospessoais({
                          ...dadospessoais,
                          bairro: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="cidade">Cidade</label>
                    <InputTextBDI
                      type="text"
                      placeholder="Cidade"
                      name="cidade"
                      id="cidade"
                      valor={dadospessoais.cidade}
                      acao={e =>
                        setDadospessoais({
                          ...dadospessoais,
                          cidade: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="uf">Estado</label>
                    <InputTextBDI
                      type="text"
                      placeholder="Estado"
                      name="uf"
                      id="uf"
                      valor={dadospessoais.estado}
                      acao={e =>
                        setDadospessoais({
                          ...dadospessoais,
                          estado: e.target.value,
                        })
                      }
                    />
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <strong className="corTextoDados">CEP</strong>
                    <span>{dadosUsuario.enderecoCep}</span>
                  </div>
                  <div>
                    <strong className="corTextoDados">Logradouro</strong>
                    <span>{dadosUsuario.enderecoLogradouro}</span>
                  </div>
                  <div>
                    <strong className="corTextoDados">Número</strong>
                    <span>{dadosUsuario.enderecoNumero}</span>
                  </div>
                  <div>
                    <strong className="corTextoDados">Complemento</strong>
                    <span>{dadosUsuario.enderecoComplemento}</span>
                  </div>
                  <div>
                    <strong className="corTextoDados">Bairro</strong>
                    <span>{dadosUsuario.bairro}</span>
                  </div>
                  <div>
                    <strong className="corTextoDados">Cidade</strong>
                    <span>{dadosUsuario.enderecoCidade}</span>
                  </div>
                  <div>
                    <strong className="corTextoDados">Estado</strong>
                    <span>{dadosUsuario.enderecoEstado}</span>
                  </div>
                </>
              )}
            </div>

            {edicaoEnderecoHabilitado ? (
              <footer>
                <Button
                  label="Cancelar"
                  className="p-button-text alinhamentoEdicaoDados botaoCancelarEdicao"
                  onClick={desabilitarEdicaoEndereco}
                />
                <Button
                  label="Salvar"
                  onClick={editarEndereco}
                  className="alinhamentoEdicaoDados"
                />
              </footer>
            ) : (
              <Button
                label="Alterar endereço"
                onClick={habilitarEdicaoEndereco}
              />
            )}
            <br />
            <br />
          </Box>
        </div>

        {/* <br />
        <br />
        <div
          style={{
            border: '2px solid rgba(83, 195, 194, 0.42)',
            boxSizing: 'border-box',
            borderRadius: '5px',
          }}
        >
          <Box>
            <h2 style={{ marginTop: '1rem' }}>Excluir meus dados</h2>
            <br />
            <Button label="Excluir dados" onClick={modalExcluir} />
            <br />
            <br />
          </Box>
        </div> */}
      </Container>
      <Dialog
        header="Tem certeza que deseja excluir seus dados?"
        visible={deletarDialog}
        onHide={fecharModalExcluir}
        style={{ width: '40rem' }}
      >
        <DialogFooter>
          <Button
            label="Cancelar"
            className="p-button-text botaoFechar"
            onClick={fecharModalExcluir}
          />
          <Button label="Excluir" onClick={emailExcluir} />
        </DialogFooter>
      </Dialog>
    </>
  );
}
