import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const CustomTag = styled.span`
  display: block;
  width: 9rem;
  height: 2rem;
  border-radius: 0.3rem;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1rem;

  ${props =>
    props?.type === '1' &&
    css`
      background-color: #53c3c2;
    `}

  ${props =>
    props?.type === '2' &&
    css`
      background-color: #4d4fa1;
    `}

  ${props =>
    props?.type === '3' &&
    css`
      background-color: #335893;
    `}

  ${props =>
    props?.type === '4' &&
    css`
      background-color: #ea736a;
    `}
`;

export const Button = styled.button`
  background: #53c3c2;
  border: 0;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  transition: all 0.2s;

  > img {
    width: 1rem;
    height: 1rem;
  }

  i {
    color: #fff;
    font-size: 0.8rem;
  }

  &:disabled {
    background: rgba(51, 88, 147, 0.5);
    cursor: not-allowed;
  }

  &:hover {
    filter: brightness(1.2);
  }
`;
