import { AxiosRequestConfig } from 'axios';

import { apiRelatorios } from '../api';
import { GetRelatorioOperacaoFundosImobiliariosData } from './types';

export const getRelatorioOperacaoFundosImobiliarios = (
  idUsuario: string,
  ano: string,
  options: AxiosRequestConfig,
) =>
  apiRelatorios.get<GetRelatorioOperacaoFundosImobiliariosData>(
    `/RelatorioOpFundosImobiliarios/v1/GetRelatorioOperacaoFundosImobiliarios/${idUsuario}/${ano}`,
    options,
  );
