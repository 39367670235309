import {
  UseQueryOptions,
  UseQueryResult,
  useQueries,
} from '@tanstack/react-query';
import Base64 from 'crypto-js/enc-base64';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import { useCallback, useEffect, useRef, useState } from 'react';
import useWebSocket from 'react-use-websocket';

import Usuario from '../../../../../../components/Usuario';
import { useSubscriptions } from '../../../../../../context/subscriptions/SubscriptionsContext';
import { getMarketDataCandles } from '../../../../../../service/MarketData/Candles';
import { MarketDataCandles } from '../../../../../../service/MarketData/Candles/types';
import { MarketDataAuthentication } from '../../../../../../service/MarketData/types';
import {
  getAno,
  getMes,
  isMesAnoAtual,
} from '../../../../../../utils/functions/dates';
import { Cotacao } from '../../types';

const ORIGIN = process.env.REACT_APP_MARKET_DATA_ORIGIN;
const SIGNING_KEY = process.env.REACT_APP_MARKET_DATA_PASSWORD;
const OUTPUT_TYPE = 'default';
const COTACOES_REAL_TIME = ['anual_ir_avancado', 'degustacao'];

const geraDataDiaSeguinte = () =>
  Number(new Date().setDate(new Date().getDate() + 1));

const getUltimaSemanaDoMes = (mesAno: string | null) => {
  const ano = Number(getAno(mesAno));
  const mes = Number(getMes(mesAno));

  const inicioUltimaSemanaDoMes = new Date(ano, mes, -7).toISOString();
  const ultimoDiaDoMes = new Date(ano, mes, 0, 23, 59, 59).toISOString();

  return {
    inicioUltimaSemanaDoMes,
    ultimoDiaDoMes,
  };
};

const geraAuthToken = (
  username: string,
  expirationDate: number,
  data_type: 'delay' | 'realtime',
) => {
  const signing_string = `${username};${data_type};bussola;${expirationDate}`;

  return Base64.stringify(hmacSHA256(signing_string, SIGNING_KEY!));
};

const geraCredencialDeAutenticacao = (
  data_type: 'delay' | 'realtime',
): MarketDataAuthentication => {
  const username = Usuario.username;
  const expirationDate = geraDataDiaSeguinte();

  return {
    username,
    origin: ORIGIN!,
    data_type,
    token: geraAuthToken(username, expirationDate, data_type),
    expiration_date: expirationDate,
  };
};

const geraCredencial = (codes: string[], data_type: 'delay' | 'realtime') => {
  const credentials = geraCredencialDeAutenticacao(data_type);

  return {
    credentials,
    output_type: OUTPUT_TYPE,
    codes: codes,
    compress: false,
    binary: false,
    price_change_only: true,
  };
};

const handleMarketDataRequest = async (
  code: string,
  marketDataConfig: Omit<MarketDataCandles, 'stockCode'>,
  signal?: AbortSignal,
) => {
  const response = await getMarketDataCandles({
    ...marketDataConfig,
    stockCode: code,
    signal,
  });

  const ultimoDiaDeTradeDoMes = response.data[0];

  return {
    ticker: code,
    valor: ultimoDiaDeTradeDoMes?.close,
  };
};

const geraMarketDataRequests = (
  codes: string[],
  mesAno: string | null,
  delayOrRealtime: 'delay' | 'realtime',
) => {
  const { inicioUltimaSemanaDoMes, ultimoDiaDoMes } =
    getUltimaSemanaDoMes(mesAno);

  const marketDataConfig: Omit<MarketDataCandles, 'stockCode'> = {
    ...geraCredencialDeAutenticacao(delayOrRealtime),
    periodicity: 'day',
    startDatetime: inicioUltimaSemanaDoMes,
    endDatetime: ultimoDiaDoMes,
  };

  return codes.map(
    code =>
      ({
        queryKey: ['marketData', code, mesAno],
        queryFn: ({ signal }) =>
          handleMarketDataRequest(code, marketDataConfig, signal),
        enabled: !isMesAnoAtual(mesAno),
        staleTime: 1000 * 20,
      } as UseQueryOptions),
  );
};

export const useMarketData = (mesAno: string | null) => {
  const [data_type, setDatatype] = useState<'delay' | 'realtime'>('delay');
  const [reconectarWebsocket] = useState(true);
  const [codes, setCodes] = useState<string[]>([]);
  const [cotacoes, setCotacoes] = useState([] as Cotacao[]);
  const didUnmount = useRef(false);

  const { getCurrentSubscription } = useSubscriptions();

  const cotacoesResponse = useQueries({
    queries: geraMarketDataRequests(codes, mesAno, data_type),
  }) as UseQueryResult<Cotacao, unknown>[];

  useEffect(() => {
    const currentSubscription = getCurrentSubscription();

    if (currentSubscription !== 'NOT_FOUND') {
      if (
        COTACOES_REAL_TIME.includes(currentSubscription.plano.billingPlanCode)
      ) {
        setDatatype('realtime');
      }
    }

    return () => {
      didUnmount.current = true;
    };
  }, [getCurrentSubscription]);

  useEffect(() => {
    const temTodasAsCotacoes = () => {
      if (!cotacoesResponse.length) return false;

      return cotacoesResponse.every(response => !!response.data);
    };

    if (temTodasAsCotacoes() && !cotacoes.length) {
      const cotacoesPassadas = cotacoesResponse.map(response => response.data!);
      setCotacoes(cotacoesPassadas);
    }
  }, [cotacoes.length, cotacoesResponse]);

  const getSocketUrl = useCallback(() => {
    return new Promise<string>(resolve => {
      reconectarWebsocket === true &&
        resolve('wss://mdstreamer.bussoladoinvestidor.com.br/rtdata');
    });
  }, [reconectarWebsocket]);

  const { lastJsonMessage, sendMessage } = useWebSocket(getSocketUrl, {
    onOpen: () => console.log(`Connected to App WS`),
    onMessage: () => wskt(),
    onError: event => {
      console.error('erro: ', event);
    },
    retryOnError: true,
    shouldReconnect: () => didUnmount.current === false,
    onReconnectStop: () => !reconectarWebsocket,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  });

  useEffect(() => {
    if (codes.length && isMesAnoAtual(mesAno)) {
      const credencial = geraCredencial(codes, data_type);

      sendMessage(JSON.stringify(credencial));
    }
  }, [codes, data_type, mesAno, sendMessage]);

  function wskt() {
    if (lastJsonMessage?.type === 0) {
      const cotacao = {
        ticker: lastJsonMessage.stock_code,
        valor: Number(lastJsonMessage.price),
      };

      setCotacoes([cotacao]);
    }
  }

  return { setCodes, cotacoes };
};
