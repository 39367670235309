import styled from 'styled-components';

import { devices } from '../../utils/functions/styles';

export const Background = styled.div`
  width: 400px;
  height: 308px;
  background: url(${props => props.img});
  background-size: cover;
  position: absolute;
  right: 10rem;
  bottom: 10rem;
  z-index: 1 !important;

  @media (max-width: 992px) {
    width: 250px;
    height: 193px;
    right: 4rem;
    bottom: 10rem;
  }
`;

export const Box1 = styled.div`
  display: flex;
  justify-content: space-betwwen;

  section {
    width: 100%;

    div {
      div {
        z-index: 100 !important;
      }
    }

    h2 {
      margin-top: 2.5rem;
    }

    p {
      article {
        font-size: 16px !important;
      }
    }

    img {
      z-index: 1 !important;
    }

    article {
      margin-bottom: 2rem;
      line-height: 2rem !important;
      z-index: 100 !important;

      span {
        label {
          font-size: 18px !important;
        }
      }

      p {
        color: #112c50;
        margin: 0 0 0 2rem;
        line-height: 7px;

        @media (max-width: 992px) {
          line-height: normal;
        }
      }
    }

    &:first-child {
      width: 20%;
      margin: 0 1rem 0 0;

      img {
        width: 100%;
      }

      @media (max-width: 992px) {
        display: none;
      }
    }
  }

  #avisoUsuarioAntigo {
    font-size: 0.9rem !important;
    color: #ea736a;

    span {
      font-weight: bold;
    }
  }

  .field input {
    border-color: #112c50 !important;
  }

  .field input::placeholder {
    color: rgba(17, 44, 80, 0.5) !important;
  }

  .field.invalido input {
    border-color: red !important;
    color: red !important;
  }

  .field.invalido input::placeholder {
    color: red !important;
  }

  .p-radiobutton-disabled + label {
    opacity: 0.6 !important;
  }
`;

export const Box2 = styled.div`
  h2 {
    line-height: 2rem;
  }
  section {
    width: 100%;
  }

  @media ${devices.mobileL} {
    .p-col-12.p-md-12:not(.table-demo),
    .p-col-12.p-md-6 {
      > div div {
        overflow-x: scroll;
        padding-right: 1rem;

        p {
          width: fit-content;
        }
      }
    }
  }
`;

export const PosicoesIniciaisContainer = styled.div`
  @media ${devices.mobileL} {
    table tbody tr td {
      display: flex !important;
      justify-content: space-between !important;
      flex-wrap: wrap !important;
    }
  }
`;

export const Box50 = styled.div`
  display: flex;

  div {
    width: 100%;
    border: 1px solid rgba(83, 195, 194, 0.4);
    border-radius: 4px;
    margin-left: 2rem;
    padding: 2.5rem 2rem;

    &:first-child {
      margin-left: 0;
    }

    input {
      width: 100% !important;
    }

    span {
      i {
        cursor: pointer;
      }
    }

    label {
      display: block;
    }

    div {
      border: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      margin: 1.5rem 0;
      width: 100%;

      div {
        margin: 0 0 0 1rem;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:first-child {
          margin: 0;
        }

        input {
          width: 100% !important;
        }
      }
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;

    div {
      margin: 1rem 0;

      div {
        margin: 1rem 0;
        flex-direction: column;

        div {
          margin: 1rem 0;

          &:first-child {
            margin: 1rem 0;
          }
        }
      }
    }
  }
`;

export const Box100 = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.4);
  border-radius: 4px;
  background-color: rgba(150, 183, 199, 0.1);

  div {
    border-top: 1px solid rgba(83, 195, 194, 0.4);
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);

    input {
      width: 100% !important;
    }

    &:first-child {
      border-top: 0;
      background: transparent;
    }

    button {
      margin: 1rem;
    }

    &:last-child {
      justify-content: space-between !important;
    }

    .subtitulosPosicaoIni {
      text-align: left !important;
    }
    p {
      margin: 1rem 0 1rem 1rem;
      position: relative;
      width: 15.5%;
      text-align: center;

      &:last-child {
        width: 7%;

        ul {
          margin: 0;
          padding: 0;
          position: absolute;
          bottom: 100%;
          box-shadow: 1px 2px 5px rgba(50, 50, 50, 0.2);
          border-radius: 3px;
          width: 80px;

          li {
            list-style: none;
            cursor: pointer;
            padding: 0.5rem 2rem 0.5rem 0.5rem;
          }
        }
      }

      i {
        font-size: 2rem;
        cursor: pointer;
      }

      button {
        margin: 0;
      }

      input {
        text-align: center;
        width: 100% !important;
      }
    }

    p.heading {
      color: #112c50 !important;
      font-size: 1rem !important;
    }
  }

  button.transparent {
    background-color: transparent;
    border: 0;
  }

  @media (max-width: 992px) {
    div {
      flex-direction: column;

      p {
        padding: 1rem;
        width: 100%;
        margin: 0 1rem;
        text-align: left;

        &:last-child {
          width: 100%;
        }

        label {
          display: block;
        }

        input {
          width: 100% !important;
        }

        div {
          display: flex;

          span {
            width: 100%;
            text-align: center;
          }
        }
      }

      &:last-child {
        flex-direction: row;
      }
    }
  }
`;

export const Box100a = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.4);
  border-radius: 4px;

  div {
    border-top: 1px solid rgba(83, 195, 194, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-around;

    &:first-child {
      border-top: 0;
      background-color: rgba(150, 183, 199, 0.1);
    }

    &:last-child {
      background-color: rgba(150, 183, 199, 0.1);
    }

    input {
      width: 100% !important;
    }

    button {
      margin: 1rem;
    }

    p {
      margin: 0.5rem 0 0.5rem 1rem;
      text-align: center;
      width: 20%;

      &:first-child {
        text-align: left;
        width: 45%;
      }

      i {
        font-size: 2rem;
      }

      button {
        margin: 0;
      }

      input {
        text-align: center;
      }
    }
  }

  @media (max-width: 992px) {
  }
`;

export const Box100b = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.4);
  border-radius: 4px;
  margin-top: 2rem;

  div {
    border-top: 1px solid rgba(83, 195, 194, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-around;

    &:first-child {
      border-top: 0;
      background-color: rgba(150, 183, 199, 0.1);
    }

    input {
      width: 100% !important;
    }

    button {
      margin: 1rem;
    }

    p {
      margin: 0.5rem 0 0.5rem 1rem;
      text-align: center;
      width: 20%;

      &:first-child {
        text-align: left;
        width: 70%;
      }

      i {
        font-size: 2rem;
      }

      button {
        margin: 0;
      }

      input {
        text-align: center;
      }
    }
  }

  @media (max-width: 992px) {
  }
`;

export const Box101a = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.4);
  border-radius: 4px;

  div {
    border-top: 1px solid rgba(83, 195, 194, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-child {
      border-top: 0;
      background-color: rgba(150, 183, 199, 0.3);
      justify-content: flex-start;
    }

    &:nth-child(2) {
      border-top: 0;
      background-color: rgba(150, 183, 199, 0.1);
    }

    &:last-child {
      background-color: rgba(150, 183, 199, 0.1);
    }

    input {
      width: 100% !important;
    }

    button {
      margin: 1rem;
    }

    p {
      margin: 0.5rem 0 0.5rem 1rem;
      text-align: center;
      width: 20%;

      &:first-child {
        text-align: left;
      }

      i {
        font-size: 2rem;
      }

      button {
        margin: 0;
      }

      input {
        text-align: center;
      }
    }

    p.heading {
      color: #112c50 !important;
      font-size: 1rem !important;
    }
  }

  @media (max-width: 992px) {
  }
`;

export const Box101b = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.4);
  border-radius: 4px;
  margin-top: 2rem;

  div {
    border-top: 1px solid rgba(83, 195, 194, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-child {
      border-top: 0;
      background-color: rgba(150, 183, 199, 0.3);
      justify-content: flex-start;
    }

    &:nth-child(2) {
      border-top: 0;
      background-color: rgba(150, 183, 199, 0.1);
    }

    input {
      width: 100% !important;
    }

    button {
      margin: 1rem;
    }

    p {
      margin: 0.5rem 0 0.5rem 1rem;
      text-align: center;
      width: 20%;

      &:first-child {
        text-align: left;
      }

      i {
        font-size: 2rem;
      }

      button {
        margin: 0;
      }

      input {
        text-align: center;
      }
    }

    p.heading {
      color: #112c50 !important;
      font-size: 1rem !important;
    }
  }

  @media (max-width: 992px) {
  }
`;

export const Box100c = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.4);
  border-radius: 4px;
  margin-top: 2rem;

  div {
    border-top: 1px solid rgba(83, 195, 194, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-betwwen;

    &:first-child {
      border-top: 0;
      background-color: rgba(150, 183, 199, 0.3);
      justify-content: flex-start;
    }

    &:nth-child(2) {
      border-top: 0;
      background-color: rgba(150, 183, 199, 0.1);
    }

    input {
      width: 100% !important;
    }

    button {
      margin: 1rem;
    }

    p {
      margin: 0.5rem 0 0.5rem 1rem;
      text-align: center;
      width: 20%;

      &:first-child {
        text-align: left;
      }
    }
  }

  @media (max-width: 992px) {
  }
`;

export const Box100cTable = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.4);
  border-radius: 4px;

  div {
    border-top: 1px solid rgba(83, 195, 194, 0.4);

    &:first-child {
      border-top: 0;
      background-color: rgba(150, 183, 199, 0.3);
      justify-content: flex-start;
    }

    &:last-child {
      border-top: 0;
      background: #fff;
      padding: 0 !important;
    }

    th {
      width: 13%;

      div {
        background: transparent !important;
        // text-align: center !important;

        span {
          // width: 100%;
          // text-align: center !important;

          &:first-child {
            // text-align: left !important;
          }
        }

        &:first-child {
          // text-align: left !important;
        }
      }

      &:first-child {
        width: 35%;
        text-align: left !important;
      }
    }

    td {
      background-color: #fff;
      // text-align: center !important;

      &:first-child {
        text-align: left !important;
      }
    }

    p {
      padding: 0.5rem 1rem;
      text-align: center;
      width: 16%;

      &:first-child {
        text-align: left;
      }
    }

    p.heading {
      color: #112c50 !important;
      font-size: 1rem !important;
    }

    td {
      text-align: center;
      width: 16%;

      &:first-child {
        text-align: left;
      }
    }
  }

  @media (max-width: 992px) {
  }
`;

export const Box100d = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.4);
  border-radius: 4px;
  margin-top: 2rem;

  div {
    border-top: 1px solid rgba(83, 195, 194, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-around;

    &:first-child {
      border-top: 0;
      background-color: rgba(150, 183, 199, 0.1);
    }

    input {
      width: 100% !important;
    }

    button {
      margin: 1rem;
    }

    p {
      margin: 0.5rem 0 0.5rem 1rem;
      text-align: center;
      width: 50%;

      i {
        font-size: 2rem;
      }

      button {
        margin: 0;
      }

      input {
        text-align: center;
      }
    }

    p.heading {
      color: #112c50 !important;
      font-size: 1rem !important;
    }
  }

  @media (max-width: 992px) {
  }
`;

export const Box100e = styled.div`
  border: 1px solid rgba(83, 195, 194, 0.4);
  border-radius: 4px;

  div {
    border-top: 1px solid rgba(83, 195, 194, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-around;

    &:first-child {
      border-top: 0;
      background-color: rgba(150, 183, 199, 0.1);
    }

    &:last-child {
      background-color: rgba(150, 183, 199, 0.1);
    }

    input {
      width: 100% !important;
    }

    button {
      margin: 1rem;
    }

    p {
      margin: 0.5rem 0 0.5rem 1rem;
      text-align: center;
      width: 20%;

      &:first-child {
        text-align: left;
        width: 70%;
      }

      i {
        font-size: 2rem;
      }

      button {
        margin: 0;
      }

      input {
        text-align: center;
      }
    }

    p.heading {
      color: #112c50 !important;
      font-size: 1rem !important;
    }
  }

  @media (max-width: 992px) {
  }
`;

export const Icon = styled.span`
  i {
    color: #53c3c2 !important;
    font-size: 1.5rem;
    margin-left: 0.5rem;
  }
`;

export const Small = styled.small``;

export const DialogFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.6rem;
  margin-top: 2rem;

  .without-background {
    background: none !important;
    color: #96b7c7;
    border: 1px solid #96b7c7 !important;
  }
`;
export const DialogPosicaoDuplicadaContainer = styled.div`
  p {
    margin-block: 3rem;
    word-wrap: break-word;
  }

  hr:last-of-type {
    height: 1.5px;
  }
`;

export const DialogInformarInconsistenciaContainer = styled.div`
  textarea {
    width: 100%;
  }
`;

export const DialogPosicaoDuplicadaFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  @media ${devices.mobileL} {
    flex-direction: column;
  }

  button {
    margin: 0 !important;
  }

  button:first-of-type {
    background-color: transparent;
    color: #a8c0ce;
    border: 1px solid #a8c0ce !important;
  }
`;

export const DialogInformarInconsistenciaFooter = styled(
  DialogPosicaoDuplicadaFooter,
)``;
