import styled from 'styled-components';

export const Container = styled.main`
  margin: 3rem 0;

  main {
    margin: 3rem 0;

    > div {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      margin-bottom: 2rem;
    }
  }

  div.field {
    display: flex;
    flex-direction: column;
  }

  div.container-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
  }
`;

export const DialogFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.6rem;
  margin-top: 2rem;
`;
