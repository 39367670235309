import * as S from './styles';

type LegendaProps = {
  labels: string[];
};

export function Legenda({ labels }: LegendaProps) {
  return (
    <S.ContainerLegenda>
      {labels.map((label, index) => (
        <S.ItemLegenda index={index} key={index}>
          {label}
        </S.ItemLegenda>
      ))}
    </S.ContainerLegenda>
  );
}
