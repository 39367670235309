import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { useToast } from '../../../../../context/toast/useToast';
import { FilterList, ListInstrumentosOpcaoResponse } from '../types';

async function changeFilterOpcoes(filter: FilterList) {
  const abortController = new AbortController();
  const signal = abortController.signal;

  const { data } = await axios.post<ListInstrumentosOpcaoResponse[]>(
    `${process.env.REACT_APP_API_EVENTOS}/AjusteOpcoes/v1/ListInstrumentosOpcaoExecucaoFiltro`,
    filter,
    { signal },
  );

  return data.map(opcao => ({
    id: opcao.idPosicaoAberta,
    callPut: opcao.callPut,
    idCorretora: opcao.idCorretora,
    corretora: opcao.corretora,
    dc: opcao.dc,
    dataPregao: opcao.dataPregao,
    dataVencimento: opcao.dataVencimento,
    quantidade: opcao.quantidade,
    status: opcao.status.descricao,
    ticker: opcao.ticker,
    idInstrumento: opcao.idInstrumento,
    tipoNegocio: opcao.tipoNegocio,
    tipoAtivo: 'Opção de ação',
  }));
}

export function useFilterOpcoesMutation() {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  return useMutation(changeFilterOpcoes, {
    onError: () => showToast(),
    onSuccess: (data, variables) =>
      queryClient.setQueryData(
        [
          'eventos',
          'AjusteOpcoes',
          'v1',
          'ListInstrumentosOpcao',
          variables.idUsuario,
        ],
        data,
      ),
  });
}
