import { AxiosRequestConfig } from 'axios';

import { apiRelatorios } from '../api';
import { GetMesAnoECorretoraOperacoesV2Data } from './types';

export const getAnosDeOperacaoPorUsuario = (idUsuario: string) =>
  apiRelatorios.get(`/FiltroBusca/v1/GetAnosDeOperacaoPorUsuario/${idUsuario}`);

export const getMesAnoECorretoraOperacoes = (idUsuario: string) =>
  apiRelatorios.get(`/FiltroBusca/GetMesAnoECorretoraOperacoes/${idUsuario}`);

export const getMesAnoECorretoraOperacoesV2 = (
  idUsuario: string,
  options: AxiosRequestConfig,
) => {
  return apiRelatorios.get<GetMesAnoECorretoraOperacoesV2Data>(
    `/FiltroBusca/v2/GetMesAnoECorretoraOperacoes/${idUsuario}`,
    options,
  );
};
