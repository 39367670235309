import { AjusteDeOpcoes } from './ModalAjusteDeOpcoes';
import { ConfirmarPagamento } from './ModalConfirmarPagamento';
import { EmailEnviado } from './ModalEnviadoParaEmail';

export const CONFIRMAR_PAGAMENTO_DARF = 'confirmarPagamentoDarf';
export const EMAIL_ENVIADO = 'emailEnviado';
export const AJUSTE_DE_OPCOES = 'ajusteDeOpcoes';

const MODAL_CONTENT = new Map([
  [CONFIRMAR_PAGAMENTO_DARF, ConfirmarPagamento],
  [EMAIL_ENVIADO, EmailEnviado],
  [AJUSTE_DE_OPCOES, AjusteDeOpcoes],
]);

export const getModal = name => MODAL_CONTENT.get(name);
