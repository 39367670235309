import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { useState } from 'react';

import { BaseModule } from '../../../../components/BaseModule';
import { RELATORIO_FUNDOS_IMOBILIARIOS } from '../../../../components/BaseModule/constants';
import Usuario from '../../../../components/Usuario';
import { useOperationYearsFilter } from '../../../../hooks/reports/useOperationYearsFilter';
import history from '../../../../service/history';
import { useFIIReport } from './hooks/useFIIReport';
import { Container, WarningMigratedUser } from './styles';

const CURRENT_YEAR = new Date().getFullYear();

export const RelatorioFII = () => {
  const [selectedYear, setSelectedYear] = useState({
    name: String(CURRENT_YEAR),
    code: CURRENT_YEAR,
  });
  const operationYears = useOperationYearsFilter({ userId: Usuario.id });
  const fiiOperations = useFIIReport({
    userId: Usuario.id,
    year: selectedYear.code,
    isThereYears: operationYears.data?.length > 0,
  });

  return (
    <BaseModule
      tipo={RELATORIO_FUNDOS_IMOBILIARIOS}
      props={{ nome: 'fundos Imobiliários' }}
    >
      {fiiOperations.isSuccess && fiiOperations.data.migration && (
        <WarningMigratedUser>
          Novidade: Você possui dados migrados da versão 1.0 da plataforma.
          Agora os relatórios da SmarttIR contemplam informações que você não
          tinha antes. Devido a isso, alguns campos podem aparecer em branco, já
          que não existiam na antiga versão.
        </WarningMigratedUser>
      )}

      {!operationYears.isLoading && (
        <Container>
          <div className="p-d-flex">
            <div className="p-flex-column p-d-flex">
              <Dropdown
                value={selectedYear}
                options={operationYears.data}
                onChange={e => setSelectedYear(e.value)}
                optionLabel="name"
                placeholder="Selecione o ano"
              />
            </div>
          </div>
          <Button onClick={() => history.push('/impostos/emissao-darf')}>
            Emitir DARF
          </Button>
        </Container>
      )}

      <DataTable
        value={
          !!fiiOperations.data?.operations ? fiiOperations.data.operations : []
        }
        selection={
          !!fiiOperations.data?.operations ? fiiOperations.data.operations : []
        }
        responsiveLayout="scroll"
        emptyMessage="Lista vazia."
        loading={fiiOperations.isLoading}
      >
        <Column field="mesDescricao" header="Mês" className="corTextoDados" />
        <Column
          field="resultadoLiquidoMes"
          header="Resultado Líquido do mês"
          className="corTextoDados"
        />
        <Column
          field="resultadoNegativoMesAnterior"
          header="Resultado neg. até o mês anterior"
          className="corTextoDados"
        />
        <Column
          field="baseCalImposto"
          header="Base de cál. do imposto"
          className="corTextoDados"
        />
        <Column
          field="prejuizoCompensar"
          header="Prejuízo a compensar"
          className="corTextoDados"
        />
        <Column
          field="aliquotaDoImposto"
          header="Alíq. do imposto"
          className="corTextoDados"
        />
        <Column
          field="impostoDevido"
          header="Imposto devido"
          className="corTextoDados"
        />
        <Column
          field="saldoDoImpostoRetidoMesAnterior"
          header="Saldo do imp.  retido nos meses anteriores"
          className="corTextoDados"
        />
        <Column
          field="impostoRetidoMes"
          header="Imposto retido no mês"
          className="corTextoDados"
        />
        <Column
          field="impostoCompensar"
          header="Imposto a compensar"
          className="corTextoDados"
        />
        <Column
          field="impostoPagar"
          header="Imposto a pagar"
          className="corTextoDados"
        />
        <Column
          field="impostoPago"
          header="Imposto pago"
          className="corTextoDados"
        />
      </DataTable>
    </BaseModule>
  );
};
