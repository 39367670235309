import history from '../../service/history';

const removeLeftZeros = number => {
  if (Number(number) === 0 || !Number(number)) return '0';
  const firstNonZeroNumber = number.match(/[1-9]/);
  if (number.length > 1 && number[0] === '0') {
    return number.substring(number.indexOf(firstNonZeroNumber[0]));
  }
  return number;
};

const getCurrencyInteger = numbers => {
  const integer = numbers.slice(0, -2) || '0';
  return removeLeftZeros(integer);
};

const getCurrencyCents = numbers => {
  const cents = numbers.slice(-2) || '00';
  if (cents.length < 2) return `0${cents}`;
  return cents;
};

const getCurrencyValue = (integer, cents, hasPrefix) => {
  const CURRENCY_SIGN = hasPrefix ? 'R$ ' : '';

  if (integer.length > 3) {
    return `${CURRENCY_SIGN}${integer.replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      '$1.',
    )},${cents}`;
  }

  return `${CURRENCY_SIGN}${integer},${cents}`;
};

// Método para formatar valores vindos da API
export const formatCurrencyForReadOnly = value => {
  const valueFormated = parseInt(value);
  const result = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(valueFormated);

  return result;
};

// Método para formatar valores de input do usuário
export const formatCurrency = (value, hasPrefix) => {
  const numbers = value.replace(/\D/g, '');

  const integer = getCurrencyInteger(numbers);
  const cents = getCurrencyCents(numbers);

  return getCurrencyValue(integer, cents, hasPrefix);
};

export const formataStringParaDecimal = valor => {
  if (!valor) return 0;

  if (typeof valor === 'string') {
    return Number(valor.replace('.', '').replace(',', '.'));
  }

  return valor.replace('.', '').replace(',', '.');
};

const isBMF = market => market === 'BM&F';

export const formataValorDecimal = valor => {
  if (!valor && isNaN(valor)) return;

  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(valor);
};

export const formataValorAjuste = (mercado, pontosAjuste, valorAjuste) => {
  return isBMF(mercado)
    ? formataValorDecimal(pontosAjuste)
    : formataValorDecimal(valorAjuste);
};

export const RegraValorAjuste = (mercado, pontosAjuste, valorAjuste) => {
  return isBMF(mercado) ? pontosAjuste : valorAjuste;
};

export const formataDataLocal = value => {
  return new Date(value).toLocaleDateString();
};

export const formataDataCelula = key => rowData => {
  const valorData = rowData[key];

  if (valorData) {
    return formataDataLocal(valorData);
  }

  return '-';
};

export const getValueCelula = key => rowData => {
  const valorData = rowData[key];

  if (valorData) {
    return valorData;
  }

  return '-';
};

export const getValueFinanceiroCelula = key => rowData => {
  const valorData = rowData[key];

  if (valorData) {
    return `R$ ${valorData}  `;
  }

  return '-';
};

export const irPara = rota => () => {
  history.push(rota);
};
