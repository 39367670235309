/* eslint-disable */
import {
  ButtonBDI,
  DialogBDI,
  InputTextBDI,
} from '@dev_bdi/bdi-front-dspack';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import React, { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../../context/config';
import { useToast } from '../../../context/toast/useToast';
import { apiOperacoes } from '../../../service/ApiOperacoes/api';
import history from '../../../service/history';
import {
  formatCurrency,
  formataStringParaDecimal,
} from '../../../utils/functions';
import { Box1, Box103 } from './styles';

export const RodapeBMeF = () => {
  const { state, setState } = useContext(ConfigContext);
  const [rodapebmef, setRodapebmef] = useState({
    idNotaCorretagemRodapeBmf: 0,
    idNotaCorretagem: 0,
    vendadisponivel: '0,0000',
    compradisponivel: '0,0000',
    vendaopcoes: '0,0000',
    compraopcoes: '0,0000',
    valornegocios: '0,0000',
    valornegociosdc: 'D',
    irrf: '0,0000',
    irrfdaytradeprojecao: '0,0000',
    taxaoperacional: '0,0000',
    taxaregistro: '0,0000',
    taxasbmefemolfgar: '0,0000',
    maisoutros: '0,0000',
    impostoiss: '0,0000',
    ajusteposicao: '0,0000',
    ajusteposicaodc: 'D',
    ajustedaytrade: '0,0000',
    ajustedaytradedc: 'D',
    totaldespesas: '0,0000',
    totaldespesasdc: 'D',
    outros: '0,0000',
    irrfcorretagem: '0,0000',
    totalcontainvestimento: '0,0000',
    totalcontainvestimentodc: 'D',
    totalcnormal: '0,0000',
    totalcnormaldc: 'D',
    totalliquido: '0,0000',
    totalliquidodc: 'D',
    totalliqnota: '0,0000',
    totalliqnotadc: 'D',
    idNotaCorretagemNavigation: null,
  });
  const [cadastromanual, setCadastromanual] = useState({});
  const [posicoesiniciais, setPosicoesiniciais] = useState([]);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [modalTituloDialog, setModalTituloDialog] = useState(false);

  const { showToast } = useToast();

  const dc = [
    { name: 'D', code: 'D' },
    { name: 'C', code: 'C' },
  ];

  async function salvar() {
    try {
      localStorage.setItem('rodapebmef', JSON.stringify(rodapebmef));

      const dataAtual = new Date().toISOString();
      const pregao = new Date(
        cadastromanual.pregao.split('/').reverse().join('-'),
      ).toISOString();

      const newOperacoes = posicoesiniciais.map(operacao => {
        return {
          idNotaCorretagemItem: 0,
          idNotaCorretagem: 0,
          ordenacao: 1,
          negociacaoMercado: '2-BM&F',
          compraVenda: operacao.posicao && operacao.posicao.code,
          tipoMercado: 'FUTURO',
          idTipoMercado: 8,
          mercadoriaTituloPapelCodigo:
            operacao.mercadoria && operacao.mercadoria.name,
          mercadoriaTituloPapelExtenso:
            operacao.mercadoria && operacao.mercadoria.name,
          idInstrumento: operacao.espectitulo && operacao.espectitulo.code,
          dataVencimento:
            operacao.vencimento &&
            new Date(
              operacao.vencimento.split('/').reverse().join('-'),
            ).toISOString(),
          prazo: '',
          quantidade: operacao.quantidade,
          valorPrecoAjuste: 0,
          pontosPrecoAjuste: formataStringParaDecimal(operacao.precoajuste),
          tipoNegocio: operacao.tiponeg && operacao.tiponeg.name,
          idTipoNegocio: operacao.tiponeg && operacao.tiponeg.code,
          valorOperacaoAjuste: formataStringParaDecimal(operacao.vlropajuste),
          pontosOperacaoAjuste: 0,
          debitoCredito: operacao.dc.code,
          valorTaxaOperacional: 0,
          txtTipoPapel: false,
          txtTipoCorretagem: false,
          txtTipoCusto: false,
          idSubTipoInstrumento: null,
          custoCalculado: 0,
          idNotaCorretagemNavigation: null,
          idSubTipoInstrumentoNavigation: null,
          idTipoMercadoNavigation: null,
          idTipoNegocioNavigation: null,
          operacos: [],
        };
      });

      const newRodapeBMeF = {
        idNotaCorretagemRodapeBmf: 0,
        idNotaCorretagem: 0,
        vendaDisponivel: formataStringParaDecimal(rodapebmef.vendadisponivel),
        compraDisponivel: formataStringParaDecimal(rodapebmef.compradisponivel),
        vendaOpcoes: formataStringParaDecimal(rodapebmef.vendaopcoes),
        compraOpcoes: formataStringParaDecimal(rodapebmef.compraopcoes),
        valorDosNegocios: formataStringParaDecimal(rodapebmef.valornegocios),
        valorDosNegociosDc: rodapebmef.valornegociosdc.code,
        irrf: formataStringParaDecimal(rodapebmef.irrf),
        irrfdayTrade: formataStringParaDecimal(rodapebmef.irrfdaytradeprojecao),
        taxaOperacional: formataStringParaDecimal(rodapebmef.taxaoperacional),
        taxaRegistroBmf: formataStringParaDecimal(rodapebmef.taxaregistro),
        taxaBmf: formataStringParaDecimal(rodapebmef.taxasbmefemolfgar),
        outrosCustos: formataStringParaDecimal(rodapebmef.maisoutros),
        iss: formataStringParaDecimal(rodapebmef.impostoiss),
        ajusteDePosicao: formataStringParaDecimal(rodapebmef.ajusteposicao),
        ajusteDePosicaoDc: rodapebmef.ajusteposicaodc.code,
        ajusteDayTrade: formataStringParaDecimal(rodapebmef.ajustedaytrade),
        ajusteDayTradeDc: rodapebmef.ajustedaytradedc.code,
        totalDasDespesas: formataStringParaDecimal(rodapebmef.totaldespesas),
        totalDasDespesasDc: rodapebmef.totaldespesasdc.code,
        outros: formataStringParaDecimal(rodapebmef.outros),
        irrfcorretagem: formataStringParaDecimal(rodapebmef.irrfcorretagem),
        totalContaInvestimento: formataStringParaDecimal(
          rodapebmef.totalcontainvestimento,
        ),
        totalContaInvestimentoDc: rodapebmef.totalcontainvestimentodc.code,
        totalContaNormal: formataStringParaDecimal(rodapebmef.totalcnormal),
        totalContaNormalDc: rodapebmef.totalcnormaldc.code,
        totalLiquido: formataStringParaDecimal(rodapebmef.totalliquido),
        totalLiquidoDc: rodapebmef.totalliquidodc.code,
        totalLiquidoNota: formataStringParaDecimal(rodapebmef.totalliqnota),
        totalLiquidoNotaDc: rodapebmef.totalliqnotadc.code,
        idNotaCorretagemNavigation: null,
      };

      await apiOperacoes.post('NotaCorretagem/v1/PostListNotaCorretagem', [
        {
          idNotaCorretagem: 0,
          idUsuario: state.usuario.id,
          idNotaCorretagemArquivo: null,
          idUsuarioCorretora: 1676,
          paginaPdf: 1,
          idTipoFormatoPagina: 1,
          paginaFolha: null,
          numeroNotaCorretora: cadastromanual.numeronota,
          dataPregao: pregao,
          dataHoraCriacao: dataAtual,
          operacaoIdSituacao: 0,
          operacaoDataHoraProcessamento: null,
          operacaoErro: '',
          idCorretora: cadastromanual.corretora.code,
          idTipoNota: cadastromanual.tiponota.code,
          cpfNota: cadastromanual.cpf,
          cnpjNota: '73.973.090/0001-70',
          idCorretoraNavigation: null,
          idNotaCorretagemArquivoNavigation: null,
          idTipoNotaNavigation: null,
          idUsuarioCorretoraNavigation: null,
          custos: null,
          fechamentos: null,
          historicoDePosicos: null,
          notaCorretagemVolumeB3s: null,
          notaCorretagemVolumeBmfs: null,
          notasCorretagemItens: newOperacoes,
          notasCorretagemRodapeB3s: [],
          notasCorretagemRodapeBmfs: [newRodapeBMeF],
          operacos: null,
          resultados: null,
        },
      ]);

      localStorage.removeItem('cadastromanual');
      localStorage.removeItem('operacoesbmef');
      localStorage.removeItem('rodapebmef');
      setDisplayBasic(true);
    } catch (error) {
      console.error(error);
      showToast();
    }
  }

  function voltar() {
    localStorage.setItem('rodapebmef', JSON.stringify(rodapebmef));
    window.history.back();
  }

  function handleValorRodape(e) {
    const moedaFormatada = formatCurrency(e.target.value);
    const key = e.target.id;

    setRodapebmef({ ...rodapebmef, [key]: moedaFormatada });
  }

  const basicDialogFooter = (
    <React.Fragment>
      <ButtonBDI
        label="Histórico de notas"
        className={`
                        p-button-primary
                        p-button-primary-cancel
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
        funcao={() => finalizar('/HistoricoNotas')}
      />
      <ButtonBDI
        label="Cadastrar notas"
        className={`
                        ${ButtonBDI.bdi_classe.severities}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
        funcao={() => finalizar('/calculadora/cadastromanual')}
      />
    </React.Fragment>
  );

  function finalizar(rota) {
    history.push(rota);
  }

  useEffect(() => {
    if (localStorage.getItem('cadastromanual'))
      setCadastromanual(JSON.parse(localStorage.getItem('cadastromanual')));
    if (localStorage.getItem('rodapebmef'))
      setRodapebmef(JSON.parse(localStorage.getItem('rodapebmef')));
    const posicoes_iniciais = localStorage.getItem('operacoesbmef')
      ? JSON.parse(localStorage.getItem('operacoesbmef'))
      : posicoesiniciais;
    setPosicoesiniciais(posicoes_iniciais);
  }, []);

  const modalTitulo = () => {
    setModalTituloDialog(true);
  };

  const fecharModalTitulo = () => {
    setModalTituloDialog(false);
  };

  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-12">
        {displayBasic && (
          <DialogBDI
            header="Dados salvos"
            visible={displayBasic}
            style={{ width: '600px' }}
            footer={basicDialogFooter}
            funcao={() => setDisplayBasic(false)}
          >
            <p>
              <b>Suas informações foram salvas!</b>
            </p>
            <p>
              Clique em “cadastrar notas” para adicionar manualmente novas notas
              de corretagem ou acesse seu histórico de notas.
            </p>
          </DialogBDI>
        )}
        <Box1>
          <section>
            <div className="card">
              <div className="p-col-12 p-md-12">
                <h2>
                  <strong>Para finalizar</strong>, cadastre as informações do{' '}
                  <strong>rodapé da nota</strong>.
                  <Button
                    icon="pi pi-question-circle"
                    style={{ top: '9px' }}
                    className="p-button-rounded p-button-text colorIcon"
                    onClick={modalTitulo}
                    tooltip="Rodapé BMF"
                  />
                </h2>
                <br />
                <p>
                  Cadastre as informações do rodapé de acordo com a sua nota de
                  corretagem para que taxas, despesas e impostos referentes às
                  operações realizadas sejam apurados pela plataforma
                  automaticamente.
                </p>
                <Dialog
                  visible={modalTituloDialog}
                  style={{ width: '41vw' }}
                  header="Título"
                  blockScroll={false}
                  className="p-fluid"
                  modal
                  onHide={fecharModalTitulo}
                >
                  <p>Texto</p>
                </Dialog>
              </div>
              <br />
              <br />
              <div className="p-col-12 p-md-12">
                <Box103>
                  <div>
                    <h4>Resultado financeiro</h4>
                  </div>
                  <div>
                    <p>
                      <label>Venda disponível</label>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="vendadisponivel"
                        valor={rodapebmef.vendadisponivel}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>Compra disponível</label>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="compradisponivel"
                        valor={rodapebmef.compradisponivel}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>Venda opções</label>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="vendaopcoes"
                        valor={rodapebmef.vendaopcoes}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>Compra opções</label>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="compraopcoes"
                        valor={rodapebmef.compraopcoes}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>Valor dos negócios</label>
                      <nav>
                        <InputTextBDI
                          type="text"
                          placeholder="00000,00"
                          id="valornegocios"
                          valor={rodapebmef.valornegocios}
                          acao={handleValorRodape}
                        />
                        <Dropdown
                          id="valornegociosdc"
                          placeholder="*D/C"
                          value={rodapebmef.valornegociosdc}
                          onChange={e =>
                            setRodapebmef({
                              ...rodapebmef,
                              valornegociosdc: e.value,
                            })
                          }
                          options={dc}
                          optionLabel="name"
                        />
                      </nav>
                    </p>
                  </div>
                  <div>
                    <p>
                      <label>IRRF</label>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="irrf"
                        valor={rodapebmef.irrf}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>IRRF daytrade (projeção)</label>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="irrfdaytradeprojecao"
                        valor={rodapebmef.irrfdaytradeprojecao}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>Taxa operacional</label>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="taxaoperacional"
                        valor={rodapebmef.taxaoperacional}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>Taxa registro</label>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="taxaregistro"
                        valor={rodapebmef.taxaregistro}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>Taxas BM&amp;F (emol+f.gar)</label>
                      <nav>
                        <InputTextBDI
                          type="text"
                          placeholder="00000,00"
                          id="taxasbmefemolfgar"
                          valor={rodapebmef.taxasbmefemolfgar}
                          acao={handleValorRodape}
                        />
                        <Dropdown
                          id="taxasbmefemolfgardc"
                          placeholder="*D/C"
                          value={rodapebmef.taxasbmefemolfgardc}
                          onChange={e =>
                            setRodapebmef({
                              ...rodapebmef,
                              taxasbmefemolfgardc: e.value,
                            })
                          }
                          options={dc}
                          optionLabel="name"
                        />
                      </nav>
                    </p>
                  </div>
                  <div>
                    <p>&nbsp;</p>
                    <p>
                      <aside>
                        <span>
                          <label>+ outros</label>
                          <InputTextBDI
                            type="text"
                            placeholder="00000,00"
                            id="maisoutros"
                            valor={rodapebmef.maisoutros}
                            acao={handleValorRodape}
                          />
                        </span>
                        <span>
                          <label>Imposto (ISS)</label>
                          <InputTextBDI
                            type="text"
                            placeholder="00000,00"
                            id="impostoiss"
                            valor={rodapebmef.impostoiss}
                            acao={handleValorRodape}
                          />
                        </span>
                      </aside>
                    </p>
                    <p>
                      <label>Ajuste de posição</label>
                      <nav>
                        <InputTextBDI
                          type="text"
                          placeholder="00000,00"
                          id="ajusteposicao"
                          valor={rodapebmef.ajusteposicao}
                          acao={handleValorRodape}
                        />
                        <Dropdown
                          id="ajusteposicaodc"
                          placeholder="*D/C"
                          value={rodapebmef.ajusteposicaodc}
                          onChange={e =>
                            setRodapebmef({
                              ...rodapebmef,
                              ajusteposicaodc: e.value,
                            })
                          }
                          options={dc}
                          optionLabel="name"
                        />
                      </nav>
                    </p>
                    <p>
                      <label>Ajuste daytrade</label>
                      <nav>
                        <InputTextBDI
                          type="text"
                          placeholder="00000,00"
                          id="ajustedaytrade"
                          valor={rodapebmef.ajustedaytrade}
                          acao={handleValorRodape}
                        />
                        <Dropdown
                          id="ajustedaytradedc"
                          placeholder="*D/C"
                          value={rodapebmef.ajustedaytradedc}
                          onChange={e =>
                            setRodapebmef({
                              ...rodapebmef,
                              ajustedaytradedc: e.value,
                            })
                          }
                          options={dc}
                          optionLabel="name"
                        />
                      </nav>
                    </p>
                    <p>
                      <label>Total das despesas</label>
                      <nav>
                        <InputTextBDI
                          type="text"
                          placeholder="00000,00"
                          id="totaldespesas"
                          valor={rodapebmef.totaldespesas}
                          acao={handleValorRodape}
                        />
                        <Dropdown
                          id="totaldespesasdc"
                          placeholder="*D/C"
                          value={rodapebmef.totaldespesasdc}
                          onChange={e =>
                            setRodapebmef({
                              ...rodapebmef,
                              totaldespesasdc: e.value,
                            })
                          }
                          options={dc}
                          optionLabel="name"
                        />
                      </nav>
                    </p>
                  </div>
                  <div>
                    <p>
                      <aside>
                        <span>
                          <label>Outros</label>
                          <InputTextBDI
                            type="text"
                            placeholder="00000,00"
                            id="outros"
                            valor={rodapebmef.outros}
                            acao={handleValorRodape}
                          />
                        </span>
                        <span>
                          <label>IRRF corretagem</label>
                          <InputTextBDI
                            type="text"
                            placeholder="00000,00"
                            id="irrfcorretagem"
                            valor={rodapebmef.irrfcorretagem}
                            acao={handleValorRodape}
                          />
                        </span>
                      </aside>
                    </p>
                    <p>
                      <label>Total conta investimento</label>
                      <nav>
                        <InputTextBDI
                          type="text"
                          placeholder="00000,00"
                          id="totalcontainvestimento"
                          valor={rodapebmef.totalcontainvestimento}
                          acao={handleValorRodape}
                        />
                        <Dropdown
                          id="totalcontainvestimentodc"
                          placeholder="*D/C"
                          value={rodapebmef.totalcontainvestimentodc}
                          onChange={e =>
                            setRodapebmef({
                              ...rodapebmef,
                              totalcontainvestimentodc: e.value,
                            })
                          }
                          options={dc}
                          optionLabel="name"
                        />
                      </nav>
                    </p>
                    <p>
                      <label>Total c. normal</label>
                      <nav>
                        <InputTextBDI
                          type="text"
                          placeholder="00000,00"
                          id="totalcnormal"
                          valor={rodapebmef.totalcnormal}
                          acao={handleValorRodape}
                        />
                        <Dropdown
                          id="totalcnormaldc"
                          placeholder="*D/C"
                          value={rodapebmef.totalcnormaldc}
                          onChange={e =>
                            setRodapebmef({
                              ...rodapebmef,
                              totalcnormaldc: e.value,
                            })
                          }
                          options={dc}
                          optionLabel="name"
                        />
                      </nav>
                    </p>
                    <p>
                      <label>Total líquido</label>
                      <nav>
                        <InputTextBDI
                          type="text"
                          placeholder="00000,00"
                          id="totalliquido"
                          valor={rodapebmef.totalliquido}
                          acao={handleValorRodape}
                        />
                        <Dropdown
                          id="totalliquidodc"
                          placeholder="*D/C"
                          value={rodapebmef.totalliquidodc}
                          onChange={e =>
                            setRodapebmef({
                              ...rodapebmef,
                              totalliquidodc: e.value,
                            })
                          }
                          options={dc}
                          optionLabel="name"
                        />
                      </nav>
                    </p>
                    <p>
                      <label>Total liq. da nota</label>
                      <nav>
                        <InputTextBDI
                          type="text"
                          placeholder="00000,00"
                          id="totalliqnota"
                          valor={rodapebmef.totalliqnota}
                          acao={handleValorRodape}
                        />
                        <Dropdown
                          id="totalliqnotadc"
                          placeholder="*D/C"
                          value={rodapebmef.totalliqnotadc}
                          onChange={e =>
                            setRodapebmef({
                              ...rodapebmef,
                              totalliqnotadc: e.value,
                            })
                          }
                          options={dc}
                          optionLabel="name"
                        />
                      </nav>
                    </p>
                  </div>
                </Box103>
              </div>
              <div className="p-col-12 p-md-6">
                <ButtonBDI
                  label="Voltar"
                  className={`
                        ${ButtonBDI.bdi_classe.outlined}
                        ${ButtonBDI.bdi_subclasse.secondary}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => voltar()}
                />
                <ButtonBDI
                  label="Salvar"
                  className={`
                        ${ButtonBDI.bdi_classe.severities}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => salvar()}
                />
              </div>
            </div>
          </section>
        </Box1>
      </div>
    </div>
  );
};
