import Cookies from 'js-cookie';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

import { Button } from '../../../../../../components/Button';
import { NO_EMPTY_FIELDS_KEY } from '../../../../../../context/toast/constants';
import { useToast } from '../../../../../../context/toast/useToast';
import { useLocale } from '../../../../../../hooks/useLocale';
import { formatCurrency } from '../../../../../../utils/functions';
import { formatStringValueToNumber } from '../../../../../../utils/functions/currency';
import {
  formatDateInstance,
  formatDateUS,
} from '../../../../../../utils/functions/dates';
import { useEncerrarOpcaoMutation } from '../../hooks/useEncerrarOpcaoMutation';
import { EncerrarOpcaoModalObj, EventsRow } from '../../types';
import * as ES from '../styles';
import * as S from './styles';

interface EncerrarOpcaoDialogProps {
  visible: boolean;
  opcaoData: EventsRow;
  onHide: () => void;
}

export function EncerrarOpcaoDialog({
  visible,
  opcaoData,
  onHide,
}: EncerrarOpcaoDialogProps) {
  const [valorPremio, setValorPremio] = useState('');
  const userId = Cookies.get('Id');

  const { locale } = useLocale();
  const { showToast } = useToast();

  const encerrarOpcaoMutation = useEncerrarOpcaoMutation();

  function handleOnHide() {
    setValorPremio('');
    onHide();
  }

  function handleNoEmptyFieldsValidation() {
    if (!valorPremio) {
      showToast(NO_EMPTY_FIELDS_KEY);
      return false;
    }

    return true;
  }

  function handleEncerrarOpcao() {
    if (!handleNoEmptyFieldsValidation()) return;

    const mutationObj: EncerrarOpcaoModalObj = {
      idUsuario: Number(userId),
      idCorretora: opcaoData.idCorretora,
      idInstrumentoOpcaoParaExercicio: opcaoData.idInstrumento,
      callPut: opcaoData.callPut,
      dataVencimento: formatDateUS(opcaoData.dataVencimento),
      dataExercicio: formatDateUS(opcaoData.dataVencimento),
      valorDoPremio: formatStringValueToNumber(valorPremio),
    };

    encerrarOpcaoMutation.mutate(mutationObj);
    handleOnHide();
  }

  const encerrarOpcaoDialogFooter = (
    <ES.FooterContainer>
      <span>*Preenchimento obrigatório</span>

      <div>
        <Button
          type="button"
          label="Cancelar"
          className="tertiary"
          onClick={handleOnHide}
        />
        <Button type="button" label="Encerrar" onClick={handleEncerrarOpcao} />
      </div>
    </ES.FooterContainer>
  );

  return (
    <S.EncerrarOpcaoDialogContainer
      visible={visible}
      onHide={handleOnHide}
      header="Informar encerramento de opção"
      footer={encerrarOpcaoDialogFooter}
      style={{ width: 'min(40rem, 80vw)' }}
      modal
    >
      <ES.FormContainer>
        <div>
          <label htmlFor="tickerEncerramento">Ticker da opção*</label>
          <InputText
            id="tickerEncerramento"
            value={opcaoData.ticker}
            required
            disabled
          />
        </div>
        <div>
          <label htmlFor="prazoEncerramento">Prazo de encerramento*</label>
          <Calendar
            id="prazoEncerramento"
            dateFormat="dd/mm/yy"
            locale={locale}
            value={formatDateInstance(opcaoData.dataVencimento)}
            disabled
          />
        </div>
        <div>
          <label htmlFor="valorPremio">Valor do prémio*</label>
          <InputText
            id="valorPremio"
            placeholder="0,00"
            value={valorPremio}
            onChange={e => setValorPremio(formatCurrency(e.target.value))}
          />
        </div>
      </ES.FormContainer>
    </S.EncerrarOpcaoDialogContainer>
  );
}
