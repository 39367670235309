import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { Column } from 'primereact/column';
import { Knob } from 'primereact/knob';
import { useEffect, useState } from 'react';

import Usuario from '../../../../../components/Usuario';
import { useToast } from '../../../../../context/toast/useToast';
import { getRelatorioOperacaoSwingDayTradeDateTime } from '../../../../../service/ApiRelatorios/RelatorioOpComumDayTrade';
import { GetRelatorioOperacaoSwingDayTradeDateTimeData } from '../../../../../service/ApiRelatorios/RelatorioOpComumDayTrade/types';
import { getRelatorioOperacaoFundosImobiliarios } from '../../../../../service/ApiRelatorios/RelatorioOpFundosImobiliarios';
import { DataFII } from '../../../../../service/ApiRelatorios/RelatorioOpFundosImobiliarios/types';
import { GreenBorderBox } from '../../../../../styles/elements/GreenBorderBox';
import {
  formataStringParaDecimal,
  formataValorDecimal,
} from '../../../../../utils/functions';
import {
  addPadStartToMonth,
  getAno,
  getAnoMes,
  getMonthWith0,
} from '../../../../../utils/functions/dates';
import { AlienacoesSkeleton } from './AlienacoesSkeleton';
import { LIMITE_ALIENACAO, geraDadosTabela } from './constants';
import * as S from './styles';
import { Impostos, TabelaImpostos } from './types';

const filtraFIIPeloMesAtual = (operacoes: DataFII[], mesAno: string | null) => {
  const operacao = operacoes.find(
    operacao =>
      addPadStartToMonth(String(operacao.mes)) === getMonthWith0(mesAno),
  );

  return !!operacao ? operacao : ({} as DataFII);
};

const calculaLimiteRestante = (alienacao: number) => {
  return formataValorDecimal(LIMITE_ALIENACAO - alienacao);
};

const getValorLimite = (alienacao: number) => {
  if (alienacao <= LIMITE_ALIENACAO) return LIMITE_ALIENACAO;

  return alienacao;
};

const calculaImpostoParcial = (
  dataSwingDayTrade: GetRelatorioOperacaoSwingDayTradeDateTimeData,
  dataFII: DataFII,
) => {
  const { impostoDevidoSwing, impostoDevidoDayTrade } = dataSwingDayTrade;
  const { impostoDevido } = dataFII;

  const _impostoDevidoSwing = formataStringParaDecimal(impostoDevidoSwing);
  const _impostoDevidoDayTrade = formataStringParaDecimal(
    impostoDevidoDayTrade,
  );
  const _impostoDevido = formataStringParaDecimal(impostoDevido);

  return formataValorDecimal(
    _impostoDevidoSwing + _impostoDevidoDayTrade + _impostoDevido,
  );
};

const calculaTotalOperacoesComuns = (
  dataSwingDayTrade: GetRelatorioOperacaoSwingDayTradeDateTimeData,
) => {
  const { baseCalculoImpostoSwing, lucroIsento } = dataSwingDayTrade;

  const _baseCalculoImpostoSwing = formataStringParaDecimal(
    baseCalculoImpostoSwing,
  );
  const _lucroIsento = formataStringParaDecimal(lucroIsento);

  return formataValorDecimal(_baseCalculoImpostoSwing - _lucroIsento);
};

const formataPorcentagem = (valor: string) => {
  const valorPorcentagem = formataStringParaDecimal(valor) * 100;

  return `${formataValorDecimal(valorPorcentagem)}%`;
};

type AlienacoesProps = {
  mesAno: string | null;
};

export function Alienacoes({ mesAno }: AlienacoesProps) {
  const [tabelaImpostos, setTabelaImpostos] = useState<TabelaImpostos[]>([]);
  const [alienacao, setAlienacao] = useState(0);
  const { showToast } = useToast();

  const handleQueryError = (error: Error) => {
    showToast();
    console.error(error);
  };

  const handleDadosOperacaoSwingDayTradeRequest = async ({
    signal,
  }: QueryFunctionContext) => {
    const response = await getRelatorioOperacaoSwingDayTradeDateTime(
      Usuario.id,
      getAnoMes(mesAno),
      { signal },
    );

    return response.data;
  };

  const {
    data: dataSwingDayTrade,
    isLoading: isLoadingDayTrade,
    error: dayTradeError,
  } = useQuery(
    ['getRelatorioOperacaoSwingDayTradeDateTime', mesAno],
    handleDadosOperacaoSwingDayTradeRequest,
    {
      enabled: !!mesAno,
      retry: false,
      onError: handleQueryError,
    },
  );

  const handleDadosOperacaoFundosImobiliariosRequest = async ({
    signal,
  }: QueryFunctionContext) => {
    const response = await getRelatorioOperacaoFundosImobiliarios(
      Usuario.id,
      getAno(mesAno),
      { signal },
    );

    return filtraFIIPeloMesAtual(response.data.operacoes, mesAno);
  };

  const {
    data: dataFII,
    isLoading: isLoadingFII,
    error: FIIError,
  } = useQuery(
    ['getRelatorioOperacaoFundosImobiliarios', mesAno],
    handleDadosOperacaoFundosImobiliariosRequest,
    {
      enabled: !!mesAno,
      retry: false,
      onError: handleQueryError,
    },
  );

  useEffect(() => {
    if (dataFII && dataSwingDayTrade) {
      const {
        baseCalculoImpostoSwing,
        lucroIsento,
        impostoDevidoSwing,
        impostoDevidoDayTrade,
        totalVendasAcoes,
        baseCalculoImpostoDaytrade,
        aliquotaDoImpostoDayTrade,
        aliquotaDoImpostoSwing,
      } = dataSwingDayTrade;

      const dataFIIFormatted = {
        baseCalImposto: !!dataFII?.baseCalImposto
          ? dataFII.baseCalImposto
          : '0,00',
        aliquotaDoImposto: !!dataFII?.aliquotaDoImposto
          ? dataFII.aliquotaDoImposto
          : '0,20',
        impostoDevido: !!dataFII?.impostoDevido
          ? dataFII.impostoDevido
          : '0,00',
        mes: dataFII.mes,
      };

      const impostos = {
        operacoesComuns: baseCalculoImpostoSwing,
        lucroIsento,
        resultadoTotalOperacoesComuns:
          calculaTotalOperacoesComuns(dataSwingDayTrade),
        impostoTotalOperacoesComuns: impostoDevidoSwing,
        aliquotaDoImpostoDayTrade: formataPorcentagem(
          aliquotaDoImpostoDayTrade,
        ),
        aliquotaDoImpostoSwing: formataPorcentagem(aliquotaDoImpostoSwing),
        resultadoDayTrade: baseCalculoImpostoDaytrade,
        impostoDayTrade: impostoDevidoDayTrade,
        resultadoFIIS: dataFIIFormatted.baseCalImposto,
        aliquotaDoImpostoFIIS: formataPorcentagem(
          dataFIIFormatted.aliquotaDoImposto,
        ),
        impostoFIIS: dataFIIFormatted.impostoDevido,
        impostoParcial: calculaImpostoParcial(
          dataSwingDayTrade,
          dataFIIFormatted,
        ),
      } as Impostos;

      setAlienacao(formataStringParaDecimal(totalVendasAcoes));
      setTabelaImpostos(geraDadosTabela(impostos));
    }
  }, [dataFII, dataSwingDayTrade]);

  const renderLimiteDeAlienacao = () => {
    if (alienacao <= LIMITE_ALIENACAO) {
      return (
        <div>
          <h2>
            <b>Faltam {calculaLimiteRestante(alienacao)}</b> para atingir o
            limite de <b>20.000,00</b>
          </h2>
          <span>
            Ao atingir o <b>limite de alienações</b>, o lucro deixa de ser
            isento e é somado ao <b>imposto do mês</b>.
          </span>
        </div>
      );
    }

    return (
      <div>
        <h2>O limite de alienações de ações foi atingido</h2>
        <span>
          Neste mês, não será aplicada a{' '}
          <b>&quot;regra dos R$ 20.000,00&quot;</b>
        </span>
      </div>
    );
  };

  const isLoadingOrError = () =>
    (isLoadingDayTrade && isLoadingFII) || dayTradeError || FIIError;

  if (isLoadingOrError()) return <AlienacoesSkeleton />;

  return (
    <GreenBorderBox>
      <S.Container>
        <div>
          {renderLimiteDeAlienacao()}

          <div>
            <Knob
              value={alienacao}
              readOnly
              max={getValorLimite(alienacao)}
              showValue={false}
            ></Knob>
            <span>{formataValorDecimal(alienacao)}</span>
            <span>Alienações de ações no mês</span>
          </div>
        </div>

        <S.TabelaAlienacoes
          value={tabelaImpostos}
          responsiveLayout="scroll"
          emptyMessage="Lista vazia."
          size="small"
        >
          <Column field="descricao" header="Descrição"></Column>
          <Column field="resultado" header="Resultado"></Column>
          <Column field="aliquota" header="Alíquota"></Column>
          <Column field="imposto" header="Imposto"></Column>
        </S.TabelaAlienacoes>
      </S.Container>
    </GreenBorderBox>
  );
}
