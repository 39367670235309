import { Skeleton } from 'primereact/skeleton';

import { GreenBorderBox } from '../../../../../styles/elements/GreenBorderBox';
import * as S from './styles';

export function ImpostosSkeleton() {
  return (
    <GreenBorderBox>
      <S.Container>
        <div className="titulo">
          <Skeleton width={'85px'} height={'25px'} />
          <Skeleton width={'218px'} height={'20px'} />
        </div>
        <div className="imposto">
          <span>
            <Skeleton width={'170px'} height={'27px'} />
          </span>
          <span>
            <Skeleton width={'265px'} height={'27px'} />
          </span>
          <span>
            <b>
              <Skeleton width={'74px'} height={'27px'} />
            </b>
          </span>
        </div>
        <div className="botoes">
          <Skeleton width={'190px'} height={'37px'} />
          <Skeleton width={'190px'} height={'37px'} />
        </div>
      </S.Container>
    </GreenBorderBox>
  );
}
