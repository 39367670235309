const DEGUSTACAO = {
  titulo: 'Teste grátis',
  descricao:
    'Neste plano, você tem acesso por 30 dias a todas as funcionalidades da plataforma, como: corretoras ilimitadas, apuração do mês corrente, posições em aberto com patrimônio atualizado em tempo real, notas Bovespa/BM&F, apuração de day trade e swing, relatório de performance, gráfico em tempo real e link RTD.',
};

const GRATUITO = {
  titulo: 'Calculadora de IR - Gratuita',
  descricao:
    'Neste plano, você pode apurar seu IR de uma corretora, fazer a apuração do mês vigente, ver posições em aberto sem atualização do patrimônio, notas Bovespa, e apurar swing trade.',
  subscricaoAtual: {
    createdAt: '',
    expiresAt: '',
    plano: {
      nome: 'Calculadora de IR - Gratuita',
      billingPlanCode: 'gratis',
    },
  },
};

const AVULSO_IR = {
  titulo: 'Calculadora de IR - Mês Avulso',
  descricao:
    'Neste plano, você tem corretoras ilimitadas, fazer a apuração do mês vigente, ver posições em aberto com patrimônio atualizado ao final do dia, notas Bovespa/BM&F, apurar day trade e swing trade.',
};

const ANUAL_IR = {
  titulo: 'Calculadora de IR - Licença Anual',
  descricao:
    'O seu plano tem vigência de 12 meses a partir do dia contrato e com ele você tem corretoras ilimitadas, pode fazer apuração do ano vigente, ver posições em aberto com patrimônio atualizado a cada 15 minutos, notas Bovespa/BM&F, apurar day trade e swing trade.',
};

const BI_ANUAL_IR = {
  titulo: 'Calculadora de IR - Licença Anual',
  descricao:
    'O seu plano tem vigência de 24 meses a partir do dia contrato e com ele você tem corretoras ilimitadas, pode fazer apuração do ano vigente, ver posições em aberto com patrimônio atualizado a cada 15 minutos, notas Bovespa/BM&F, apurar day trade e swing trade.',
};

const ANUAL_IR_AVANCADO = {
  titulo: 'Calculadora de IR - Avançado',
  descricao:
    'O seu plano tem vigência de 12 meses a partir do dia contratado e com ele possui corretoras ilimitadas, fazer a apuração dos últimos 5 anos fiscais, ver posições em aberto com patrimônio atualizado em tempo real, notas Bovespa/BM&F, day trade e swing, relatório de performance, gráfico em tempo real e link RTD.',
};

const BI_ANUAL_IR_AVANCADO = {
  titulo: 'Calculadora de IR - Avançado',
  descricao:
    'O seu plano tem vigência de 24 meses a partir do dia contratado e com ele possui corretoras ilimitadas, fazer a apuração dos últimos 5 anos fiscais, ver posições em aberto com patrimônio atualizado em tempo real, notas Bovespa/BM&F, day trade e swing, relatório de performance, gráfico em tempo real e link RTD.',
};

const IR_PRO_INTER_MENSAL = {
  titulo: 'Calculadora de IR - Inter',
  descricao:
    'O seu plano tem vigência de 1 mês a partir do dia contrato e com ele você tem corretoras ilimitadas, pode fazer apuração do ano vigente, ver posições em aberto com patrimônio atualizado a cada 15 minutos, notas Bovespa/BM&F, apurar day trade e swing trade.',
};

const IR_PRO_INTER_ANUAL = {
  titulo: 'Calculadora de IR - Inter',
  descricao:
    'O seu plano tem vigência de 12 meses a partir do dia contrato e com ele você tem corretoras ilimitadas, pode fazer apuração do ano vigente, ver posições em aberto com patrimônio atualizado a cada 15 minutos, notas Bovespa/BM&F, apurar day trade e swing trade.',
};

const INVEST_PRO_INTER_MENSAL = {
  titulo: 'Calculadora de IR - Inter',
  descricao:
    'O seu plano tem vigência de 1 mês a partir do dia contrato e com ele você tem corretoras ilimitadas, pode fazer apuração do ano vigente, ver posições em aberto com patrimônio atualizado a cada 15 minutos, notas Bovespa/BM&F, apurar day trade e swing trade.',
};

export const DESCRICAO_PLANO = new Map([
  ['degustacao', DEGUSTACAO],
  ['gratis', GRATUITO],
  ['avulso_ir', AVULSO_IR],
  ['anual_ir', ANUAL_IR],
  ['bi_anual_ir', BI_ANUAL_IR],
  ['anual_ir_avancado', ANUAL_IR_AVANCADO],
  ['bi_anual_ir_avancado', BI_ANUAL_IR_AVANCADO],
  ['irpro_inter_mensal', IR_PRO_INTER_MENSAL],
  ['irpro_inter_anual', IR_PRO_INTER_ANUAL],
  ['investpro_inter_mensal', INVEST_PRO_INTER_MENSAL],
]);
