import { ButtonBDI, RadioButtonBDI } from '@dev_bdi/bdi-front-dspack';
import { Button } from 'primereact/button';
import { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../context/config';
import history from '../../service/history';
import { CENTRAL_AJUDA_TIPO_DATA_UTILIZADO_LINK } from '../../utils/linksCentralAjuda';
import Usuario from '../Usuario';
import { Box1, Small } from './styles';

export const CadastroInicial = () => {
  const { state, setState } = useContext(ConfigContext);
  const [usuario, setUsuario] = useState({});
  const [tipodata, setTipodata] = useState();
  const { usuarioAntigo, idTipoCorteCalculo } = state.configuracao;

  function avancar() {
    localStorage.setItem('tipodata', tipodata);

    history.push(`/cadastroinicial/datareferencia`);
  }

  function viewMenu(estado) {
    setState({
      ...state,
      menu: estado,
    });
  }

  function configUsuario(config) {
    if (config.configuracao && config.configuracao.usuarioAntigo) {
      history.push('/cadastroinicial');
    } else {
      history.push('/cadastroinicial/relatorio');
    }
  }

  useEffect(() => {
    state.configuracao && configUsuario(state);
  }, [state.configuracao]);

  useEffect(() => {
    viewMenu(false);
  }, [state.menu]);

  useEffect(() => {
    setUsuario(Usuario);

    if (localStorage.getItem('tipodata')) {
      setTipodata(localStorage.getItem('tipodata'));
    } else if (usuarioAntigo) {
      setTipodata(idTipoCorteCalculo);
    } else {
      setTipodata('1');
    }
  }, [usuarioAntigo, idTipoCorteCalculo]);

  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-12">
        <Box1>
          <section className="card">
            <div className="timeline">
              <div className="container active left">
                <div className="content active">
                  <h3>Tipo de data utilizado</h3>
                </div>
              </div>
              <div className="container left">
                <div className="content">
                  <h3>Data de referência</h3>
                </div>
              </div>
              <div className="container left">
                <div className="content">
                  <h3>Posições iniciais</h3>
                </div>
              </div>
              <div className="container left">
                <div className="content">
                  <h3>Prejuízos acumulados</h3>
                </div>
              </div>
              <div className="container left">
                <div className="content">
                  <h3>IRRF acumulado</h3>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="card">
              <div className="p-col-12 p-md-12">
                <h2 style={{ fontSize: 25 }}>
                  Olá,{' '}
                  <strong>{usuario.nome && usuario.nome.split(' ')[0]}!</strong>
                  <a
                    href={CENTRAL_AJUDA_TIPO_DATA_UTILIZADO_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      icon="pi pi-question-circle"
                      style={{ top: '9px' }}
                      className="p-button-rounded p-button-text colorIcon"
                    />
                  </a>
                </h2>
                <br />
                <p>
                  <article>
                    Antes de começar a utilizar a plataforma e apurar seu
                    imposto, você deverá cadastrar sua posição inicial,
                    prejuízos anteriores e impostos acumulados.
                  </article>
                </p>
                <p>
                  <article>
                    Para iniciarmos, conte-nos o <strong>tipo de data</strong>{' '}
                    que você deseja utilizar para apurar suas{' '}
                    <strong>operações</strong>:
                  </article>
                </p>
              </div>
              <div className="p-col-12 p-md-12">
                <article>
                  <span>
                    <RadioButtonBDI
                      label="Liquidação* Recomendado"
                      inputId="liquidacao"
                      name="pinicial"
                      valor="1"
                      checked={tipodata == '1'}
                      acao={e => setTipodata(e.value)}
                      disabled={usuarioAntigo}
                    />
                  </span>
                  <p>
                    Representa o dia em que o valor das operações é creditado ou
                    debitado em sua conta.
                  </p>
                </article>
                <article>
                  <span>
                    <RadioButtonBDI
                      label="Execução*"
                      inputId="execucao"
                      name="pinicial"
                      valor="2"
                      checked={tipodata == '2'}
                      acao={e => setTipodata(e.value)}
                      disabled={usuarioAntigo}
                    />
                  </span>
                  <p>Representa o dia em que as operações são realizadas.</p>
                </article>
              </div>
              <br />
              <br />
              <br />
              <div className="p-col-12 p-md-6 preenchimentoobrigatorio">
                <Small>*Preenchimento obrigatório</Small>
              </div>
              <div className="p-col-12 p-md-6">
                <ButtonBDI
                  label="Avançar"
                  className={`
              ${ButtonBDI.bdi_classe.default}
              ${ButtonBDI.bdi_alinhamento_lateral}
              ${ButtonBDI.bdi_alinhamento_vertical}
              `}
                  funcao={() => avancar()}
                  disabled={tipodata ? false : true}
                />
              </div>
            </div>
          </section>
        </Box1>
      </div>
    </div>
  );
};
