import { useQuery } from '@tanstack/react-query';

import { DOWNLOAD_DATA_ERROR } from '../../../../../context/toast/constants';
import { useToast } from '../../../../../context/toast/useToast';
import { getRelatorioOperacaoFundosImobiliarios } from '../../../../../service/ApiRelatorios/RelatorioOpFundosImobiliarios';

const getOperacoesFii = async ({ queryKey, signal }) => {
  const userId = queryKey[2];
  const year = queryKey[3];

  const { data } = await getRelatorioOperacaoFundosImobiliarios(userId, year, {
    signal,
  });

  const migrationStatus = data.migracao;
  const operations = data.operacoes.map(item => {
    return {
      mesDescricao: item.mesDescricao,
      resultadoLiquidoMes: 'R$ ' + item.resultadoLiquidoMes,
      resultadoNegativoMesAnterior: 'R$ ' + item.resultadoNegativoMesAnterior,
      baseCalImposto: 'R$ ' + item.baseCalImposto,
      prejuizoCompensar: 'R$ ' + item.prejuizoCompensar,
      aliquotaDoImposto: 'R$ ' + item.aliquotaDoImposto,
      impostoDevido: 'R$ ' + item.impostoDevido,
      saldoDoImpostoRetidoMesAnterior:
        'R$ ' + item.salvoDoImpostoRetidoMesAnterior,
      impostoRetidoMes: 'R$ ' + item.impostoRetidoMes,
      impostoCompensar: 'R$ ' + item.impostoCompensar,
      impostoPagar: 'R$ ' + item.impostoPagar,
      impostoPago: 'R$ ' + item.impostoPago,
    };
  });

  return { migration: migrationStatus, operations };
};

export function useFIIReport({ userId, year, isThereYears }) {
  const { showToast } = useToast();

  return useQuery(['reports', 'fii', userId, year], getOperacoesFii, {
    onError: () => showToast(DOWNLOAD_DATA_ERROR),
    enabled: isThereYears,
  });
}
