/* eslint-disable */
import {
  ButtonBDI,
  InputMaskBDI,
  InputTextBDI,
} from '@dev_bdi/bdi-front-dspack';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ConfigContext from '../../../context/config';
import { useToast } from '../../../context/toast/useToast';
import { apiOperacoes } from '../../../service/ApiOperacoes/api';
import apiCorretoras from '../../../service/apiCorretoras';
import apiFeriados from '../../../service/feriados';
import history from '../../../service/history';
import { BaseModule } from '../../BaseModule';
import { CADASTRO_MANUAL } from '../../BaseModule/constants';
import { Box100, InputLeft, InputRight, Small } from './styles';
import { Dropdown } from 'primereact/dropdown';

export const CadastroManual = () => {
  const { state, setState } = useContext(ConfigContext);
  const [cadastromanual, setCadastromanual] = useState({});
  const [listacorretoras, setListacorretoras] = useState([]);
  const [listatipos, setListatipos] = useState([]);

  const { showToast } = useToast();

  function avancar() {
    localStorage.setItem('cadastromanual', JSON.stringify(cadastromanual));
    if (cadastromanual.tiponota.name === 'Bovespa')
      history.push(`/calculadora/cadastromanual/operacoesbovespa`);
    else if (cadastromanual.tiponota.name === 'BM&F')
      history.push(`/calculadora/cadastromanual/operacoesbmef`);
  }

  async function getCorretoras() {
    try {
      const response = await apiCorretoras.get('/Corretoras');

      const newList = response.data.map(dado => {
        return {
          name: dado.nome,
          code: dado.idCorretora,
        };
      });
      setListacorretoras(newList);
    } catch (error) {
      console.error(error);
      showToast();
    }
  }

  async function getTiposNota() {
    try {
      const response = await apiOperacoes.get('TipoNota');

      const newList = response.data.map(dado => {
        return {
          name: dado.nome,
          code: dado.idTipoNota,
        };
      });
      setListatipos(newList);
    } catch (error) {
      console.error(error);
      showToast();
    }
  }

  async function diasUteis(date) {
    const qtdNumeros = date
      .split('')
      .filter(n => Number(n) || n == 0)
      .join('');

    if (qtdNumeros.length >= 8) {
      if (
        qtdNumeros === '00000000' ||
        qtdNumeros === '11111111' ||
        qtdNumeros === '22222222' ||
        qtdNumeros === '33333333' ||
        qtdNumeros === '44444444' ||
        qtdNumeros === '55555555' ||
        qtdNumeros === '66666666' ||
        qtdNumeros === '77777777' ||
        qtdNumeros === '88888888' ||
        qtdNumeros === '99999999'
      ) {
        setCadastromanual({ ...cadastromanual, pregao: '' });
        return false;
      }
      if (date.split('/')[1] > 12) {
        setCadastromanual({ ...cadastromanual, pregao: '' });
        return false;
      }

      try {
        const response = await apiFeriados.get('/DataFeriado');
        let data = new Date(date.split('/').reverse().join('-'));

        const filterDatas = response.data.filter(
          dt =>
            dt.dataFeriadoValido.split('T')[0] ===
            date.split('/').reverse().join('-'),
        );

        if (filterDatas.length) {
          data = data.setDate(data.getDate() + 1);
          data = new Date(data).toISOString();
          data = new Date(data.split('T')[0]);
        }

        const semana = data.getDay() + 1;
        if (semana === 6) {
          data = data.setDate(data.getDate() + 2);
          data = new Date(data).toISOString();
          data = data.split('T')[0].split('-').reverse().join('/');
          setCadastromanual({ ...cadastromanual, pregao: data });
        } else if (semana === 6 || semana === 7) {
          data = data.setDate(data.getDate() + 1);
          data = new Date(data).toISOString();
          data = data.split('T')[0].split('-').reverse().join('/');
          setCadastromanual({ ...cadastromanual, pregao: data });
        } else {
          data = new Date(data).toISOString();
          data = data.split('T')[0].split('-').reverse().join('/');
          setCadastromanual({ ...cadastromanual, pregao: data });
        }
      } catch (error) {
        console.error(error);
        showToast();
      }
    }
  }

  function formatarNumeronota(e) {
    var elemento = e;
    var valor = Number(elemento);

    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor = valor + '';

    elemento = valor;

    setCadastromanual({ ...cadastromanual, numeronota: elemento });
  }

  useEffect(() => {
    setCadastromanual({ ...cadastromanual, cpf: state.usuario.cpf });
  }, [state.usuario]);

  useEffect(() => {
    if (localStorage.getItem('cadastromanual'))
      setCadastromanual(JSON.parse(localStorage.getItem('cadastromanual')));
    getCorretoras();
    getTiposNota();
  }, []);

  useEffect(() => {
    setCadastromanual({ ...cadastromanual, cpf: state.usuario.cpf });
  }, [state.usuario]);

  useEffect(() => {
    if (localStorage.getItem('cadastromanual'))
      setCadastromanual(JSON.parse(localStorage.getItem('cadastromanual')));
    getCorretoras();
    getTiposNota();
  }, []);

  const importacaoLink = () => (
    <Link to="/ImportacaoNotas">
      Clique aqui caso deseje importar o arquivo em PDF
    </Link>
  );

  const getNome = () => state.usuario.nome && state.usuario.nome.split(' ')[0];

  return (
    <BaseModule
      tipo={CADASTRO_MANUAL}
      props={{ nome: getNome(), redirectLink: importacaoLink() }}
    >
      <div className="p-col-12 p-md-12">
        <article>
          <p>
            Agora, <b>informe</b> os dados contidos no <b>cabeçalho</b> da sua{' '}
            <b>nota</b>:
          </p>
        </article>
      </div>
      <div className="p-col-12 p-md-12">
        <Box100>
          <div>
            <InputRight>
              <label>CPF</label>
              <InputMaskBDI
                id="cpf"
                mask="999.999.999-99"
                placeholder="123.456.789-10"
                valor={cadastromanual.cpf}
                // acao={(e) => setCadastromanual({ ...cadastromanual, cpf: e.value })}
                autofocus={true}
                disabled={true}
              ></InputMaskBDI>
            </InputRight>
            <InputLeft>
              <label>Data do pregão*</label>
              <InputMaskBDI
                id="datadopregao"
                mask="99/99/9999"
                placeholder="Data do pregão*"
                valor={cadastromanual.pregao}
                acao={e => diasUteis(e.value)}
              ></InputMaskBDI>
            </InputLeft>
            <p>
              <label>Corretora*</label>
              <Dropdown
                 id="corretora"
                 placeholder="Corretora*"
                 value={cadastromanual.corretora}
                 onChange={e => setCadastromanual({
                  ...cadastromanual,
                  corretora: e.value,
                 })}
                 options={listacorretoras}
                optionLabel="name"
              />
            </p>
            <p>
              <label>Tipo de nota*</label>
              <Dropdown
                id="tipomercado"
                placeholder="Tipo de nota*"
                value={cadastromanual.tiponota}
                onChange={e =>
                  setCadastromanual({
                    ...cadastromanual,
                    tiponota: e.value,
                  })
                }
                options={listatipos}
                optionLabel="name"
              />
            </p>
            <InputLeft>
              <label>Número da nota*</label>
              <InputTextBDI
                type="number"
                placeholder="Número da nota*"
                id="numerodanota"
                valor={cadastromanual.numeronota}
                acao={e => formatarNumeronota(e.target.value)}
              />
            </InputLeft>
          </div>
        </Box100>
      </div>
      <div className="p-col-12 p-md-6 preenchimentoobrigatorio">
        <Small>*Preenchimento obrigatório</Small>
      </div>
      <div className="p-col-12 p-md-6">
        <ButtonBDI
          label="Avançar"
          className={`
                        ${ButtonBDI.bdi_classe.severities}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
          funcao={() => avancar()}
          disabled={
            cadastromanual.cpf &&
            cadastromanual.pregao &&
            cadastromanual.corretora &&
            cadastromanual.tiponota &&
            cadastromanual.numeronota
              ? false
              : true
          }
        />
      </div>
    </BaseModule>
  );
};
