import apiUsuarios from '../service/apiUsuarios';
import history from '../service/history';
import Usuario from './Usuario';

async function configuracaoUsuario(rota = false) {
  try {
    const config = await apiUsuarios.get(
      `Configuracao/v1/GetUsuarioConfiguracaoByIdUsuario/${Usuario.id}`,
    );
    if (rota) history.push('/cadastroinicial/relatorio');
    return config;
  } catch (error) {
    if (rota) history.push('/cadastroinicial');
    return false;
  }
}

export default configuracaoUsuario;
