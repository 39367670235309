import { useState } from 'react';
import { formatCurrency } from 'smarttbot-utils';

import { formatStringValueToNumber } from '../../../../../utils/functions/currency';
import { WidgetGrafico } from '../commons/WidgetGrafico';
import { CONTRATOS_FUTUROS } from '../commons/constants';
import { Ativos } from '../types';
import { geraObjetoDataDoGrafico } from '../utils';

const geraLabels = (ativos: Ativos) =>
  ativos?.map(ativo => {
    if (ativo.tipoAtivo) {
      return ativo.tipoAtivo;
    }

    return 'Não encontrado';
  });

const geraData = (ativos: Ativos) =>
  ativos?.map(({ patrimonioInvestido, tipoAtivo }) => {
    if (patrimonioInvestido && tipoAtivo !== CONTRATOS_FUTUROS) {
      return formatStringValueToNumber(patrimonioInvestido);
    }

    return 0;
  });

const geraGraficoData = (ativos: Ativos) => {
  const labels = geraLabels(ativos);
  const data = geraData(ativos);

  return geraObjetoDataDoGrafico(labels, data);
};

const geraValorTotal = (ativos: Ativos) => {
  const valorAcumulado = ativos.reduce((acumulador, ativo) => {
    const { patrimonioInvestido, tipoAtivo } = ativo;

    if (patrimonioInvestido && tipoAtivo !== CONTRATOS_FUTUROS) {
      return acumulador + formatStringValueToNumber(patrimonioInvestido);
    }

    return acumulador;
  }, 0);

  return formatCurrency(valorAcumulado);
};

const titulo = () => (
  <>
    Minha <b>carteira</b>
  </>
);

const valorTotal = (ativos: Ativos) => (
  <>
    <span>{geraValorTotal(ativos)}</span>
    <span>Valor Total</span>
  </>
);

type MinhaCarteiraProps = {
  ativos: Ativos;
};

export function MinhaCarteira({ ativos }: MinhaCarteiraProps) {
  const [data] = useState(geraGraficoData(ativos));

  return (
    <WidgetGrafico
      dataGrafico={data}
      titulo={titulo()}
      subtitulo="Distribuição por classe de ativo"
      slotDireito={valorTotal(ativos)}
    />
  );
}
