/* eslint-disable */
import {
  ButtonBDI,
  DialogBDI,
  InputMaskBDI,
  InputTextBDI,
} from '@dev_bdi/bdi-front-dspack';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import React, { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../../context/config';
import { useToast } from '../../../context/toast/useToast';
import { apiOperacoes } from '../../../service/ApiOperacoes/api';
import history from '../../../service/history';
import {
  formatCurrency,
  formataStringParaDecimal,
} from '../../../utils/functions';
import './style.css';
import {
  BgTransparent,
  BgTransparent2,
  Box1,
  Box102,
  Phidden,
  Small,
  TextCenter2,
} from './styles';

export const RodapeBovespa = () => {
  const dc = [
    { name: 'D', code: 'D' },
    { name: 'C', code: 'C' },
  ];

  const { state, setState } = useContext(ConfigContext);
  const [rodapebovespa, setRodapebovespa] = useState({
    idNotaCorretagemRodapeB3: 0,
    idNotaCorretagem: 0,
    valorliquidooperacoes: '0,0000',
    valorliquidooperacoesdc: 'D',
    taxaliquidacao: '0,0000',
    taxaregistro: '0,0000',
    totalcblc: '0,0000',
    totalcblcdc: 'D',
    taxatermoopcoes: '0,0000',
    taxaana: '0,0000',
    emolumentos: '0,0000',
    totalbovespasoma: '0,0000',
    taxaoperacionalclearing: '0,0000',
    corretagem: '0,0000',
    taxacustodia: '0,0000',
    impostosiss: '0,0000',
    irrfsemoperacoesbase0: '0,0000',
    despesasoutras: '0,0000',
    totalcustosdespesas: '0,0000',
    liquidoparavalor: '0,0000',
    liquidoparavalordc: 'D',
    liquidodata: new Date().toLocaleDateString(),
    debentures: '0,0000',
    vendasavista: '0,0000',
    comprasavista: '0,0000',
    opcaocompras: '0,0000',
    opcaovendas: '0,0000',
    operacoestermo: '0,0000',
    valoropertitulospublicos: '0,0000',
    valoroperacoes: '0,0000',
    irrfdaytradebase: '0,0000',
    projecao: '0,0000',
    idNotaCorretagemNavigation: null,
  });
  const [cadastromanual, setCadastromanual] = useState({});
  const [posicoesiniciais, setPosicoesiniciais] = useState([]);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [modalTituloDialog, setModalTituloDialog] = useState(false);

  const { showToast } = useToast();

  async function salvar() {
    try {
      localStorage.setItem('rodapebovespa', JSON.stringify(rodapebovespa));

      const dataAtual = new Date().toISOString();
      const pregao = new Date(
        cadastromanual.pregao.split('/').reverse().join('-'),
      ).toISOString();
      const liquidodata = new Date(
        rodapebovespa.liquidoparadata.split('/').reverse().join('-'),
      ).toISOString();

      const newOperacoes = posicoesiniciais.map((operacao, index) => {
        return {
          idNotaCorretagemItem: 0,
          idNotaCorretagem: 0,
          ordenacao: index + 1,
          negociacaoMercado: '1-Bovespa',
          compraVenda: operacao.posicao.code,
          tipoMercado: operacao.tipomercado.name,
          idTipoMercado: operacao.tipomercado.code,
          mercadoriaTituloPapelCodigo: operacao.espectitulo.name,
          mercadoriaTituloPapelExtenso: operacao.espectitulo.name,
          idInstrumento: operacao.espectitulo.code,
          dataVencimento: null,
          quantidade: operacao.quantidade,
          valorPrecoAjuste: formataStringParaDecimal(operacao.precoajuste),
          pontosPrecoAjuste: 0,
          tipoNegocio: operacao.observacao.name,
          idTipoNegocio: operacao.observacao.code,
          valorOperacaoAjuste: formataStringParaDecimal(operacao.vlropajuste),
          pontosOperacaoAjuste: 0,
          prazo: operacao.prazo ? operacao.prazo : '',
          debitoCredito: operacao.dc.code,
          valorTaxaOperacional: 0,
          txtTipoPapel: false,
          txtTipoCorretagem: false,
          txtTipoCusto: false,
          idSubTipoInstrumento: null,
          custoCalculado: 0,
          idNotaCorretagemNavigation: null,
          idSubTipoInstrumentoNavigation: null,
          idTipoMercadoNavigation: null,
          idTipoNegocioNavigation: null,
          operacos: [],
        };
      });

      const newRodapeBovespa = {
        idNotaCorretagemRodapeB3: 0,
        idNotaCorretagem: 0,
        valorLiquidoOperacoes: formataStringParaDecimal(
          rodapebovespa.valorliquidooperacoes,
        ),
        valorLiquidoOperacoesDc: rodapebovespa.valorliquidooperacoesdc.code
          ? rodapebovespa.valorliquidooperacoesdc.code
          : rodapebovespa.valorliquidooperacoesdc,
        taxaLiquidacao: formataStringParaDecimal(rodapebovespa.taxaliquidacao),
        taxaRegistro: formataStringParaDecimal(rodapebovespa.taxaregistro),
        totalCblc: formataStringParaDecimal(rodapebovespa.totalcblc),
        totalCblcdc: rodapebovespa.totalcblcdc,
        taxaTermoOpcoesFuturos: formataStringParaDecimal(
          rodapebovespa.taxatermoopcoes,
        ),
        taxaAna: formataStringParaDecimal(rodapebovespa.taxaana),
        emolumentos: formataStringParaDecimal(rodapebovespa.emolumentos),
        totalBovespaSoma: formataStringParaDecimal(
          rodapebovespa.totalbovespasoma,
        ),
        taxaOperacionalClearing: 0,
        execucaoCorretagem: formataStringParaDecimal(rodapebovespa.corretagem),
        taxaCustodia: 0,
        impostosIss: formataStringParaDecimal(rodapebovespa.impostosiss),
        irrf: formataStringParaDecimal(rodapebovespa.irrfsemoperacoesbase0),
        outros: formataStringParaDecimal(rodapebovespa.despesasoutras),
        totalCustosDespesas: formataStringParaDecimal(
          rodapebovespa.totalcorretagemdespesa,
        ),
        liquido: formataStringParaDecimal(rodapebovespa.liquidoparavalor),
        liquidoDc: rodapebovespa.liquidoparavalordc.code
          ? rodapebovespa.liquidoparavalordc.code
          : rodapebovespa.liquidoparavalordc,
        liquidoData: liquidodata,
        debentures: formataStringParaDecimal(rodapebovespa.debentures),
        vendasAvista: formataStringParaDecimal(rodapebovespa.vendasavista),
        comprasAvista: formataStringParaDecimal(rodapebovespa.comprasavista),
        opcoesCompras: formataStringParaDecimal(rodapebovespa.opcaocompras),
        opcoesVendas: formataStringParaDecimal(rodapebovespa.opcaovendas),
        operacoesTermo: formataStringParaDecimal(rodapebovespa.operacoestermo),
        valorOperacoesTituloPublico: formataStringParaDecimal(
          rodapebovespa.valoropertitulospublicos,
        ),
        valorOperacoes: formataStringParaDecimal(rodapebovespa.valoroperacoes),
        irrfdaytradeBase: formataStringParaDecimal(
          rodapebovespa.irrfdaytradebase,
        ),
        projecao: formataStringParaDecimal(rodapebovespa.projecao),
        idNotaCorretagemNavigation: null,
      };

      const setCadastromanual = await apiOperacoes.post(
        'NotaCorretagem/v1/PostListNotaCorretagem',
        [
          {
            idNotaCorretagem: 0,
            idUsuario: state.usuario.id,
            idNotaCorretagemArquivo: null,
            idUsuarioCorretora: 1676,
            paginaPdf: 1,
            idTipoFormatoPagina: 1,
            paginaFolha: null,
            numeroNotaCorretora: cadastromanual.numeronota,
            dataPregao: pregao,
            dataHoraCriacao: dataAtual,
            operacaoIdSituacao: 0,
            operacaoDataHoraProcessamento: null,
            operacaoErro: '',
            idCorretora: cadastromanual.corretora.code,
            idTipoNota: cadastromanual.tiponota.code,
            cpfNota: cadastromanual.cpf,
            cnpjNota: '73.973.090/0001-70',
            idCorretoraNavigation: null,
            idNotaCorretagemArquivoNavigation: null,
            idTipoNotaNavigation: null,
            idUsuarioCorretoraNavigation: null,
            custos: null,
            fechamentos: null,
            historicoDePosicos: null,
            notaCorretagemVolumeB3s: null,
            notaCorretagemVolumeBmfs: null,
            notasCorretagemItens: newOperacoes,
            notasCorretagemRodapeB3s: [newRodapeBovespa],
            notasCorretagemRodapeBmfs: [],
            operacos: null,
            resultados: null,
          },
        ],
      );

      localStorage.removeItem('cadastromanual');
      localStorage.removeItem('operacoesbovespa');
      localStorage.removeItem('rodapebovespa');
      setDisplayBasic(true);
    } catch (error) {
      console.error(error);
      showToast();
      setDisplayBasic(true);
    }
  }

  function voltar() {
    localStorage.setItem('rodapebovespa', JSON.stringify(rodapebovespa));
    window.history.back();
  }

  function handleValorRodape(e) {
    const moedaFormatada = formatCurrency(e.target.value);
    const key = e.target.id;

    setRodapebovespa({ ...rodapebovespa, [key]: moedaFormatada });
  }

  function calcularSomaCBLC() {
    let total = 0;
    if (rodapebovespa.valorliquidooperacoes) {
      if (
        rodapebovespa.valorliquidooperacoesdc &&
        rodapebovespa.valorliquidooperacoesdc.code === 'C'
      ) {
        total += Number(
          rodapebovespa.valorliquidooperacoes
            .split('.')
            .join('')
            .split(',')
            .join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalcblc: total,
          totalcblcdc: total >= 0 ? 'C' : 'D',
        });
      } else if (
        rodapebovespa.valorliquidooperacoesdc &&
        rodapebovespa.valorliquidooperacoesdc.code === 'D'
      ) {
        total -= Number(
          rodapebovespa.valorliquidooperacoes
            .split('.')
            .join('')
            .split(',')
            .join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalcblc: total,
          totalcblcdc: total >= 0 ? 'C' : 'D',
        });
      }
    }
    if (rodapebovespa.taxaliquidacao) {
      if (
        rodapebovespa.taxaliquidacaodc &&
        rodapebovespa.taxaliquidacaodc.code === 'C'
      ) {
        total += Number(
          rodapebovespa.taxaliquidacao.split('.').join('').split(',').join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalcblc: total,
          totalcblcdc: total >= 0 ? 'C' : 'D',
        });
      } else if (
        rodapebovespa.taxaliquidacaodc &&
        rodapebovespa.taxaliquidacaodc.code === 'D'
      ) {
        total -= Number(
          rodapebovespa.taxaliquidacao.split('.').join('').split(',').join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalcblc: total,
          totalcblcdc: total >= 0 ? 'C' : 'D',
        });
      }
    }
    if (rodapebovespa.taxaregistro) {
      if (
        rodapebovespa.taxaregistrodc &&
        rodapebovespa.taxaregistrodc.code === 'C'
      ) {
        total += Number(
          rodapebovespa.taxaregistro.split('.').join('').split(',').join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalcblc: total,
          totalcblcdc: total >= 0 ? 'C' : 'D',
        });
      } else if (
        rodapebovespa.taxaregistrodc &&
        rodapebovespa.taxaregistrodc.code === 'D'
      ) {
        total -= Number(
          rodapebovespa.taxaregistro.split('.').join('').split(',').join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalcblc: total,
          totalcblcdc: total >= 0 ? 'C' : 'D',
        });
      }
    }
  }

  function calcularSomaBovespa() {
    let total = 0;
    if (rodapebovespa.taxatermoopcoes) {
      if (
        rodapebovespa.taxatermoopcoesdc &&
        rodapebovespa.taxatermoopcoesdc.code === 'C'
      ) {
        total += Number(
          rodapebovespa.taxatermoopcoes
            .split('.')
            .join('')
            .split(',')
            .join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalbovespasoma: total,
          totalbovespasomadc: total >= 0 ? 'C' : 'D',
        });
      } else if (
        rodapebovespa.taxatermoopcoesdc &&
        rodapebovespa.taxatermoopcoesdc.code === 'D'
      ) {
        total -= Number(
          rodapebovespa.taxatermoopcoes
            .split('.')
            .join('')
            .split(',')
            .join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalbovespasoma: total,
          totalbovespasomadc: total >= 0 ? 'C' : 'D',
        });
      }
    }
    if (rodapebovespa.taxaana) {
      if (rodapebovespa.taxaanadc && rodapebovespa.taxaanadc.code === 'C') {
        total += Number(
          rodapebovespa.taxaana.split('.').join('').split(',').join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalbovespasoma: total,
          totalbovespasomadc: total >= 0 ? 'C' : 'D',
        });
      } else if (
        rodapebovespa.taxaanadc &&
        rodapebovespa.taxaanadc.code === 'D'
      ) {
        total -= Number(
          rodapebovespa.taxaana.split('.').join('').split(',').join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalbovespasoma: total,
          totalbovespasomadc: total >= 0 ? 'C' : 'D',
        });
      }
    }
    if (rodapebovespa.emolumentos) {
      if (
        rodapebovespa.emolumentosdc &&
        rodapebovespa.emolumentosdc.code === 'C'
      ) {
        total += Number(
          rodapebovespa.emolumentos.split('.').join('').split(',').join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalbovespasoma: total,
          totalbovespasomadc: total >= 0 ? 'C' : 'D',
        });
      } else if (
        rodapebovespa.emolumentosdc &&
        rodapebovespa.emolumentosdc.code === 'D'
      ) {
        total -= Number(
          rodapebovespa.emolumentos.split('.').join('').split(',').join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalbovespasoma: total,
          totalbovespasomadc: total >= 0 ? 'C' : 'D',
        });
      }
    }
  }

  function calcularSomaCorretagem() {
    let total = 0;
    if (rodapebovespa.corretagem) {
      if (
        rodapebovespa.corretagemdc &&
        rodapebovespa.corretagemdc.code === 'C'
      ) {
        total += Number(
          rodapebovespa.corretagem.split('.').join('').split(',').join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalcorretagemdespesa: total,
          totalcorretagemdespesadc: total >= 0 ? 'C' : 'D',
        });
      } else if (
        rodapebovespa.corretagemdc &&
        rodapebovespa.corretagemdc.code === 'D'
      ) {
        total -= Number(
          rodapebovespa.corretagem.split('.').join('').split(',').join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalcorretagemdespesa: total,
          totalcorretagemdespesadc: total >= 0 ? 'C' : 'D',
        });
      }
    }
    if (rodapebovespa.impostosiss) {
      if (
        rodapebovespa.impostosissdc &&
        rodapebovespa.impostosissdc.code === 'C'
      ) {
        total += Number(
          rodapebovespa.impostosiss.split('.').join('').split(',').join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalcorretagemdespesa: total,
          totalcorretagemdespesadc: total >= 0 ? 'C' : 'D',
        });
      } else if (
        rodapebovespa.impostosissdc &&
        rodapebovespa.impostosissdc.code === 'D'
      ) {
        total -= Number(
          rodapebovespa.impostosiss.split('.').join('').split(',').join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalcorretagemdespesa: total,
          totalcorretagemdespesadc: total >= 0 ? 'C' : 'D',
        });
      }
    }
    if (rodapebovespa.irrfsemoperacoesbase0) {
      if (
        rodapebovespa.irrfsemoperacoesbase0dc &&
        rodapebovespa.irrfsemoperacoesbase0dc.code === 'C'
      ) {
        total += Number(
          rodapebovespa.irrfsemoperacoesbase0
            .split('.')
            .join('')
            .split(',')
            .join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalcorretagemdespesa: total,
          totalcorretagemdespesadc: total >= 0 ? 'C' : 'D',
        });
      } else if (
        rodapebovespa.irrfsemoperacoesbase0dc &&
        rodapebovespa.irrfsemoperacoesbase0dc.code === 'D'
      ) {
        total -= Number(
          rodapebovespa.irrfsemoperacoesbase0
            .split('.')
            .join('')
            .split(',')
            .join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalcorretagemdespesa: total,
          totalcorretagemdespesadc: total >= 0 ? 'C' : 'D',
        });
      }
    }
    if (rodapebovespa.despesasoutras) {
      if (
        rodapebovespa.despesasoutrasdc &&
        rodapebovespa.despesasoutrasdc.code === 'C'
      ) {
        total += Number(
          rodapebovespa.despesasoutras.split('.').join('').split(',').join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalcorretagemdespesa: total,
          totalcorretagemdespesadc: total >= 0 ? 'C' : 'D',
        });
      } else if (
        rodapebovespa.despesasoutrasdc &&
        rodapebovespa.despesasoutrasdc.code === 'D'
      ) {
        total -= Number(
          rodapebovespa.despesasoutras.split('.').join('').split(',').join('.'),
        );
        setRodapebovespa({
          ...rodapebovespa,
          totalcorretagemdespesa: total,
          totalcorretagemdespesadc: total >= 0 ? 'C' : 'D',
        });
      }
    }
  }

  const basicDialogFooter = (
    <React.Fragment>
      <ButtonBDI
        label="Histórico de notas"
        className={`
                        p-button-primary
                        p-button-primary-cancel
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
        funcao={() => finalizar('/HistoricoNotas')}
      />
      <ButtonBDI
        label="Importar nova nota"
        className={`
                        ${ButtonBDI.bdi_classe.severities}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
        funcao={() => finalizar('/calculadora/cadastromanual')}
      />
    </React.Fragment>
  );

  function finalizar(rota) {
    history.push(rota);
  }

  useEffect(() => {
    setRodapebovespa({
      ...rodapebovespa,
      totalcblc: 0,
      totalbovespasoma: 0,
      totalcorretagemdespesa: 0,
      totalcblcdc: 'D/C',
      totalbovespasomadc: 'D/C',
      totalcorretagemdespesadc: 'D/C',
    });
    if (localStorage.getItem('cadastromanual'))
      setCadastromanual(JSON.parse(localStorage.getItem('cadastromanual')));
    if (localStorage.getItem('rodapebovespa'))
      setRodapebovespa(JSON.parse(localStorage.getItem('rodapebovespa')));
    const posicoes_iniciais = localStorage.getItem('operacoesbovespa')
      ? JSON.parse(localStorage.getItem('operacoesbovespa'))
      : posicoesiniciais;
    setPosicoesiniciais(posicoes_iniciais);
  }, []);

  useEffect(() => {
    calcularSomaCBLC();
  }, [
    rodapebovespa.valorliquidooperacoes,
    rodapebovespa.valorliquidooperacoesdc,
    rodapebovespa.taxaliquidacao,
    rodapebovespa.taxaliquidacaodc,
    rodapebovespa.taxaregistro,
    rodapebovespa.taxaregistrodc,
  ]);

  useEffect(() => {
    calcularSomaBovespa();
  }, [
    rodapebovespa.taxatermoopcoes,
    rodapebovespa.taxatermoopcoesdc,
    rodapebovespa.taxaana,
    rodapebovespa.taxaanadc,
    rodapebovespa.emolumentos,
    rodapebovespa.emolumentosdc,
  ]);

  useEffect(() => {
    calcularSomaCorretagem();
  }, [
    rodapebovespa.corretagem,
    rodapebovespa.corretagemdc,
    rodapebovespa.impostosiss,
    rodapebovespa.impostosissdc,
    rodapebovespa.irrfsemoperacoesbase0,
    rodapebovespa.irrfsemoperacoesbase0dc,
    rodapebovespa.despesasoutras,
    rodapebovespa.despesasoutrasdc,
  ]);

  const modalTitulo = () => {
    setModalTituloDialog(true);
  };

  const fecharModalTitulo = () => {
    setModalTituloDialog(false);
  };

  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-12">
        {displayBasic && (
          <DialogBDI
            header="Dados salvos"
            visible={displayBasic}
            style={{ width: '600px' }}
            footer={basicDialogFooter}
            funcao={() => setDisplayBasic(false)}
          >
            <p>
              <b>Suas informações foram salvas!</b>
            </p>
            <p>
              Clique em “cadastrar notas” para adicionar manualmente novas notas
              de corretagem ou acesse seu histórico de notas.
            </p>
          </DialogBDI>
        )}
        <Box1>
          <section>
            <div className="card">
              <div className="p-col-12 p-md-12">
                <h2>
                  <strong>Para finalizar</strong>, cadastre as informações do{' '}
                  <strong>rodapé da nota</strong>.
                  <Button
                    icon="pi pi-question-circle"
                    style={{ top: '9px' }}
                    className="p-button-rounded p-button-text colorIcon"
                    onClick={modalTitulo}
                    tooltip="Rodapé Bovespa"
                  />
                </h2>
                <br />
                <p className="subTituloBov">
                  Cadastre as informações do rodapé de acordo com a sua nota de
                  corretagem para que taxas, despesas e impostos referentes às
                  operações realizadas sejam apurados pela plataforma
                  automaticamente.
                </p>
                <Dialog
                  visible={modalTituloDialog}
                  style={{ width: '41vw' }}
                  header="Título"
                  blockScroll={false}
                  className="p-fluid"
                  modal
                  onHide={fecharModalTitulo}
                >
                  <p>Texto</p>
                </Dialog>
              </div>
              <br />
              <br />
              <div className="p-col-12 p-md-12">
                <Box102>
                  <div>
                    <h4 className="resumoNegociosTitulo">
                      Resumo dos Negócios
                    </h4>
                    <h4 className="resumoFinanceiro">Resumo Financeiro</h4>
                  </div>
                  <div>
                    <p>
                      <label>Debêntures</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="debentures"
                        valor={rodapebovespa.debentures}
                        acao={handleValorRodape}
                      />
                    </p>
                    <Phidden>
                      <label>CBLC</label>
                    </Phidden>
                    <Phidden>&nbsp;</Phidden>
                    <Phidden>
                      <label>D/C</label>
                    </Phidden>
                  </div>
                  <div>
                    <p>
                      <label>Vendas à vista</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="vendasavista"
                        valor={rodapebovespa.vendasavista}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>Valor líquido das operações</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="valorliquidooperacoes"
                        valor={rodapebovespa.valorliquidooperacoes}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <Dropdown
                        id="valorliquidooperacoesdc"
                        placeholder="*D/C"
                        required
                        value={rodapebovespa.valorliquidooperacoesdc}
                        onChange={e =>
                          setRodapebovespa({
                            ...rodapebovespa,
                            valorliquidooperacoesdc: e.value,
                          })
                        }
                        options={dc}
                        optionLabel="name"
                      />
                    </p>
                  </div>
                  <div>
                    <p>
                      <label>Compras à vista</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="comprasavista"
                        valor={rodapebovespa.comprasavista}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>Taxa de liquidação</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="taxaliquidacao"
                        valor={rodapebovespa.taxaliquidacao}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <Dropdown
                        id="taxaliquidacaodc"
                        placeholder="*D/C"
                        value={rodapebovespa.taxaliquidacaodc}
                        onChange={e =>
                          setRodapebovespa({
                            ...rodapebovespa,
                            taxaliquidacaodc: e.value,
                          })
                        }
                        options={dc}
                        optionLabel="name"
                      />
                    </p>
                  </div>
                  <div>
                    <p>
                      <label>Opção - compras</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="opcaocompras"
                        valor={rodapebovespa.opcaocompras}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>Taxa de registro</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="taxaregistro"
                        valor={rodapebovespa.taxaregistro}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <Dropdown
                        id="taxaregistrodc"
                        placeholder="*D/C"
                        value={rodapebovespa.taxaregistrodc}
                        onChange={e =>
                          setRodapebovespa({
                            ...rodapebovespa,
                            taxaregistrodc: e.value,
                          })
                        }
                        options={dc}
                        optionLabel="name"
                      />
                    </p>
                  </div>
                  <div>
                    <p>
                      <label>Opção - vendas</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="opcaovendas"
                        valor={rodapebovespa.opcaovendas}
                        acao={handleValorRodape}
                      />
                    </p>
                    <BgTransparent>
                      <label>Total CBLC</label>
                    </BgTransparent>
                    <TextCenter2>
                      <label>
                        {rodapebovespa.totalcblc
                          ? Math.abs(rodapebovespa.totalcblc).toLocaleString(
                              'pt-br',
                              { style: 'currency', currency: 'BRL' },
                            )
                          : '00000,00'}
                      </label>
                    </TextCenter2>
                    <BgTransparent>
                      <label>{rodapebovespa.totalcblcdc}</label>
                    </BgTransparent>
                  </div>
                  <div>
                    <p>
                      <label>Operações de termo</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="operacoestermo"
                        valor={rodapebovespa.operacoestermo}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>Bovespa/soma</label>
                    </p>
                    <Phidden>&nbsp;</Phidden>
                    <Phidden>&nbsp;</Phidden>
                  </div>
                  <div>
                    <p>
                      <label>Valor das oper. c/ títulos públ. (v. nom.)</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="valoropertitulospublicos"
                        valor={rodapebovespa.valoropertitulospublicos}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>Taxa de termo/opções</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="taxatermoopcoes"
                        valor={rodapebovespa.taxatermoopcoes}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <Dropdown
                        id="taxatermoopcoesdc"
                        placeholder="*D/C"
                        value={rodapebovespa.taxatermoopcoesdc}
                        onChange={e =>
                          setRodapebovespa({
                            ...rodapebovespa,
                            taxatermoopcoesdc: e.value,
                          })
                        }
                        options={dc}
                        optionLabel="name"
                      />
                    </p>
                  </div>
                  <div>
                    <p>
                      <label>Valor das operações</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="valoroperacoes"
                        valor={rodapebovespa.valoroperacoes}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>Taxa A.N.A.</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="taxaana"
                        valor={rodapebovespa.taxaana}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <Dropdown
                        id="taxaanadc"
                        placeholder="*D/C"
                        value={rodapebovespa.taxaanadc}
                        onChange={e =>
                          setRodapebovespa({
                            ...rodapebovespa,
                            taxaanadc: e.value,
                          })
                        }
                        options={dc}
                        optionLabel="name"
                      />
                    </p>
                  </div>
                  <div>
                    <p>
                      <label>IRRF daytrade base</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="irrfdaytradebase"
                        valor={rodapebovespa.irrfdaytradebase}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <label>Emolumentos</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="emolumentos"
                        valor={rodapebovespa.emolumentos}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <Dropdown
                        id="emolumentosdc"
                        placeholder="*D/C"
                        value={rodapebovespa.emolumentosdc}
                        onChange={e =>
                          setRodapebovespa({
                            ...rodapebovespa,
                            emolumentosdc: e.value,
                          })
                        }
                        options={dc}
                        optionLabel="name"
                      />
                    </p>
                  </div>
                  <div>
                    <p>
                      <label>Projeção</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="projecao"
                        valor={rodapebovespa.projecao}
                        acao={handleValorRodape}
                      />
                    </p>
                    <BgTransparent>
                      <label>Total Bovespa/soma</label>
                    </BgTransparent>
                    <TextCenter2>
                      {rodapebovespa.totalbovespasoma
                        ? Math.abs(
                            rodapebovespa.totalbovespasoma,
                          ).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })
                        : '00000,00'}
                    </TextCenter2>
                    <BgTransparent>
                      <label>{rodapebovespa.totalbovespasomadc}</label>
                    </BgTransparent>
                  </div>
                  <div>
                    <Phidden>&nbsp;</Phidden>
                    <Phidden>&nbsp;</Phidden>
                    <p>
                      <label>Corretagem/despesas</label>
                    </p>
                    <Phidden>&nbsp;</Phidden>
                    <Phidden>&nbsp;</Phidden>
                  </div>
                  <div>
                    <Phidden>&nbsp;</Phidden>
                    <Phidden>&nbsp;</Phidden>
                    <p>
                      <label>Corretagem</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="corretagem"
                        valor={rodapebovespa.corretagem}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <Dropdown
                        id="corretagemdc"
                        placeholder="*D/C"
                        value={rodapebovespa.corretagemdc}
                        onChange={e =>
                          setRodapebovespa({
                            ...rodapebovespa,
                            corretagemdc: e.value,
                          })
                        }
                        options={dc}
                        optionLabel="name"
                      />
                    </p>
                  </div>
                  <div>
                    <Phidden>&nbsp;</Phidden>
                    <Phidden>&nbsp;</Phidden>
                    <p>
                      <label>Impostos (ISS)</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="impostosiss"
                        valor={rodapebovespa.impostosiss}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <Dropdown
                        id="impostosissdc"
                        placeholder="*D/C"
                        value={rodapebovespa.impostosissdc}
                        onChange={e =>
                          setRodapebovespa({
                            ...rodapebovespa,
                            impostosissdc: e.value,
                          })
                        }
                        options={dc}
                        optionLabel="name"
                      />
                    </p>
                  </div>
                  <div>
                    <Phidden>&nbsp;</Phidden>
                    <Phidden>&nbsp;</Phidden>
                    <p>
                      <label>I.R.R.F s/ operações, base R$ 0,00</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="irrfsemoperacoesbase0"
                        valor={rodapebovespa.irrfsemoperacoesbase0}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <Dropdown
                        id="irrfsemoperacoesbase0dc"
                        placeholder="*D/C"
                        value={rodapebovespa.irrfsemoperacoesbase0dc}
                        onChange={e =>
                          setRodapebovespa({
                            ...rodapebovespa,
                            irrfsemoperacoesbase0dc: e.value,
                          })
                        }
                        options={dc}
                        optionLabel="name"
                      />
                    </p>
                  </div>
                  <div>
                    <Phidden>&nbsp;</Phidden>
                    <Phidden>&nbsp;</Phidden>
                    <p>
                      <label>Despesas/outras</label>
                    </p>
                    <p>
                      <InputTextBDI
                        type="text"
                        placeholder="00000,00"
                        id="despesasoutras"
                        valor={rodapebovespa.despesasoutras}
                        acao={handleValorRodape}
                      />
                    </p>
                    <p>
                      <Dropdown
                        id="despesasoutrasdc"
                        placeholder="*D/C"
                        value={rodapebovespa.despesasoutrasdc}
                        onChange={e =>
                          setRodapebovespa({
                            ...rodapebovespa,
                            despesasoutrasdc: e.value,
                          })
                        }
                        options={dc}
                        optionLabel="name"
                      />
                    </p>
                  </div>
                  <div>
                    <Phidden>&nbsp;</Phidden>
                    <Phidden>&nbsp;</Phidden>
                    <BgTransparent>
                      <label>Total corretagem/despesa</label>
                    </BgTransparent>
                    <TextCenter2>
                      {rodapebovespa.totalcorretagemdespesa
                        ? Math.abs(
                            rodapebovespa.totalcorretagemdespesa,
                          ).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })
                        : '00000,00'}
                    </TextCenter2>
                    <BgTransparent>
                      <label>{rodapebovespa.totalcorretagemdespesadc}</label>
                    </BgTransparent>
                  </div>
                  <div>
                    <Phidden>&nbsp;</Phidden>
                    <Phidden>&nbsp;</Phidden>
                    <BgTransparent2>
                      <label>Líquido para*</label>
                      <label>
                        <InputMaskBDI
                          id="liquidoparadata"
                          mask="99/99/9999"
                          placeholder="00/00/0000"
                          valor={rodapebovespa.liquidoparadata}
                          acao={e =>
                            setRodapebovespa({
                              ...rodapebovespa,
                              liquidoparadata: e.value,
                            })
                          }
                        ></InputMaskBDI>
                      </label>
                    </BgTransparent2>
                    <BgTransparent>
                      <InputTextBDI
                        required
                        type="text"
                        placeholder="00000,00"
                        id="liquidoparavalor"
                        valor={rodapebovespa.liquidoparavalor}
                        acao={handleValorRodape}
                      />
                    </BgTransparent>
                    <BgTransparent>
                      <Dropdown
                        id="liquidoparavalordc"
                        placeholder="*D/C"
                        value={rodapebovespa.liquidoparavalordc}
                        onChange={e =>
                          setRodapebovespa({
                            ...rodapebovespa,
                            liquidoparavalordc: e.value,
                          })
                        }
                        options={dc}
                        optionLabel="name"
                      />
                    </BgTransparent>
                  </div>
                </Box102>
              </div>
              <div className="p-col-12 p-md-6 preenchimentoobrigatorio">
                <Small>*Preenchimento obrigatório</Small>
              </div>
              <div className="p-col-12 p-md-6">
                <ButtonBDI
                  label="Voltar"
                  className={`
                        ${ButtonBDI.bdi_classe.outlined}
                        ${ButtonBDI.bdi_subclasse.secondary}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => voltar()}
                />
                <ButtonBDI
                  label="Salvar"
                  className={`
                        ${ButtonBDI.bdi_classe.severities}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => salvar()}
                  disabled={
                    rodapebovespa.liquidoparadata &&
                    rodapebovespa.liquidoparavalor &&
                    rodapebovespa.liquidoparavalordc
                      ? false
                      : true
                  }
                />
              </div>
            </div>
          </section>
        </Box1>
      </div>
    </div>
  );
};
