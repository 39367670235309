import { Skeleton } from 'primereact/skeleton';

import { GreenBorderBox } from '../../../../../../styles/elements/GreenBorderBox';
import { Container } from './styles';

type WidgetGraficoSkeletonProps = {
  slotDireito?: boolean;
};

export function WidgetGraficoSkeleton({
  slotDireito = false,
}: WidgetGraficoSkeletonProps) {
  return (
    <GreenBorderBox>
      <Container>
        <div className="header">
          <div>
            <Skeleton width={'130px'} height={'25px'} />
            <Skeleton width={'220px'} height={'20px'} />
          </div>
          <div>
            {slotDireito && (
              <>
                <Skeleton width={'120px'} height={'27px'} />
                <Skeleton width={'200px'} height={'27px'} />
              </>
            )}
          </div>
        </div>
        <div className="grafico">
          <div className="skeletonLegenda">
            <Skeleton width={'60px'} height={'21px'} />
            <Skeleton width={'60px'} height={'21px'} />
            <Skeleton width={'60px'} height={'21px'} />
            <Skeleton width={'60px'} height={'21px'} />
            <Skeleton width={'60px'} height={'21px'} />
            <Skeleton width={'60px'} height={'21px'} />
          </div>
          <div className="skeletonGrafico">
            <Skeleton size="220px" shape={'circle'} />
          </div>
        </div>
      </Container>
    </GreenBorderBox>
  );
}
