import Cookies from 'js-cookie';
import { createContext, useCallback, useEffect, useState } from 'react';

import configuracaoUsuario from '../../components/configuracaoUsuario';
import posicoesUsuario from '../../components/posicoesUsuario';

const DEFAULT_VALUE = {
  state: {
    menu: true,
    usuario: '',
    dadospessoais: '',
    configuracao: '',
    posicoesabertas: [],
  },
  // eslint-disable-next-line
  setState: () => {},
};

const ConfigContext = createContext(DEFAULT_VALUE);

const ConfigContextProvider = ({ children }) => {
  const [state, setState] = useState(DEFAULT_VALUE.state);

  const setUsuario = useCallback(
    async (updateMenu = false) => {
      const [config, posicoes] = await Promise.all([
        await configuracaoUsuario(),
        await posicoesUsuario(),
      ]);

      setState({
        ...state,
        usuario: Cookies.get('usuario')
          ? JSON.parse(Cookies.get('usuario'))
          : '',
        configuracao: config?.data ? config.data : '',
        posicoesabertas: posicoes.data ? posicoes.data : [],
        menu: updateMenu ? updateMenu : state.menu,
      });
    },
    [state],
  );

  useEffect(() => {
    setUsuario();
  }, []);

  return (
    <ConfigContext.Provider value={{ state, setState, setUsuario }}>
      {children}
    </ConfigContext.Provider>
  );
};
export { ConfigContextProvider };
export default ConfigContext;
