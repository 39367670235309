import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTablePageParams } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';

import {
  DELETE_INVOICE_HISTORY_ERROR,
  DELETE_INVOICE_HISTORY_SUCCESS,
} from '../../context/toast/constants';
import { useToast } from '../../context/toast/useToast';
import { useLocale } from '../../hooks/useLocale';
import { GreenBoxContainer } from '../../layouts/GreenBoxContainer';
import { NotaCorretagemFilter } from '../../service/ApiStatusProcessamento/types';
import { formatISO } from '../../utils/functions/dates';
import { CENTRAL_AJUDA_HISTORICO_NOTAS_LINK } from '../../utils/linksCentralAjuda';
import { Button } from '../Button';
import Usuario from '../Usuario';
import { AccordionToggle } from './AccordionToggle/index';
import { statusPendente, statusProcessada, statusRejeitada } from './constants';
import { useHistoricoNotasData } from './hooks/useHistoricoNotasData';
import { useNotaCorretagemData } from './hooks/useNotaCorretagemData';
import { DeleteNotaDialog } from './modals/DeleteNotaDialog';
import { InconsistenciaDialog } from './modals/InformarInconsistencia/InconsistenciaDialog';
import { NotaDetailsDialog } from './modals/NotaDetailsDialog';
import { StatusDialog } from './modals/StatusDialog';
import * as S from './styles';
import { paginatorTemplate } from './templates/PaginatorTemplate';
import { Corretora, NotaCorretagem } from './types';

const STATUS = new Map([
  [2, [1, 2, 6, 7, 8]],
  [3, [3, 15, 9, 16, 10, 11, 12, 13, 14, 17, 18]],
  [4, [4]],
  [5, [5]],
]);

export function HistoricoNotasV2() {
  const [firstPaginator, setFirstPaginator] = useState(0);
  const [rowsPaginator, setRowsPaginator] = useState(5);
  const [inconsistenciaDialog, setInconsistenciaDialog] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [deleteNotaDialog, setDeleteNotaDialog] = useState(false);
  const [notaDetailsDialog, setNotaDetailsDialog] = useState(false);
  const [notaCanBeDeleted, setNotaCanBeDeleted] = useState<
    'canBeDeleted' | 'cannotBeDeleted'
  >('cannotBeDeleted');
  const [variantDialog, setVariantDialog] = useState('');
  const [selectedLine, setSelectedLine] = useState({} as NotaCorretagem);
  const [dataCadastro, setDataCadastro] = useState<Date | Date[]>();
  const [dataPregao, setDataPregao] = useState<Date | Date[]>();
  const [numeroNota, setNumeroNota] = useState<number | null>(null);
  const [corretora, setCorretora] = useState({} as Corretora);
  const [tipoNotaValue, setTipoNotaValue] = useState(
    {} as { name: string; code: number },
  );
  const [statusNota, setStatusNota] = useState(
    {} as { name: string; code: number },
  );
  const [notasCorretagemFilter, setNotasCorretagemFilter] = useState(
    {} as NotaCorretagemFilter,
  );
  const [idNotaCorretagem, setIdNotaCorretagem] = useState<number | null>(null);

  const menuRef = useRef<Menu>(null);
  const { locale } = useLocale();
  const { currentToast, showToast } = useToast();
  const queryClient = useQueryClient();

  const [notasCorretagem, listCorretoras, listStatusNota] =
    useHistoricoNotasData(Usuario.id, notasCorretagemFilter);
  const notaCorretagem = useNotaCorretagemData(idNotaCorretagem);

  async function handleInformarInconsistencia(
    message: string,
    files: FileList,
  ) {
    const { username, email } = Usuario;
    const [dayPregao, monthPregao, yearPregao] = selectedLine.pregao.split('/');
    const [dayRegistration, monthRegistration, yearRegistration] =
      selectedLine.data.split('/');
    const formData = new FormData();

    const data = {
      loginUsuario: username,
      emailUsuario: email,
      planoUsuario: Cookies.get('paymentPlan'),
      nomeArquivo: selectedLine.arquivoNovo,
      tipoCadastro: selectedLine.detalhe,
      corretora: selectedLine.corretora,
      numeroDanota: selectedLine.idNotaCorretagem,
      tipoNota: selectedLine.idTipoNota,
      status: selectedLine.status,
      mensagemDoUsuario: message,
      dataPregao: `${yearPregao}-${monthPregao}-${dayPregao}`,
      dataCadastroNota: `${yearRegistration}-${monthRegistration}-${dayRegistration}`,
      DataHoraInconcistencia: new Date().toLocaleDateString('sv-SE'),
    };

    formData.append('jsonData', JSON.stringify(data));
    Array.from(files).forEach((file, index) => {
      formData.append(`file-${index}`, file);
    });

    const config = {
      headers: {
        'Context-Type': 'multipart/form-data',
      },
    };

    try {
      if (data.status === 'Falha ao Processar Arquivo') {
        await axios.post(
          `${process.env.REACT_APP_API_USUARIOS}/EnvioEmailSistemico/v1/PostEmailInconcistenciaGenerico`,
          formData,
          config,
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_USUARIOS}/EnvioEmailSistemico/v1/PostEmailInconcistencia`,
          formData,
          config,
        );
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setInconsistenciaDialog(false);
    }
  }

  async function handleDesativaArquivoStatus() {
    try {
      await axios.put(
        `${
          process.env.REACT_APP_API_STATUSPROCESSAMENTO
        }/StatusArquivo/v1/DesativaArquivoStatus/${Usuario.id}/${7}/${
          selectedLine.idUpload
        }`,
        {},
      );

      queryClient.invalidateQueries(['notasCorretagem']);
      showToast(DELETE_INVOICE_HISTORY_SUCCESS);
    } catch (error) {
      console.error(error);
      showToast(DELETE_INVOICE_HISTORY_ERROR);
    }
  }

  async function handleDeleteStatusProcessamento() {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_OPERACOES}/NotaCorretagem/v1/DeleteStatusProcessamento/${selectedLine.idStatusProcessamento}`,
        {},
      );

      queryClient.invalidateQueries(['notasCorretagem']);
      showToast(DELETE_INVOICE_HISTORY_SUCCESS);
    } catch (error) {
      console.error(error);
      showToast(DELETE_INVOICE_HISTORY_ERROR);
    }
  }

  async function handleDeleteHistory() {
    const { idNotaCorretagem, status } = selectedLine;

    try {
      if (status !== 'DARF Emitido') {
        await axios.delete(
          `${process.env.REACT_APP_API_OPERACOES}/NotaCorretagem/v1/DeleteNotaCorretagem/${idNotaCorretagem}`,
          {},
        );

        queryClient.invalidateQueries(['notasCorretagem']);
        showToast(DELETE_INVOICE_HISTORY_SUCCESS);
      }
    } catch (error) {
      console.error(error);
      showToast(DELETE_INVOICE_HISTORY_ERROR);
    } finally {
      setDeleteNotaDialog(false);
    }
  }

  async function handleDeleteNotas() {
    const { status, notaAtiva, detalhe } = selectedLine;

    if (notaAtiva) {
      setDeleteNotaDialog(true);
      if (status !== 'DARF Emitido') {
        setNotaCanBeDeleted('canBeDeleted');
      } else {
        setNotaCanBeDeleted('cannotBeDeleted');
      }
    } else if (!statusPendente.includes(status)) {
      currentToast?.show({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Não é possível deletar uma nota com status pendente',
        life: 3000,
      });
    } else if (status === 'Falha ao Processar Arquivo') {
      handleDesativaArquivoStatus();
    } else if (detalhe === 'Erro ao processar Arquivo') {
      handleDeleteStatusProcessamento();
    } else {
      currentToast?.show({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Nota já foi apagada anteriormente',
        life: 3000,
      });
    }
  }

  function handleClearFormFields() {
    setDataCadastro(undefined);
    setDataPregao(undefined);
    setNumeroNota(null);
    setCorretora({} as Corretora);
    setTipoNotaValue({} as { name: string; code: number });
    setStatusNota({} as { name: string; code: number });
  }

  function handleNotaCorretagemFilter() {
    const cadastroDate = dataCadastro as Date;
    const pregaoDate = dataPregao as Date;
    let arrayStatus = [] as number[];

    if (STATUS.has(statusNota.code)) {
      arrayStatus = STATUS.get(statusNota.code)!;
    }

    const filterObj = {
      idUsuario: Usuario.id,
      idServico: 7,
      numeroNotaCorretora: numeroNota ? numeroNota : 0,
      dataCadastro: dataCadastro ? formatISO(cadastroDate) : '',
      corretora: corretora ? corretora.code : 0,
      dataPregao: dataPregao ? formatISO(pregaoDate) : '',
      tipoNota: tipoNotaValue ? tipoNotaValue.code : 0,
      status: arrayStatus,
    };

    setNotasCorretagemFilter(filterObj);
  }

  // Paginator
  const handleChangePaginator = (event: DataTablePageParams) => {
    setFirstPaginator(event.first);
    setRowsPaginator(event.rows);
  };

  // Templates
  const statusTemplate = ({ status }: { status: string }) => {
    let currentStatus = 'Pendente' as 'Pendente' | 'Processada' | 'Falha';

    if (!statusPendente.includes(status)) {
      currentStatus = 'Pendente';
    } else if (statusProcessada.includes(status)) {
      currentStatus = 'Processada';
    } else if (statusRejeitada.includes(status)) {
      currentStatus = 'Falha';
    }

    return (
      <S.StatusButton
        value={status}
        status={currentStatus}
        onClick={e => {
          const target = e.target as HTMLButtonElement;
          setVariantDialog(target.value);
          setStatusDialog(true);
        }}
      >
        {currentStatus}
      </S.StatusButton>
    );
  };

  const menuTemplate = (rowData: NotaCorretagem) => {
    const overlayMenuItems = [
      {
        label: 'Detalhes',
        command: () => {
          setIdNotaCorretagem(selectedLine.idNotaCorretagem);
          setNotaDetailsDialog(true);
        },
      },
      {
        label: 'Excluir',
        command: () => {
          handleDeleteNotas();
        },
      },
      {
        label: 'Informar inconsistência',
        command: () => setInconsistenciaDialog(true),
      },
    ];

    return (
      <>
        <Menu id="popup_menu" model={overlayMenuItems} ref={menuRef} popup />
        <Button
          aria-controls="popup_menu"
          icon="pi pi-ellipsis-v"
          onClick={(event: React.SyntheticEvent<Element, Event>) => {
            setSelectedLine(rowData);
            !!menuRef.current && menuRef.current.toggle(event);
          }}
        />
      </>
    );
  };

  return (
    <GreenBoxContainer>
      <S.Header>
        <div>
          <h2>Histórico de Notas</h2>
          <a
            href={CENTRAL_AJUDA_HISTORICO_NOTAS_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              type="button"
              tooltip="Histórico de notas"
              icon="pi pi-question-circle"
            />
          </a>
        </div>
        <p>
          Consulte suas notas importadas e acompanhe o status do processamento
          de cada uma.
        </p>
      </S.Header>

      <S.Container>
        <AccordionToggle headerName="Filtros" className="mobile">
          <S.FormSection>
            <div>
              <div>
                <label htmlFor="dataCadastro">Data do cadastro</label>
                <Calendar
                  id="dataCadastro"
                  placeholder="Data do cadastro"
                  locale={locale}
                  dateFormat="dd/mm/yy"
                  showIcon
                  showButtonBar
                  value={dataCadastro}
                  onChange={e => setDataCadastro(e.value)}
                />
              </div>
              <div>
                <label htmlFor="numeroNota">Nº da nota</label>
                <InputNumber
                  id="numeroNota"
                  useGrouping={false}
                  placeholder="Digite o número da nota"
                  value={numeroNota}
                  onChange={e => setNumeroNota(e.value)}
                />
              </div>
              <div>
                <label htmlFor="corretora">Corretora</label>
                <Dropdown
                  id="corretora"
                  placeholder="Selecione uma corretora"
                  value={corretora}
                  onChange={e => setCorretora(e.value)}
                  optionLabel="name"
                  options={listCorretoras.data}
                  filter
                  required
                />
              </div>
              <div>
                <label htmlFor="dataPregao">Data do pregão</label>
                <Calendar
                  id="dataPregao"
                  placeholder="Data do pregão"
                  dateFormat="dd/mm/yy"
                  locale={locale}
                  value={dataPregao}
                  onChange={e => setDataPregao(e.value)}
                  showIcon
                  showButtonBar
                />
              </div>
              <div>
                <label htmlFor="tipoNota">Tipo de nota</label>
                <Dropdown
                  id="tipoNota"
                  placeholder="Selecione um tipo de nota"
                  value={tipoNotaValue}
                  onChange={e => setTipoNotaValue(e.value)}
                  optionLabel="name"
                  options={[
                    { name: 'Bovespa', code: '1' },
                    { name: 'BMF', code: '2' },
                  ]}
                  filter
                />
              </div>
              <div>
                <label htmlFor="status">Status</label>
                <Dropdown
                  id="status"
                  placeholder="Selecione o status"
                  value={statusNota}
                  onChange={e => setStatusNota(e.value)}
                  optionLabel="name"
                  options={listStatusNota.data}
                />
              </div>
            </div>
            <div>
              <Button
                type="button"
                className="tertiary"
                onClick={handleClearFormFields}
              >
                Limpar
              </Button>
              <Button type="button" onClick={handleNotaCorretagemFilter}>
                Filtrar
              </Button>
            </div>
          </S.FormSection>
        </AccordionToggle>

        <S.FormSection className="desktop">
          <div>
            <div>
              <label htmlFor="dataCadastro">Data do cadastro</label>
              <Calendar
                id="dataCadastro"
                placeholder="Data do cadastro"
                locale={locale}
                dateFormat="dd/mm/yy"
                showIcon
                showButtonBar
                value={dataCadastro}
                onChange={e => setDataCadastro(e.value)}
              />
            </div>
            <div>
              <label htmlFor="numeroNota">Nº da nota</label>
              <InputNumber
                id="numeroNota"
                useGrouping={false}
                placeholder="Digite o número da nota"
                value={numeroNota}
                onChange={e => setNumeroNota(e.value)}
              />
            </div>
            <div>
              <label htmlFor="corretora">Corretora</label>
              <Dropdown
                id="corretora"
                placeholder="Selecione uma corretora"
                value={corretora}
                onChange={e => setCorretora(e.value)}
                optionLabel="name"
                options={listCorretoras.data}
                filter
                required
              />
            </div>
            <div>
              <label htmlFor="dataPregao">Data do pregão</label>
              <Calendar
                id="dataPregao"
                placeholder="Data do pregão"
                dateFormat="dd/mm/yy"
                locale={locale}
                value={dataPregao}
                onChange={e => setDataPregao(e.value)}
                showIcon
                showButtonBar
              />
            </div>
            <div>
              <label htmlFor="tipoNota">Tipo de nota</label>
              <Dropdown
                id="tipoNota"
                placeholder="Selecione um tipo de nota"
                value={tipoNotaValue}
                onChange={e => setTipoNotaValue(e.value)}
                optionLabel="name"
                options={[
                  { name: 'Bovespa', code: '1' },
                  { name: 'BMF', code: '2' },
                ]}
              />
            </div>
            <div>
              <label htmlFor="status">Status</label>
              <Dropdown
                id="status"
                placeholder="Selecione o status"
                value={statusNota}
                onChange={e => setStatusNota(e.value)}
                optionLabel="name"
                options={listStatusNota.data}
              />
            </div>
          </div>
          <div>
            <Button
              type="button"
              className="tertiary"
              onClick={handleClearFormFields}
            >
              Limpar
            </Button>
            <Button type="button" onClick={handleNotaCorretagemFilter}>
              Filtrar
            </Button>
          </div>
        </S.FormSection>

        <section>
          <S.PrimeDataTable
            value={notasCorretagem.data}
            emptyMessage="Lista vazia"
            responsiveLayout="stack"
            breakpoint="768px"
            sortField="data"
            sortOrder={-1}
            loading={notasCorretagem.isLoading}
            paginator
            first={firstPaginator}
            rows={rowsPaginator}
            onPage={handleChangePaginator}
            paginatorTemplate={paginatorTemplate}
          >
            <Column field="data" header="Cadastro" sortable dataType="date" />
            <Column field="detalhe" header="Importação" sortable />
            <Column field="corretora" header="Corretora" sortable />
            <Column field="ndanota" header="Nº da Nota" sortable />
            <Column field="pregao" header="Pregão" sortable dataType="date" />
            <Column field="idTipoNota" header="Tipo de nota" sortable />
            <Column
              field="status"
              body={statusTemplate}
              header="Status"
              sortable
              style={{ width: '15rem' }}
            />
            <Column body={menuTemplate} style={{ width: '5rem' }} />
          </S.PrimeDataTable>
        </section>
      </S.Container>

      <InconsistenciaDialog
        visible={inconsistenciaDialog}
        onHide={() => setInconsistenciaDialog(false)}
        onInformarInconsistencia={handleInformarInconsistencia}
      />

      <StatusDialog
        visible={statusDialog}
        onHide={() => setStatusDialog(false)}
        onShowInconsistenciaDialog={() => setInconsistenciaDialog(true)}
        variant={variantDialog}
      />

      <DeleteNotaDialog
        visible={deleteNotaDialog}
        onHide={() => setDeleteNotaDialog(false)}
        canBeDeleted={notaCanBeDeleted}
        onDeleteHistory={handleDeleteHistory}
      />

      <NotaDetailsDialog
        visible={notaDetailsDialog}
        onHide={() => setNotaDetailsDialog(false)}
        selectedLineData={selectedLine}
        notaCorretagemData={notaCorretagem.data}
        isNotaCorretagemLoading={notaCorretagem.isLoading}
      />
    </GreenBoxContainer>
  );
}
