import {
  ButtonBDI,
  InputTextBDI,
  RadioButtonBDI,
} from '@dev_bdi/bdi-front-dspack';
import { useQuery } from '@tanstack/react-query';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import ConfigContext from '../../context/config';
import {
  EMPTY_MESSAGE,
  NO_ATTACHED_FILES,
  SEND_EMAIL_ERROR,
  SEND_EMAIL_SUCCESS,
} from '../../context/toast/constants';
import { useToast } from '../../context/toast/useToast';
import { getPosicoesAbertasMigradasPorIdUsuario } from '../../service/ApiOperacoes/PosicoesAbertas';
import { apiOperacoes } from '../../service/ApiOperacoes/api';
import apiCorretoras from '../../service/apiCorretoras';
import apiSincronizaCache from '../../service/apiSincronizaCache';
import apiUsuarios from '../../service/apiUsuarios';
import history from '../../service/history';
import { formatCurrency } from '../../utils/functions';
import { CENTRAL_AJUDA_POSICOES_INICIAIS_LINK } from '../../utils/linksCentralAjuda';
import {
  Box1,
  Box100,
  DialogInformarInconsistenciaContainer,
  DialogInformarInconsistenciaFooter,
  DialogPosicaoDuplicadaContainer,
  DialogPosicaoDuplicadaFooter,
  Small,
} from './styles';

const INITIAL_FIELD_ERRORS = {
  corretora: false,
  posicao: false,
  tipomercado: false,
  ticker: false,
  quantidade: false,
  precomedio: false,
  pontos: false,
};

export const PosicoesIniciais = () => {
  const { state, setState } = useContext(ConfigContext);
  const [posicaoinicial, setPosicaoinicial] = useState({});
  const [posicoesiniciais, setPosicoesiniciais] = useState([]);
  const [semposicaoinicial, setSemposicaoinicial] = useState(true);
  const [novocampo, setNovocampo] = useState(false);
  const [menu, setMenu] = useState({ ativo: false, linha: null });
  const [editar, setEditar] = useState({ ativo: false, linha: null });
  const [listacorretoras, setListacorretoras] = useState([]);
  const [listatipos, setListatipos] = useState([]);
  const [listainstrumentos, setListainstrumentos] = useState([]);
  const [respostaGetinstrumentos, SetRespostaGetinstrumentos] = useState([]);
  const [statusPosicaoDuplicadaDialog, setStatusPosicaoDuplicadaDialog] =
    useState(false);
  const [
    statusInformarInconsistenciaDialog,
    setStatusInformarInconsistenciaDialog,
  ] = useState(false);
  const [mensagemInconsistencia, setMensagemInconsistencia] = useState('');
  const [fieldErrors, setFieldErrors] = useState(INITIAL_FIELD_ERRORS);
  const [usuarioMigradoTemDados, setUsuarioMigradoTemDados] = useState(null);
  let informarInconsistenciaFileRef = useRef(null);
  const { usuarioAntigo } = state.configuracao;

  const { showToast } = useToast();

  const posicao = [
    { name: 'Compra', code: 'C' },
    { name: 'Venda', code: 'V' },
  ];

  const semErrosNosCampos = useMemo(() => {
    const errosArray = [];

    for (let erro in fieldErrors) {
      errosArray.push(fieldErrors[erro]);
    }

    return errosArray.every(erro => erro === false);
  }, [fieldErrors]);

  const habilitaBotaoAvancar = useMemo(() => {
    if (usuarioAntigo) {
      return posicoesiniciais.every(posicao => posicao.corretora !== '');
    }

    return true;
  }, [posicoesiniciais, usuarioAntigo]);

  async function getCorretoras() {
    try {
      const response = await apiCorretoras.get('/Corretoras');

      const newList = response.data.map(dado => {
        return {
          name: dado.nome,
          code: dado.idCorretora,
        };
      });
      setListacorretoras(newList);
    } catch (error) {
      console.error(error);
      showToast();
    }
  }

  async function getTiposdeMercado() {
    try {
      const response = await apiOperacoes.get('/TipoMercado');

      const newList = response.data.map(dado => {
        return {
          name: dado.nome,
          code: dado.idTipoMercado,
        };
      });

      const listaSemExercOpc = newList.filter(
        dado => !dado.name.startsWith('EXERC'),
      );
      setListatipos(listaSemExercOpc);
    } catch (error) {
      console.error(error);
      showToast();
    }
  }

  const handlePosicoesUsuarioLegado = async () => {
    const userId = state.usuario.id;
    const { data } = await getPosicoesAbertasMigradasPorIdUsuario(userId);

    const posicoesIniciaisUsuarioMigrado = data.map(posicao => {
      const isPontos = posicao.pontos !== '0,00' && posicao.pontos !== '';

      return {
        corretora: '',
        pontos: isPontos ? formatCurrency(posicao.pontos) : '0,00',
        posicao: {
          name: posicao.vendaCompra === 'V' ? 'Venda' : 'Compra',
          code: posicao.vendaCompra,
        },
        precomedio: !isPontos ? formatCurrency(posicao.pmLiquido) : '0,00',
        quantidade: posicao.quantidadeTotal,
        ticker: {
          name: posicao.ticker,
          code: posicao.idInstrumento,
        },
        tipomercado: {
          name: isPontos ? 'FUTURO' : 'VISTA',
          code: isPontos ? 8 : 1,
        },
      };
    });
    localStorage.setItem(
      'posicoesiniciais',
      JSON.stringify(posicoesIniciaisUsuarioMigrado),
    );
    setPosicoesiniciais(posicoesIniciaisUsuarioMigrado);

    if (posicoesIniciaisUsuarioMigrado.length > 0) {
      setSemposicaoinicial(false);
      setUsuarioMigradoTemDados(true);
    } else {
      setUsuarioMigradoTemDados(false);
    }
  };

  useQuery(['posicoesUsuarioLegado'], handlePosicoesUsuarioLegado, {
    enabled: state.configuracao !== '' && usuarioAntigo,
  });

  function avancar() {
    localStorage.setItem('semposicaoinicial', semposicaoinicial);
    if (semposicaoinicial) localStorage.setItem('posicoesiniciais', []);
    history.push(`/cadastroinicial/prejuizosacumulados`);
  }

  function voltar() {
    localStorage.setItem('semposicaoinicial', semposicaoinicial);
    if (semposicaoinicial)
      localStorage.setItem('posicoesiniciais', posicoesiniciais);
    window.history.back();
  }

  function validaPosicao(posicao) {
    const { corretora, ticker } = posicao;
    const mesmaCorretora = posicoesiniciais.some(
      posicaoInicial => posicaoInicial.corretora.name === corretora.name,
    );

    if (mesmaCorretora) {
      const posicoesCorretoras = posicoesiniciais.filter(
        posicaoInicial => posicaoInicial.corretora.name === corretora.name,
      );
      const mesmoTicket = posicoesCorretoras.some(
        posicaoInicial => posicaoInicial.ticker.name === ticker.name,
      );

      return mesmoTicket;
    }

    return false;
  }

  function validaPosicaoEdicao(posicao, indice) {
    const { corretora, ticker } = posicao;
    const mesmaCorretora = posicoesiniciais.some(
      posicaoInicial => posicaoInicial.corretora.name === corretora.name,
    );

    if (mesmaCorretora) {
      const posicoesCorretoras = posicoesiniciais.filter(
        posicaoInicial => posicaoInicial.corretora.name === corretora.name,
      );
      const mesmoTicket = posicoesCorretoras.some(
        posicaoInicial => posicaoInicial.ticker.name === ticker.name,
      );

      if (mesmoTicket) {
        const linha = posicoesiniciais.findIndex(
          posicaoInicial =>
            posicaoInicial.corretora.name === corretora.name &&
            posicaoInicial.ticker.name === ticker.name,
        );

        // O ativo que o usuário está editando está na mesma linha do que já existe?
        if (linha === indice) {
          return false;
        }

        return true;
      }
    }

    return false;
  }

  function salvarPosicao(posicao) {
    const existePosicao = validaPosicao(posicao);

    if (existePosicao) {
      setStatusPosicaoDuplicadaDialog(true);
      handleFieldErrors('ticker');
    } else {
      setPosicaoinicial({});
      setNovocampo(false);

      let posicoes = localStorage.getItem('posicoesiniciais')
        ? JSON.parse(localStorage.getItem('posicoesiniciais'))
        : [];
      posicoes.push(posicao);
      localStorage.setItem('posicoesiniciais', JSON.stringify(posicoes));
      setPosicoesiniciais(JSON.parse(localStorage.getItem('posicoesiniciais')));
      setFieldErrors(INITIAL_FIELD_ERRORS);
    }
  }

  function editarPosicao(posicao) {
    setNovocampo(false);
    setEditar({ ativo: true, linha: posicao });
    var item = JSON.parse(localStorage.getItem('posicoesiniciais'))[posicao];
    setPosicaoinicial(item);
  }

  function salvarEdicao(indice) {
    const existePosicao = validaPosicaoEdicao(posicaoinicial, indice);

    if (existePosicao) {
      setStatusPosicaoDuplicadaDialog(true);
      handleFieldErrors('ticker');
    } else {
      let itens = JSON.parse(localStorage.getItem('posicoesiniciais'));
      itens.splice(indice, 1, posicaoinicial);
      localStorage.setItem('posicoesiniciais', JSON.stringify(itens));
      setPosicoesiniciais(JSON.parse(localStorage.getItem('posicoesiniciais')));
      setEditar({ ativo: false, linha: null });
      setPosicaoinicial({});
      setFieldErrors(INITIAL_FIELD_ERRORS);
    }
  }

  function removerPosicao(posicao) {
    var posicoes = JSON.parse(localStorage.getItem('posicoesiniciais'));
    posicoes.splice(posicao, 1);
    localStorage.setItem('posicoesiniciais', JSON.stringify(posicoes));
    setPosicoesiniciais(JSON.parse(localStorage.getItem('posicoesiniciais')));
  }

  function viewMenu(estado) {
    setState({
      ...state,
      menu: estado,
    });
  }

  const informarInconsistencia = async () => {
    if (!informarInconsistenciaFileRef.current?.files) {
      showToast(NO_ATTACHED_FILES);
      return;
    } else if (!mensagemInconsistencia) {
      showToast(EMPTY_MESSAGE);
      return;
    }

    try {
      const loginUsuario = state.usuario.username;
      const emailusuario = state.usuario.email;
      const dateNow = new Date().toLocaleString('sv-SE');
      const formData = new FormData();

      const obj = {
        loginUsuario: loginUsuario,
        emailusuario: emailusuario,
        mensagemDoUsuario: mensagemInconsistencia,
        DataHoraInconcistencia: dateNow,
        planoUsuario: 'Sem plano',
        TipoInconcistencia: 'Nota duplicada',
      };

      const myJSON = JSON.stringify(obj);

      formData.append('jsonData', myJSON);
      informarInconsistenciaFileRef.current.files.forEach((file, index) =>
        formData.append(`file-${index}`, file),
      );

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      await apiUsuarios.post(
        `/EnvioEmailSistemico/v1/PostEmailInconcistenciaGenerico`,
        formData,
        config,
      );

      setStatusInformarInconsistenciaDialog(false);
      setMensagemInconsistencia('');
      informarInconsistenciaFileRef.current.clear();
      showToast(SEND_EMAIL_SUCCESS);
    } catch (error) {
      console.error(error);
      setStatusInformarInconsistenciaDialog(false);
      setMensagemInconsistencia('');
      informarInconsistenciaFileRef.current.clear();
      showToast(SEND_EMAIL_ERROR);
    }
  };

  // Modal Dialog
  const fechaDialogPosicaoDuplicada = () =>
    setStatusPosicaoDuplicadaDialog(false);

  const dialogPosicaoDuplicadaFooter = (
    <DialogPosicaoDuplicadaFooter>
      <Button label="Corrigir cadastro" onClick={fechaDialogPosicaoDuplicada} />
      {/* <Button
        label="Falar com o suporte"
        onClick={() => {
          fechaDialogPosicaoDuplicada();
          setStatusInformarInconsistenciaDialog(true);
        }}
      /> */}
    </DialogPosicaoDuplicadaFooter>
  );

  const fechaDialogInformarInconsistencia = () =>
    setStatusInformarInconsistenciaDialog(false);

  const dialogInformarInconsistenciaFooter = (
    <DialogInformarInconsistenciaFooter>
      <Button
        label="Cancelar"
        onClick={() => setStatusInformarInconsistenciaDialog(false)}
      />
      <Button
        label="Informar inconsistência"
        onClick={informarInconsistencia}
      />
    </DialogInformarInconsistenciaFooter>
  );

  function handleFieldErrors(fieldName) {
    const newErrors = { ...fieldErrors, [fieldName]: true };
    setFieldErrors(newErrors);
  }

  function handleTicketObrigatorio(e, id) {
    if (posicaoinicial[id] && e.target.value) {
      if (posicaoinicial[id].name === e.target.value) {
        const newErrors = { ...fieldErrors, [id]: false };
        setFieldErrors(newErrors);
      } else {
        const newErrors = { ...fieldErrors, [id]: true };
        setFieldErrors(newErrors);
      }
    } else {
      const newErrors = { ...fieldErrors, [id]: true };
      setFieldErrors(newErrors);
    }
  }

  function handleValorPosicaoInicial(e) {
    const moedaFormatada = formatCurrency(e.target.value);

    if (e.target.id === 'precomedio')
      setPosicaoinicial({
        ...posicaoinicial,
        precomedio: moedaFormatada,
        pontos: '',
      });
    if (e.target.id === 'pontos')
      setPosicaoinicial({
        ...posicaoinicial,
        pontos: moedaFormatada,
        precomedio: '',
      });

    setSemposicaoinicial(false);
  }

  function configUsuario(config) {
    if (config.configuracao && !config.configuracao.usuarioAntigo) {
      history.push('/cadastroinicial/relatorio');
    }
  }

  const consultaInstrumentos = async event => {
    const query = event && event.query.toUpperCase();

    async function ExecutaRequestBuscaInstrumento() {
      try {
        const response = await apiSincronizaCache.get(
          'v1/GetBuscaInstrumentosAll/' + query,
        );

        SetRespostaGetinstrumentos(response.data);

        const filterList = response.data.instrumentos.filter(dado => {
          return dado.ticker;
        });

        const obj = filterList.map(dado => {
          return {
            name: dado.ticker,
            code: dado.idInstrumento,
          };
        });

        setListainstrumentos(obj);
      } catch (error) {
        console.error(error);
        showToast();
      }
    }

    if (query !== null || query !== undefined) {
      if (query.length >= 3) {
        ExecutaRequestBuscaInstrumento();
      } else {
        if (
          respostaGetinstrumentos !== null &&
          respostaGetinstrumentos !== undefined
        ) {
          if (
            respostaGetinstrumentos.instrumentos !== null &&
            respostaGetinstrumentos.instrumentos !== undefined
          ) {
            if (respostaGetinstrumentos.instrumentos.length > 0) {
              const filterList = respostaGetinstrumentos.instrumentos.filter(
                dado => {
                  return dado.ticker.toUpperCase().indexOf(query) === 0;
                },
              );
              if (filterList.length > 0) {
                const obj = filterList.map(dado => {
                  return {
                    name: dado.ticker,
                    code: dado.idInstrumento,
                  };
                });

                setListainstrumentos(obj);
              } else {
                ExecutaRequestBuscaInstrumento();
              }
            } else {
              ExecutaRequestBuscaInstrumento();
            }
          } else {
            ExecutaRequestBuscaInstrumento();
          }
        } else {
          ExecutaRequestBuscaInstrumento();
        }
      }
    }
  };

  useEffect(() => {
    state.configuracao && configUsuario(state);
  }, [state.configuracao]);

  useEffect(() => {
    viewMenu(false);
  }, [state.menu]);

  useEffect(() => {
    const posicoes_iniciais = localStorage.getItem('posicoesiniciais')
      ? JSON.parse(localStorage.getItem('posicoesiniciais'))
      : [];
    posicoes_iniciais
      ? setPosicoesiniciais(posicoes_iniciais)
      : setNovocampo(true);
    localStorage.getItem('semposicaoinicial') &&
    localStorage.getItem('semposicaoinicial') === 'false'
      ? setSemposicaoinicial(false)
      : setSemposicaoinicial(true);
    if (!localStorage.getItem('posicoesiniciais')) setNovocampo(true);
    if (!semposicaoinicial) setNovocampo(false);
    getCorretoras();
    getTiposdeMercado();
  }, []);

  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-12">
        <Box1>
          <section className="card">
            <div className="timeline">
              <div className="container checked left">
                <div className="content checked">
                  <h3>Tipo de data utilizado</h3>
                </div>
              </div>
              <div className="container checked left">
                <div className="content checked">
                  <h3>Data de referência</h3>
                </div>
              </div>
              <div className="container active left">
                <div className="content active">
                  <h3>Posições iniciais</h3>
                </div>
              </div>
              <div className="container left">
                <div className="content">
                  <h3>Prejuízos acumulados</h3>
                </div>
              </div>
              <div className="container left">
                <div className="content">
                  <h3>IRRF acumulado</h3>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="card">
              <div className="p-col-12 p-md-12">
                <h2>
                  <strong>Aqui</strong>, indique suas{' '}
                  <strong>posições iniciais</strong>.{' '}
                  <a
                    href={CENTRAL_AJUDA_POSICOES_INICIAIS_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      icon="pi pi-question-circle"
                      style={{ top: '9px' }}
                      className="p-button-rounded p-button-text colorIcon"
                    />
                  </a>
                </h2>
                <br />
                <p className="subTituloBov">
                  A Calculadora de IR permite que posições iniciais sejam
                  cadastradas para cada ativo da carteira de investimentos,
                  tendo como referência a data selecionada no item anterior.
                </p>
                <p className="subTituloBov">
                  Caso não possua posições a serem informadas, clique na opção{' '}
                  <strong>
                    &quot;Não tenho posições iniciais para cadastrar&quot;
                  </strong>
                  .
                </p>
              </div>
              <br />
              <br />
              <div className="p-col-12 p-md-8">
                <RadioButtonBDI
                  label="Não tenho posições iniciais para cadastrar.*"
                  inputId="semposicaoinicial"
                  name="semposicaoinicial"
                  valor="1"
                  checked={
                    usuarioAntigo && !semposicaoinicial
                      ? false
                      : semposicaoinicial
                  }
                  disabled={
                    usuarioAntigo &&
                    usuarioMigradoTemDados &&
                    !semposicaoinicial
                  }
                  acao={() => {
                    setSemposicaoinicial(true);
                    setNovocampo(false);
                    if (!semposicaoinicial) {
                      setPosicoesiniciais([]);
                      localStorage.setItem('posicoesiniciais', []);
                      setPosicaoinicial({
                        corretora: '',
                        posicao: '',
                        tipomercado: '',
                        ticker: '',
                        quantidade: null,
                        precomedio: null,
                      });
                    }
                  }}
                />
                <RadioButtonBDI
                  label="Desejo cadastrar posições iniciais*"
                  inputId="semposicaoinicial1"
                  name="semposicaoinicial1"
                  valor="1"
                  checked={
                    (usuarioAntigo && !semposicaoinicial) || !semposicaoinicial
                  }
                  acao={() => setSemposicaoinicial(false)}
                />
                {usuarioAntigo && !semposicaoinicial && (
                  <p id="avisoUsuarioAntigo">
                    <span>ATENÇÃO:</span> Clique no três pontos &gt;
                    &quot;Editar&quot; &gt; selecione sua corretora
                  </p>
                )}
              </div>
              {!semposicaoinicial && (
                <div className="p-col-12 p-md-12">
                  <Box100>
                    <div>
                      <p className="heading">Corretora</p>
                      <p className="heading">Posição C/V</p>
                      <p className="heading">Tipo de Mercado</p>
                      <p className="heading">Ticker</p>
                      <p className="heading">Qtde</p>
                      <p className="heading">Preço médio/Pontos</p>
                      <p>&nbsp;</p>
                    </div>
                    {posicoesiniciais &&
                      posicoesiniciais.map((p, indice) => (
                        <div key={indice}>
                          {editar.ativo && editar.linha === indice ? (
                            <>
                              <p>
                                <label htmlFor="corretora">Corretora*</label>
                                <Dropdown
                                  id="corretora"
                                  value={posicaoinicial.corretora}
                                  required
                                  onChange={e => {
                                    setPosicaoinicial({
                                      ...posicaoinicial,
                                      corretora: e.value,
                                    });
                                  }}
                                  options={listacorretoras}
                                  optionLabel="name"
                                  filter
                                  placeholder="Corretora*"
                                  className={`field ${
                                    fieldErrors['corretora'] ? 'invalido' : ''
                                  }`}
                                  style={{ width: '100%' }}
                                />
                              </p>
                              <p>
                                <label htmlFor="posicao">Posição C/V*</label>
                                <Dropdown
                                  id="posicao"
                                  value={posicaoinicial.posicao}
                                  required
                                  onChange={e => {
                                    setPosicaoinicial({
                                      ...posicaoinicial,
                                      posicao: e.value,
                                    });
                                  }}
                                  options={posicao}
                                  optionLabel="name"
                                  filter
                                  placeholder="C/V*"
                                  className={`field ${
                                    fieldErrors['posicao'] ? 'invalido' : ''
                                  }`}
                                  style={{ width: '100%' }}
                                />
                              </p>
                              <p>
                                <label htmlFor="tipomercado">
                                  Tipo de Mercado*
                                </label>
                                <Dropdown
                                  id="tipomercado"
                                  value={posicaoinicial.tipomercado}
                                  required
                                  onChange={e => {
                                    setPosicaoinicial({
                                      ...posicaoinicial,
                                      tipomercado: e.value,
                                    });
                                  }}
                                  options={listatipos}
                                  optionLabel="name"
                                  filter
                                  placeholder="Tipo de Mercado*"
                                  className={`field ${
                                    fieldErrors['tipomercado'] ? 'invalido' : ''
                                  }`}
                                  style={{ width: '100%' }}
                                />
                              </p>
                              <p>
                                <label htmlFor="ticker">Ticker*</label>
                                <AutoComplete
                                  id="ticker"
                                  placeholder="Ticker*"
                                  value={posicaoinicial.ticker}
                                  suggestions={listainstrumentos}
                                  completeMethod={consultaInstrumentos}
                                  field="name"
                                  onChange={e =>
                                    setPosicaoinicial({
                                      ...posicaoinicial,
                                      ticker: e.target.value,
                                    })
                                  }
                                  onBlur={e =>
                                    handleTicketObrigatorio(e, 'ticker')
                                  }
                                  className={`field ${
                                    fieldErrors['ticker'] ? 'invalido' : ''
                                  }`}
                                />
                              </p>
                              <p>
                                <label htmlFor="quantidade">Qtde*</label>
                                <InputTextBDI
                                  type="number"
                                  placeholder="Qtde*"
                                  id="quantidade"
                                  valor={posicaoinicial.quantidade}
                                  acao={e =>
                                    setPosicaoinicial({
                                      ...posicaoinicial,
                                      quantidade: e.target.value,
                                    })
                                  }
                                  className={`field ${
                                    fieldErrors['quantidade'] ? 'invalido' : ''
                                  }`}
                                />
                              </p>
                              {posicaoinicial.tipomercado &&
                              posicaoinicial.tipomercado.name === 'FUTURO' ? (
                                <p>
                                  <label htmlFor="pontos">Pontos*</label>
                                  <InputTextBDI
                                    type="text"
                                    placeholder="Pontos"
                                    id="pontos"
                                    valor={posicaoinicial.pontos}
                                    max={14}
                                    acao={handleValorPosicaoInicial}
                                    className={`field ${
                                      fieldErrors['pontos'] ? 'invalido' : ''
                                    }`}
                                  />
                                </p>
                              ) : (
                                <p>
                                  <label htmlFor="precomedio">
                                    Preço médio*
                                  </label>
                                  <InputTextBDI
                                    type="text"
                                    placeholder="Preço médio"
                                    id="precomedio"
                                    valor={posicaoinicial.precomedio}
                                    max={14}
                                    acao={handleValorPosicaoInicial}
                                    className={`field ${
                                      fieldErrors['precomedio']
                                        ? 'invalido'
                                        : ''
                                    }`}
                                  />
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              <p>
                                <label>
                                  {p.corretora != null ||
                                  p.corretora !== undefined
                                    ? p.corretora.name
                                    : ' '}
                                </label>
                              </p>
                              <p>
                                <label>
                                  {p.posicao != null || p.posicao !== undefined
                                    ? p.posicao.name
                                    : ' '}
                                </label>
                              </p>
                              <p>
                                <label>
                                  {p.tipomercado != null ||
                                  p.tipomercado !== undefined
                                    ? p.tipomercado.name
                                    : ' '}
                                </label>
                              </p>
                              <p>
                                <label>
                                  {p.ticker != null || p.ticker !== undefined
                                    ? p.ticker.name
                                    : ' '}
                                </label>
                              </p>
                              <p>
                                <label>
                                  {p.quantidade != null ||
                                  p.quantidade !== undefined
                                    ? p.quantidade
                                    : ' '}
                                </label>
                              </p>
                              <p
                                style={{
                                  display:
                                    p.pontos !== '0,00' && p.pontos !== ''
                                      ? 'block'
                                      : 'none',
                                }}
                              >
                                <label>
                                  {p.pontos != null || p.pontos !== undefined
                                    ? p.pontos.replace(',', '.')
                                    : ''}
                                </label>
                              </p>
                              <p
                                style={{
                                  display:
                                    p.precomedio !== '0,00' &&
                                    p.precomedio !== ''
                                      ? 'block'
                                      : 'none',
                                }}
                              >
                                <label>
                                  {p.precomedio != null ||
                                  p.precomedio !== undefined
                                    ? p.precomedio
                                    : ' '}
                                </label>
                              </p>
                            </>
                          )}
                          <p className="opcoes">
                            {menu.ativo && menu.linha === indice && (
                              <ul role="menu">
                                <li
                                  role="menuitem"
                                  tabIndex={0}
                                  onClick={() => {
                                    editarPosicao(indice);
                                    setMenu({ ativo: false, linha: 0 });
                                  }}
                                  onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                      editarPosicao(indice);
                                      setMenu({ ativo: false, linha: 0 });
                                    }
                                  }}
                                >
                                  Editar
                                </li>
                                <li
                                  role="menuitem"
                                  tabIndex={0}
                                  onClick={() => {
                                    removerPosicao(indice);
                                    setMenu({ ativo: false, linha: 0 });
                                  }}
                                  onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                      removerPosicao(indice);
                                      setMenu({ ativo: false, linha: 0 });
                                    }
                                  }}
                                >
                                  Excluir
                                </li>
                              </ul>
                            )}
                            {menu.linha === indice &&
                              editar.linha !== indice && (
                                <button
                                  type="button"
                                  className="transparent"
                                  onClick={() =>
                                    setMenu({
                                      ativo: !menu.ativo,
                                      linha: indice,
                                    })
                                  }
                                >
                                  <i className="pi pi-ellipsis-v"></i>
                                </button>
                              )}
                            {menu.linha !== indice &&
                              editar.linha !== indice && (
                                <button
                                  type="button"
                                  className="transparent"
                                  onClick={() =>
                                    setMenu({ ativo: true, linha: indice })
                                  }
                                >
                                  <i className="pi pi-ellipsis-v"></i>
                                </button>
                              )}
                            {editar.ativo &&
                              editar.linha === indice &&
                              posicaoinicial.corretora &&
                              posicaoinicial.posicao &&
                              posicaoinicial.quantidade &&
                              posicaoinicial.tipomercado &&
                              posicaoinicial.ticker &&
                              (posicaoinicial.precomedio ||
                                posicaoinicial.pontos) &&
                              semErrosNosCampos && (
                                <button
                                  type="button"
                                  className="transparent"
                                  onClick={() => salvarEdicao(indice)}
                                >
                                  <i className="pi pi-save"></i>
                                </button>
                              )}
                          </p>
                        </div>
                      ))}
                    {novocampo && (
                      <div>
                        <p className="subtitulosPosicaoIni">
                          <label htmlFor="corretora">Corretora*</label>
                          <Dropdown
                            id="corretora"
                            value={posicaoinicial.corretora}
                            required
                            onChange={e => {
                              setPosicaoinicial({
                                ...posicaoinicial,
                                corretora: e.value,
                              });
                              setSemposicaoinicial(false);
                            }}
                            options={listacorretoras}
                            optionLabel="name"
                            filter
                            placeholder="Corretora*"
                            className={`field ${
                              fieldErrors['corretora'] ? 'invalido' : ''
                            }`}
                            style={{ width: '100%' }}
                          />
                        </p>
                        <p className="subtitulosPosicaoIni">
                          <label htmlFor="posicao">Posição C/V*</label>
                          <Dropdown
                            id="posicao"
                            value={posicaoinicial.posicao}
                            required
                            onChange={e => {
                              setPosicaoinicial({
                                ...posicaoinicial,
                                posicao: e.value,
                              });
                              setSemposicaoinicial(false);
                            }}
                            options={posicao}
                            optionLabel="name"
                            filter
                            placeholder="C/V*"
                            className={`field ${
                              fieldErrors['posicao'] ? 'invalido' : ''
                            }`}
                            style={{ width: '100%' }}
                          />
                        </p>
                        <p className="subtitulosPosicaoIni">
                          <label htmlFor="tipomercado">Tipo de Mercado*</label>
                          <Dropdown
                            id="tipomercado"
                            value={posicaoinicial.tipomercado}
                            required
                            onChange={e => {
                              setPosicaoinicial({
                                ...posicaoinicial,
                                tipomercado: e.value,
                              });
                              setSemposicaoinicial(false);
                            }}
                            options={listatipos}
                            optionLabel="name"
                            filter
                            placeholder="Tipo de Mercado*"
                            className={`field ${
                              fieldErrors['tipomercado'] ? 'invalido' : ''
                            }`}
                            style={{ width: '100%' }}
                          />
                        </p>
                        <p className="subtitulosPosicaoIni">
                          <label htmlFor="ticker">Ticker*</label>
                          <AutoComplete
                            id="ticker"
                            placeholder="Ticker*"
                            value={posicaoinicial.ticker}
                            suggestions={listainstrumentos}
                            completeMethod={consultaInstrumentos}
                            field="name"
                            onChange={e =>
                              setPosicaoinicial({
                                ...posicaoinicial,
                                ticker: e.target.value,
                              })
                            }
                            onBlur={e => handleTicketObrigatorio(e, 'ticker')}
                            className={`field ${
                              fieldErrors['ticker'] ? 'invalido' : ''
                            }`}
                          />
                        </p>
                        <p className="subtitulosPosicaoIni">
                          <label htmlFor="quantidade">Qtde*</label>
                          <InputNumber
                            id="quantidade"
                            value={posicaoinicial.quantidade}
                            onValueChange={e => {
                              setPosicaoinicial({
                                ...posicaoinicial,
                                quantidade: e.target.value,
                              });
                              setSemposicaoinicial(false);
                            }}
                            min={1}
                            required
                            placeholder="Qtde*"
                            useGrouping={false}
                            showButtons
                            className={`field ${
                              fieldErrors['quantidade'] ? 'invalido' : ''
                            }`}
                          />
                        </p>
                        {posicaoinicial.tipomercado &&
                        posicaoinicial.tipomercado.name === 'FUTURO' ? (
                          <p>
                            <label htmlFor="pontos">Pontos*</label>
                            <InputTextBDI
                              type="text"
                              placeholder="Pontos"
                              id="pontos"
                              valor={posicaoinicial.pontos}
                              max={14}
                              acao={handleValorPosicaoInicial}
                              className={`field ${
                                fieldErrors['pontos'] ? 'invalido' : ''
                              }`}
                            />
                          </p>
                        ) : (
                          <p className="subtitulosPosicaoIni">
                            <label htmlFor="precomedio">Preço médio*</label>
                            <InputTextBDI
                              type="text"
                              placeholder="Preço médio"
                              id="precomedio"
                              valor={posicaoinicial.precomedio}
                              max={14}
                              acao={handleValorPosicaoInicial}
                              className={`field ${
                                fieldErrors['precomedio'] ? 'invalido' : ''
                              }`}
                            />
                          </p>
                        )}
                        <p>
                          {posicaoinicial.corretora &&
                            posicaoinicial.posicao &&
                            posicaoinicial.quantidade &&
                            posicaoinicial.tipomercado &&
                            posicaoinicial.ticker &&
                            (posicaoinicial.precomedio ||
                              posicaoinicial.pontos) &&
                            semErrosNosCampos && (
                              <button
                                type="button"
                                className="transparent"
                                onClick={() => {
                                  SetRespostaGetinstrumentos([]);
                                  salvarPosicao(posicaoinicial);
                                }}
                              >
                                <i
                                  className="pi pi-save"
                                  style={{ marginTop: '30px' }}
                                ></i>
                              </button>
                            )}
                        </p>
                      </div>
                    )}
                    {
                      <div>
                        <p>Quantidade: {posicoesiniciais.length}</p>
                        <p>
                          {!novocampo ? (
                            <ButtonBDI
                              disabled={semposicaoinicial}
                              icon={ButtonBDI.bdi_icon.plus}
                              className={`
                        ${ButtonBDI.bdi_classe.rounded}
                        ${ButtonBDI.bdi_subclasse.primary}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                              funcao={() => {
                                setPosicaoinicial({});
                                setNovocampo(true);
                                setEditar({ ativo: false, linha: null });
                              }}
                            />
                          ) : (
                            <ButtonBDI
                              icon={ButtonBDI.bdi_icon.minus}
                              className={`
                      ${ButtonBDI.bdi_classe.rounded}
                      ${ButtonBDI.bdi_subclasse.primary}
                      ${ButtonBDI.bdi_alinhamento_lateral}
                      ${ButtonBDI.bdi_alinhamento_vertical}
                      `}
                              funcao={() => setNovocampo(false)}
                            />
                          )}
                        </p>
                      </div>
                    }
                  </Box100>
                </div>
              )}
              <div className="p-col-12 p-md-6 preenchimentoobrigatorio">
                <Small>*Preenchimento obrigatório</Small>
              </div>
              <div className="p-col-12 p-md-6">
                <ButtonBDI
                  label="Voltar"
                  className={`
                        ${ButtonBDI.bdi_classe.outlined}
                        ${ButtonBDI.bdi_subclasse.secondary}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => voltar()}
                />
                <ButtonBDI
                  label="Avançar"
                  className={`
                        ${ButtonBDI.bdi_classe.severities}
                        ${ButtonBDI.bdi_alinhamento_lateral}
                        ${ButtonBDI.bdi_alinhamento_vertical}
                        `}
                  funcao={() => avancar()}
                  disabled={
                    semposicaoinicial ||
                    (posicoesiniciais.length && habilitaBotaoAvancar)
                      ? false
                      : true
                  }
                />
              </div>
            </div>
          </section>

          <Dialog
            visible={statusPosicaoDuplicadaDialog}
            header="Você já cadastrou uma operação deste ativo na corretora informada."
            footer={dialogPosicaoDuplicadaFooter}
            onHide={fechaDialogPosicaoDuplicada}
            style={{
              width: 'min(45rem, 80vw)',
            }}
            modal
          >
            <DialogPosicaoDuplicadaContainer>
              <hr />
              <p>
                Somente é permitido o cadastro de uma operação por ativo, em uma
                mesma corretora. Para mais informações, entre em contato com o
                nosso suporte pelo chat ou pelo e-mail
                comunidade@bussoladoinvestidor.com.
              </p>
              <hr />
            </DialogPosicaoDuplicadaContainer>
          </Dialog>

          <Dialog
            visible={statusInformarInconsistenciaDialog}
            header="Algo não está certo?"
            footer={dialogInformarInconsistenciaFooter}
            onHide={fechaDialogInformarInconsistencia}
            style={{
              width: 'min(40rem, 80vw)',
            }}
            modal
          >
            <DialogInformarInconsistenciaContainer>
              <p>
                Caso tenha percebido alguma inconsistência, ou precise de ajuda,
                entre em contato com a nossa equipe de suporte. Você também pode
                enviar uma imagem, caso seja necessário.
              </p>

              <h3>Conte-nos o ocorrido:</h3>
              <InputTextarea
                value={mensagemInconsistencia}
                onChange={e => setMensagemInconsistencia(e.target.value)}
                rows={5}
                autoResize
              />
              <FileUpload
                chooseLabel="Anexar"
                name="invoice"
                accept="image/*"
                mode="basic"
                ref={informarInconsistenciaFileRef}
                customUpload
                multiple
              />
            </DialogInformarInconsistenciaContainer>
          </Dialog>
        </Box1>
      </div>
    </div>
  );
};
