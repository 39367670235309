import React, { MouseEvent } from 'react';

import { GreenBorderBox } from '../../styles/elements/GreenBorderBox';
import { Button } from '../Button';
import { Container } from './styles';

type ContainerFiltroProps = {
  children: React.ReactNode;
  bussola?: React.ReactNode;
  leftButtonLabel?: string;
  rightButtonLabel?: string;
  leftButtonDisabled?: boolean;
  rightButtonDisabled?: boolean;
  onLeftButtonClick: () => void;
  onRightButtonClick: (
    event: MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
};

export const ContainerFiltro = ({
  children,
  bussola,
  leftButtonLabel = 'Limpar',
  rightButtonLabel = 'Filtrar',
  leftButtonDisabled = false,
  rightButtonDisabled = false,
  onLeftButtonClick,
  onRightButtonClick,
}: ContainerFiltroProps) => {
  return (
    <GreenBorderBox>
      {!!bussola && bussola}
      <Container>
        {children}
        <div>
          <Button
            type="button"
            bdiType="secondary"
            label={leftButtonLabel}
            onClick={onLeftButtonClick}
            disabled={leftButtonDisabled}
          />
          <Button
            type="button"
            label={rightButtonLabel}
            onClick={onRightButtonClick}
            disabled={rightButtonDisabled}
          />
        </div>
      </Container>
    </GreenBorderBox>
  );
};
