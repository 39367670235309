import axios from 'axios';
import Cookies from 'js-cookie';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { BaseModule } from '../../../../components/BaseModule';
import { ALTERAR_CODIGO } from '../../../../components/BaseModule/constants';
import { ContainerFiltro } from '../../../../components/ContainerFiltro';
import { CustomTag } from '../../../../components/CustomTag';
import {
  OPERATION_ACCEPTED_INFO,
  SUCCESS_KEY,
  TICKER_MIM_SELECTION,
} from '../../../../context/toast/constants';
import { useToast } from '../../../../context/toast/useToast';
import { useEventListener } from '../../../../hooks/useEventListener';
import { AcoesContainer } from './styles';

export function AlteracaoCodigo() {
  const dropdownRef = useRef(null);

  const [dropdownEstaAberto, setDropdownEstaAberto] = useState(false);
  const [linhaSelecionada, setLinhaSelecionada] = useState(null);
  const [dados, setDados] = useState([]);

  const [ticktsFiltroAtualSelect, setTicktsFiltroAtualSelect] = useState(0);
  const [ticktsFiltroNovoSelect, setTicktsFiltroNovoSelect] = useState(0);
  const [dataFiltroSelecionada, setDataFiltro] = useState('');
  const [statusFiltro, setStatusFiltro] = useState('');
  const [ticktsFiltro, setTicktsFiltro] = useState([]);

  const { showToast } = useToast();

  const tiposTag = useMemo(() => {
    return {
      aceito: {
        type: '1',
        label: 'Aceito',
      },
      pendente: {
        type: '2',
        label: 'Pendente',
      },
      negado: {
        type: '4',
        label: 'Recusado',
      },
    };
  }, []);

  const status = [
    { label: 'Aceito', value: 'Aceito' },
    { label: 'Pendente', value: 'Pendente' },
    { label: 'Recusado', value: 'Recusado' },
  ];

  const fecharModal = useCallback(event => {
    if (dropdownRef.current?.contains(event.target)) {
      return;
    }

    setDropdownEstaAberto(false);
  }, []);

  useEventListener('click', fecharModal, {
    enabled: dropdownEstaAberto,
  });

  const GetInstrumentosUsuario = async () => {
    var idUser = Cookies.get('Id');
    await axios
      .get(
        `${process.env.REACT_APP_API_EVENTOS}/DesdobramentoGrupamento/InstrumentosUsuario/${idUser}`,
      )
      .then(response => {
        const dropdownOptions = [];

        response.data.map(item => {
          let options = { label: item.ticker, value: item.idInstrumento };
          dropdownOptions.push(options);
        });

        setTicktsFiltro(dropdownOptions);
      })
      .catch(error => {
        console.error(error);
        showToast();
      });
  };

  const AceitarRecusarTodos = async aceite => {
    var idUser = Cookies.get('Id');

    if (linhaSelecionada != null) {
      if (aceite) {
        var entitiesToAccept = linhaSelecionada.filter(
          item => item.status === tiposTag.pendente,
        );

        if (entitiesToAccept == null || entitiesToAccept.length == 0) {
          showToast(OPERATION_ACCEPTED_INFO);
          setLinhaSelecionada(null);
          return;
        }
        entitiesToAccept.forEach(async item => {
          await axios
            .post(
              `${process.env.REACT_APP_API_EVENTOS}/AlteracaoInstrumento/execute?idAlteracaoInstrumentos=${item.id}&autorizacao=${aceite}&idUsuario=${idUser}`,
            )
            .then(() => {
              setDados([]);
              var index = dados.indexOf(item);
              item.status = tiposTag.aceito;
              dados.splice(index, 1);
              dados.push(item);
              setDados(dados);
              setLinhaSelecionada(null);

              showToast(SUCCESS_KEY);
            })
            .catch(error => {
              showToast();
              console.error(error);
            });
        });
      } else {
        var entitiesToDecline = linhaSelecionada.filter(
          item => item.status === tiposTag.pendente,
        );

        if (entitiesToDecline == null || entitiesToDecline.length == 0) {
          showToast(OPERATION_ACCEPTED_INFO);
          setLinhaSelecionada(null);
          return;
        }
        entitiesToDecline.forEach(async item => {
          await axios
            .post(
              `${process.env.REACT_APP_API_EVENTOS}/AlteracaoInstrumento/execute?idAlteracaoInstrumentos=${item.id}&autorizacao=${aceite}&idUsuario=${idUser}`,
            )
            .then(() => {
              setDados([]);
              var index = dados.indexOf(item);
              item.status = tiposTag.negado;
              dados.splice(index, 1);
              dados.push(item);
              setDados(dados);
              setLinhaSelecionada(null);
              showToast(SUCCESS_KEY);
            })
            .catch(error => {
              showToast();
              console.error(error);
            });
        });
      }
    } else {
      showToast(TICKER_MIM_SELECTION);
    }
  };

  const GetDados = async () => {
    var idUser = Cookies.get('Id');
    const body = { idUsuario: idUser };
    await axios
      .post(
        `${process.env.REACT_APP_API_EVENTOS}/AlteracaoInstrumento/ListAlteracaoInstrumentosFiltro`,
        body,
      )
      .then(response => {
        let responseData = [];
        response.data.forEach(item => {
          var obj = {
            id: item.idAlteracaoInstrumentos,
            dataEvento: item.dataEvento != null ? item.dataEvento : null,
            tickerAtual: item.tickerAnterior,
            tickerNovo: item.tickerPosterior,
            status:
              item.status === 'Pendente'
                ? tiposTag.pendente
                : item.status === 'Recusado'
                ? tiposTag.negado
                : tiposTag.aceito,
          };

          responseData.push(obj);
        });
        setDados(responseData);
      })
      .catch(error => {
        console.error(error);
        showToast();
      });
  };

  const filtrar = async () => {
    var dataFormatada = '';
    if (dataFiltroSelecionada !== '') {
      let yourDate = new Date(dataFiltroSelecionada);
      dataFormatada = yourDate.toISOString().split('T')[0];
    }

    var idUser = Cookies.get('Id');
    const body = {
      idUsuario: idUser,
      dataEvento: dataFormatada,
      idInstrumento: 0,
      status: statusFiltro,
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_EVENTOS}/AlteracaoInstrumento/ListAlteracaoInstrumentosFiltro`,
        body,
      )
      .then(response => {
        let responseData = [];
        response.data.forEach(item => {
          var obj = {
            id: item.idAlteracaoInstrumentos,
            dataEvento: item.dataEvento != null ? item.dataEvento : null,
            tickerAtual: item.tickerAnterior,
            tickerNovo: item.tickerPosterior,
            status:
              item.status === 'Pendente'
                ? tiposTag.pendente
                : item.status === 'Recusado'
                ? tiposTag.negado
                : tiposTag.aceito,
          };

          responseData.push(obj);
        });
        setDados(responseData);
      })
      .catch(error => {
        setDados([]);
        console.error(error);
        showToast();
      });
  };

  const LimpaCamposFiltro = event => {
    setStatusFiltro('');
    setDataFiltro('');
    setTicktsFiltroNovoSelect(0);
    setTicktsFiltroAtualSelect(0);
    event.preventDefault();
  };

  useEffect(() => {
    GetInstrumentosUsuario();
    GetDados();
  }, []);

  return (
    <BaseModule tipo={ALTERAR_CODIGO}>
      <ContainerFiltro
        onLeftButtonClick={LimpaCamposFiltro}
        onRightButtonClick={filtrar}
      >
        <div>
          <label htmlFor="dataDoEvento">Data do Evento</label>
          <Calendar
            id="dataDoEvento"
            value={dataFiltroSelecionada}
            onChange={e => setDataFiltro(e.value)}
            dateFormat="dd/mm/yy"
          />
        </div>
        <div>
          <label htmlFor="tickerAtual">Ticker atual</label>
          <Dropdown
            id="tickerAtual"
            value={ticktsFiltroAtualSelect}
            onChange={e => setTicktsFiltroAtualSelect(e.value)}
            options={ticktsFiltro}
          />
        </div>
        <div>
          <label htmlFor="tickerNovo">Ticker novo</label>
          <Dropdown
            id="tickerNovo"
            value={ticktsFiltroNovoSelect}
            onChange={e => setTicktsFiltroNovoSelect(e.value)}
            options={ticktsFiltro}
          />
        </div>
        <div>
          <label htmlFor="status">Status</label>
          <Dropdown
            id="status"
            value={statusFiltro}
            options={status}
            onChange={e => setStatusFiltro(e.value)}
          />
        </div>
      </ContainerFiltro>

      <section>
        <AcoesContainer>
          <div className="container-buttons-1">
            <Button
              type="button"
              label="Aceitar todos"
              onClick={() => AceitarRecusarTodos(true)}
            />
            <Button
              type="button"
              label="Recusar todos"
              onClick={() => AceitarRecusarTodos(false)}
            />
          </div>
        </AcoesContainer>

        <DataTable
          value={dados}
          sortMode="multiple"
          paginator
          responsiveLayout="scroll"
          emptyMessage="Lista vazia."
          dataKey="id"
          currentPageReportTemplate="Mostrando de {first} até {last} - Total de {totalRecords} registros"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
          onSelectionChange={e => setLinhaSelecionada(e.value)}
          selectionMode="checkbox"
          selection={linhaSelecionada}
        >
          <Column selectionMode="multiple" />
          <Column
            field="id"
            key={dados.id}
            header="ID"
            hidden
            className="corTitulos"
          />
          <Column
            field="dataEvento"
            header="Data de evento"
            sortable
            className="corTitulos"
          />
          <Column
            field="tickerAtual"
            header="Ticker atual"
            sortable
            className="corTitulos"
          />
          <Column
            field="tickerNovo"
            header="Ticker novo"
            sortable
            className="corTitulos"
          />
          <Column
            header="Status"
            sortable
            body={data => <CustomTag status={data.status} />}
          />
        </DataTable>
      </section>
    </BaseModule>
  );
}
