import { Button } from 'primereact/button';

import { getBaseModuleValues } from './constants';
import { Container, Header, Wrapper } from './styles';

type BaseModuleProps = {
  tipo: string;
  children: React.ReactNode;
  props?: any;
  slotDireito?: React.ReactNode;
  bussola?: React.ReactNode;
  warn?: React.ReactNode;
};

export const BaseModule = ({
  tipo,
  children,
  props,
  slotDireito,
  bussola,
  warn,
}: BaseModuleProps) => {
  const { titulo, tooltip, descricao, link } = getBaseModuleValues(tipo);

  return (
    <Wrapper>
      <Container>
        <Header>
          <div>
            {titulo(props)}
            {link && (
              <a href={link} target="_blank" rel="noopener noreferrer">
                <Button
                  icon="pi pi-question-circle"
                  className="p-button-rounded p-button-text colorIcon iconTitulo"
                  tooltip={tooltip}
                />
              </a>
            )}
            <div className="slotDireito">{slotDireito}</div>
          </div>
          {descricao(props)}
          {!!bussola && bussola}
          {!!warn && warn}
        </Header>
        {children}
      </Container>
    </Wrapper>
  );
};
