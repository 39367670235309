import { useState } from 'react';

import {
  ClasseAtivo,
  PosicaoAtivo,
} from '../../../../../service/ApiRelatorios/types';
import { formatStringValueToNumber } from '../../../../../utils/functions/currency';
import { WidgetGrafico } from '../commons/WidgetGrafico';
import { Ativos, Corretoras } from '../types';
import { geraObjetoDataDoGrafico } from '../utils';

const filtraPosicoesDoAtivoPorIdDaCorretora = (
  ativo: ClasseAtivo,
  id: number,
) => {
  if (ativo.posicoes) {
    return ativo.posicoes.filter(posicao => posicao.idCorretora === id);
  }

  return [];
};

const calculaPatrimonioAcumuladoDasPosicoes = (posicoes: PosicaoAtivo[]) =>
  posicoes.reduce((patrimonioAcumulado, posicao) => {
    const { pmBruto, quantidadeTotal } = posicao;

    const precoMedioBruto = formatStringValueToNumber(pmBruto);

    return patrimonioAcumulado + precoMedioBruto * quantidadeTotal;
  }, 0);

const calculaPatrimonioDaCorretora = (ativos: Ativos, id: number) =>
  ativos.reduce((acumulador, ativo) => {
    const posicoesDaCorretora = filtraPosicoesDoAtivoPorIdDaCorretora(
      ativo,
      id,
    );

    if (posicoesDaCorretora.length) {
      const patrimonioDasPosicoes =
        calculaPatrimonioAcumuladoDasPosicoes(posicoesDaCorretora);

      return acumulador + patrimonioDasPosicoes;
    }

    return acumulador;
  }, 0);

const geraCorretorasEValores = (corretoras: Corretoras, ativos: Ativos) =>
  corretoras.reduce(
    (acumulador, corretora) => {
      const { id, nome } = corretora;
      const patrimonioDaCorretora = calculaPatrimonioDaCorretora(ativos, id);

      if (patrimonioDaCorretora) {
        return {
          corretoras: [...acumulador.corretoras, nome],
          valor: [...acumulador.valor, patrimonioDaCorretora],
        };
      }

      return acumulador;
    },
    { corretoras: [], valor: [] } as { corretoras: string[]; valor: number[] },
  );

const geraGraficoData = (ativos: Ativos, corretoras: Corretoras) => {
  const corretorasEValores = geraCorretorasEValores(corretoras, ativos);

  return geraObjetoDataDoGrafico(
    corretorasEValores.corretoras,
    corretorasEValores.valor,
  );
};

const titulo = () => (
  <>
    Minhas <b>corretoras</b>
  </>
);

type MinhasCorretorasProps = {
  corretorasSelecionadas: Corretoras;
  ativos: Ativos;
};

export function MinhasCorretoras({
  corretorasSelecionadas,
  ativos,
}: MinhasCorretorasProps) {
  const [data] = useState(geraGraficoData(ativos, corretorasSelecionadas));

  return (
    <WidgetGrafico
      dataGrafico={data}
      type="doughnut"
      titulo={titulo()}
      subtitulo="Distribuição por corretora"
    />
  );
}
