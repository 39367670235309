import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';

import { useBRLocale } from '../../../utils/functions/dates';
import { formatCurrency } from '../../../utils/functions/index';
import { ConfirmacaoBaseDialog } from '../ModalConfirmacaoBase';
import {
  CAMPOS_MOEDA,
  CAMPOS_OBRIGATORIOS,
  ENCERRAMENTO_DE_OPCOES,
  EXERCICIO_DE_OPCOES,
  FORMULARIO_VAZIO,
  getInformacoesModal,
} from './constants';
import { Container } from './styles';

const mockedTickers = [
  { label: 'PETR3', value: 'PETR3' },
  { label: 'PETR4', value: 'PETR4' },
  { label: 'PETR11', value: 'PETR11' },
];

export const AjusteDeOpcoes = ({
  closeModal,
  tipo = ENCERRAMENTO_DE_OPCOES,
  tickerExercicioDeOpcao,
  tickerDaOpcao,
  ...props
}) => {
  const [formulario, setFormulario] = useState({
    ...FORMULARIO_VAZIO,
    exercicioDeOpcoes: { ...FORMULARIO_VAZIO[tipo], tickerExercicioDeOpcao },
    encerramentoDeOpcoes: { ...FORMULARIO_VAZIO[tipo], tickerDaOpcao },
  });
  const [desabilitaAjustar, setDesabilitaAjustar] = useState(true);

  const { titulo, acao } = getInformacoesModal(tipo);

  useBRLocale();

  const handleChange = e => {
    const { value, id } = e.target;
    let _value = value;

    if (CAMPOS_MOEDA.includes(id)) {
      _value = formatCurrency(value, true);
    }

    setFormulario(prevState => ({
      ...prevState,
      [tipo]: { ...prevState[tipo], [id]: _value },
    }));
  };

  const onClickBotaoAplicar = () => {
    console.log('await envia formulario');
  };

  const onClickBotaoCancelar = () => {
    closeModal();
  };

  useEffect(() => {
    const validaFormulario = () => {
      const chaves = Object.keys(formulario[tipo]);

      const chavesComErro = chaves.map(chave => {
        const chaveObrigatoria = CAMPOS_OBRIGATORIOS[tipo].includes(chave);

        if (chaveObrigatoria && !formulario[tipo][chave]) {
          return true;
        }

        if (
          chave === 'precoMedio' &&
          formulario.exercicioDeOpcoes.precoMedio === formatCurrency('0', true)
        ) {
          return true;
        }

        return false;
      });

      setDesabilitaAjustar(chavesComErro.some(Boolean));
    };

    validaFormulario();
  }, [formulario, tipo]);

  const renderExercicioDeOpcao = () => (
    <>
      <div>
        <label htmlFor="tickerExercicioDeOpcao">
          Ticker exercício de opção
        </label>
        <InputText
          id="tickerExercicioDeOpcao"
          value={formulario.exercicioDeOpcoes.tickerExercicioDeOpcao}
          onChange={handleChange}
          placeholder={'Ticker exercício de opção'}
          disabled
        />
      </div>
      <div>
        <label htmlFor="tickerPregao">Ticker adquirido*</label>
        <Dropdown
          id="tickerPregao"
          value={formulario.exercicioDeOpcoes.tickerPregao}
          onChange={handleChange}
          placeholder={'Ticker adquirido'}
          options={mockedTickers}
          required
        />
      </div>
      <div>
        <label htmlFor="precoMedio">Preço médio*</label>
        <InputText
          id="precoMedio"
          value={formulario.exercicioDeOpcoes.precoMedio}
          onChange={handleChange}
          placeholder={'Preço médio'}
          required
        />
      </div>
    </>
  );

  const renderEncerramentoDeOpcao = () => (
    <>
      <div>
        <label htmlFor="tickerDaOpcao">Ticker da opção</label>
        <InputText
          id="tickerDaOpcao"
          value={formulario.encerramentoDeOpcoes.tickerDaOpcao}
          onChange={handleChange}
          placeholder={'Ticker da opção'}
          disabled
        />
      </div>
      <div>
        <label htmlFor="dataDeEncerramento">Data de encerramento*</label>
        <Calendar
          id="dataDeEncerramento"
          value={formulario.encerramentoDeOpcoes.dataDeEncerramento}
          onChange={handleChange}
          placeholder={'Data de encerramento'}
          required
          locale="br"
        />
      </div>
      <div>
        <label htmlFor="valorDoPremio">Valor do prêmio*</label>
        <InputText
          id="valorDoPremio"
          value={formulario.encerramentoDeOpcoes.valorDoPremio}
          onChange={handleChange}
          placeholder={'Valor do prêmio'}
          required
        />
      </div>
    </>
  );

  const renderFormulario = () =>
    tipo === EXERCICIO_DE_OPCOES
      ? renderExercicioDeOpcao()
      : renderEncerramentoDeOpcao();

  return (
    <ConfirmacaoBaseDialog
      header={titulo}
      onClickBotaoEsquerda={onClickBotaoCancelar}
      textoBotaoDireita={acao}
      onClickBotaoDireita={onClickBotaoAplicar}
      desabilitaBotaoDireita={desabilitaAjustar}
      temCamposObrigatorios
      {...props}
    >
      <Container>{renderFormulario()}</Container>
    </ConfirmacaoBaseDialog>
  );
};
