import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { useToast } from '../../context/toast/useToast';

type InstrumentosAll = {
  consulta: string;
  instrumentos: {
    idInstrumento: number;
    ticker: string;
  }[];
};

async function getCacheInstrumentosAll({
  queryKey,
  signal,
}: QueryFunctionContext) {
  const ticker = queryKey[3];

  const { data } = await axios.get<InstrumentosAll>(
    `${process.env.REACT_APP_API_SINCRONIZACACHE_INST}/v1/GetBuscaInstrumentosAll/${ticker}`,
    { signal },
  );

  return data.instrumentos.map(instrumento => ({
    label: instrumento.ticker,
    value: instrumento.idInstrumento,
  }));
}

export function useCacheInstrumentos(ticker: string | null) {
  const { showToast } = useToast();

  const tickerFormatted = !!ticker ? ticker.substring(0, 4) : '';

  return useQuery(
    ['sincronizaCache', 'instrumentos', 'all', tickerFormatted],
    getCacheInstrumentosAll,
    {
      onError: () => showToast(),
      enabled: !!ticker,
    },
  );
}
