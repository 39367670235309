import { Button } from 'primereact/button';
import styled from 'styled-components';

const colors = {
  primaryBlue: '#112c50',
  tertiaryDarkBlue: '#1b3667',
  tertiaryLightBlue: '#335893',
  grayishBlue: '#96b7c7',
  cyan: '#53c3c2',
  purple: '#4d4fa1',
  salmon: '#ea736a',
};

export const StyledButton = styled(Button)`
  width: ${props => props.fullWidth && '100%'};
  background-color: ${props => colors[props.color]} !important;

  &:not(.tag):hover {
    color: white !important;
    background: #96b7c7 !important;
    border-color: #96b7c7 !important;
  }

  &.tag {
    height: 24px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    min-width: 150px;
    border: none !important;

    &.p-button.p-component:hover {
      opacity: unset !important;
    }
  }

  &.secondary {
    background: white;
    color: #112c50;
  }

  &.tertiary {
    background-color: transparent;
    color: ${colors.grayishBlue};
    border-color: ${colors.grayishBlue} !important;
  }
`;
