import { AlteracaoCodigo } from '../../modules/calculadora-ir/pages/AlteracaoCodigo';
import { CadastroIPOSubscricao } from '../../modules/calculadora-ir/pages/CadastroIPOSubscricao';
import { DesdobramentoGrupamentoBonificacao } from '../../modules/calculadora-ir/pages/DesdobramentoGrupamentoBonificacao';
import { HistoricoDeOperacoes } from '../../modules/calculadora-ir/pages/HistoricoDeOperacoes';
import { Notificacoes } from '../../modules/configuracoes/pages/Notificacoes';
import { AjusteDeOpcoes } from '../../modules/impostos/pages/AjusteDeOpcoes';
import { EmissaoDarf } from '../../modules/impostos/pages/EmissaoDarf';
import { EmissaoDarf2 } from '../../modules/impostos/pages/EmissaoDarf2';
import { HistoricoDARF } from '../../modules/impostos/pages/HistoricoDARF';
import { Patrimonio } from '../../modules/minha-carteira/pages/Patrimonio';
import { Perfil } from '../../modules/minha-conta/pages/Perfil';
import { CommonOperationsAndDayTrade } from '../../modules/reports/pages/CommonOperationsAndDayTrade';
import { RelatorioBensDireitos } from '../../modules/reports/pages/RelatorioBensDireitos';
import { RelatorioFII } from '../../modules/reports/pages/RelatorioFII';
import { RelatorioIsentos } from '../../modules/reports/pages/RelatorioIsento';
import { RelatorioTributacaoExclusiva } from '../../modules/reports/pages/RelatorioTributacao';
import { CadastroInicial } from '../CadastroInicial';
import { DadosPessoais } from '../CadastroInicial/DadosPessoais';
import { DataReferencia } from '../CadastroInicial/DataReferencia';
import { IrrfAcumulado } from '../CadastroInicial/IRRFAcumulado';
import { PosicoesIniciais } from '../CadastroInicial/PosicoesIniciais';
import { PrejuizosAcumulados } from '../CadastroInicial/PrejuizosAcumulados';
import { Relatorio } from '../CadastroInicial/Relatorio';
import { CadastroManual } from '../Calculadora/CadastroManual';
import { OperacoesBMeF } from '../Calculadora/CadastroManual/OperacoesBMeF';
import { OperacoesBovespa } from '../Calculadora/CadastroManual/OperacoesBovespa';
import { RodapeBMeF } from '../Calculadora/CadastroManual/RodapeBMeF';
import { RodapeBovespa } from '../Calculadora/CadastroManual/RodapeBovespa';
import { GraficoB3 } from '../GraficoB3/GraficoB3';
import { HistoricoNotas } from '../HistoricoNotas/HistoricoNotas';
import { ImportacaoNotas } from '../ImportacaoNotas/ImportacaoNotas';
import { Onboarding } from '../Onboarding/Onboarding';
import { Proventos } from '../Proventos/Proventos';

var Rotas;

Rotas = [
  {
    path: '/',
    component: Patrimonio,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Calculadora de IR' }] },
  },
  {
    path: '/cadastroinicial',
    component: CadastroInicial,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Configurações', label: '' }] },
  },
  {
    path: '/cadastroinicial/datareferencia',
    component: DataReferencia,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Configurações', label: '' }] },
  },
  {
    path: '/cadastroinicial/posicoesiniciais',
    component: PosicoesIniciais,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Configurações', label: '' }] },
  },
  {
    path: '/cadastroinicial/prejuizosacumulados',
    component: PrejuizosAcumulados,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Configurações', label: '' }] },
  },
  {
    path: '/cadastroinicial/irrfacumulado',
    component: IrrfAcumulado,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Configurações', label: '' }] },
  },
  {
    path: '/cadastroinicial/dadospessoais',
    component: DadosPessoais,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Configurações', label: '' }] },
  },
  {
    path: '/cadastroinicial/relatorio',
    component: Relatorio,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Configurações', label: '' }] },
  },
  {
    path: '/proventos',
    component: Proventos,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Minha Carteira', label: '' }] },
  },
  {
    path: '/ImportacaoNotas',
    component: ImportacaoNotas,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Calculadora', label: '' }] },
  },
  {
    path: '/HistoricoNotas',
    component: HistoricoNotas,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Calculadora', label: '' }] },
  },
  {
    path: '/calculadora/cadastromanual',
    component: CadastroManual,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Calculadora de IR', label: '' }] },
  },
  {
    path: '/calculadora/cadastromanual/operacoesbovespa',
    component: OperacoesBovespa,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Calculadora de IR', label: '' }] },
  },
  {
    path: '/calculadora/cadastromanual/operacoesbmef',
    component: OperacoesBMeF,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Calculadora de IR', label: '' }] },
  },
  {
    path: '/calculadora/cadastromanual/rodapebovespa',
    component: RodapeBovespa,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Calculadora de IR', label: '' }] },
  },
  {
    path: '/calculadora/cadastromanual/rodapebmef',
    component: RodapeBMeF,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Calculadora de IR', label: '' }] },
  },
  {
    path: '/relatorios/operacoes-comuns-e-day-trade',
    component: CommonOperationsAndDayTrade,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Relatórios' }] },
  },
  {
    path: '/relatorios/bens-direitos',
    component: RelatorioBensDireitos,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Relatórios' }] },
  },
  {
    path: '/relatorios/tributacao-exclusiva',
    component: RelatorioTributacaoExclusiva,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Relatórios' }] },
  },
  {
    path: '/relatorios/isento',
    component: RelatorioIsentos,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Relatórios' }] },
  },
  {
    path: '/relatorios/fundosImobiliarios',
    component: RelatorioFII,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Relatórios' }] },
  },
  {
    path: '/impostos/emissao-darf',
    component: EmissaoDarf,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Impostos' }] },
  },
  {
    path: '/impostos/emissao-darf2',
    component: EmissaoDarf2,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Impostos' }] },
  },
  {
    path: '/impostos/historico-darf',
    component: HistoricoDARF,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Impostos' }] },
  },
  {
    path: '/calculadora-ir/historico-operacoes',
    component: HistoricoDeOperacoes,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Calculadora de IR' }] },
  },
  {
    path: '/calculadora-ir/eventos',
    component: DesdobramentoGrupamentoBonificacao,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Calculadora de IR' }] },
  },
  {
    path: '/calculadora-ir/alteracao-codigo',
    component: AlteracaoCodigo,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Calculadora de IR' }] },
  },
  {
    path: '/configuracoes/notificacoes',
    component: Notificacoes,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Configurações' }] },
  },
  {
    path: '/perfil',
    component: Perfil,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Minha Conta' }] },
  },
  {
    path: '/calculadora-ir/cadastro-ipo-subscricao',
    component: CadastroIPOSubscricao,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Calculadora de IR' }] },
  },
  {
    path: '/calculadora-ir/ajuste-de-opcoes',
    component: AjusteDeOpcoes,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Calculadora de IR' }] },
  },
  {
    path: '/minha-carteira/patrimonio',
    component: Patrimonio,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Calculadora de IR' }] },
  },
  {
    path: '/onboarding',
    component: Onboarding,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Suporte', label: '' }] },
  },
  {
    path: '/graficob3',
    component: GraficoB3,
    exact: false,
    meta: { breadcrumb: [{ parent: 'Suporte', label: '' }] },
  },
];

export default Rotas;
