import styled from 'styled-components';

import { pxToRem } from '../../../../utils/functions/styles';

export const BMFDialogFooterContainer = styled.section`
  margin-top: 2rem;
  border: 1px solid #53c3c26b;
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: space-around;
  gap: 2rem;
  overflow-x: scroll;

  h3 {
    font-weight: 400 !important;
    font-size: ${pxToRem(16, 14)} !important;
    color: #112c50 !important;
    line-height: ${pxToRem(16, 14)};
  }

  p {
    line-height: ${pxToRem(16, 14)};
    color: #12121294;
  }

  h3,
  p {
    text-align: center;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  div > div:empty {
    height: 6rem;
  }
`;

export const TwoColumnsDiv = styled.div`
  display: flex;
  gap: 0.8rem;
`;
