import styled from 'styled-components';

import { pxToRem } from '../utils/functions/styles';

export const TopMenuContainer = styled.div`
  #user-info li {
    margin-block: 10px !important;
  }

  #user-info li img {
    width: ${pxToRem(60)} !important;
    height: ${pxToRem(60)} !important;
  }

  .perfil-menu-span {
    padding: 0 25px 15px 25px !important;
  }

  @media (max-width: 992px) {
    #user-info li {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    #user-info li h2 {
      color: #fff !important;
      font-size: 18px !important;
      font-weight: 400 !important;
      text-align: center !important;
      white-space: normal;
    }

    #user-info li p {
      color: #fff !important;
      font-size: 14px !important;
      font-weight: 300 !important;
      text-align: center !important;
    }
  }
`;

export const AvatarContainer = styled.div`
  flex-direction: column;
  align-items: center;

  .p-avatar-text {
    padding: 2px 15px !important;
  }
`;
