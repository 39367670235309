import styled from 'styled-components';

import { devices, pxToRem } from '../../../../utils/functions/styles';

export const BovespaDialogFooterContainer = styled.section`
  margin-top: 2rem;
  border: 1px solid #53c3c26b;
  display: flex;

  @media ${devices.tablet} {
    flex-direction: column;

    > div:first-of-type {
      border-bottom: 1px solid #53c3c26b;
    }
  }

  > div {
    width: 100%;

    h3 {
      border-bottom: 1px solid #53c3c26b;
      padding: 1rem 0.8rem;
      color: #112c50 !important;
      background-color: #96b7c71a;
      font-size: ${pxToRem(16, 14)} !important;
      font-weight: 400 !important;
    }
  }
`;

export const ResumoNegociosContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-inline: 0.8rem;

  div {
    p {
      font-size: 1rem !important;
      color: #112c50 !important;
      font-weight: 400 !important;

      @media ${devices.mobileL} {
        font-size: 12px !important;
      }

      :last-of-type {
        padding-bottom: 1rem;
      }
    }

    &:last-of-type div p {
      color: #112c5080 !important;
    }
  }
`;

export const ResumoFinanceiroContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: space-between;
    padding-inline: 0.8rem;

    p {
      font-size: 1rem !important;
      color: #112c50 !important;
      font-weight: 400 !important;

      @media ${devices.mobileL} {
        font-size: 12px !important;
      }
    }

    div {
      display: inline-flex;

      span:first-of-type {
        margin-right: 1rem;
        color: #112c5080 !important;
      }

      span:last-of-type {
        min-width: 1.5rem;
        color: #112c50 !important;
      }
    }
  }
`;
