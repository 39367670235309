import { TabPanel, TabView } from 'primereact/tabview';

import { BaseModule } from '../../../../components/BaseModule';
import { MINHA_CONTA } from '../../../../components/BaseModule/constants';
import Paymentplan from '../../../../components/PaymentPlan';
import { AlterarSenha } from '../../components/AlterarSenha';
import { LogarComo } from '../../components/LogarComo';
import { MeuPlano } from '../../components/MeuPlano';
import { PerfilTemplate } from '../../components/PerfilTemplate';
import { Container } from './styles';

export function Perfil() {
  return (
    <BaseModule tipo={MINHA_CONTA}>
      <Container>
        <TabView>
          <TabPanel header="Perfil">
            <PerfilTemplate />
          </TabPanel>
          <TabPanel header="Meu Plano">
            <MeuPlano />
          </TabPanel>
          <TabPanel header="Alterar senha">
            <AlterarSenha />
          </TabPanel>
          {Paymentplan === 'adm' && (
            <TabPanel header="Acessar área do cliente">
              <LogarComo />
            </TabPanel>
          )}
        </TabView>
      </Container>
    </BaseModule>
  );
}
