import { DesdobramentoAgrupamentoPorFiltroResponse } from './types';

export function formatFilterData(
  filterEventsObj: DesdobramentoAgrupamentoPorFiltroResponse[],
) {
  const formattedData = filterEventsObj.map(item => {
    const formatPrecoAberto = (price: string) =>
      price === '-' ? '-' : `R$ ${item.novoPrecoAberto}`;

    return {
      id: item.idDesdobramentoGrupamento,
      status: item.status,
      dataEvento: item.dataEvento || null,
      ticker: item.ticker,
      quantidade: item.quantidade,
      precoMedio: !!item.precoMedio ? `R$ ${item.precoMedio}` : null,
      precoUsuario: `R$ ${item.precoMedioUsuario}`,
      tipoEvento: item.tipoEvento.nomeEvento,
      novaQuantidadeAberto: item.novaQuantidadeAberto,
      novoPrecoAberto: !!item.novoPrecoAberto
        ? formatPrecoAberto(item.novoPrecoAberto)
        : null,
      mudanca: item.mudanca,
      corretora: item.corretora,
      idCorretora: item.idCorretora,
    };
  });

  return formattedData;
}
