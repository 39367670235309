import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 24px;
  padding: 16px 12px;
  border: 1px solid rgba(83, 195, 194, 0.42);
  border-radius: 4px;

  & > div {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 8px;
    }

    input {
      max-width: 242px;
    }

    div {
      width: 242px;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;

    & > div {
      input {
        max-width: unset;
        width: 100%;
      }

      div {
        width: 100%;
      }
    }
  }
`;
