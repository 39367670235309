import { Impostos, TabelaImpostos } from './types';

const getDado = (key: string) => (impostos: Impostos) => impostos[key];

export const DADOS_TABELA = [
  {
    descricao: '(+) Op. comuns',
    resultado: getDado('operacoesComuns'),
    aliquota: '-',
    imposto: '0,00',
  },
  {
    descricao: '(-) Lucro isento',
    resultado: getDado('lucroIsento'),
    aliquota: '-',
    imposto: '0,00',
  },
  {
    descricao: '(=) Total Op. comuns',
    resultado: getDado('resultadoTotalOperacoesComuns'),
    aliquota: getDado('aliquotaDoImpostoSwing'),
    imposto: getDado('impostoTotalOperacoesComuns'),
  },
  {
    descricao: '(+) Day trade',
    resultado: getDado('resultadoDayTrade'),
    aliquota: getDado('aliquotaDoImpostoDayTrade'),
    imposto: getDado('impostoDayTrade'),
  },
  {
    descricao: '(+) FIIs',
    resultado: getDado('resultadoFIIS'),
    aliquota: getDado('aliquotaDoImpostoFIIS'),
    imposto: getDado('impostoFIIS'),
  },
  {
    key: 'impostoParcial',
    descricao: '(=) Imposto parcial',
    resultado: '',
    aliquota: '',
    imposto: getDado('impostoParcial'),
  },
];

export const geraDadosTabela = (impostos: Impostos) =>
  DADOS_TABELA.map(dado => {
    let resultado = dado.resultado;
    let imposto = dado.imposto;
    let aliquota = dado.aliquota;

    if (typeof dado.resultado === 'function') {
      resultado = dado.resultado(impostos);
    }

    if (typeof dado.imposto === 'function') {
      imposto = dado.imposto(impostos);
    }

    if (typeof dado.aliquota === 'function') {
      aliquota = dado.aliquota(impostos);
    }

    return { ...dado, resultado, imposto, aliquota } as TabelaImpostos;
  });

export const LIMITE_ALIENACAO = 20000;
