import { marketData } from '..';
import { MarketData, MarketDataCandles } from './types';

export const getMarketDataCandles = ({
  periodicity,
  stockCode,
  startDatetime,
  endDatetime,
  data_type,
  expiration_date,
  origin,
  token,
  username,
  signal,
}: MarketDataCandles) => {
  const params = new URLSearchParams();
  params.append('start_datetime', startDatetime);
  params.append('end_datetime', endDatetime);
  params.append('username', username);
  params.append('data_type', data_type);
  params.append('expiration_date', expiration_date.toString());
  params.append('origin', origin);
  params.append('token', token);

  return marketData.get<MarketData[]>(`/candles/${periodicity}/${stockCode}/`, {
    params,
    signal,
  });
};
