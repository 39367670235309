import { Dialog } from 'primereact/dialog';
import styled from 'styled-components';

import { pxToRem } from '../../../utils/functions/styles';

export const DialogContainer = styled(Dialog)`
  width: min(${pxToRem(960)}, 80vw);
`;

export const DialogFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    display: flex;
    gap: 24px;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    gap: 24px;

    span {
      align-self: flex-start;
    }

    div {
      align-self: flex-end;
    }
  }
`;
