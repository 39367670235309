import { QueryFunctionContext, useQueries } from '@tanstack/react-query';
import axios from 'axios';

import { useToast } from '../../../../../context/toast/useToast';
import {
  BussolaOperations,
  BussolaOperationsFilter,
  NotasCorretagemBussolaPorIdUsuarioFiltrosResponse,
} from '../types';

interface UseBussolaOperationsProps {
  bussolaFilter: BussolaOperationsFilter;
  migrationDataVisible: boolean;
}

type GenericResponseData = {
  dados: {
    id: number;
    nome: string;
  }[];
};

export const getOperationsBussolaData = async ({
  queryKey,
  signal,
}: QueryFunctionContext) => {
  const filterValue = queryKey[4];
  const { data } =
    await axios.post<NotasCorretagemBussolaPorIdUsuarioFiltrosResponse>(
      `${process.env.REACT_APP_API_OPERACOES}/OperacoesLegado/v1/GetNotasCorretagemPorIdUsuarioFiltros`,
      filterValue,
      { signal },
    );

  const result = {
    operations: [],
    quantidade: data.quantidade,
  } as BussolaOperations;

  data.dados.forEach(nota => {
    const notaFormatted = {
      corretora: nota.nomeCorretora,
      pregao: nota.dataPregao,
      tipo_ativo: nota.nomeSubTipoinstrumento,
      ticker: nota.nomeInstrumento,
      qtde: nota.quantidade,
      preco_ajuste: `R$ ${nota.precoAjuste}`,
      custos: `R$ ${nota.custos}`,
      tipo_neg: nota.tipoNegocio,
      operacao: nota.operacao,
      dc: nota.dc,
    };

    result.operations.push(notaFormatted);
  });

  return result;
};

const getCorretorasBussola = async ({
  queryKey,
  signal,
}: QueryFunctionContext) => {
  const idUSer = queryKey[4];
  const { data } = await axios.get<GenericResponseData>(
    `${process.env.REACT_APP_API_OPERACOES}/OperacoesLegado/v1/GetComboCorretoraPorIdUsuario/${idUSer}`,
    { signal },
  );

  return data.dados.map(item => ({
    label: item.nome,
    value: item.id,
  }));
};

const getSubTipoInstrumentosBussola = async ({
  queryKey,
  signal,
}: QueryFunctionContext) => {
  const idUser = queryKey[4];
  const { data } = await axios.get<GenericResponseData>(
    `${process.env.REACT_APP_API_OPERACOES}/OperacoesLegado/v1/GetComboSubTipoInstrumentoPorIdUsuario/${idUser}`,
    { signal },
  );

  return data.dados.map(item => ({
    label: item.nome,
    value: item.id,
  }));
};

const getTipoNegocioBussola = async ({
  queryKey,
  signal,
}: QueryFunctionContext) => {
  const idUser = queryKey[4];
  const { data } = await axios.get<GenericResponseData>(
    `${process.env.REACT_APP_API_OPERACOES}/OperacoesLegado/v1/GetComboTipoNegocioPorIdUsuario/${idUser}`,
    { signal },
  );

  return data.dados.map(item => ({
    label: item.nome,
    value: item.nome,
  }));
};

export function useBussolaOperations({
  bussolaFilter,
  migrationDataVisible,
}: UseBussolaOperationsProps) {
  const { showToast } = useToast();

  // the staleTime is infinity here because the bussola data is static, it will never be updated
  return useQueries({
    queries: [
      {
        queryKey: [
          'operacoes',
          'legado',
          'v1',
          'notaCorretagemUsuarioId',
          bussolaFilter,
        ],
        queryFn: getOperationsBussolaData,
        onError: () => showToast(),
        enabled: migrationDataVisible && !!bussolaFilter.idUsuario,
        staleTime: Infinity,
        cacheTime: 1000 * 60,
        keepPreviousData: true,
      },
      {
        queryKey: [
          'operacoes',
          'legado',
          'v1',
          'comboCorretora',
          bussolaFilter.idUsuario,
        ],
        queryFn: getCorretorasBussola,
        onError: () => showToast(),
        enabled: migrationDataVisible && !!bussolaFilter.idUsuario,
        staleTime: Infinity,
      },
      {
        queryKey: [
          'operacoes',
          'legado',
          'v1',
          'comboSubTipoInstrumento',
          bussolaFilter.idUsuario,
        ],
        queryFn: getSubTipoInstrumentosBussola,
        onError: () => showToast(),
        enabled: migrationDataVisible && !!bussolaFilter.idUsuario,
        staleTime: Infinity,
      },
      {
        queryKey: [
          'operacoes',
          'legado',
          'v1',
          'comboTipoNegocio',
          bussolaFilter.idUsuario,
        ],
        queryFn: getTipoNegocioBussola,
        onError: () => showToast(),
        enabled: migrationDataVisible && !!bussolaFilter.idUsuario,
        staleTime: Infinity,
      },
    ],
  });
}
