import { InputSwitch } from 'primereact/inputswitch';
import styled from 'styled-components';

import { pxToRem } from '../../../../utils/functions/styles';

export const SwitchTitle = styled.h3`
  color: #112c50 !important;
`;

export const SwitchContainer = styled.div`
  display: flex;

  p {
    color: #112c50 !important;
  }

  p:first-of-type {
    margin-right: 0.5rem;
  }

  p:last-of-type {
    margin-left: 0.5rem;
  }
`;

export const PrimeReactInputSwitch = styled(InputSwitch)`
  &[aria-checked='true']:hover .p-inputswitch-slider {
    background-color: #112c50 !important;
  }
`;

export const MigratedParagraphWarning = styled.p`
  font-size: ${pxToRem(12, 14)} !important;
  color: red;
`;

export const DataTableContainer = styled.div`
  button.p-paginator-next:disabled,
  button.p-paginator-prev:disabled {
    pointer-events: all;
    cursor: not-allowed !important;
  }

  thead .p-sortable-column.p-highlight {
    background-color: #f8f9fa !important;
  }
`;
