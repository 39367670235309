import styled from 'styled-components';

import { devices } from '../../../../utils/functions/styles';

export const Page = styled.div`
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
  padding: 0 24px;
`;

export const Container = styled.section`
  @media ${devices.mobileM} {
    ul li {
      font-size: 12px;
    }
  }

  @media ${devices.mobileS} {
    ul li a {
      padding: 0.5rem !important;
    }
  }
`;
